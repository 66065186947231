import React, { useMemo } from "react";
import { PercentDownSvg, PercentUpSvg } from "../../../../../assets/svgs/Svgs";
import { useDesktopOrLaptop } from "../../../../../services/useDesktopOrLaptop";
import { useTheme } from "../../../../../store";
import { INSIGHT_DATA } from "../../../utils";

const CardHeader = ({ title, lineChart, hoveredState }) => {
  const { smfontsize, mdfontsize } = useDesktopOrLaptop();
  const { Colors } = useTheme();

  const arrowColorsHandler = (title, direction) => {
    if (direction === "up") {
      if (
        title === INSIGHT_DATA.AVG_DELIVERY_TIME.title ||
        title === INSIGHT_DATA.RETURNS.title ||
        title === INSIGHT_DATA.AVG_MILEAGE.title ||
        title === INSIGHT_DATA.AVG_PICKUP_TIME.title ||
        title === INSIGHT_DATA.AVG_PICK_UP_DELAYS.title ||
        title === INSIGHT_DATA.AVG_DROPOFF_TIME.title ||
        title === INSIGHT_DATA.AVG_DROP_OFF_DELAYS.title
      ) {
        return Colors.percentDown;
      } else {
        return Colors.percentUp;
      }
    } else {
      if (
        title === INSIGHT_DATA.AVG_DELIVERY_TIME.title ||
        title === INSIGHT_DATA.RETURNS.title ||
        title === INSIGHT_DATA.AVG_MILEAGE.title ||
        title === INSIGHT_DATA.AVG_PICKUP_TIME.title ||
        title === INSIGHT_DATA.AVG_PICK_UP_DELAYS.title ||
        title === INSIGHT_DATA.AVG_DROPOFF_TIME.title ||
        title === INSIGHT_DATA.AVG_DROP_OFF_DELAYS.title
      ) {
        return Colors.percentUp;
      } else {
        return Colors.percentDown;
      }
    }
  };

  const styles = useMemo(() => {
    return {
      container: {
        width: "100%",
        display: "flex",
        alignItems: "baseline",
        height: "40px",
      },
      headingPercent: {
        display: "flex",
        alignItems: "center",
      },
      headingTitle: {
        fontFamily: "Poppins",
        fontWeight: 600,
        fontSize: mdfontsize,
        color: Colors.fontDark,
        marginRight: "12px",
        marginBottom: 0,
      },
      headingPercentText: {
        fontFamily: "Poppins",
        fontWeight: 600,
        fontSize: smfontsize,
        marginLeft: "5px",
        marginBottom: "0px",
        color: arrowColorsHandler(title, lineChart?.percentageChangeDirection),
        // color: lineChart?.percentageChangeDirection === "up" ? Colors.percentUp: Colors.percentDown
      },
    };
  }, [Colors, lineChart, mdfontsize, smfontsize, title]);

  return (
    <div style={styles.container}>
      <div style={styles.headingPercent}>
        <p style={styles.headingTitle}>{title}</p>
        {hoveredState?.isHover ? (
          <>
            {hoveredState?.hoverPayload.percentageChangeDirection && (
              <>
                {hoveredState.hoverPayload.percentageChangeDirection === "up" ? (
                  <PercentUpSvg color={arrowColorsHandler(title, hoveredState.hoverPayload.percentageChangeDirection)} />
                ) : (
                  <PercentDownSvg color={arrowColorsHandler(title, hoveredState.hoverPayload.percentageChangeDirection)} />
                )}
                <p
                  style={{
                    ...styles.headingPercentText,
                    color: arrowColorsHandler(title, hoveredState.hoverPayload.percentageChangeDirection),
                    // color: hoveredState.hoverPayload.percentageChangeDirection === "up" ? Colors.percentUp : Colors.percentDown,
                  }}
                >
                  {hoveredState?.hoverPayload?.percentageChange}%
                </p>
              </>
            )}
          </>
        ) : (
          <>
            {lineChart?.percentageChangeDirection && (
              <>
                {lineChart?.percentageChangeDirection === "up" ? (
                  <PercentUpSvg color={arrowColorsHandler(title, lineChart?.percentageChangeDirection)} />
                ) : (
                  <PercentDownSvg color={arrowColorsHandler(title, lineChart?.percentageChangeDirection)} />
                )}
                <p style={styles.headingPercentText}>{lineChart?.percentageChange}%</p>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CardHeader;
