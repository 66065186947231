import React from "react";
import TableHeader from "./TableHeader";
import styles from "../styles/InvoiceStoresDetails/styles.module.css"
import classNames from "classnames";
import TableBody from "./TableBody";

const InvoiceStoresDetails = () => {

  return (
    <div>
      <TableHeader />
      <div className={classNames(styles.container, 'kata')}>
        <TableBody />
      </div>
    </div>
  );
};

export default InvoiceStoresDetails;
