import moment from "moment";

// Initial payload for line charts
export const InsightsInitialPayload = {
  data: [],
  percent: "",
  percent_value: "",
  current_month: "",
  previous_month: "",
  primaryRangeTotal: "",
  secondaryRangeTotal: "",
  average_value: 0,
};

export const GROUP_BY_OPTIONS = {
  DAY: "day",
  HOUR: "hour",
};

export const getDateObject = (date, format) => {
  return {
    day: parseInt(moment(date, format).format("DD")),
    month: parseInt(moment(date, format).format("MM")),
    year: parseInt(moment(date, format).format("YYYY")),
  };
};

export const getValueComparesion = (currentCount, previousCount) => {
  if (!currentCount && !previousCount) {
    return { percentageChange: "0.00", percentageChangeDirection: "up" };
  } else if (currentCount && !previousCount) {
    return { percentageChange: "100", percentageChangeDirection: "up" };
  } else if (!currentCount && previousCount) {
    return { percentageChange: "100", percentageChangeDirection: "down" };
  }

  const min_percent_value = currentCount - previousCount;
  const percentageChange = (min_percent_value / previousCount) * 100;
  const percentageChangeDirection = percentageChange >= 0 ? "up" : "down";
  return {
    percentageChange: percentageChange.toFixed(2),
    percentageChangeDirection,
  };
};

export const getDateDifference = (rangeStart, rangeEnd) => {
  var momentStart = moment(rangeStart);
  var momentEnd = moment(rangeEnd);
  return momentEnd.diff(momentStart, "days");
};

export const mapAdverbsOfTime = (date) => {
  if (moment(date).isSame(moment(), "day")) return "Today";
  if (moment().diff(moment(date), "days") === 1) return "Yesterday";
  return moment(date).format("MMM DD");
};

export const formatNumberToTime = (num) => {
  if (num && num > 9) {
    return `${num}:00`;
  }
  return `0${num}:00`;
};

export const formatDataKeys = (date, hour) => {
  return `${mapAdverbsOfTime(date)} ${formatNumberToTime(hour)}`;
};

//helper for combineData function defined below////////////////////////////////////////////
export const createLegends = (primaryDateRange, secondaryDateRange, groupByOption) => {
  const primaryRangeDifference = getDateDifference(primaryDateRange.start_date, primaryDateRange.end_date);
  const secondaryRangeDifference = getDateDifference(secondaryDateRange.start_date, secondaryDateRange.end_date);
  if (groupByOption === GROUP_BY_OPTIONS.DAY) {
    if (primaryRangeDifference < secondaryRangeDifference) {
      return {
        leftLegend: mapAdverbsOfTime(primaryDateRange.start_date),
        rightLegend: null,
      };
    }
    return {
      leftLegend: mapAdverbsOfTime(primaryDateRange.start_date),
      rightLegend: mapAdverbsOfTime(primaryDateRange.end_date),
    };
  } else if (groupByOption === GROUP_BY_OPTIONS.HOUR) {
    if (primaryRangeDifference < secondaryRangeDifference) {
      return {
        leftLegend: `${mapAdverbsOfTime(primaryDateRange.start_date)} 00:00`,
        rightLegend: null,
      };
    }
    return {
      leftLegend: `${mapAdverbsOfTime(primaryDateRange.start_date)} 00:00`,
      rightLegend: `${mapAdverbsOfTime(primaryDateRange.start_date)} 23:00`,
    };
  }
};

//helper for combineData function defined below////////////////////////////////////////////
export const createDateWiseData = (primaryData, secondaryData, isAmount, value, parentLoopIndex) => {
  let tempArray = [];
  for (let index = 0; index < 24; index++) {
    const dataObject = {
      key: parentLoopIndex * 24 + index,
    };
    if (primaryData) {
      dataObject.primaryDataKey = formatDataKeys(primaryData.date, index);
      dataObject.primaryDataValue = isAmount
        ? Number((parseFloat(primaryData.hours[index][value]) / 100).toFixed(2))
        : parseFloat(primaryData.hours[index][value]);
    }
    if (secondaryData) {
      dataObject.secondaryDataKey = formatDataKeys(secondaryData.date, index);
      dataObject.secondaryDataValue = isAmount
        ? Number((parseFloat(secondaryData.hours[index][value]) / 100).toFixed(2))
        : parseFloat(secondaryData.hours[index][value]);
    }
    tempArray.push(dataObject);
  }
  return tempArray;
};

//helper for formatSingleRangeData function defined below////////////////////////////////////////////
export const createSingleRangeLegends = (primaryDateRange, groupByOption, currentHour) => {
  if (groupByOption === GROUP_BY_OPTIONS.DAY) {
    return {
      leftLegend: mapAdverbsOfTime(primaryDateRange.start_date),
      rightLegend: mapAdverbsOfTime(primaryDateRange.end_date),
    };
  } else if (groupByOption === GROUP_BY_OPTIONS.HOUR) {
    return {
      leftLegend:
        currentHour === 23
          ? `${mapAdverbsOfTime(primaryDateRange.end_date)} 00:00`
          : `${mapAdverbsOfTime(primaryDateRange.start_date)} ${currentHour + 1}:00`,
      rightLegend: `${mapAdverbsOfTime(primaryDateRange.end_date)} ${currentHour}:00`,
    };
  }
};

//helper for formatSingleRangeData function defined below////////////////////////////////////////////
export const createSingleLineDateWiseData = (primaryData, isAmount, value, parentLoopIndex) => {
  let tempArray = [];
  for (let index = 0; index < primaryData.hours.length; index++) {
    const dataObject = {
      key: parentLoopIndex * 24 + index,
    };
    if (primaryData) {
      dataObject.primaryDataKey = formatDataKeys(primaryData.date, primaryData.hours[index].hour);
      dataObject.primaryDataValue = isAmount
        ? Number((parseFloat(primaryData.hours[index][value]) / 100).toFixed(2))
        : parseFloat(primaryData.hours[index][value]);
    }
    tempArray.push(dataObject);
  }
  return tempArray;
};

export const transformDataDaily = (startDate, endDate, returnKey = "average") => {
  const result = [];
  const dateMap = {};

  // Generate the date range and populate the result
  let currentDate = moment(startDate).utc().startOf("day");
  const end = moment(endDate).utc().endOf("day");

  while (currentDate.isSameOrBefore(end, "day")) {
    const dateStr = currentDate.format("YYYY-MM-DD");
    result.push({
      date: dateStr,
      [returnKey]: dateMap[dateStr] || 0,
    });
    currentDate.add(1, "day");
  }

  return result;
};

const transformDataHourly = (startDate, endDate, returnKey = "average") => {
  const result = [];
  const dateMap = {};

  // Generate the date range and populate the result
  let currentDate = moment(startDate).utc().startOf("day");
  const end = moment(endDate).utc().endOf("day");

  while (currentDate.isBefore(end) || currentDate.isSame(end, "day")) {
    const dateStr = currentDate.format("YYYY-MM-DD");
    const hours = [];

    for (let hour = 0; hour < 24; hour++) {
      hours.push({
        hour: hour,
        [returnKey]: (dateMap[dateStr] && dateMap[dateStr][hour]) || 0,
      });
    }

    result.push({
      date: dateStr,
      hours: hours,
    });

    currentDate = currentDate.add(1, "day");
  }

  return result;
};

export const backfillHelperFunctionDays = (providersArray, startDate, endDate, returnKey) => {
  let resultData = [];
  if (moment(startDate).isSame(moment(endDate), "day")) {
    for (let i = 0; i < providersArray.length; i++) {
      const data = transformDataHourly(startDate, endDate, returnKey);
      resultData.push({ data: data, provider_name: providersArray[i] });
    }
  } else {
    for (let i = 0; i < providersArray.length; i++) {
      const data = transformDataDaily(startDate, endDate, returnKey);
      resultData.push({ data: data, provider_name: providersArray[i] });
    }
  }
  return resultData;
};

export const transformData = (originalData, dataKey) => {
  const providers = originalData.analytics_data.map((item) => item.provider_name);
  // const colors = ["#00A0DE", "#FE3009", "#000000", "#D45229", "#F8931F"];
  const colors = [
    "#FE3009",
    "#D45229",
    "#F8931F",
    "#4CAF50",
    "#9C27B0",
    "#FFEB3B",
    "#3F51B5",
    "#FF5722",
    "#795548",
    "#607D8B",
    "#E91E63",
    "#CDDC39",
    "#8BC34A",
    "#FFC107",
    "#9E9E9E",
    "#00BCD4",
    "#673AB7",
    "#FF9800",
    "#00A0DE",
  ];

  const dates = originalData.analytics_data[0].data.map((item) => item.date);
  let finalList = [];
  const providersData = originalData.analytics_data[0].data;
  const finaldata = {
    providersList: providers.map((provider, index) => ({
      name: provider,
      color: colors[index],
      // color: colors[index % colors.length],
    })),
    leftLegend: mapAdverbsOfTime(providersData[0].date),
    rightLegend: mapAdverbsOfTime(providersData[providersData.length - 1].date),
    data: [],
  };

  if (originalData.analytics_data[0].data[0].hours) {
    const hours = originalData.analytics_data[0].data[0].hours.map((item) => item.hour);

    finaldata.data = hours.map((hour) => {
      const dataPoint = { hour };
      providers.forEach((provider) => {
        const providerData = originalData.analytics_data.find((item) => item.provider_name === provider);
        const hourData = providerData.data[0].hours.find((item) => item.hour === hour);
        dataPoint[provider] = hourData[dataKey];
      });
      return dataPoint;
    });
    finaldata.leftLegend = "00:00";
    finaldata.rightLegend = "23:00";
    return finaldata;
  } else {
    finalList = dates.map((date, index) => {
      const dataPoint = { date };
      providers.forEach((provider) => {
        const providerData = originalData.analytics_data.find((item) => item.provider_name === provider);
        dataPoint[provider] = providerData.data[index][dataKey];
      });
      return dataPoint;
    });
    finaldata.data = finalList;

    return finaldata;
  }
};
// };

//For insights line charts: It an api response formater for double line charts/////////////////////////////////
export const combineData = (datePayload, compareDatePayload, dataField, isAmount = false, value = "value", groupBy) => {
  const percentValues = getValueComparesion(datePayload[value], compareDatePayload[value]);
  const legends = createLegends(datePayload, compareDatePayload, groupBy);
  const finaldata = {
    ...percentValues,
    ...legends,
    primaryRangeTotal: isAmount ? Number((datePayload[value] / 100).toFixed(2)) : datePayload[value],
    secondaryRangeTotal: isAmount ? Number((compareDatePayload[value] / 100).toFixed(2)) : compareDatePayload[value],
    data: [],
  };
  const datePyloadList = !datePayload[dataField] ? [] : datePayload[dataField];
  const compareDatePayloadList = !compareDatePayload[dataField] ? [] : compareDatePayload[dataField];

  const greaterLength =
    datePyloadList.length > compareDatePayloadList.length ? datePyloadList.length : compareDatePayloadList.length;

  let finalList = [];

  if (groupBy === GROUP_BY_OPTIONS.DAY) {
    for (let index = 0; index < greaterLength; index++) {
      const primaryData = datePyloadList[index];
      const secondaryData = compareDatePayloadList[index];

      const dataObject = {
        key: index,
      };
      if (primaryData) {
        dataObject.primaryDataKey = mapAdverbsOfTime(primaryData.date);
        dataObject.primaryDataValue = isAmount
          ? Number((parseFloat(primaryData[value]) / 100).toFixed(2))
          : parseFloat(primaryData[value]);
      }
      if (secondaryData) {
        dataObject.secondaryDataKey = mapAdverbsOfTime(secondaryData.date);
        dataObject.secondaryDataValue = isAmount
          ? Number((parseFloat(secondaryData[value]) / 100).toFixed(2))
          : parseFloat(secondaryData[value]);
      }
      finalList.push(dataObject);
    }
  } else if (groupBy === GROUP_BY_OPTIONS.HOUR) {
    for (let index = 0; index < greaterLength; index++) {
      const singleDateData = createDateWiseData(datePyloadList[index], compareDatePayloadList[index], isAmount, value, index);
      finalList.push(...singleDateData);
    }
  }

  finaldata.data = finalList;
  finaldata.showPrimaryLineDot = groupBy === GROUP_BY_OPTIONS.DAY && datePyloadList.length === 1;
  finaldata.showSecondaryLineDot = groupBy === GROUP_BY_OPTIONS.DAY && compareDatePayloadList.length === 1;
  return finaldata;
};

//For overview chart: It is an api response formater for single line chart/////////////////////////////////
export const formatSingleRangeData = (datePayload, dataField, isAmount = false, value = "value", groupBy) => {
  const currentHour = moment().hour();
  const legends = createSingleRangeLegends(datePayload, groupBy, currentHour);
  const finaldata = {
    ...legends,
    primaryRangeTotal: isAmount ? Number((datePayload.total / 100).toFixed(2)) : datePayload.total,
    data: [],
  };
  const datePyloadList = !datePayload[dataField] ? [] : datePayload[dataField];
  const greaterLength = datePyloadList.length;
  let finalList = [];
  let total = 0;

  if (groupBy === GROUP_BY_OPTIONS.DAY) {
    for (let index = 0; index < greaterLength; index++) {
      const primaryData = datePyloadList[index];
      const dataObject = {
        key: index,
      };
      if (primaryData) {
        dataObject.primaryDataKey = mapAdverbsOfTime(primaryData.date);
        dataObject.primaryDataValue = isAmount
          ? Number((parseFloat(primaryData[value]) / 100).toFixed(2))
          : parseFloat(primaryData[value]);
      }
      finalList.push(dataObject);
    }
  } else if (groupBy === GROUP_BY_OPTIONS.HOUR) {
    for (let index = 0; index < greaterLength; index++) {
      const indexDatePyloadList = { ...datePyloadList[index] };
      if (index === 0) {
        indexDatePyloadList.hours = indexDatePyloadList.hours.filter((data) => data.hour > currentHour);
      }
      if (index === greaterLength - 1) {
        indexDatePyloadList.hours = indexDatePyloadList.hours.filter((data) => data.hour <= currentHour);
      }
      const singleDateData = createSingleLineDateWiseData(indexDatePyloadList, isAmount, value, index);

      // total = indexDatePyloadList.hours.reduce(
      //   (accumulator, object) => accumulator + object.count,
      //   total
      // );
      total = isAmount ? Number((datePayload.total / 100).toFixed(2)) : datePayload.total;
      finalList.push(...singleDateData);
    }
  }

  finaldata.data = finalList;
  if (groupBy === GROUP_BY_OPTIONS.HOUR) {
    finaldata.primaryRangeTotal = isAmount ? Number((total / 100).toFixed(2)) : total;
  }
  finaldata.showPrimaryLineDot = groupBy === GROUP_BY_OPTIONS.DAY && datePyloadList.length === 1;
  return finaldata;
};

export const groupByOption = (datePayload, compareDatePayload) => {
  const primaryRangeDifference = getDateDifference(datePayload.start_date, datePayload.end_date);
  const secondaryRangeDifference = getDateDifference(compareDatePayload.start_date, compareDatePayload.end_date);
  if (!primaryRangeDifference && secondaryRangeDifference < 14) return "hour";
  return "day";
};

//This function returns the start and end date for current cycle like this month, this week, or, last week etc
//starting from the first date of the cycle///////////////////////////////////////////////////////////////////
export const getPreviousMonthDate = ({ prevMonth, addDay = false, isStart = false, format = "YYYY-MM-DD" }) => {
  let lastmonthlastdate = moment().subtract(prevMonth, "months");

  if (addDay) {
    lastmonthlastdate = lastmonthlastdate.add(addDay, "days");
  }
  if (isStart) {
    lastmonthlastdate = lastmonthlastdate.startOf("month");
  }
  lastmonthlastdate = lastmonthlastdate.format(format);

  const lastmonthfirstdate = moment().subtract(prevMonth, "months").endOf("month").format(format);

  const date = {
    monthStart: lastmonthlastdate,
    monthEnd: lastmonthfirstdate,
    monthStartObject: getDateObject(lastmonthlastdate, format),
    monthEndObject: getDateObject(lastmonthfirstdate, format),
  };

  return date;
};

//This function returns the interval like last 12months, 1 month, week etc//////////////////////////////
export const getPreviousRangeIntervalHelper = ({
  dateCycle = "months",
  cycleOffset = 0,
  addDay = false,
  isStart = false,
  format = "YYYY-MM-DD",
  endDateCycleOffset = 0,
}) => {
  let cyclelastDate = moment().subtract(cycleOffset, dateCycle);
  if (addDay) {
    cyclelastDate = cyclelastDate.add(addDay, "days");
  }
  if (isStart) {
    cyclelastDate = cyclelastDate.startOf(dateCycle.slice(0, -1));
  }
  cyclelastDate = cyclelastDate.format(format);

  const lastmonthfirstdate =
    endDateCycleOffset > 0 && !isStart
      ? moment().subtract(endDateCycleOffset, dateCycle).format(format)
      : moment().subtract(cycleOffset, dateCycle).endOf(dateCycle.slice(0, -1)).format(format);

  const date = {
    periodStartDate: cyclelastDate,
    periodEndDate: lastmonthfirstdate,
    monthStartObject: getDateObject(cyclelastDate, format),
    monthEndObject: getDateObject(lastmonthfirstdate, format),
  };
  return date;
};

// It converts commas in any number after every 3 digits right to left/////////////////////////////////
export const numberWithCommas = (x) => {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

// This fuction gives the formated values with attached units based on the format provided//////////////
export const getFormatedValue = (value, valueFormat) => {
  let formatedValue = value;
  switch (valueFormat) {
    case "amount":
      formatedValue = "$" + numberWithCommas(formatedValue);
      break;
    case "min":
      formatedValue = (
        <>
          {numberWithCommas(formatedValue)}
          <span style={{ fontSize: "12px", fontWeight: 600 }}>&nbsp;min</span>
        </>
      );
      break;
    case "delivery":
      formatedValue = (
        <>
          {numberWithCommas(formatedValue)}
          <span style={{ fontSize: "12px", fontWeight: 600 }}>&nbsp;deliveries</span>
        </>
      );
      break;
    case "mile":
      formatedValue = (
        <>
          {numberWithCommas(formatedValue?.toFixed(2))}
          <span style={{ fontSize: "12px", fontWeight: 600 }}>&nbsp;mi.</span>
        </>
      );
      break;
    case "average":
      formatedValue = (
        <>
          {numberWithCommas(formatedValue.toFixed(2))}
          <span style={{ fontSize: "12px", fontWeight: 600 }}>%</span>
        </>
      );
      break;
    default:
      formatedValue = formatedValue ? numberWithCommas(formatedValue) : 0;
      break;
  }
  return formatedValue;
};

// for Insights charts: helping to created a grid of 12 charts////////////////////////////////
export const INSIGHT_DATA = {
  DELIVERY_VOLUME: {
    title: "Delivery volume",
    primaryLineLable: "primaryDataValue",
    secondaryLineLable: "secondaryDataValue",
    valueFormat: "delivery",
  },
  AVG_DELIVERY_TIME: {
    title: "Avg. delivery time",
    primaryLineLable: "primaryDataValue",
    secondaryLineLable: "secondaryDataValue",
    valueFormat: "min",
  },
  AVG_MILEAGE: {
    title: "Avg. Mileage",
    primaryLineLable: "primaryDataValue",
    secondaryLineLable: "secondaryDataValue",
    valueFormat: "mile",
  },

  RETURNS: {
    title: "Returns",
    primaryLineLable: "primaryDataValue",
    secondaryLineLable: "secondaryDataValue",
    valueFormat: "delivery",
  },
  AVG_PICKUP_TIME: {
    title: "Avg. pick-up time",
    primaryLineLable: "primaryDataValue",
    secondaryLineLable: "secondaryDataValue",
    valueFormat: "min",
  },
  AVG_PICK_UP_DELAYS: {
    title: "Avg. pick-up delays",
    primaryLineLable: "primaryDataValue",
    secondaryLineLable: "secondaryDataValue",
    valueFormat: "min",
  },
  AVG_DROPOFF_TIME: {
    title: "Avg. drop-off time",
    primaryLineLable: "primaryDataValue",
    secondaryLineLable: "secondaryDataValue",
    valueFormat: "min",
  },
  AVG_DROP_OFF_DELAYS: {
    title: "Avg. drop-off delays",
    primaryLineLable: "primaryDataValue",
    secondaryLineLable: "secondaryDataValue",
    valueFormat: "min",
  },

  //!
  // DELIVERY_SUCCESS_RATE: {
  //   title: "Delivery success rate",
  //   primaryLineLable: "primaryDataValue",
  //   secondaryLineLable: "secondaryDataValue",
  //   valueFormat: "average",
  // },
  //!
  // DELIVERY_FAILURE_RATE: {
  //   title: "Delivery failure rate",
  //   primaryLineLable: "primaryDataValue",
  //   secondaryLineLable: "secondaryDataValue",
  //   valueFormat: "average",
  // },
  // DELIVERY_COST: {
  //   title: "Delivery cost",
  //   primaryLineLable: "primaryDataValue",
  //   secondaryLineLable: "secondaryDataValue",
  //   valueFormat: "amount",
  // },
  // ORDER_VALUE: {
  //   title: "Order value",
  //   primaryLineLable: "primaryDataValue",
  //   secondaryLineLable: "secondaryDataValue",
  //   valueFormat: "amount",
  // },
};

// for overview chart: based on the selected period it creates datePayload for query params/////////////
export const selectPeriodHelper = (value) => {
  const initDate = {
    end_date: moment().format("YYYY-MM-DD"),
  };

  switch (value) {
    case 1:
      {
        const periodData = getPreviousRangeIntervalHelper({
          dateCycle: "months",
          cycleOffset: 12,
        });
        initDate.start_date = periodData.periodStartDate;
      }
      break;
    case 2:
      {
        const periodData = getPreviousRangeIntervalHelper({
          dateCycle: "days",
          cycleOffset: 30,
        });
        initDate.start_date = periodData.periodStartDate;
      }
      break;
    case 3:
      {
        const periodData = getPreviousRangeIntervalHelper({
          dateCycle: "days",
          cycleOffset: 7,
        });
        initDate.start_date = periodData.periodStartDate;
      }
      break;
    case 4:
      {
        const periodData = getPreviousRangeIntervalHelper({
          dateCycle: "hours",
          cycleOffset: 24,
        });
        initDate.start_date = periodData.periodStartDate;
      }
      break;
    default:
      {
        const periodData = getPreviousRangeIntervalHelper({
          dateCycle: "months",
          cycleOffset: 12,
        });
        initDate.start_date = periodData.monthStart;
      }
      break;
  }
  return initDate;
};

//for HeatChart: format week days to readable strings ////////////////
export const formatWeek = (week) => {
  switch (week) {
    case 1:
      return "Sun";
    case 2:
      return "Mon";
    case 3:
      return "Tue";
    case 4:
      return "Wed";
    case 5:
      return "Thu";
    case 6:
      return "Fri";
    case 7:
      return "Sat";
    default:
      return "";
  }
};

//for HeatChart: format hours to readable strings ////////////////
export const formatTime = (time) => {
  if (time === 0) return "12 AM";
  else if (time < 12) return `${time} AM`;
  else if (time === 12) return "12 PM";
  else if (time > 12) return `${time - 12} PM`;
  else return "";
};

//for HeatChart: Creating 7x24 initial data 2 dimentional array filled with 0 ////////////////
const initialArrayConstructor = () => {
  const m = 7;
  const n = 24;
  let arr = new Array(m);
  for (var i = 0; i < m; i++) {
    arr[i] = new Array(n).fill(0);
  }
  return arr;
};

//for HeatChart: formating backend data to heat chart readable data////////////////
export const formatTopTimingData = (data) => {
  const dataArr = initialArrayConstructor();
  for (let index = 0; index < data.length; index++) {
    const datePayload = data[index];
    const weekDay = moment(datePayload.date).day();
    for (let j = 0; j < datePayload.hours.length; j++) {
      const hourPayload = datePayload.hours[j];
      dataArr[weekDay][hourPayload.hour] = dataArr[weekDay][hourPayload.hour] + hourPayload.count;
    }
  }
  const payload = dataArr.reduce((result, currentValue, currentIndex, arr) => {
    const dayPayload = currentValue.map((hourData, hourDataIndex) => ({
      day: currentIndex + 1,
      hour: hourDataIndex,
      deliveries: hourData,
    }));
    return [...result, ...dayPayload];
  }, []);

  return payload;
};

export const timePickerOptions = [
  { value: "today", name: "Today" },
  { value: "yesterday", name: "Yesterday" },
  { value: "this_month", name: "This Month" },
  { value: "previous_month", name: "Previous Month" },
  { value: "this_week", name: "This Week" },
  { value: "previous_week", name: "Previous Week" },
  { value: "last_3_months", name: "3 months" },
  { value: "previous_3_months", name: "Previous 3 months" },
  { value: "last_30_days", name: "30 days" },
  { value: "previous_30_days", name: "Previous 30 days" },
  { value: "last_7_days", name: "7 days" },
  { value: "previous_7_days", name: "Previous 7 days" },
];

export const createDateFromInterval = (payload) => {
  const dateObject = {};
  switch (payload) {
    case "today":
      dateObject.start_date = moment().format("YYYY-MM-DD");
      dateObject.end_date = moment().format("YYYY-MM-DD");
      break;
    case "this_month":
      {
        const periodData = getPreviousRangeIntervalHelper({
          dateCycle: "months",
          isStart: true,
          cycleOffset: 0,
        });
        dateObject.start_date = moment(periodData.periodStartDate).format("YYYY-MM-DD");
        dateObject.end_date = moment().format("YYYY-MM-DD");
      }
      break;
    case "this_week":
      {
        const periodData = getPreviousRangeIntervalHelper({
          dateCycle: "weeks",
          isStart: true,
          cycleOffset: 0,
        });
        dateObject.start_date = moment(periodData.periodStartDate).format("YYYY-MM-DD");
        dateObject.end_date = moment().format("YYYY-MM-DD");
      }
      break;
    case "yesterday":
      {
        const periodData = getPreviousRangeIntervalHelper({
          dateCycle: "days",
          cycleOffset: 1,
        });
        dateObject.start_date = moment(periodData.periodStartDate).format("YYYY-MM-DD");
        dateObject.end_date = moment(periodData.periodStartDate).format("YYYY-MM-DD");
      }
      break;
    case "previous_month":
      {
        const periodData = getPreviousRangeIntervalHelper({
          dateCycle: "months",
          isStart: true,
          cycleOffset: 1,
        });
        dateObject.start_date = moment(periodData.periodStartDate).format("YYYY-MM-DD");
        dateObject.end_date = moment(periodData.periodEndDate).format("YYYY-MM-DD");
      }
      break;
    case "previous_week":
      {
        const periodData = getPreviousRangeIntervalHelper({
          dateCycle: "weeks",
          isStart: true,
          cycleOffset: 1,
        });
        dateObject.start_date = moment(periodData.periodStartDate).format("YYYY-MM-DD");
        dateObject.end_date = moment(periodData.periodEndDate).format("YYYY-MM-DD");
      }
      break;
    case "last_3_months":
      {
        const periodData = getPreviousRangeIntervalHelper({
          dateCycle: "months",
          cycleOffset: 3,
          addDay: true,
        });
        dateObject.start_date = moment(periodData.periodStartDate).format("YYYY-MM-DD");
        dateObject.end_date = moment().format("YYYY-MM-DD");
      }
      break;
    case "last_30_days":
      {
        const periodData = getPreviousRangeIntervalHelper({
          dateCycle: "days",
          cycleOffset: 30,
          addDay: true,
        });
        dateObject.start_date = moment(periodData.periodStartDate).format("YYYY-MM-DD");
        dateObject.end_date = moment().format("YYYY-MM-DD");
      }
      break;
    case "last_7_days":
      {
        const periodData = getPreviousRangeIntervalHelper({
          dateCycle: "days",
          cycleOffset: 7,
          addDay: true,
        });
        dateObject.start_date = moment(periodData.periodStartDate).format("YYYY-MM-DD");
        dateObject.end_date = moment().format("YYYY-MM-DD");
      }
      break;
    case "previous_3_months":
      {
        const periodData = getPreviousRangeIntervalHelper({
          dateCycle: "months",
          cycleOffset: 6,
          endDateCycleOffset: 3,
          addDay: true,
        });
        dateObject.start_date = moment(periodData.periodStartDate).format("YYYY-MM-DD");
        dateObject.end_date = moment(periodData.periodEndDate).format("YYYY-MM-DD");
      }
      break;
    case "previous_30_days":
      {
        const periodData = getPreviousRangeIntervalHelper({
          dateCycle: "days",
          cycleOffset: 60,
          endDateCycleOffset: 30,
          addDay: true,
        });
        dateObject.start_date = moment(periodData.periodStartDate).format("YYYY-MM-DD");
        dateObject.end_date = moment(periodData.periodEndDate).format("YYYY-MM-DD");
      }
      break;
    case "previous_7_days":
      {
        const periodData = getPreviousRangeIntervalHelper({
          dateCycle: "days",
          cycleOffset: 14,
          endDateCycleOffset: 7,
          addDay: true,
        });
        dateObject.start_date = moment(periodData.periodStartDate).format("YYYY-MM-DD");
        dateObject.end_date = moment(periodData.periodEndDate).format("YYYY-MM-DD");
      }
      break;
    default:
      break;
  }
  return dateObject;
};

export const mapStyles = [
  {
    featureType: "administrative",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.province",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 65,
      },
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: "50",
      },
      {
        visibility: "off",
      },
    ],
  },
];

const MAP_OFFSET_TO_HOURS = {
  "-12": 12,
  "-11": 13,
  "-10": 14,
  "-9": 15,
  "-8": 16,
  "-7": 17,
  "-6": 18,
  "-5": 19,
  "-4": 20,
  "-3": 21,
  "-2": 22,
  "-1": 23,
  24: 0,
  25: 1,
  26: 2,
  27: 3,
  28: 4,
  29: 5,
  30: 6,
  31: 7,
  32: 8,
  33: 9,
  34: 10,
  35: 11,
  36: 12,
  37: 13,
  38: 14,
};

const rangeFormater = (offset, date, data, finalArray) => {
  let newDate = date;
  if (data.hour - offset < 0) {
    newDate = moment(date).subtract(1, "days").format("YYYY-MM-DD");
  }
  if (data.hour - offset > 23) {
    newDate = moment(date).add(1, "days").format("YYYY-MM-DD");
  }

  if (Array.isArray(finalArray[newDate])) {
    finalArray[newDate].push({
      ...data,
      hour: MAP_OFFSET_TO_HOURS[data.hour - offset] ?? data.hour - offset,
    });
  } else {
    finalArray[newDate] = [
      {
        ...data,
        hour: MAP_OFFSET_TO_HOURS[data.hour - offset] ?? data.hour - offset,
      },
    ];
  }
};

export const mapUtcRangeToLocalRange = (data) => {
  const parsedData = {};
  const finaldata = [];
  const utcOffset = moment().utcOffset() / 60;
  for (let i = 0; i < data.length; i++) {
    const element = data[i];
    const date = element.date;
    for (let j = 0; j < element.hours.length; j++) {
      const hourEl = element.hours[j];
      rangeFormater(utcOffset, date, hourEl, parsedData);
    }
  }
  for (let [key, value] of Object.entries(parsedData)) {
    finaldata.push({ date: key, hours: value });
  }
  return data;
};
