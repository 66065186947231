import React from "react";

import styles from "./styles.module.css";
import classNames from "classnames";
import {
  calculateCostLineItemsTotalAmount,
  sortObjectArrayByOrder,
} from "../../../../../../utils/helpers";
const order = ["Delivery Fee", "Transaction Fee", "Tip"];

const AmountDetails = ({ item }) => {
  return (
    <div className="flex flex-col w-full">
      <div className={styles.title}>Amount</div>
      <div className={styles.amountCont}>
        {sortObjectArrayByOrder(item?.cost_line_items ?? [], order).map(
          (lineItem, index) => {
            return (
              <div
                key={index}
                className={classNames(
                  "flex justify-between mt-2",
                  styles.detailsOneLine
                )}
              >
                <div className={classNames(styles.oneLineTitle, styles.light)}>
                  {lineItem.name}
                </div>
                <div className={classNames(styles.oneLineTitle, styles.dark)}>
                  ${(lineItem.amount / 100).toFixed(2)}
                </div>
              </div>
            );
          }
        )}
        {/* <div className="flex justify-between w-full mt-7">
          <div className={classNames(styles.oneLineTitle, styles.light)}>
            Subsidized
          </div>
          <div className={classNames(styles.oneLineTitle, styles.dark)}>
            $
            {item.subsidized
              ? (item.subsidized / 100).toFixed(2)
              : (0).toFixed(2)}
          </div>
        </div> */}
        <div
          className={classNames(
            "flex justify-between mt-6",
            styles.detailsOneLine
          )}
        >
          <div
            className={classNames(
              styles.oneLineTitle,
              styles.dark,
              styles.fontBigger,
              "font-semibold"
            )}
          >
            Total
          </div>
          <div
            className={classNames(
              styles.oneLineTitle,
              styles.dark,
              styles.fontBigger,
              "font-semibold"
            )}
          >
            {/* ${(item?.payment_amount / 100).toFixed(2)} */}${" "}
            {(
              calculateCostLineItemsTotalAmount(item.cost_line_items) / 100
            ).toFixed(2)}
          </div>
        </div>
        {(item?.upsell || item?.subsidized) && (
          <div
            className={classNames(
              styles.detailsOneLine,
              styles.noBorder,
              "flex justify-between mt-5 border-0"
            )}
          >
            <div className={classNames(styles.oneLineTitle, styles.light)}>
              {item?.subsidized ? "Subsidized" : item?.upsell && "Upsell"}
            </div>
            <div className={classNames(styles.oneLineTitle, styles.dark)}>
              $
              {item?.subsidized
                ? (item?.subsidized / 100).toFixed(2)
                : item?.upsell && (item?.upsell / 100).toFixed(2)}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AmountDetails;
