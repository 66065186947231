import React from "react";
import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";

const AddressSection = ({ orderInfo }) => {
  const { smfontsize, isdesktop } = useDesktopOrLaptop();
  return (
    <div className="w-full  ">
      <div
        className="kata"
        style={{
          maxHeight: "40vh",
          overflowY: "scroll",
          width: "100%",
          paddingLeft: "20px",
        }}
      >
        <div className="text-gray-500  text-xs capitalize  relative">
          <div
            style={{ left: "-20px" }}
            className="absolute top-1.5  h-2.5 w-2.5 rounded-full bg-yellow-500 z-10"
          ></div>
        </div>
        <div style={{ fontSize: smfontsize }} className="text-gray-400">
          Pickup Address
        </div>
        <div
          style={{ fontSize: smfontsize }}
          className="text-gray-700 font-semibold  capitalize"
        >
          {orderInfo.pickup.address}
        </div>
        <div style={{ width: "100%" }}>
          <div
            style={{ fontSize: smfontsize }}
            className="text-gray-500 relative  text-xs capitalize mt-6 "
          >
            Stop
            <div
              style={{ left: "-19.5px", zIndex: 100 }}
              className="absolute h-2 w-2 rounded-full bg-green-300"
            ></div>
            <div
              style={{
                left: "-17px",
                height: isdesktop ? "68px" : "74px",
              }}
              className="absolute   w-0.5 -bottom-1 bg-gray-300"
            ></div>
          </div>
          <div
            style={{ fontSize: smfontsize }}
            className="text-gray-700 font-semibold capitalize"
          >
            {orderInfo.dropoff.address}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddressSection;
