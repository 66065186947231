import React, { useEffect, useState } from "react";
import { useFlag } from "@unleash/proxy-client-react";
import { useMyStore, useTheme, userDataStore, useInvoices, useMerchant } from "../../../../store";
import TableHeader from "./TableHeader";
import { useDesktopOrLaptop } from "../../../../services/useDesktopOrLaptop";
import Pagination from "../../../shared/Pagination";
import InvoiceItems from "./InvoiceItem";
import Loader from "../../../shared/atoms/Loader";
import ConnectedAccountsFilter from "./ConnectedAccountsFilter";
import styles from "./styles/invoiceList.module.css";
import StatusFilter from "./StatusFilter";

const InvoiceList = () => {
  const { Colors } = useTheme();
  const { xlfontsize } = useDesktopOrLaptop();

  const { isSandbox } = useMyStore();
  const { invoices, getInvoicesWorker, isInvoicesLoading, paymentStatusfilter } = useInvoices();

  const { authInfo } = userDataStore();
  const { merchantData } = useMerchant();
  const [selectedConnectedAccount, setSelectedConnectedAccount] = useState();

  const getInvoicesHandler = async (param = {}) => {
    const params = {
      test_mode: param.isSandbox ?? isSandbox,
      starting_after: param.starting_after || null,
      ending_before: param.ending_before || null,
      payment_status: paymentStatusfilter?.value || null,
    };
    const id = selectedConnectedAccount?.id || authInfo.merchantAccountId;
    await getInvoicesWorker(id, params);
  };

  useEffect(() => {
    let subscribe = true;
    if (subscribe) {
      if (authInfo) {
        getInvoicesHandler();
      }
    }

    return () => {
      subscribe = false;
    };
  }, [authInfo, isSandbox, selectedConnectedAccount, paymentStatusfilter]);

  return (
    <>
      <div style={{ position: "relative" }}>
        <div className={styles.filters}>
          <StatusFilter />
          {merchantData?.account_type === "platform" && (
            <ConnectedAccountsFilter
              selectedConnectedAccount={selectedConnectedAccount}
              setSelectedConnectedAccount={setSelectedConnectedAccount}
            />
          )}
        </div>
      </div>
      <TableHeader />
      <div
        className="kata"
        style={{
          height: "calc(100% - 255px)",
          overflowY: "auto",
        }}
      >
        {isInvoicesLoading && (
          <div style={{ minHeight: "100px" }} className="w-full flex items-center justify-center kata">
            <Loader />
          </div>
        )}
        {invoices?.data?.length > 0 ? (
          invoices.data.map((invoice) => <InvoiceItems key={invoice.id} invoiceObject={invoice} />)
        ) : (
          <div
            style={{ color: Colors.fontDark, fontSize: xlfontsize }}
            className="h-full w-full flex justify-center items-center"
          >
            No invoice has been generated yet.
          </div>
        )}
      </div>
      <div className="flex items-center justify-end pr-28 mt-9">
        <Pagination
          data={invoices}
          onGetPaginationData={getInvoicesHandler}
          dependencies={[isSandbox, selectedConnectedAccount, paymentStatusfilter]}
        />
      </div>
    </>
  );
};

export default InvoiceList;
