import React from "react";
import {
  BurqFeeSvg,
  HideQuoteSvg,
  MultipleQuoteIcon,
  RadiusLimitSvg,
  SubsidizeSvg,
  UpsellSvg,
} from "../../../../assets/svgs/Svgs";
import { useDesktopOrLaptop } from "../../../../services/useDesktopOrLaptop";
import { useQuoteWorkflow, useTheme } from "../../../../store";
import { PreferenceIcon, VehicleTypeIcon } from "../../OrderWorkflow/OrderWorkflowSvgs";
import { LuRepeat2 } from "react-icons/lu";

const QuoteBoxes = ({ item, selectedKey, removeWorkflowItem }) => {
  const { Colors } = useTheme();
  const { setSelectedQuoteItem } = useQuoteWorkflow();
  const { isdesktop, smfontsize } = useDesktopOrLaptop();

  return (
    <div
      onClick={() => {
        setSelectedQuoteItem(item.key);
      }}
      type="button"
      key={item.key}
      style={{
        margin: "10px",
        width: isdesktop ? "190px" : "210px",
        height: isdesktop ? "100px" : "120px",
        marginBottom: "20px",
        borderRadius: "10px",
        borderWidth: "1px",
        borderColor: selectedKey === item.key ? Colors.bluePrimary : Colors.borderSecondary,
        backgroundColor: Colors.white,
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        fontSize: smfontsize,
      }}
    >
      {item.id !== 7 && (
        <button
          onClick={(e) => {
            removeWorkflowItem(item.key);
            e.stopPropagation();
          }}
          className="myShadow kata"
          style={{
            position: "absolute",
            top: "-13px",
            right: "-13px",
            zIndex: 3,
            height: "26px",
            width: "26px",
            borderRadius: "15px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // backgroundColor: "red",
            backgroundColor: isdesktop ? Colors.bgGrey : Colors.white,
            color: Colors.fontDark,
          }}
        >
          -
        </button>
      )}
      <div style={{ color: Colors.fontDark }}>{item.front}</div>
      {item.key === "Subsidize" ? (
        <SubsidizeSvg />
      ) : item.key === "Upsell" ? (
        <UpsellSvg />
      ) : item.key === "Limit Radius" ? (
        <RadiusLimitSvg />
      ) : item.key === "Price Ceiling" ? (
        <HideQuoteSvg />
      ) : item.key === "Multi Quote" ? (
        <MultipleQuoteIcon />
      ) : item.key === "Reroute" ? (
        <LuRepeat2 size={28} color={Colors.bluePrimary} />
      ) : item.key === "Provider Preference" ? (
        <PreferenceIcon />
      ) : (
        <BurqFeeSvg />
      )}
      <div style={{ color: Colors.fontDark, marginTop: "8px" }}>{item.key}</div>
    </div>
  );
};

export default QuoteBoxes;
