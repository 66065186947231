import React, { useState } from "react";
import { FiDownload } from "react-icons/fi";
import { useDesktopOrLaptop } from "../../../../../services/useDesktopOrLaptop";
import CustomModal from "../../../../CustomModal/CustomModal";
import { Loading } from "../../../../../assets/svgs/Svgs";
import { RiQuestionLine } from "react-icons/ri";
import ToolTip from "../../../../Tooltip/Tooltip";
import CsvModalContent from "./CsvModalContent";
import { useInvoices, useTheme } from "../../../../../store";
import styles from "./styles.module.css";
import Button from "../../../../shared/atoms/Button";

const InvoiceCSVExport = ({ invoiceId, csvToolTip }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTimeZone, setSelectedTimeZone] = useState("UTC");

  const { isdesktop, smfontsize } = useDesktopOrLaptop();
  const { Colors } = useTheme();
  const { isInvoiceCSVLoading } = useInvoices();

  return (
    <div className="h-full mr-3 flex items-center">
      <CustomModal
        extraPanelStyles={{ maxWidth: isdesktop ? "50%" : "80%" }}
        isOpen={isModalVisible}
        onClose={() => {
          setIsModalVisible(false);
        }}
      >
        <CsvModalContent
          invoiceId={invoiceId}
          selectedTimeZone={selectedTimeZone}
          setIsModalVisible={setIsModalVisible}
          setSelectedTimeZone={setSelectedTimeZone}
        />
      </CustomModal>
      <Button
        onClick={async () => {
          setIsModalVisible(true);
        }}
        externalClassName={styles.downloadButton}
        text="Download CSV"
        variant="secondary"
      />
      {/* <button
        style={{
          marginLeft: "10px",
          color: Colors.fontDark,
          display: "flex",
          alignItems: "center",
          padding: "0px 16px",
          height: isdesktop ? "36px" : "50px",
          borderRadius: "6px",
          backgroundColor: Colors.bgGrey,
        }}
        type="button"
        onClick={async () => {
          setIsModalVisible(true);
          return;
        }}
      >
        {isInvoiceCSVLoading ? (
          <div
            style={{ minHeight: "100px" }}
            className="w-full flex items-center justify-center kata"
          >
            <Loading
              color={Colors.bluePrimary}
              className="h-6 w-24 animate-spin"
            />
          </div>
        ) : (
          <>
            <FiDownload color={Colors.fontGreyLight} size={21} />{" "}
            <span
              style={{
                marginLeft: "6px",
                color: Colors.fontGreyLight,
                fontWeight: "500",
                fontSize: smfontsize,
              }}
            >
              Export
            </span>
          </>
        )}
      </button> */}
      {/* {csvToolTip && (
        <div
          data-tip
          data-for={`csvToolTip`}
          className="cursor-pointer relative ml-2 "
        >
          <RiQuestionLine color={Colors.fontGreyLight} size={24} />
          <ToolTip
            id={`csvToolTip`}
            disable={false}
            place="right"
            text={csvToolTip}
          />
        </div>
      )} */}
    </div>
  );
};

export default InvoiceCSVExport;
