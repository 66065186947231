import React from "react";
import JsonView from "../../../../shared/common/JsonView";
import StatusBadgeReusable from "../../../../shared/common/StatusBadgeReusable";
import { badgesForApiReuqestStatus } from "../../../utils";
import classNames from "classnames";
import styles from "../../../styles/DeliveryFailureLogs/requestDetails.module.css";

const RequestDetails = ({ request }) => {
  return (
    <div className={classNames("kata", styles.container)}>
      <div className={styles.activityHeader}>
        <div className={styles.url}>{request.api_url}</div>
        {request.response_payload.status && request.response_payload.code && (
          <div className={styles.statusBadge}>
            <StatusBadgeReusable
              badgesObject={badgesForApiReuqestStatus}
              lookupKey={request.response_payload.status}
              status={
                request.response_payload.status +
                " " +
                request.response_payload.code
              }
            />
          </div>
        )}
      </div>
      <div className={styles.jsonHeader}>Request parameters</div>
      <JsonView jsonObject={request.request_payload} />
      <div className={styles.jsonHeader}>Response body</div>
      <JsonView jsonObject={request.response_payload} />
    </div>
  );
};

export default RequestDetails;
