import React from "react";
import styles from "./styles/listHeader.module.css";
import classNames from "classnames";
import { classNameHandler } from "./utils";

export const ListHeader = ({ columns }) => {
  return (
    <>
      <tr>
        {columns.map((item) => {
          if (item.isActive) {
            return (
              <th
                key={item.header}
                className={classNames(styles.columnTitle, classNameHandler(item.header), { "pl-2": item.header === "status" })}
              >
                {item.header}
              </th>
            );
          } else return <></>;
        })}
      </tr>
    </>
  );
};

export default ListHeader;
