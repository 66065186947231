import { useState } from "react";
import { createStore } from "aniuta";
import * as Sentry from "@sentry/react";
import { toast } from "react-toastify";
import {
  acceptQuote,
  cancelOrder,
  createOrder,
  createOrderNote,
  deleteOrder,
  getOrder,
  getOrderQuotes,
  getOrders,
  updateOrder,
} from "../utils/api/order";
import { constructName } from "../utils/helpers";

const useOrders = createStore({
  name: "useOrders",
  Store: () => {
    //Filters
    const [searchByPickupId, setSearchByPickupId] = useState();
    const [searchByPickupAddress, setSearchByPickupAddress] = useState();
    const [searchByPickupName, setSearchByPickupName] = useState();
    const [searchByPickupPhoneNumber, setSearchByPickupPhoneNumber] = useState();
    const [searchByDropoffAddress, setSearchByDropoffAddress] = useState();
    const [searchByDropoffName, setSearchByDropoffName] = useState();
    const [searchByDropoffPhoneNumber, setSearchByDropoffPhoneNumber] = useState();
    const [searchByOrderId, setSearchByOrderId] = useState();
    const [formatedDateRange, setFormatedDateRange] = useState();
    const [filterByStatus, setFilterByStatus] = useState([]);
    const [activeStatus, setActiveStatus] = useState();
    const [filterByStore, setFilterByStore] = useState();
    const [filterByExternalOrderId, setFilterByExternalOrderId] = useState();

    const [orders, setOrders] = useState();
    const [isOrdersLoading, setIsOrdersLoading] = useState(false);
    const [isCreateNoteLoading, setIsCreateNoteLoading] = useState(false);

    const [duplicateOrderData, setDuplicateOrderData] = useState();

    const createOrderNoteWorker = async (id, testMode, note) => {
      setIsCreateNoteLoading(true);
      try {
        const { data } = await createOrderNote(id, { test_mode: testMode }, { note });
        getOrderWorker(id);
        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsCreateNoteLoading(false);
      }
    };

    const getOrdersWorker = async (id, params) => {
      setIsOrdersLoading(true);
      try {
        const { data } = await getOrders(id, {
          status: activeStatus,
          pickup_id: searchByPickupId,
          pickup_address: searchByPickupAddress,
          pickup_name: searchByPickupName,
          pickup_phone_number: searchByPickupPhoneNumber,
          dropoff_address: searchByDropoffAddress,
          dropoff_name: searchByDropoffName,
          dropoff_phone_number: searchByDropoffPhoneNumber,
          order_id: searchByOrderId,
          start_date: formatedDateRange?.start || null,
          end_date: formatedDateRange?.end || null,
          starting_after: null,
          ending_before: null,
          store_id: filterByStore?.id || null,
          external_order_ref: filterByExternalOrderId || null,
          limit: 30,
          ...params,
        });
        setOrders(data);
        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsOrdersLoading(false);
      }
    };

    const [order, setOrder] = useState();
    const [isOrderLoading, setIsOrderLoading] = useState(false);

    const getOrderWorker = async (id) => {
      setIsOrderLoading(true);
      try {
        const { data } = await getOrder(id);
        setOrder(data?.data);
        return data.data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsOrderLoading(false);
      }
    };

    const [isCreateOrderLoading, setIsCreateOrderLoading] = useState(false);

    const createOrderWorker = async (id, testMode, payload, callback) => {
      setIsCreateOrderLoading(true);
      try {
        const requestedMerchantAccountId = payload.merchant_account_id ?? id;
        const requestParams = {
          order_value: Math.floor(payload.order_value * 100),
          external_order_ref: payload?.external_order_ref || undefined,
          items: payload.items,
          tip: Math.floor(payload.tip * 100),
          store_id: payload.store_id ?? null,
          pickup_customer_email: payload.pickup_customer_email ?? null,
          pickup_address: !payload.store_id ? payload.pickup_address : "",
          pickup_unit: !payload.store_id ? payload.pickup_unit : "",
          pickup_phone_number: !payload.store_id ? payload.pickup_phone_number : "",
          pickup_name: !payload.store_id ? constructName(payload.pickup_first_name, payload.pcikup_last_name) : "",
          pickup_at: payload.schedule_type !== "schedule_dropoff" && payload.eta ? payload.eta : "",
          pickup_notes: !payload.store_id ? payload.pickup_notes : "",
          dropoff_phone_number: payload.dropoff_phone_number ?? "",
          dropoff_address: payload.dropoff_address ?? "",
          dropoff_unit: payload.dropoff_unit ?? "",
          dropoff_name: constructName(payload.dropoff_first_name, payload.dropoff_last_name) ?? "",
          dropoff_at: payload.schedule_type === "schedule_dropoff" && payload.eta ? payload.eta : "",
          dropoff_notes: payload.dropoff_notes ?? "",
          dropoff_customer_email: payload?.dropoff_customer_email ?? "",
          // customer_email: payload.is_customer_email_saved
          //   ? payload?.customer_email
          //   : "",
        };

        const { data } = await createOrder(requestedMerchantAccountId, { test_mode: testMode }, requestParams);
        toast.success("Order has been saved successfully");
        callback(data?.data.id);
        return data.data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsCreateOrderLoading(false);
      }
    };

    const [isUpdateOrderLoading, setIsUpdateOrderLoading] = useState(false);

    const updateOrderWorker = async (accountId, orderId, testMode, payload, callback) => {
      setIsUpdateOrderLoading(true);
      try {
        const requestParams = {
          order_value: Math.floor(payload.order_value * 100),
          external_order_ref: payload?.external_order_ref || undefined,
          items: payload.items,
          tip: Math.floor(payload.tip * 100),
          store_id: payload.store_id ?? null,
          pickup_customer_email: payload.pickup_customer_email ?? null,
          pickup_address: !payload.store_id ? payload.pickup_address : "",
          pickup_unit: !payload.store_id ? payload.pickup_unit : "",
          pickup_phone_number: !payload.store_id ? payload.pickup_phone_number : "",
          pickup_name: !payload.store_id ? constructName(payload.pickup_first_name, payload.pcikup_last_name) : "",
          pickup_at: payload.schedule_type !== "schedule_dropoff" && payload.eta ? payload.eta : "",
          pickup_notes: !payload.store_id ? payload.pickup_notes : "",
          dropoff_phone_number: payload.dropoff_phone_number ?? "",
          dropoff_address: payload.dropoff_address ?? "",
          dropoff_unit: payload.dropoff_unit ?? "",
          dropoff_name: constructName(payload.dropoff_first_name, payload.dropoff_last_name) ?? "",
          dropoff_at: payload.schedule_type === "schedule_dropoff" && payload.eta ? payload.eta : "",
          dropoff_notes: payload.dropoff_notes ?? "",
          dropoff_customer_email: payload?.dropoff_customer_email ?? "",
          // customer_email: payload.is_customer_email_saved
          //   ? payload?.customer_email
          //   : "",
        };

        const { data } = await updateOrder(accountId, orderId, { test_mode: testMode }, requestParams);
        toast.success("Order has been saved successfully");
        callback(data?.data.id);
        return data.data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsUpdateOrderLoading(false);
      }
    };

    const [isOrderQuotesLoading, setIsOrderQuotesLoading] = useState(false);
    const [orderQuotes, setOrderQuotes] = useState([]);
    const [unavailableProviderInfo, setUnavailableProviderInfo] = useState([]);

    const getOrderQuotesWorker = async (accountId, orderId, testMode, requestParams) => {
      setIsOrderQuotesLoading(true);
      try {
        const queryParams = { test_mode: testMode };

        const { data } = await getOrderQuotes(accountId, orderId, queryParams, requestParams);
        setOrderQuotes(data.quotes);
        setUnavailableProviderInfo(data.unavailable_providers_info);
        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsOrderQuotesLoading(false);
      }
    };

    const [isAcceptQuotesLoading, setIsAcceptQuotesLoading] = useState(false);

    const acceptOrderQuotesWorker = async (accountId, quoteId, testMode, payload, storeId, callback) => {
      setIsAcceptQuotesLoading(true);
      try {
        const providerSettings = {
          signature: !!payload.provider_settings.signature,
          contactless: !!payload.provider_settings.contactless,
        };
        if (payload.provider_settings.undeliverable_action) {
          providerSettings.undeliverable_action = payload.provider_settings.undeliverable_action;
        }
        if (payload.provider_settings.age_verification) {
          providerSettings.recipient_minimum_age = payload.provider_settings.recipient_minimum_age;
        }

        const queryParams = { test_mode: testMode };
        const requestParams = { required_provider_settings: providerSettings };

        const { data } = await acceptQuote(accountId, quoteId, queryParams, requestParams);
        toast.success("Delivery is created successfully.");
        callback();
        return data.data;
      } catch (error) {
        if (error?.response?.data?.message) {
          if (error.response.data.message === "Store does not have a payment method associated to it.") {
            setTimeout(() => {
              window.location.href = `/v1/stores/${storeId}?active=paymentMethods`;
            }, 3000);
          }
          toast.error(
            error.response.data.message === "Store does not have a payment method associated to it."
              ? "Please select a default payment method for the store to create the delivery using this store."
              : error?.response?.data?.message
          );
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsAcceptQuotesLoading(false);
      }
    };

    const [isCancelOrderLoading, setIsCancelOrderLoading] = useState(false);

    const cancelOrderWorker = async (accountId, orderId, testMode, callback) => {
      setIsCancelOrderLoading(true);
      try {
        const queryParams = { test_mode: testMode };
        const { data } = await cancelOrder(accountId, orderId, queryParams);
        toast.success("Order has been canceled successfully");
        callback();
        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsCancelOrderLoading(false);
      }
    };

    const [isDeleteOrderLoading, setIsDeleteOrderLoading] = useState(false);

    const deleteOrderWorker = async (accountId, orderId, testMode, callback) => {
      setIsDeleteOrderLoading(true);
      try {
        const queryParams = { test_mode: testMode };
        const { data } = await deleteOrder(accountId, orderId, queryParams);
        toast.success("Order has been deleted successfully");
        callback();
        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsDeleteOrderLoading(false);
      }
    };

    const [selectedVehicle, setSelectedVehicle] = useState(null);

    const resetFilters = () => {
      setSearchByPickupId();
      setSearchByPickupAddress();
      setSearchByPickupName();
      setSearchByPickupPhoneNumber();
      setSearchByDropoffAddress();
      setSearchByDropoffName();
      setSearchByDropoffPhoneNumber();
      setSearchByOrderId();
      setFormatedDateRange();
      setFilterByStatus([]);
    };

    return {
      orders,
      isOrdersLoading,
      getOrdersWorker,

      order,
      setOrder,
      getOrderWorker,
      isOrderLoading,

      searchByPickupId,
      setSearchByPickupId,
      searchByPickupAddress,
      setSearchByPickupAddress,
      searchByPickupName,
      setSearchByPickupName,
      searchByPickupPhoneNumber,
      setSearchByPickupPhoneNumber,
      searchByDropoffAddress,
      setSearchByDropoffAddress,
      searchByDropoffName,
      setSearchByDropoffName,
      searchByDropoffPhoneNumber,
      setSearchByDropoffPhoneNumber,
      searchByOrderId,
      setSearchByOrderId,
      formatedDateRange,
      setFormatedDateRange,
      filterByStatus,
      setFilterByStatus,
      resetFilters,
      isCreateNoteLoading,
      createOrderNoteWorker,

      filterByExternalOrderId,
      setFilterByExternalOrderId,

      isCreateOrderLoading,
      createOrderWorker,

      isUpdateOrderLoading,
      updateOrderWorker,

      isOrderQuotesLoading,
      orderQuotes,
      unavailableProviderInfo,
      setOrderQuotes,
      getOrderQuotesWorker,

      isAcceptQuotesLoading,
      acceptOrderQuotesWorker,

      selectedVehicle,
      setSelectedVehicle,

      isCancelOrderLoading,
      cancelOrderWorker,

      isDeleteOrderLoading,
      deleteOrderWorker,

      filterByStore,
      setFilterByStore,

      duplicateOrderData,
      setDuplicateOrderData,

      activeStatus,
      setActiveStatus,
    };
  },
});

export default useOrders;
