export const industries = [
  { front: "Food", back: "food" },
  { front: "Catering", back: "catering" },
  { front: "Grocery", back: "grocery" },
  { front: "Flowers", back: "flowers" },
  { front: "Laundry / Dry Cleaning", back: "laundry_dry_cleaning" },
  { front: "Pharmacy", back: "pharmacy" },
  { front: "Ecommerce / Parcel", back: "ecommerce_parcel" },
  { front: "Auto Parts", back: "auto_parts" },
  { front: "Vape / Tobacco", back: "vape_tobacco" },
  { front: "Alcohol", back: "alcohol" },
  { front: "Cannabis / CBD", back: "cannabis_cbd" },
];
