import React, { useState } from "react";
import styles from "./styles.module.css"; // Import the CSS module
import classNames from "classnames";
import { RiArrowDownSLine } from "react-icons/ri";

const DisclosureComp = ({
  title = "Click to expand!",
  children,
  containerClasses,
  titleClasses,
  onTitleClick = null,
  defaultOpened = false,
  extraArrowClasses,
  isExpandDisabled = false,
}) => {
  const [isContentVisible, setIsContentVisible] = useState(defaultOpened);

  const toggleVisibility = () => {
    if (isExpandDisabled) {
      return;
    }
    setIsContentVisible(!isContentVisible);
    if (onTitleClick) {
      onTitleClick();
    }
  };

  return (
    <div className={classNames(styles.disclosureContainer, containerClasses)}>
      <div
        onClick={toggleVisibility}
        className="flex items-center w-full justify-between cursor-pointer"
      >
        <div className={classNames(styles.disclosureTitle, titleClasses)}>
          {title || "Click to expand!"}
        </div>
        {!isExpandDisabled && (
          <div>
            <RiArrowDownSLine
              size={20}
              className={classNames(styles.arrow, extraArrowClasses, {
                [styles.rotate]: isContentVisible,
              })}
            />
          </div>
        )}
      </div>
      <div
        className={`${styles.disclosureContent} ${
          isContentVisible ? styles.disclosureContentVisible : ""
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default DisclosureComp;
