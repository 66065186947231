import React, { useState, useMemo, useEffect } from "react";
import moment from "moment";
import { DropDown } from "../../assets/svgs/Svgs";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import { useTheme } from "../../store";
import { getPreviousMonthDate } from "../../utils/helpers";
import OutsideClickHandler from "react-outside-click-handler";
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker, {
  Calendar,
  utils,
} from "@hassanmojab/react-modern-calendar-datepicker";

const DatePickerComp = ({
  placeholder = "Select Date",
  minDate,
  range,
  setRange,
  disabled = false,
  defaultValue,
}) => {
  const { isdesktop, mdfontsize } = useDesktopOrLaptop();
  const { Colors } = useTheme();
  const styles = useMemo(() => {
    return {
      datePickerContainer: {
        display: "flex",
        border: `1px solid ${Colors.borderSecondary}`,
        alignItems: "center",
        borderRadius: "8px",
        height: "40px",
        width: "300px",
        position: "relative",
      },
      datePicker: {
        width: "100%",
        fontWeight: 400,
        fontSize: mdfontsize,
        lineHeight: "24px",
        color: Colors.fontDark,
        backgroundColor: Colors.bgDarkSecondary,
        margin: "0px 20px",
        outline: "none",
        padding: "6px 10px",
        borderRadius: "10px",
      },
    };
  }, [Colors, isdesktop]);

  const [selectDate, setSelectDate] = useState(null);
  const [selectedDayRange, setSelectedDayRange] = useState({
    from: null,
    to: null,
  });
  const [openCalender, setOpenCalender] = useState(false);
  const [rangeInput, setRangeInput] = useState("");

  const onCalenderClick = () => {
    setOpenCalender(!openCalender);
  };
  const onCalenderClickClose = () => {
    setOpenCalender(false);
  };

  const renderCustomInput = ({ ref }) => (
    <div style={styles.datePickerContainer}>
      <input
        readOnly
        ref={ref} // necessary
        placeholder={placeholder}
        value={
          selectDate
            ? `${moment(
                `${selectDate.day}-${selectDate.month}-${selectDate.year}`,
                "DD-MM-YYYY"
              ).format("DD/MM/YYYY")}`
            : ""
        }
        style={styles.datePicker}
      />
      <DropDown />
    </div>
  );

  useEffect(() => {
    if (defaultValue) {
      setSelectedDayRange(defaultValue);
      setOpenCalender(false);
    }
  }, []);

  useEffect(() => {
    if (selectedDayRange.from && selectedDayRange.to) {
      setRageValue(selectedDayRange);
    }
  }, [selectedDayRange]);

  const setRageValue = (value) => {
    const from = moment(
      `${value.from.day}-${value.from.month}-${value.from.year}`,
      "DD-MM-YYYY"
    ).format("MMM D, YYYY");
    const to = moment(
      `${value.to.day}-${value.to.month}-${value.to.year}`,
      "DD-MM-YYYY"
    ).format("MMM D, YYYY");

    setRangeInput(from + " to " + to);
    const rangeObject = {
      start_date: from,
      end_date: to,
    };

    setRange(rangeObject);
    onCalenderClickClose();
  };

  if (range) {
    return (
      <OutsideClickHandler
        onOutsideClick={() => {
          setOpenCalender(false);
        }}
      >
        <div style={{ ...styles.datePickerContainer }}>
          <input
            placeholder={placeholder}
            value={rangeInput}
            readOnly
            style={{
              ...styles.datePicker,
              cursor: "default",
              caretColor: "transparent",
            }}
            onClick={onCalenderClick}
            disabled={disabled}
          />

          {openCalender && (
            <div
              style={{
                position: "absolute",
                top: "105%",
              }}
            >
              <Calendar
                value={selectedDayRange}
                onChange={setSelectedDayRange}
                colorPrimary="#0062FF"
                colorPrimaryLight="rgba(0, 98, 255,0.2)"
                maximumDate={utils().getToday()}
                minimumDate={
                  minDate && getPreviousMonthDate(minDate).monthStartObject
                }
              />
            </div>
          )}
        </div>
      </OutsideClickHandler>
    );
  } else {
    return (
      <DatePicker
        minimumDate={minDate ? minDate : undefined}
        value={selectDate}
        onChange={setSelectDate}
        renderInput={renderCustomInput}
        colorPrimary="#0062FF"
      />
    );
  }
};

export default DatePickerComp;
