import React from "react";
import OutsideClickHandler from "react-outside-click-handler";
import classNames from "classnames";
import { useMyStore, useTheme } from "../../../../store";
import Divider from "../../../shared/atoms/Divider";
import styles from "../../styles/ProviderList/ProviderCard/actionMenu.module.css";

const ActionMenu = ({
  provider,
  setDotsMenuVisible,
  accountProviderSettingUpdateHandler,
  refElement,
}) => {
  const { themeChanger } = useTheme();
  const { isSandbox } = useMyStore();

  const aps = provider?.account_provider_setting;
  const initial_payload = {
    is_merchant_preferred: aps?.is_merchant_preferred,
    is_test_mode_enabled: aps?.is_test_mode_enabled,
    is_live_mode_enabled: aps?.is_live_mode_enabled,
  };

  return (
    <OutsideClickHandler
      onOutsideClick={(e) => {
        e.preventDefault();
        if (!refElement.current.contains(e.target)) {
          setDotsMenuVisible(false);
        }
      }}
    >
      <div className={styles.container}>
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          className={classNames(
            `focus:outline-none px-4 py-2.5 cursor-default`,
            styles.actionItem
          )}
        >
          Actions
        </div>
        <Divider />
        {/* <button
          onClick={() => {
            setDotsMenuVisible(false);
            accountProviderSettingUpdateHandler({
              ...initial_payload,
              is_merchant_preferred: !aps.is_merchant_preferred,
            });
          }}
          className={classNames(
            `focus:outline-none px-4 py-2.5 ${
              themeChanger ? "hover:bg-gray-200 " : ""
            }`,
            styles.actionItem
          )}
        >
          {aps?.is_merchant_preferred
            ? "Remove as Preferred"
            : "Set as Preferred"}
        </button> */}
        <button
          onClick={() => {
            const prop = isSandbox
              ? "is_test_mode_enabled"
              : "is_live_mode_enabled";
            setDotsMenuVisible(false);
            accountProviderSettingUpdateHandler({
              ...initial_payload,
              [prop]: !aps[prop],
            });
          }}
          className={classNames(
            `focus:outline-none px-4 py-2.5 ${
              themeChanger ? "hover:bg-gray-200 " : ""
            }`,
            styles.actionItem
          )}
        >
          {provider?.is_enabled ? "Disable" : "Enable"}
        </button>
      </div>
    </OutsideClickHandler>
  );
};

export default ActionMenu;
