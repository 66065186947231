import React, { useMemo } from "react";
import { useAnalyticsStore, useTheme } from "../../../../store";

const tabs = [
  { title: "24 hours", value: 4 },
  { title: "7 days", value: 3 },
  { title: "30 days", value: 2 },
  { title: "12 months", value: 1 },
];

const HeaderTabs = () => {
  const { Colors } = useTheme();
  const { overviewPeriod, setOverviewPeriod } = useAnalyticsStore();
  const styles = useMemo(() => {
    return {
      container: {
        display: "flex",
        fontFamily: "Poppins",
        alignItems: "center",
        justifyContent: "center",
        height: "50px",
      },
      button: {
        fontFamily: "Poppins",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "150%",
        padding: "0 10px",
        borderRadius: "10%",
        height: "100%",
        color: Colors.chartSecondLine,
        paddingBottom: "4px",
      },
      buttonSelected: {
        color: Colors.fontBlack,
        paddingBottom: 0,
      },
    };
  }, [Colors]);

  const renderTabs = (title, value) => {
    return (
      <div
        key={title}
        style={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        <button
          style={
            overviewPeriod === value
              ? { ...styles.button, ...styles.buttonSelected }
              : { ...styles.button }
          }
          onClick={() => setOverviewPeriod(value)}
        >
          {title}
        </button>
        {overviewPeriod === value && (
          <div style={{ borderTop: "4px solid black", borderRadius: "20%" }} />
        )}
      </div>
    );
  };

  return (
    <div style={styles.container}>
      {tabs.map((tab) => renderTabs(tab.title, tab.value))}
    </div>
  );
};

export default HeaderTabs;
