import React from "react";
import CustomFullScreenModal from "../../../CustomModal/CustomFullScreenModal";

import classNames from "classnames";
import styles from "./styles.module.css";
import { IoClose } from "react-icons/io5";
import { FiDownload } from "react-icons/fi";

export const ImagePreviewPopup = ({ imageUrl, isOpen, onClose }) => {
  return (
    <>
      <CustomFullScreenModal
        extraClasses={classNames(
          "w-screen h-screen flex items-center justify-between",
          styles.container
        )}
        isOpen={isOpen}
        onClose={onClose}
      >
        <div
          className={`h-full w-full py-1 flex justify-center items-center  relative`}
        >
          <div className="absolute top-6 right-6 flex justify-center items-center">
            <div
              className={classNames(" mr-4 p-4 cursor-pointer", styles.close)}
            >
              <a href={imageUrl} target="_blank" rel="noreferrer">
                <FiDownload size={38} color="white" />{" "}
              </a>
            </div>
            <div
              onClick={onClose}
              className={classNames(
                "justify-center items-center p-4 cursor-pointer ",
                styles.close
              )}
            >
              <IoClose size={38} color="white" />
            </div>
          </div>

          <img
            className="max-h-full overflow-hidden object-cover"
            src={imageUrl}
            alt=""
          />
        </div>
      </CustomFullScreenModal>
    </>
  );
};
