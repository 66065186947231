import React from "react";
import { useTheme } from "../../../../store";
import ToolTip from "../../../Tooltip/Tooltip";

function SingleItemForModalNew({ name, quantity, size, index, length }) {
  const { Colors } = useTheme();

  return (
    <div
      style={{
        paddingBottom: index === length - 1 ? "20px" : "10px",
        marginBottom: index === length - 1 ? "0px" : "10px",
        width: "100%",
        marginTop: "4px",
        borderBottomWidth: "1px",
        borderColor: Colors.borderPrimary,
      }}
      className="flex justify-between items-start"
    >
      <div
        style={{
          ...Styles.categoryText,
          ...{ color: Colors.fontDark },
        }}
      >
        {index + 1}
      </div>
      <div style={{ ...Styles.categoryText, ...{ color: Colors.fontDark } }} className="truncate" data-tip
        data-for={`modal-responsive-item-name-${index}`}>
        {name}
      </div>

      <div style={{ ...Styles.categoryText, ...{ color: Colors.fontDark } }}>
        {quantity}
      </div>

      <div
        style={{
          ...Styles.categoryText,
          ...{ color: Colors.fontDark, textTransform: "capitalize" },
        }}
      >
        {size === "xlarge" ? "Extra Large" : size}
      </div>
      <ToolTip id={`modal-responsive-item-name-${index}`} disable={name.length > 18 ? false : true} text={name} />
    </div>
  );
}
const Styles = {
  categoryText: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "400",
    width: "25%",
  },
};

export default SingleItemForModalNew;
