import React from "react";
import { useTheme } from "../../../../../../store";
import { numberWithCommas } from "../../../../../Analytics/utils";
import classNames from "classnames";
import styles from "./styles.module.css";

const IncidentDetails = ({ item }) => {
  const { Colors } = useTheme();

  return (
    <div
      className={classNames(
        styles.container,
        "w-full flex items-center justify-between"
      )}
    >
      {item?.delivery_incident?.status === "resolved" ? (
        <div className="flex flex-col w-full">
          <div>Burq has reviewed this delivery.</div>

          <div className={classNames(styles.refundedTitles, "mt-4")}>
            Delivery fee refunded:{" "}
            <span
              className={styles.refundedStatus}
              style={{
                color: item?.delivery_incident?.delivery_fee_refunded
                  ? Colors.greenDark
                  : Colors.alertRed,
              }}
            >
              {item?.delivery_incident?.delivery_fee_refunded ? "Yes" : "No"}
            </span>
          </div>
          <div className={styles.refundedTitles}>
            Tip refunded:{" "}
            <span
              className={styles.refundedStatus}
              style={{
                color: item?.delivery_incident?.tip_refunded
                  ? Colors.greenDark
                  : Colors.alertRed,
              }}
            >
              {item?.delivery_incident?.tip_refunded ? "Yes" : "No"}
            </span>
          </div>
          {item?.delivery_incident?.order_value_refunded_amount > 0 && (
            <div className={styles.refundedTitles}>
              Additional refund{" "}
              <span className={styles.refundedStatus}>
                $
                {numberWithCommas(
                  (
                    item?.delivery_incident?.order_value_refunded_amount / 100
                  )?.toFixed(2)
                )}
              </span>
            </div>
          )}
        </div>
      ) : (
        <div>Burq is reviewing this delivery for refund.</div>
      )}
    </div>
  );
};

export default IncidentDetails;
