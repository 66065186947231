import { createStore } from "aniuta";
import { useState } from "react";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";
import { getDisputedList, getHomeDeliveriesList } from "../utils/api/deliveries";
import { FILTER_TYPES } from "../constants";
import { homeStatuses } from "../components/Home/utils";
import axios from "axios";

const useHomeDeliveries = createStore({
  name: "useHomeDeliveries",
  Store: () => {
    const [homeDeliveriesData, setHomeDeliveriesData] = useState();
    const [isHomeDeliveriesLoading, setIsHomeDeliveriesLoading] = useState(false);
    const [statusesFilter, setStatusesFilter] = useState(homeStatuses);

    const [dateRangeForHomeDeliveries, setDateRangeForHomeDeliveries] = useState();

    const [storesFilteredArray, setStoresFilteredArray] = useState([]);

    const [searchById, setSearchById] = useState("");
    const [searchByPickupAddress, setSearchByPickupAddress] = useState("");
    const [searchByPickupName, setSearchByPickupName] = useState("");
    const [searchByPickupPhoneNumber, setSearchByPickupPhoneNumber] = useState("");
    const [searchByDropoffName, setSearchByDropoffName] = useState("");
    const [searchByDropoffPhoneNumber, setSearchByDropoffPhoneNumber] = useState("");
    const [searchByDropoffAddress, setSearchByDropoffAddress] = useState("");
    const [searchByProviderId, setSearchByProviderId] = useState("");
    const [searchByBurqOrderId, setSearchByBurqOrderId] = useState("");

    const statusesHandler = (statusesArray) => {
      const newArray = [];
      statusesArray.forEach((oneStatusObj) => {
        if (oneStatusObj.checked) {
          newArray.push(oneStatusObj.back);
        }
      });

      return newArray;
    };

    const homeDeliveriesHandler = async (id, params) => {
      setIsHomeDeliveriesLoading(true);
      const queryParams = {
        ending_before: params?.ending_before ?? undefined,
        starting_after: params?.starting_after ?? undefined,
        test_mode: params?.test_mode ?? undefined,
        search_by_delivery_id: params?.search_by_delivery_id ?? searchById,
        search_by_pickup_address: params?.search_by_pickup_address ?? searchByPickupAddress,
        search_by_pickup_name: params?.search_by_pickup_name ?? searchByPickupName,
        search_by_pickup_phone_number: params?.search_by_pickup_phone_number ?? searchByPickupPhoneNumber,
        search_by_dropoff_name: params?.search_by_dropoff_name ?? searchByDropoffName,
        search_by_dropoff_phone_number: params?.search_by_dropoff_phone_number ?? searchByDropoffPhoneNumber,
        search_by_dropoff_address: params?.search_by_dropoff_address ?? searchByDropoffAddress,
        search_by_provider_name: params?.search_by_provider_name ?? searchByProviderId,
        // search_by_provider_id: params?.search_by_provider_id ?? searchByProviderId,
        search_by_burq_order_id: params?.search_by_burq_order_id ?? searchByBurqOrderId,
        store_ids: params?.store_ids?.map((item) => item.id) ?? storesFilteredArray?.map((item) => item.id),
        statuses: statusesHandler(params?.status ?? statusesFilter),
        start_date:
          params?.start_date !== undefined && params?.start_date !== null ? params.start_date : dateRangeForHomeDeliveries?.start,
        end_date: params?.end_date !== undefined && params?.end_date !== null ? params.end_date : dateRangeForHomeDeliveries?.end,

        // ...params,
      };
      try {
        const { data } = await getHomeDeliveriesList(id, queryParams);
        if (params?.ending_before) {
          if (data?.items?.length < 1) {
            return "disablePrevious";
          }
        }

        setHomeDeliveriesData(data);
        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsHomeDeliveriesLoading(false);
      }
    };

    const homeFiltersDefinitions = [
      {
        id: "search_by_delivery_id",
        title: "By Delivery Id",
        type: FILTER_TYPES.SEARCH,
        placeholder: "Delivery Id",
        filterState: searchById,
        // filterState: searchById,
        setFilterState: (id, merchantId, test_mode) => {
          homeDeliveriesHandler(merchantId, { search_by_delivery_id: id, test_mode: test_mode });
          setSearchById(id);
        },
      },
      {
        id: "search_by_pickup_address",
        title: "By Pick-up Address",
        type: FILTER_TYPES.SEARCH,
        placeholder: "Pick-up Address",
        filterState: searchByPickupAddress,
        // filterState: searchByAddress,
        setFilterState: (id, merchantId, test_mode) => {
          homeDeliveriesHandler(merchantId, { search_by_pickup_address: id, test_mode: test_mode });
          setSearchByPickupAddress(id);
        },
      },
      // {
      //   id: "search_by_pickup_name",
      //   title: "by Pick-up Name",
      //   type: FILTER_TYPES.SEARCH,
      //   placeholder: "Pick-up Name",
      //   filterState: searchByPickupName,
      //   // filterState: searchByName,
      //   setFilterState: (id, merchantId, test_mode) => {
      //     homeDeliveriesHandler(merchantId, { search_by_pickup_name: id, test_mode: test_mode });
      //     setSearchByPickupName(id);
      //   },
      // },

      {
        id: "search_by_pickup_phone_number",
        title: "By Pick-up Phone Number",
        type: FILTER_TYPES.SEARCH,
        placeholder: "Pick-up Phone",
        filterState: searchByPickupPhoneNumber,
        // filterState: searchByPhoneNumber,
        setFilterState: (id, merchantId, test_mode) => {
          homeDeliveriesHandler(merchantId, { search_by_pickup_phone_number: id, test_mode: test_mode });
          setSearchByPickupPhoneNumber(id);
        },
      },
      {
        id: "search_by_dropoff_name",
        title: "By Dropoff Name",
        type: FILTER_TYPES.SEARCH,
        placeholder: "Dropoff Name",
        filterState: searchByDropoffName,
        // filterState: searchByDroppofName,
        setFilterState: (id, merchantId, test_mode) => {
          homeDeliveriesHandler(merchantId, { search_by_dropoff_name: id, test_mode: test_mode });
          setSearchByDropoffName(id);
        },
      },
      {
        id: "search_by_dropoff_phone_number",
        title: "By Dropoff Phone Number",
        type: FILTER_TYPES.SEARCH,
        placeholder: "Dropoff Phone Number",
        filterState: searchByDropoffPhoneNumber,
        // filterState: searchByDropoffPhoneNum,
        setFilterState: (id, merchantId, test_mode) => {
          homeDeliveriesHandler(merchantId, { search_by_dropoff_phone_number: id, test_mode: test_mode });
          setSearchByDropoffPhoneNumber(id);
        },
      },
      {
        id: "search_by_dropoff_address",
        title: "By Dropoff Address",
        type: FILTER_TYPES.SEARCH,
        placeholder: "Dropoff Address",
        filterState: searchByDropoffAddress,
        // filterState: searchByDropoffAddress,
        setFilterState: (id, merchantId, test_mode) => {
          homeDeliveriesHandler(merchantId, { search_by_dropoff_address: id, test_mode: test_mode });
          setSearchByDropoffAddress(id);
        },
      },
      {
        id: "search_by_provider_name",
        title: "By Provider Name",
        type: FILTER_TYPES.SEARCH,
        placeholder: "Provider Name",
        filterState: searchByProviderId,
        // filterState: searchByProviderId,
        setFilterState: (id, merchantId, test_mode) => {
          homeDeliveriesHandler(merchantId, { search_by_provider_name: id, test_mode: test_mode });
          setSearchByProviderId(id);
        },
      },
      // {
      //   id: "search_by_burq_order_id",
      //   title: "By Burq Order Id",
      //   type: FILTER_TYPES.SEARCH,
      //   placeholder: "Burq order id",
      //   filterState: searchByBurqOrderId,
      //   setFilterState: (id, merchantId, test_mode) => {
      //     homeDeliveriesHandler(merchantId, { search_by_burq_order_id: id, test_mode: test_mode });
      //     setSearchByBurqOrderId(id);
      //   },
      // },
    ];

    return {
      homeDeliveriesData,
      setHomeDeliveriesData,
      isHomeDeliveriesLoading,
      setIsHomeDeliveriesLoading,

      statusesFilter,
      setStatusesFilter,

      dateRangeForHomeDeliveries,
      setDateRangeForHomeDeliveries,

      searchById,
      setSearchById,
      searchByPickupAddress,
      setSearchByPickupAddress,
      searchByPickupName,
      setSearchByPickupName,
      searchByPickupPhoneNumber,
      setSearchByPickupPhoneNumber,
      searchByDropoffName,
      setSearchByDropoffName,
      searchByDropoffPhoneNumber,
      setSearchByDropoffPhoneNumber,
      searchByDropoffAddress,
      setSearchByDropoffAddress,
      searchByProviderId,
      setSearchByProviderId,
      searchByBurqOrderId,
      setSearchByBurqOrderId,
      homeDeliveriesHandler,
      homeFiltersDefinitions,

      storesFilteredArray,
      setStoresFilteredArray,
    };
  },
});

export default useHomeDeliveries;
