import React, { memo } from "react";
import DetailsOnTracking from "../../../TrackingPage/DetailsOnTracking";
import { DUMMY_DELIVERY } from "../BrandingActionSection/helper";

const DetailsMemo = memo(
  ({ iconUploadedUrl, merchantData, brandingUploadedUrl, brandingColor }) => {
    return (
      <div
        style={{
          width: "45%",
          height: "100%",
          maxWidth: "600px",
          backgroundColor: "white",
        }}
        className=""
      >
        <DetailsOnTracking
          brandingColor={brandingColor}
          logo={
            iconUploadedUrl?.image
              ? iconUploadedUrl?.image
              : merchantData?.branding_settings?.icon_image_url
              ? merchantData?.branding_settings?.icon_image_url +
                "?" +
                Date.now()
              : undefined
          }
          branding={
            brandingUploadedUrl?.image
              ? brandingUploadedUrl?.image
              : merchantData?.branding_settings?.branding_image_url
              ? merchantData?.branding_settings?.branding_image_url +
                "?" +
                Date.now()
              : null
          }
          deliveryInfo={DUMMY_DELIVERY}
          isOnBranding
        />
      </div>
    );
  }
);

export default DetailsMemo;
