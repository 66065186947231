import React from "react";
// import Colors from "../assets/Colors";
import { useTheme } from "../store";

function SingleAddressForModal({
  index,
  address,
  name,
  bussinessNmae,
  phoneNumber,
  note,
  length,
  smfontsize,
  mdfontsize,
  unit,
}) {
  const { Colors } = useTheme();
  const styles = {
    circle: {
      position: "absolute",
      height: "14px",
      width: "14px",
      borderRadius: "100%",
      top: "0px",
      left: "0px",
    },
    pickupDropoff: {
      fontFamily: "Poppins",
      fontSize: "16px",
      color: Colors.fontGreyLight,
      fontWeight: "500",
      marginBottom: "2px",
      marginTop: "0px",
    },
    singleAddress: {
      position: "relative",
      minHeight: "33%",
      overflow: "hidden",
      padding: "0px 0px 0px 30px",
    },
    addresses: {
      fontFamily: "Poppins",
      fontSize: "16px",
      color: Colors.fontDark,
      fontWeight: "500",
    },
  };

  return (
    <div>
      <div style={styles.singleAddress}>
        {index !== length && (
          <div
            style={{
              width: "1px",
              height: "100%",
              backgroundColor: Colors.fontDark,
              position: "absolute",
              top: "0px",
              left: "6px",
            }}
          ></div>
        )}

        <div
          style={{
            ...styles.circle,
            ...{
              backgroundColor:
                index !== 0 ? Colors.greenDark : Colors.yellowDark,
            },
          }}
        ></div>
        <div style={styles.pickupDropoff}>
          {index === 0 ? "Pickup:" : `Stop ${index}`}
        </div>

        <div style={{ ...styles.addresses, ...{ fontSize: mdfontsize } }}>
          {address}
        </div>
        <div style={styles.pickupDropoff}>Unit:</div>
        <div style={{ ...styles.addresses, ...{ fontSize: mdfontsize } }}>
          {unit}
        </div>
        <div
          style={{ paddingRight: "61px", marginTop: "20px" }}
          className="flex w-full items-center justify-between"
        >
          <div
            style={{
              ...styles.pickupDropoff,
              ...{ textAlign: "start", width: "33%", fontSize: mdfontsize },
            }}
          >
            Name
          </div>
          {/* <div
            style={{
              ...styles.pickupDropoff,
              ...{ textAlign: "start", width: "33%", fontSize: mdfontsize },
            }}
          >
            Business Name
          </div> */}
          <div
            style={{
              ...styles.pickupDropoff,
              ...{ textAlign: "start", width: "33%", fontSize: mdfontsize },
            }}
          >
            Phone Number
          </div>
        </div>
        <div
          style={{ paddingRight: "61px", marginTop: "0px" }}
          className="flex w-full items-center justify-between"
        >
          <div
            className="elipsis"
            style={{
              ...styles.addresses,
              ...{
                textAlign: "start",
                width: "54%",
                fontSize: mdfontsize,
              },
            }}
          >
            {name}
          </div>
          {/* <div
            style={{
              ...styles.addresses,
              ...{ textAlign: "start", width: "33%", fontSize: mdfontsize },
            }}
          >
            {bussinessNmae}
          </div> */}
          <div
            style={{
              ...styles.addresses,
              ...{ textAlign: "start", width: "33%", fontSize: mdfontsize },
            }}
          >
            +{phoneNumber}
          </div>
        </div>
        {note ? (
          <>
            <div
              style={{
                ...styles.pickupDropoff,
                ...{ marginTop: "20px", fontSize: mdfontsize },
              }}
            >
              Note:
            </div>

            <div
              className="noScroll"
              style={{
                minHeight: "80px",
                width: "90%",
                backgroundColor: Colors.bgGrey,
                overflowY: "scroll",
                borderRadius: "8px",
                padding: "10px",

                marginBottom: "10px",
              }}
            >
              <div style={{ ...styles.addresses, ...{ fontSize: mdfontsize } }}>
                {note}
              </div>
            </div>
          </>
        ) : (
          <div style={{ marginTop: "40px" }} />
        )}
      </div>
    </div>
  );
}

export default SingleAddressForModal;
