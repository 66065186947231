import React from "react";
import { useMerchant, useTheme } from "../../../../store";
import moment from "moment";
import momenttz from "moment-timezone";
import { useFormikContext } from "formik";
import classNames from "classnames";
import Badge from "../../../../components/shared/atoms/Badge";
import UndeliverableActions from "./UndeliverableActions";
import DeliveryPreferences from "./DeliveryPreferences";
import styles from "./styles/styles.module.css";
import { costLineItemDisplayNames } from "../../../../constants";
import { PARTNER_INDUSTRY_OPTIONS } from "../../../../components/ProviderIntegrationSettings/utils";
import AgeVerification from "./AgeVerification";
import { useFlag } from "@unleash/proxy-client-react";

const OneProvider = ({ quote, isNoQuote = false, setSelectedQuote, rerouteItem, index, length, fastestQuote }) => {
  const isNoDeliveryPreferenceDisabled = useFlag("isNoDeliveryPreferenceDisabled");

  const { Colors } = useTheme();
  const { values, setFieldValue } = useFormikContext();
  const { merchantData } = useMerchant();

  const resetProviderSettings = (quote) => {
    const shouldAllowSignatureOnly =
      merchantData.standardized_industry === PARTNER_INDUSTRY_OPTIONS.pharmacy.toLowerCase() || values.contains_alcohol;
    const contactless = isNoDeliveryPreferenceDisabled && quote.provider_capability.contactless && !shouldAllowSignatureOnly;
    setFieldValue("provider_settings.signature", shouldAllowSignatureOnly);
    setFieldValue("provider_settings.contactless", contactless);
    setFieldValue("provider_settings.recipient_minimum_age", 18);
    setFieldValue("provider_settings.age_verification", false);
    if (
      shouldAllowSignatureOnly &&
      Array.isArray(quote?.provider_capability?.undeliverable_actions) &&
      quote.provider_capability.undeliverable_actions?.includes("return_to_store")
    ) {
      setFieldValue("provider_settings.undeliverable_action", "return_to_store");
    } else {
      setFieldValue("provider_settings.undeliverable_action", null);
    }
  };

  const getQuoteLabels = (quote) => {
    return (
      <div style={{ display: "flex", gap: 10, marginTop: "4px" }}>
        {quote.default_provider && <Badge hideIcon={false} label="preferred" />}
        {fastestQuote.some((e) => e.id === quote.id) && <Badge hideIcon={false} label="fastest" type={"success"} />}
      </div>
    );
  };

  if (isNoQuote) {
    return (
      <div
        key={quote.id}
        style={{
          borderBottomWidth: length - 1 === index ? "0px" : "2px",
        }}
        className={classNames("w-full flex items-center justify-between text-left", styles.container)}
      >
        <div className=" flex items-center justify-start">
          {quote?.provider_logo_url && (
            <div className="rounded-full h-14 w-14 overflow-hidden">
              <img className={`object-cover h-full w-full`} src={quote.provider_logo_url} alt="" />
            </div>
          )}

          <div className="ml-5">
            <div className={classNames("capitalize", styles.providerName)}>{quote.provider_name}</div>
            <div className={classNames(styles.reasonOrTime)}> {quote.unavailable_reason}</div>
          </div>
        </div>
        <div className="flex flex-col items-end">
          <div />
        </div>
      </div>
    );
  }
  const deliveryFee = quote.byopk_cost
    ? quote.byopk_cost.delivery_cost
    : quote.cost_line_items?.find((item) => item.name === costLineItemDisplayNames.DELIVERY_FEE)?.amount ?? 0;
  return (
    <div
      key={quote.id}
      onClick={() => {
        if (values.quote_id !== quote.id) {
          setFieldValue("quote_id", quote.id);
          resetProviderSettings(quote);
          setSelectedQuote(quote);
        }
      }}
      className={classNames(
        `w-full flex flex-col items-center justify-between text-left border-b-2 ${!isNoQuote && "cursor-pointer"}`,
        styles.container
      )}
    >
      <div className={styles.quoteTitleSection}>
        <div className=" flex items-center justify-start">
          <div
            className="flex rounded-full justify-center items-center"
            style={{
              height: "24px",
              width: "24px",
              marginRight: "10px",
              backgroundColor: values.quote_id === quote.id ? Colors.bluePrimary : Colors.bgGrey,
            }}
          >
            <div
              className="rounded-full"
              style={{
                height: "8px",
                width: "8px",
                backgroundColor: "white",
              }}
            ></div>
          </div>
          {quote?.provider_logo_url && (
            <div className="rounded-full  overflow-hidden h-14 w-14">
              <img className={` h-full w-full object-cover`} src={quote.provider_logo_url} alt="" />
            </div>
          )}
          <div className={classNames("capitalize ml-2.5", styles.providerName)}>{quote.service}</div>
        </div>

        <div className={styles.fee}>${deliveryFee === 0 ? "0.00" : (deliveryFee / 100).toFixed(2)}</div>
      </div>

      <div className={styles.timeSection}>
        <div className={classNames(styles.reasonOrTime)}>
          {quote.pickup_eta && (
            <div>
              Pickup ETA: {moment(quote.pickup_eta).format("MMM D, YYYY, h:mma")}, {momenttz.tz(momenttz.tz.guess()).format("z")}
            </div>
          )}
          {quote.dropoff_eta && (
            <div>
              Dropoff ETA: {moment(quote.dropoff_eta).format("MMM D, YYYY, h:mma")},{" "}
              {momenttz.tz(momenttz.tz.guess()).format("z")}
            </div>
          )}
          {!quote.pickup_eta && !quote.dropoff_eta && quote.created && (
            <div>
              Created: {moment(quote.created).format("MMM D, YYYY, h:mma")}, {momenttz.tz(momenttz.tz.guess()).format("z")}
            </div>
          )}
          {/* {getQuoteLabels(quote)} */}
        </div>
      </div>

      <DeliveryPreferences quote={quote} />
      <UndeliverableActions quote={quote} />
      <AgeVerification quote={quote} />
    </div>
  );
};

export default OneProvider;
