import axios from "axios";
import apiUrls from "../../constants/apiUrls";
import { generateQueryParams } from "./utils";

export const getUsers = (payload) => {
  const urlWithQueryParams = generateQueryParams(apiUrls.USERS_LIST(), payload);
  return axios.get(urlWithQueryParams, {
    headers: { "x-burq-client": "client-dashboard" },
  });
};
export const getAdminDelsApi = (payload) => {
  const urlWithQueryParams = generateQueryParams(
    apiUrls.ADMIN_DELIVERIES_LIST(),
    payload
  );
  return axios.get(urlWithQueryParams, {
    headers: { "x-burq-client": "client-dashboard" },
  });
};
export const cancelAdminDeliveryApi = (payload, delId) => {
  const urlWithQueryParams = generateQueryParams(
    apiUrls.ADMIN_DELIVERIES_CANCEL(delId),
    payload
  );
  return axios.post(urlWithQueryParams, {
    headers: { "x-burq-client": "client-dashboard" },
  });
};

export const markDeliveryAsNotReroutable = (id, payload) =>
  axios.put(apiUrls.REROUTE_INFO(id), payload, {
    headers: { "x-burq-client": "client-dashboard" },
  });

export const getRerouteRecommendations = (params) => {
  const urlWithQueryParams = generateQueryParams(apiUrls.REROUTE_RECOMMENDATIONS, params);
  return axios.get(urlWithQueryParams, {
    headers: { "x-burq-client": "client-dashboard" },
  });
}

export const rerouteIntentAction = (id, payload) =>
  axios.put(apiUrls.REROUTE_INTENT_ACTION(id), payload, {
    headers: { "x-burq-client": "client-dashboard" },
  });
