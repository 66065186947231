import axios from "axios";
import apiUrls from "../../constants/apiUrls";
import { generateQueryParams } from "./utils";

export const getNotifications = (id, queryParams, source) => {
  const urlWithQueryParams = generateQueryParams(
    apiUrls.GET_NOTIFICATIONS(id),
    queryParams
  );
  return axios.get(urlWithQueryParams, {
    headers: { "x-burq-client": "client-dashboard" },
    cancelToken: source.token,
  });
};

export const resetNotificationsCount = (id) =>
  axios.put(apiUrls.RESET_NOTIFICATIONS_COUNT(id), {
    headers: { "x-burq-client": "client-dashboard" },
  });

export const readOneNotification = (id, payload) =>
  axios.put(apiUrls.READ_ONE_NOTIFICATION(id), payload, {
    headers: { "x-burq-client": "client-dashboard" },
  });

export const readAllNotifications = (id) =>
  axios.put(apiUrls.READ_ALL_NOTIFICATIONS(id), {
    headers: { "x-burq-client": "client-dashboard" },
  });
