import React, { useEffect, useState } from "react";
import Avatar from "react-avatar";

import styles from "./styles.module.css";
import {
  useAdmin,
  useCsvDeliveries,
  // useStoreAndChild,
  useTheme,
} from "../../../store";
import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";
import { Loading, SearchSvg } from "../../../assets/svgs/Svgs";
import ButtonComp from "../../ButtonComp/ButtonComp";

import Pagination from "../../shared/Pagination";

const UsersListOnCsv = ({ admin }) => {
  const { getUsersList, usersListData, isUsersListLoading } = useAdmin();
  const { selectedUserForCsv, setSelectedUserForCsv } = useCsvDeliveries();
  // const { selectedStore } = useStoreAndChild();

  const { isdesktop } = useDesktopOrLaptop();

  const { Colors } = useTheme();

  const [searchedUser, setSearchedUser] = useState();

  const usersListHandler = async (params) => {
    let paramsObj = {
      ending_before: params?.ending_before ?? "",
      starting_after: params?.starting_after ?? "",
      account_email: params?.account_email ?? "",
      store_id: !admin && (params.store_id ?? ""),
    };
    getUsersList(paramsObj);
  };

  useEffect(() => {
    usersListHandler();
    return () => {
      setSelectedUserForCsv();
    };
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.selectUserText}>Select User</div>
        <div />
      </div>
      <form className="w-full flex items-center">
        <div className={styles.searchCont}>
          <SearchSvg />
          <input
            type="text"
            placeholder="Search"
            onChange={(e) => {
              setSearchedUser(e.target.value);
            }}
            className={styles.searchInput}
          />
        </div>
        <ButtonComp
          type="submit"
          title={"Search"}
          onClick={(e) => {
            e.preventDefault();
            usersListHandler({ account_email: searchedUser });
          }}
          primary
          extraStyles={{ marginTop: "10px", marginLeft: "14px" }}
        />
        <ButtonComp
          title={"Clear"}
          onClick={async () => {
            setSelectedUserForCsv();
            usersListHandler();
          }}
          extraStyles={{
            marginTop: "10px",
            marginLeft: "14px",
            color: Colors.fontGreyLight,
          }}
        />
      </form>
      <div className={styles.listCont}>
        {isUsersListLoading && (
          <div className="flex justify-center w-full items-center my-24">
            <Loading
              color={Colors.bluePrimary}
              className="h-14 w-14 animate-spin"
            />
          </div>
        )}

        {!isUsersListLoading &&
          usersListData?.data.length > 0 &&
          usersListData?.data.map((user, index) => {
            return (
              <button
                key={user.id}
                type="button"
                onClick={() => {
                  if (selectedUserForCsv === user.id) {
                    setSelectedUserForCsv();
                  }
                  setSelectedUserForCsv(user.id);
                }}
                style={{ padding: "10px 8px", width: "80%" }}
                className="flex flex-row justify-between items-center focus:outline-none"
              >
                <div style={{ width: "70%" }} className="flex items-center">
                  <div className={styles.imageCont}>
                    {user?.image_url ? (
                      <img
                        className="h-full"
                        src={user.image_url}
                        alt="user avatar"
                      />
                    ) : (
                      <Avatar
                        name={user?.email.split("").join(" ")}
                        size={isdesktop ? 40 : 50}
                        maxInitials={2}
                      />
                    )}
                  </div>
                  <div
                    id={`usersId${user.name}`}
                    style={{
                      color:
                        selectedUserForCsv === user.id
                          ? Colors.bluePrimary
                          : Colors.fontDark,
                    }}
                    className={styles.name}
                  >
                    {user.name}
                  </div>
                </div>
                <div
                  className={styles.name}
                  style={{
                    color:
                      selectedUserForCsv === user.id
                        ? Colors.bluePrimary
                        : Colors.fontDark,
                  }}
                >
                  {user.email}
                </div>
              </button>
            );
          })}
      </div>
      <Pagination data={usersListData} onGetPaginationData={usersListHandler} />
    </div>
  );
};

export default UsersListOnCsv;
