import moment from "moment";

export const DUMMY_ROUTE = [
  {
    geocoded_waypoints: [
      {
        geocoder_status: "OK",
        place_id: "ChIJp7KWGjNZwokRMamoosmxkCo",
        types: ["establishment", "jewelry_store", "point_of_interest", "store"],
      },
      {
        geocoder_status: "OK",
        place_id: "ChIJOR2WkqVZwokRqtQ3-Q46Z98",
        types: ["premise"],
      },
    ],
    routes: [
      {
        bounds: {
          south: 40.74407,
          west: -73.99055000000001,
          north: 40.75865,
          east: -73.97812,
        },
        copyrights: "Map data ©2023 Google",
        legs: [
          {
            distance: {
              text: "1.4 mi",
              value: 2311,
            },
            duration: {
              text: "10 mins",
              value: 629,
            },
            end_address: "800 6th Ave, New York, NY 10001, USA",
            end_location: {
              lat: 40.7452415,
              lng: -73.9905464,
            },
            start_address: "1200 6th Ave, New York, NY 10036, USA",
            start_location: {
              lat: 40.7582534,
              lng: -73.98162090000001,
            },
            steps: [
              {
                distance: {
                  text: "167 ft",
                  value: 51,
                },
                duration: {
                  text: "1 min",
                  value: 13,
                },
                end_location: {
                  lat: 40.7586531,
                  lng: -73.9813286,
                },
                polyline: {
                  points: "arwwFbppbMoAy@",
                },
                start_location: {
                  lat: 40.7582534,
                  lng: -73.98162090000001,
                },
                travel_mode: "DRIVING",
                encoded_lat_lngs: "arwwFbppbMoAy@",
                path: [
                  {
                    lat: 40.758250000000004,
                    lng: -73.98162,
                  },
                  {
                    lat: 40.75865,
                    lng: -73.98133,
                  },
                ],
                lat_lngs: [
                  {
                    lat: 40.758250000000004,
                    lng: -73.98162,
                  },
                  {
                    lat: 40.75865,
                    lng: -73.98133,
                  },
                ],
                instructions:
                  "Head <b>northeast</b> on <b>6th Ave</b>/<wbr/><b>Ave of the Americas</b> toward <b>W 48th St</b>",
                maneuver: "",
                start_point: {
                  lat: 40.7582534,
                  lng: -73.98162090000001,
                },
                end_point: {
                  lat: 40.7586531,
                  lng: -73.9813286,
                },
              },
              {
                distance: {
                  text: "0.2 mi",
                  value: 310,
                },
                duration: {
                  text: "2 mins",
                  value: 96,
                },
                end_location: {
                  lat: 40.7573003,
                  lng: -73.9781055,
                },
                maneuver: "turn-right",
                polyline: {
                  points: "qtwwFhnpbM~@}CbBkFPi@HSNi@FQPk@Pi@Pg@?A",
                },
                start_location: {
                  lat: 40.7586531,
                  lng: -73.9813286,
                },
                travel_mode: "DRIVING",
                encoded_lat_lngs: "qtwwFhnpbM~@}CbBkFPi@HSNi@FQPk@Pi@Pg@?A",
                path: [
                  {
                    lat: 40.75865,
                    lng: -73.98133,
                  },
                  {
                    lat: 40.75833,
                    lng: -73.98054,
                  },
                  {
                    lat: 40.757830000000006,
                    lng: -73.97936,
                  },
                  {
                    lat: 40.757740000000005,
                    lng: -73.97915,
                  },
                  {
                    lat: 40.757690000000004,
                    lng: -73.97905,
                  },
                  {
                    lat: 40.75761000000001,
                    lng: -73.97884,
                  },
                  {
                    lat: 40.75757,
                    lng: -73.97875,
                  },
                  {
                    lat: 40.75748,
                    lng: -73.97853,
                  },
                  {
                    lat: 40.75739,
                    lng: -73.97832000000001,
                  },
                  {
                    lat: 40.7573,
                    lng: -73.97812,
                  },
                  {
                    lat: 40.7573,
                    lng: -73.97811,
                  },
                ],
                lat_lngs: [
                  {
                    lat: 40.75865,
                    lng: -73.98133,
                  },
                  {
                    lat: 40.75833,
                    lng: -73.98054,
                  },
                  {
                    lat: 40.757830000000006,
                    lng: -73.97936,
                  },
                  {
                    lat: 40.757740000000005,
                    lng: -73.97915,
                  },
                  {
                    lat: 40.757690000000004,
                    lng: -73.97905,
                  },
                  {
                    lat: 40.75761000000001,
                    lng: -73.97884,
                  },
                  {
                    lat: 40.75757,
                    lng: -73.97875,
                  },
                  {
                    lat: 40.75748,
                    lng: -73.97853,
                  },
                  {
                    lat: 40.75739,
                    lng: -73.97832000000001,
                  },
                  {
                    lat: 40.7573,
                    lng: -73.97812,
                  },
                  {
                    lat: 40.7573,
                    lng: -73.97811,
                  },
                ],
                instructions:
                  "Turn <b>right</b> at the 1st cross street onto <b>W 48th St</b>",
                start_point: {
                  lat: 40.7586531,
                  lng: -73.9813286,
                },
                end_point: {
                  lat: 40.7573003,
                  lng: -73.9781055,
                },
              },
              {
                distance: {
                  text: "1.0 mi",
                  value: 1681,
                },
                duration: {
                  text: "7 mins",
                  value: 427,
                },
                end_location: {
                  lat: 40.744074,
                  lng: -73.9877532,
                },
                maneuver: "turn-right",
                polyline: {
                  points:
                    "clwwFdzobM~BxANJLJ~@l@\\Tv@f@~@l@FDZR|AdALJVP@?DBNL^V^TFBFDvA~@^VJFTNPLn@b@^Tr@f@p@b@RNx@h@`An@p@b@hAr@\\Td@Zt@d@f@\\tA|@ZRZRbAp@bAp@`@X\\Vz@j@hAv@x@f@`Al@\\V^V|@l@LFf@\\FDz@l@d@VrA|@LHbAn@j@^~@l@h@\\PL",
                },
                start_location: {
                  lat: 40.7573003,
                  lng: -73.9781055,
                },
                travel_mode: "DRIVING",
                encoded_lat_lngs:
                  "clwwFdzobM~BxANJLJ~@l@\\Tv@f@~@l@FDZR|AdALJVP@?DBNL^V^TFBFDvA~@^VJFTNPLn@b@^Tr@f@p@b@RNx@h@`An@p@b@hAr@\\Td@Zt@d@f@\\tA|@ZRZRbAp@bAp@`@X\\Vz@j@hAv@x@f@`Al@\\V^V|@l@LFf@\\FDz@l@d@VrA|@LHbAn@j@^~@l@h@\\PL",
                path: [
                  {
                    lat: 40.7573,
                    lng: -73.97811,
                  },
                  {
                    lat: 40.756660000000004,
                    lng: -73.97856,
                  },
                  {
                    lat: 40.75658000000001,
                    lng: -73.97862,
                  },
                  {
                    lat: 40.756510000000006,
                    lng: -73.97868000000001,
                  },
                  {
                    lat: 40.756190000000004,
                    lng: -73.97891,
                  },
                  {
                    lat: 40.756040000000006,
                    lng: -73.97902,
                  },
                  {
                    lat: 40.75576,
                    lng: -73.97922000000001,
                  },
                  {
                    lat: 40.75544,
                    lng: -73.97945,
                  },
                  {
                    lat: 40.7554,
                    lng: -73.97948000000001,
                  },
                  {
                    lat: 40.75526,
                    lng: -73.97958000000001,
                  },
                  {
                    lat: 40.75479,
                    lng: -73.97993000000001,
                  },
                  {
                    lat: 40.754720000000006,
                    lng: -73.97999,
                  },
                  {
                    lat: 40.7546,
                    lng: -73.98008,
                  },
                  {
                    lat: 40.75459,
                    lng: -73.98008,
                  },
                  {
                    lat: 40.754560000000005,
                    lng: -73.98010000000001,
                  },
                  {
                    lat: 40.75448,
                    lng: -73.98017,
                  },
                  {
                    lat: 40.75432,
                    lng: -73.98029000000001,
                  },
                  {
                    lat: 40.754160000000006,
                    lng: -73.9804,
                  },
                  {
                    lat: 40.75412,
                    lng: -73.98042000000001,
                  },
                  {
                    lat: 40.75408,
                    lng: -73.98045,
                  },
                  {
                    lat: 40.753640000000004,
                    lng: -73.98077,
                  },
                  {
                    lat: 40.75348,
                    lng: -73.98089,
                  },
                  {
                    lat: 40.753420000000006,
                    lng: -73.98093,
                  },
                  {
                    lat: 40.753310000000006,
                    lng: -73.98101000000001,
                  },
                  {
                    lat: 40.753220000000006,
                    lng: -73.98108,
                  },
                  {
                    lat: 40.75298,
                    lng: -73.98126,
                  },
                  {
                    lat: 40.75282,
                    lng: -73.98137000000001,
                  },
                  {
                    lat: 40.75256,
                    lng: -73.98157,
                  },
                  {
                    lat: 40.75231,
                    lng: -73.98175,
                  },
                  {
                    lat: 40.752210000000005,
                    lng: -73.98183,
                  },
                  {
                    lat: 40.751920000000005,
                    lng: -73.98204000000001,
                  },
                  {
                    lat: 40.75159,
                    lng: -73.98228,
                  },
                  {
                    lat: 40.751340000000006,
                    lng: -73.98246,
                  },
                  {
                    lat: 40.75097,
                    lng: -73.98272,
                  },
                  {
                    lat: 40.750820000000004,
                    lng: -73.98283,
                  },
                  {
                    lat: 40.75063,
                    lng: -73.98297000000001,
                  },
                  {
                    lat: 40.75036,
                    lng: -73.98316000000001,
                  },
                  {
                    lat: 40.75016,
                    lng: -73.98331,
                  },
                  {
                    lat: 40.74973000000001,
                    lng: -73.98362,
                  },
                  {
                    lat: 40.749590000000005,
                    lng: -73.98372,
                  },
                  {
                    lat: 40.74945,
                    lng: -73.98382000000001,
                  },
                  {
                    lat: 40.74911,
                    lng: -73.98407,
                  },
                  {
                    lat: 40.74877,
                    lng: -73.98432000000001,
                  },
                  {
                    lat: 40.7486,
                    lng: -73.98445000000001,
                  },
                  {
                    lat: 40.748450000000005,
                    lng: -73.98457,
                  },
                  {
                    lat: 40.74815,
                    lng: -73.98479,
                  },
                  {
                    lat: 40.747780000000006,
                    lng: -73.98507000000001,
                  },
                  {
                    lat: 40.747490000000006,
                    lng: -73.98527,
                  },
                  {
                    lat: 40.74716,
                    lng: -73.9855,
                  },
                  {
                    lat: 40.74701,
                    lng: -73.98562000000001,
                  },
                  {
                    lat: 40.74685,
                    lng: -73.98574,
                  },
                  {
                    lat: 40.74654,
                    lng: -73.98597000000001,
                  },
                  {
                    lat: 40.74647,
                    lng: -73.98601000000001,
                  },
                  {
                    lat: 40.74627,
                    lng: -73.98616000000001,
                  },
                  {
                    lat: 40.746230000000004,
                    lng: -73.98619000000001,
                  },
                  {
                    lat: 40.74593,
                    lng: -73.98642000000001,
                  },
                  {
                    lat: 40.745740000000005,
                    lng: -73.98654,
                  },
                  {
                    lat: 40.74532000000001,
                    lng: -73.98685,
                  },
                  {
                    lat: 40.745250000000006,
                    lng: -73.9869,
                  },
                  {
                    lat: 40.744910000000004,
                    lng: -73.98714000000001,
                  },
                  {
                    lat: 40.744690000000006,
                    lng: -73.9873,
                  },
                  {
                    lat: 40.74437,
                    lng: -73.98753,
                  },
                  {
                    lat: 40.74416,
                    lng: -73.98768000000001,
                  },
                  {
                    lat: 40.74407,
                    lng: -73.98775,
                  },
                ],
                lat_lngs: [
                  {
                    lat: 40.7573,
                    lng: -73.97811,
                  },
                  {
                    lat: 40.756660000000004,
                    lng: -73.97856,
                  },
                  {
                    lat: 40.75658000000001,
                    lng: -73.97862,
                  },
                  {
                    lat: 40.756510000000006,
                    lng: -73.97868000000001,
                  },
                  {
                    lat: 40.756190000000004,
                    lng: -73.97891,
                  },
                  {
                    lat: 40.756040000000006,
                    lng: -73.97902,
                  },
                  {
                    lat: 40.75576,
                    lng: -73.97922000000001,
                  },
                  {
                    lat: 40.75544,
                    lng: -73.97945,
                  },
                  {
                    lat: 40.7554,
                    lng: -73.97948000000001,
                  },
                  {
                    lat: 40.75526,
                    lng: -73.97958000000001,
                  },
                  {
                    lat: 40.75479,
                    lng: -73.97993000000001,
                  },
                  {
                    lat: 40.754720000000006,
                    lng: -73.97999,
                  },
                  {
                    lat: 40.7546,
                    lng: -73.98008,
                  },
                  {
                    lat: 40.75459,
                    lng: -73.98008,
                  },
                  {
                    lat: 40.754560000000005,
                    lng: -73.98010000000001,
                  },
                  {
                    lat: 40.75448,
                    lng: -73.98017,
                  },
                  {
                    lat: 40.75432,
                    lng: -73.98029000000001,
                  },
                  {
                    lat: 40.754160000000006,
                    lng: -73.9804,
                  },
                  {
                    lat: 40.75412,
                    lng: -73.98042000000001,
                  },
                  {
                    lat: 40.75408,
                    lng: -73.98045,
                  },
                  {
                    lat: 40.753640000000004,
                    lng: -73.98077,
                  },
                  {
                    lat: 40.75348,
                    lng: -73.98089,
                  },
                  {
                    lat: 40.753420000000006,
                    lng: -73.98093,
                  },
                  {
                    lat: 40.753310000000006,
                    lng: -73.98101000000001,
                  },
                  {
                    lat: 40.753220000000006,
                    lng: -73.98108,
                  },
                  {
                    lat: 40.75298,
                    lng: -73.98126,
                  },
                  {
                    lat: 40.75282,
                    lng: -73.98137000000001,
                  },
                  {
                    lat: 40.75256,
                    lng: -73.98157,
                  },
                  {
                    lat: 40.75231,
                    lng: -73.98175,
                  },
                  {
                    lat: 40.752210000000005,
                    lng: -73.98183,
                  },
                  {
                    lat: 40.751920000000005,
                    lng: -73.98204000000001,
                  },
                  {
                    lat: 40.75159,
                    lng: -73.98228,
                  },
                  {
                    lat: 40.751340000000006,
                    lng: -73.98246,
                  },
                  {
                    lat: 40.75097,
                    lng: -73.98272,
                  },
                  {
                    lat: 40.750820000000004,
                    lng: -73.98283,
                  },
                  {
                    lat: 40.75063,
                    lng: -73.98297000000001,
                  },
                  {
                    lat: 40.75036,
                    lng: -73.98316000000001,
                  },
                  {
                    lat: 40.75016,
                    lng: -73.98331,
                  },
                  {
                    lat: 40.74973000000001,
                    lng: -73.98362,
                  },
                  {
                    lat: 40.749590000000005,
                    lng: -73.98372,
                  },
                  {
                    lat: 40.74945,
                    lng: -73.98382000000001,
                  },
                  {
                    lat: 40.74911,
                    lng: -73.98407,
                  },
                  {
                    lat: 40.74877,
                    lng: -73.98432000000001,
                  },
                  {
                    lat: 40.7486,
                    lng: -73.98445000000001,
                  },
                  {
                    lat: 40.748450000000005,
                    lng: -73.98457,
                  },
                  {
                    lat: 40.74815,
                    lng: -73.98479,
                  },
                  {
                    lat: 40.747780000000006,
                    lng: -73.98507000000001,
                  },
                  {
                    lat: 40.747490000000006,
                    lng: -73.98527,
                  },
                  {
                    lat: 40.74716,
                    lng: -73.9855,
                  },
                  {
                    lat: 40.74701,
                    lng: -73.98562000000001,
                  },
                  {
                    lat: 40.74685,
                    lng: -73.98574,
                  },
                  {
                    lat: 40.74654,
                    lng: -73.98597000000001,
                  },
                  {
                    lat: 40.74647,
                    lng: -73.98601000000001,
                  },
                  {
                    lat: 40.74627,
                    lng: -73.98616000000001,
                  },
                  {
                    lat: 40.746230000000004,
                    lng: -73.98619000000001,
                  },
                  {
                    lat: 40.74593,
                    lng: -73.98642000000001,
                  },
                  {
                    lat: 40.745740000000005,
                    lng: -73.98654,
                  },
                  {
                    lat: 40.74532000000001,
                    lng: -73.98685,
                  },
                  {
                    lat: 40.745250000000006,
                    lng: -73.9869,
                  },
                  {
                    lat: 40.744910000000004,
                    lng: -73.98714000000001,
                  },
                  {
                    lat: 40.744690000000006,
                    lng: -73.9873,
                  },
                  {
                    lat: 40.74437,
                    lng: -73.98753,
                  },
                  {
                    lat: 40.74416,
                    lng: -73.98768000000001,
                  },
                  {
                    lat: 40.74407,
                    lng: -73.98775,
                  },
                ],
                instructions:
                  'Turn <b>right</b> onto <b>5th Ave</b><div style="font-size:0.9em">Pass by Reinlieb Laurence (on the right in 0.7 mi)</div>',
                start_point: {
                  lat: 40.7573003,
                  lng: -73.9781055,
                },
                end_point: {
                  lat: 40.744074,
                  lng: -73.9877532,
                },
              },
              {
                distance: {
                  text: "0.2 mi",
                  value: 269,
                },
                duration: {
                  text: "2 mins",
                  value: 93,
                },
                end_location: {
                  lat: 40.7452415,
                  lng: -73.9905464,
                },
                maneuver: "turn-right",
                polyline: {
                  points:
                    "mytwFlvqbMGTOb@CFIZADOb@Sn@GNIVIVi@dBGPIVQh@a@rAEJK\\",
                },
                start_location: {
                  lat: 40.744074,
                  lng: -73.9877532,
                },
                travel_mode: "DRIVING",
                encoded_lat_lngs:
                  "mytwFlvqbMGTOb@CFIZADOb@Sn@GNIVIVi@dBGPIVQh@a@rAEJK\\",
                path: [
                  {
                    lat: 40.74407,
                    lng: -73.98775,
                  },
                  {
                    lat: 40.744110000000006,
                    lng: -73.98786000000001,
                  },
                  {
                    lat: 40.74419,
                    lng: -73.98804000000001,
                  },
                  {
                    lat: 40.74421,
                    lng: -73.98808000000001,
                  },
                  {
                    lat: 40.744260000000004,
                    lng: -73.98822000000001,
                  },
                  {
                    lat: 40.74427,
                    lng: -73.98825000000001,
                  },
                  {
                    lat: 40.744350000000004,
                    lng: -73.98843000000001,
                  },
                  {
                    lat: 40.74445,
                    lng: -73.98867,
                  },
                  {
                    lat: 40.744490000000006,
                    lng: -73.98875000000001,
                  },
                  {
                    lat: 40.74454,
                    lng: -73.98887,
                  },
                  {
                    lat: 40.74459,
                    lng: -73.98899,
                  },
                  {
                    lat: 40.744800000000005,
                    lng: -73.9895,
                  },
                  {
                    lat: 40.74484,
                    lng: -73.98959,
                  },
                  {
                    lat: 40.744890000000005,
                    lng: -73.98971,
                  },
                  {
                    lat: 40.744980000000005,
                    lng: -73.98992000000001,
                  },
                  {
                    lat: 40.74515,
                    lng: -73.99034,
                  },
                  {
                    lat: 40.745180000000005,
                    lng: -73.99040000000001,
                  },
                  {
                    lat: 40.74524,
                    lng: -73.99055000000001,
                  },
                ],
                lat_lngs: [
                  {
                    lat: 40.74407,
                    lng: -73.98775,
                  },
                  {
                    lat: 40.744110000000006,
                    lng: -73.98786000000001,
                  },
                  {
                    lat: 40.74419,
                    lng: -73.98804000000001,
                  },
                  {
                    lat: 40.74421,
                    lng: -73.98808000000001,
                  },
                  {
                    lat: 40.744260000000004,
                    lng: -73.98822000000001,
                  },
                  {
                    lat: 40.74427,
                    lng: -73.98825000000001,
                  },
                  {
                    lat: 40.744350000000004,
                    lng: -73.98843000000001,
                  },
                  {
                    lat: 40.74445,
                    lng: -73.98867,
                  },
                  {
                    lat: 40.744490000000006,
                    lng: -73.98875000000001,
                  },
                  {
                    lat: 40.74454,
                    lng: -73.98887,
                  },
                  {
                    lat: 40.74459,
                    lng: -73.98899,
                  },
                  {
                    lat: 40.744800000000005,
                    lng: -73.9895,
                  },
                  {
                    lat: 40.74484,
                    lng: -73.98959,
                  },
                  {
                    lat: 40.744890000000005,
                    lng: -73.98971,
                  },
                  {
                    lat: 40.744980000000005,
                    lng: -73.98992000000001,
                  },
                  {
                    lat: 40.74515,
                    lng: -73.99034,
                  },
                  {
                    lat: 40.745180000000005,
                    lng: -73.99040000000001,
                  },
                  {
                    lat: 40.74524,
                    lng: -73.99055000000001,
                  },
                ],
                instructions:
                  'Turn <b>right</b> onto <b>W 27th St</b><div style="font-size:0.9em">Destination will be on the right</div>',
                start_point: {
                  lat: 40.744074,
                  lng: -73.9877532,
                },
                end_point: {
                  lat: 40.7452415,
                  lng: -73.9905464,
                },
              },
            ],
            traffic_speed_entry: [],
            via_waypoint: [],
            via_waypoints: [],
          },
        ],
        overview_polyline:
          "arwwFbppbMoAy@~@}CtBuGr@{Bb@qA~BvAzBzA`HrE|DhChGbE`ElCbEjC|JxGdCbBzBtAhCdBn@b@z@l@d@V`BfAbAn@fDxBe@|AkB~FwApE",
        summary: "5th Ave",
        warnings: [],
        waypoint_order: [],
        overview_path: [
          {
            lat: 40.758250000000004,
            lng: -73.98162,
          },
          {
            lat: 40.75865,
            lng: -73.98133,
          },
          {
            lat: 40.75833,
            lng: -73.98054,
          },
          {
            lat: 40.757740000000005,
            lng: -73.97915,
          },
          {
            lat: 40.75748,
            lng: -73.97853,
          },
          {
            lat: 40.7573,
            lng: -73.97812,
          },
          {
            lat: 40.756660000000004,
            lng: -73.97856,
          },
          {
            lat: 40.756040000000006,
            lng: -73.97902,
          },
          {
            lat: 40.75459,
            lng: -73.98008,
          },
          {
            lat: 40.753640000000004,
            lng: -73.98077,
          },
          {
            lat: 40.75231,
            lng: -73.98175,
          },
          {
            lat: 40.751340000000006,
            lng: -73.98246,
          },
          {
            lat: 40.75036,
            lng: -73.98316000000001,
          },
          {
            lat: 40.748450000000005,
            lng: -73.98457,
          },
          {
            lat: 40.747780000000006,
            lng: -73.98507000000001,
          },
          {
            lat: 40.74716,
            lng: -73.9855,
          },
          {
            lat: 40.74647,
            lng: -73.98601000000001,
          },
          {
            lat: 40.746230000000004,
            lng: -73.98619000000001,
          },
          {
            lat: 40.74593,
            lng: -73.98642000000001,
          },
          {
            lat: 40.745740000000005,
            lng: -73.98654,
          },
          {
            lat: 40.745250000000006,
            lng: -73.9869,
          },
          {
            lat: 40.744910000000004,
            lng: -73.98714000000001,
          },
          {
            lat: 40.74407,
            lng: -73.98775,
          },
          {
            lat: 40.744260000000004,
            lng: -73.98822000000001,
          },
          {
            lat: 40.744800000000005,
            lng: -73.9895,
          },
          {
            lat: 40.74524,
            lng: -73.99055000000001,
          },
        ],
      },
    ],
    status: "OK",
    request: {
      origin: {
        location: {
          lat: 40.7581699,
          lng: -73.981422,
        },
      },
      destination: {
        query: "800 6th Ave, New York, NY 10001, USA",
      },
      travelMode: "DRIVING",
    },
  },
];

export const DUMMY_DELIVERY = {
  id: "7401ede6-9418-11ee-b9d1-0242ac120002",
  delivery_type: "asap",
  service: "uber",
  provider_logo_url:
    "https://preprod-provider-images.burqup.com/a6fd5ac5-57af-40c4-b009-0f4d9a327373.png",
  status: "delivered",
  tracking_url: "",
  currency: "USD",
  payment_amount: 868,
  tip: 0,
  fee: 769,
  upsell: null,
  subsidized: null,
  created_at: "2023-11-08T14:32:06.000Z",
  cancellation_reason: null,
  contactless_requested: false,
  external_order_ref: null,
  current_transit_status_duration_mins: null,
  order_value: 2000,
  proof_of_delivery: [],
  quote_id: "qo_667789c3-cce2-481d-8abb-e334ec9a13f7",
  signature: null,
  signature_requested: false,
  status_history: [
    {
      status: "delivery_created",
      time: "2023-11-08T14:32:07.000Z",
    },
    {
      status: "enroute_pickup",
      time: "2023-11-08T14:32:48.000Z",
    },
    {
      status: "enroute_dropoff",
      time: "2023-11-08T14:33:42.000Z",
    },
    {
      status: "pickup_complete",
      time: "2023-11-08T14:33:42.000Z",
    },
    {
      status: "enroute_dropoff",
      time: "2023-11-08T14:34:41.000Z",
    },
    {
      status: "delivered",
      time: moment().subtract("day", 1),
      // time: "2023-11-08T14:34:41.000Z",
    },
  ],
  store_id: null,
  test_mode: true,
  updated_at: "2023-11-08T14:34:41.000Z",
  vehicle: null,
  pickup_at: null,
  pickup_address: "1200 6th Ave, New York, NY 10036, USA",
  pickup_eta: "2023-11-08T14:33:39.513Z",
  pickup_name: "leo leo  ",
  pickup_notes: "das",
  pickup_phone_number: "18664113212",
  pickup_unit: null,
  dropoffs: [
    {
      dropoff_address: "800 6th Ave, New York, NY 10001, USA",
      dropoff_at: null,
      dropoff_eta: "2023-11-08T14:34:39.434Z",
      dropoff_name: "leo leo",
      dropoff_notes: "das",
      dropoff_phone_number: "18664113212",
      dropoff_unit: null,
      dropoff_address_city: "Manhattan",
      dropoff_address_state: "NY",
      dropoff_address_street: "800 6th Avenue",
    },
  ],
  pickup_window: {
    start_at: null,
    end_at: null,
  },
  dropoff_window: {
    start_at: null,
    end_at: null,
  },
  items_description: "Things",
  items: [
    {
      name: "das",
      quantity: 2,
      size: "small",
    },
  ],
  courier_name: "Alex I.",
  courier_img:
    "https://d1w2poirtb3as9.cloudfront.net/default.jpeg?Expires=1699497165&Signature=LY3nZUMpBzGH~bXYl~8JWuGZvaCs-E94yuyXlnDjSrk2z2ImXKVDGoEZP9Co3Wp~en4klWMXjtgd55cD78xX-P80YM8MPQrjHw~~Zq1~Srn-6yR0rU-ULUgF-kdMSai4N3XZLaR~fwt-tq5U1Q6PmuXzEDeAcSOuh~m7sVIx95ftc2IffAfWJyKwiLOeI6VTyStWEyUS-1kt5d~W92b2ZpqRIOVbzS8zJ6pkTaRuOL9gATHJe5Uqyu8bYJQFAoFO0S8MUbsUjk6coXZDSpjdbrY5uC~Qw-vSPqxBiKZAdHs3viynVEDmRZif5LeR4p8awDNqHi8eMms0WZWhJ3IUPw__&Key-Pair-Id=K36LFL06Z5BT10",
  courier_phone_number: undefined,
  courier_vehicle_type: "white Prius Toyota",
  courier_location_lat: "0",
  courier_location_lng: "0",
  payment_status: "unpaid",
  account_id: "acct_19g67lfv0j25w",
  events: [
    {
      status: "delivery_created",
      time: "2023-11-08T14:32:07.000Z",
    },
    {
      status: "enroute_pickup",
      time: "2023-11-08T14:32:48.000Z",
    },
    {
      status: "enroute_dropoff",
      time: "2023-11-08T14:33:42.000Z",
    },
    {
      status: "pickup_complete",
      time: "2023-11-08T14:33:42.000Z",
    },
    {
      status: "enroute_dropoff",
      time: "2023-11-08T14:34:41.000Z",
    },
    {
      status: "delivered",
      time: "2023-11-08T14:34:41.000Z",
    },
  ],
  provider_id: "del_vBA_k4nNSSSosj5w9Rj2Lw",
  provider_customer_support_id: "BC103F9389CD4924A8B23E70F518F62F",
  pickup_address_street: "1200 6th Avenue",
  pickup_address_city: "Manhattan",
  pickup_address_state: "NY",
  pickup_address_zip_code: "10036",
  pickup_address_country: "US",
  dropoff_unit: null,
  dropoff_at: null,
  dropoff_eta: "2023-11-08T14:34:39.434Z",
  updatedAt: "2023-11-08T14:34:41.000Z",
  createdAt: "2023-11-08T14:32:06.000Z",
  testMode: true,
  reroute_parent_delivery_id: null,
  reroute_child_delivery_id: null,
  delivery_incident: null,
  rating: null,
};
