import { useFormikContext } from "formik";
import React, { useEffect, useMemo } from "react";
import { PickupSvg } from "../../assets/svgs/Svgs";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import { useStoreAndChild, useTheme } from "../../store";

const StoreDetailsOnCreate = ({ setIsAddressDefaulted, store_id }) => {
  const { Colors, themeChanger } = useTheme();
  const { selectedStore, getStoreById, setSelectedStore } = useStoreAndChild();
  const { smfontsize, mdfontsize } = useDesktopOrLaptop();
  const { setFieldValue } = useFormikContext();

  const styles = useMemo(() => {
    return {
      screen: {
        width: "100%",
        borderRadius: "8px",
        backgroundColor: Colors.bgDarkSecondary,
        padding: "20px 20px",
        marginBottom: "0px",
      },
      defaultTitle: {
        fontFamily: "Poppins",
        fontWeight: 500,
        fontSize: mdfontsize,
        color: Colors.fontDark,
      },
      details: {
        fontFamily: "Poppins",
        fontWeight: 500,
        fontSize: mdfontsize,
        color: Colors.fontGreyLight,
        marginTop: "20px",
        marginBottom: "20px",
      },
      oneRow: {
        padding: "10px",
        borderBottomWidth: "1px",
        borderColor: Colors.borderPrimary,
      },
      rowTtle: {
        fontSize: smfontsize,
        fontWeight: 500,
        color: Colors.fontDark,
      },
    };
  }, [themeChanger]);

  const storeHandler = async (storeID) => {
    const res = await getStoreById(storeID);
    if (res) {
      setSelectedStore(res);
    }
  };
  useEffect(() => {
    let subscribe = true;

    if (subscribe) {
      if (!selectedStore) {
        storeHandler(store_id);
      }
    }

    return () => {
      subscribe = false;
    };
  }, [store_id]);

  if (!selectedStore) return <></>;
  return (
    <div style={styles.screen}>
      <div
        className="w-full text-center flex items-center justify-center"
        style={styles.defaultTitle}
      >
        <div className="mr-4">
          <PickupSvg />{" "}
        </div>
        The Pickup address is defaulted to the store's address
        {/* <span
          style={{ color: Colors.bluePrimary }}
          onClick={() => {
            setIsAddressDefaulted(false);
            setFieldValue("pickup", [
              {
                pickup_address: selectedStore?.address,
                pickup_unit: selectedStore?.unit_number || "",
                pickup_businessName: "",
                pickup_phone_number: selectedStore?.phone_number,
                pickup_notes: selectedStore?.pickup_notes,
                pickup_name: selectedStore?.name,
              },
            ]);
          }}
          className="cursor-pointer "
        >
          (Edit)
        </span> */}
      </div>
      <div className="text-center" style={styles.details}>
        Details:{" "}
      </div>

      <div
        style={{
          padding: "10px",
          borderBottomWidth: "1px",
          borderColor: Colors.borderPrimary,
        }}
        className="flex items-center"
      >
        <div
          style={{
            fontSize: smfontsize,
            fontWeight: 500,
            color: Colors.fontDark,
          }}
          className="w-1/2"
        >
          Store's Name:
        </div>
        <div style={{ color: Colors.fontGreyLight }} className="w-1/2">
          {selectedStore.name}
        </div>
      </div>
      <div
        style={{
          padding: "10px",
          borderBottomWidth: "1px",
          borderColor: Colors.borderPrimary,
        }}
        className="flex items-center"
      >
        <div
          style={{
            fontSize: smfontsize,
            fontWeight: 500,
            color: Colors.fontDark,
          }}
          className="w-1/2"
        >
          Store's Id:
        </div>
        <div style={{ color: Colors.fontGreyLight }} className="w-1/2">
          {selectedStore.id}
        </div>
      </div>
      <div style={styles.oneRow} className="flex items-center">
        <div style={styles.rowTtle} className="w-1/2">
          Store's Address:
        </div>
        <div style={{ color: Colors.fontGreyLight }} className="w-1/2">
          {selectedStore.address}
        </div>
      </div>
      <div style={styles.oneRow} className="flex items-center">
        <div style={styles.rowTtle} className="w-1/2">
          Suite/Apt. # :
        </div>
        <div style={{ color: Colors.fontGreyLight }} className="w-1/2">
          {selectedStore.unit_number}
        </div>
      </div>
      <div style={styles.oneRow} className="flex items-center">
        <div style={styles.rowTtle} className="w-1/2">
          Notes :
        </div>
        <div style={{ color: Colors.fontGreyLight }} className="w-1/2">
          {selectedStore.pickup_notes}
        </div>
      </div>
      <div style={styles.oneRow} className="flex items-center">
        <div style={styles.rowTtle} className="w-1/2">
          Stores Phone Number :
        </div>
        <div style={{ color: Colors.fontGreyLight }} className="w-1/2">
          {selectedStore.phone_number[0] === "+"
            ? selectedStore.phone_number
            : "+" + selectedStore.phone_number}
        </div>
      </div>
    </div>
  );
};

export default StoreDetailsOnCreate;
