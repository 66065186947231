import axios from "axios";
import apiUrls from "../../constants/apiUrls";
import { generateQueryParams } from "./utils";

export const getInvoices = (merchantAccountId, params) => {
  const urlWithQueryParams = generateQueryParams(
    apiUrls.GET_INVOICES(merchantAccountId),
    params
  );
  return axios.get(urlWithQueryParams, {
    headers: { "x-burq-client": "client-dashboard" },
  });
};

export const getInvoice = (invoiceId) =>
  axios.get(apiUrls.GET_INVOICE(invoiceId), {
    headers: { "x-burq-client": "client-dashboard" },
  });

export const getTransactions = (invoiceId, params) => {
  const urlWithQueryParams = generateQueryParams(
    apiUrls.GET_TRANSACTIONS(invoiceId),
    params
  );
  return axios.get(urlWithQueryParams, {
    headers: { "x-burq-client": "client-dashboard" },
  });
};

export const invoicesCSVExport = (invoiceId, params = {}) => {
  const urlWithQueryParams = generateQueryParams(
    apiUrls.INVOICES_CSV_EXPORT(invoiceId),
    params
  );
  return axios.get(urlWithQueryParams, {
    headers: { "x-burq-client": "client-dashboard" },
  });
};

export const invoiceInitiatePayment = (id) =>
  axios.post(apiUrls.PAY_INVOICE(id));

export const getInvoicePDFLink = (id) =>
  axios.get(apiUrls.INVOICE_PDF_LINK(id));

export const getInvoicesStores = (id) =>
  axios.get(apiUrls.GET_INVOICES_STORES(id), {
    headers: { "x-burq-client": "client-dashboard" },
  });

export const getStripePaymentAccount = (merchantAccountId) =>
  axios.get(apiUrls.GET_STRIPE_PAYMENT_ACCOUNT(merchantAccountId), {
    headers: { "x-burq-client": "client-dashboard" },
  });
