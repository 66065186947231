import React, { useEffect } from "react";
import styles from "./styles.module.css";
import classNames from "classnames";

import { MdCheckBoxOutlineBlank } from "react-icons/md";
import { IoCheckbox } from "react-icons/io5";
import OneNotifRow from "./OneNotifRow";
import { useNotifSettings, userDataStore } from "../../store";

const NotificationsSettings = ({}) => {
  const {
    getNotificationsSettingsHandler,
    notificationsSettings,
    setNotificationsSettings,
  } = useNotifSettings();
  const { authInfo } = userDataStore();
  useEffect(() => {
    if (authInfo) {
      getNotificationsSettingsHandler(authInfo?.id);
    }
  }, [authInfo]);
  return (
    <div className={styles.container}>
      <div
        className={classNames(
          "flex items-center justify-start",
          styles.rowCont
        )}
      >
        <div className={styles.pageTitle}>Notification Settings</div>
        <div
          className={classNames(
            "flex w-40 items-center justify-between",
            styles.emailWeb
          )}
        >
          <div className="w-1/2 flex items-center justify-center">Email</div>
          <div className="w-1/2 flex items-center justify-center">Web</div>
        </div>
      </div>
      {notificationsSettings?.data?.map((item, index) => {
        return (
          <OneNotifRow
            authInfo={authInfo}
            key={item.id}
            item={item}
            index={index}
            notificationsSettings={notificationsSettings}
            setNotificationsSettings={setNotificationsSettings}
          />
        );
      })}
    </div>
  );
};

export default NotificationsSettings;
