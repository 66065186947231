import React, { useEffect } from "react";
import { useAnalyticsStore, useFeatureFlags, useMyStore, userDataStore } from "../../../store";
import OverviewDataSection from "./OverviewDataSection";
import OverviewHeader from "./OverviewHeader";
import OverviewTitle from "./OverviewTitle";

const OverviewSection = () => {
  const { isSandbox, userId } = useMyStore();
  const { authInfo } = userDataStore();
  const { isSnowflakeDisabled } = useFeatureFlags();

  const { overviewPeriod, selectStatus, overviewDeliveryReport, getOverviewDeliveryReport } = useAnalyticsStore();

  useEffect(() => {
    if (getOverviewDeliveryReport) {
      getOverviewDeliveryReport(isSandbox, userId ?? authInfo?.merchantAccountId, isSnowflakeDisabled);
    }
  }, [isSandbox, userId, selectStatus, overviewPeriod, authInfo]);

  return (
    <div>
      <OverviewTitle />
      <OverviewHeader />
      <OverviewDataSection payload={overviewDeliveryReport} />
    </div>
  );
};

export default OverviewSection;
