import React from "react";
import Badge from "../../../shared/atoms/Badge";
import styles from "../../styles/ProviderList/ProviderCard/providerBadges.module.css";

const BadgeComponent = ({ data, aps }) => (
  <div className={styles.badgeSection}>
    {data?.is_enabled && (
      <Badge
        hideIcon={false}
        label="enabled"
        type={"success"}
        externalClassName={styles.badge}
      />
    )}
    {aps?.is_merchant_preferred && (
      <Badge
        hideIcon={false}
        label="preferred"
        externalClassName={styles.badge}
      />
    )}
  </div>
);

export default BadgeComponent;
