import React, { useState, useCallback, useRef } from "react";

import Cookies from "js-cookie";
import debounce from "lodash.debounce";
import { Link } from "react-router-dom";

import { SearchSvg } from "../../../assets/svgs/Svgs";
import logo from "../../../assets/png/g10.png";
import darklogo from "../../../assets/png/g10Dark.png";
import burgerPng from "../../../assets/png/burgerPng.png";

import { useMyStore, useClickStore, userDataStore, useTheme, useStoreAndChild, useMerchant, useFilter } from "../../../store";
import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";
import ButtonComp from "../../ButtonComp/ButtonComp";
import { ZendeskAPI } from "react-zendesk";
import { removeCookie } from "../../../utils";

function Header(props) {
  const { useOnClickOutside } = useClickStore();
  const { merchantData } = useMerchant();
  const [logoutVisible, setLogoutVisible] = useState(false);
  const { Colors, themeChanger } = useTheme();

  const { selectedStore } = useStoreAndChild();

  const { isdesktop, smfontsize, mdfontsize } = useDesktopOrLaptop();

  const { isSandbox, setIsSandbox, deliveries, pageCount, setPageCount } = useMyStore();
  const { search, setSearch } = useFilter();

  const clickRef = useRef();
  const handler = useCallback(() => {
    setLogoutVisible(false);
  });
  useOnClickOutside(clickRef, handler);

  return (
    <header
      style={{
        position: props.fixed ? "fixed" : "",
        paddingLeft: "26px",
        paddingBottom: "30px",
        paddingTop: "94px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        zIndex: 10,
      }}
    >
      <div className="flex flex-row items-center">
        <div style={{ marginLeft: isdesktop ? "25px" : "0px" }} className=" ml-0 flex flex-row items-center">
          <Link
            id="homeId"
            to=""
            onClick={() => {
              // props.setSidepanelactive && props.setSidepanelactive("home");
            }}
          >
            <div
              style={{
                width: "80px",
                height: "80px",
                objectFit: "fill",
              }}
            >
              <img style={{ width: "80px", height: "80px" }} src={themeChanger ? darklogo : logo} alt="Burq logo" />
            </div>
          </Link>
        </div>
      </div>
      <form
        style={{ width: "70%", height: "80px" }}
        className="flex xl:justify-between justify-start"
        onSubmit={(e) => {
          if (pageCount > 0) {
            setPageCount(0);
          }
          deliveries(search, 0, "", selectedStore?.id);
          e.preventDefault();
        }}
      >
        <div
          style={{
            padding: "4px 15px",
            borderColor: Colors.borderPrimary,
            borderWidth: "3px",
            borderRadius: "10px",
          }}
          className="w-full  flex items-center justify-evenly lg:px-0"
        >
          <SearchSvg />
          <input
            onChange={(e) => {
              setSearch(e.target.value);

              if (e.target.value === "") {
                if (pageCount > 0) {
                  setPageCount(0);
                }
                deliveries(e.target.value, 0, "", selectedStore?.id);
              }
            }}
            style={{
              fontFamily: "Poppins",
              fontSize: mdfontsize,
              fontWeight: "600",
              backgroundColor: Colors.white,
              color: Colors.fontDark,
              marginLeft: "20px",
            }}
            type="search"
            className="  focus:outline-none mr-1 bg   w-full  pl-2  py-1 "
            placeholder="Search"
          />
        </div>
        <ButtonComp
          title={"Search"}
          type="submit"
          extraStyles={{
            // height: "42px",
            color: Colors.fontGreyLight,
          }}
        />
      </form>
      <div style={{ fontFamily: "Poppins" }} className="flex items-center mr-4">
        {!isdesktop && !props.noBurger && (
          <>
            <button
              id={props.createBurger ? props.createBurger : "burgerId"}
              className="flex flex-row items-center "
              type="button"
              onClick={() => {
                props.setIsModal(true);
              }}
              style={{
                outline: "none",
                fontFamily: "Poppins",
                height: "80px",
                width: "80px",
              }}
            >
              <img style={{ width: "70px", height: "70px", objectFit: "fill" }} src={burgerPng} alt="Burq logo" />
            </button>
          </>
        )}

        {logoutVisible && (
          <div
            ref={clickRef}
            className="shadow-md rounded absolute  right-5"
            style={{
              minWidth: "80px",
              paddingBottom: "2px",
              zIndex: 110,
              top: isdesktop ? "56px" : "70px",
            }}
          >
            <button
              onClick={() => {
                Object.keys(Cookies.get()).forEach((cookieName) => {
                  removeCookie(cookieName);
                });
                ZendeskAPI("messenger", "logoutUser");
                window.location.href = "/api/auth/logout";
              }}
              style={{
                fontFamily: "Poppins",
                fontSize: smfontsize,
                backgroundColor: Colors.white,
                color: Colors.fontDark,
              }}
              className={`hover:bg-gray-100   font-semibold px-4  py-4 w-full `}
            >
              Log Out
            </button>
          </div>
        )}
      </div>
    </header>
  );
}

export default Header;
