import React from "react";
import { useFlag } from "@unleash/proxy-client-react";
import { BsArrowRepeat } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

import Loader from "../../../../../shared/atoms/Loader";
import { useDeliveriesStore, useSingleItemStore } from "../../../../../../store";

import styles from "./styles.module.css";
import classNames from "classnames";
import { useDesktopOrLaptop } from "../../../../../../services/useDesktopOrLaptop";

const ActionButtons = ({ item, isOnSideBar, setIsIncidentVisible }) => {
  const { setRerouteItem, setIsAdminReroute } = useSingleItemStore();
  const { isdesktop } = useDesktopOrLaptop();
  const { isGetTrackIncidentDataLoading, getTrackIncidentData } = useDeliveriesStore();

  const navigate = useNavigate();

  const trackIncidentHandler = async () => {
    const data = await getTrackIncidentData(item?.delivery_incident?.zendesk_ticket_id);
    if (data?.url) {
      window.open(data.url);
    }
  };

  return (
    <div>
      {!isOnSideBar && (
        <a
          target="_blank"
          rel="noreferrer"
          href={`${process.env.REACT_APP_TRACKING_URL}/track/${item.id}`}
          className={classNames(
            "hover-blue",
            {
              [styles.mobile]: !isdesktop,
              [styles.buttonOnsidebar]: isOnSideBar,
            },
            styles.button
          )}
        >
          Track
        </a>
      )}
      {!item?.reroute_child_delivery_id &&
        (item.status === "enroute_to_return" ||
          item.status === "provider_canceled" ||
          item.status === "customer_canceled" ||
          item.status === "burq_canceled" ||
          item.status === "failed" ||
          item.status === "returned") && (
          <button
            onClick={() => {
              setRerouteItem(item);
              setIsAdminReroute(false);
              navigate(`/reroute/${item.id}`);
            }}
            className={classNames("hover-blue", styles.button, {
              [styles.mobile]: !isdesktop,
              [styles.buttonOnsidebar]: isOnSideBar,
            })}
          >
            <span
              style={{
                marginRight: "8px",
              }}
            >
              <BsArrowRepeat size={isdesktop ? 20 : 34} className="hover-blue" />
            </span>
            Reroute
          </button>
        )}
      {/* {item?.delivery_incident?.zendesk_ticket_id && (
        <button
          onClick={trackIncidentHandler}
          className={classNames(
            "hover-blue",
            {
              [styles.mobile]: !isdesktop,
              [styles.buttonOnsidebar]: isOnSideBar,
            },
            styles.button
          )}
          disabled={isGetTrackIncidentDataLoading}
        >
          Track Incident
          {isGetTrackIncidentDataLoading && (
            <span className={styles.loaderSpan}>
              <Loader extraClassName={styles.loader} />
            </span>
          )}
        </button>
      )} */}
      {!item?.delivery_incident && (
        <button
          onClick={() => {
            setIsIncidentVisible(true);
          }}
          className={classNames(
            "hover-blue",
            {
              [styles.mobile]: !isdesktop,
              [styles.buttonOnsidebar]: isOnSideBar,
            },
            styles.button
          )}
          disabled={isGetTrackIncidentDataLoading}
        >
          Create Delivery Incident
        </button>
      )}

      <button
        onClick={() => {
          setRerouteItem(item);
          setIsAdminReroute(false);
          navigate(`/rerouteEdit/${item.id}`);
        }}
        className={classNames(
          "hover-blue",
          {
            [styles.mobile]: !isdesktop,
            [styles.buttonOnsidebar]: isOnSideBar,
          },
          styles.button
        )}
      >
        <span
          style={{
            marginRight: "8px",
          }}
        >
          <BsArrowRepeat size={isdesktop ? 20 : 34} className="hover-blue" />
        </span>
        Duplicate Delivery
      </button>
    </div>
  );
};

export default ActionButtons;
