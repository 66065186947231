import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import { useTheme } from "../../store";

const DeliveryPreferences = ({ isReroute }) => {
  const { setFieldValue, values } = useFormikContext();
  const { Colors } = useTheme();

  const { smfontsize } = useDesktopOrLaptop();
  const [choosenValue, setChoosenValue] = useState("");
  const styles = {
    inputs: {
      fontFamily: "Poppins",
      borderRadius: "8px",
      fontSize: smfontsize,
      fontWeight: "400",
      color: Colors.fontDark,
      backgroundColor: Colors.buttonBg,
      borderRight: "20px solid transparent",
    },
  };
  useEffect(() => {
    if (values.signature) {
      setChoosenValue("signature");
    } else if (values.proofOfDelivery) {
      setChoosenValue("contactless");
    } else {
      setChoosenValue("");
    }
  }, []);

  return (
    <div className="flex items-center w-full mt-7 ">
      <div style={{ width: "100%" }}>
        <div style={{ color: Colors.fontDark, marginBottom: "10px" }}>
          Delivery preferences
        </div>
        <select
          disabled={isReroute}
          draggable={true}
          value={choosenValue}
          onChange={(e) => {
            if (e.target.value === "contactless") {
              setFieldValue("proofOfDelivery", true);
              setFieldValue("signature", false);
              setChoosenValue("contactless");
            } else if (e.target.value === "signature") {
              setFieldValue("signature", true);
              setFieldValue("proofOfDelivery", false);
              setChoosenValue("signature");
            } else {
              setFieldValue("signature", false);
              setFieldValue("proofOfDelivery", false);
              setChoosenValue("");
            }
          }}
          style={{
            ...styles.inputs,
            ...{ backgroundColor: Colors.borderPrimary },
          }}
          className="py-3 px-5  w-full focus:outline-none "
          placeholder="Delivery preferences"
        >
          <option value="">No preference</option>
          <option value="contactless">
            Contactless (proof of delivery will be provided)
          </option>
          <option value="signature">Signature required</option>
        </select>
      </div>
    </div>
  );
};

export default DeliveryPreferences;
