import { useField, useFormikContext } from "formik";
import React, { useState } from "react";
import { useTheme } from "../../store";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function CustomPhoneNumberField(props) {
  const [field, meta] = useField(props.name);
  const { Colors } = useTheme();
  const { setFieldValue } = useFormikContext();
  const [isPhoneValid, setIsPhoneValid] = useState(true);

  return (
    <div
      style={{
        ...{
          marginBottom: props.marginbottom,
          display: "flex",
          alignItems: "center",
          width: "100%",
          height: "100%",
        },
        ...props.containerStyles,
      }}
    >
      <div style={{ width: "100%", height: "100%" }}>
        {props.label && <label htmlFor={props.name}>{props.label}</label>}
        <PhoneInput
          disabled={props.disabled}
          prefix="+"
          countryCodeEditable={false}
          isValid={(value) => {
            if (value.length < 2) {
              setIsPhoneValid(true);
            } else if (
              value.match(
                /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/
              ) &&
              value.length === 11
            ) {
              setIsPhoneValid(true);
            } else {
              setIsPhoneValid(false);
            }
          }}
          autoFormat
          country={"us"}
          onlyCountries={["us"]}
          onChange={(value, country, e, formattedValue) => {
            setFieldValue(field.name, formattedValue);
          }}
          value={field.value}
          buttonStyle={{
            ...{
              borderWidth: "0px",
              backgroundColor: Colors.borderPrimary,
            },
            ...props.buttonStyle,
          }}
          disableDropdown
          inputStyle={{
            ...{
              fontFamily: "Poppins",
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '24px',
              letterSpacing: '0.10000000149011612px',
              textAlign: 'left',
              color: Colors.fontGreyLight,
              height: '44px',
              width: '100%',
              borderRadius: '8px',
              backgroundColor: Colors.bgSettings,
              border: `1px solid ${Colors.tableBorderGray}`,
              padding: '10px',
              marginBottom: '27px',
              position: "relative",
            },
            ...props.style,
          }}
          placeholder="+1 (###) ###-####"
        />
        {!meta.error && !isPhoneValid && !props.storeError && (
          <p
            style={{
              color: Colors.redDark,
              fontFamily: "Poppins",
              fontSize: "12px",
              padding: "6px 10px 0px 12px",
              position: "absolute",
            }}
          >
            Invalid Phone Number
          </p>
        )}
        {props.storeError && !meta.error && !isPhoneValid && (
          <p
            style={{
              color: Colors.redDark,
              fontFamily: "Poppins",
              fontSize: "12px",
              marginBottom: "0px",
              padding: "6px 0px 0px 12px",
              position: "absolute",
            }}
          >
            Invalid Phone Number
          </p>
        )}
        {props.isClickedOnSubmit &&
          (field.value === "" || field.value == "+1") && (
            <p
              style={{
                color: Colors.redDark,
                fontFamily: "Poppins",
                fontSize: "12px",
                marginBottom: "0px",
                padding: "6px 0px 0px 12px",
                position: "absolute",
              }}
            >
              Invalid Phone Number
            </p>
          )}
        {meta.error && meta.touched ? (
          <p
            style={{
              color: Colors.redDark,
              fontFamily: "Poppins",
              fontSize: "12px",
              padding: "6px 10px 0px 12px",
              position: "absolute",
            }}
          >
            {meta.error}
          </p>
        ) : props.isClickedOnContinue && meta.error ? (
          <p
            style={{
              color: Colors.redDark,
              fontFamily: "Poppins",
              fontSize: "12px",
              padding: "6px 10px 0px 12px",
              position: "absolute",
            }}
          >
            {meta.error}
          </p>
        ) : props.error && meta.error ? (
          <p
            style={{
              color: Colors.redDark,
              fontFamily: "Poppins",
              fontSize: "12px",
              padding: "6px 10px 0px 12px",
              position: "absolute",
            }}
          >
            {meta.error}
          </p>
        ) : null}
      </div>
    </div>
  );
}

export default CustomPhoneNumberField;
