import React, { useEffect, useMemo, useState } from "react";
import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";
import { useAnalyticsStore, useFeatureFlags, useMyStore, userDataStore } from "../../../store";
import ChartTableComp from "../../shared/ChartTableComp";
import { useFlag } from "@unleash/proxy-client-react";

const TableSection = () => {
  const { isSnowflakeDisabled } = useFeatureFlags();

  const { isdesktop } = useDesktopOrLaptop();
  const {
    datePayload,
    topDeliveryProviders,
    getTopDeliveryProviders,
    // topStores,
    // getProviderTopStores,
    topPickupLocations,
    getTopPickupLocations,
  } = useAnalyticsStore();

  const { isSandbox, userId } = useMyStore();
  const { authInfo } = userDataStore();

  const styles = useMemo(() => {
    return {
      container: {
        display: "flex",
        flexDirection: isdesktop ? "row" : "column",
        justifyContent: isdesktop ? "space-between" : "flex-start",
        padding: "0 12px",
        marginBottom: isdesktop ? "14px" : "10px",
        paddingBottom: "60px",
      },
      chartTable: {
        width: isdesktop ? "49%" : "100%",
        height: "284px",
      },
    };
  }, [isdesktop]);
  const handleTopLocations = (page) => {
    if (page) {
      getTopPickupLocations(isSandbox, datePayload, userId ?? authInfo?.merchantAccountId, page, isSnowflakeDisabled);
    }
  };

  useEffect(() => {
    // console.log("first", userId);
    getTopDeliveryProviders(isSandbox, datePayload, userId ?? authInfo?.merchantAccountId, isSnowflakeDisabled);
    // getProviderTopStores(isSandbox, datePayload, userId);
    getTopPickupLocations(isSandbox, datePayload, userId ?? authInfo?.merchantAccountId, undefined, isSnowflakeDisabled);
  }, [isSandbox, datePayload, userId, authInfo]);
  return (
    <div style={styles.container}>
      <ChartTableComp
        style={styles.chartTable}
        title="Top Locations"
        payload={topPickupLocations}
        handleTopLocations={handleTopLocations}
      />
      <ChartTableComp style={styles.chartTable} title="Top Providers" payload={topDeliveryProviders} isImage />
    </div>
  );
};

export default TableSection;
