import { useFormikContext } from "formik";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";
import { VscTrash } from "react-icons/vsc";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import { useClickStore, useTheme } from "../../store";
import ReusableDropdownItem from "./StoresDropdownList";

const ReusableDropdown = ({
  isDropdownVisible,
  setIsDropdownVisible,
  data,
  children,
  height,
  placeHolder,
  placeHolderStyles,
  inputStyle={},
  onChange,
}) => {
  const { Colors } = useTheme();
  const { smfontsize, isdesktop } = useDesktopOrLaptop();
  const { useOnClickOutside } = useClickStore();

  const { values, setFieldValue } = useFormikContext();

  const clickRef = useRef();

  const [search, setSearch] = useState("");
  const handler = useCallback(() => {
    setIsDropdownVisible(false);
  }, []);

  useOnClickOutside(clickRef, handler);

  return (
    <div
      style={{ marginTop: "24px" }}
      ref={clickRef}
      className="flex w-full justify-between  relative items-center"
    >
      <button
        style={{
          width: "100%",
          backgroundColor: Colors.borderPrimary,
          borderRadius: "10px",
          padding: "0px",
          // padding: "9px 10px",
          color: Colors.fontGreyLight,
          fontWeight: "500",
          fontSize: smfontsize,
        }}
        onClick={() => {
          setIsDropdownVisible(!isDropdownVisible);
        }}
        type="button"
        className="flex focus:outline-none items-center justify-between"
      >
        <input
          value={isDropdownVisible ? search : placeHolder}
          onChange={(e) => {
            if (values.storeId) {
              setFieldValue("storeId", undefined);
            }
            setSearch(e.target.value);
            onChange(e.target.value);
          }}
          className="focus:outline-none"
          style={{
            width: "100%",
            height: "100%",
            padding: "9px 10px",
            backgroundColor: "transparent",
            borderRadius: "10px",
            color: Colors.fontGreyLight,
            ...inputStyle,
          }}
          placeholder={placeHolder}
          type="text"
        />
        {/* <span style={placeHolderStyles}>{placeHolder}</span> */}
        <RiArrowDropDownLine size={isdesktop ? 25 : 30} />
      </button>
      {isDropdownVisible && (
        <div
          style={{
            backgroundColor: Colors.bgDarkSecondary,
            width: "100%",
            top: "50px",
            maxHeight: "500px",
            overflowY: "scroll",
          }}
          className=" border-blue-500 rounded-lg absolute right-0  overflow-y-scroll myShadowLight z-20 flex flex-col justify-start"
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default ReusableDropdown;
