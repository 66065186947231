import axios from "axios";
import apiUrls from "../../constants/apiUrls";
import { generateQueryParams } from "./utils";

export const getDeliveryVolumeData = (params, isSnowflakeDisabled) => {
  const urlWithQueryParams = generateQueryParams(apiUrls.DELIVERY_VOLUME(isSnowflakeDisabled), params);
  return axios.get(urlWithQueryParams);
};

export const getDeliveryAmountData = (params, isSnowflakeDisabled) => {
  const urlWithQueryParams = generateQueryParams(apiUrls.DELIVERY_AMOUNT(isSnowflakeDisabled), params);
  return axios.get(urlWithQueryParams);
};

export const getProvidersUsedData = (params, isSnowflakeDisabled) => {
  const urlWithQueryParams = generateQueryParams(apiUrls.PROVIDERS_USED(isSnowflakeDisabled), params);
  return axios.get(urlWithQueryParams);
};

// export const getProviderTopStoresData = (params, isSnowflakeDisabled) => {
//   const urlWithQueryParams = generateQueryParams(apiUrls.PROVIDER_TOP_STORES(isSnowflakeDisabled), params);
//   return axios.get(urlWithQueryParams);
// };

// export const getOrdersValueData = (params, isSnowflakeDisabled) => {
//   const urlWithQueryParams = generateQueryParams(apiUrls.ORDERS_VALUE(isSnowflakeDisabled), params);
//   return axios.get(urlWithQueryParams);
// };

export const getDeliveryCitiesData = (params, isSnowflakeDisabled) => {
  const urlWithQueryParams = generateQueryParams(apiUrls.DELIVERY_CITIES(isSnowflakeDisabled), params);
  return axios.get(urlWithQueryParams);
};

export const getTopDeliveryPickupLocationsData = (params, isSnowflakeDisabled) => {
  const urlWithQueryParams = generateQueryParams(apiUrls.TOP_DELIVERY_PICKUP_LOCATIONS(isSnowflakeDisabled), params);
  return axios.get(urlWithQueryParams);
};

export const getDeliveryTimeData = (params, isSnowflakeDisabled) => {
  const urlWithQueryParams = generateQueryParams(apiUrls.DELIVERY_TIME(isSnowflakeDisabled), params);
  return axios.get(urlWithQueryParams);
};

export const getDeliveryTopTimingData = (params, isSnowflakeDisabled) => {
  const urlWithQueryParams = generateQueryParams(apiUrls.DELIVERY_TOP_TIMING(isSnowflakeDisabled), params);
  return axios.get(urlWithQueryParams);
};

export const getDeliveryAverageMilesData = (params, isSnowflakeDisabled) => {
  const urlWithQueryParams = generateQueryParams(apiUrls.DELIVERY_AVERAGE_MILES(isSnowflakeDisabled), params);
  return axios.get(urlWithQueryParams);
};

export const getAnalyticsOverviewData = (params, isSnowflakeDisabled) => {
  const urlWithQueryParams = generateQueryParams(apiUrls.ANALYTICS_OVERVIEW(isSnowflakeDisabled), params);
  return axios.get(urlWithQueryParams);
};

export const getDeliveryAverageData = (params, isSnowflakeDisabled) => {
  const urlWithQueryParams = generateQueryParams(apiUrls.DELIVERY_AVERAGE(isSnowflakeDisabled), params);
  return axios.get(urlWithQueryParams);
};

export const getDeliveryReturnData = (params, isSnowflakeDisabled) => {
  const urlWithQueryParams = generateQueryParams(apiUrls.DELIVERY_RETURN(isSnowflakeDisabled), params);
  return axios.get(urlWithQueryParams);
};

export const getAvgDeliveryPickUpTimeData = (params, isSnowflakeDisabled) => {
  const urlWithQueryParams = generateQueryParams(apiUrls.AVG_DELIVERY_PICK_UP_TIME(isSnowflakeDisabled), params);
  return axios.get(urlWithQueryParams);
};

export const getAvgDeliveryPickUpDelayTimeData = (params, isSnowflakeDisabled) => {
  const urlWithQueryParams = generateQueryParams(apiUrls.AVG_DELIVERY_PICK_UP_DELAY_TIME(isSnowflakeDisabled), params);
  return axios.get(urlWithQueryParams);
};

export const getAvgDeliveryDropOffTimeData = (params, isSnowflakeDisabled) => {
  const urlWithQueryParams = generateQueryParams(apiUrls.AVG_DELIVERY_DROP_OFF_TIME(isSnowflakeDisabled), params);
  return axios.get(urlWithQueryParams);
};

export const getAvgDeliveryDropOffDelayTimeData = (params, isSnowflakeDisabled) => {
  const urlWithQueryParams = generateQueryParams(apiUrls.AVG_DELIVERY_DROP_OFF_DELAY_TIME(isSnowflakeDisabled), params);
  return axios.get(urlWithQueryParams);
};

// export const getOrderVolumeData = (params) => {
//   const urlWithQueryParams = generateQueryParams(apiUrls.ORDER_VOLUME, params);
//   return axios.get(urlWithQueryParams);
// };

// export const getDeliveryReportData = (params) => {
//   const urlWithQueryParams = generateQueryParams(apiUrls.DELIVERY_REPORT, params);
//   return axios.get(urlWithQueryParams);
// };

// export const getDeliveryCostAverageData = (params) => {
//   const urlWithQueryParams = generateQueryParams(apiUrls.DELIVERY_COST_AVERAGE, params);
//   return axios.get(urlWithQueryParams);
// };

// export const getOrderValueAverageData = (params) => {
//   const urlWithQueryParams = generateQueryParams(apiUrls.ORDER_VALUE_AVERAGE, params);
//   return axios.get(urlWithQueryParams);
// };

// export const getProviderDeliveriesSentData = (params) => {
//   const urlWithQueryParams = generateQueryParams(apiUrls.PROVIDER_DELIVERIES_SENT, params);
//   return axios.get(urlWithQueryParams);
// };

// export const getProviderDeliveryAmountData = (params) => {
//   const urlWithQueryParams = generateQueryParams(apiUrls.PROVIDER_DELIVERY_AMOUNT, params);
//   return axios.get(urlWithQueryParams);
// };

// export const getProviderOrderValueData = (params) => {
//   const urlWithQueryParams = generateQueryParams(apiUrls.PROVIDER_ORDER_VALUE, params);
//   return axios.get(urlWithQueryParams);
// };

// export const getProviderCanceledData = (params) => {
//   const urlWithQueryParams = generateQueryParams(apiUrls.PROVIDER_CANCELED, params);
//   return axios.get(urlWithQueryParams);
// };

// export const getAverageOrderAmountDeliveryData = (params) => {
//   const urlWithQueryParams = generateQueryParams(apiUrls.AVERAGE_ORDER_AMOUNT_DELIVERY, params);
//   return axios.get(urlWithQueryParams);
// };

// export const getProviderDeliveryTimeAverageData = (params) => {
//   const urlWithQueryParams = generateQueryParams(apiUrls.PROVIDER_DELIVERY_TIME_AVERAGE, params);
//   return axios.get(urlWithQueryParams);
// };
