import React, { useEffect, useRef } from "react";
import DeliveriesAnalytics from "../DeliveriesAnalytics";
import {
  useMyStore,
  usePaymentStore,
  useTheme,
  useStoreAndChild,
  useFilter,
  userDataStore,
} from "../../store";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";

const StoreDeliveries = () => {
  const { Colors } = useTheme();

  const { isdesktop } = useDesktopOrLaptop();
  const { selectedStore } = useStoreAndChild();
  const {
    filterStore,
    startDate,
    setStartDate,
    search,
    setSearch,
    setFilterStore,
    filterArray,
  } = useFilter();

  const { isSandbox, setPageCount, deliveries, pageCount } = useMyStore();
  const { authInfo } = userDataStore();
  const { loadCardList } = usePaymentStore();

  const scrollRef = useRef(null);

  const scrollHandler = (top) => {
    scrollRef.current?.scrollTo({
      top: top,
      left: 0,
      behavior: "smooth",
    });
  };

  // useEffect(() => {
  //   window.analytics.page();
  // }, []);

  useEffect(() => {
    loadCardList(authInfo?.merchantAccountId);
  }, [authInfo]);

  useEffect(() => {
    deliveries(
      "",
      0,
      "",
      selectedStore ? selectedStore.id : filterStore?.id,
      filterArray
    );
    setPageCount(0);
    setSearch("");
    setStartDate("");
  }, [isSandbox, selectedStore]);

  useEffect(() => {
    deliveries(
      search,
      pageCount,
      startDate,
      selectedStore ? selectedStore.id : filterStore?.id ? filterStore?.id : "",
      filterArray
    );
    return () => {
      setFilterStore();
    };
  }, [pageCount]);

  return (
    <div
      ref={scrollRef}
      style={{
        padding: "0px 10px 100px 10px",
        backgroundColor: Colors.white,
        height: isdesktop ? `calc(100% - 170px)` : `calc(100% - 360px)`,
      }}
      className="md:w-full flex flex-col  shadow-sm overflow-y-auto   "
    >
      <div className={`lg:flex lg:flex-row  flex flex-col`}>
        <DeliveriesAnalytics
          scrollHandler={scrollHandler}
          isInsideStore={true}
        />
      </div>
    </div>
  );
};

export default StoreDeliveries;
