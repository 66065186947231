import React from "react";
import { useNavigate } from "react-router-dom";

import { BiDetail } from "react-icons/bi";
import { useTheme } from "../../../../store";
import { useDesktopOrLaptop } from "../../../../services/useDesktopOrLaptop";
import { numberWithCommas } from "../../../../utils/helpers";
import moment from "moment";
import PaymentStatusForInvoices from "../PaymentStatusForInvoices";

const InvoiceItems = ({ invoiceObject, disabled }) => {
  const { Colors, themeChanger } = useTheme();
  const { mdfontsize } = useDesktopOrLaptop();
  const navigate = useNavigate();

  return (
    <div
      style={{
        padding: "30px 0px",
        borderBottomWidth: "1.5px",
        borderColor: Colors.borderPrimary,
      }}
      className={`grid grid-cols-12 gap-1 w-full pb-2 items-center ${
        themeChanger ? "hover:bg-gray-100" : "hover:bg-gray-800"
      }`}
    >
      <div
        style={{
          fontSize: mdfontsize,
          fontWeight: "400",
          color: Colors.fontGreyLight,
        }}
        className="col-start-1 col-span-3 text-center flex justify-center items-center"
      >
        {moment(invoiceObject?.billing_period_start_at).format("MMM D, YYYY")} -{" "}
        {moment(invoiceObject?.billing_period_end_at).format("MMM D, YYYY")}
      </div>
      <div
        style={{
          fontSize: mdfontsize,
          fontWeight: "400",
          color: Colors.fontGreyLight,
        }}
        className="col-start-4 col-span-3 text-center"
      >
        {invoiceObject?.store_name}
      </div>
      <div
        style={{
          fontSize: mdfontsize,
          fontWeight: "400",
          color: Colors.fontGreyLight,
        }}
        className="col-start-7 col-span-2 flex justify-center items-center"
      >
        <PaymentStatusForInvoices data={invoiceObject?.payment_status} />
      </div>
      <div
        style={{
          fontSize: mdfontsize,
          fontWeight: "400",
          color: Colors.fontGreyLight,
        }}
        className="col-start-9 col-span-2 flex justify-center items-center"
      >
        {moment(invoiceObject?.payment_due_at).format("MMM D, YYYY")}
      </div>
      <div
        style={{
          fontSize: mdfontsize,
          fontWeight: "400",
          color: Colors.fontGreyLight,
        }}
        className="col-start-11 col-span-1 text-center"
      >
        $
        {invoiceObject?.amount_due
          ? numberWithCommas((invoiceObject.amount_due / 100)?.toFixed(2))
          : "0"}
      </div>
      <button
        type="button"
        style={{
          fontSize: mdfontsize,
          fontWeight: "400",
          color: Colors.fontGreyLight,
        }}
        className="col-start-12 col-span-1 cursor-pointer flex justify-center"
        disabled={disabled}
        onClick={(event) => {
          if (event.metaKey || event.ctrlKey) {
            const newTabURL = `${process.env.REACT_APP_STRIPE_RETURN_URL}/v1/settings/billing/invoice/${invoiceObject.id}`; // Replace this URL with the one you want to open
            window.open(newTabURL, "_blank");
          } else {
            navigate(`/v1/settings/billing/invoice/${invoiceObject.id}`);
          }
        }}
      >
        <BiDetail color={Colors.fontDark} size={22} />
      </button>
    </div>
  );
};

export default InvoiceItems;
