import React from "react";
import { useTheme, useClickStore, useMyStore, useMerchant } from "../../store";

import { AiOutlineCloseCircle } from "react-icons/ai";
import { ImSun } from "react-icons/im";
import { FaMoon } from "react-icons/fa";
import burqLOgo from "../../assets/png/dark.png";
import PanelButtonResponsive from "./PanelButtonResponsive";
import CommonToggle from "../CommonToggle";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import DropdownResponsive from "./DropdownResponsive";
import { useFlag } from "@unleash/proxy-client-react";
import Cookies from "js-cookie";
import { setCookie } from "../../utils";

export default function MobileNavigation({ setIsModal }) {
  const { Colors, themeChanger, changeTheme } = useTheme();

  const enabledMultiStop = useFlag("multistop");
  const enabledAnalytics = useFlag("analytics");

  const { isSandbox, setIsSandbox } = useMyStore();

  const { sidepanelactive, setSidepanelactive, setIsBurgerMenuVisible } = useClickStore();

  const { isdesktop } = useDesktopOrLaptop();
  const { merchantData, isAdmin } = useMerchant();
  const analyticsAsHomeEnabled = useFlag("analyticsAsHomeEnabled");

  const sandboxHandler = () => {
    setIsSandbox(!isSandbox);
    setCookie("testModeCookie", !isSandbox ? "true" : "false");
  };

  return (
    <div
      style={{
        position: "absolute",
        zIndex: 1000,
        backgroundColor: Colors.white,
        overflowY: "scroll",
        // backgroundColor: "red",

        display: "flex",
        flexDirection: "column",
        top: "0px",
        left: "0px",
        right: "0px",
        bottom: "0px",
        // paddingBottom: "140px",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "80px",
          padding: "0px 40px 0px 0px",
        }}
      >
        <button
          onClick={() => {
            setIsModal(false);
          }}
          style={{
            fontSize: "50px",
            color: "white",
            marginLeft: "30px",
          }}
        >
          <AiOutlineCloseCircle color={Colors.fontDark} size={60} />
        </button>
        <div
          style={{
            marginLeft: "20px",
          }}
          className="flex flex-row items-center"
        >
          <div
            className="font-semibold  "
            style={{
              fontFamily: "Poppins",
              marginRight: "12px",
              color: isSandbox ? Colors.bluePrimary : Colors.fontGreyLight,
              fontSize: "30px",
            }}
          >
            Test
          </div>
          <CommonToggle
            id="headerToggle"
            smsHandler={sandboxHandler}
            checked={isSandbox}
            translateX={isdesktop ? 14 : 20}
            circleStyle={{
              width: isdesktop ? "10px" : "30px",
              height: isdesktop ? "10px" : "30px",
              backgroundColor: Colors.white,
            }}
            style={{
              width: isdesktop ? "30px" : "56px",
              height: isdesktop ? "14px" : "32px",
              padding: "3px",
              backgroundColor: isSandbox ? Colors.bluePrimary : Colors.fontGreyLight,
            }}
          />
        </div>
      </div>
      <div style={{ marginTop: "10%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            marginTop: "60px",
          }}
        >
          {isAdmin && (
            <PanelButtonResponsive
              setSidepanelactive={setSidepanelactive}
              setBurger={setIsBurgerMenuVisible}
              sidepanelactive={sidepanelactive}
              active="admin"
              setIsModal={setIsModal}
            />
          )}
          {(isAdmin || enabledAnalytics || analyticsAsHomeEnabled) && (
            <PanelButtonResponsive
              setSidepanelactive={setSidepanelactive}
              setBurger={setIsBurgerMenuVisible}
              sidepanelactive={sidepanelactive}
              active={analyticsAsHomeEnabled ? "home" : "analytics"}
              setIsModal={setIsModal}
            />
          )}
          <PanelButtonResponsive
            id="ActivityId"
            setSidepanelactive={setSidepanelactive}
            setBurger={setIsBurgerMenuVisible}
            sidepanelactive={sidepanelactive}
            active={analyticsAsHomeEnabled ? "deliveries" : "home"}
            setIsModal={setIsModal}
          />
          {merchantData?.account_type === "platform" && (
            <PanelButtonResponsive
              id="accounts"
              setSidepanelactive={setSidepanelactive}
              setBurger={setIsBurgerMenuVisible}
              sidepanelactive={sidepanelactive}
              active="accounts"
              setIsModal={setIsModal}
            />
          )}

          <PanelButtonResponsive
            id="storesId"
            setSidepanelactive={setSidepanelactive}
            setBurger={setIsBurgerMenuVisible}
            sidepanelactive={sidepanelactive}
            active="stores"
            setIsModal={setIsModal}
          />
          {/* <PanelButtonResponsive
            id="statementsId"
            setSidepanelactive={setSidepanelactive}
            setBurger={setIsBurgerMenuVisible}
            sidepanelactive={sidepanelactive}
            active="statements"
            setIsModal={setIsModal}
          /> */}
          {merchantData?.account_type !== "connected" && (
            <PanelButtonResponsive
              id="developersId"
              setSidepanelactive={setSidepanelactive}
              setBurger={setIsBurgerMenuVisible}
              sidepanelactive={sidepanelactive}
              active="developers"
              setIsModal={setIsModal}
            />
          )}
          <PanelButtonResponsive
            id="settingsId"
            setSidepanelactive={setSidepanelactive}
            setBurger={setIsBurgerMenuVisible}
            sidepanelactive={sidepanelactive}
            active="settings"
            setIsModal={setIsModal}
          />
          {enabledMultiStop && (
            <>
              <PanelButtonResponsive
                id="orders"
                setSidepanelactive={setSidepanelactive}
                setBurger={setIsBurgerMenuVisible}
                sidepanelactive={sidepanelactive}
                active="orders"
                setIsModal={setIsModal}
              />
              <PanelButtonResponsive
                id="trips"
                setSidepanelactive={setSidepanelactive}
                setBurger={setIsBurgerMenuVisible}
                sidepanelactive={sidepanelactive}
                active="trips"
                setIsModal={setIsModal}
              />
            </>
          )}
          {merchantData?.settings?.is_self_setting_management_enabled && merchantData?.account_type !== "connected" && (
            <DropdownResponsive
              id="workflowId"
              setSidepanelactive={setSidepanelactive}
              setBurger={setIsBurgerMenuVisible}
              sidepanelactive={sidepanelactive}
              active="workflows"
              dropDown
              setIsModal={setIsModal}
              // setIsExpanded={setIsWorkflowExpanded}
            />
          )}
          <PanelButtonResponsive
            id="Log Out"
            setSidepanelactive={setSidepanelactive}
            setBurger={setIsBurgerMenuVisible}
            sidepanelactive={sidepanelactive}
            active="logOut"
            setIsModal={setIsModal}
          />
        </div>
      </div>
      <div
        style={{
          width: "100%",
          height: "100px",
          display: "flex",
          justifyContent: "space-between",
          marginTop: "60px",
          marginBottom: "60px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {themeChanger ? (
            <button
              onClick={() => {
                changeTheme();
              }}
              type="button"
              style={{
                marginLeft: "60px",
                outline: "none",
                marginRight: "30px",
              }}
            >
              <ImSun color="#d4af37" size={70} />
            </button>
          ) : (
            <button
              onClick={async () => {
                changeTheme();
              }}
              type="button"
              style={{
                marginLeft: "60px",
                outline: "none",
                marginRight: "30px",
              }}
            >
              <FaMoon color="lightgrey" size={70} />
            </button>
          )}
          {/* <div>test Environment</div> */}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "50px",
          }}
        >
          <div
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "15px",
              marginRight: "30px",
              color: Colors.fontDark,
            }}
          >
            Burq V1.0.0
          </div>
          <img style={{ width: "100px", height: "100px", objectFit: "contain" }} src={burqLOgo} alt="Burq logo" />
        </div>
      </div>
    </div>
  );
}
