import { useState } from "react";
import * as Sentry from "@sentry/react";
import { createStore } from "aniuta";
import { downloadStatementCSVCall } from "../utils/api/statements";
import { toast } from "react-toastify";

const useStatementsCsv = createStore({
  name: "useStatementsCsv",
  Store: () => {
    const [isStatementCSVLoading, setIsStatementCSVLoading] = useState(false);
    const getStatementDownloadCSV = async (id, params) => {
      try {
        const { data } = await downloadStatementCSVCall(id, params);
        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
        return "errror";
      } finally {
        setIsStatementCSVLoading(false);
      }
    };
    return {
      getStatementDownloadCSV,
      isStatementCSVLoading,
      setIsStatementCSVLoading,
    };
  },
});

export default useStatementsCsv;
