import React, { useEffect, useState } from "react";
import { compose, withProps } from "recompose";
import {
  withGoogleMap,
  GoogleMap,
  DirectionsRenderer,
  Marker,
  OverlayView,
} from "react-google-maps";

// import moment from "moment";
import { geolocated } from "react-geolocated";
// import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import dropoffMarker from "../../assets/png/pickup.png";

import pickupMarker from "../../assets/png/dropoff.png";
import newCar from "../../assets/png/carTrack.png";
import newVan from "../../assets/png/vanTrack.png";
import newTruck from "../../assets/png/truckTrack.png";

import { useOrdersForMap, useTheme } from "../../store";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import { geocodeByAddress } from "react-places-autocomplete";
import { LIGHT_MAP } from "../../runTimeConst";

const MapForOrders = compose(
  withProps({
    // googleMapURL:
    //   "https://maps.googleapis.com/maps/api/js?key=AIzaSyB56CDm80M7ITJq402N0J2H4qvuk1KNT6w&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  // withScriptjs,
  withGoogleMap
)(({ itemForDetailes }) => {
  const [tripsStopsArray, setTripsStopsArray] = useState([]);
  const [tripsDirections, setTripsDirections] = useState();

  const getOverlayViewMarkerSrc = () => {
    switch (itemForDetailes?.provider?.driver?.vehicle) {
      case "truck":
        return newTruck;
      case "van":
        return newVan;
      default:
        return newCar;
    }
  };

  const constructName = (firstName, lastName) => {
    let nameArray = [firstName, lastName];
    nameArray = nameArray.filter(Boolean);
    return nameArray.join(" ");
  };

  const calculateAndDisplayDriverRoute = async (tripStopsArray) => {
    const tripsForMap = [];
    let _tripsDirections = [];

    const notCompletedStops = await tripStopsArray.filter((stop) => {
      return stop.status !== "completed";
    });

    for (let i = 0; i < notCompletedStops.length; i++) {
      const coords = await geocodeByAddress(notCompletedStops[i].address);
      const coordsLoc = coords[0].geometry.location;

      tripsForMap.push({
        address: notCompletedStops[i].address,
        id: notCompletedStops[i].id,
        type: notCompletedStops[i].type,
        location: {
          lat: coordsLoc.lat(),
          lng: coordsLoc.lng(),
        },
      });
    }

    const DirectionsService = new window.google.maps.DirectionsService();
    const { last_latitude, last_longitude } = itemForDetailes?.provider?.driver;

    for (let i = 0; i < tripsForMap.length; i++) {
      const data = await DirectionsService.route({
        origin:
          i === 0
            ? new window.google.maps.LatLng(last_latitude, last_longitude)
            : tripsForMap[i - 1].location,
        destination: tripsForMap[i].location,
        travelMode: window.google.maps.TravelMode.DRIVING,
      });
      _tripsDirections.push(data);
    }

    setTripsDirections(_tripsDirections);
  };

  const ordersInArrayHandler = async (tripStopsArray) => {
    const tripsForMap = [];

    for (let i = 0; i < tripStopsArray.length; i++) {
      const coords = await geocodeByAddress(tripStopsArray[i].address);
      const coordsLoc = coords[0].geometry.location;

      tripsForMap.push({
        address: tripStopsArray[i].address,
        id: tripStopsArray[i].id,
        type: tripStopsArray[i].type,
        location: {
          lat: coordsLoc.lat(),
          lng: coordsLoc.lng(),
        },
      });
    }

    if (!showDriverMarker()) {
      routeHandler(tripsForMap);
    } else {
      calculateAndDisplayDriverRoute(tripStopsArray);
    }

    if (tripsStopsArray.length > 0 && zoom === 2) {
      setZoom(14);
    } else if (tripsStopsArray.length === 0) {
      setZoom(2);
    }
    setTripsStopsArray(tripsForMap);
  };
  useEffect(() => {
    ordersInArrayHandler(itemForDetailes.stops);
  }, [itemForDetailes]);

  const showDriverMarker = () => {
    if (
      itemForDetailes?.provider?.driver?.last_latitude &&
      itemForDetailes?.provider?.driver?.last_longitude
    ) {
      return true;
    }
    return false;
  };

  const { Colors } = useTheme();
  const { smfontsize, xsfontsize } = useDesktopOrLaptop();
  const [zoom, setZoom] = useState(2);

  const routeHandler = async (tripsStopsArray) => {
    const DirectionsService = new window.google.maps.DirectionsService();

    let _tripsDirections = [];

    for (let i = 0; i < tripsStopsArray.length - 1; i++) {
      const data = await DirectionsService.route({
        origin: tripsStopsArray[i].location,
        destination: tripsStopsArray[i + 1].location,
        travelMode: window.google.maps.TravelMode.DRIVING,
      });
      _tripsDirections.push(data);
    }

    setTripsDirections(_tripsDirections);
  };

  const getPixelPositionOffset = (width, height) => ({
    x: -30,
    y: -30,
  });

  return (
    <GoogleMap
      defaultOptions={{
        styles: LIGHT_MAP,
        clickableIcons: false,
        fullscreenControl: false,
        disableDefaultUI: true,
        mapTypeControl: false,
        gestureHandling: "greedy",
      }}
      zoom={zoom}
      center={
        showDriverMarker()
          ? {
              lat: parseFloat(itemForDetailes?.provider?.driver?.last_latitude),
              lng: parseFloat(
                itemForDetailes?.provider?.driver?.last_longitude
              ),
            }
          : tripsStopsArray[tripsStopsArray.length - 1]?.location || {
              lat: 0,
              lng: 0,
            }
      }
    >
      {tripsStopsArray.length > 0 &&
        tripsStopsArray.map((item, index) => {
          if (item.type === "pickup") {
            return (
              <OverlayView
                key={item.id}
                getPixelPositionOffset={getPixelPositionOffset}
                position={{
                  lat: item.location.lat,
                  lng: item.location.lng,
                }}
                mapPaneName={OverlayView?.OVERLAY_MOUSE_TARGET}
              >
                <div>
                  <div
                    style={{
                      position: "absolute",
                      top: "-80px",
                      paddingBottom: "45px",
                      left: "-50%",
                      height: "130px",
                    }}
                    className="hover:opacity-100 opacity-0"
                  >
                    <div
                      className="myShadow flex flex-col justify-between"
                      style={{
                        height: "75px",
                        width: "160px",
                        backgroundColor: Colors.white,
                        borderRadius: "12px",
                        padding: "14px 10px",
                      }}
                    >
                      <div
                        style={{
                          fontSize: xsfontsize,
                          color: Colors.fontGreyLight,
                        }}
                      >
                        Pickup:
                      </div>
                      <div
                        style={{ fontSize: smfontsize, color: Colors.fontDark }}
                        className="truncate"
                      >
                        {/* {item.store.name} */}
                        {item.address}
                      </div>
                    </div>
                  </div>
                  <img
                    style={{
                      height: "42px",
                      width: "42px",
                    }}
                    src={pickupMarker}
                    alt=""
                  />
                </div>
              </OverlayView>
            );
          } else {
            return (
              <OverlayView
                key={item.id}
                getPixelPositionOffset={getPixelPositionOffset}
                position={{
                  lat: item.location.lat,
                  lng: item.location.lng,
                }}
                mapPaneName={OverlayView?.OVERLAY_MOUSE_TARGET}
              >
                <div>
                  <div
                    style={{
                      position: "absolute",
                      top: "-80px",
                      paddingBottom: "45px",
                      left: "-50%",
                      height: "130px",
                    }}
                    className="hover:opacity-100 opacity-0"
                  >
                    <div
                      className="myShadow flex flex-col justify-between"
                      style={{
                        height: "75px",
                        width: "160px",
                        backgroundColor: Colors.white,
                        borderRadius: "12px",
                        padding: "14px 10px",
                      }}
                    >
                      <div
                        style={{
                          fontSize: xsfontsize,
                          color: Colors.fontGreyLight,
                        }}
                      >
                        Stop {index} :
                      </div>
                      <div
                        className="truncate"
                        style={{ fontSize: smfontsize, color: Colors.fontDark }}
                      >
                        {item.address}
                      </div>
                    </div>
                  </div>
                  <img
                    style={{
                      height: "42px",
                      width: "42px",
                    }}
                    src={dropoffMarker}
                    alt=""
                  />
                </div>
              </OverlayView>
            );
          }
        })}

      {tripsStopsArray.length > 0 && showDriverMarker() && (
        <OverlayView
          key="driver-overlay"
          getPixelPositionOffset={getPixelPositionOffset}
          position={{
            lat: itemForDetailes?.provider?.driver.last_latitude,
            lng: itemForDetailes?.provider?.driver.last_longitude,
          }}
          mapPaneName={OverlayView?.OVERLAY_MOUSE_TARGET}
        >
          <div>
            <div
              style={{
                position: "absolute",
                top: "-80px",
                paddingBottom: "45px",
                left: "-50%",
                height: "130px",
              }}
              className="hover:opacity-100 opacity-0"
            >
              <div
                className="myShadow flex flex-col justify-between"
                style={{
                  height: "75px",
                  width: "160px",
                  backgroundColor: Colors.white,
                  borderRadius: "12px",
                  padding: "14px 10px",
                }}
              >
                <div
                  style={{
                    fontSize: xsfontsize,
                    color: Colors.fontGreyLight,
                  }}
                >
                  Driver:
                </div>
                <div
                  className="truncate"
                  style={{ fontSize: smfontsize, color: Colors.fontDark }}
                >
                  {constructName(
                    itemForDetailes?.provider?.driver?.first_name,
                    itemForDetailes?.provider?.driver?.last_name
                  )}
                </div>
              </div>
            </div>
            <img
              style={{
                height: "42px",
                width: "42px",
              }}
              src={getOverlayViewMarkerSrc()}
              alt=""
            />
          </div>
        </OverlayView>
      )}

      {tripsDirections?.length > 0 &&
        tripsDirections.map((item, index) => {
          return (
            <DirectionsRenderer
              key={index}
              options={{
                suppressMarkers: true,
                polylineOptions: { strokeColor: "#1460EF" },
              }}
              directions={item}
            />
          );
        })}
    </GoogleMap>
  );
});

// !

function TripsMap({ itemForDetailes }) {
  return (
    <div className=" w-full h-full relative ">
      <MapForOrders
        itemForDetailes={itemForDetailes}
        containerElement={<div style={{ height: "100%", width: "100%" }} />}
      />
    </div>
  );
}
export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 1,
})(TripsMap);
