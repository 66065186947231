import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { usePaymentStore, userDataStore } from "../../../store";
import { constructName } from "../../../utils/helpers";
import { stripePromise } from "../../../runTimeConst";
import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";
import CustomModal from "../../CustomModal/CustomModal";
import PaymentStatus from "./PaymentStatus";
import PaymentElementCheckoutForm from "./PaymentElementCheckoutForm";

const StripePaymentElement = ({ returnUrl, successMessage = false }) => {
  const { authInfo } = userDataStore();
  const { isdesktop } = useDesktopOrLaptop();

  const { addCardModal, setAddCardModal, setupIntent } = usePaymentStore();

  const appearance = {
    theme: "stripe",
  };

  const options = {
    clientSecret: setupIntent,
    appearance,
  };

  const paymentElementOptions = {
    layout: "tabs",
    defaultValues: {
      billingDetails: {
        email: authInfo?.email,
        name: constructName(authInfo?.first_name, authInfo?.last_name),
      },
    },
  };

  return (
    <div>
      {setupIntent && (
        <Elements options={options} stripe={stripePromise}>
          <PaymentStatus successMessage={successMessage} />
          <CustomModal
            isOpen={addCardModal}
            onClose={() => setAddCardModal(false)}
            extraPanelStyles={{ maxWidth: isdesktop ? "650px" : "80%" }}
          >
            <div style={{ overflowX: "auto" }}>
              <PaymentElementCheckoutForm
                returnUrl={returnUrl}
                options={paymentElementOptions}
              />
            </div>
          </CustomModal>
        </Elements>
      )}
    </div>
  );
};

export default StripePaymentElement;
