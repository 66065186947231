import React, { useEffect, useMemo, useState } from "react";
import { VscTrash } from "react-icons/vsc";
import { EditIcon } from "../../assets/svgs/Svgs";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import {
  useClickStore,
  useConnectedAccounts,
  useFilter,
  useMerchant,
  userDataStore,
  useStoreAndChild,
  useTheme,
} from "../../store";
import ToolTip from "../Tooltip/Tooltip";
import QuoteWorkflow from "../workflow/QuoteWorkflow";
import SmsWorkflow from "../workflow/SmsWorkflow";
import PaymentMethods from "./PaymentMethods";
import { useParams } from "react-router-dom";
import HomePage from "../HomePage";
import StoreDeliveries from "./StoreDeliveries";
import { ROLES } from "../AccountSettings/RolesSidebar/helpers";

const StoreDetailedPage = () => {
  const { isdesktop, mdfontsize, smfontsize, lgfontsize } =
    useDesktopOrLaptop();
  const { Colors } = useTheme();
  const { isAdmin } = useMerchant();
  const { setStartDate, setSearch, setFilterStore, setFilterArray } =
    useFilter();
  const {
    setIsCreateStoreModalOpen,
    setStoreForEdit,
    selectedStore,
    setSelectedStore,
    getStoreById,
  } = useStoreAndChild();
  const { isBurgerMenuVisible, setIsBurgerMenuVisible, setIsCalendarVisible } =
    useClickStore();
  const { setSelectedConnectedAccount } = useConnectedAccounts();
  const { authInfo, userRole } = userDataStore();
  const [activeStorePage, setActiveStorePage] = useState("deliveries");

  const { id } = useParams();
  const tabProp = new URLSearchParams(window.location.search).get("active");

  const showPaymentMethod = (selectedStore?.merchant_account_id === authInfo?.merchantAccountId || isAdmin) &&
    (userRole === ROLES.ADMIN || userRole === ROLES.STORE_MANAGER || isAdmin) && selectedStore?.enable_store_payment_method;

  const styles = useMemo(() => {
    return {
      buttons: {
        fontFamily: "Poppins",
        fontWeight: "600",
        fontSize: mdfontsize,
        justifyContent: "center",
        alignItems: "center",
      },
      bottomLine: {
        width: "80px",
        height: "4px",
        borderBottomLeftRadius: "4px",
        borderBottomRightRadius: "4px",
      },
    };
  }, [mdfontsize]);

  useEffect(() => {
    if (tabProp === "paymentMethods" && showPaymentMethod) {
      setActiveStorePage(tabProp);
    }
  }, [tabProp, selectedStore, authInfo, isAdmin]);

  useEffect(() => {
    return () => {
      setStartDate();
      setSearch("");
      setFilterStore();
      setFilterArray([]);
      setActiveStorePage("deliveries");
      // setSelectedStore();
    };
  }, []);

  useEffect(() => {
    if (id !== selectedStore?.id) {
      getStoreById(id).then((res) => {
        setSelectedStore(res);
      });
    }
  }, [id]);

  if (!selectedStore) {
    return null;
  }

  return (
    <div className="h-full">
      <div
        style={{
          backgroundColor: isdesktop ? Colors.white : Colors.white,
          padding: "16px 24px 0px 24px",
        }}
      >
        <div
          style={{
            marginBottom: "10px",
            paddingBottom: "6px",
          }}
          className="w-full flex items-center justify-between"
        >
          <div
            style={{
              width: isdesktop ? "85%" : "80%",
            }}
            className={`w-full pr-6 `}
          >
            <div className="flex items-center w-full">
              <div
                style={{
                  color: Colors.fontDark,
                  fontSize: lgfontsize,
                  marginBottom: "4px",
                  fontWeight: "500",
                }}
                className="truncate capitalize"
              >
                {selectedStore?.name}
              </div>
              <div
                className="truncate"
                style={{
                  whiteSpace: "nowrap",
                  fontSize: smfontsize,
                  fontWeight: "400",
                  color: Colors.fontGreyLight,
                  paddingLeft: "20px",
                }}
              >
                {selectedStore?.id}
              </div>
            </div>
            <div className="flex items-center my-4 w-full">
              {selectedStore?.merchant_store_id && (
                <div
                  className="truncate  mr-8 "
                  style={{
                    fontSize: smfontsize,
                    color: Colors.fontGreyLight,
                    maxWidth: "50%",
                  }}
                >
                  {selectedStore?.merchant_store_id}
                </div>
              )}
              <div
                className="truncate capitalize "
                style={{
                  fontSize: smfontsize,
                  color: Colors.fontGreyLight,
                  maxWidth: "80%",
                }}
              >
                {selectedStore?.address}
              </div>
              <div
                className="truncate capitalize ml-8"
                style={{
                  fontSize: smfontsize,
                  color: Colors.fontGreyLight,
                  maxWidth: "20%",
                }}
              >
                {selectedStore?.phone_number[0] !== "+"
                  ? "+" + selectedStore?.phone_number
                  : selectedStore?.phone_number}
              </div>
              <div
                className="truncate capitalize ml-8"
                style={{
                  fontSize: smfontsize,
                  color: Colors.fontGreyLight,
                  maxWidth: "20%",
                }}
              >
                {selectedStore.source_demand_side_platform}
              </div>
            </div>
          </div>
          <div style={{ fontSize: smfontsize }} className="flex items-center  ">
            {(userRole === ROLES.ADMIN ||
              userRole === ROLES.STORE_MANAGER ||
              isAdmin) && (
              <button
                onClick={(e) => {
                  setStoreForEdit(selectedStore);
                  setIsCreateStoreModalOpen(true);
                  setSelectedConnectedAccount();
                  setIsCalendarVisible(false);
                  e.stopPropagation();
                }}
                type="button"
                className="mr-4 outline-none flex"
              >
                <EditIcon />
              </button>
            )}
          </div>
        </div>
        <div
          className={`flex flex-row items-center  ${!isdesktop && "scrollbar"}`}
        >
          <button
            id="deliveriesTabButton"
            style={{
              ...styles.buttons,
              ...{
                color:
                  activeStorePage === "deliveries"
                    ? Colors.bluePrimary
                    : Colors.fontGreyLight,
                transition: "all 0.5s",
                outline: "none",
              },
            }}
            type="button"
            onClick={() => {
              setActiveStorePage("deliveries");
            }}
            className={`focus:outline-none flex flex-col`}
          >
            Deliveries
            <div
              style={{
                ...styles.bottomLine,
                ...{
                  transition: "all 0.5s",
                  outline: "none",
                  backgroundColor:
                    activeStorePage === "deliveries"
                      ? Colors.bluePrimary
                      : Colors.white,
                },
              }}
            ></div>
          </button>
          {showPaymentMethod && (
              <button
                id="paymentMethods"
                onClick={() => {
                  setActiveStorePage("paymentMethods");
                }}
                type="button"
                style={{
                  ...styles.buttons,
                  ...{
                    color:
                      activeStorePage === "paymentMethods"
                        ? Colors.bluePrimary
                        : Colors.fontGreyLight,
                    marginLeft: "40px",
                    transition: "all 0.5s",
                    outline: "none",
                  },
                }}
                className={`focus:outline-none flex flex-col `}
              >
                Payment Method
                <div
                  style={{
                    ...styles.bottomLine,
                    ...{
                      transition: "all 0.5s",
                      outline: "none",
                      backgroundColor:
                        activeStorePage === "paymentMethods"
                          ? Colors.bluePrimary
                          : Colors.white,
                    },
                  }}
                ></div>
              </button>
            )}
        </div>
      </div>
      {activeStorePage === "deliveries" ? (
        <StoreDeliveries />
      ) : activeStorePage === "paymentMethods" ? (
        <PaymentMethods />
      ) : (
        <div
          style={{
            backgroundColor: isdesktop ? Colors.bgGrey : Colors.white,
            height: isdesktop ? `calc(100vh - 70px)` : `calc(100vh - 200px)`,
            overflowY: "auto",
            display: "flex",
            flexDirection: "row",
            width: "100%",
          }}
        >
          {activeStorePage === "sms" ? (
            <SmsWorkflow
              isdesktop={isdesktop}
              setBurger={setIsBurgerMenuVisible}
              burger={isBurgerMenuVisible}
              isInsideStore
            />
          ) : (
            <QuoteWorkflow
              isdesktop={isdesktop}
              setBurger={setIsBurgerMenuVisible}
              burger={isBurgerMenuVisible}
              isInsideStore
            />
          )}
        </div>
      )}
    </div>
  );
};

export default StoreDetailedPage;
