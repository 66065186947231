import axios from "axios";
import apiUrls from "../../constants/apiUrls";
import { generateQueryParams } from "./utils";

export const createConnectedAccount = (id, payload) =>
  axios.post(apiUrls.CONNECTED_ACCOUNTS(id), payload, {
    headers: { "x-burq-client": "client-dashboard" },
  });

export const getMerchantAccountsStripeSetupIntent = (id, payload = {}) =>
  axios.post(apiUrls.MERCHANT_ACCOUNTS_STRIPE_SETUP_INTENT(id), payload, {
    headers: { "x-burq-client": "client-dashboard" },
  });

export const getMerchantAccountStores = (id, params) => {
  const urlWithQueryParams = generateQueryParams(
    apiUrls.MERCHANT_ACCOUNT_STORES(id),
    params
  );
  return axios.get(urlWithQueryParams, {
    headers: { "x-burq-client": "client-dashboard" },
  });
};

export const getStore = (id) =>
  axios.get(apiUrls.STORE(id), {
    headers: { "x-burq-client": "client-dashboard" },
  });

export const deleteConnectedAccount = (id) =>
  axios.post(
    apiUrls.DELETE_CONNECTED_ACCOUNT(id),
    {},
    {
      headers: { "x-burq-client": "client-dashboard" },
    }
  );

export const getConnectedAccountsList = (id, payload) => {
  const urlWithQueryParams = generateQueryParams(
    apiUrls.CONNECTED_ACCOUNTS(id),
    payload
  );
  return axios.get(urlWithQueryParams, {
    headers: { "x-burq-client": "client-dashboard" },
  });
};

export const getProviders = (id) =>
  axios.get(apiUrls.MERCHANT_ENABLED_PROVIDERS(id), {
    headers: { "x-burq-client": "client-dashboard" },
  });

export const updateRerouteConfig = (id, payload) =>
  axios.put(apiUrls.UPDATE_REROUTE_CONFIG(id), payload, {
    headers: { "x-burq-client": "client-dashboard" },
  });
