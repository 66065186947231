import React from "react";
import { useDesktopOrLaptop } from "../../../../services/useDesktopOrLaptop";
import { useTheme } from "../../../../store";
import { BiChevronRight } from "react-icons/bi";

const WorkflowSidebarOpener = ({
  setIsLeftOpened,
  isLeftOpened,
  isInsideStore,
}) => {
  const { isdesktop } = useDesktopOrLaptop();
  const { Colors } = useTheme();
  return (
    <button
      type="button"
      className="myShadow kata"
      onClick={() => setIsLeftOpened(!isLeftOpened)}
      style={{
        position: "absolute",
        top: isdesktop
          ? isInsideStore
            ? "230px"
            : "130px"
          : isInsideStore
          ? "380px"
          : "220px",
        left: isdesktop
          ? isLeftOpened
            ? "335px"
            : "-15px"
          : isLeftOpened
          ? "330px"
          : "0px",
        zIndex: 21,
        height: isdesktop ? "30px" : "40px",
        width: isdesktop ? "30px" : "40px",
        borderRadius: "100px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: isdesktop ? Colors.bgGrey : Colors.white,
        transform: isLeftOpened ? `rotate(180deg)` : `rotate(0deg)`,
      }}
    >
      <BiChevronRight size={26} color={Colors.fontDark} />
    </button>
  );
};
export default WorkflowSidebarOpener;
