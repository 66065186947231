import { useFormikContext } from "formik";
import React from "react";

import DefaultVehicle from "../../../assets/png/vehicles/devaultVehicle.png";
import car from "../../../assets/png/vehicles/car.png";
import van from "../../../assets/png/vehicles/van.png";
import truck from "../../../assets/png/vehicles/truck.png";
import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";
import styles from "./styles.module.css";
import { useTheme } from "../../../store";

function VehicleTab({ trip, isReroute }) {
  const { Colors, themeChanger } = useTheme();
  const { isdesktop, smfontsize } = useDesktopOrLaptop();

  const { values, setFieldValue } = useFormikContext();

  return (
    <div className={styles.container}>
      <div className={styles.title}>Select a Vehicle</div>
      <div className={styles.cardContainer}>
        {!trip && (
          <button
            onClick={() => {
              setFieldValue("vehicleType", "");
            }}
            type="button"
            className={styles.card}
            style={{
              borderWidth: values.vehicleType == "" ? "3px" : "0px",
              borderColor: Colors.bluePrimary,
              backgroundColor: themeChanger ? "#FDF5EE" : "#312A34",
              padding: isdesktop ? "26px 8px 0px 8px" : "30px 0px",
              height: isdesktop ? "230px" : "300px",
              width: trip ? "32%" : "24%",
            }}
          >
            <div className="flex flex-col items-center">
              <img
                style={{
                  height: isdesktop ? "28px" : "34px",
                  width: isdesktop ? "46px" : "52px",
                  objectFit: "contain",
                }}
                src={DefaultVehicle}
                alt=""
              />
              <div className={styles.cardTitle}>Default</div>
            </div>
            <div className={styles.cardText}>
              Have us decide between scooters, mopeds, bikes, or sedans
            </div>
          </button>
        )}
        <button
          onClick={() => {
            if (!isReroute) {
              setFieldValue("vehicleType", "car");
            }
          }}
          type="button"
          className={styles.card}
          style={{
            borderWidth: values.vehicleType == "car" ? "3px" : "0px",
            borderColor: Colors.bluePrimary,
            backgroundColor: themeChanger ? "#FDF5EE" : "#312A34",
            padding: isdesktop ? "26px 8px 0px 8px" : "30px 0px",
            height: isdesktop ? "230px" : "300px",
            width: trip ? "32%" : "24%",
          }}
        >
          <div className="flex flex-col items-center">
            <img
              style={{
                height: isdesktop ? "32px" : "36px",
                width: isdesktop ? "50px" : "56px",
                objectFit: "contain",
              }}
              src={car}
              alt=""
            />
            {/* <CarSvg /> */}
            <div className={styles.cardTitle}>Car</div>
          </div>
          <div className={styles.cardText}>
            Small items - food, grocery, flowers
          </div>
        </button>
        <button
          onClick={() => {
            if (!isReroute) {
              setFieldValue("vehicleType", "suv");
            }
          }}
          type="button"
          className={styles.card}
          style={{
            borderWidth: values.vehicleType == "suv" ? "3px" : "0px",
            borderColor: Colors.bluePrimary,
            backgroundColor: themeChanger ? "#FDF5EE" : "#312A34",
            padding: isdesktop ? "26px 8px 0px 8px" : "30px 0px",
            height: isdesktop ? "230px" : "300px",
            width: trip ? "32%" : "24%",
          }}
        >
          <div className="flex flex-col items-center">
            <img
              style={{
                height: isdesktop ? "32px" : "36px",
                width: isdesktop ? "50px" : "56px",
                objectFit: "contain",
              }}
              src={van}
              alt=""
            />
            {/* <VanSvg /> */}
            <div className={styles.cardTitle}>SUV</div>
          </div>
          <div className={styles.cardText}>
            Medium size items - retail bags, boxes, catering
          </div>
        </button>
        <button
          onClick={() => {
            if (!isReroute) {
              setFieldValue("vehicleType", "truck");
            }
          }}
          type="button"
          className={styles.card}
          style={{
            borderWidth: values.vehicleType == "truck" ? "3px" : "0px",
            borderColor: Colors.bluePrimary,
            backgroundColor: themeChanger ? "#FDF5EE" : "#312A34",
            padding: isdesktop ? "26px 8px 0px 8px" : "30px 0px",
            height: isdesktop ? "230px" : "300px",
            width: trip ? "32%" : "24%",
          }}
        >
          <div className="flex flex-col items-center">
            <img
              style={{
                height: isdesktop ? "32px" : "36px",
                width: isdesktop ? "50px" : "56px",
                objectFit: "contain",
              }}
              src={truck}
              alt=""
            />
            {/* <TruckSvg /> */}
            <div className={styles.cardTitle}>Pickup Truck</div>
          </div>
          <div className={styles.cardText}>
            Large size items - furniture, appliances
          </div>
        </button>
      </div>
    </div>
  );
}

export default VehicleTab;
