import React, { useRef, useState } from "react";
import styles from "./styles/paymentMethodListItem.module.css";
import { allowedBadgesForBankAccountStatuses, allowedBadgesForCardStatuses, badgesForCardsNumber } from "./utils";
import CardLogo from "./CardLogo";
import { usePaymentStore, useTheme, userDataStore } from "../../store";
import StatusBadgeReusable from "../shared/common/StatusBadgeReusable";
import { InfoSVG, SettingSVG } from "./Svgs";
import AreYouSurePopup from "../shared/common/AreYouSurePopup";
import ToolTip from "../Tooltip/Tooltip";
import { LearnMoreLink } from "../../constants";
import ActionMenu from "../shared/ActionMenu/ActionMenu";

const PaymentMethodListItem = ({ data }) => {
  const { Colors } = useTheme();
  const actionMenuRef = useRef();
  const { authInfo } = userDataStore();

  const { removeSelectedCard, setSelectedCardDefault } = usePaymentStore();
  const [isSettingOpen, setIsSettingOpen] = useState(false);
  const [deletePMId, setDeletePMId] = useState(null);

  const settingClickHandler = () => {
    setIsSettingOpen((oldvalue) => {
      return !oldvalue;
    });
  };
  const onDefaultClickHandler = () => {
    if (data?.is_default) {
      return;
    }
    const paymentMethodId = data?.is_default ? null : data?.id;

    setSelectedCardDefault(authInfo.merchantAccountId, paymentMethodId);
  };
  const onDeleteClickHandler = () => {
    setDeletePMId(data?.id);
  };
  const actionItems = [
    {
      label: "Delete",
      action: onDeleteClickHandler,
    },
  ];
  if (!data.is_default) {
    actionItems.push({
      label: "Make as Default",
      action: onDefaultClickHandler,
    });
  }

  if (data?.type !== "card" && data?.type !== "us_bank_account") return null;

  const unverifiedTooltip = (
    <span className={styles.tooltipText}>
      Once you receive micro-deposits in your bank account (usually within 2-3 days after you added the payment method), please
      click on the verify link to complete payment method verification.{" "}
      <a style={{ color: Colors.bluePrimary }} href={LearnMoreLink} target="_blank" rel="noreferrer">
        Learn More.
      </a>
    </span>
  );
  const linedExpiredTooltip = (
    <span className={styles.tooltipText}>
      The micro-deposit verification of this payment method has expired (usually ten days after the payment method was added).
      Please click to restart the verification process by re-entering your bank account info and waiting for a new micro-deposit
      to be processed.{" "}
      <a style={{ color: Colors.bluePrimary }} href={LearnMoreLink} target="_blank" rel="noreferrer">
        Learn More.
      </a>
    </span>
  );
  return (
    <>
      <tr key={data.id} className={styles.tableRow}>
        <td className={styles.tableRowText}>
          <div
            style={{
              borderWidth: "1px",
              borderColor: Colors.borderPrimary,
              width: "fit-content",
            }}
            className="p-2 rounded "
          >
            <CardLogo cardIssuer={data?.card_issuer} type={data?.type} />
          </div>
        </td>
        <td className={styles.tableRowText}>
          <div className={styles.cardNumberContainer}>
            <span
              className="text-gray-800 text-sm"
              style={{
                color: Colors.fontGreyLight,
              }}
            >
              ****
              {" " + data?.public_identifier}
            </span>
            {data.is_default && (
              <StatusBadgeReusable
                status={"default"}
                badgesObject={badgesForCardsNumber}
                showDefault={true}
                defaultType="neutral"
                externalClassName={styles.badge}
              />
            )}
            {data?.verification_url && data.status === "unverified" && (
              <span
                onClick={() => {
                  if (data.verification_url) {
                    window.open(data.verification_url, "_blank");
                  }
                }}
              >
                <StatusBadgeReusable
                  status={"clickToVerify"}
                  badgesObject={badgesForCardsNumber}
                  showDefault={true}
                  defaultType="neutral"
                  externalClassName={styles.badge}
                />
              </span>
            )}
          </div>
        </td>
        <td className={styles.tableRowText}>
          <div className="flex gap-2.5">
            <StatusBadgeReusable
              status={data?.status}
              badgesObject={data.type !== "card" ? allowedBadgesForBankAccountStatuses : allowedBadgesForCardStatuses}
              showDefault={true}
              defaultType="neutral"
              externalClassName={styles.badge}
            />
            {(data?.status === "unverified" || data?.status === "verification_expired") && (
              <span className="self-center" data-for={`infoToolTip-${data.id}`} data-tip>
                <InfoSVG />
                <ToolTip
                  anchorSelect="#clickable"
                  clickable
                  backgroundColor="#fff"
                  className={
                    data?.status === "unverified" ? styles.tooltipContainerForUnverified : styles.tooltipContainerForLinkExpired
                  }
                  id={`infoToolTip-${data.id}`}
                  disable={false}
                  place="right"
                  text={data?.status === "unverified" ? unverifiedTooltip : linedExpiredTooltip}
                />
              </span>
            )}
          </div>
        </td>
        <td className={styles.tableLastColumn}>
          {isSettingOpen && (
            <ActionMenu
              refElement={actionMenuRef}
              setDotsMenuVisible={setIsSettingOpen}
              options={actionItems}
              modalPosition={{ top: 40, right: -15 }}
            />
          )}
          <span onClick={settingClickHandler} ref={actionMenuRef} className={styles.settingIcon}>
            <SettingSVG isSelected={isSettingOpen} />
          </span>
          <AreYouSurePopup
            isOpen={!!deletePMId}
            onClose={() => {
              setDeletePMId(null);
            }}
            onYes={async () => {
              removeSelectedCard(deletePMId);
              setDeletePMId(null);
            }}
            helperText={
              "Deleting this payment method will permanently remove any associated payment history. This action is irreversible. Please confirm before proceeding."
            }
          />
        </td>
      </tr>
    </>
  );
};

export default PaymentMethodListItem;
