import { useState } from "react";
import * as Sentry from "@sentry/react";
import { createStore } from "aniuta";
import { toast } from "react-toastify";
import {
  createConnectedAccount,
  deleteConnectedAccount,
  getConnectedAccountsList,
} from "../utils/api/account";

const useConnectedAccounts = createStore({
  name: "useConnectedAccounts",
  Store: () => {
    const [connectedAccountsList, setConnectedAccountsList] = useState();
    const [isConnectedAccountsLoading, setIsConnectedAccountsLoading] =
      useState(false);
    const [isCreateAccountModalOpen, setIsCreateAccountModalOpen] =
      useState(false);
    const [createAccountLoading, setCreateAccountLoading] = useState(false);
    const [accountForEditing, setAccountForEditing] = useState();
    const [selectedConnectedAccount, setSelectedConnectedAccount] = useState();
    const [isNewAccountCreated, setIsNewAccountCreated] = useState(false);

    const [isDeleteAccountModalVisible, setIsDeleteAccountModalVisible] =
      useState(false);

    const getConnectedAccounts = async (userId, payload = {}) => {
      setIsConnectedAccountsLoading(true);
      try {
        const response = await getConnectedAccountsList(userId, {
          limit: 40,
          ...payload,
        });
        setIsConnectedAccountsLoading(false);
        setConnectedAccountsList(response.data);
        return response.data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsConnectedAccountsLoading(false);
      }
    };

    const createAccount = async (id, payload, cb) => {
      try {
        setCreateAccountLoading(true);
        const { data } = await createConnectedAccount(id, payload);
        getConnectedAccounts(id);
        if (!!cb) cb(data);
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsCreateAccountModalOpen(false);
        setCreateAccountLoading(false);
      }
    };

    const deleteAccountHandler = async (id) => {
      try {
        setCreateAccountLoading(true);
        await deleteConnectedAccount(id);
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
        return error;
      }
    };

    return {
      getConnectedAccounts,
      connectedAccountsList,
      setConnectedAccountsList,
      isConnectedAccountsLoading,
      setIsConnectedAccountsLoading,

      //createAccount
      isCreateAccountModalOpen,
      setIsCreateAccountModalOpen,
      createAccountLoading,
      setCreateAccountLoading,
      createAccount,
      //edit account
      accountForEditing,
      setAccountForEditing,
      selectedConnectedAccount,
      setSelectedConnectedAccount,
      isNewAccountCreated,
      setIsNewAccountCreated,
      isDeleteAccountModalVisible,
      setIsDeleteAccountModalVisible,

      deleteAccountHandler,
    };
  },
});

export default useConnectedAccounts;
