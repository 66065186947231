import React, { useCallback, useEffect, useRef, useState } from "react";
import { debounce } from "lodash";
import { MdSearch } from "react-icons/md";
import classNames from "classnames";
import styles from "../styles/Filters/searchFilter.module.css";
import { useMyStore, userDataStore, useTheme } from "../../../../store";
import { EnterSvg } from "../../Svgs";
import { IoCloseCircle } from "react-icons/io5";

const SearchFilter = ({
  id,
  show,
  placeholder = "Search",
  filterState,
  setFilterState,
  activeFiltersIds,
  setActiveFiltersIds,
}) => {
  const [query, setQuery] = useState("");
  const { Colors } = useTheme();
  const { isSandbox } = useMyStore();
  const queryRef = useRef(null);

  const { authInfo } = userDataStore();
  const merchantAccountId = authInfo.merchantAccountId;

  useEffect(() => {
    if (queryRef.current) {
      queryRef.current.focus();
    }
  }, [queryRef.current]);

  const debounceFn = useCallback(
    debounce((search) => {
      setFilterState(search, merchantAccountId, isSandbox);
    }, 800),
    [activeFiltersIds, filterState, setFilterState, merchantAccountId, isSandbox]
  );

  useEffect(() => {
    setActiveFiltersIds([]);
  }, [isSandbox]);

  useEffect(() => {
    if (filterState && !activeFiltersIds.includes(id)) {
      setActiveFiltersIds([...activeFiltersIds, id]);
    }
    if (!filterState && activeFiltersIds.includes(id)) {
      setActiveFiltersIds([...activeFiltersIds.filter((f) => f !== id)]);
    }
  }, [filterState]);

  useEffect(() => {
    if (queryRef.current.value !== filterState) {
      setQuery("");
    }
  }, [filterState]);

  const onQueryChangeHandler = (value) => {
    debounceFn(value);
  };

  return (
    <div className={classNames(styles.container, { [styles.show]: filterState || show })}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        className={styles.form}
      >
        <EnterSvg />
        <div className={styles.inputContainer}>
          <input
            ref={queryRef}
            type="text"
            placeholder={placeholder}
            value={query}
            onChange={(event) => {
              setQuery(event.target.value);
              onQueryChangeHandler(event.target.value);
            }}
            className={styles.input}
          />

          <IoCloseCircle
            onClick={() => {
              setQuery("");
              // onQueryChangeHandler("");
              setFilterState("", authInfo.merchantAccountId, isSandbox);
            }}
            color="#0062ff"
            className="mr-2 cursor-pointer"
            size={14}
          />
        </div>
      </form>
    </div>
  );
};

export default SearchFilter;
