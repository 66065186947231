import React from 'react';
import { useField } from "formik";
import PlacesAutocomplete from "react-places-autocomplete";
import { geocodeByAddress } from "react-google-places-autocomplete";
import { useTheme } from '../../store';

function CustomTextField(props) {
  const [field, meta] = useField(props.name);
  const { Colors } = useTheme();

  return (
    <div
      style={{
        marginBottom: props.marginbottom,
        display: "flex",
        alignItems: "center",
        width: "100%",
      }}
    >
      <div style={{ width: "100%", position: "relative" }}>
        {props.label && <label htmlFor={props.name}>{props.label}</label>}

        {props.address ? (
          <PlacesAutocomplete
            value={meta.value}
            onChange={(e) => {
              props.setFieldValue(props.name, e.trimLeft());
            }}
            onSelect={async (e) => {
              const geocode = await geocodeByAddress(e);
              props.setFieldValue(props.name, geocode[0].formatted_address);
            }}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => {
              return (
                <>
                  <input
                    {...field}
                    {...props}
                    id="pickupAddressInput"
                    {...getInputProps({
                      placeholder: props.placeholder ?? "Search Address *",
                      disabled: props.disabled,
                    })}
                    type="text"
                  />
                  {(loading || suggestions.length > 0) && (
                    <div className="w-full absolute" style={{ top: "42px", border: `1px solid ${Colors.tableBorderGray}`, zIndex: 999 }}>
                      {loading ? (
                        <div style={{ color: Colors.fontGreyLight, }}>...loading</div>
                      ) : null}
                      {suggestions.map((sug, index) => {
                        const styles = sug.active
                          ? {
                            backgroundColor: Colors.bluePrimary,
                            color: Colors.white,
                          } : {
                            backgroundColor: Colors.white,
                            color: Colors.fontGreyLight,
                          }
                        return (
                          <div
                            id={index}
                            key={sug.index}
                            {...getSuggestionItemProps(sug, {
                              className: `px-3 py-1 cursor-pointer`,
                              style: { fontFamily: "Poppins", fontSize: "13px", ...styles },
                            })}
                          >
                            {sug.description}
                          </div>
                        );
                      })}
                    </div>)
                  }
                </>
              );
            }}
          </PlacesAutocomplete>
        ) : (
          <input
            {...field}
            {...props}
          />
        )}
        {meta.error && (meta.touched || props.isClicked) && (
          <p
            style={{
              color: Colors.redDark,
              fontFamily: "Poppins",
              fontSize: "12px",
              padding: "6px 10px 0px 12px",
              position: "absolute",
              top: "42px",
            }}
          >
            {meta.error}
          </p>
        )}
      </div>
    </div>
  );
}

export default CustomTextField;
