import React, { useState } from "react";
import styles from "./styles.module.css";
import classNames from "classnames";
import { useMyStore, useTheme } from "../../../../store";
import { AiOutlineClear } from "react-icons/ai";
import { RiArrowDropDownLine } from "react-icons/ri";
import OutsideClickHandler from "react-outside-click-handler";
import { CheckIcon } from "../../../../assets/svgs/Svgs";
import { FaChevronDown } from "react-icons/fa";
import { IoCubeOutline } from "react-icons/io5";

const DropdownReusable = ({
  disabled = false,
  selectedItem,
  setSelectedItem,
  onChange,
  items = [],
  placeHolder = "status",
  children,
  extraContainerClasses = "",
  inputExtras = "",
  showCleaner = true,
  setIsDropdownOpened,
  isDropdownOpened,
  icon,
  rightComponent,
  extraPlaceholderStyles,
  extraDropDownContentStyles = "",
}) => {
  const { Colors } = useTheme();
  const { isSandbox } = useMyStore();
  return (
    <div className={classNames(styles.container, extraContainerClasses)}>
      <OutsideClickHandler
        onOutsideClick={() => {
          if (isDropdownOpened) {
            setIsDropdownOpened(false);
          }
        }}
      >
        <div
          onClick={(e) => {
            if (!disabled) {
              setIsDropdownOpened((prev) => !prev);
              e.stopPropagation();
            }
          }}
          style={{
            borderColor: Colors.borderPrimary,
            backgroundColor: Colors.bgSettings,
          }}
          className={classNames(styles.containerInput, inputExtras, "capitalize", {
            [styles.inputWhenOpened]: isDropdownOpened,
          })}
        >
          <div className={styles.iconCont}>
            {icon && icon}
            <div className={classNames(styles.placeHolder, extraPlaceholderStyles)}>{selectedItem || placeHolder}</div>
          </div>
          {selectedItem && showCleaner ? (
            <button
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedItem("");
                onChange({ item: "", isSandbox: isSandbox });
              }}
            >
              <AiOutlineClear className={styles.dropdownIcon} />
            </button>
          ) : !showCleaner && selectedItem && rightComponent ? (
            rightComponent
          ) : (
            <FaChevronDown className={styles.dropdownIcon} />
          )}
        </div>
        {isDropdownOpened &&
          (children || (
            <div
              className={classNames(styles.dropdownContainer, extraDropDownContentStyles)}
              style={{
                borderColor: Colors.borderPrimary,
              }}
            >
              {items.map((item, index) => {
                return (
                  <div key={index} className={styles.oneItem}>
                    <div
                      onClick={() => {
                        setSelectedItem(item);
                        setIsDropdownOpened(false);
                        onChange({ item: item, isSandbox: isSandbox });
                      }}
                      className={classNames(styles.oneItemInDropdown)}
                    >
                      {item}
                    </div>
                    {item === selectedItem && <CheckIcon />}
                  </div>
                );
              })}
            </div>
          ))}
      </OutsideClickHandler>
    </div>
  );
};

export default DropdownReusable;
