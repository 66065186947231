export const ruleExpressionFieldsList = [
    {
      label: "Distance",
      value: "distance",
      type: "number",
      unit: "Mi",
    },
    {
      label: "Order value",
      value: "order_value",
      type: "number",
      unit: "¢",
    },
    {
      label: "Tip",
      value: "tip",
      type: "number",
      unit: "¢",
    },
  ];
  
  export const ruleResultFieldsList = [
    {
      label: "Price per mile",
      value: "price_per_mile",
      type: "number",
      unit: "¢",
    },
    {
      label: "Fixed fee",
      value: "fixed_fee",
      type: "number",
      unit: "¢",
    },
    {
      label: "Delivery fee",
      value: "delivery_fee",
      type: "number",
      unit: "¢",
    },
    {
      label: "Base mile",
      value: "base_mile",
      type: "number",
      unit: "Mi",
    },
  ];
  
  export const operationsList = [
    {
      label: "*",
      value: "multiplication",
    },
    {
      label: "/",
      value: "division",
    },
    {
      label: "-",
      value: "subtraction",
    },
    {
      label: "+",
      value: "addition",
    },
  ];
  
  export const equalityOperatorsList = [
    {
      label: ">",
      value: "greater",
    },
    {
      label: ">=",
      value: "greater_or_equal",
    },
    {
      label: "==",
      value: "equal",
    },
    {
      label: "<",
      value: "less",
    },
    {
      label: "<=",
      value: "less_or_equal",
    },
  ];
  
  export const initialExpressionPayload = {
    type: "expression",
    value: {
      left_side_value: {
        variable: null,
        operation: null,
        field_value: null,
      },
      right_side_value: {
        variable: null,
        operation: null,
        field_value: null,
      },
      equality_operator: null,
    },
  };
  
  export const initialLogicalOperatorPayload = {
    type: "logical_operator",
    value: "OR",
  };
  
  export const initialResultPayload = {
    field: null,
    value: {
      variable: null,
      operation: null,
      field_value: null,
    },
  };
  
  export const initialPayloadForRule = {
    condition: [initialExpressionPayload],
    result: initialResultPayload,
  };
  
  export const getFieldValueIcon = (list, field) => {
    const listItem = list.find((item) => item.value === field);
    if (listItem) {
      return listItem.unit;
    }
    return "";
  };
  