import { values } from "lodash";
import { geocodeByAddress } from "react-google-places-autocomplete";
import * as Yup from "yup";

export const initialValues = (rerouteItem) => {
  if (rerouteItem) {
    return {
      dropoff: [
        {
          dropoff_address: rerouteItem.dropoffs[0].dropoff_address,
          dropoff_unit: rerouteItem.dropoff_unit || "",
          dropoff_businessName: "",
          dropoff_phone_number:
            rerouteItem.dropoffs[0].dropoff_phone_number[0] !== "+"
              ? rerouteItem.dropoffs[0].dropoff_phone_number[0] == "1"
                ? rerouteItem.dropoffs[0].dropoff_phone_number
                : "1" + rerouteItem.dropoffs[0].dropoff_phone_number
              : rerouteItem.dropoffs[0].dropoff_phone_number,
          dropoff_notes: rerouteItem.dropoffs[0].dropoff_notes ?? "",
          dropoff_name: rerouteItem.dropoffs[0].dropoff_name,
          // dropoffLastName: rerouteItem.dropoffs[0].dropoff_name
          //   .trim()
          //   .split(/\s+/)[1],
          items: rerouteItem.items,
        },
      ],
      pickup: [
        {
          pickup_address: rerouteItem.pickup_address,
          pickup_unit: rerouteItem.pickup_unit || "",
          pickup_businessName: "",
          pickup_phone_number: rerouteItem.pickup_phone_number,
          pickup_notes: rerouteItem.pickup_notes ?? "",
          pickup_name: rerouteItem.pickup_name,
          // pickupLastName: rerouteItem.pickup_name.trim().split(/\s+/)[1],
        },
      ],
      initiate:
        rerouteItem.status === "scheduled" || (rerouteItem.pickup_at && rerouteItem.pickup_at !== "Invalid date")
          ? "scheduled"
          : rerouteItem.status === "request"
          ? "later"
          : "now",
      vehicleType:
        rerouteItem.vehicle === "car"
          ? "car"
          : rerouteItem.vehicle === "truck"
          ? "truck"
          : rerouteItem.vehicle === "suv"
          ? "suv"
          : "",
      quote_id: "",
      tip: (rerouteItem.tip / 100).toFixed(2),
      order_value: rerouteItem.order_value ? rerouteItem.order_value / 100 : undefined,
      eta: rerouteItem.pickup_at && rerouteItem.pickup_at !== "Invalid date" ? rerouteItem.pickup_at : "",
      isPickupAt: rerouteItem.pickup_at && rerouteItem.pickup_at !== "Invalid date" ? true : false,
      pickup_at: rerouteItem.pickup_at,
      proofOfDelivery: rerouteItem.contactless_delivery ?? false,
      signature: rerouteItem.signature ?? false,
      burq_fee: undefined,
    };
  } else if (!rerouteItem) {
    return {
      dropoff: [
        {
          dropoff_address: "",
          dropoff_unit: "",
          dropoff_businessName: "",
          dropoff_phone_number: "",
          dropoff_notes: "",
          dropoff_name: "",
          items: [{ name: "", quantity: "", size: "small" }],
        },
      ],
      pickup: [
        {
          pickup_address: "",
          pickup_unit: "",
          pickup_businessName: "",
          pickup_phone_number: "",
          pickup_notes: "",
          pickup_name: "",
        },
      ],
      initiate: "now",
      vehicleType: "",
      quote_id: "",
      tip: 0,
      order_value: "",
      eta: "",
      isPickupAt: true,
      proofOfDelivery: false,
      signature: false,
      burq_fee: undefined,
      contains_alcohol: false,
    };
  }
};

export const initialValuesHandler = (rerouteItem, isReroute) => {
  if (rerouteItem) {
    return {
      dropoff: [
        {
          dropoff_address: rerouteItem.dropoffs[0].dropoff_address,
          dropoff_unit: rerouteItem.dropoff_unit || "",
          dropoff_businessName: "",
          dropoff_phone_number:
            rerouteItem.dropoffs[0].dropoff_phone_number[0] !== "+"
              ? rerouteItem.dropoffs[0].dropoff_phone_number[0] == "1"
                ? rerouteItem.dropoffs[0].dropoff_phone_number
                : "1" + rerouteItem.dropoffs[0].dropoff_phone_number
              : rerouteItem.dropoffs[0].dropoff_phone_number,
          dropoff_notes: rerouteItem.dropoffs[0].dropoff_notes ?? "",
          dropoff_name: rerouteItem.dropoffs[0].dropoff_name,
          // dropoffLastName: rerouteItem.dropoffs[0].dropoff_name
          //   .trim()
          //   .split(/\s+/)[1],
          items: rerouteItem.items,
        },
      ],
      pickup: [
        {
          pickup_address: rerouteItem.pickup_address,
          pickup_unit: rerouteItem.pickup_unit || "",
          pickup_businessName: "",
          pickup_phone_number: rerouteItem.pickup_phone_number,
          pickup_notes: rerouteItem.pickup_notes ?? "",
          pickup_name: rerouteItem.pickup_name,
          // pickupLastName: rerouteItem.pickup_name.trim().split(/\s+/)[1],
        },
      ],
      initiate:
        rerouteItem.status === "scheduled" ||
        (rerouteItem.pickup_at && rerouteItem.pickup_at !== "Invalid date") ||
        (rerouteItem.dropoff_at && rerouteItem.dropoff_at !== "Invalid date")
          ? "scheduled"
          : rerouteItem.status === "request"
          ? "later"
          : "now",
      vehicleType:
        rerouteItem.vehicle === "car"
          ? "car"
          : rerouteItem.vehicle === "truck"
          ? "truck"
          : rerouteItem.vehicle === "suv"
          ? "suv"
          : "",
      quote_id: "",
      tip: rerouteItem.tip ? (rerouteItem.tip / 100).toFixed(2) : 0,
      order_value: rerouteItem.order_value ? rerouteItem.order_value / 100 : undefined,
      external_order_ref: rerouteItem.external_order_ref ? rerouteItem.external_order_ref : undefined,
      eta:
        rerouteItem.pickup_at && rerouteItem.pickup_at !== "Invalid date"
          ? rerouteItem.pickup_at
          : rerouteItem.dropoff_at && rerouteItem.dropoff_at !== "Invalid date"
          ? rerouteItem.dropoff_at
          : "",
      isPickupAt:
        (rerouteItem.pickup_at && rerouteItem.pickup_at !== "Invalid date") ||
        (rerouteItem.dropoff_at && rerouteItem.dropoff_at !== "Invalid date")
          ? true
          : false,
      pickup_at: rerouteItem.pickup_at,
      dropoff_at: rerouteItem.dropoff_at,
      provider_settings: {
        signature: false,
        contactless: false,
        undeliverable_action: null,
        recipient_minimum_age: 18,
        age_verification: false,
      },
      burq_fee: undefined,
      is_reroute: isReroute,
      reroute_reason: "",
      contains_alcohol: rerouteItem.contains_alcohol,
    };
  } else if (!rerouteItem) {
    return {
      dropoff: [
        {
          dropoff_address: "",
          dropoff_unit: "",
          dropoff_businessName: "",
          dropoff_phone_number: "",
          dropoff_notes: "",
          dropoff_name: "",
          items: [{ name: "", quantity: "", size: "small" }],
        },
      ],
      pickup: [
        {
          pickup_address: "",
          pickup_unit: "",
          pickup_businessName: "",
          pickup_phone_number: "",
          pickup_notes: "",
          pickup_name: "",
        },
      ],
      initiate: "now",
      vehicleType: "",
      quote_id: "",
      tip: 0,
      order_value: "",
      external_order_ref: "",
      eta: "",
      isPickupAt: true,
      provider_settings: {
        signature: false,
        contactless: false,
        undeliverable_action: null,
        recipient_minimum_age: 18,
        age_verification: false,
      },
      burq_fee: undefined,
      is_reroute: false,
    };
  }
};

export const formSchemaHandler = (pickupSchema) => {
  return Yup.object().shape({
    initiate: Yup.string().required(),
    vehicleType: Yup.string(),
    quote_id: Yup.string(),
    tip: Yup.number("Only numbers"),
    order_value: Yup.number("Only numbers").label("Order value").max(3000).required("Order value is required"),
    external_order_ref: Yup.string().label("Order number"),
    eta: Yup.string().when("initiate", (initiate) => {
      if (initiate === "scheduled") {
        return Yup.string().required("Schedule Time is required");
      } else {
        return Yup.string().notRequired();
      }
    }),
    dropoff: Yup.array().of(
      Yup.object().shape({
        dropoff_address: Yup.string()
          .test("len", "Address is required", (val) => val != " ")
          .required("Address is required"),
        dropoff_unit: Yup.string(),
        dropoff_businessName: Yup.string().nullable(),
        dropoff_phone_number: Yup.string().required("Phone number is required"),
        dropoff_notes: Yup.string(),
        dropoff_name: Yup.string()
          .test("len", "First  name is required", (val) => val != " ")
          .required("First name is required"),
        // dropoffLastName: Yup.string()
        //   .test("len", "Last name is required", (val) => val != " ")
        //   .required("Last name is required"),
        items: Yup.array().of(
          Yup.object().shape({
            name: Yup.string()
              .test("len", "Item name is required", (val) => val != " ")
              .required("Item name is required"),
            quantity: Yup.number().min(1, "Quantity must be greater than 0").required("Quantity is required"),
            size: Yup.string(),
          })
        ),
      })
    ),
    pickup: Yup.array().of(Yup.object().shape(pickupSchema)),
    reroute_reason: Yup.string().when("is_reroute", (isReroute) => {
      if (isReroute) {
        return Yup.string().required("Reroute reason is required");
      } else {
        return Yup.string().notRequired();
      }
    }),
    provider_settings: Yup.object().shape({
      signature: Yup.boolean(),
      contactless: Yup.boolean(),
      undeliverable_action: Yup.mixed().nullable(),
      recipient_minimum_age: Yup.number()
        .nullable()
        .when("age_verification", {
          is: true,
          then: Yup.number().min(18, "Age must not be less than 18").required("Minimum age is required"),
          otherwise: Yup.number().nullable(),
        }),
      age_verification: Yup.boolean(),
    }),
  });
};

export const pickupSchemaHandler = (isAddressDefaulted) => {
  if (!isAddressDefaulted) {
    return {
      pickup_address: Yup.string().required("Address is required"),
      pickup_unit: Yup.string(),
      pickup_businessName: Yup.string().nullable(),
      pickup_phone_number: Yup.string().required("Phone number is required"),
      pickup_notes: Yup.string(),
      pickup_name: Yup.string()
        .test("len", "First name is required", (val) => val != " ")
        .required("First name is required"),
      // pickupLastName: Yup.string()
      //   .test("len", "Last name is required", (val) => val != " ")
      //   .required("Last name is required"),
    };
  } else {
    return {
      pickup_address: Yup.string(),
      pickup_unit: Yup.string(),
      pickup_businessName: Yup.string().nullable(),
      pickup_phone_number: Yup.string(),
      pickup_notes: Yup.string(),
      pickupName: Yup.string(),
      pickupLastName: Yup.string(),
    };
  }
};

export const coordinatesHandler = (
  dropoffCoordinatesArr,
  setFirstCoordinates,
  setZoom,
  addresses,
  setCoordinatesArr,
  coordinatesArr,
  arr,
  selected,
  index
) => {
  let geocodePromises = [];
  for (let i = 0; i < addresses.length; i++) {
    const address = arr === "pickup" ? addresses[i].pickup_address : addresses[i].dropoff_address;
    geocodePromises.push(geocodeByAddress(address));
  }
  Promise.all(geocodePromises).then((e) => {
    const _arrayOfCoords = selected ? [...dropoffCoordinatesArr] : [];

    e.forEach((element, i) => {
      const location = element[0].geometry.location;

      const coordObj = {
        lat: location.lat(),
        lng: location.lng(),
      };

      // ! FOR SINGLESTOP
      // if (arr === "pickup" && selected) {
      //   _arrayOfCoords.splice(0, 1, coordObj);
      // } else {
      //   _arrayOfCoords.splice(0, 1, coordObj);
      // }

      // ! FOR MULTISTOP
      if (arr === "pickup" && selected) {
        _arrayOfCoords.splice(0, 1, coordObj);
      } else {
        _arrayOfCoords.splice(index, 1, coordObj);
      }
    });

    setFirstCoordinates({
      lat: _arrayOfCoords[0].lat,
      lng: _arrayOfCoords[0].lng,
    });

    setZoom(12);

    setCoordinatesArr(_arrayOfCoords);
  });
};

export const continueHandler = (errors, values, steps, setSteps, setIsClickedOnContinue, isAddressDefaulted, isReroute) => {
  if (isAddressDefaulted) {
    if (steps === 4 && isReroute) {
      if (values.reroute_reason) {
        setSteps(steps + 1);
      }
    } else if (
      steps === 1 &&
      !errors.dropoff &&
      // touched.dropoff &&
      values.order_value &&
      values.order_value < 3000
    ) {
      setSteps(steps + 1);
    } else if (steps == 2) {
      setSteps(steps + 1);
    } else if (steps == 3) {
      if (values.initiate !== "scheduled") {
        setSteps(steps + 1);
      } else if (values.initiate === "scheduled" && values.eta) {
        setSteps(steps + 1);
      }
    } else if (steps !== 1 && steps !== 2 && steps !== 3) {
      setSteps((prev) => prev + 1);
    }
    setIsClickedOnContinue(true);
  } else {
    // if (!errors.dropoff.items) {
    setIsClickedOnContinue(true);
    // }
    if (steps === 4 && isReroute) {
      if (values.reroute_reason) {
        setSteps(steps + 1);
      }
    } else if (
      (steps === 1 &&
        !errors.dropoff &&
        !errors.pickup &&
        // (touched.pickup || touched.dropoff) &&
        values.order_value &&
        values.pickup[0].pickup_phone_number?.length === 17) ||
      (values.pickup[0].pickup_phone_number?.length === 11 && values.dropoff[0].dropoff_phone_number.length === 17) ||
      values.dropoff[0].dropoff_phone_number.length === 11
    ) {
      setSteps(steps + 1);
    } else if (steps !== 1) {
      if (values.initiate !== "scheduled") {
        setSteps(steps + 1);
      } else if (values.initiate === "scheduled" && values.eta) {
        setSteps(steps + 1);
      }
    }
  }
};

export const submitRequestHandler = async (
  analyticsAsHomeEnabled,
  info,
  store,
  isReroute,
  rerouteDeliveryHandler,
  rerouteItem,
  history,
  setSteps,
  saveAddress,
  postSaveAddres,
  edit,
  isAdminReroute,
  createRerouteInfo,
  isSandbox,
  merchantAccountId,
  createDeliveryInfo,
  setDeliveryLoading
) => {
  console.log("first", isAdminReroute);
  if (isReroute) {
    const res = await rerouteDeliveryHandler(rerouteItem.id, info.quote_id, info);
    if (res) {
      if (isAdminReroute) {
        history("/v1/admin");
      } else if (analyticsAsHomeEnabled) {
        history("/v1/deliveries");
      } else {
        history("/v1/home");
      }
    } else if (!res) {
      setSteps(4);
    }
  } else {
    let items = [];

    for (let i = 0; i < info.dropoff.length; i++) {
      for (let j = 0; j < info.dropoff[i].items.length; j++) {
        items.push(info.dropoff[i].items[j]);
      }
    }
    if (saveAddress) {
      const pickup = info.pickup[0];
      await postSaveAddres(pickup.pickup_address, pickup.pickup_name, pickup.pickup_phone_number, "", pickup.pickup_notes);
    }

    if (edit) {
      if (isAdminReroute) {
        createRerouteInfo(info, items, isSandbox, merchantAccountId, rerouteItem?.store_id).then((e) => {
          if (e) {
            history("/v1/admin");
          }
        });
      } else if (!isAdminReroute) {
        createDeliveryInfo(info, items, isSandbox, rerouteItem?.store_id, rerouteItem?.id).then((e) => {
          if (e) {
            if (analyticsAsHomeEnabled) {
              history("/v1/deliveries");
            } else {
              history("/v1/home");
            }
          }
        });
      }
    } else {
      const res = await createDeliveryInfo(info, items, isSandbox, store?.id);
      if (res) {
        if (analyticsAsHomeEnabled) {
          history("/v1/deliveries");
        } else {
          history("/v1/home");
        }
      } else if (!res) {
        setSteps(4);
      }
    }
  }
};
