import { baseUrl, baseUrlWithoutApi } from "../runTimeConst";

const orders = {
  ORDER_FULFILLMENT: (id) => `${baseUrl}orders/${id}/fulfillment`,
  GET_ORDER: (id) => `${baseUrl}orders/${id}`,
  GET_ORDERS: (id) => `${baseUrl}merchant_accounts/${id}/orders`,
  CREATE_ORDER: (id) => `${baseUrl}merchant_accounts/${id}/orders`,
  UPDATE_ORDER: (accountId, orderId) => `${baseUrl}merchant_accounts/${accountId}/orders/${orderId}`,
  DELETE_ORDER: (accountId, orderId) => `${baseUrl}merchant_accounts/${accountId}/orders/${orderId}`,
  CANCEL_ORDER: (accountId, orderId) => `${baseUrl}merchant_accounts/${accountId}/orders/${orderId}/cancel`,
  ACCEPT_QUOTE: (accountId, quoteId) => `${baseUrl}merchant_accounts/${accountId}/quotes/${quoteId}/accept`,
  CREATE_ORDER_NOTE: (id) => `${baseUrl}orders/${id}/notes`,
  DELETE_ORDER_NOTE: (noteId) => `${baseUrl}order_notes/${noteId}`,
  GET_ORDER_QUOTES: (account_id, order_id) => `${baseUrl}merchant_accounts/${account_id}/orders/${order_id}/quotes`,
};

const analytics = {
  // ANALYTICS_OVERVIEW: (isSnowflakeDisabled) => `${baseUrl}delivery_analytics${isSnowflakeDisabled ? "" : ""}/analytics_overview`,
  ANALYTICS_OVERVIEW: (isSnowflakeDisabled) =>
    `${baseUrl}delivery_analytics${isSnowflakeDisabled ? "/v2" : "/v2/snowflake"}/analytics_overview`,
  DELIVERY_VOLUME: (isSnowflakeDisabled) =>
    `${baseUrl}delivery_analytics${isSnowflakeDisabled ? "/v2" : "/v2/snowflake"}/delivery_volume`,
  DELIVERY_AMOUNT: (isSnowflakeDisabled) =>
    `${baseUrl}delivery_analytics${isSnowflakeDisabled ? "/v2" : "/v2/snowflake"}/delivery_amount`,
  PROVIDERS_USED: (isSnowflakeDisabled) =>
    `${baseUrl}delivery_analytics${isSnowflakeDisabled ? "/v2" : "/v2/snowflake"}/providers_used`,
  TOP_DELIVERY_PICKUP_LOCATIONS: (isSnowflakeDisabled) =>
    `${baseUrl}delivery_analytics${isSnowflakeDisabled ? "/v2" : "/v2/snowflake"}/top_delivery_pickup_locations`,
  DELIVERY_TIME: (isSnowflakeDisabled) =>
    `${baseUrl}delivery_analytics${isSnowflakeDisabled ? "/v2" : "/v2/snowflake"}/delivery_time`,
  DELIVERY_TOP_TIMING: (isSnowflakeDisabled) =>
    `${baseUrl}delivery_analytics${isSnowflakeDisabled ? "/v2" : "/v2/snowflake"}/delivery_top_timing`,
  DELIVERY_AVERAGE_MILES: (isSnowflakeDisabled) =>
    `${baseUrl}delivery_analytics${isSnowflakeDisabled ? "/v2" : "/v2/snowflake"}/delivery_average_miles`,
  DELIVERY_AVERAGE: (isSnowflakeDisabled) =>
    `${baseUrl}delivery_analytics${isSnowflakeDisabled ? "/v2" : "/v2/snowflake"}/delivery_average`,
  AVG_DELIVERY_PICK_UP_TIME: (isSnowflakeDisabled) =>
    `${baseUrl}delivery_analytics${isSnowflakeDisabled ? "/v2" : "/v2/snowflake"}/avg_delivery_pick_up_time`,
  AVG_DELIVERY_PICK_UP_DELAY_TIME: (isSnowflakeDisabled) =>
    `${baseUrl}delivery_analytics${isSnowflakeDisabled ? "/v2" : "/v2/snowflake"}/avg_delivery_pick_up_delay_time`,
  AVG_DELIVERY_DROP_OFF_TIME: (isSnowflakeDisabled) =>
    `${baseUrl}delivery_analytics${isSnowflakeDisabled ? "/v2" : "/v2/snowflake"}/avg_delivery_drop_off_time`,
  AVG_DELIVERY_DROP_OFF_DELAY_TIME: (isSnowflakeDisabled) =>
    `${baseUrl}delivery_analytics${isSnowflakeDisabled ? "/v2" : "/v2/snowflake"}/avg_delivery_drop_off_delay_time`,
  DELIVERY_RETURN: (isSnowflakeDisabled) =>
    `${baseUrl}delivery_analytics${isSnowflakeDisabled ? "/v2" : "/v2/snowflake"}/delivery_return`,

  // ? not active:
  PROVIDER_TOP_STORES: (isSnowflakeDisabled) =>
    `${baseUrl}${isSnowflakeDisabled ? "v2" : "/v2snowflake"}/delivery_analytics/provider_top_stores`,
  DELIVERY_CITIES: (isSnowflakeDisabled) =>
    `${baseUrl}${isSnowflakeDisabled ? "v2" : "/v2snowflake"}/delivery_analytics/delivery_cities`,
  // ORDERS_VALUE: (isSnowflakeDisabled) => `${baseUrl}${isSnowflakeDisabled ? "v2/snowflake/" : ""}delivery_analytics/orders_value`,
  // ANALYTICS_OVERVIEW: `${baseUrl}delivery_analytics/analytics_overview`,
  // DELIVERY_VOLUME: `${baseUrl}delivery_analytics/delivery_volume`,
  // DELIVERY_AMOUNT: `${baseUrl}delivery_analytics/delivery_amount`,
  // PROVIDERS_USED: `${baseUrl}delivery_analytics/providers_used`,
  // ORDERS_VALUE: `${baseUrl}delivery_analytics/orders_value`,
  // DELIVERY_CITIES: `${baseUrl}delivery_analytics/delivery_cities`,
  // TOP_DELIVERY_PICKUP_LOCATIONS: `${baseUrl}delivery_analytics/top_delivery_pickup_locations`,
  // DELIVERY_TIME: `${baseUrl}delivery_analytics/delivery_time`,
  // DELIVERY_TOP_TIMING: `${baseUrl}delivery_analytics/delivery_top_timing`,
  // DELIVERY_AVERAGE_MILES: `${baseUrl}delivery_analytics/delivery_average_miles`,
  // DELIVERY_AVERAGE: `${baseUrl}delivery_analytics/delivery_average`,
  // AVG_DELIVERY_PICK_UP_TIME: `${baseUrl}delivery_analytics/avg_delivery_pick_up_time`,
  // AVG_DELIVERY_PICK_UP_DELAY_TIME: `${baseUrl}delivery_analytics/avg_delivery_pick_up_delay_time`,
  // AVG_DELIVERY_DROP_OFF_TIME: `${baseUrl}delivery_analytics/avg_delivery_drop_off_time`,
  // AVG_DELIVERY_DROP_OFF_DELAY_TIME: `${baseUrl}delivery_analytics/avg_delivery_drop_off_delay_time`,
  // PROVIDER_TOP_STORES: `${baseUrl}delivery_analytics/provider_top_stores`,
  // DELIVERY_RETURN: `${baseUrl}delivery_analytics/delivery_return`,
  // PROVIDER_TOTAL_MILES: `${baseUrl}delivery_analytics/provider_total_miles`,
  // ORDER_VOLUME: `${baseUrl}delivery_analytics/order_volume`,
  // DELIVERY_REPORT: `${baseUrl}delivery_analytics/delivery_report`,
  // DELIVERY_COST_AVERAGE: `${baseUrl}delivery_analytics/delivery_cost_average`,
  // ORDER_VALUE_AVERAGE: `${baseUrl}delivery_analytics/order_value_average`,
  // PROVIDER_DELIVERIES_SENT: `${baseUrl}delivery_analytics/provider_deliveries_sent`,
  // PROVIDER_DELIVERY_AMOUNT: `${baseUrl}delivery_analytics/provider_delivery_amount`,
  // PROVIDER_ORDER_VALUE: `${baseUrl}delivery_analytics/provider_order_value`,
  // PROVIDER_CANCELED: `${baseUrl}delivery_analytics/provider_canceled`,
  // AVERAGE_ORDER_AMOUNT_DELIVERY: `${baseUrl}delivery_analytics/average_order_amount_delivery`,
  // PROVIDER_DELIVERY_TIME_AVERAGE: `${baseUrl}delivery_analytics/provider_delivery_time_average`,
};

const onboarding = {
  // CONNECTED_ACCOUNT_INVITATIONS: (id) => `${baseUrl}connected_account_invitations/${id}`,
  CONNECTED_ACCOUNT_INVITATIONS: `${baseUrl}connected_account_invitations/search`,
  CONNECTED_ACCOUNT_INVITATIONS_SET_PASSWORD: (id) => `${baseUrl}connected_account_invitations/${id}/set_password`,
  CONNECTED_ACCOUNT_INVITATIONS_SET_DEFAULT_PAYMENT_METHOD: (id) =>
    `${baseUrl}connected_account_invitations/${id}/set_default_payment_method`,
  CONNECTED_ACCOUNT_INVITATIONS_ACCOUNT_AGREEMENT_CONSENT: (id) =>
    `${baseUrl}connected_account_invitations/${id}/account_agreement_consent`,
  PLATFORM_INFO: (id) => `${baseUrl}merchant_accounts/${id}/platform_config`,
};

const accounts = {
  CONNECTED_ACCOUNTS: (id) => `${baseUrl}merchant_accounts/${id}/connected_accounts`,
  MERCHANT_ACCOUNTS_STRIPE_SETUP_INTENT: (id) => `${baseUrl}merchant_accounts/${id}/stripe_setup_intent`,
  MERCHANT_ACCOUNT_STORES: (id) => `${baseUrl}merchant_accounts/${id}/stores`,
  STORE: (id) => `${baseUrl}stores/${id}`,
  DELETE_CONNECTED_ACCOUNT: (id) => `${baseUrl}merchant_accounts/${id}/disable`,
  MERCHANT_ENABLED_PROVIDERS: (id) => `${baseUrl}merchant_accounts/${id}/providers`,
  UPDATE_REROUTE_CONFIG: (id) => `${baseUrl}merchant_accounts/${id}/reroute_config`,
};

const payment = {
  MERCHANT_ACCOUNTS_PAYMENT_METHODS: (id) => `${baseUrl}merchant_accounts/${id}/payment_methods`,
  SET_STORE_PAYMENT_METHOD: (id) => `${baseUrl}stores/${id}/payment_method`,
  REMOVE_PAYMENT_METHOD: (id) => `${baseUrl}payment_methods/${id}`,
  MERCHANT_ACCOUNTS_SET_DEFAULT_PAYMENT_METHOD: (id) => `${baseUrl}merchant_accounts/${id}/default_payment_method`,
};

const statements = {
  MERCHANT_ACCOUNTS_STATEMENTS: (id) => `${baseUrl}merchant_accounts/${id}/deposit_statements`,
  DEPOSIT_STATEMENT: (id) => `${baseUrl}deposit_statements/${id}`,
  DEPOSIT_STATEMENT_TRANSACTIONS: (id) => `${baseUrl}deposit_statements/${id}/transactions`,
  STATEMENT_CSV: (id) => `${baseUrl}deposit_statements/${id}/csv_export`,
  BILLING_EVENTS: (id) => `${baseUrl}merchant_accounts/${id}/billing_events`,
  RECENT_TRANSACTIONS: (id) => `${baseUrl}merchant_accounts/${id}/recent_transactions`,
  RELATED_STATEMENTS: (id) => `${baseUrl}deposit_statements/${id}/related_statements`,
  DEPOSIT_STATEMENTS_INITIATE_PAYMENT: (id) => `${baseUrl}deposit_statements/${id}/initiate_payment`,
};
const paymentHistory = {
  MERCHANT_ACCOUNTS_PAYMENTS_HISTORY: (id) => `${baseUrl}merchant_accounts/${id}/payments`,
};

const incidents = {
  DELIVERY_INCIDENT: `${baseUrlWithoutApi}admin/delivery_incidents`,
  UPDATE_DELIVERY_INCIDENT: (id) => `${baseUrlWithoutApi}admin/delivery_incidents/${id}`,
  ASSIGN_ADMIN_TO_INCIDENT: (id) => `${baseUrlWithoutApi}admin/delivery_incidents/${id}/assign`,
  INCIDENT_PRESIGNED_URL: `${baseUrl}deliveries/delivery_incidents/presigned_url`,
  GET_REJECTION_REASONS: `${baseUrlWithoutApi}admin/delivery_incidents_refund_rejection_reasons`,
};

const merchantIncidents = {
  MERCHANT_DELIVERY_INCIDENTS: (id) => `${baseUrl}merchant_accounts/${id}/delivery_incidents`,
  CREATE_MERCHANT_DELIVERY_INCIDENT: (id) => `${baseUrl}deliveries/${id}/delivery_incidents`,
  MERCHATN_INCIDENTS_REASONS: `${baseUrl}delivery_incident_reasons`,
};

const deliveries = {
  DELIVERY_BY_ID: (id) => `${baseUrl}delivery/${id}`,
  DELIVERY_API_ERRORS: (id) => `${baseUrl}deliveries/${id}/api_errors`,
  GET_TRACK_INCIDENT_LINK: (id) => `${baseUrl}delivery/tickets/${id}`,
  GET_DISPUTED_DELIVERIES_LIST: (id) => `${baseUrl}merchant_accounts/${id}/deliveries/disputed`,
  GET_HOME_DELIVERIES_LIST: (id) => `${baseUrl}merchant_accounts/${id}/deliveries`,
};

const providers = {
  PROVIDERS_LIST: `${baseUrlWithoutApi}admin/providers`,
  MERCHATN_ENABLED_PROVIDERS_LIST: (id) => `${baseUrl}merchant_accounts/${id}/enabled_providers`,
};

const users = {
  USERS_LIST: () => `${baseUrlWithoutApi}admin/merchant_accounts`,
};

const adminDeliveries = {
  ADMIN_DELIVERIES_LIST: () => `${baseUrlWithoutApi}admin/deliveries`,
  ADMIN_DELIVERIES_CANCEL: (delId) => `${baseUrlWithoutApi}admin/delivery/${delId}/cancel`,
  REROUTE_INFO: (id) => `${baseUrlWithoutApi}admin/delivery/${id}/reroute_info`,
};

const deliveryCsv = {
  DELIVERY_CSV_JOB: (id) => `${baseUrl}merchant_accounts/${id}/deliveries/csv_export_job`,
  DELIVERY_CSV_DOWNLOAD: (id) => `${baseUrl}csv_export_jobs/${id}`,
};

const messagingWidget = {
  ZENDESK_MESSAGING_WIDGET_AUTH_TOKEN: `${baseUrl}auth/zendesk_messaging_auth_token`,
};

const merchantUsers = {
  MERCHANT_USERS: (id) => `${baseUrl}merchant_users/${id}`,
};

const proviersManagment = {
  GET_MERCHANT_PROVIDERS: (id) => `${baseUrl}merchant_accounts/${id}/providers`,
  PROVIDER_ACCESS_REQUEST: (accountId, providerId) =>
    `${baseUrl}merchant_accounts/${accountId}/providers/${providerId}/provider_access_request`,
  PROVIDER_ACCESS_REQUEST_QUESTIONNAIRE: `${baseUrl}provider_access_request/questionnaire`,
  PROVIDER_CREDENTIAL: (accountId, providerId) =>
    `${baseUrl}merchant_accounts/${accountId}/providers/${providerId}/provider_credential`,
  UPDATE_ACCOUNT_PROVIDER_SETTING: (id) => `${baseUrl}account_provider_settings/${id}`,
  PROVIDER_CONTACT_REQUEST: (accountId, providerId) =>
    `${baseUrl}merchant_accounts/${accountId}/providers/${providerId}/provider_contact_request`,
  MISSING_PROVIDER_REQUEST: (accountId) => `${baseUrl}merchant_accounts/${accountId}/missing_provider_request`,
};

const ratings = {
  CREATE_RATING: (id) => `${baseUrl}delivery/${id}/ratings`,
};

const webhooks = {
  SUBSCRIBLE_EVENT_TYPES: (id) => `${baseUrl}webhooks/${id}/subscribeable_webhook_event_types`,
  UPDATE_WEBHOOK: (id) => `${baseUrl}webhooks/${id}`,
  EVENT_TYPES: (id) => `${baseUrl}webhooks/${id}/webhook_subscriptions`,
  DELETE_EVENT_TYPE: (id) => `${baseUrl}webhook_subscriptions/${id}`,
};

const referrals = {
  POST_CREATE_REFERRAL: (id) => `${baseUrl}merchant_users/${id}/referral_requests`,
};

const partners = {
  PARTNER_USERS: `${baseUrl}partner/v1/partner_users`,
  PARTNER_PRICING_RULE: (id) => `${baseUrl}merchant_account/partner/pricing/${id}`,
  PARTNER_PRICING_RULES: `${baseUrl}merchant_account/partner/pricing`,
};

const pusher = {
  PUSHER_USER_AUTHENTICATION: `${baseUrl}pusher/user_authentication`,
  PUSHER_CHANNEL_AUTHORIZATION: `${baseUrl}pusher/user_authorization`,
};

const branding = {
  GET_BRANDING_SETTINGS: (id) => `${baseUrl}merchant_accounts/${id}/branding_settings`,

  PUT_BRANDING_SETTINGS: (id) => `${baseUrl}merchant_accounts/${id}/branding_settings`,

  POST_BRANDING_SETTINGS: (id) => `${baseUrl}merchant_accounts/${id}/branding_settings`,

  POST_UPLOAD_BRANDING_FILE: (id) => `${baseUrl}merchant_accounts/${id}/branding_settings/uploadImage`,
  DELETE_BRANDING_IMAGE: (id) => `${baseUrl}merchant_accounts/${id}/branding_settings/delete_image`,
};

const invoice = {
  GET_INVOICES: (id) => `${baseUrl}merchant_accounts/${id}/invoices`,
  INVOICES_CSV_EXPORT: (id) => `${baseUrl}invoices/${id}/csv_export`,
  GET_INVOICE: (id) => `${baseUrl}invoices/${id}`,
  GET_TRANSACTIONS: (id) => `${baseUrl}invoices/${id}/transactions`,
  PAY_INVOICE: (id) => `${baseUrl}invoices/${id}/payment`,
  INVOICE_PDF_LINK: (id) => `${baseUrl}invoices/${id}/pdf_link`,
  GET_INVOICES_STORES: (id) => `${baseUrl}invoices/${id}/stores`,
  GET_STRIPE_PAYMENT_ACCOUNT: (id) => `${baseUrl}merchant_accounts/${id}/stripe_payment_account`,
};

const notifications = {
  GET_NOTIFICATIONS: (id) => `${baseUrl}merchant_accounts/${id}/notifications`,
  RESET_NOTIFICATIONS_COUNT: (id) => `${baseUrl}merchant_accounts/${id}/notifications/reset`,
  READ_ONE_NOTIFICATION: (id) => `${baseUrl}merchant_accounts/${id}/notifications/read`,
  READ_ALL_NOTIFICATIONS: (id) => `${baseUrl}merchant_accounts/${id}/notifications/mark_all_read`,
};
const notificationsSettings = {
  GET_NOTIFICATIONS_SETTINGS: (id) => `${baseUrl}merchant_users/${id}/notification_settings`,
  PUT_UPDATE_NOTIFICATIONS_SETTING: (userId, notificationId) =>
    `${baseUrl}merchant_users/${userId}/notification_settings/${notificationId}`,
};

const uploadCsvBulk = {
  GET_PRESIGNED_URL: (id) => `${baseUrl}merchant_accounts/${id}/orders/bulk_csv_uploads/presigned_url`,
  POST_START_BULK_UPLOAD: (id) => `${baseUrl}merchant_accounts/${id}/orders/bulk_csv_uploads`,
  GET_CSV_ORDERS_LIST: (id) => `${baseUrl}merchant_accounts/${id}/orders/csv_import_jobs`,
  GET_CSV_ORDER_CHILD_INFO: (id, orderId) =>
    `${baseUrl}merchant_accounts/${id}/orders/csv_import_jobs/${orderId}/order_create_jobs`,
};

const rerouteRecommendations = {
  REROUTE_RECOMMENDATIONS: `${baseUrlWithoutApi}admin/reroute_intents`,
  REROUTE_INTENT_ACTION: (intent_id) => `${baseUrlWithoutApi}admin/reroute_intents/${intent_id}`,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...orders,
  ...analytics,
  ...onboarding,
  ...accounts,
  ...payment,
  ...statements,
  ...paymentHistory,
  ...merchantIncidents,
  ...incidents,
  ...deliveries,
  ...providers,
  ...users,
  ...deliveryCsv,
  ...messagingWidget,
  ...merchantUsers,
  ...adminDeliveries,
  ...proviersManagment,
  ...ratings,
  ...webhooks,
  ...partners,
  ...pusher,
  ...branding,
  ...invoice,
  ...notifications,
  ...notificationsSettings,
  ...uploadCsvBulk,
  ...rerouteRecommendations,
  ...referrals,
};
