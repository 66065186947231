import React, { useEffect, useState } from "react";
import Select from "react-select";

const SelectField = ({
  optionsList = [],
  onSelect,
  defaultValueIndex,
  resetSelection,
  isDisabled = false,
  isMulti = false,
  styleProps = null,
  placeholder = "Choose",
  useExternalState = false,
  selectedValue = null,
}) => {
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(null);

  const customstyles = {
    inputForEdit: {
      width: "100%",
      border: `1px solid #F2F2F7`,
      padding: "6px 4px",
      borderRadius: "10px",
    },
    input: {
      fontFamily: "Poppins",
      fontSize: "14px",
      border: "0px",
      padding: "3px 10px",
      borderRadius: "8px",
      outlineWidth: "0px",
      width: "100%",
      fontWeight: "500",
    },
  };

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      ...customstyles.inputForEdit,
    }),
    input: (styles) => ({
      ...styles,
      ...customstyles.input,
    }),
    options: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? "#0062FF"
              : "#CDCDCD"
            : undefined,
        },
        borderRadius: "10px",
      };
    },
  };

  useEffect(() => {
    setOptions(optionsList);
  }, []);

  useEffect(() => {
    if (resetSelection) {
      resetDropdown();
    }
  }, [resetSelection]);

  const resetDropdown = () =>
    !useExternalState ? setValue(null) : onSelect(null);

  const onChange = (payload) => {
    if (!useExternalState) {
      setValue(payload);
    }
    onSelect(payload);
  };

  return (
    <Select
      className={isMulti ? "basic-multi-select" : "basic-single"}
      classNamePrefix="select"
      value={useExternalState ? selectedValue : value}
      defaultValue={options[defaultValueIndex]}
      isDisabled={isDisabled}
      isClearable={true}
      isSearchable={true}
      onChange={onChange}
      name="label"
      options={options}
      styles={styleProps ? styleProps : colourStyles}
      placeholder={placeholder}
      isMulti={isMulti}
    />
  );
};

export default SelectField;
