import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useOnboardingStore, useTheme } from "../../store";
import { Loading } from "../../assets/svgs/Svgs";
import OnboardingHeader from "./OnboardingHeader";
import OnboardingFooter from "./OnboardingFooter";
import useViewportMeta from "../../customHooks/useViewportMeta";
import { isValidEmail } from "./utils";
import CommonInput from "../CommonInput";
import classNames from "classnames";

import styles from "./styles/styles.module.css";

const ConnectedAccountOnboarding = () => {
  useViewportMeta();
  const { Colors } = useTheme();

  const { invitationDetails, platformInfo, getPlatformInfo, isLoading, getInvitationDetails } = useOnboardingStore();

  const { id } = useParams();

  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);

  const isButtonEnabled = !!email && isValidEmail(email);

  const handleChange = (event) => {
    if (!isValidEmail(event.target.value)) {
      setError("Pleade enter a valid email");
    } else {
      setError(null);
    }

    setEmail(event.target.value);
  };

  useEffect(() => {
    if (!invitationDetails?.id) {
      if (id) {
        getPlatformInfo(id);
      }
    } else if (invitationDetails?.id) {
      window.location.href = `/onboarding/${invitationDetails.id}`;
    }
  }, [invitationDetails, id]);

  const submitHandler = async () => {
    if (!isValidEmail(email) || isLoading) return;
    const encodedEmail = encodeURIComponent(email);

    const params = {
      email: encodedEmail,
      platform_account_id: id,
    };
    getInvitationDetails(params, platformInfo?.redirect_url);
  };

  if (!id || !platformInfo) {
    return (
      <div className="w-full h-screen flex justify-center items-center">
        <Loading color={Colors.bluePrimary} className="h-12 w-12 animate-spin" />{" "}
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <div className={styles.background}></div>
        <div className="py-6 px-11 w-full">
          <OnboardingHeader />
          <div className="w-full flex flex-col items-center">
            <div className={styles.title}>Enter your email address</div>
            <ul className={styles.instructions}>
              <li className="mb-3 mt-5">Use the same email you use with {platformInfo?.platform_name}.</li>
              <li>You'll use this to log in to create and track customer orders.</li>
            </ul>
            <div className={classNames("mt-8 mb-4 relative", styles.inputCont)}>
              <div className={styles.label}>Email Address</div>
              <CommonInput type="text" onChange={handleChange} className={classNames(styles.inputs, "mb-2")} />
              {error && <p style={{ color: "red", position: "absolute", fontSize: "12px" }}>{error}</p>}
            </div>
            {/* //! has to be ramoved for cleanup */}
            {/* <ConnectedAccountEmailForm platformAccountId={id} platformInfo={platformInfo} /> */}
          </div>
        </div>

        <OnboardingFooter disabled={!isButtonEnabled} onClick={submitHandler} isLoading={isLoading} />
      </div>
    </div>
  );
};

export default ConnectedAccountOnboarding;
