import React, { useState } from "react";
import moment from "moment";

import {
  IoChevronDownCircleOutline,
  IoCloseCircleOutline,
} from "react-icons/io5";
import {
  useClickStore,
  useIncidents,
  useMyStore,
  useSingleItemStore,
  useTheme,
  userDataStore,
} from "../../../../store";
import { useDesktopOrLaptop } from "../../../../services/useDesktopOrLaptop";
import CustomModal from "../../../CustomModal/CustomModal";
import Details from "../../Details";
import { numberWithCommas } from "../../../../utils/helpers";
import ButtonComp from "../../../ButtonComp/ButtonComp";
import { EditIcon } from "../../../../assets/svgs/Svgs";

import { incidentsStatuses } from "./utils";
import StatusBadgeReusable from "../../../shared/common/StatusBadgeReusable";

import RerouteButtonForIncident from "./RerouteButtonForIncident";

const DeliveryIncidentItem = ({
  incident,
  incidentsPaginationHandler,
  searchedId,
  index,
}) => {
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);

  const { setAllTimeVisible } = useClickStore();
  const { isdesktop } = useDesktopOrLaptop();
  const { authInfo } = userDataStore();
  const { isSandbox } = useMyStore();

  const {
    setIsCreateEditIncidentModalOpen,
    setSelectedIncident,
    assignAdminToincidentHandler,
    incidents,
    setUpdateList,
    setIncidents,
    updatingIncidentIndex,
    setUpdatingIncidentIndex,
  } = useIncidents();

  const { Colors, themeChanger } = useTheme();
  const { xsfontsize } = useDesktopOrLaptop();
  return (
    <>
      <CustomModal
        extraPanelStyles={{ maxWidth: isdesktop ? "60%" : "80%" }}
        isOpen={isDetailsOpen}
        onClose={(e) => {
          setIsDetailsOpen(false);
        }}
      >
        <Details
          index={index}
          deliveryId={incident?.delivery_id}
          onClose={() => {
            setIsDetailsOpen(false);
          }}
        />
      </CustomModal>
      <div
        style={{
          borderBottomWidth: "1.5px",
          borderColor: Colors.borderPrimary,
          fontWeight: "500",
          color: Colors.fontDark,
        }}
        className={`grid grid-cols-12 gap-1 w-full py-3 ${
          !themeChanger ? "hover:bg-gray-700" : "hover:bg-gray-100"
        }`}
      >
        <div className="col-start-1 col-span-1 pl-4 break-all flex ">
          {incident.assignee_user_name}
        </div>
        <button
          type="button"
          style={{ color: Colors.bluePrimary }}
          onClick={() => {
            setIsDetailsOpen(true);
          }}
          className="col-start-2 col-span-1 underline break-all"
        >
          <div>{incident.delivery_id}</div>
          <div>{incident.merchant_account_name}</div>
        </button>
        <div className="col-start-3 col-span-1 justify-center flex items-center">
          {incident.delivery_fee_and_tips_refunded ? (
            <IoChevronDownCircleOutline color={Colors.greenDark} size={26} />
          ) : (
            <IoCloseCircleOutline color={Colors.alertRed} size={26} />
          )}
        </div>
        <div className="col-start-4 col-span-1 justify-center flex items-center">
          {incident.has_return_payment ? incident.return_fee_refunded ? (
            <IoChevronDownCircleOutline color={Colors.greenDark} size={26} />
          ) : (
            <IoCloseCircleOutline color={Colors.alertRed} size={26} />
          ) : null}
        </div>

        <div className="col-start-5 col-span-1 justify-center flex w-full capitalize items-center break-all">
          {incident.status && (
            <>
              <StatusBadgeReusable
                badgesObject={incidentsStatuses}
                status={incident.status}
              />
            </>
          )}
        </div>

        <div className="col-start-6 col-span-1 justify-center flex items-center break-all">
          $
          {numberWithCommas(
            (incident?.order_value_refunded_amount / 100)?.toFixed(2)
          )}
        </div>
        <div className="col-start-7 col-span-1 justify-center flex items-center break-all">
          $
          {numberWithCommas(
            (incident?.provider_refunded_amount / 100)?.toFixed(2)
          )}
        </div>
        <div className="col-start-8 col-span-1 justify-center flex items-center flex-col break-all ">
          <span>{moment(incident.created_at).format("MMM D")}</span>
          <span>{moment(incident.created_at).format("hh:mma")}</span>
        </div>
        <div className="col-start-9 col-span-1 justify-center flex items-center text-center ">
          {incident.reroute_child_delivery_id ? (
            <IoChevronDownCircleOutline color={Colors.greenDark} size={26} />
          ) : (
            <RerouteButtonForIncident delivery_id={incident.delivery_id} />
          )}
        </div>
        <div className="col-start-10 col-span-2 justify-center flex capitalize text-center">
          {incident.internal_note}
        </div>
        <div className="col-start-12 col-span-1 justify-center flex items-center">
          {incident?.status === "pending" ? (
            <ButtonComp
              onClick={async (e) => {
                e.stopPropagation();
                const res = await assignAdminToincidentHandler(incident?.id, {
                  assignee_user_id: authInfo?.id,
                });
                if (res) {
                  const _incidents = { ...incidents };
                  _incidents.data[index].status = "processing";
                  setIncidents(_incidents);

                  setAllTimeVisible(false);
                  setIsCreateEditIncidentModalOpen(true);

                  setUpdatingIncidentIndex(index);
                  setSelectedIncident(res);
                }
              }}
              primary
              title={"Review"}
              extraStyles={{
                padding: "8px 12px",
                fontSize: xsfontsize,
                fontWeight: "400",
              }}
            />
          ) : (
            <button
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                setAllTimeVisible(false);
                setIsCreateEditIncidentModalOpen(true);

                setUpdatingIncidentIndex(index);
                setSelectedIncident(incident);
              }}
            >
              <EditIcon />
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default DeliveryIncidentItem;
