import React, { useEffect, useMemo } from "react";
import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";
import { useAnalyticsStore, useFeatureFlags, useMyStore, useTheme, userDataStore } from "../../../store";
import HeatChart from "./HeatChart";
import HeatChartHeader from "./HeatChartHeader";

const HeatChartSection = () => {
  const { isSnowflakeDisabled } = useFeatureFlags();

  const { Colors } = useTheme();
  const { isdesktop } = useDesktopOrLaptop();
  const { isSandbox, userId } = useMyStore();
  const { authInfo } = userDataStore();

  const { datePayload, topDeliveryTiming, getDeliveryTopTiming } = useAnalyticsStore();

  const styles = useMemo(() => {
    return {
      mainContainer: {
        padding: "0 12px",
        width: "100%",
        marginBottom: isdesktop ? "24px" : "10px",
      },
      container: {
        border: `1px solid ${Colors.borderSecondary}`,
        borderRadius: "8px",
        padding: isdesktop ? "24px" : "10px",
        boxSizing: "border-box",
        width: "100%",
      },
    };
  }, [Colors, isdesktop]);

  useEffect(() => {
    if (getDeliveryTopTiming) {
      getDeliveryTopTiming(isSandbox, datePayload, userId ?? authInfo.merchantAccountId, isSnowflakeDisabled);
    }
  }, [isSandbox, datePayload, userId, authInfo]);

  return (
    <div style={styles.mainContainer}>
      <div style={styles.container}>
        <HeatChartHeader />
        <HeatChart payload={topDeliveryTiming} />
      </div>
    </div>
  );
};

export default HeatChartSection;
