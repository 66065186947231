import React from "react";
import moment from "moment";
import { AiFillBank } from "react-icons/ai";

import { useTheme } from "../../store";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import { cardLogoHandler } from "../InsideOfStores/UtilsComponents";
import { numberWithCommas } from "../../utils/helpers";
import StatusBadgeReusable from "../shared/common/StatusBadgeReusable";
import { statusesBadgesObject } from "./utils";
import styles from "./styles/paymentHistoryListItem.module.css";

const PaymentHistoryItem = ({ paymentObject }) => {
  const { smfontsize, xsfontsize } = useDesktopOrLaptop();
  const { Colors } = useTheme();

  return (
    <tr className={styles.tableRow}>
      <td className={styles.tableRowText}>{moment(paymentObject?.created_at).format("MMM D, YYYY")}</td>
      <td className={styles.tableRowText}>
        {paymentObject.is_paid_out_of_band ? (
          <span>Paid Out of Band</span>
        ) : (
          <div>
            <div className="flex items-center">
              {paymentObject.payment_method?.type === "us_bank_account" ? (
                <div
                  style={{
                    borderWidth: "1px",
                    borderColor: Colors.borderPrimary,
                  }}
                  className="p-1 rounded"
                >
                  <AiFillBank size={20} />
                </div>
              ) : (
                cardLogoHandler(paymentObject.payment_method?.card_issuer, xsfontsize, Colors)
              )}
              <div className="text-gray-400 ml-3 font-medium h-5">
                <span
                  style={{
                    fontSize: smfontsize,
                    color: Colors.fontGreyLight,
                  }}
                >
                  ****
                </span>
                <span
                  style={{
                    fontSize: smfontsize,
                    color: Colors.fontGreyLight,
                  }}
                  className="text-gray-800"
                >
                  {" " + paymentObject.payment_method?.public_identifier}
                </span>
              </div>
            </div>
          </div>
        )}
      </td>
      <td className={styles.tableRowText}>$ {numberWithCommas((paymentObject?.amount / 100)?.toFixed(2))}</td>
      <td className={styles.tableRowText}>
        <StatusBadgeReusable
          status={paymentObject.status}
          badgesObject={statusesBadgesObject}
          externalClassName={styles.badgeClass}
        />
      </td>
      <td className={styles.tableRowText}></td>
    </tr>
  );
};

export default PaymentHistoryItem;
