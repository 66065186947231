import { useField, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { BsCheck } from "react-icons/bs";
import { useDesktopOrLaptop } from "../../../../services/useDesktopOrLaptop";

import { useTheme } from "../../../../store";
import styles from "./styles.module.css";
import { findAmountByName } from "../utils";

const TipsWithoutPercents = ({ quoteRes, rerouteItem }) => {
  const { Colors } = useTheme();

  const { mdfontsize } = useDesktopOrLaptop();

  const { values, setFieldValue } = useFormikContext();
  const [meta] = useField("tip");

  const [isCustomSelected, setIsCustomSelected] = useState(false);

  const styless = {
    bottomCardText: {
      fontSize: mdfontsize,
      fontFamily: "Poppins",
      fontWeight: "500",
    },
  };

  const dynamicTextHandler = (tip) => {
    if (tip <= 1) {
      return (
        <div className={styles.dynamicText}>
          Tips help to ensure top-tier service quality, timely deliveries, and fostering seamless order execution. Lower tip can
          result in longer acceptance time resulting in slower delivery
        </div>
      );
    } else if (tip > 1 && tip <= 4) {
      return <div className={styles.dynamicText}>100% of the tips goes to the driver</div>;
    } else if (tip > 4) {
      return <div className={styles.dynamicText}>Thank you for your generous tip!</div>;
    }
  };

  useEffect(() => {
    if (rerouteItem) {
      const tip = findAmountByName(rerouteItem.cost_line_items, "Tip");
      if (tip) {
        const tipForPayload = (tip / 100).toFixed(2);
        setFieldValue("tip", tipForPayload);
        if (tip !== (1).toFixed(2) || tip !== (2).toFixed(2) || tip !== (3).toFixed(2) || tip !== (5).toFixed(2)) {
          setIsCustomSelected(true);
        }
      }
    }
  }, [rerouteItem]);

  return (
    <div className={styles.container}>
      <div className={styles.headerText}>Add a Tip: </div>
      <div className="flex items-center ">
        <div className="flex items-center">
          <button
            disabled={quoteRes ? false : true}
            onClick={() => {
              setIsCustomSelected(false);
              setFieldValue("tip", 0);
            }}
            type="button"
            className="flex items-center outline-none mr-14"
          >
            <div
              className="flex justify-center items-center h-4 w-4 rounded-full"
              style={{
                backgroundColor: values.tip === 0 ? "#6ED29D" : Colors.buttonBg,
                border: values.tip === 0 ? "0px" : "solid 1px #92929D",
              }}
            >
              <BsCheck color={values.tip === 0 ? "white" : Colors.buttonBg} />
            </div>
            <div
              style={{
                ...styless.bottomCardText,
                ...{ color: Colors.fontGreyLight, marginLeft: "13px" },
              }}
            >
              $0
            </div>
          </button>
          <button
            disabled={quoteRes ? false : true}
            onClick={() => {
              setIsCustomSelected(false);
              setFieldValue("tip", (1).toFixed(2));
            }}
            type="button"
            className="flex items-center outline-none mr-14"
          >
            <div
              className="flex justify-center items-center h-4 w-4 rounded-full"
              style={{
                backgroundColor: values.tip === (1).toFixed(2) ? "#6ED29D" : Colors.buttonBg,
                border: values.tip === (1).toFixed(2) ? "0px" : "solid 1px #92929D",
              }}
            >
              <BsCheck color={values.tip === (1).toFixed(2) ? "white" : Colors.buttonBg} />
            </div>
            <div
              style={{
                ...styless.bottomCardText,
                ...{ color: Colors.fontGreyLight, marginLeft: "10px" },
              }}
            >
              $1
            </div>
          </button>
          <button
            disabled={!quoteRes}
            onClick={() => {
              setIsCustomSelected(false);
              setFieldValue("tip", quoteRes ? (2).toFixed(2) : 0);
            }}
            type="button"
            className="flex items-center outline-none mr-14"
          >
            <div
              className="flex justify-center items-center h-4 w-4 rounded-full"
              style={{
                backgroundColor: values.tip === (2).toFixed(2) ? "#6ED29D" : Colors.buttonBg,
                border: values.tip === (2).toFixed(2) ? "0px" : "solid 1px #92929D",
              }}
            >
              <BsCheck color={values.tip === (2).toFixed(2) ? "white" : Colors.buttonBg} />
            </div>
            <div
              style={{
                ...styless.bottomCardText,
                ...{ color: Colors.fontGreyLight, marginLeft: "10px" },
              }}
            >
              $2
            </div>
          </button>
          <button
            disabled={quoteRes ? false : true}
            onClick={() => {
              setIsCustomSelected(false);
              setFieldValue("tip", quoteRes ? (3).toFixed(2) : 0);
            }}
            type="button"
            className="flex items-center outline-none mr-14 relative"
          >
            <div className="flex items-center">
              <div
                className="flex justify-center items-center h-4 w-4 rounded-full"
                style={{
                  backgroundColor: values.tip === (3).toFixed(2) ? "#6ED29D" : Colors.buttonBg,
                  border: values.tip === (3).toFixed(2) ? "0px" : "solid 1px #92929D",
                }}
              >
                <BsCheck color={values.tip === (3).toFixed(2) ? "white" : Colors.buttonBg} />
              </div>
              <div
                style={{
                  ...styless.bottomCardText,
                  ...{ color: Colors.fontGreyLight, marginLeft: "10px" },
                }}
              >
                $3
              </div>
            </div>
            <div className={styles.bannerCont}>
              <div className={styles.mostTippedBanner}>
                <div className={styles.leftSquare} />
                <div className={styles.rightSquare} />
                Most Tipped
              </div>
            </div>
          </button>
          <button
            disabled={!quoteRes}
            onClick={() => {
              setIsCustomSelected(false);
              setFieldValue("tip", quoteRes ? (5).toFixed(2) : 0);
            }}
            type="button"
            className="flex items-center outline-none mr-0"
          >
            <div
              className="flex justify-center items-center h-4 w-4 rounded-full"
              style={{
                backgroundColor: values.tip === (5).toFixed(2) ? "#6ED29D" : Colors.buttonBg,
                border: values.tip === (5).toFixed(2) ? "0px" : "solid 1px #92929D",
              }}
            >
              <BsCheck color={values.tip === (5).toFixed(2) ? "white" : Colors.buttonBg} />
            </div>
            <div
              style={{
                ...styless.bottomCardText,
                ...{ color: Colors.fontGreyLight, marginLeft: "10px" },
              }}
            >
              $5
            </div>
          </button>
        </div>
        <div className="flex items-center ml-14 w-auto">
          {!isCustomSelected ? (
            <div
              onClick={() => {
                setIsCustomSelected(true);
                setFieldValue("tip", "");
              }}
              className="flex items-center"
            >
              <div
                className="flex justify-center items-center h-4 w-4 rounded-full mr-3"
                style={{
                  backgroundColor: isCustomSelected ? "#6ED29D" : Colors.buttonBg,
                  border: isCustomSelected ? "0px" : "solid 1px #92929D",
                }}
              >
                <BsCheck color={isCustomSelected ? "white" : Colors.buttonBg} />
              </div>
              <div className={styles.customTipButton}> Custom Tip</div>
            </div>
          ) : (
            <>
              <div
                className="flex justify-center items-center h-4 w-4 rounded-full mr-3"
                style={{
                  backgroundColor: isCustomSelected ? "#6ED29D" : Colors.buttonBg,
                  border: isCustomSelected ? "0px" : "solid 1px #92929D",
                }}
              >
                <BsCheck color={isCustomSelected ? "white" : Colors.buttonBg} />
              </div>
              {/* <div className={styles.dollarSign}>$</div> */}
              <input
                autoFocus
                disabled={!(quoteRes || quoteRes === 0)}
                placeholder="0"
                type="number"
                onChange={(e) => {
                  const value = e.target.value.replace(/[^.\d]/g, "");
                  setFieldValue("tip", value);
                }}
                value={values.tip}
                className={styles.tipInput}
              />
            </>
          )}
        </div>
      </div>
      {meta.error && <div className={styles.err}>{meta.error}</div>}
      {dynamicTextHandler(values.tip)}
    </div>
  );
};

export default TipsWithoutPercents;
