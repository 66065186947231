import { createStore } from "aniuta";
import axios from "axios";
import Cookies from "js-cookie";
import { useState } from "react";
import { toast } from "react-toastify";
import { baseUrl, baseUrlOfApi } from "../runTimeConst";

const useTripManagement = createStore({
  name: "useTripManagement",
  Store: () => {
    const [tripQuoteRes, setTripQuoteRes] = useState();
    const createTripQuote = async (isSandbox, payload) => {
      try {
        const response = await axios.post(`${baseUrlOfApi}api/trips`, payload, {
          headers: {
            "x-api-key": isSandbox
              ? Cookies.get("sandbox")
              : Cookies.get("prod"),
            "x-burq-client": "client-dashboard",
          },
          withCredentials: false,
        });
        setTripQuoteRes(response.data);
        return response.data;
      } catch (err) {
        console.log(err);
        if (err.response.data.message) {
          toast.error(err.response.data.message);
        }
        return err;
      }
    };

    const submitTrip = async (isSandbox, tripId, quote_id) => {
      try {
        const response = await axios.post(
          `${baseUrlOfApi}api/trips/${tripId}/submit`,
          { quote_id },
          {
            headers: {
              "x-api-key": isSandbox
                ? Cookies.get("sandbox")
                : Cookies.get("prod"),
              "x-burq-client": "client-dashboard",
            },
            withCredentials: false,
          }
        );
        return response.data;
      } catch (err) {
        console.log(err);
        if (err.response.data.message) {
          toast.error(err.response.data.message);
        }
        return err;
      }
    };

    const [tripsList, setTripsList] = useState();

    const [isTripsListLoading, setIsTripsListLoading] = useState(false);

    const getTrips = async (isSandbox, beforeId, afterId, status, limit) => {
      setIsTripsListLoading(true);
      try {
        const response = await axios.get(
          `${baseUrlOfApi}api/trips?ending_before=${
            beforeId || ""
          }&starting_after=${afterId || ""}&limit=30&trip_status=${
            status || ""
          }`,
          {
            headers: {
              "x-api-key": isSandbox
                ? Cookies.get("sandbox")
                : Cookies.get("prod"),
              "x-burq-client": "client-dashboard",
            },
            withCredentials: false,
          }
        );

        setTripsList(response.data);
        setIsTripsListLoading(false);
        return response.data;
      } catch (err) {
        setIsTripsListLoading(false);
        console.log(err);
        if (err.response.data.message) {
          toast.error(err.response.data.message);
        }
        return err;
      }
    };

    const cancelTrip = async (isSandbox, id, reason) => {
      try {
        const response = await axios.post(
          `${baseUrlOfApi}api/trips/${id}/cancel`,
          { reason },
          {
            headers: {
              "x-api-key": isSandbox
                ? Cookies.get("sandbox")
                : Cookies.get("prod"),
              "x-burq-client": "client-dashboard",
            },
            withCredentials: false,
          }
        );
        toast.success("Trip canceled successfully");
        return response.data;
      } catch (err) {
        console.log(err);
        if (err.response.data.message) {
          toast.error(err.response.data.message);
        }
        return err;
      }
    };

    return {
      isTripsListLoading,
      setIsTripsListLoading,
      createTripQuote,
      tripQuoteRes,
      setTripQuoteRes,
      submitTrip,
      getTrips,
      tripsList,
      setTripsList,
      cancelTrip,
    };
  },
});

export default useTripManagement;
