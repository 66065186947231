import React from "react";
import { FaCcDinersClub, FaCcJcb } from "react-icons/fa";
import { MasterCard, VisaCard } from "../../assets/svgs/Svgs";
import { GrAmex } from "react-icons/gr";
import { SiDiscover } from "react-icons/si";
import unionpayPng from "../../assets/png/unionpay.png";
import { useTheme } from "../../store";
import { AiFillBank } from "react-icons/ai";

const CardLogo = ({ cardIssuer, type }) => {
  const { Colors } = useTheme();

  if (type === "us_bank_account") {
    return <AiFillBank size={20} color={Colors.bluePrimary} />;
  }
  return (
    <>
      {cardIssuer === "visa" ? (
        <VisaCard />
      ) : cardIssuer === "diners" ? (
        <FaCcDinersClub size={20} color={Colors.bluePrimary} />
      ) : cardIssuer === "amex" ? (
        <GrAmex size={28} color={Colors.bluePrimary} />
      ) : cardIssuer === "unionpay" ? (
        <div
          style={{
            color: Colors.bluePrimary,
            fontSize: "12px",
            fontWeight: "500",
          }}
        >
          <img style={{ objectFit: "contain", height: "18px" }} src={unionpayPng} alt="" />
        </div>
      ) : cardIssuer === "discover" ? (
        <SiDiscover size={32} color={Colors.bluePrimary} />
      ) : cardIssuer === "jcb" ? (
        <FaCcJcb size={20} color={Colors.bluePrimary} />
      ) : (
        <MasterCard />
      )}
    </>
  );
};

export default CardLogo;
