import axios from "axios";
import apiUrls from "../../constants/apiUrls";
import { generateQueryParams } from "./utils";

export const getAllProviders = (id) =>
  axios.get(apiUrls.PROVIDERS_LIST, {
    headers: { "x-burq-client": "client-dashboard" },
  });

export const getMerchantEnabledProviders = (id, params) => {
  const urlWithQueryParams = generateQueryParams(apiUrls.MERCHATN_ENABLED_PROVIDERS_LIST(id), params);
  return axios.get(urlWithQueryParams, {
    headers: { "x-burq-client": "client-dashboard" },
  });
};
// export const getAccountProviderSettings = (id) =>
//   axios.get(apiUrls.GET_ACCOUNT_PROVIDER_SETTING(id), {
//     headers: { "x-burq-client": "client-dashboard" },
//   });

export const getMerchantProviders = (id, params) => {
  const urlWithQueryParams = generateQueryParams(apiUrls.GET_MERCHANT_PROVIDERS(id), params);
  return axios.get(urlWithQueryParams, {
    headers: { "x-burq-client": "client-dashboard" },
  });
};

export const updateAccountProviderSetting = (id, paylaod) =>
  axios.put(apiUrls.UPDATE_ACCOUNT_PROVIDER_SETTING(id), paylaod, {
    headers: { "x-burq-client": "client-dashboard" },
  });

export const createProviderAccessRequest = (accountId, providerId, paylaod) =>
  axios.post(apiUrls.PROVIDER_ACCESS_REQUEST(accountId, providerId), paylaod, {
    headers: { "x-burq-client": "client-dashboard" },
  });

export const getProviderAccessRequestQuestionnaire = (id) =>
  axios.get(apiUrls.PROVIDER_ACCESS_REQUEST_QUESTIONNAIRE, {
    headers: { "x-burq-client": "client-dashboard" },
  });

export const getProviderCredential = (accountId, providerId, params) => {
  const urlWithQueryParams = generateQueryParams(apiUrls.PROVIDER_CREDENTIAL(accountId, providerId), params);
  return axios.get(urlWithQueryParams, {
    headers: { "x-burq-client": "client-dashboard" },
  });
};

export const createProviderContactRequest = (accountId, providerId, paylaod) =>
  axios.post(apiUrls.PROVIDER_CONTACT_REQUEST(accountId, providerId), paylaod, {
    headers: { "x-burq-client": "client-dashboard" },
  });

export const createMissingProviderRequest = (accountId, paylaod) =>
  axios.post(apiUrls.MISSING_PROVIDER_REQUEST(accountId), paylaod, {
    headers: { "x-burq-client": "client-dashboard" },
  });
