import React, { useState } from 'react';
import { AiOutlineClear } from 'react-icons/ai';
import { useDesktopOrLaptop } from '../../../../../services/useDesktopOrLaptop';
import { useInvoices, useTheme } from '../../../../../store';
import StatusFilterOptionList from './StatusFilterOptionList';
import { BiFilterAlt } from 'react-icons/bi';

const StatusFilter = () => {
    const { isdesktop, smfontsize } = useDesktopOrLaptop();
    const { Colors } = useTheme();
    const { paymentStatusfilter, setPaymentStatusfilter } = useInvoices();
    const [isListVisible, setIsListVisible] = useState(false);


    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                width: !isdesktop && "100%",
                marginRight: '20px',
                position: "relative",
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: !isdesktop && "100%",
                    justifyContent: "end",
                }}
                className="items-center w-full"
            >
                <div
                    style={{
                    }}
                    className="flex items-center"
                >
                    <button
                        style={{
                            borderWidth: "1px",
                            borderColor: "#D8D8D8",
                            borderRadius: "16px",
                            padding: isdesktop ? "4px 4px" : "4px 4px",
                            marginLeft: "0px",
                            borderStyle: paymentStatusfilter ? "solid" : "dashed",
                        }}
                        onClick={() => {
                            setIsListVisible(true);
                        }}
                        type="button"
                        className="flex flex-row items-center focus:outline-none "
                    >
                        {paymentStatusfilter ? (
                            <button
                                type="button"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setPaymentStatusfilter(null);
                                }}
                                style={{ padding: "2px 0px 2px 10px" }}
                            >
                                <AiOutlineClear
                                    color={Colors.fontGreyLight}
                                    size={isdesktop ? 22 : 26}
                                />
                            </button>
                        ) : <BiFilterAlt color={Colors.fontGreyLight} size={18} />}
                        <div
                            style={{
                                fontFamily: "Poppins",
                                fontSize: smfontsize,
                                color: Colors.fontGreyLight,
                                display: "flex",
                                alignItems: "center",
                                padding: paymentStatusfilter
                                    ? "2px 6px 2px 0px"
                                    : "2px 10px 2px 0px",
                            }}
                        >
                            <span style={{ marginLeft: "8px" }}>Payment Status</span>
                        </div>
                        {paymentStatusfilter && (
                            <div
                                style={{
                                    padding: "2px 10px 2px 6px",
                                    color: Colors.fontGreyLight,
                                    maxWidth: "170px",
                                    fontSize: smfontsize,
                                }}
                                className="border-l border-[#D8D8D8] border-dashed truncate"
                            >
                                {paymentStatusfilter.label}
                            </div>
                        )}
                    </button>

                    <StatusFilterOptionList
                        isListVisible={isListVisible}
                        setIsListVisible={setIsListVisible}
                    />
                </div>
            </div>
        </div>
    );
};

export default StatusFilter;
