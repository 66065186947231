import axios from "axios";
import apiUrls from "../../constants/apiUrls";
import { generateQueryParams } from "./utils";

// export const subscribleEventTypes = (id, params = {}) => {
//   const urlWithQueryParams = generateQueryParams(
//     apiUrls.DEPOSIT_STATEMENT_TRANSACTIONS(id),
//     params
//   );
//   return axios.get(urlWithQueryParams, {
//     headers: { "x-burq-client": "client-dashboard" },
//   });
// };

export const subscribleEventTypes = (id) =>
  axios.get(apiUrls.SUBSCRIBLE_EVENT_TYPES(id), {
    headers: { "x-burq-client": "client-dashboard" },
  });

export const subscribedEvents = (id) =>
  axios.get(apiUrls.EVENT_TYPES(id), {
    headers: { "x-burq-client": "client-dashboard" },
  });

export const updateWebhooks = (id, payload) =>
  axios.put(apiUrls.UPDATE_WEBHOOK(id), payload, {
    headers: { "x-burq-client": "client-dashboard" },
  });

export const addEventTypes = (id, payload) =>
  axios.post(apiUrls.EVENT_TYPES(id), payload, {
    headers: { "x-burq-client": "client-dashboard" },
  });

export const eventTypeDelete = (id) =>
  axios.delete(apiUrls.DELETE_EVENT_TYPE(id), {
    headers: { "x-burq-client": "client-dashboard" },
  });
