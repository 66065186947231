import React, { useState, useRef, useLayoutEffect } from "react";
import CancelDeliveryRequest from "../../../CancelDeliveryRequest";
import CustomModal from "../../../CustomModal/CustomModal";
import styles from "../../styles/deliveryDetailsCollapsable.module.css";
import HeaderTabs from "../../../shared/common/HeaderTabs";
import DeliverySummary from "./DeliverySummary";
import DeliveryFailureLogs from "./DeliveryFailureLogs";
import { useDesktopOrLaptop } from "../../../../services/useDesktopOrLaptop";
import RequestIncident from "./RequestIncident";

const TABS = {
  SUMMARY: "Summary",
  DEVELOPER: "Logs",
};

export default function DeliveryDetailsCollapsable({
  item,
  setIsCalendarVisible,
  statusesHystory,
  setDetailsHeight,
}) {
  const [cancelVisible, setCancelVisible] = useState(false);
  const [isIncidentVisible, setIsIncidentVisible] = useState(false);
  const [activeTab, setActiveTab] = useState(TABS.SUMMARY);
  const { isdesktop } = useDesktopOrLaptop();

  const heightRef = useRef(null);
  useLayoutEffect(() => {
    // setDetailsHeight(heightRef.current.clientHeight);
  }, [heightRef]);

  const tabs = [
    { label: TABS.SUMMARY, value: TABS.SUMMARY },
    {
      label: TABS.DEVELOPER,
      value: TABS.DEVELOPER,
      isHidden: item?.status !== "failed",
    },
  ];

  return (
    <div ref={heightRef} className={styles.container}>
      <CustomModal
        extraPanelStyles={{ maxWidth: isdesktop ? "50%" : "80%" }}
        isOpen={cancelVisible}
        onClose={() => setCancelVisible(false)}
      >
        <CancelDeliveryRequest
          setIsCalendarVisible={setIsCalendarVisible}
          request_id={item.id}
          setCancelVisible={setCancelVisible}
        />
      </CustomModal>
      <CustomModal
        extraPanelStyles={{ maxWidth: isdesktop ? "40%" : "80%" }}
        isOpen={isIncidentVisible}
        onClose={() => setIsIncidentVisible(false)}
      >
        <RequestIncident
          delInfo={item}
          setIsIncidentVisible={setIsIncidentVisible}
        />
      </CustomModal>
      <div className="px-7 mt-4">
        <HeaderTabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabs={tabs}
        />
      </div>
      {activeTab === TABS.DEVELOPER && item?.status === "failed" ? (
        <DeliveryFailureLogs deliveryId={item?.id} />
      ) : (
        <DeliverySummary
          item={item}
          statusesHystory={statusesHystory}
          setCancelVisible={setCancelVisible}
          setIsIncidentVisible={setIsIncidentVisible}
          isIncidentVisible={isIncidentVisible}
        />
      )}
    </div>
  );
}
