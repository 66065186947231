import React, { useState } from 'react';
import { useAdmin, useTheme } from '../../../store';
import newStyles from "./styles.module.css";
import Button from '../../shared/atoms/Button';
import Loader from '../../shared/atoms/Loader';
import { useDesktopOrLaptop } from '../../../services/useDesktopOrLaptop';

const MarkDeliveryNotReroutable = ({ deliveryId, onClose, onCallback }) => {
    const { Colors } = useTheme();
    const [value, setValue] = useState('');
    const [isClickedOnSubmit, setIsClickedOnSubmit] = useState('');
    const { isMarkDeliveryAsNotReroutableLoading, markDeliveryAsNotReroutableWorker } = useAdmin();
    const { isdesktop } = useDesktopOrLaptop();

    const submitHandler = async () => {
        if (!value) {
            setIsClickedOnSubmit(true);
            return
        }
        const payload = {
            not_rerouted_reason: value
        }
        const callback = (res, error) => {
            if (onCallback) onCallback(res, error);
            onClose();
        }
        await markDeliveryAsNotReroutableWorker(deliveryId, payload, callback);
    }

    return (
        <div className={newStyles.container}>
            <div className={newStyles.rerouteReasonContainer}>
                <button
                    onClick={onClose}
                    type="button"
                    style={{
                        color: Colors?.fontDark,
                        fontSize: isdesktop ? "20px" : "36px",
                        right: "20px",
                        top: "20px"
                    }}
                    className="material-icons outline-none absolute"
                >
                    close
                </button>
                <h1 style={{textAlign: 'center'}}>Mark Delivery Not Reroutable</h1>
                <select
                    disabled={false}
                    draggable={true}
                    value={value || ''}
                    placeholder="Select Reroute Reason"
                    onChange={(e) => {
                        setValue(
                            e.target.value
                        );
                    }}
                    style={{
                        ...{
                            fontFamily: "Poppins",
                            borderRadius: "8px",
                            fontSize: "14px",
                            lineHeight: "21px",
                            fontWeight: "400",
                            color: Colors.fontDark,
                            backgroundColor: Colors.buttonBg,
                            padding: "10px",
                            minHeight: "43px",
                        },
                        ...{ backgroundColor: Colors.borderPrimary },
                    }}
                    className="w-full focus:outline-none "
                >
                    <option value="" disabled hidden>
                        No Reroute Reason
                    </option>
                    <option id="no_alternative_provider" value="no_alternative_provider">
                        No Alternative Provider
                    </option>
                    <option id="alternative_too_expensive" value="alternative_too_expensive">
                        Alternative Too Expensive
                    </option>
                    <option id="items_not_available" value="items_not_available">
                        Items Not Available
                    </option>
                    <option id="restaurant_closed" value="restaurant_closed">
                        Restaurant Closed
                    </option>
                    <option id="customer_cancelled" value="customer_cancelled">
                        Customer Cancelled
                    </option>
                    <option id="already_picked_up" value="already_picked_up">
                        Already Picked Up
                    </option>
                    <option id="merchant_requested" value="merchant_requested">
                        Merchant Requested
                    </option>
                </select>
                {isClickedOnSubmit && !value ? (
                    <p
                        style={{
                            color: Colors.redDark,
                            fontFamily: "Poppins",
                            fontSize: "12px",
                            padding: "0 10px 3px 12px",
                            textAlign: 'left',
                        }}
                    >
                        Please select an option
                    </p>
                ) : null}
            </div>
            <Button 
                disabled={isMarkDeliveryAsNotReroutableLoading} 
                externalClassName={newStyles.button} 
                onClick={submitHandler}
            >
                {isMarkDeliveryAsNotReroutableLoading ?
                    <Loader color="#fff" extraClassName={newStyles.loader} /> : "Update"}
            </Button>
        </div>
    );
};

export default MarkDeliveryNotReroutable;
