import React from "react";
import styles from "./styles.module.css";
import classnames from "classnames";

const Hyperlink = ({ text = "", onClick, externalClassName = "" }) => {
  return (
    <div
      className={classnames(styles.link, externalClassName)}
      onClick={onClick}
    >
      {text}
    </div>
  );
};

export default Hyperlink;
