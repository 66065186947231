import React from "react";
import CustomModal from "../../../CustomModal/CustomModal";
import { useDesktopOrLaptop } from "../../../../services/useDesktopOrLaptop";
import { useTheme } from "../../../../store";

function AreYouSurePopup({ isOpen, onClose, onYes, helperText = null }) {
  const { mdfontsize, isdesktop } = useDesktopOrLaptop();
  const { Colors } = useTheme();
  return (
    <CustomModal
      extraPanelStyles={{ maxWidth: isdesktop ? "50%" : "80%" }}
      isOpen={isOpen}
      onClose={onClose}
    >
      <div
        style={{ backgroundColor: Colors.white }}
        className="flex flex-col  py-16 px-10  justify-center items-startrounded-md w-full overflow-hidden"
      >
        <div
          style={{ color: Colors.fontDark }}
          className="text-2xl  font-bold text-center w-full mb-6"
        >
          Are you Sure?
        </div>
        {helperText && (
          <div
            style={{
              color: Colors.fontDark,
              fontSize: mdfontsize,
              padding: "0px 40px",
            }}
            className="text-center w-full mb-16"
          >
            {helperText}
          </div>
        )}
        <div className="w-full flex justify-evenly">
          <button
            onClick={onYes}
            className="focus:outline-none px-12 py-1.5 bg-red-50 rounded-xl text-red-500 border border-red-500 text-center"
          >
            Yes
          </button>
          <button
            onClick={onClose}
            className="focus:outline-none px-12 py-1.5 bg-blue-600 rounded-xl text-indigo-50 text-center"
          >
            No
          </button>
        </div>
      </div>
    </CustomModal>
  );
}

export default AreYouSurePopup;
