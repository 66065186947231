import React from "react";
import { useDesktopOrLaptop } from "../../../../services/useDesktopOrLaptop";
import TabButton from "../TabButton";
import classNames from "classnames";

const HeaderTabs = ({
  tabs = [],
  activeTab,
  setActiveTab,
  extraButtonClasses,
  extraBottomLineClasses,
  extraBottomLineClassesInActive,
  extraBottomLineClassesActive,
  extraClasses,
}) => {
  const { isdesktop } = useDesktopOrLaptop();

  return (
    <div
      className={classNames(
        `flex flex-row items-center gap-4  ${!isdesktop && "scrollbar"}`,
        extraClasses
      )}
    >
      {tabs.map((tab) =>
        tab.isHidden ? null : (
          <TabButton
            extraButtonClasses={extraButtonClasses}
            extraBottomLineClasses={extraBottomLineClasses}
            extraBottomLineClassesInActive={extraBottomLineClassesInActive}
            extraBottomLineClassesActive={extraBottomLineClassesActive}
            key={tab.label}
            name={tab.label}
            onClick={() => {
              setActiveTab(tab.value);
              if (tab.action) tab.action();
            }}
            active={activeTab === tab.value}
          />
        )
      )}
    </div>
  );
};

export default HeaderTabs;
