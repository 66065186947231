import { createStore } from "aniuta";
import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import { baseUrl } from "../runTimeConst";

const useOrdersForMap = createStore({
  name: "useOrdersForMap",
  Store: () => {
    const [ordersArray, setOrdersArray] = useState([]);
    const [directionsForOrders, setDirectionsForOrders] = useState([]);

    return {
      ordersArray,
      setOrdersArray,
      directionsForOrders,
      setDirectionsForOrders,
    };
  },
});

export default useOrdersForMap;
