import React from "react";
import { useClickStore, useTheme, useWorkflow } from "../../store";
import QuoteWorkflow from "./QuoteWorkflow";
import SmsWorkflow from "./SmsWorkflow";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import BrandingWorkflow from "./BrandingWorkflow";
import { Outlet } from "react-router-dom";
// import OrderWorkflow from "./OrderWorkflow";

function Workflows() {
  const { Colors } = useTheme();
  const { activeWorkflow } = useWorkflow();
  const { isdesktop } = useDesktopOrLaptop();

  return (
    <div
      style={{
        backgroundColor: isdesktop ? Colors.bgGrey : Colors.white,
        height: isdesktop ? `calc(100vh - 70px)` : `calc(100vh - 200px)`,
        overflowY: "auto",
        display: "flex",
        flexDirection: "row",
        width: "100%",
      }}
    >
      <Outlet />
      {/* {activeWorkflow === "sms" ? (
        <SmsWorkflow />
      ) : activeWorkflow === "branding" ? (
        <BrandingWorkflow />
      ) : (
        activeWorkflow === "quote" && <QuoteWorkflow />
      )} */}
    </div>
  );
}

export default Workflows;
