import { useState } from "react";
import * as Sentry from "@sentry/react";
import { createStore } from "aniuta";
import moment from "moment";

import {
  // getAverageOrderAmountDeliveryData,
  getDeliveryAmountData,
  getDeliveryCitiesData,
  // getDeliveryCostAverageData,
  // getDeliveryReportData,
  getDeliveryTimeData,
  getDeliveryTopTimingData,
  getDeliveryVolumeData,
  // getOrdersValueData,
  // getOrderValueAverageData,
  // getOrderVolumeData,
  // getProviderCanceledData,
  // getProviderDeliveriesSentData,
  // getProviderDeliveryAmountData,
  // getProviderDeliveryTimeAverageData,
  // getProviderOrderValueData,
  getProvidersUsedData,
  getTopDeliveryPickupLocationsData,
  getDeliveryAverageMilesData,
  getAnalyticsOverviewData,
  getDeliveryAverageData,
  getAvgDeliveryPickUpTimeData,
  getAvgDeliveryPickUpDelayTimeData,
  getAvgDeliveryDropOffTimeData,
  getAvgDeliveryDropOffDelayTimeData,
  // getProviderTopStoresData,
  getDeliveryReturnData,
} from "../utils/api/analytics";
import {
  combineData,
  groupByOption,
  InsightsInitialPayload,
  getPreviousMonthDate,
  GROUP_BY_OPTIONS,
  selectPeriodHelper,
  formatSingleRangeData,
  formatTopTimingData,
  transformData,
  backfillHelperFunctionDays,
} from "../components/Analytics/utils";

const compareFormatedDate = getPreviousMonthDate({
  prevMonth: 1,
  isStart: true,
});
const initFormatedDate = getPreviousMonthDate({ prevMonth: 0, isStart: true });

const initDate = {
  start_date: initFormatedDate.monthStart,
  end_date: moment().format("YYYY-MM-DD"),
};
const initCompareDate = {
  start_date: compareFormatedDate.monthStart,
  end_date: moment().subtract(1, "months").format("YYYY-MM-DD"),
};

const useAnalyticsStore = createStore({
  name: "AnalyticsStore",
  Store: () => {
    // const [providerUsed, setProviderUsed] = useState(initPayload);
    // const [overViewIsLoading, setOverViewIsLoading] = useState(false);
    const [datePayload, setDatePayload] = useState(initDate);
    const [compareDatePayload, setCompareDatePayload] = useState(initCompareDate);

    //! -----------------------------------------------------------------
    /////////////////////Insights Charts Section/////////////////////////////////////////////////////////////
    const [deliveryVolume, setDeliveryVolume] = useState(InsightsInitialPayload);
    const [deliveryValue, setDeliveryValue] = useState(InsightsInitialPayload);
    const [orderValue, setOrderValue] = useState(InsightsInitialPayload);
    const [averageDeliveryTime, setAverageDeliveryTime] = useState(InsightsInitialPayload);
    const [averageMileage, setAverageMileage] = useState(InsightsInitialPayload);
    const [deliverySuccessRate, setDeliverySuccessRate] = useState(InsightsInitialPayload);
    const [deliveryFailureRate, setDeliveryFailureRate] = useState(InsightsInitialPayload);
    const [deliveryReturns, setDeliveryReturns] = useState(InsightsInitialPayload);
    const [averagePickupTime, setAveragePickupTime] = useState(InsightsInitialPayload);
    const [averagePickupDelays, setAveragePickupDelays] = useState(InsightsInitialPayload);
    const [averageDropoffTime, setAverageDropoffTime] = useState(InsightsInitialPayload);
    const [averageDropoffDelays, setAverageDropoffDelays] = useState(InsightsInitialPayload);

    // const [orderVolume, setOrderVolume] = useState(InsightsInitialPayload);

    const [selectedProviderArray, setSelectedProviderArray] = useState(undefined);
    const [isDateSelected, setIsDateSelected] = useState(true);

    const getDeliveryVolume = async (isSandbox, datePayload, compareDatePayload, user_id, isSnowflakeDisabled) => {
      // const user_id = "acct_05df7836-ea71-44fb-b483-c7c00dd97a3d";
      if (!user_id) return;
      const groupBy = groupByOption(datePayload, compareDatePayload);

      try {
        const requestedProviders =
          selectedProviderArray && (selectedProviderArray[0] === "all_providers" || selectedProviderArray[0] == [])
            ? []
            : selectedProviderArray?.length > 0
            ? selectedProviderArray
            : undefined;
        if (!isDateSelected) {
          const dateBody = {
            test_mode: isSandbox,
            start_date: datePayload.start_date,
            end_date: datePayload.end_date,
            // user_id: "acct_05df7836-ea71-44fb-b483-c7c00dd97a3d",
            user_id: user_id,
            group_by: groupBy,
            filter_type: !isDateSelected ? "provider_filter" : undefined,
            providers: JSON.stringify(requestedProviders),
          };
          const res = await getDeliveryVolumeData(dateBody, isSnowflakeDisabled);

          const _requestedProviders = [...requestedProviders];
          _requestedProviders.push("all_providers");
          const providers = res.data == [] ? [] : res.data.analytics_data.map((item) => item.provider_name);
          const missingElements = _requestedProviders.filter((element) => !providers.includes(element));
          let backfilledData;
          if (missingElements.length > 0) {
            const newData = backfillHelperFunctionDays(
              missingElements,
              datePayload.start_date,
              datePayload.end_date,
              "total_delivery_volume"
            );

            if (res.data == []) {
              backfilledData = { analytics_data: [] };
            } else {
              backfilledData = { ...res.data };
            }
            backfilledData.analytics_data = [...backfilledData.analytics_data, ...newData];
          }
          const transformedData = transformData(backfilledData || res.data, "total_delivery_volume");

          setDeliveryVolume(transformedData);
          return transformedData;
        } else {
          const dateBody = {
            test_mode: isSandbox,
            start_date: datePayload.start_date,
            end_date: datePayload.end_date,
            user_id: user_id,
            group_by: groupBy,
          };
          const compareDateBody = {
            test_mode: isSandbox,
            start_date: compareDatePayload.start_date,
            end_date: compareDatePayload.end_date,
            user_id: user_id,
            group_by: groupBy,
          };
          const [response, compareDateResponse] = await Promise.all([
            getDeliveryVolumeData(dateBody, isSnowflakeDisabled),
            getDeliveryVolumeData(compareDateBody, isSnowflakeDisabled),
          ]);

          const finaldata = combineData(
            { ...response.data, ...datePayload },
            { ...compareDateResponse.data, ...compareDatePayload },
            "data",
            false,
            "total_delivery_volume",
            groupBy
          );

          setDeliveryVolume(finaldata);
          return finaldata;
        }
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    };

    const getAverageMileage = async (isSandbox, datePayload, compareDatePayload, user_id, isSnowflakeDisabled) => {
      if (!user_id) return;
      const groupBy = groupByOption(datePayload, compareDatePayload);

      try {
        const requestedProviders =
          selectedProviderArray && (selectedProviderArray[0] === "all" || selectedProviderArray[0] == [])
            ? []
            : selectedProviderArray?.length > 0
            ? selectedProviderArray
            : undefined;
        if (!isDateSelected) {
          const dateBody = {
            test_mode: isSandbox,
            start_date: datePayload.start_date,
            end_date: datePayload.end_date,
            // user_id: "acct_05df7836-ea71-44fb-b483-c7c00dd97a3d",
            user_id: user_id,
            group_by: groupBy,
            filter_type: !isDateSelected ? "provider_filter" : undefined,
            providers: JSON.stringify(requestedProviders),
          };
          const res = await getDeliveryAverageMilesData(dateBody, isSnowflakeDisabled);

          const _requestedProviders = [...requestedProviders];
          _requestedProviders.push("all_providers");
          const providers = res.data == [] ? [] : res.data.analytics_data.map((item) => item.provider_name);
          const missingElements = _requestedProviders.filter((element) => !providers.includes(element));
          let backfilledData;
          if (missingElements.length > 0) {
            const newData = backfillHelperFunctionDays(
              missingElements,
              datePayload.start_date,
              datePayload.end_date,
              "average_miles"
            );

            if (res.data == []) {
              backfilledData = { analytics_data: [] };
            } else {
              backfilledData = { ...res.data };
            }
            backfilledData.analytics_data = [...backfilledData.analytics_data, ...newData];
          }
          const transformedData = transformData(backfilledData || res.data, "average_miles");

          setAverageMileage(transformedData);
          return transformedData;
        } else {
          const dateBody = {
            test_mode: isSandbox,
            start_date: datePayload.start_date,
            end_date: datePayload.end_date,
            user_id: user_id,
            group_by: groupBy,
          };
          const compareDateBody = {
            test_mode: isSandbox,
            start_date: compareDatePayload.start_date,
            end_date: compareDatePayload.end_date,
            user_id: user_id,
            group_by: groupBy,
          };
          const [response, compareDateResponse] = await Promise.all([
            getDeliveryAverageMilesData(dateBody, isSnowflakeDisabled),
            getDeliveryAverageMilesData(compareDateBody, isSnowflakeDisabled),
          ]);

          const finaldata = combineData(
            { ...response.data, ...datePayload },
            { ...compareDateResponse.data, ...compareDatePayload },
            "data",
            false,
            "average_miles",
            groupBy
          );

          setAverageMileage(finaldata);
          return finaldata;
        }
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    };

    const getDeliveryReturns = async (isSandbox, datePayload, compareDatePayload, user_id, isSnowflakeDisabled) => {
      if (!user_id) return;
      const groupBy = groupByOption(datePayload, compareDatePayload);

      try {
        const requestedProviders =
          selectedProviderArray && (selectedProviderArray[0] === "all" || selectedProviderArray[0] == [])
            ? []
            : selectedProviderArray?.length > 0
            ? selectedProviderArray
            : undefined;
        if (!isDateSelected) {
          const dateBody = {
            test_mode: isSandbox,
            start_date: datePayload.start_date,
            end_date: datePayload.end_date,
            // user_id: "acct_05df7836-ea71-44fb-b483-c7c00dd97a3d",
            user_id: user_id,
            group_by: groupBy,
            filter_type: !isDateSelected ? "provider_filter" : undefined,
            providers: JSON.stringify(requestedProviders),
          };
          const res = await getDeliveryReturnData(dateBody, isSnowflakeDisabled);

          const _requestedProviders = [...requestedProviders];
          _requestedProviders.push("all_providers");
          const providers = res.data == [] ? [] : res.data.analytics_data.map((item) => item.provider_name);
          const missingElements = _requestedProviders.filter((element) => !providers.includes(element));
          let backfilledData;
          if (missingElements.length > 0) {
            const newData = backfillHelperFunctionDays(
              missingElements,
              datePayload.start_date,
              datePayload.end_date,
              "returned_deliveries_count"
            );

            if (res.data == []) {
              backfilledData = { analytics_data: [] };
            } else {
              backfilledData = { ...res.data };
            }
            backfilledData.analytics_data = [...backfilledData.analytics_data, ...newData];
          }
          const transformedData = transformData(backfilledData || res.data, "returned_deliveries_count");

          setDeliveryReturns(transformedData);
          return transformedData;
        } else {
          const dateBody = {
            test_mode: isSandbox,
            start_date: datePayload.start_date,
            end_date: datePayload.end_date,
            user_id: user_id,
            group_by: groupBy,
          };
          const compareDateBody = {
            test_mode: isSandbox,
            start_date: compareDatePayload.start_date,
            end_date: compareDatePayload.end_date,
            user_id: user_id,
            group_by: groupBy,
          };
          const [response, compareDateResponse] = await Promise.all([
            getDeliveryReturnData(dateBody, isSnowflakeDisabled),
            getDeliveryReturnData(compareDateBody, isSnowflakeDisabled),
          ]);

          const finaldata = combineData(
            { ...response.data, ...datePayload },
            { ...compareDateResponse.data, ...compareDatePayload },
            "data",
            false,
            "returned_deliveries_count",
            groupBy
          );

          setDeliveryReturns(finaldata);
          return finaldata;
        }
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    };

    const getAverageDeliveryTime = async (isSandbox, datePayload, compareDatePayload, user_id, isSnowflakeDisabled) => {
      // const user_id = "acct_05df7836-ea71-44fb-b483-c7c00dd97a3d";
      if (!user_id) return;
      const groupBy = groupByOption(datePayload, compareDatePayload);

      try {
        const requestedProviders =
          selectedProviderArray && (selectedProviderArray[0] === "all" || selectedProviderArray[0] == [])
            ? []
            : selectedProviderArray?.length > 0
            ? selectedProviderArray
            : undefined;
        if (!isDateSelected) {
          const dateBody = {
            test_mode: isSandbox,
            start_date: datePayload.start_date,
            end_date: datePayload.end_date,
            // user_id: "acct_05df7836-ea71-44fb-b483-c7c00dd97a3d",
            user_id: user_id,
            group_by: groupBy,
            filter_type: !isDateSelected ? "provider_filter" : undefined,
            providers: JSON.stringify(requestedProviders),
          };
          const res = await getDeliveryTimeData(dateBody, isSnowflakeDisabled);

          const _requestedProviders = [...requestedProviders];
          _requestedProviders.push("all_providers");
          const providers = res.data == [] ? [] : res.data.analytics_data.map((item) => item.provider_name);
          const missingElements = _requestedProviders.filter((element) => !providers.includes(element));
          let backfilledData;
          if (missingElements.length > 0) {
            const newData = backfillHelperFunctionDays(
              missingElements,
              datePayload.start_date,
              datePayload.end_date,
              "average_delivery_time"
            );

            if (res.data == []) {
              backfilledData = { analytics_data: [] };
            } else {
              backfilledData = { ...res.data };
            }
            backfilledData.analytics_data = [...backfilledData.analytics_data, ...newData];
          }
          const transformedData = transformData(backfilledData || res.data, "average_delivery_time");

          setAverageDeliveryTime(transformedData);
          return transformedData;
        } else {
          const dateBody = {
            test_mode: isSandbox,
            start_date: datePayload.start_date,
            end_date: datePayload.end_date,
            user_id: user_id,
            group_by: groupBy,
          };
          const compareDateBody = {
            test_mode: isSandbox,
            start_date: compareDatePayload.start_date,
            end_date: compareDatePayload.end_date,
            user_id: user_id,
            group_by: groupBy,
          };
          const [response, compareDateResponse] = await Promise.all([
            getDeliveryTimeData(dateBody, isSnowflakeDisabled),
            getDeliveryTimeData(compareDateBody, isSnowflakeDisabled),
          ]);

          const finaldata = combineData(
            { ...response.data, ...datePayload },
            { ...compareDateResponse.data, ...compareDatePayload },
            "data",
            false,
            "average_delivery_time",
            groupBy
          );

          setAverageDeliveryTime(finaldata);
          return finaldata;
        }
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    };

    const getAveragePickupTime = async (isSandbox, datePayload, compareDatePayload, user_id, isSnowflakeDisabled) => {
      if (!user_id) return;
      const groupBy = groupByOption(datePayload, compareDatePayload);

      try {
        const requestedProviders =
          selectedProviderArray && (selectedProviderArray[0] === "all" || selectedProviderArray[0] == [])
            ? []
            : selectedProviderArray?.length > 0
            ? selectedProviderArray
            : undefined;
        if (!isDateSelected) {
          const dateBody = {
            test_mode: isSandbox,
            start_date: datePayload.start_date,
            end_date: datePayload.end_date,
            // user_id: "acct_05df7836-ea71-44fb-b483-c7c00dd97a3d",
            user_id: user_id,
            group_by: groupBy,
            filter_type: !isDateSelected ? "provider_filter" : undefined,
            providers: JSON.stringify(requestedProviders),
          };
          const res = await getAvgDeliveryPickUpTimeData(dateBody, isSnowflakeDisabled);

          const _requestedProviders = [...requestedProviders];
          _requestedProviders.push("all_providers");
          const providers = res.data == [] ? [] : res.data.analytics_data.map((item) => item.provider_name);
          const missingElements = _requestedProviders.filter((element) => !providers.includes(element));
          let backfilledData;
          if (missingElements.length > 0) {
            const newData = backfillHelperFunctionDays(
              missingElements,
              datePayload.start_date,
              datePayload.end_date,
              "average_pickup_time"
            );

            if (res.data == []) {
              backfilledData = { analytics_data: [] };
            } else {
              backfilledData = { ...res.data };
            }
            backfilledData.analytics_data = [...backfilledData.analytics_data, ...newData];
          }
          const transformedData = transformData(backfilledData || res.data, "average_pickup_time");

          setAveragePickupTime(transformedData);
          return transformedData;
        } else {
          const dateBody = {
            test_mode: isSandbox,
            start_date: datePayload.start_date,
            end_date: datePayload.end_date,
            user_id: user_id,
            group_by: groupBy,
          };
          const compareDateBody = {
            test_mode: isSandbox,
            start_date: compareDatePayload.start_date,
            end_date: compareDatePayload.end_date,
            user_id: user_id,
            group_by: groupBy,
          };
          const [response, compareDateResponse] = await Promise.all([
            getAvgDeliveryPickUpTimeData(dateBody, isSnowflakeDisabled),
            getAvgDeliveryPickUpTimeData(compareDateBody, isSnowflakeDisabled),
          ]);

          const finaldata = combineData(
            { ...response.data, ...datePayload },
            { ...compareDateResponse.data, ...compareDatePayload },
            "data",
            false,
            "average_pickup_time",
            groupBy
          );

          setAveragePickupTime(finaldata);
          return finaldata;
        }
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    };

    const getAveragePickupDelays = async (isSandbox, datePayload, compareDatePayload, user_id, isSnowflakeDisabled) => {
      if (!user_id) return;
      const groupBy = groupByOption(datePayload, compareDatePayload);

      try {
        const requestedProviders =
          selectedProviderArray && (selectedProviderArray[0] === "all" || selectedProviderArray[0] == [])
            ? []
            : selectedProviderArray?.length > 0
            ? selectedProviderArray
            : undefined;
        if (!isDateSelected) {
          const dateBody = {
            test_mode: isSandbox,
            start_date: datePayload.start_date,
            end_date: datePayload.end_date,
            // user_id: "acct_05df7836-ea71-44fb-b483-c7c00dd97a3d",
            user_id: user_id,
            group_by: groupBy,
            filter_type: !isDateSelected ? "provider_filter" : undefined,
            providers: JSON.stringify(requestedProviders),
          };
          const res = await getAvgDeliveryPickUpDelayTimeData(dateBody, isSnowflakeDisabled);

          const _requestedProviders = [...requestedProviders];
          _requestedProviders.push("all_providers");
          const providers = res.data == [] ? [] : res.data.analytics_data.map((item) => item.provider_name);
          const missingElements = _requestedProviders.filter((element) => !providers.includes(element));
          let backfilledData;
          if (missingElements.length > 0) {
            const newData = backfillHelperFunctionDays(
              missingElements,
              datePayload.start_date,
              datePayload.end_date,
              "avgerage_pickup_delay"
            );

            if (res.data == []) {
              backfilledData = { analytics_data: [] };
            } else {
              backfilledData = { ...res.data };
            }
            backfilledData.analytics_data = [...backfilledData.analytics_data, ...newData];
          }
          const transformedData = transformData(backfilledData || res.data, "avgerage_pickup_delay");

          setAveragePickupDelays(transformedData);
          return transformedData;
        } else {
          const dateBody = {
            test_mode: isSandbox,
            start_date: datePayload.start_date,
            end_date: datePayload.end_date,
            user_id: user_id,
            group_by: groupBy,
          };
          const compareDateBody = {
            test_mode: isSandbox,
            start_date: compareDatePayload.start_date,
            end_date: compareDatePayload.end_date,
            user_id: user_id,
            group_by: groupBy,
          };
          const [response, compareDateResponse] = await Promise.all([
            getAvgDeliveryPickUpDelayTimeData(dateBody, isSnowflakeDisabled),
            getAvgDeliveryPickUpDelayTimeData(compareDateBody, isSnowflakeDisabled),
          ]);

          const finaldata = combineData(
            { ...response.data, ...datePayload },
            { ...compareDateResponse.data, ...compareDatePayload },
            "data",
            false,
            "avgerage_pickup_delay",
            groupBy
          );

          setAveragePickupDelays(finaldata);
          return finaldata;
        }
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    };

    const getAverageDropoffTime = async (isSandbox, datePayload, compareDatePayload, user_id, isSnowflakeDisabled) => {
      if (!user_id) return;
      const groupBy = groupByOption(datePayload, compareDatePayload);

      try {
        const requestedProviders =
          selectedProviderArray && (selectedProviderArray[0] === "all" || selectedProviderArray[0] == [])
            ? []
            : selectedProviderArray?.length > 0
            ? selectedProviderArray
            : undefined;
        if (!isDateSelected) {
          const dateBody = {
            test_mode: isSandbox,
            start_date: datePayload.start_date,
            end_date: datePayload.end_date,
            // user_id: "acct_05df7836-ea71-44fb-b483-c7c00dd97a3d",
            user_id: user_id,
            group_by: groupBy,
            filter_type: !isDateSelected ? "provider_filter" : undefined,
            providers: JSON.stringify(requestedProviders),
          };
          const res = await getAvgDeliveryDropOffTimeData(dateBody, isSnowflakeDisabled);

          const _requestedProviders = [...requestedProviders];
          _requestedProviders.push("all_providers");
          const providers = res.data == [] ? [] : res.data.analytics_data.map((item) => item.provider_name);
          const missingElements = _requestedProviders.filter((element) => !providers.includes(element));
          let backfilledData;
          if (missingElements.length > 0) {
            const newData = backfillHelperFunctionDays(
              missingElements,
              datePayload.start_date,
              datePayload.end_date,
              "average_dropoff_time"
            );

            if (res.data == []) {
              backfilledData = { analytics_data: [] };
            } else {
              backfilledData = { ...res.data };
            }
            backfilledData.analytics_data = [...backfilledData.analytics_data, ...newData];
          }
          const transformedData = transformData(backfilledData || res.data, "average_dropoff_time");

          setAverageDropoffTime(transformedData);
          return transformedData;
        } else {
          const dateBody = {
            test_mode: isSandbox,
            start_date: datePayload.start_date,
            end_date: datePayload.end_date,
            user_id: user_id,
            group_by: groupBy,
          };
          const compareDateBody = {
            test_mode: isSandbox,
            start_date: compareDatePayload.start_date,
            end_date: compareDatePayload.end_date,
            user_id: user_id,
            group_by: groupBy,
          };
          const [response, compareDateResponse] = await Promise.all([
            getAvgDeliveryDropOffTimeData(dateBody, isSnowflakeDisabled),
            getAvgDeliveryDropOffTimeData(compareDateBody, isSnowflakeDisabled),
          ]);

          const finaldata = combineData(
            { ...response.data, ...datePayload },
            { ...compareDateResponse.data, ...compareDatePayload },
            "data",
            false,
            "average_dropoff_time",
            groupBy
          );

          setAverageDropoffTime(finaldata);
          return finaldata;
        }
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    };

    const getAverageDropoffDelays = async (isSandbox, datePayload, compareDatePayload, user_id, isSnowflakeDisabled) => {
      if (!user_id) return;
      const groupBy = groupByOption(datePayload, compareDatePayload);

      try {
        const requestedProviders =
          selectedProviderArray && (selectedProviderArray[0] === "all" || selectedProviderArray[0] == [])
            ? []
            : selectedProviderArray?.length > 0
            ? selectedProviderArray
            : undefined;
        if (!isDateSelected) {
          const dateBody = {
            test_mode: isSandbox,
            start_date: datePayload.start_date,
            end_date: datePayload.end_date,
            // user_id: "acct_05df7836-ea71-44fb-b483-c7c00dd97a3d",
            user_id: user_id,
            group_by: groupBy,
            filter_type: !isDateSelected ? "provider_filter" : undefined,
            providers: JSON.stringify(requestedProviders),
          };
          const res = await getAvgDeliveryDropOffDelayTimeData(dateBody, isSnowflakeDisabled);

          const _requestedProviders = [...requestedProviders];
          _requestedProviders.push("all_providers");
          const providers = res.data == [] ? [] : res.data.analytics_data.map((item) => item.provider_name);
          const missingElements = _requestedProviders.filter((element) => !providers.includes(element));
          let backfilledData;
          if (missingElements.length > 0) {
            const newData = backfillHelperFunctionDays(
              missingElements,
              datePayload.start_date,
              datePayload.end_date,
              "average_dropoff_delay"
            );

            if (res.data == []) {
              backfilledData = { analytics_data: [] };
            } else {
              backfilledData = { ...res.data };
            }
            backfilledData.analytics_data = [...backfilledData.analytics_data, ...newData];
          }
          const transformedData = transformData(backfilledData || res.data, "average_dropoff_delay");

          setAverageDropoffDelays(transformedData);
          return transformedData;
        } else {
          const dateBody = {
            test_mode: isSandbox,
            start_date: datePayload.start_date,
            end_date: datePayload.end_date,
            user_id: user_id,
            group_by: groupBy,
          };
          const compareDateBody = {
            test_mode: isSandbox,
            start_date: compareDatePayload.start_date,
            end_date: compareDatePayload.end_date,
            user_id: user_id,
            group_by: groupBy,
          };
          const [response, compareDateResponse] = await Promise.all([
            getAvgDeliveryDropOffDelayTimeData(dateBody, isSnowflakeDisabled),
            getAvgDeliveryDropOffDelayTimeData(compareDateBody, isSnowflakeDisabled),
          ]);

          const finaldata = combineData(
            { ...response.data, ...datePayload },
            { ...compareDateResponse.data, ...compareDatePayload },
            "data",
            false,
            "average_dropoff_delay",
            groupBy
          );

          setAverageDropoffDelays(finaldata);
          return finaldata;
        }
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    };

    const getDeliveryValue = async (isSandbox, datePayload, compareDatePayload, user_id, isSnowflakeDisabled) => {
      if (!user_id) return;
      const groupBy = groupByOption(datePayload, compareDatePayload);

      try {
        const dateBody = {
          test_mode: isSandbox,
          start_date: datePayload.start_date,
          end_date: datePayload.end_date,
          user_id: user_id,
          group_by: groupBy,
        };
        const compareDateBody = {
          test_mode: isSandbox,
          start_date: compareDatePayload.start_date,
          end_date: compareDatePayload.end_date,
          user_id: user_id,
          group_by: groupBy,
        };
        const [response, compareDateResponse] = await Promise.all([
          getDeliveryAmountData(dateBody, isSnowflakeDisabled),
          getDeliveryAmountData(compareDateBody, isSnowflakeDisabled),
        ]);

        const finaldata = combineData(
          { ...response.data, ...datePayload },
          { ...compareDateResponse.data, ...compareDatePayload },
          "data",
          true,
          "value",
          groupBy
        );

        setDeliveryValue(finaldata);
        return finaldata;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    };

    // const getOrderValue = async (isSandbox, datePayload, compareDatePayload, user_id, isSnowflakeDisabled) => {
    //   if (!user_id) return;
    //   const groupBy = groupByOption(datePayload, compareDatePayload);

    //   try {
    //     const dateBody = {
    //       test_mode: isSandbox,
    //       start_date: datePayload.start_date,
    //       end_date: datePayload.end_date,
    //       user_id: user_id,
    //       group_by: groupBy,
    //     };
    //     const compareDateBody = {
    //       test_mode: isSandbox,
    //       start_date: compareDatePayload.start_date,
    //       end_date: compareDatePayload.end_date,
    //       user_id: user_id,
    //       group_by: groupBy,
    //     };
    //     const [response, compareDateResponse] = await Promise.all([
    //       getOrdersValueData(dateBody, isSnowflakeDisabled),
    //       getOrdersValueData(compareDateBody, isSnowflakeDisabled),
    //     ]);

    //     const finaldata = combineData(
    //       { ...response.data, ...datePayload },
    //       { ...compareDateResponse.data, ...compareDatePayload },
    //       "data",
    //       true,
    //       "value",
    //       groupBy
    //     );
    //     setOrderValue(finaldata);
    //     return finaldata;
    //   } catch (error) {
    //     Sentry.captureException(error);
    //     throw error;
    //   }
    // };

    const getDeliverySuccessRate = async (isSandbox, datePayload, compareDatePayload, user_id, isSnowflakeDisabled) => {
      if (!user_id) return;
      const groupBy = groupByOption(datePayload, compareDatePayload);

      try {
        const dateBody = {
          test_mode: isSandbox,
          start_date: datePayload.start_date,
          end_date: datePayload.end_date,
          status: "delivered",
          user_id: user_id,
          group_by: groupBy,
        };
        const compareDateBody = {
          test_mode: isSandbox,
          start_date: compareDatePayload.start_date,
          end_date: compareDatePayload.end_date,
          status: "delivered",
          user_id: user_id,
          group_by: groupBy,
        };
        const [response, compareDateResponse] = await Promise.all([
          getDeliveryAverageData(dateBody, isSnowflakeDisabled),
          getDeliveryAverageData(compareDateBody, isSnowflakeDisabled),
        ]);

        const finaldata = combineData(
          { ...response.data, ...datePayload },
          { ...compareDateResponse.data, ...compareDatePayload },
          "data",
          false,
          "average",
          groupBy
        );

        setDeliverySuccessRate(finaldata);
        return finaldata;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    };

    // const getDeliveryFailureRate = async (isSandbox, datePayload, compareDatePayload, user_id, isSnowflakeDisabled) => {
    //   if (!user_id) return;
    //   const groupBy = groupByOption(datePayload, compareDatePayload);

    //   try {
    //     const dateBody = {
    //       test_mode: isSandbox,
    //       start_date: datePayload.start_date,
    //       end_date: datePayload.end_date,
    //       status: "failed",
    //       user_id: user_id,
    //       group_by: groupBy,
    //     };
    //     const compareDateBody = {
    //       test_mode: isSandbox,
    //       start_date: compareDatePayload.start_date,
    //       end_date: compareDatePayload.end_date,
    //       status: "failed",
    //       user_id: user_id,
    //       group_by: groupBy,
    //     };
    //     const [response, compareDateResponse] = await Promise.all([
    //       getDeliveryAverageData(dateBody, isSnowflakeDisabled),
    //       getDeliveryAverageData(compareDateBody, isSnowflakeDisabled),
    //     ]);

    //     const finaldata = combineData(
    //       { ...response.data, ...datePayload },
    //       { ...compareDateResponse.data, ...compareDatePayload },
    //       "data",
    //       false,
    //       "average",
    //       groupBy
    //     );

    //     setDeliveryFailureRate(finaldata);
    //     return finaldata;
    //   } catch (error) {
    //     Sentry.captureException(error);
    //     throw error;
    //   }
    // };

    /////////////////////Overview Charts Section/////////////////////////////////////////////////////////////
    const [overviewPeriod, setOverviewPeriod] = useState(4);
    const [selectStatus, setSelectStatus] = useState("delivered");
    const [overviewDeliveryReport, setOverviewDeliveryReport] = useState(InsightsInitialPayload);
    const [overviewKPIs, setOverviewKPIs] = useState({});

    const getOverviewDeliveryReport = async (isSandbox, user_id, isSnowflakeDisabled) => {
      if (!user_id) return;
      try {
        const datePayload = selectPeriodHelper(overviewPeriod);
        const groupBy = overviewPeriod === 4 ? GROUP_BY_OPTIONS.HOUR : GROUP_BY_OPTIONS.DAY;
        const dateBody = {
          test_mode: isSandbox,
          start_date: datePayload.start_date,
          end_date: datePayload.end_date,
          group_by: groupBy,
          user_id,
          status: selectStatus,
          utc_offset: moment().utcOffset() / 60,
        };
        const { data } = await getAnalyticsOverviewData(dateBody, isSnowflakeDisabled);

        // const utcRangeData = mapUtcRangeToLocalRange(data.data.data);
        // const parsedData = { ...data.data, data: utcRangeData };

        const finaldata = formatSingleRangeData({ ...data.data, ...datePayload }, "data", false, "count", groupBy);
        const { data: nestedData, ...rest } = data;
        setOverviewDeliveryReport(finaldata);
        // console.log("res", rest);
        setOverviewKPIs({ ...rest });
        return finaldata;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    };

    /////////////////////Top Timing section/////////////////////////////////////////////////////////////
    const [topTimingDataGroupBy, setTopTimingDataGroupBy] = useState("week");
    const [topDeliveryTiming, setTopDeliveryTiming] = useState([]);

    const getDeliveryTopTiming = async (isSandbox, datePayload, user_id, isSnowflakeDisabled) => {
      if (!user_id) return;
      try {
        const dateBody = {
          test_mode: isSandbox,
          start_date: datePayload.start_date,
          end_date: datePayload.end_date,
          user_id: user_id,
        };
        const response = await getDeliveryTopTimingData(dateBody, isSnowflakeDisabled);
        const deliveryTiming = response.data;
        const finaldata = formatTopTimingData(deliveryTiming.data);

        setTopDeliveryTiming([...finaldata]);
        return finaldata;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    };

    /////////////////////Table Section//////////////////////////////////////////////////////////////////

    const [topDeliveryProviders, setTopDeliveryProviders] = useState(InsightsInitialPayload);
    const [topStores, setTopStores] = useState(InsightsInitialPayload);

    const [topPickupLocations, setTopPickupLocations] = useState(InsightsInitialPayload);

    const getTopPickupLocations = async (isSandbox, datePayload, user_id, page, isSnowflakeDisabled) => {
      if (!user_id) return;
      try {
        const dateBody = {
          test_mode: isSandbox,
          start_date: datePayload.start_date,
          end_date: datePayload.end_date,
          user_id: user_id,
          page: page || 0,
        };
        const response = await getTopDeliveryPickupLocationsData(dateBody, isSnowflakeDisabled);
        const providerList = response.data;

        const finaldata = {
          data: [],
        };

        if (providerList.length) {
          finaldata.data = providerList.map((el, index) => {
            const newData = {
              // provider_name: el.Store?.name || "No Name",
              provider_name: el?.pickup_address || "No Name",
              provider_deliveries: el.count,
            };

            return newData;
          });
        }
        if (page) {
          // console.log("page", page, "topLoc", topPickupLocations, "finaldata", finaldata);

          let mergedData;
          mergedData = [...topPickupLocations.data, ...finaldata.data];
          setTopPickupLocations({ data: mergedData });
          // console.log("finaldataLength", mergedData.length);
          return { data: mergedData };
        } else {
          setTopPickupLocations(finaldata);
          return finaldata;
        }
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    };

    const getTopDeliveryProviders = async (isSandbox, datePayload, user_id, isSnowflakeDisabled) => {
      if (!user_id) return;
      try {
        const dateBody = {
          test_mode: isSandbox,
          start_date: datePayload.start_date,
          end_date: datePayload.end_date,
          user_id: user_id,
        };
        const response = await getProvidersUsedData(dateBody, isSnowflakeDisabled);
        const providerList = response.data;

        const finaldata = {
          data: [],
        };

        if (providerList.providers.length) {
          finaldata.data = providerList.providers.map((el) => {
            const newData = {
              provider_name: el.service,
              provider_logo_url: el.image_url,
              provider_deliveries: el.value,
            };
            return newData;
          });
        }

        setTopDeliveryProviders(finaldata);

        return finaldata;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    };

    // const getProviderTopStores = async (isSandbox, datePayload, user_id, isSnowflakeDisabled) => {
    //   if (!user_id) return;
    //   try {
    //     const dateBody = {
    //       test_mode: isSandbox,
    //       start_date: datePayload.start_date,
    //       end_date: datePayload.end_date,
    //       user_id: user_id,
    //     };
    //     const response = await getProviderTopStoresData(dateBody, isSnowflakeDisabled);
    //     const storeList = response.data;

    //     const finaldata = {
    //       data: [],
    //     };

    //     if (storeList?.stores?.length) {
    //       finaldata.data = storeList.stores.map((el) => {
    //         const newData = {
    //           provider_name: el?.Store?.name,
    //           provider_deliveries: el.count,
    //         };
    //         return newData;
    //       });
    //     }

    //     setTopStores(finaldata);
    //     return finaldata;
    //   } catch (error) {
    //     Sentry.captureException(error);
    //     throw error;
    //   }
    // };

    /////////////////////Active Users Section//////////////////////////////////////////////////////////////////
    const [topCityDeliveries, setTopCityDeliveries] = useState(InsightsInitialPayload);

    const getDeliveryCities = async (isSandbox, datePayload, user_id, isSnowflakeDisabled) => {
      if (!user_id) return;
      try {
        const dateBody = {
          test_mode: isSandbox,
          start_date: datePayload.start_date,
          end_date: datePayload.end_date,
          user_id: user_id,
        };
        const response = await getDeliveryCitiesData(dateBody, isSnowflakeDisabled);
        const cityList = response.data;

        const finaldata = {
          data: [],
        };

        if (cityList.cities.length) {
          finaldata.data = cityList.cities.map((el) => {
            const newData = {
              provider_name: el.pickup_address_city,
              provider_deliveries: el.count,
            };
            return newData;
          });
        }

        setTopCityDeliveries(finaldata);
        return finaldata;
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    };

    // const getReport = async (
    //   isSandbox,
    //   datePayload,
    //   user_id,
    //   status
    // ) => {
    //   const groupBy = groupByOption(datePayload, compareDatePayload);

    //   try {
    //     const dateBody = {
    //       test_mode: isSandbox,
    //       start_date: datePayload.start_date,
    //       end_date: datePayload.end_date,
    //       group_by: groupBy,
    //       user_id,
    //       status,
    //     };
    //     const compareDateBody = {
    //       test_mode: isSandbox,
    //       start_date: compareDatePayload.start_date,
    //       end_date: compareDatePayload.end_date,
    //       group_by: groupBy,
    //       user_id,
    //       status,
    //     };
    //     const [response, compareDateResponse] = await Promise.all([
    //       getDeliveryReportData(dateBody),
    //       getDeliveryReportData(compareDateBody),
    //     ]);

    //     const finaldata = combineData(
    //       { ...response.data, ...datePayload },
    //       { ...compareDateResponse.data, ...compareDatePayload },
    //       "data",
    //       false,
    //       "count",
    //       groupBy
    //     );

    //     setReport(finaldata);
    //     return finaldata;
    //   } catch (err) {
    //     console.warn(err);
    //     throw err;
    //   }
    // };

    // const getOrderVolume = async (
    //   isSandbox,
    //   datePayload,
    //   compareDatePayload,
    //   user_id
    // ) => {
    //   const groupBy = groupByOption(datePayload, compareDatePayload);

    //   try {
    //     const dateBody = {
    //       test_mode: isSandbox,
    //       start_date: datePayload.start_date,
    //       end_date: datePayload.end_date,
    //       user_id: user_id,
    //       group_by: groupBy,
    //     };
    //     const compareDateBody = {
    //       test_mode: isSandbox,
    //       start_date: compareDatePayload.start_date,
    //       end_date: compareDatePayload.end_date,
    //       user_id: user_id,
    //       group_by: groupBy,
    //     };
    //     const [response, compareDateResponse] = await Promise.all([
    //       getOrderVolumeData(dateBody),
    //       getOrderVolumeData(compareDateBody),
    //     ]);

    //     const finaldata = combineData(
    //       { ...response.data, ...datePayload },
    //       { ...compareDateResponse.data, ...compareDatePayload },
    //       "data",
    //       false,
    //       "count",
    //       groupBy
    //     );
    //     setOrderVolume(finaldata);
    //     return finaldata;
    //   } catch (err) {
    //     console.warn(err);
    //     throw err;
    //   }
    // };

    // const getProviderUsed = async (
    //   isSandbox,
    //   datePayload,
    //   compareDatePayload,
    //   user_id
    // ) => {
    //   try {
    //     const dateBody = {
    //       test_mode: isSandbox,
    //       start_date: datePayload.start_date,
    //       end_date: datePayload.end_date,
    //       user_id: user_id,
    //     };
    //     const compareDateBody = {
    //       test_mode: isSandbox,
    //       start_date: compareDatePayload.start_date,
    //       end_date: compareDatePayload.end_date,
    //       user_id: user_id,
    //     };
    //     const [response, compareDateResponse] = await Promise.all([
    //       getProvidersUsedData(dateBody),
    //       getProvidersUsedData(compareDateBody),
    //     ]);
    //     const currentPayload = { ...response.data, ...datePayload };
    //     const previousPayload = {
    //       ...compareDateResponse.data,
    //       ...compareDatePayload,
    //     };
    //     const providerList = currentPayload.providers
    //       ? currentPayload.providers.map((el) => {
    //           const newData = {
    //             provider_name: el.service,
    //             provider_deliveries: el.value,
    //           };
    //           return newData;
    //         })
    //       : [];

    //     const percentValues = getValueComparesion(
    //       currentPayload.total,
    //       previousPayload.total
    //     );
    //     const ProvidersUsedData = {
    //       ...percentValues,
    //       data: providerList,
    //       current_month: moment(currentPayload.start_date).format("MMM DD"),
    //       previous_month: moment(currentPayload.end_date).format("MMM DD"),
    //       current_month_value: numberWithCommas(currentPayload.total),
    //       previous_month_value: numberWithCommas(previousPayload.total),
    //     };

    //     setProviderUsed(ProvidersUsedData);
    //     return ProvidersUsedData;
    //   } catch (err) {
    //     throw err;
    //   }
    // };

    // const setOverViewChart = async (
    //   isSandbox,
    //   datePayload,
    //   compareDatePayload,
    //   user_id
    // ) => {
    //   try {
    //     setOverViewIsLoading(true);
    //     const groupBy = groupByOption(datePayload, compareDatePayload);
    //     await Promise.all([
    //       getDeliveryVolume(
    //         isSandbox,
    //         datePayload,
    //         compareDatePayload,
    //         user_id,
    //         groupBy
    //       ),
    //       getOrderVolume(
    //         isSandbox,
    //         datePayload,
    //         compareDatePayload,
    //         user_id,
    //         groupBy
    //       ),
    //       getDeliveryValue(
    //         isSandbox,
    //         datePayload,
    //         compareDatePayload,
    //         user_id,
    //         groupBy
    //       ),
    //       getOrderValue(
    //         isSandbox,
    //         datePayload,
    //         compareDatePayload,
    //         user_id,
    //         groupBy
    //       ),
    //       // getProviderUsed(isSandbox, datePayload, compareDatePayload, user_id),
    //     ]);
    //     setOverViewIsLoading(false);
    //   } catch (err) {
    //     console.log(err);
    //     let message = err.message;
    //     if (err.response.data?.message) {
    //       message = err.response.data.message;
    //     }
    //     setOverViewIsLoading(false);
    //     // toast.error(message, {
    //     //   position: "top-center",
    //     //   autoClose: 2500,
    //     //   hideProgressBar: false,
    //     //   closeOnClick: true,
    //     //   pauseOnHover: true,
    //     //   draggable: true,
    //     //   closeButton: false,
    //     //   // progress: undefined,
    //     // });
    //   }
    // };

    /* -------------------------- Report APIs ---------------------------------*/

    // const [topDeliveryProviders, setTopDeliveryProviders] =
    //   useState(initPayload);
    // const [averageDeliveryCost, setAverageDeliveryCost] = useState(initPayload);
    // const [averageOrderValue, setAverageOrderValue] = useState(initPayload);
    // const [topDeliveryTiming, setTopDeliveryTiming] = useState(initPayload);
    // const [reportIsLoading, setReportIsLoading] = useState(false);
    // const [selectStatus, setSelectStatus] = useState("delivered");

    // const getTopDeliveryProviders = async (isSandbox, datePayload, user_id) => {
    //   try {
    //     const dateBody = {
    //       test_mode: isSandbox,
    //       start_date: datePayload.start_date,
    //       end_date: datePayload.end_date,
    //       user_id: user_id,
    //     };
    //     const response = await getProvidersUsedData(dateBody);
    //     const providerList = response.data;

    //     const finaldata = {
    //       data: [],
    //     };

    //     if (providerList.providers.length) {
    //       finaldata.data = providerList.providers.map((el) => {
    //         const newData = {
    //           provider_name: el.service,
    //           provider_deliveries: el.value,
    //         };
    //         return newData;
    //       });
    //     }

    //     setTopDeliveryProviders(finaldata);
    //     return finaldata;
    //   } catch (err) {
    //     console.warn(err);
    //     throw err;
    //   }
    // };

    // const getAverageDeliveryCost = async (
    //   isSandbox,
    //   datePayload,
    //   compareDatePayload,
    //   user_id,
    //   groupBy
    // ) => {
    //   try {
    //     const dateBody = {
    //       test_mode: isSandbox,
    //       start_date: datePayload.start_date,
    //       end_date: datePayload.end_date,
    //       group_by: groupBy,
    //       user_id,
    //     };
    //     const compareDateBody = {
    //       test_mode: isSandbox,
    //       start_date: compareDatePayload.start_date,
    //       end_date: compareDatePayload.end_date,
    //       group_by: groupBy,
    //       user_id,
    //     };
    //     const [response, compareDateResponse] = await Promise.all([
    //       getDeliveryCostAverageData(dateBody),
    //       getDeliveryCostAverageData(compareDateBody),
    //     ]);

    //     const finaldata = combineData(
    //       { total: 0, ...response.data, ...datePayload },
    //       { total: 0, ...compareDateResponse.data, ...compareDatePayload },
    //       "data",
    //       true,
    //       "value",
    //       groupBy
    //     );

    //     setAverageDeliveryCost(finaldata);
    //     return finaldata;
    //   } catch (err) {
    //     console.warn(err);
    //     throw err;
    //   }
    // };

    // const getAverageOrderValue = async (
    //   isSandbox,
    //   datePayload,
    //   compareDatePayload,
    //   user_id,
    //   groupBy
    // ) => {
    //   try {
    //     const dateBody = {
    //       test_mode: isSandbox,
    //       start_date: datePayload.start_date,
    //       end_date: datePayload.end_date,
    //       group_by: groupBy,
    //       user_id,
    //     };
    //     const compareDateBody = {
    //       test_mode: isSandbox,
    //       start_date: compareDatePayload.start_date,
    //       end_date: compareDatePayload.end_date,
    //       group_by: groupBy,
    //       user_id,
    //     };
    //     const [response, compareDateResponse] = await Promise.all([
    //       getOrderValueAverageData(dateBody),
    //       getOrderValueAverageData(compareDateBody),
    //     ]);

    //     const finaldata = combineData(
    //       { total: 0, ...response.data, ...datePayload },
    //       { total: 0, ...compareDateResponse.data, ...compareDatePayload },
    //       "data",
    //       true,
    //       "value",
    //       groupBy
    //     );

    //     setAverageOrderValue(finaldata);
    //     return finaldata;
    //   } catch (err) {
    //     console.warn(err);
    //     throw err;
    //   }
    // };

    // const setDeliveryReportChart = async (
    //   isSandbox,
    //   datePayload,
    //   compareDatePayload,
    //   user_id
    // ) => {
    //   try {
    //     setReportIsLoading(true);
    //     const groupBy = groupByOption(datePayload, compareDatePayload);
    //     if (!groupBy) {
    //       setReportIsLoading(false);
    //       return;
    //     }
    //     await Promise.all([
    //       getReport(
    //         isSandbox,
    //         datePayload,
    //         compareDatePayload,
    //         user_id,
    //         groupBy
    //       ),
    //       getAverageDeliveryTime(
    //         isSandbox,
    //         datePayload,
    //         compareDatePayload,
    //         user_id,
    //         groupBy
    //       ),
    //       getAverageDeliveryCost(
    //         isSandbox,
    //         datePayload,
    //         compareDatePayload,
    //         user_id,
    //         groupBy
    //       ),
    //       getAverageOrderValue(
    //         isSandbox,
    //         datePayload,
    //         compareDatePayload,
    //         user_id,
    //         groupBy
    //       ),
    //       getTopDeliveryProviders(isSandbox, datePayload, user_id),
    //       getDeliveryCities(isSandbox, datePayload, user_id),
    //       getDeliveryStores(isSandbox, datePayload, user_id),
    //       // getDeliveryTopTiming(isSandbox, datePayload, user_id),
    //     ]);

    //     setReportIsLoading(false);
    //   } catch (err) {
    //     console.log(err);
    //     let message = err.message;
    //     if (err.response.data?.message) {
    //       message = err.response.data.message;
    //     }

    //     setReportIsLoading(false);

    //     // toast.error(message, {
    //     //   position: "top-center",
    //     //   autoClose: 2500,
    //     //   hideProgressBar: false,
    //     //   closeOnClick: true,
    //     //   pauseOnHover: true,
    //     //   draggable: true,
    //     //   closeButton: false,
    //     //   // progress: undefined,
    //     // });
    //   }
    // };

    /* -------------------------- Provider Performance APIs ---------------------------------*/
    // const initProviderAverage = {
    //   deliveryTimeAverage: 0,
    //   pickupTimeAverage: 0,
    //   pickupDelayTimeAverage: 0,
    //   dropoffTimeAverage: 0,
    //   dropoffDelayTimeAverage: 0,
    // };
    // const intProviderProformance = {
    //   totalDeliveriesSent: 0,
    //   deliveryAmount: 0,
    //   orderAmount: 0,
    //   totalCanceled: 0,
    //   averageOrderAmountperDelivery: 0,
    // };
    // const [providerAverage, setProviderAverage] = useState(initProviderAverage);
    // const [providerProformanceData, setProviderProformanceData] = useState(
    //   intProviderProformance
    // );
    // const [totalDeliveriesSent, setTotalDeliveriesSent] = useState(0);
    // const [deliveryAmount, setDeliveryAmount] = useState(0);
    // const [orderAmount, setOrderAmount] = useState(0);
    // const [totalCanceled, setTotalCanceled] = useState(0);
    // const [averageOrderAmountperDelivery, setAverageOrderAmountperDelivery] =
    //   useState(0);
    // const [providerPerformaceIsLoading, setProviderPerformaceIsLoading] =
    //   useState(false);

    // const getTotalDeliveriesSent = async (isSandbox, datePayload, user_id) => {
    //   try {
    //     const dateBody = {
    //       test_mode: isSandbox,
    //       start_date: datePayload.start_date,
    //       end_date: datePayload.end_date,
    //       user_id: user_id,
    //     };
    //     const response = await getProviderDeliveriesSentData(dateBody);
    //     const { data } = response.data;
    //     let totalDeliveriesSent = 0;

    //     if (data && data.length) {
    //       totalDeliveriesSent = data.reduce(
    //         (a, v) => a + parseFloat(v.count),
    //         0
    //       );
    //       totalDeliveriesSent = Number(totalDeliveriesSent.toFixed(2));
    //     }
    //     setTotalDeliveriesSent(numberWithCommas(totalDeliveriesSent));
    //     return totalDeliveriesSent;
    //   } catch (err) {
    //     console.warn(err);
    //     throw err;
    //   }
    // };
    // const getDeliveryAmount = async (isSandbox, datePayload, user_id) => {
    //   try {
    //     const dateBody = {
    //       test_mode: isSandbox,
    //       start_date: datePayload.start_date,
    //       end_date: datePayload.end_date,
    //       user_id: user_id,
    //     };
    //     const response = await getProviderDeliveryAmountData(dateBody);
    //     const { data } = response.data;

    //     let deliveryAmount = 0;

    //     if (data && data.length) {
    //       deliveryAmount = data.reduce((a, v) => a + parseFloat(v.value), 0);
    //       deliveryAmount = Number(deliveryAmount.toFixed(2));
    //     }

    //     deliveryAmount =
    //       "$" + numberWithCommas(Number((deliveryAmount / 100).toFixed(2)));

    //     setDeliveryAmount(deliveryAmount);
    //     return deliveryAmount;
    //   } catch (err) {
    //     console.warn(err);
    //     throw err;
    //   }
    // };
    // const getOrderAmount = async (isSandbox, datePayload, user_id) => {
    //   try {
    //     const dateBody = {
    //       test_mode: isSandbox,
    //       start_date: datePayload.start_date,
    //       end_date: datePayload.end_date,
    //       user_id: user_id,
    //     };
    //     const response = await getProviderOrderValueData(dateBody);
    //     const { data } = response.data;

    //     let orderAmount = 0;

    //     if (data && data.length) {
    //       orderAmount = data.reduce((a, v) => a + parseFloat(v.value), 0);
    //       orderAmount = Number(orderAmount.toFixed(2));
    //     }

    //     orderAmount =
    //       "$" + numberWithCommas(Number((orderAmount / 100).toFixed(2)));
    //     setOrderAmount(orderAmount);
    //     return orderAmount;
    //   } catch (err) {
    //     console.warn(err);
    //     throw err;
    //   }
    // };
    // const getTotalCanceled = async (isSandbox, datePayload, user_id) => {
    //   try {
    //     const dateBody = {
    //       test_mode: isSandbox,
    //       start_date: datePayload.start_date,
    //       end_date: datePayload.end_date,
    //       user_id: user_id,
    //     };
    //     const response = await getProviderCanceledData(dateBody);
    //     const { data } = response.data;

    //     let totalCanceled = 0;

    //     if (data && data.length) {
    //       totalCanceled = data.reduce((a, v) => a + parseFloat(v.count), 0);
    //       totalCanceled = Number(totalCanceled.toFixed(2));
    //     }
    //     setTotalCanceled(numberWithCommas(totalCanceled));
    //     return totalCanceled;
    //   } catch (err) {
    //     console.warn(err);
    //     throw err;
    //   }
    // };

    // const getAverageOrderAmountperDelivery = async (
    //   isSandbox,
    //   datePayload,
    //   user_id
    // ) => {
    //   try {
    //     const dateBody = {
    //       test_mode: isSandbox,
    //       start_date: datePayload.start_date,
    //       end_date: datePayload.end_date,
    //       user_id: user_id,
    //     };
    //     const response = await getAverageOrderAmountDeliveryData(dateBody);
    //     const { data } = response;
    //     let averageOrderAmountperDelivery = 0;
    //     if (data && data.length) {
    //       averageOrderAmountperDelivery = data.reduce(
    //         (a, v) => a + parseFloat(v.average),
    //         0
    //       );
    //       averageOrderAmountperDelivery = Number(
    //         (averageOrderAmountperDelivery / data.length).toFixed(2)
    //       );
    //     }
    //     averageOrderAmountperDelivery =
    //       "$" +
    //       numberWithCommas(
    //         Number((averageOrderAmountperDelivery / 100).toFixed(2))
    //       );
    //     setAverageOrderAmountperDelivery(averageOrderAmountperDelivery);
    //     return averageOrderAmountperDelivery;
    //   } catch (err) {
    //     console.warn(err);
    //     throw err;
    //   }
    // };

    // const getProviderAverageTime = async (isSandbox, datePayload, user_id) => {
    //   try {
    //     const dateBody = {
    //       test_mode: isSandbox,
    //       start_date: datePayload.start_date,
    //       end_date: datePayload.end_date,
    //       user_id: user_id,
    //     };
    //     const response = await getProviderDeliveryTimeAverageData(dateBody);
    //     const providerList = response.data;
    //     const keys = Object.keys(providerList);

    //     let obj = { ...providerAverage };
    //     if (keys && keys.length) {
    //       keys.forEach((key) => {
    //         if (providerList[key])
    //           obj[key] = numberWithCommas(providerList[key]);
    //       });
    //       setProviderAverage(obj);
    //     }

    //     return obj;
    //   } catch (err) {
    //     console.warn(err);
    //     throw err;
    //   }
    // };

    // const setProviderProformanceChart = async (
    //   isSandbox,
    //   datePayload,
    //   user_id
    // ) => {
    //   try {
    //     setProviderPerformaceIsLoading(true);
    //     const prodiverResponse = await Promise.all([
    //       getTotalDeliveriesSent(isSandbox, datePayload, user_id),
    //       getDeliveryAmount(isSandbox, datePayload, user_id),
    //       getOrderAmount(isSandbox, datePayload, user_id),
    //       getTotalCanceled(isSandbox, datePayload, user_id),
    //       getAverageOrderAmountperDelivery(isSandbox, datePayload, user_id),
    //       getProviderAverageTime(isSandbox, datePayload, user_id),
    //     ]);

    //     setProviderPerformaceIsLoading(false);
    //   } catch (err) {
    //     console.log(err);
    //     let message = err.message;
    //     if (err.response.data?.message) {
    //       message = err.response.data.message;
    //     }

    //     setProviderPerformaceIsLoading(false);

    //     // toast.error(message, {
    //     //   position: "top-center",
    //     //   autoClose: 2500,
    //     //   hideProgressBar: false,
    //     //   closeOnClick: true,
    //     //   pauseOnHover: true,
    //     //   draggable: true,
    //     //   closeButton: false,
    //     //   // progress: undefined,
    //     // });
    //   }
    // };

    return {
      //   setOverViewChart,
      //   deliveryVolume,
      //   orderVolume,
      //   deliveryValue,
      //   orderValue,
      //   providerUsed,
      //   overViewIsLoading,
      //   // Report Section
      //   setDeliveryReportChart,
      //   report,
      //   topDeliveryProviders,
      //   topStoreDeliveries,
      //   topCityDeliveries,
      //   averageDeliveryCost,
      //   averageOrderValue,
      //   averageDeliveryTime,
      //   topDeliveryTiming,
      //   reportIsLoading,
      //   getReport,
      //   getDeliveryTopTiming,
      //   selectStatus,
      //   setSelectStatus,
      //   // Provider Proformance
      //   setProviderProformanceChart,
      //   providerAverage,
      //   totalDeliveriesSent,
      //   deliveryAmount,
      //   orderAmount,
      //   totalCanceled,
      //   averageOrderAmountperDelivery,
      //   providerPerformaceIsLoading,

      //Date states
      datePayload,
      setDatePayload,
      compareDatePayload,
      setCompareDatePayload,

      //Insights charts data
      deliveryVolume,
      getDeliveryVolume,
      deliveryValue,
      getDeliveryValue,
      orderValue,
      // getOrderValue,
      averageDeliveryTime,
      getAverageDeliveryTime,
      averageMileage,
      getAverageMileage,
      deliverySuccessRate,
      getDeliverySuccessRate,
      deliveryFailureRate,
      // getDeliveryFailureRate,
      deliveryReturns,
      getDeliveryReturns,
      averagePickupTime,
      getAveragePickupTime,
      averagePickupDelays,
      getAveragePickupDelays,
      averageDropoffTime,
      getAverageDropoffTime,
      averageDropoffDelays,
      getAverageDropoffDelays,

      //Overview chart data
      overviewPeriod,
      setOverviewPeriod,
      selectStatus,
      setSelectStatus,
      overviewKPIs,
      overviewDeliveryReport,
      getOverviewDeliveryReport,

      //Top Timing Section
      topTimingDataGroupBy,
      setTopTimingDataGroupBy,
      topDeliveryTiming,
      getDeliveryTopTiming,

      //Table data
      topPickupLocations,
      getTopPickupLocations,
      topDeliveryProviders,
      getTopDeliveryProviders,
      topStores,
      // getProviderTopStores,

      //Active Users Section
      topCityDeliveries,
      getDeliveryCities,

      //filter providers
      selectedProviderArray,
      setSelectedProviderArray,
      isDateSelected,
      setIsDateSelected,
    };
  },
});

export default useAnalyticsStore;
