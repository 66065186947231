import classNames from "classnames";
import React, { useEffect, useState } from "react";

import styles from "./styles.module.css";
import { useTheme } from "../../../../store";
import { useDesktopOrLaptop } from "../../../../services/useDesktopOrLaptop";

import curri from "../../../../assets/png/providers/curri.png";
import doneFeedback from "../../../../assets/png/ratings/doneFeedback.png";
import Button from "../../../../components/shared/atoms/Button";
import useRatings from "../../../../store/useRatings";
import { BsFillCheckCircleFill } from "react-icons/bs";



const ModalContent = ({
  setIsModalVisible,
  ratingStatuses,
  delId,
  // getDeliveryInfo,
  itemInfo,
  setSearchParams,
  delInfoHandler,
}) => {
  const { Colors, themeChanger } = useTheme();
  const { giveFeedbackHandler } = useRatings();
  const { xlfontsize, xxlfontsize, isdesktop, lgfontsize } =
    useDesktopOrLaptop();
  const [steps, setSteps] = useState(1);

  const [feedbackText, setFeedbackText] = useState();
  const [selectedRating, setSelectedRating] = useState();
  const [endTedt, setEndTedt] = useState(
    itemInfo?.rating?.rating_score && {
      title: "Thank you!",
      body: "Your feedback has already been submitted.",
    }
  );

  useEffect(() => {
    if (itemInfo?.rating?.rating_score) {
      setSteps(2);
    }
    return () => {
      setSelectedRating();
      setSteps(1);
      setFeedbackText();
    };
  }, []);

  return (
    <>
      <div className=" flex justify-center">
        {itemInfo?.rating ? (
          <div
            className={classNames({
              [styles.logoContainer]: !isdesktop,
              [styles.logoContainerDesktop]: isdesktop,
            })}
          >
            <BsFillCheckCircleFill
              color={Colors.bluePrimary}
              className="h-full w-full"
            />
          </div>
        ) : (
          itemInfo?.provider_logo_url && (
            <div
              className="hidden"
              // className={classNames({
              //   [styles.logoContainer]: !isdesktop,
              //   [styles.logoContainerDesktop]: isdesktop,
              // })}
            >
              <img
                className={`h-full w-full  shadow-md ${
                  itemInfo?.service === "uber"
                    ? "object-contain"
                    : "object-cover"
                }`}
                src={itemInfo.provider_logo_url}
                alt=""
              />
            </div>
          )
        )}
      </div>
      <div
        className={classNames(
          "w-full h-full flex flex-col items-center relative justify-center",
          {
            [styles.contentCont]: !isdesktop,
            [styles.contentContDesktop]: isdesktop,
          }
        )}
      >
        <div className="absolute top-6 right-7">
          <button
            onClick={() => {
              setIsModalVisible(false);
              setSearchParams({ feedback: false });
            }}
            type="button"
            style={{
              color: Colors?.fontGreyLight,
              fontSize: isdesktop ? "26px" : "56px",
            }}
            className="material-icons outline-none p-2 rounded-full shadow-xl"
          >
            close
          </button>
        </div>
        {steps === 1 ? (
          <>
            <div
              style={{ fontSize: xlfontsize, fontWeight: "500" }}
              className={classNames(
                "my-5 mt-10 capitalize",
                styles.providerName
              )}
            >
              {/* {itemInfo?.service} */}Feedback
            </div>
            <div
              style={{ fontSize: xxlfontsize, fontWeight: "500" }}
              className={classNames("mt-2", styles.providerName)}
            >
              How was your delivery?
            </div>
            <div className="w-full flex items-center justify-between px-8 mt-8">
              {ratingStatuses.map((item, index) => {
                const length = ratingStatuses.length;
                return (
                  <div
                    key={item.id}
                    className={classNames("flex flex-col items-center px-3", {
                      "pr-0": index === length - 1,
                      "pl-0": index === 0,
                    })}
                  >
                    <img
                      onClick={() => {
                        setSelectedRating(item);
                      }}
                      style={{
                        borderWidth:
                          selectedRating?.id === item.id ? "3px" : "0px",
                        borderColor: item.color,
                      }}
                      className={classNames({
                        [styles.ratingEmojies]: !isdesktop,
                        [styles.ratingEmojiesDesktop]: isdesktop,
                      })}
                      src={item.img}
                      alt=""
                    />
                    <div
                      style={{ fontSize: lgfontsize }}
                      className={classNames("mt-8", styles.providerName)}
                    >
                      {item.name}
                    </div>
                  </div>
                );
              })}
            </div>
            {selectedRating && (
              <>
                <div
                  className={classNames(
                    "text-center px-16 w-auto mt-12",
                    styles.providerName
                  )}
                >
                  {/* Please tell us what you like or dislike. <br /> In case of
                  technical issues please elaborate.
                  <br /> This will help us improve! */}
                </div>
                <div style={{ width: "inherit" }} className=" px-10 mt-0">
                  <textarea
                    style={{ width: "inherit" }}
                    maxLength={"500"}
                    className={classNames(
                      `${
                        themeChanger
                          ? "focus:border-gray-400"
                          : "focus:border-gray-300"
                      }  border border-transparent w-i`,
                      styles.textInput
                    )}
                    onChange={(e) => {
                      setFeedbackText(e.target.value);
                    }}
                    value={feedbackText}
                    rows="5"
                    placeholder={"Tell us about your experience... "}
                  ></textarea>
                </div>
              </>
            )}

            {selectedRating && (
              <Button
                text="Submit"
                style={{ fontSize: xlfontsize }}
                externalClassName={styles.submitButton}
                disabled={!selectedRating}
                onClick={async () => {
                  let payload = {
                    rating_score: selectedRating.value,
                    comment: feedbackText,
                  };
                  await giveFeedbackHandler(delId, payload);
                  await delInfoHandler();
                  setEndTedt({
                    title: <div>🌟 Thanks for your feedback!</div>,
                    body: "",
                  });
                  setSteps((prev) => prev + 1);
                }}
              />
            )}
          </>
        ) : (
          <div
            style={{
              fontSize: xlfontsize,
              fontWeight: "500",
              color: Colors.fontDark,
              maxWidth: "500px",
            }}
            className={classNames("my-5 px-10 text-center pb-0 pt-12")}
          >
            {endTedt ? (
              <div>
                <div>{endTedt.title}</div>
                <div
                  style={{ fontSize: lgfontsize, color: Colors.fontGreyLight }}
                  className="mt-10 font-normal px-20"
                >
                  {endTedt.body}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default ModalContent;
