import React, { useEffect, useState } from "react";

import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";
import { useMerchant, useMyStore, useQuoteWorkflow, userDataStore, useStoreAndChild, useTheme } from "../../../store";

import QuoteItems from "./QuoteItems";
import QuoteTempArea from "./QuoteTempArea";
import RightSidebar from "./RightSidebar";
import WorkflowSidebarOpener from "../HelperComponents/WorkflowSidebarOpener";
import OrderWorkflowRightSidebar from "../OrderWorkflow/OrderWorkflowRightSidebar";
import RerouteWorkflow from "../RerouteWorkflow";

function QuoteWorkflow({ isInsideStore }) {
  const [isLeftOpened, setIsLeftOpened] = useState(true);
  const { Colors } = useTheme();
  const { mdfontsize, isdesktop } = useDesktopOrLaptop();
  const { userId } = useMyStore();
  const { loginAuth } = userDataStore();
  const { selectedStore } = useStoreAndChild();

  const { getMerchantData } = useMerchant();
  const { selectedQuoteItem, setSelectedQuoteItem } = useQuoteWorkflow();

  const userDataHandler = async () => {
    const authData = await loginAuth();
    getMerchantData(authData.data.user.merchantAccountId);
  };

  const dataHandler = async () => {
    if (userId) {
      // await getQuoteWorkflowHandler("/" + userId);
    } else {
      await userDataHandler();
    }
  };

  useEffect(() => {
    dataHandler();
  }, [selectedStore]);

  const showRightSidebar = (key) => {
    switch (key) {
      case "Provider Preference":
        return true;

      default:
        return false;
    }
  };

  return (
    <div
      style={{
        backgroundImage: `radial-gradient(${Colors.bgDots} 20%, ${Colors.bgWorkflow} 14%)`,
        backgroundPosition: "0px 0px",
        backgroundSize: "20px 20px",
        height: isdesktop
          ? isInsideStore
            ? `calc(100vh - 210px)`
            : `calc(100vh - 70px)`
          : isInsideStore
          ? `calc(100vh - 380px)`
          : `calc(100vh - 200px)`,
        overflowY: "auto",
        display: "flex",
        flexDirection: "row",
        width: "100%",
      }}
    >
      <WorkflowSidebarOpener isInsideStore={isInsideStore} isLeftOpened={isLeftOpened} setIsLeftOpened={setIsLeftOpened} />

      <div
        className="kata"
        style={{
          width: "350px",
          transform: isdesktop
            ? isLeftOpened
              ? `translate(0px)`
              : `translate(-350px)`
            : isLeftOpened
            ? `translate(0px)`
            : `translate(-334px)`,
          borderRightWidth: "1px",
          borderColor: Colors.borderSecondary,
          backgroundColor: Colors.white,
          padding: "20px",
          position: "absolute",
          top: isdesktop ? (isInsideStore ? "211px" : "70px") : isInsideStore ? "377px" : "200px",
          left: "0px",
          bottom: "0px",
          zIndex: 20,
        }}
      >
        <div
          style={{
            fontFamily: "Poppins",
            fontWeight: "500",
            fontSize: mdfontsize,
            color: Colors.fontDark,
          }}
        >
          Quote Workflow
        </div>
        <QuoteItems />
      </div>
      <div
        className="kata"
        style={{
          position: "absolute",
          left: "0px",
          top: isdesktop ? (isInsideStore ? "210px" : "70px") : isInsideStore ? "380px" : "200px",
          bottom: "0px",
          right: "0px",
          display: "flex",
          marginLeft: isdesktop ? "350px" : "30px",
          alignItems: "center",
        }}
      >
        <QuoteTempArea />
      </div>
      {showRightSidebar(selectedQuoteItem) ? (
        <OrderWorkflowRightSidebar isInsideStore={isInsideStore} setIsLeftOpened={setIsLeftOpened} />
      ) : selectedQuoteItem === "Reroute" ? (
        <RerouteWorkflow />
      ) : (
        <RightSidebar isInsideStore={isInsideStore} setIsLeftOpened={setIsLeftOpened} />
      )}
    </div>
  );
}

export default QuoteWorkflow;
