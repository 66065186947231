import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { ReasonForIncident } from "./ReasonForIncident";

import classNames from "classnames";
import Button from "../../../../shared/atoms/Button";
import useMerchant from "../../../../../store/useMerchant";
import { useFilter, useMerchantIncidents, useMyStore, useStoreAndChild, userDataStore } from "../../../../../store";
import UploadIncidentProofs from "./UploadIncidentProofs";
import { handleUpload } from "./utils";
import { Loading } from "../../../../../assets/svgs/Svgs";
import { useFlag } from "@unleash/proxy-client-react";

const RequestIncident = ({ delInfo, setIsIncidentVisible, insideSidebarFunc }) => {
  const { authInfo } = userDataStore();
  const { merchantData } = useMerchant();
  const { selectedStore } = useStoreAndChild();
  const { getPresignedUrl, uploadToS3, getIncidentReasonsHandler, createMerchantIncidentHandler } = useMerchantIncidents();
  const { filterStore, startDate, search, filterArray } = useFilter();
  const { deliveries, pageCount } = useMyStore();

  const [images, setImages] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [reasons, setReasons] = useState();
  const [reasonActive, setReasonActive] = useState("order_canceled");
  const [incidentNote, setIncidentNote] = useState();

  const isPOIDisabled = useFlag("disablePOIonHomePage");

  const firstLoader = async () => {
    const data = await getIncidentReasonsHandler();
    if (data) {
      setReasons(data);
      setReasonActive(data.data[0].id);
    }
  };

  const submitHandler = async () => {
    setIsLoading(true);
    const s3names = await handleUpload(images, getPresignedUrl, authInfo?.merchantAccountId, uploadToS3);

    const payload = {
      // delivery_id: delInfo?.id,
      delivery_incident_reason: reasonActive ?? undefined,
      external_note: incidentNote ?? "",
      proof_of_incident: s3names,
    };

    const data = await createMerchantIncidentHandler(delInfo?.id, payload);

    if (data) {
      deliveries(
        search,
        pageCount,
        startDate,
        selectedStore ? selectedStore.id : filterStore?.id ? filterStore?.id : "",
        filterArray
      );
      setIsIncidentVisible(false);
      if (insideSidebarFunc) {
        insideSidebarFunc();
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    firstLoader();
    return () => {
      setReasonActive();
      setIncidentNote();
      setIsLoading(false);
    };
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.header}> Request Refund for Order</div>
      <div className={styles.id}>{delInfo?.id}</div>

      <div className={styles.id}>Select the reason for requesting a refund:</div>
      {reasons?.data?.map((reason) => {
        return (
          <ReasonForIncident reasonActive={reasonActive} setReasonActive={setReasonActive} key={reason.id} reason={reason} />
        );
      })}
      <div className={styles.textAreaCont}>
        <textarea
          className={styles.textArea}
          id="incidentNote"
          placeholder="Describe what happened in as much detail as you can."
          rows={4} // You can adjust the number of rows as needed
          value={incidentNote}
          onChange={(e) => {
            setIncidentNote(e.target.value);
          }}
        />
      </div>
      <UploadIncidentProofs setErrorMessage={setErrorMessage} errorMessage={errorMessage} images={images} setImages={setImages} />

      <div className="w-full flex items-center justify-end mt-3">
        <Button
          text="Cancel"
          variant="secondary"
          externalClassName={classNames("mr-5", "secondaryButton")}
          onClick={() => {
            setIsIncidentVisible(false);
          }}
        />
        <Button
          externalClassName={"primaryButton"}
          // text={"Submit"}
          text={
            isLoading ? (
              <div className="w-full flex justify-center">
                <Loading color="#ECF0F1" className="h-6 w-6 animate-spin" />{" "}
              </div>
            ) : (
              "Submit"
            )
          }
          onClick={() => {
            if (!isPOIDisabled) {
              if (images && images?.length > 0) {
                submitHandler();
                if (errorMessage) {
                  setErrorMessage(null);
                }
              } else {
                setErrorMessage("Please provide the proof of Incident!");
              }
            } else {
              submitHandler();
            }
          }}
        />
      </div>
    </div>
  );
};
export default RequestIncident;
