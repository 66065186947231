import React from "react";
import classNames from "classnames";

import styles from "./styles.module.css";

const CancellationReason = ({ item }) => {
  return (
    <div className="w-full flex flex-col justify-start items-start mb-1">
      <div className={styles.title}>Cancellation Reason</div>
      <div className={classNames("kata", styles.reasonCont)}>
        {item.cancellation_reason}
      </div>
    </div>
  );
};

export default CancellationReason;
