import React, { useEffect } from "react";
import { Loading } from "../../../assets/svgs/Svgs";
import {
  useMyStore,
  useClickStore,
  useTheme,
  useFilter,
  useAdmin,
} from "../../../store";
import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";
import DeliveriesListForAdmin from "./DeliveriesListForAdmin";
import ListHeaderAdmin from "./ListHeaderAdmin";
import Pagination from "../../shared/Pagination";
import useSocket from "../../../customHooks/useSocket";

function DeliveriesForAdmin() {
  const { isdesktop, smfontsize } = useDesktopOrLaptop();

  const { Colors } = useTheme();
  const { isSandbox } = useMyStore();

  const { startDate, setStartDate } = useClickStore();
  const { getAdminDeliveries, adminDelsListData, isAdminDelsLoading } =
    useAdmin();

  const { filterArray, selectedProvider, setSelectedProvider } = useFilter();

  const { message } = useSocket({
    channelName: `admin@deliveries`,
  });

  const {
    setSearchByDropoffAddress,
    setSearchByDropoffPhoneNum,
    setSearchByDroppofName,
    setSearchByPhoneNumber,
    setSearchByName,
    setSearchByAddress,
    setSearchById,
    searchByDropoffAddress,
    searchByDropoffPhoneNum,
    searchByDroppofName,
    searchByPhoneNumber,
    searchByName,
    searchByAddress,
    searchById,
    setIsAdminDelsLoading,
    searchByProviderId,
    setSearchByProviderId,
    searchByBurqOrderId,
    setSearchByBurqOrderId,
  } = useAdmin();

  useEffect(() => {
    if (searchByDropoffAddress) {
      setSearchByDropoffAddress();
    }
    if (searchByDropoffPhoneNum) {
      setSearchByDropoffPhoneNum();
    }
    if (searchByDroppofName) {
      setSearchByDroppofName();
    }
    if (searchByPhoneNumber) {
      setSearchByPhoneNumber();
    }
    if (searchByName) {
      setSearchByName();
    }
    if (searchByAddress) {
      setSearchByAddress();
    }
    if (searchById) {
      setSearchById();
    }
    if (selectedProvider) {
      setSelectedProvider("");
    }
    if (searchByProviderId) {
      setSearchByProviderId();
    }
    if (setStartDate) {
      setStartDate();
    }
    if (searchByBurqOrderId) {
      setSearchByBurqOrderId();
    }
    setTimeout(() => {
      setIsAdminDelsLoading(true);
      getAdminDeliveries({
        ending_before: "",
        starting_after: "",
        search_by_pickup_id: "",
        search_by_pickup_address: "",
        search_by_pickup_name: "",
        search_by_pickup_phone_number: "",
        search_by_dropoff_name: "",
        search_by_dropoff_phone_number: "",
        search_by_dropoff_address: "",
        test_mode: isSandbox,
        provider_id: "",
        statuses: "",
        start_date: "",
        end_date: "",
        short_id: "",
        search_by_burq_order_id: "",
      });
    }, 600);
    return () => {
      if (searchByDropoffAddress) {
        setSearchByDropoffAddress();
      }
      if (searchByDropoffPhoneNum) {
        setSearchByDropoffPhoneNum();
      }
      if (searchByDroppofName) {
        setSearchByDroppofName();
      }
      if (searchByPhoneNumber) {
        setSearchByPhoneNumber();
      }
      if (searchByName) {
        setSearchByName();
      }
      if (searchByAddress) {
        setSearchByAddress();
      }
      if (searchById) {
        setSearchById();
      }
      if (selectedProvider) {
        setSelectedProvider("");
      }
      if (setStartDate) {
        setStartDate();
      }
      if (searchByProviderId) {
        setSearchByProviderId();
      }
      if (searchByBurqOrderId) {
        setSearchByBurqOrderId();
      }
    };
  }, [isSandbox]);

  useEffect(() => {
    adminDeliveriesHandler();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProvider, filterArray, startDate]);

  const adminDeliveriesHandler = async (param) => {
    let params = {
      ending_before: param?.ending_before ?? "",
      starting_after: param?.starting_after ?? "",
      // search_by_pickup_id: param?.search_by_pickup_id ?? searchById,
      // search_by_pickup_address:
      //   param?.search_by_pickup_address ?? searchByAddress,
      // search_by_pickup_name: param?.search_by_pickup_name ?? searchByName,
      // search_by_pickup_phone_number:
      //   param?.search_by_pickup_phone_number ?? searchByPhoneNumber,
      // search_by_dropoff_name:
      //   param?.search_by_dropoff_name ?? searchByDroppofName,
      // search_by_dropoff_phone_number:
      //   param?.search_by_dropoff_phone_number ?? searchByDropoffPhoneNum,
      // search_by_dropoff_address:
      //   param?.search_by_dropoff_address ?? searchByDropoffAddress,
      test_mode: isSandbox,
      provider_id: param?.provider_id ?? selectedProvider?.id,
      statuses: param?.statuses ?? filterArray?.join(","),
      start_date: param?.startDate?.start ?? startDate?.start,
      end_date: param?.startDate?.end ?? startDate?.end,
      // short_id: searchByProviderId ?? "",
    };
    await getAdminDeliveries(params);
  };

  return (
    <div
      style={{
        borderRadius: "8px",
      }}
      className="w-full flex flex-col relative  shadow-md"
    >
      <div className="w-full ">
        <div
          style={{ backgroundColor: Colors.white }}
          className="w-full rounded-2xl p-4 "
        >
          <ListHeaderAdmin />
          {isdesktop ? (
            <div
              id="modal"
              style={{
                fontFamily: "Poppins",
                color: Colors.fontGreyLight,
                fontSize: smfontsize,
                backgroundColor: Colors.bgListHeader,
                borderRadius: "8px",
                width: "100%",
              }}
              className="grid grid-cols-12 gap-1 py-4 pl-4 mt-5"
            >
              <div className="col-start-1 col-span-1">MERCHANT</div>
              <div className="col-start-2 col-span-1 text-center">
                DATE & TIME
              </div>
              <div className="col-start-3 col-span-3 text-center">
                <div className="grid grid-cols-2">
                  <div className="col-start-1 col-span-1 text-center">PICKUP</div>
                  <div className="col-start-2 col-span-1 text-center">DROPOFF</div>
                </div>
              </div>
              <div className="col-start-6 col-span-1 text-center">AMOUNT</div>
              <div className="col-start-7 col-span-1 text-center">SERVICE</div>
              <div className="col-start-8 col-span-1 text-center">
                Reroute Status
              </div>
              <div className="col-start-9 col-span-1 text-center">
                TRANSIT DURATION (MINS)
              </div>
              <div className="col-start-10 col-span-2 text-center">STATUS</div>
            </div>
          ) : (
            <div
              id="modal"
              style={{
                fontFamily: "Poppins",
                color: Colors.fontGreyLight,
                fontSize: smfontsize,
                backgroundColor: Colors.bgListHeader,
                borderRadius: "8px",
              }}
              className="grid grid-cols-12 gap-1 w-full py-4 pl-4 mt-5"
            >
              <div className="col-start-1 col-span-2">MERCHANT</div>
              <div className="col-start-3 col-span-3 text-center">
                DATE & TIME
              </div>

              <div className="col-start-6 col-span-2 text-center">AMOUNT</div>
              <div className="col-start-8 col-span-2 text-center">SERVICE</div>
              <div className="col-start-10 col-span-2 text-center">STATUS</div>
            </div>
          )}
          {isAdminDelsLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                alignItems: "center",
                marginTop: "100px",
                marginBottom: "100px",
              }}
            >
              <Loading
                color={Colors.bluePrimary}
                className="h-14 w-14 animate-spin"
              />
            </div>
          ) : (
            adminDelsListData?.data?.length > 0 &&
            adminDelsListData?.data?.map((item, index) => {
              return (
                <DeliveriesListForAdmin
                  isAdmin
                  index={index}
                  key={item.id}
                  name={item.pickup_name}
                  date={item.createdAt}
                  pickup={item.pickup_address_street}
                  dropOff={item.dropoffs[0]?.dropoff_address_street}
                  status={item.status}
                  complete={item.complete}
                  delivery_id={item.delivery_id}
                  tracking_url={item.tracking_url}
                  item={item}
                  request_type={item.request_type}
                  requestId={item.id}
                  service={item.service}
                  currentTransitStatusDurationMins={
                    item.current_transit_status_duration_mins
                  }
                  socketMessage={message}
                />
              );
            })
          )}
        </div>
        <Pagination
          data={adminDelsListData}
          onGetPaginationData={adminDeliveriesHandler}
          dependencies={[
            searchByDropoffAddress,
            searchByDropoffPhoneNum,
            searchByDroppofName,
            searchByPhoneNumber,
            searchByName,
            searchByAddress,
            searchById,
            startDate,
            selectedProvider,
            searchByBurqOrderId,
          ]}
        />
      </div>
    </div>
  );
}

export default DeliveriesForAdmin;
