import React from "react";
import classnames from "classnames";
import styles from "./styles.module.css";

const Button = ({
  onClick,
  text = "",
  loading = false,
  disabled = false,
  narrow = false,
  variant = "primary", //'primary' | 'secondary' | 'disbaled;
  type = "button", //'button' | 'submit' | 'reset';
  children = null,
  externalClassName = "",
  ...props
}) => {
  const preventClick = disabled || loading;

  return (
    <button
      type={type}
      disabled={disabled}
      onClick={preventClick ? undefined : onClick}
      className={classnames(
        styles.btn,
        {
          [styles.primary]: variant === "primary",
          [styles.secondary]: variant === "secondary",
          [styles.disabled]: variant === "disabled",
          [styles.lightPrimary]: variant === "lightPrimary",
          [styles.alert]: variant === "alert",
        },
        externalClassName
      )}
      {...props}
    >
      {text || children}
    </button>
  );
};

export default Button;
