import React from "react";
import { useFormikContext } from "formik";
import { getFieldValueIcon } from "../utils";
import InputErrorComp from "./InputErrorComp";
import styles from "../../styles/PricingRules/CreateRule/resultForm.module.css";
import InputField from "../../../../shared/atoms/InputField";
import SelectField from "../../../../shared/atoms/SelectField";

const ResultForm = ({
  ruleExpressionFieldsList,
  ruleResultFieldsList,
  operationsList,
}) => {
  const { values, errors, touched, handleChange, setFieldValue } =
    useFormikContext();

  const customstyles = {
    selectControl: {
      width: "100%",
      border: `1px solid #E5E7EB`,
      borderRadius: "10px",
      boxShadow: "none",
    },
    selectInput: {
      fontFamily: "Poppins",
      fontSize: "14px",
      border: "0px",
      borderRadius: "8px",
      outlineWidth: "0px",
      fontWeight: "500",
      margin: "5px",
    },
  };

  const selectStyles = (isErrors = false) => {
    return {
      control: (styles) => ({
        ...styles,
        ...customstyles.selectControl,
        ...{
          border: isErrors ? `solid #ff3f3f` : `solid #E5E7EB`,
        },
        ":hover": {
          ...styles[":hover"],
          border: isErrors ? `solid #ff3f3f` : `solid #E5E7EB`,
        },
      }),
      input: (styles) => ({
        ...styles,
        ...customstyles.selectInput,
      }),
      options: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ":active": {
            ...styles[":active"],
            backgroundColor: !isDisabled
              ? isSelected
                ? "#0062FF"
                : "#CDCDCD"
              : undefined,
          },
          borderRadius: "10px",
        };
      },
    };
  };

  return (
    <div>
      <div className={styles.expressionTopContainer}>
        <SelectField
          selectedValue={ruleResultFieldsList.find(
            (item) => item.value === values.result.field
          )}
          onSelect={(payload) =>
            setFieldValue("result.field", payload?.value || null)
          }
          optionsList={ruleResultFieldsList}
          styleProps={selectStyles(
            touched?.result?.field && errors?.result?.field
          )}
          useExternalState={true}
        />
        {touched?.result?.field && (
          <InputErrorComp error={errors?.result?.field} />
        )}
      </div>
      <div style={{ margin: "10px 0" }}>Equals to</div>
      <div className={styles.expressionContainer}>
        <div className={styles.expressionLeftContainer}>
          <SelectField
            selectedValue={ruleExpressionFieldsList.find(
              (item) => item.value === values.result.value.variable
            )}
            onSelect={(payload) =>
              setFieldValue("result.value.variable", payload?.value || null)
            }
            optionsList={ruleExpressionFieldsList}
            styleProps={selectStyles(
              touched?.result?.value?.variable &&
                errors?.result?.value?.variable
            )}
            useExternalState={true}
          />
          {touched?.result?.value?.variable && (
            <InputErrorComp error={errors?.result?.value?.variable} />
          )}
        </div>
        <div className={styles.expressionOperatorContainer}>
          <SelectField
            selectedValue={operationsList.find(
              (item) => item.value === values.result.value.operation
            )}
            onSelect={(payload) =>
              setFieldValue("result.value.operation", payload?.value || null)
            }
            optionsList={operationsList}
            styleProps={selectStyles(
              touched?.result?.value?.operation &&
                errors?.result?.value?.operation
            )}
            isDisabled={!values.result.value.variable}
            resetSelection={!values.result.value.variable}
            useExternalState={true}
          />
          {touched?.result?.value?.operation && (
            <InputErrorComp error={errors?.result?.value?.operation} />
          )}
        </div>
        <div className={styles.expressionRightContainer}>
          <InputField
            name={`result.value.field_value`}
            onChange={handleChange}
            value={values.result.value.field_value}
            disabled={!values.result.field}
            leftIcon={getFieldValueIcon(
              ruleResultFieldsList,
              values?.result?.field
            )}
          />
          {touched?.result?.value?.field_value && (
            <InputErrorComp error={errors?.result?.value?.field_value} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ResultForm;
