import { createStore } from "aniuta";
import * as Sentry from "@sentry/react";
import { useState } from "react";
import { toast } from "react-toastify";
import {
  getPartnerUsers,
  getPartnerRules,
  createPartnerRules,
  updatePartnerRule,
  deletePartnerRule,
} from "../utils/api/partner";

const usePartnerStore = createStore({
  name: "usePartnerStore",
  Store: () => {
    const [partnerUsers, setPartnerUsers] = useState(null);
    const [isPartnerUsersLoading, setIsPartnerUsersLoading] = useState(null);
    const [isPartnerRulesLoading, setIsPartnerRulesLoading] = useState(null);
    const [isUpdatePartnerRulesLoading, setIsUpdatePartnerRulesLoading] =
      useState(null);
    const [isDeletePartnerRulesLoading, setIsDeletePartnerRulesLoading] =
      useState(null);

    const getPartnerUsersWorker = async () => {
      try {
        setIsPartnerUsersLoading(true);
        const { data } = await getPartnerUsers();
        setPartnerUsers(data);
        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsPartnerUsersLoading(false);
      }
    };

    const getPartnerRulesWorker = async (params, callback) => {
      try {
        setIsPartnerRulesLoading(true);
        const { data } = await getPartnerRules({
          limit: 20,
          starting_after: null,
          ending_before: null,
          ...params,
        });
        callback(null, data);
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
        callback(error, null);
      } finally {
        setIsPartnerRulesLoading(false);
      }
    };

    const createPartnerRulesWorker = async (payload, callback) => {
      try {
        await createPartnerRules(payload);
        toast.success("Pricing rule is created successfully.");
        callback(null);
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
        callback(error);
      }
    };

    const updatePartnerRulesWorker = async (id, payload, callback) => {
      try {
        setIsUpdatePartnerRulesLoading(true);
        await updatePartnerRule(id, payload);
        toast.success("Pricing rule is updated successfully.");
        callback(null);
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
        callback(error);
      } finally {
        setIsUpdatePartnerRulesLoading(false);
      }
    };

    const deletePartnerRulesWorker = async (payload, callback) => {
      try {
        setIsDeletePartnerRulesLoading(true);
        await deletePartnerRule(payload);
        toast.success("Pricing rule is deleted successfully.");
        callback(null);
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
        callback(error);
      } finally {
        setIsDeletePartnerRulesLoading(false);
      }
    };

    return {
      partnerUsers,
      isPartnerUsersLoading,
      getPartnerUsersWorker,
      getPartnerRulesWorker,
      createPartnerRulesWorker,
      updatePartnerRulesWorker,
      deletePartnerRulesWorker,
      isPartnerRulesLoading,
      isUpdatePartnerRulesLoading,
      isDeletePartnerRulesLoading,
    };
  },
});

export default usePartnerStore;
