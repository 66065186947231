import { geocodeByAddress } from "react-places-autocomplete";

export const ORDER_STATUSES = {
  SCHEDULED: "scheduled",
  DRIVER_ENROUTE: "driver_enroute",
  ARRIVED: "arrived",
  COMPLETED: "completed",
  SUBMITTED: "submitted",

  PICKUP_SCHEDULED: "pickup_scheduled",
  DRIVER_ENROUTE_FOR_PICKUP: "pickup_driver_enroute",
  ARRIVED_AT_PICKUP: "pickup_arrived",
  PICKUP_COMPLETED: "pickup_completed",
  DROPOFF_SCHEDULED: "dropoff_scheduled",
  DRIVER_ENROUTE_FOR_DROPOFF: "dropoff_driver_enroute",
  ARRIVED_AT_DROPOFF: "dropoff_arrived",
  DROPOFF_COMPLETED: "dropoff_completed",
};

export const mapStatus = {
  pickup_scheduled: "Pickup Scheduled",
  pickup_driver_enroute: "Driver Enroute For Pickup",
  pickup_arrived: "Driver Arrived At Pickup",
  pickup_completed: "Pick-up Completed",
  dropoff_scheduled: "Dropoff Scheduled",
  dropoff_driver_enroute: "Driver Enroute For Dropoff",
  dropoff_arrived: "Driver Arrived At Dropoff",
  dropoff_completed: "Dropoff Completed",
};

export const mapStyles = [
  {
    featureType: "administrative",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.province",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 65,
      },
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: "50",
      },
      {
        visibility: "off",
      },
    ],
  },
];

export const getPixelPositionOffset = () => ({
  x: -30,
  y: -30,
});

export const statusesFilterForOverlayView = [
  "delivered",
  "completed",
  "canceled",
  "returned",
  "Paid order",
];

export const calculateRoutes = async (itemInfo) => {
  const DirectionsService = new window.google.maps.DirectionsService();
  const _routesForMap = [];
  const data = await DirectionsService.route({
    origin: {
      query: itemInfo.pickup.address,
    },
    destination: {
      query: itemInfo.dropoff.address,
    },
    travelMode: window.google.maps.TravelMode.DRIVING,
  });
  _routesForMap.push(data);
  return _routesForMap;
};

export const calculateDriverRoute = async (itemInfo) => {
  const currentStatus = currentOrderStatus(itemInfo);
  const { driver, dropoff, pickup } = itemInfo;
  const DirectionsService = new window.google.maps.DirectionsService();
  const _routesForMap = [];
  const data = await DirectionsService.route({
    origin: new window.google.maps.LatLng(
      driver.last_latitude,
      driver.last_longitude
    ),
    destination: {
      query: currentStatus.includes("pickup")
        ? pickup.address
        : dropoff.address,
    },
    travelMode: window.google.maps.TravelMode.DRIVING,
  });
  _routesForMap.push(data);
  return _routesForMap;
};

export const coordinatesHandler = async (pickupAddress) => {
  const pickupResults = await geocodeByAddress(pickupAddress);
  const location = pickupResults[0].geometry.location;
  return { lat: location.lat(), lng: location.lng() };
};

export const constructName = (firstName, lastName) => {
  let nameArray = [firstName, lastName];
  nameArray = nameArray.filter(Boolean);
  return nameArray.join(" ");
};

export const currentOrderStatus = (orderInfo) => {
  const { pickup, dropoff } = orderInfo;
  if(!pickup.status && !dropoff.status) return ""
  if (
    !pickup.status ||
    pickup.status === ORDER_STATUSES.COMPLETED ||
    dropoff.status === ORDER_STATUSES.COMPLETED ||
    dropoff.status !== ORDER_STATUSES.SCHEDULED
  ) {
    return `dropoff_${dropoff.status}`;
  } else return `pickup_${pickup.status}`;
};

export const shouldShowDriverMarker = (orderInfo) => {
  if (
    orderInfo?.driver?.last_latitude &&
    orderInfo?.driver?.last_longitude
  )
    return true;
  return false;
};

export const shouldShowDriverRoute = (orderInfo) => {
  const currentStatus = currentOrderStatus(orderInfo);
  if (
    orderInfo?.driver?.last_latitude &&
    orderInfo?.driver?.last_longitude &&
    (currentStatus === ORDER_STATUSES.DRIVER_ENROUTE_FOR_PICKUP ||
      currentStatus === ORDER_STATUSES.DRIVER_ENROUTE_FOR_DROPOFF)
  )
    return true;
  return false;
};
