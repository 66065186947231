import React, { useEffect, useState } from "react";

import { useClickStore, useIncidents, useTheme } from "../../store";
import "../../screens/CreateDelivery/CreateDelivery.css";
import LogsPage from "../LogsPage";
import DeliveryIncidentsTab from "./DeliveryIncident";
import DeliveryIncidentModals from "./DeliveryIncident/DeliveryIncidentModals";
import CustomModal from "../CustomModal/CustomModal";
import DeliveriesForAdmin from "./DeliveriesForAdmin";
import UsersForAdmin from "./UsersForAdmin";
import TabButtonComp from "../TabButtonComp/TabButtonComp";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import RerouteRecommendations from "./RerouteRecommendation";
import { useFlag } from "@unleash/proxy-client-react";

const AdminPage = () => {
  const isAdminRerouteIntentFFEnabled = useFlag("adminRerouteIntent");
  const { Colors } = useTheme();
  const { isdesktop } = useDesktopOrLaptop();
  const { setAllTimeVisible } = useClickStore();
  const {
    isCreateEditIncidentModalOpen,
    setIsCreateEditIncidentModalOpen,
    setUpdatingIncidentIndex,
    markNotReroutableVisible,
    setMarkNotReroutableVisible,
  } = useIncidents();

  const [activePage, setActivePage] = useState("deliveries");

  useEffect(() => {
    window.analytics.page();
  }, []);

  return (
    <div
      style={{
        padding: "24px",
        fontFamily: "Poppins",
        width: "100%",
        overflowY: "scroll",
        backgroundColor: Colors.white,
        paddingBottom: "100px",
      }}
      className="  flex flex-col  h-full kata "
    >
      <CustomModal
        extraPanelStyles={{ maxWidth: isdesktop ? "50%" : "80%" }}
        isOpen={isCreateEditIncidentModalOpen}
        onClose={() => {
          if (markNotReroutableVisible) {
            setMarkNotReroutableVisible(false);
          } else {
            setAllTimeVisible(true);
            setIsCreateEditIncidentModalOpen(false);
            setUpdatingIncidentIndex();
          }
        }}
      >
        <DeliveryIncidentModals />
      </CustomModal>
      <div
        style={{ marginBottom: "24px" }}
        className="flex flex-row items-center"
      >
        <TabButtonComp
          isActive={activePage === "deliveries"}
          onClick={() => {
            setActivePage("deliveries");
          }}
          title={"All Deliveries"}
        />
        <TabButtonComp
          isActive={activePage === "incidents"}
          onClick={() => {
            setActivePage("incidents");
          }}
          title={"Delivery Incidents"}
        />
        {isAdminRerouteIntentFFEnabled && <TabButtonComp
          isActive={activePage === "rerouteRecommendations"}
          onClick={() => {
            setActivePage("rerouteRecommendations");
          }}
          title={"Reroute Recommendations"}
        />}
        <TabButtonComp
          isActive={activePage === "users"}
          onClick={() => {
            setActivePage("users");
          }}
          title={"Users"}
        />

        <TabButtonComp
          isActive={activePage === "logs"}
          onClick={() => {
            setActivePage("logs");
          }}
          title={"Logs"}
        />
      </div>
      {activePage === "users" ? (
        <UsersForAdmin />
      ) : activePage === "logs" ? (
        <LogsPage />
      ) : activePage === "incidents" ? (
        <DeliveryIncidentsTab />
      ) : activePage === "rerouteRecommendations" ? (
        <RerouteRecommendations />
      ) : (
        <>
          <DeliveriesForAdmin />
        </>
      )}
    </div>
  );
};

export default AdminPage;
