import React from "react";
import { useTheme, useProofDownloader } from "../../store";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";

function SignatureContainer({ image, id }) {
  const { Colors } = useTheme();

  const { isdesktop, smfontsize } = useDesktopOrLaptop();
  const { onDownloadImages } = useProofDownloader();

  return (
    <div style={{ width: "100%" }} className="flex flex-col">
      <div
        style={{
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: smfontsize,
          marginTop: "24px",
          color: Colors.fontDark,
          width: "100%",
          backgroundColor: Colors.whiteBlue,
          // height: "31px",
          padding: "8px 12px",
          alignItems: "center",
          display: "flex",
          borderRadius: "5px 5px 0px 0px",
          justifyContent: "space-between",
        }}
      >
        <p style={{ color: Colors.fontDark, marginBottom: "0px" }}>Signature</p>
        <button
          // disabled
          style={{ color: Colors.bluePrimary }}
          onClick={() => onDownloadImages(id, true)}
        >
          Download
        </button>
      </div>
      <div
        className="noScroll"
        style={{
          paddingTop: "15px",
          backgroundColor: Colors.whiteBlue,
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          borderRadius: "0px 0px 10px 10px",
          padding: "14px 14px 15px",
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          overflowX: "auto",
        }}
      >
        <a
          style={{
            backgroundColor: "#292E44",
            height: isdesktop ? "100px" : "200px",
            width: isdesktop ? "100px" : "200px",
          }}
          href={image}
          target="_blank"
          rel="noreferrer"
        >
          <img
            style={{
              height: "100%",
              width: "100%",
              objectFit: "cover",
              margin: "0px 10px 0px 0px",
            }}
            alt=""
            src={image}
            // src={
            //   "https://helpx.adobe.com/content/dam/help/en/photoshop/using/convert-color-image-black-white/jcr_content/main-pars/before_and_after/image-before/Landscape-Color.jpg"
            // }
            // ! png
            // src={
            //   "https://cdn.pixabay.com/photo/2017/02/09/21/08/wings-2053515__340.png"
            // }
          />
        </a>
      </div>
    </div>
  );
}

export default SignatureContainer;
