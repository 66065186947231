import React, { useEffect } from "react";

import { useMyStore, useTheme } from "../../../store";
import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";
import useWebhooks from "../../../store/useWebhooks";

const AddEditWebhookModal = ({ setCreateWebhookVisible, setUrl, url }) => {
  const { Colors } = useTheme();
  const { isSandbox, addWebhook, addWebhookLoading, getWebhooksList } =
    useMyStore();
  const { smfontsize, lgfontsize } = useDesktopOrLaptop();
  const {
    webhookForUpdate,
    setWebhookForUpdate,
    isUpgrading,
    setIsUpgrading,
    putUpdateWebhooks,
  } = useWebhooks();

  useEffect(() => {
    if (webhookForUpdate) {
      setUrl(webhookForUpdate.url);
    }
    return () => {
      setWebhookForUpdate();
      setIsUpgrading(false);
    };
  }, []);

  const addNewWebhookHandler = async () => {
    await addWebhook(url, isSandbox);
    setUrl("");

    setCreateWebhookVisible(false);
  };

  const updateWebhookHandler = async (upgrade) => {
    await putUpdateWebhooks(webhookForUpdate.id, {
      url: url,
      current_version: upgrade
        ? webhookForUpdate.latest_version
        : webhookForUpdate.current_version,
    });
    getWebhooksList(isSandbox);
    setUrl("");
    setCreateWebhookVisible(false);
    setWebhookForUpdate();
    setIsUpgrading(false);
  };

  return (
    <div
      style={{ backgroundColor: Colors.white }}
      className="flex py-10 flex-col justify-start items-center "
    >
      <div
        style={{
          fontFamily: "Poppins",
          fontSize: lgfontsize,
          marginBottom: isUpgrading ? "50px" : "24px",
          color: Colors.fontDark,
          fontWeight: "500",
        }}
        className=" px-20 text-center"
      >
        {isUpgrading
          ? "After upgrading you can't revert the changes"
          : "Set your webhook URL and you’ll be able to send events there"}
      </div>
      {!isUpgrading && (
        <div className="w-full px-16  ">
          <input
            id="newWebhookId"
            style={{
              fontFamily: "Poppins",
              fontSize: smfontsize,
              marginBottom: "24px",
              backgroundColor: Colors.white,
              color: Colors.fontDark,
            }}
            className={` 
           p-2 rounded border focus:outline-none focus:border-blue-600 px-5 w-full ml-2`}
            type="url"
            onChange={(e) => {
              setUrl(e.target.value);
            }}
            value={url}
            placeholder={`https://example.com`}
          />
        </div>
      )}

      <div className="flex w-full justify-evenly ">
        <button
          id="saveNewWebhookButtonId"
          style={{
            fontFamily: "Poppins",
            fontSize: smfontsize,
            backgroundColor: Colors.bluePrimary,
            fontWeight: "600",
          }}
          onClick={async () => {
            if (webhookForUpdate) {
              if (isUpgrading) {
                updateWebhookHandler(true);
              } else {
                updateWebhookHandler();
              }
            } else {
              addNewWebhookHandler();
            }
          }}
          className=" text-blue-50 rounded-md px-6 py-1.5 focus:outline-none"
        >
          {addWebhookLoading ? "..loading" : isUpgrading ? "Upgrade" : "Save"}
        </button>
        <button
          id="cancelSaveNewWebhookButtonId"
          style={{
            fontFamily: "Poppins",
            fontSize: smfontsize,
            borderRadius: "8px",
            // backgroundColor: "#FC5A5A",
            color: Colors.bluePrimary,
            fontWeight: "600",
          }}
          onClick={() => {
            setUrl("");
            setCreateWebhookVisible(false);
          }}
          className=" px-6 py-1.5 focus:outline-none "
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default AddEditWebhookModal;
