import Cookies from "js-cookie";
import { ROLES } from "./components/AccountSettings/RolesSidebar/helpers";
export function setCookie(cookieName, cookieValue) {
  if (process.env.REACT_APP_ENVIRONMENT === "local") {
    Cookies.set(cookieName, cookieValue);
  } else {
    Cookies.set(cookieName, cookieValue, {
      domain: ".burqup.com", // Ensures the cookie is accessible across subdomains
      path: "/", // Make it available across the entire domain
      sameSite: "None",
      secure: true,
    });
  }
}

export function removeCookie(cookieName) {
  if (process.env.REACT_APP_ENVIRONMENT === "local") {
    Cookies.remove(cookieName);
  } else {
    Cookies.remove(cookieName, {
      domain: ".burqup.com", // Ensures the cookie is accessible across subdomains
      path: "/", // Make it available across the entire domain
      sameSite: "None",
      secure: true,
    });
  }
}


export const userRoleHandler = (user_roles) => {
  if (user_roles && user_roles[0]) {
    if (user_roles[0]?.role_name === "store_admin") {
      return ROLES.STORE_MANAGER;
    } else if (user_roles[0]?.role_name === "store_operator_admin") {
      return ROLES.STORE_EMPLOEE;
    } else {
      return user_roles[0]?.role_name;
    }
  } else {
    return ROLES.ADMIN;
  }
};
