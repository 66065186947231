import React, { useState } from "react";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import { useTheme } from "../../store";

const ChooseTimezone = ({
  selectedTimeZone,
  setSelectedTimeZone,
  isClickedOnSubmit,
  ianaTimezones,
  selectedIndex,
  setSelectedIndex,
}) => {
  const { isdesktop, smfontsize, mdfontsize } = useDesktopOrLaptop();
  const { Colors } = useTheme();

  const styles = {
    inputs: {
      fontFamily: "Poppins",
      borderRadius: "8px",
      fontSize: smfontsize,
      fontWeight: "400",
      color: Colors.fontDark,
      // backgroundColor: Colors.buttonBg,
    },
  };

  return (
    <div
      style={{
        fontFamily: "Poppins",
        color:
          isClickedOnSubmit && !selectedTimeZone
            ? Colors.redDark
            : Colors.fontGreyLight,
        fontSize: mdfontsize,
        marginBottom: "20px",
      }}
      className="w-full "
    >
      <div style={{ marginBottom: "10px" }}>Choose the timezone</div>
      <div className="col-start-4 col-span-3">
        <select
          draggable={true}
          value={selectedIndex ?? ""}
          onChange={(e) => {
            setSelectedTimeZone(ianaTimezones[e.target.value]);
            setSelectedIndex(e.target.value);
          }}
          style={{
            ...styles.inputs,
            ...{ backgroundColor: Colors.borderPrimary },
          }}
          className="py-3 px-5  w-full focus:outline-none "
          placeholder="Delivery preferences"
        >
          <option value={""}>Time Zone</option>
          {ianaTimezones.map((item, index) => {
            return (
              <option key={item.front} value={index}>
                {item.front}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

export default ChooseTimezone;
