import { createStore } from "aniuta";
import * as Sentry from "@sentry/react";
import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import { baseUrl } from "../runTimeConst";
import { getMerchantAccountStores, getStore } from "../utils/api/account";

const useStoreAndChild = createStore({
  name: "useStoreAndChild",
  Store: () => {
    const [selectedStore, setSelectedStore] = useState();

    const [isCreateStoreModalOpen, setIsCreateStoreModalOpen] = useState(false);
    const [isInviteMemberVisible, setIsInviteMemberVisible] = useState(false);

    const [createAnotherStore, setCreateAnotherStore] = useState(false);

    const [selectedStoreId, setSelectedStoreId] = useState("");
    // ! STORE FOR EDIT
    const [storeForEdit, setStoreForEdit] = useState();

    //! Get  STORE BY USER
    const [isStoreByIdLoading, setIsStoreByIdLoading] = useState(false);
    const [storeById, setStoreById] = useState();

    const getStoreById = async (storeId) => {
      setIsStoreByIdLoading(true);
      try {
        const response = await getStore(storeId);
        setStoreById(response.data?.users);
        return response.data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsStoreByIdLoading(false);
      }
    };

    // ! POST CREATE STORE

    const [isCreateStoreLoading, setIsCreateStoreLoading] = useState(false);

    const postCreateStore = async (userId, payload, cb) => {
      setIsCreateStoreLoading(true);
      try {
        const response = await axios.post(
          `${baseUrl}merchant_accounts/${userId}/stores`,
          payload,
          { headers: { "x-burq-client": "client-dashboard" } }
        );
        if (!!cb) cb();
        return response.data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsCreateStoreLoading(false);
      }
    };

    // ! ADD USER TO STRE

    const [isAddUserToStoreLoading, setIsAddUserToStoreLoading] =
      useState(false);

    const postAddUserToStore = async (storeId, email, role) => {
      setIsAddUserToStoreLoading(true);
      try {
        const response = await axios.post(
          `${baseUrl}stores/${storeId}/user`,
          {
            email: email,
            // role: role,
          },
          { headers: { "x-burq-client": "client-dashboard" } }
        );
        return response;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsAddUserToStoreLoading(false);
      }
    };
    // ! DELETE USER FROM THE STRE

    const [isDeleteUserLoading, setIsDeleteUserLoading] = useState(false);

    const deleteUserFromStore = async (storeId, userId) => {
      setIsDeleteUserLoading(true);
      try {
        const response = await axios.delete(
          `${baseUrl}stores/${storeId}/users/${userId}`,
          { headers: { "x-burq-client": "client-dashboard" } }
        );
        return response;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsDeleteUserLoading(false);
      }
    };

    //! GET ALL STORES BY USER

    const [isAllStoresByUserLoading, setIsAllStoresByUserLoading] =
      useState(false);
    const [
      isConnectedAccountStoresLoading,
      setIsConnectedAccountStoresLoading,
    ] = useState(false);
    const [storesForConnectedAccount, setStoresForConnectedAccount] =
      useState();
    const [allStoresByUser, setAllStoresByUser] = useState([]);

    const getAllStoresByUser = async (
      userId,
      isConnectedAccount = false,
      params = {}
    ) => {
      if (isConnectedAccount) {
        setIsConnectedAccountStoresLoading(true);
      } else {
        setIsAllStoresByUserLoading(true);
      }
      try {
        const response = await getMerchantAccountStores(userId, {
          ...params,
          limit: 50,
        });
        if (isConnectedAccount) {
          setStoresForConnectedAccount(response?.data);
          setIsConnectedAccountStoresLoading(false);
        } else {
          setAllStoresByUser(response?.data);
          setIsAllStoresByUserLoading(false);
        }
        return response?.data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
        setIsAllStoresByUserLoading(false);
        setIsConnectedAccountStoresLoading(false);
      }
    };

    //! PUT UPDATE STORE

    const [isUpdateStoreLoading, setIsUpdateStoreLoading] = useState(false);

    const putUpdateStore = async (
      storeId,
      title,
      store_no,
      phone_no,
      address,
      pickup_instructions,
      merchant_store_id,
      timezone,
      settings
    ) => {
      setIsUpdateStoreLoading(true);
      try {
        const response = await axios.put(
          `${baseUrl}stores/${storeId}`,
          {
            name: title,
            unit_number: store_no,
            phone_number: phone_no,
            address: address,
            pickup_notes: pickup_instructions,
            merchant_store_id: merchant_store_id,
            timezone: timezone,
            settings: settings,
          },
          { headers: { "x-burq-client": "client-dashboard" } }
        );
        return response.data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsUpdateStoreLoading(false);
      }
    };

    //! DELETE STORE

    const [isDeleteStoreLoading, setIsDeleteStoreLoading] = useState(false);

    const deleteStore = async (storeId) => {
      setIsDeleteStoreLoading(true);
      try {
        const response = await axios.delete(`${baseUrl}stores/${storeId}`, {
          headers: { "x-burq-client": "client-dashboard" },
        });
        return response.data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsDeleteStoreLoading(false);
      }
    };

    return {
      selectedStore,
      setSelectedStore,
      selectedStoreId,
      setSelectedStoreId,
      isCreateStoreModalOpen,
      setIsCreateStoreModalOpen,
      isInviteMemberVisible,
      setIsInviteMemberVisible,
      // ! STORE FOR EDIT
      storeForEdit,
      setStoreForEdit,
      //! Get STORE BY ID
      getStoreById,
      storeById,
      setStoreById,
      isStoreByIdLoading,
      setIsStoreByIdLoading,
      // ! POST CREATE STORE
      postCreateStore,
      isCreateStoreLoading,
      setIsCreateStoreLoading,
      // ! ADD USER TO STORE
      postAddUserToStore,
      isAddUserToStoreLoading,
      setIsAddUserToStoreLoading,
      //! DELETE USER FROM STORE
      deleteUserFromStore,
      isDeleteUserLoading,
      setIsDeleteUserLoading,
      //! GET ALL STORES BY USER
      getAllStoresByUser,
      allStoresByUser,
      setAllStoresByUser,
      isAllStoresByUserLoading,
      setIsAllStoresByUserLoading,
      //! PUT UPDATE STORE
      putUpdateStore,
      isUpdateStoreLoading,
      setIsUpdateStoreLoading,
      //! DELETE STORE
      deleteStore,
      isDeleteStoreLoading,
      //! Connected Account's Stores
      storesForConnectedAccount,
      setStoresForConnectedAccount,
      isConnectedAccountStoresLoading,
      setIsConnectedAccountStoresLoading,
      createAnotherStore,
      setCreateAnotherStore,
    };
  },
});

export default useStoreAndChild;
