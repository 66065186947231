import React from "react";
import styles from "./styles.module.css";
import Button from "../../shared/atoms/Button";
import classNames from "classnames";
import { useOnboardingStore } from "../../../store";
import { Loading } from "../../../assets/svgs/Svgs";

const OnboardingFooter = ({ onClick, disabled = false, isLoading = false, hideButton = false, buttonTitle }) => {
  const { platformInfo } = useOnboardingStore();

  return (
    <div className={styles.container}>
      <div className="flex items-center justify-end px-9">
        {!hideButton && (
          <Button
            onClick={onClick}
            disabled={disabled}
            variant={disabled ? "disabled" : "primary"}
            externalClassName={classNames(styles.buttonStyles)}
          >
            {isLoading ? (
              <div className="w-full flex justify-center">
                <Loading color={disabled ? "#0062ff" : "#ECF0F1"} className="h-6 w-6 animate-spin" />{" "}
              </div>
            ) : (
              buttonTitle || "Next"
            )}
          </Button>
        )}
      </div>
      <div className={classNames("text-center mb-4", styles.footerText)}>
        Burq helps your business offer on-demand delivery to your customers.
        {platformInfo?.onboarding_faq_url && (
          <div>
            Questions?
            <a className={styles.hyperLink} href={platformInfo.onboarding_faq_url} target="blank">
              {" "}
              View FAQ
            </a>
            .
          </div>
        )}
      </div>
    </div>
  );
};

export default OnboardingFooter;
