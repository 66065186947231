import React from "react";
import { statuses } from "../../../../../runTimeConst";
import { useAnalyticsStore } from "../../../../../store";
import SelectComp from "../../../../SelectComp/SelectComp";

const StatusDropdown = () => {
  const { selectStatus, setSelectStatus } = useAnalyticsStore();

  return (
    <div style={{ width: "25%", marginBottom: '4px' }}>
      <SelectComp
        list={statuses}
        setSelectedValue={setSelectStatus}
        defaultValue={selectStatus}
        value={selectStatus}
        title=""
      />
    </div>
  );
};

export default StatusDropdown;
