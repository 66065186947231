import React, { useEffect, useState } from "react";

import { useConnectedAccounts, useMerchant, useMyStore, userDataStore, useStoreAndChild, useTheme } from "../../store";
import StoresList from "./StoresList/StoresList";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import { FILTER_TYPES } from "../../constants";
import FiltersMenu from "../shared/common/FiltersMenu";
import ActiveFiltersBar from "../shared/common/FiltersMenu/ActiveFiltersBar";
import Button from "../shared/atoms/Button";
import styles from "./styles/storeSettings.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ROLES } from "../AccountSettings/RolesSidebar/helpers";

const StoreSettings = () => {
  const { Colors } = useTheme();
  const [searchName, setSearchName] = useState();
  const [searchId, setSearchId] = useState();
  const [searchMerchantId, setSearchMerchantId] = useState();
  const { setIsCreateStoreModalOpen, getAllStoresByUser } = useStoreAndChild();

  const { authInfo, userRole } = userDataStore();
  const { merchantData, isAdmin } = useMerchant();
  const { userId } = useMyStore();

  const { isdesktop } = useDesktopOrLaptop();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const message = queryParams.get("message");
    if (message === "success" || message === "no_stores_found" || message === "no_brands_found") {
      toast.success("The stores have been successfully imported");
    } else if (message) {
      toast.error("There was an error importing the stores. Please try again.");
    }
    queryParams.delete("message");
    navigate({
      pathname: location.pathname,
      search: queryParams.toString(),
    });
  }, [location.search, navigate]);

  const filtersDefinition = [
    {
      id: "search_by_store_name",
      title: "Store Name",
      type: FILTER_TYPES.SEARCH,
      placeholder: "Store Name",
      filterState: searchName,
      setFilterState: setSearchName,
    },
    {
      id: "search_by_store_id",
      title: "Burq Store ID",
      type: FILTER_TYPES.SEARCH,
      placeholder: "Burq Store ID",
      filterState: searchId,
      setFilterState: setSearchId,
    },
    {
      id: "search_by_merchant_store_id",
      title: "Merchant Store ID",
      type: FILTER_TYPES.SEARCH,
      placeholder: "Merchant Store ID",
      filterState: searchMerchantId,
      setFilterState: setSearchMerchantId,
    },
  ];

  const storeListHandler = (params) => {
    const merchantId = userId ?? authInfo.merchantAccountId;
    getAllStoresByUser(merchantId, false, {
      search_by_name: searchName,
      search_by_id: searchId,
      search_by_merchant_store_id: searchMerchantId,
      ending_before: params?.ending_before || "",
      starting_after: params?.starting_after || "",
    });
  };
  useEffect(() => {
    storeListHandler();
  }, [authInfo, searchName, searchId, searchMerchantId]);

  const { setSelectedConnectedAccount } = useConnectedAccounts();

  return (
    <div className="h-full">
      <div
        style={{
          padding: isdesktop ? "24px 0px " : "24px 20px",
          height: isdesktop ? `calc(100vh - 70px)` : `calc(100vh - 200px)`,
        }}
        className="w-full flex flex-col"
      >
        <div
          style={{
            backgroundColor: Colors.white,
            padding: isdesktop ? "17px 24px" : "0px",
            borderRadius: "10px",
            height: "100%",
          }}
          className="flex flex-col"
        >
          <div className="flex flex-row items-center justify-between mt-5 mb-2">
            <div className="flex flex-row items-center">
              <div style={{ minWidth: "130px" }}>
                <FiltersMenu menuItems={filtersDefinition} title="Filter By" />
              </div>
              <div style={{ width: "100%" }}>
                <ActiveFiltersBar activeFilters={[...filtersDefinition]} />
              </div>
            </div>
            <div>
              {(userRole === ROLES.ADMIN || isAdmin) && (
                <Button
                  text="Create Store"
                  onClick={() => {
                    setIsCreateStoreModalOpen(true);
                    setSelectedConnectedAccount();
                  }}
                  externalClassName={styles.createButton}
                />
              )}
            </div>
          </div>
          <StoresList storeListHandler={storeListHandler} dependencies={[searchName, searchId, searchMerchantId]} />
        </div>
      </div>
    </div>
  );
};

export default StoreSettings;
