import { useEffect, useState } from "react";
import { pusher } from "../config/pusher";

function useSocket({ channelName = null, notificationsEvent = null }) {
  const [message, setMessage] = useState(null);
  const [notificationsData, setNotificationsData] = useState(null);

  useEffect(() => {
    if (channelName) {
      const channel = pusher.subscribe(`presence-${channelName}`);
      // const channel = pusher.subscribe(`private-${channelName}`);

      channel.bind("pusher:subscription_succeeded", () => {
        console.log(`successfully subscribed to the channel`);
      });

      channel.bind("pusher:subscription_error", (error) => {
        console.log(`Failed subscription to channel`, error);
      });

      channel.bind("delivery_update", (data) => {
        setMessage(data);
      });
      if (notificationsEvent) {
        channel.bind(notificationsEvent, (data) => {
          setNotificationsData(data);
        });
      }
      return () => {
        channel.unbind_all();
        channel.unsubscribe();
      };
    }
  }, [channelName]);

  return { message, notificationsData };
}

export default useSocket;
