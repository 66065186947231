import React, { useEffect, useState } from "react";
import { useDesktopOrLaptop } from "../../../../services/useDesktopOrLaptop";
import { useMyStore, useTheme, useQuoteWorkflow, useStoreAndChild, useMerchant, userDataStore } from "../../../../store";

import CommonToggle from "../../../CommonToggle";
import { QUOTE_ITEMS } from "../utils";

const RightSidebar = ({ setIsLeftOpened, isInsideStore }) => {
  const [isPercent, setIsPercent] = useState(false);

  const { selectedQuoteItem, setSelectedQuoteItem } = useQuoteWorkflow();

  const { merchantData, updateMerchantData } = useMerchant();

  const { userId } = useMyStore();
  const { authInfo } = userDataStore();
  const { selectedStore, putUpdateStore, setSelectedStore, getAllStoresByUser } = useStoreAndChild();

  const [isError, setIsError] = useState(false);

  const { isdesktop, mdfontsize, smfontsize, xsfontsize } = useDesktopOrLaptop();

  const { Colors } = useTheme();

  const [value, setValue] = useState(undefined);
  const [itemForFrontend, setItemForFrontend] = useState();

  const [toggleChecker, setToggleChecker] = useState(false);
  const _dataForDisplay = selectedStore ? { ...selectedStore.settings } : { ...merchantData?.settings };

  useEffect(() => {
    let subscribe = true;
    if (subscribe) {
      const kata = QUOTE_ITEMS.filter((item) => item?.key === selectedQuoteItem);
      setItemForFrontend(kata[0]);
      if (selectedQuoteItem === "Subsidize") {
        const amount =
          _dataForDisplay?.delivery_fee_display_adjustment_amount < 0
            ? (_dataForDisplay?.delivery_fee_display_adjustment_amount * -0.01).toFixed(2)
            : undefined;
        const percentage =
          _dataForDisplay?.delivery_fee_display_adjustment_percentage < 0
            ? _dataForDisplay?.delivery_fee_display_adjustment_percentage * -1
            : undefined;
        setValue(amount ? amount : percentage ? percentage : undefined);
        setIsPercent(percentage ? true : false);
      } else if (selectedQuoteItem === "Upsell") {
        const amount =
          _dataForDisplay?.delivery_fee_display_adjustment_amount > 0
            ? (_dataForDisplay?.delivery_fee_display_adjustment_amount * 0.01).toFixed(2)
            : undefined;
        const percentage =
          _dataForDisplay?.delivery_fee_display_adjustment_percentage > 0
            ? _dataForDisplay?.delivery_fee_display_adjustment_percentage
            : undefined;
        setValue(amount ? amount : percentage ? percentage : undefined);
        setIsPercent(percentage ? true : false);
      } else if (selectedQuoteItem === "Limit Radius") {
        const miles = _dataForDisplay?.max_delivery_radius;
        setValue(miles || undefined);
      } else if (selectedQuoteItem === "Price Ceiling") {
        const maxCost = _dataForDisplay?.max_cost_of_delivery;
        setValue((maxCost * 0.01).toFixed(2) || undefined);
      } else if (selectedQuoteItem === "Multi Quote") {
        const isMultiCuoteEnables = _dataForDisplay?.is_multi_quote_enabled;
        setToggleChecker(isMultiCuoteEnables);
      } else if (selectedQuoteItem === "Include Burq fee") {
        const isBurqFeeIncluded = _dataForDisplay.is_burq_fee_included_in_fee;
        setToggleChecker(isBurqFeeIncluded);
      }
    }
    return () => {
      subscribe = false;
      setToggleChecker(false);
    };
  }, [selectedQuoteItem, merchantData]);

  return (
    <div
      className="kata"
      style={{
        position: "absolute",
        transform: selectedQuoteItem ? `translate(0px)` : `translate(440px)`,
        right: "0px",
        top: isInsideStore ? (isdesktop ? "211px" : "240px") : isdesktop ? "70px" : "200px",
        bottom: "0px",
        width: "440px",
        padding: "24px",
        backgroundColor: Colors.white,
        display: "flex",
        flexDirection: "column",
        justifyContent: isdesktop ? "space-between" : "start",
        zIndex: 4,
      }}
    >
      <div>
        <div
          style={{
            fontFamily: "Poppins",
            fontWeight: "500",
            color: Colors.fontDark,
            fontSize: mdfontsize,
          }}
        >
          {itemForFrontend?.key}
        </div>

        <div
          style={{
            color: Colors.fontGreyLight,
            marginTop: "10px",
            fontSize: smfontsize,
          }}
        >
          {itemForFrontend?.description}
        </div>

        <div
          style={{
            marginTop: "30px",
            color: Colors.fontDark,
            marginLeft: "4px",
            fontSize: mdfontsize,
          }}
        >
          {itemForFrontend?.key === "Subsidize" || itemForFrontend?.key === "Upsell"
            ? "Amount or Percent:"
            : itemForFrontend?.key === "Limit Radius"
            ? "Number of miles:"
            : ""}
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          {itemForFrontend?.key !== "Multi Quote" && itemForFrontend?.key !== "Include Burq fee" ? (
            <div
              style={{
                width: "100%",
                borderWidth: "1px",
                borderColor: isError ? Colors.redDark : Colors.borderSecondary,
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                height: isdesktop ? "36px" : "44px",
              }}
            >
              <input
                onChange={(event) => {
                  if (isError) {
                    setIsError(false);
                  }
                  const regex = new RegExp(/^\d+\.?\d*$/);
                  const inputValue = event.target.value;

                  if (regex.test(inputValue)) {
                    // const min = 0;
                    // return
                    if (inputValue === "") {
                      setValue();
                    } else if (itemForFrontend?.key === "Subsidize" || itemForFrontend?.key === "Upsell") {
                      let max = 200;
                      if (isPercent) max = 100;

                      if (max < inputValue) {
                        setValue(max);
                      } else {
                        const [beforDecimel, afterDecimel] = inputValue.split(".");
                        if (afterDecimel && afterDecimel.length > 2) {
                          setValue(parseFloat(inputValue).toFixed(2));
                        } else {
                          setValue(inputValue);
                        }
                      }
                    } else {
                      // const val = Math.max(min, Number(inputValue));
                      setValue(inputValue);
                    }
                  } else {
                    setValue();
                  }
                }}
                value={value || ""}
                style={{
                  backgroundColor: "transparent",
                  paddingLeft: "20px",
                  outline: "none",
                  color: Colors.fontDark,
                  width: "70%",
                  height: isdesktop ? "32px" : "40px",
                  fontSize: smfontsize,
                }}
                type="text"
              />

              {(itemForFrontend?.key === "Subsidize" ||
                itemForFrontend?.key === "Upsell" ||
                itemForFrontend?.key === "Price Ceiling") && (
                <div
                  style={{
                    height: "30px",
                    display: "flex",
                    alignItems: "center",
                    padding: "3px",
                    backgroundColor: Colors.detailsBg,
                    borderRadius: "6px",
                    marginRight: "2px",
                  }}
                >
                  {itemForFrontend?.key !== "Price Ceiling" && (
                    <button
                      onClick={() => {
                        setIsPercent(true);
                      }}
                      type="button"
                      className={`${isPercent ? "mySmallShadow" : ""}`}
                      style={{
                        backgroundColor: isPercent ? Colors.white : Colors.detailsBg,
                        borderRadius: "6px",
                        width: "26px",
                        height: "26px",
                        fontSize: "18px",
                        marginRight: "4px",
                        color: isPercent ? Colors.bluePrimary : Colors.fontGreyLight,
                      }}
                    >
                      %
                    </button>
                  )}
                  <button
                    className={`${!isPercent ? "mySmallShadow" : ""}`}
                    onClick={() => {
                      setIsPercent(false);
                    }}
                    type="button"
                    style={{
                      backgroundColor: !isPercent ? Colors.white : Colors.detailsBg,
                      borderRadius: "6px",
                      width: "26px",
                      height: "26px",
                      fontSize: "18px",
                      color: !isPercent ? Colors.bluePrimary : Colors.fontGreyLight,
                    }}
                  >
                    $
                  </button>
                </div>
              )}
              {itemForFrontend?.key === "Limit Radius" && (
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    // padding: "3px",
                    backgroundColor: Colors.detailsBg,
                    borderRadius: "0px 10px 10px 0px",
                    padding: "0px 20px",
                    color: Colors.fontGreyLight,
                  }}
                >
                  miles
                </div>
              )}
            </div>
          ) : (
            <div className="flex items-center">
              <CommonToggle
                checked={toggleChecker}
                translateX={isdesktop ? 19 : 24}
                smsHandler={() => {
                  setToggleChecker(!toggleChecker);
                }}
                circleStyle={{
                  width: isdesktop ? "18px" : "30px",
                  height: isdesktop ? "18px" : "30px",
                  backgroundColor: Colors.white,
                }}
                style={{
                  width: isdesktop ? "42px" : "60px",
                  height: isdesktop ? "20px" : "32px",
                  padding: "3px",
                  backgroundColor: toggleChecker ? Colors.greenDark : Colors.fontGreyLight,
                }}
              />
              <div
                style={{
                  marginLeft: "8px",
                  color: Colors.fontGreyLight,
                  fontSize: xsfontsize,
                }}
              >
                {itemForFrontend?.key === "Include Burq fee" ? "Include Burq fee" : "Allow multiple quote service"}
              </div>
            </div>
          )}
        </div>
        <div
          style={{
            marginTop: "20px",
            color: Colors.fontGreyLight,
            fontSize: xsfontsize,
          }}
        >
          {itemForFrontend?.example}
        </div>
      </div>
      <div style={{ width: "100%", marginTop: !isdesktop && "50px" }}>
        <button
          type="button"
          onClick={() => {
            // let payload = { ...merchantData.settings };
            let numberValue = Number(value);
            let payload = selectedStore ? { ...selectedStore?.settings } : { ...merchantData?.settings };
            if (selectedQuoteItem === "Subsidize") {
              if (isPercent) {
                payload.delivery_fee_display_adjustment_amount =
                  payload.delivery_fee_display_adjustment_amount > 0 ? payload.delivery_fee_display_adjustment_amount : null;
                payload.delivery_fee_display_adjustment_percentage =
                  numberValue > 0 ? parseFloat(numberValue.toFixed(2)) * -1 : null;
              } else {
                payload.delivery_fee_display_adjustment_percentage =
                  payload.delivery_fee_display_adjustment_percentage > 0
                    ? payload.delivery_fee_display_adjustment_percentage
                    : null;
                payload.delivery_fee_display_adjustment_amount =
                  numberValue > 0 ? parseFloat((numberValue * -100).toFixed(2)) : null;
              }
            } else if (selectedQuoteItem === "Upsell") {
              if (isPercent) {
                payload.delivery_fee_display_adjustment_percentage = numberValue > 0 ? parseFloat(numberValue.toFixed(2)) : null;
                payload.delivery_fee_display_adjustment_amount =
                  payload.delivery_fee_display_adjustment_amount < 0 ? payload.delivery_fee_display_adjustment_amount : null;
              } else {
                payload.delivery_fee_display_adjustment_percentage =
                  payload.delivery_fee_display_adjustment_percentage < 0
                    ? payload.delivery_fee_display_adjustment_percentage
                    : null;
                payload.delivery_fee_display_adjustment_amount =
                  numberValue > 0 ? parseFloat((numberValue * 100).toFixed(0)) : null;
              }
            } else if (selectedQuoteItem === "Limit Radius") {
              payload.max_delivery_radius = numberValue > 0 ? numberValue : null;
            } else if (selectedQuoteItem === "Price Ceiling") {
              payload.max_cost_of_delivery = numberValue > 0 ? parseFloat((numberValue * 100).toFixed(0)) : null;
            } else if (selectedQuoteItem === "Multi Quote") {
              payload.is_multi_quote_enabled = toggleChecker;
            } else if (selectedQuoteItem === "Include Burq fee") {
              payload.is_burq_fee_included_in_fee = toggleChecker;
            }
            // console.log("value---", value, "-----payload-----", payload);

            if (selectedStore) {
              putUpdateStore(
                selectedStore.id,
                selectedStore.name,
                selectedStore.unit_number,
                selectedStore.phone_number,
                selectedStore.address,
                selectedStore.pickup_notes,
                "",
                selectedStore.timezone,
                payload
              ).then((data) => {
                setSelectedStore(data);
                getAllStoresByUser(authInfo?.merchantAccountId).catch((err) => console.log(err));
                setSelectedQuoteItem();
              });
            } else {
              updateMerchantData(userId || authInfo.merchantAccountId, merchantData, payload).then((res) => {
                setSelectedQuoteItem();
              });
            }
          }}
          disabled={
            selectedQuoteItem === "Include Burq fee" || selectedQuoteItem === "Multi Quote"
              ? false
              : Number(value) > 0
              ? false
              : true
          }
          style={{
            backgroundColor: Colors.bluePrimary,
            fontFamily: "Poppins",
            fontSize: isdesktop ? "16px" : "28px",
            fontWeight: "500",
            color: "#FAFAFB",
            textAlign: "center",
            borderRadius: "8px",
            padding: "10px 30px",
            opacity:
              selectedQuoteItem === "Include Burq fee" || selectedQuoteItem === "Multi Quote" ? 1 : Number(value) > 0 ? 1 : 0.5,
            cursor:
              selectedQuoteItem === "Include Burq fee" || selectedQuoteItem === "Multi Quote"
                ? "pointer"
                : Number(value) > 0
                ? "pointer"
                : "not-allowed",
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default RightSidebar;
