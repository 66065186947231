import React, { useEffect, useState } from "react";
import CustomModal from "../../CustomModal/CustomModal";
import { useClickStore, useCsvDeliveries, useMyStore, useTheme, userDataStore } from "../../../store";
import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";
import { Loading } from "../../../assets/svgs/Svgs";
import DatePickerComp from "../../shared/DatePickerComp";
import UsersListOnCsv from "../UsersListOnCsv";
import StoresFilterOnListHeader from "../../DeliveriesAnalytics/DeliveriesListSection/common/StoresFilterOnListHeader";

const CSVModal = ({
  isCsvModalVisible,
  setIsCsvModalVisible,
  setStoreForCSV,
  isLoading,
  setDatePayload,
  admin,
  storeForCSV,
  isDownloadLoading,
  datePayload,
  setIsDownloadLoading,
  initDate,
  stopper,
}) => {
  const { setIsCalendarHidden, setAllTimeVisible } = useClickStore();
  const { Colors } = useTheme();
  const { isdesktop, smfontsize, mdfontsize } = useDesktopOrLaptop();
  const { isSandbox } = useMyStore();
  const { selectedUserForCsv, setSelectedUserForCsv, createCSVJob, downloadDeliveryCSV } = useCsvDeliveries();
  const { authInfo } = userDataStore();

  const jobHandler = async (jobRes) => {
    if (!stopper.current) {
      if (jobRes?.status === "processing") {
        let res = await downloadDeliveryCSV(jobRes.id);
        if (res?.status === "processing") {
          setTimeout(() => {
            setIsDownloadLoading(true);
            jobHandler(res);
          }, 1000);
        } else if (res.presigned_url) {
          window.open(res.presigned_url, "_blank");
          // setTimeout(() => {
          setDatePayload(initDate);
          setSelectedUserForCsv();
          setIsCalendarHidden(false);
          setIsCsvModalVisible(false);
          setAllTimeVisible(true);
          setIsDownloadLoading(false);
          // }, 300);
        }
      }
    } else {
      setIsDownloadLoading(false);
    }
  };

  useEffect(() => {
    setIsDownloadLoading(false);
    stopper.current = false;
    return () => {
      // clearTimeout(timeOutId);
      setDatePayload(initDate);
    };
  }, []);

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        position: "relative",
        flexDirection: "column",
        backgroundColor: Colors.white,
        zIndex: 999,
        minHeight: "600px",
      }}
    >
      <div style={{ width: "100%" }}>
        <div
          style={{
            width: "100%",
            backgroundColor: Colors.white,
            padding: "20px",
            fontSize: mdfontsize,
            color: Colors.fontDark,
            borderBottomWidth: "0.5px",
          }}
        >
          Export CSV
        </div>
        {isLoading && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              padding: "14px",
              marginTop: "10%",
            }}
          >
            <Loading color={Colors.bluePrimary} className="h-14 w-14 animate-spin" />
          </div>
        )}
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0px 20px",
            marginTop: "30px",
            color: Colors.fontDark,
          }}
        >
          <div>Choose Date</div>
          <DatePickerComp range setRange={setDatePayload} disabled={isLoading} />
        </div>
        {admin && <UsersListOnCsv admin={admin} />}

        {!admin && (
          <div>
            <div style={{ padding: "0px 192px 0px 130px" }} className="relative w-full">
              <StoresFilterOnListHeader
                storeForCSV={storeForCSV}
                setStoreForCSV={setStoreForCSV}
                isOnCsv
                isStoresListVisible={true}
                setIsStoresListVisible={() => {}}
              />
            </div>
          </div>
        )}
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "end",
          padding: "20px 50px",
        }}
      >
        <button
          style={{
            padding: "8px 14px",
            backgroundColor: Colors.redDark,
            borderRadius: "8px",
            color: "white",
            marginRight: "20px",
          }}
          onClick={() => {
            stopper.current = true;
            setIsCalendarHidden(false);
            setIsCsvModalVisible(false);
            setAllTimeVisible(true);
            setStoreForCSV();
          }}
        >
          Cancel
        </button>
        {isDownloadLoading ? (
          <div className="flex justify-center items-center ">
            <Loading color={Colors.bluePrimary} className="h-7 w-7 animate-spin" />
          </div>
        ) : (
          <button
            onClick={async () => {
              setIsDownloadLoading(true);
              let params = {
                start_date: datePayload?.start_date,
                end_date: datePayload?.end_date,
                test_mode: JSON.parse(isSandbox),
                store_id: !admin && storeForCSV ? storeForCSV?.id : "",
              };

              const userId = selectedUserForCsv ? selectedUserForCsv : authInfo?.merchantAccountId;

              const jobRes = await createCSVJob(userId, params, setIsDownloadLoading);
              jobHandler(jobRes);
            }}
            style={{
              padding: "8px 14px",
              backgroundColor: Colors.bluePrimary,
              borderRadius: "8px",
              color: "white",
              marginRight: "20px",
            }}
            disabled={isLoading}
          >
            Export
          </button>
        )}
      </div>
    </div>
  );
};

export default CSVModal;
