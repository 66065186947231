import React from "react";
import {
  useClickStore,
  useDelInfoOnSidePopup,
  useFilter,
  useMyStore,
  useStoreAndChild,
  useTheme,
} from "../../../store";

import styles from "./styles.module.css";

import classNames from "classnames";

import { IoIosCloseCircleOutline } from "react-icons/io";

import CopyToClipboard from "../../shared/atoms/CopyToClipboard";

const HeaderForDrawer = ({ deliveryObject, setSearchParams, statusSocket }) => {
  const { setDeliveryIdFromQueryParam } = useDelInfoOnSidePopup();

  const { setAllTimeVisible, setIsCalendarVisible } = useClickStore();

  return (
    <div className={classNames("w-full", styles.container)}>
      <div className="mb-4">
        <div className="flex items-center w-full justify-between">
          <div className={styles.burqIdTitle}>Burq ID</div>
          <div className="flex items-center">
            <button
              onClick={() => {
                setDeliveryIdFromQueryParam();
                setSearchParams({ deliveryId: "" });
                setAllTimeVisible(true);
                setIsCalendarVisible(true);
              }}
              type="button"
            >
              <IoIosCloseCircleOutline
                className={styles.burqIdTitle}
                size={28}
              />
            </button>
          </div>
        </div>
        <div className={styles.titleId}>
          {deliveryObject?.id}
          <CopyToClipboard copyText={deliveryObject?.id} />
        </div>
        <div className="flex items-center justify-start"></div>
      </div>
      <div className="flex items-center justify-between">
        <div className=""></div>
      </div>
    </div>
  );
};

export default HeaderForDrawer;
