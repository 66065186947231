export const allowedBadgesForBankAccountStatuses = {
  unverified: { type: "warning", label: "Pending verification" },
  verification_expired: {
    type: "error",
    label: "Verification link expired",
  },
  declined: {
    type: "error",
    label: "Declined",
  },
  verified: {
    type: "success",
    label: "VERIFIED",
  },
};

export const allowedBadgesForCardStatuses = {
  verification_pending: {
    type: "warning",
    label: "VERIFICATION PENDING",
  },
  declined: {
    type: "error",
    label: "Declined",
  },
  verified: {
    type: "success",
    label: "VERIFIED",
  },
  verification_expired: {
    type: "error",
    label: "VERIFICATION EXPIRED",
  },

  unverified: {
    type: "error",
    label: "Unverified",
  },
};
export const badgesForCardsNumber = {
  default: {
    type: "pickup",
    label: "DEFAULT",
  },
  clickToVerify: {
    type: "primaryWithUnderLine",
    label: "CLICK HERE TO VERIFY",
  },
};

export const cardNameHandler = (cardIssuer) => {
  return (
    <>
      {cardIssuer === "visa"
        ? "Visa"
        : cardIssuer === "diners"
        ? "Diners"
        : cardIssuer === "amex"
        ? "Amex"
        : cardIssuer === "unionpay"
        ? "Unionpay"
        : cardIssuer === "discover"
        ? "Discover"
        : cardIssuer === "jcb"
        ? "JCB"
        : "Mastercard"}{" "}
    </>
  );
};
