import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { cardsData, referralsFormSchema, submitHandler } from "./utils";
import FormikNewTextInput from "../formikFields/FormikNewTextInput";
import { Form, Formik } from "formik";
import CustomFormikPhoneNumber from "../CustomFormikPhoneNumber";
import classNames from "classnames";
import Button from "../shared/atoms/Button";
import { useMerchant, userDataStore, useReferralsStore } from "../../store";
import Lottie from "lottie-react";
import successJson from "../../assets/lottieFiles/success.json";
import { IoCloseCircleOutline } from "react-icons/io5";
import useViewportMeta from "../../customHooks/useViewportMeta";

const ReferralsPage = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseData, setResponseData] = useState(undefined);

  const { referralCreationHandler } = useReferralsStore();
  const { authInfo } = userDataStore();
  const { IdOfUser } = useMerchant();

  const initialValues = {
    referee_business_name: "",
    referee_phone_number: "",
    referee_business_email: "",
    referee_business_poc: "",
    referrer_name: (authInfo?.first_name || "") + " " + (authInfo?.last_name || ""),
    referrer_email: authInfo?.email,
    referrer_phone_number: "",
  };

  useViewportMeta();

  if (responseData) {
    return (
      <div className={styles.container}>
        <div className={styles.lottieContainer}>
          <div
            onClick={() => {
              setResponseData(undefined);
            }}
            className={styles.close}
          >
            <IoCloseCircleOutline size={26} className={styles.closeIcon} />
          </div>
          <Lottie speed={0.5} animationData={successJson} loop={false} style={{ height: "120px", width: "120px" }} />
          <div className={classNames(styles.title, "mt-10")}>Submission Received!</div>
          <div className={classNames(styles.cardDescription, "mt-2")}>Sit back and relax while we get in touch with them</div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.title}>Spread the Word and Earn $50!</div>
      <div className={styles.cardsContainer}>
        {cardsData.map((card) => {
          return (
            <div id={card.id} className={styles.card}>
              <div className={`mb-2 h-14`}>{card.logo}</div>
              <div className={styles.cardTitle}>{card.name}</div>
              <div className={styles.cardDescription}>{card.description}</div>
            </div>
          );
        })}
      </div>
      <Formik
        onSubmit={(values) => {
          const merchantId = IdOfUser || authInfo.id;
          setIsLoading(true);
          submitHandler(values, setIsLoading, referralCreationHandler, merchantId, setResponseData);
        }}
        validationSchema={referralsFormSchema}
        enableReinitialize={true}
        initialValues={initialValues}
      >
        {({ handleSubmit, setFieldValue, errors, touched, values, setFieldTouched }) => {
          return (
            <Form className="w-full flex flex-col justify-start relative">
              <div className={styles.inputsContainer}>
                <div className={styles.intputsTitle}>Who are you referring?</div>
                <div className={classNames(styles.row, "mb-0")}>
                  <div className={styles.oneInput}>
                    <FormikNewTextInput
                      name={`referee_business_name`}
                      placeholder="Business Name"
                      disabled={false}
                      type="text"
                      externalInputStyle={{ fontSize: "14px" }}
                      value={values.referee_business_name}
                    />
                  </div>
                  <div className={styles.oneInput}>
                    <FormikNewTextInput
                      name={`referee_business_poc`}
                      placeholder="Contact Name"
                      disabled={false}
                      type="text"
                      externalInputStyle={{ fontSize: "14px" }}
                      value={values.referee_business_poc}
                    />
                  </div>
                </div>
                <div className={classNames(styles.row, "mt-5")}>
                  <div className={styles.oneInput}>
                    <CustomFormikPhoneNumber
                      disabled={false}
                      name={`referee_phone_number`}
                      placeholder="1 (###) ###-####"
                      type="tel"
                      format="+1 (###) ###-####"
                      onValueChange={(e) => {
                        setFieldValue(`referee_phone_number`, e.formattedValue);
                      }}
                      value={values.contact_number}
                      style={{ fontSize: "14px", minHeight: "43px" }}
                    />
                  </div>
                  <div className={styles.oneInput}>
                    <FormikNewTextInput
                      name={`referee_business_email`}
                      placeholder="Business Email (optional)"
                      disabled={false}
                      type="text"
                      externalInputStyle={{ fontSize: "14px" }}
                      value={values.referee_business_email}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.inputsContainer}>
                <div className={styles.intputsTitle}>Share your details</div>
                <div className={classNames(styles.row, "mb-4")}>
                  <div className={styles.oneInput}>
                    <FormikNewTextInput
                      name={`referrer_name`}
                      placeholder="Name"
                      disabled={false}
                      type="text"
                      externalInputStyle={{ fontSize: "14px" }}
                      value={values.referrer_name}
                    />
                  </div>
                  <div className={styles.oneInput}>
                    <CustomFormikPhoneNumber
                      disabled={false}
                      name={`referrer_phone_number`}
                      placeholder="1 (###) ###-####"
                      type="tel"
                      format="+1 (###) ###-####"
                      onValueChange={(e) => {
                        setFieldValue(`phone`, e.formattedValue);
                      }}
                      value={values.referrer_phone_number}
                      style={{ fontSize: "14px", minHeight: "43px" }}
                    />
                  </div>
                </div>
                <FormikNewTextInput
                  name={`referrer_email`}
                  placeholder="Email"
                  disabled={false}
                  type="text"
                  externalInputStyle={{ fontSize: "14px", marginTop: "6px" }}
                  value={values.referrer_email}
                />
              </div>
              <div className={classNames(styles.buttonCont, { "opacity-70": isLoading })}>
                <Button type="submit" text="Submit & Get Reward" />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ReferralsPage;
