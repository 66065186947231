import { createStore } from "aniuta";

import { toast } from "react-toastify";

import {
  getNotifications,
  readAllNotifications,
  readOneNotification,
  resetNotificationsCount,
} from "../utils/api/notifications";
import * as Sentry from "@sentry/react";
import { useState } from "react";
import axios from "axios";

const useNotifications = createStore({
  name: "useNotifications",
  Store: () => {
    const [notifications, setNotifications] = useState();
    const [isNotificationsLoading, setIsNotificationsLoading] = useState(false);
    const getNotificationsHandler = async (id, queryParams, source, setter) => {
      // setIsNotificationsLoading(true);
      try {
        const { data } = await getNotifications(id, queryParams, source);
        if (!setter) {
          setIsNotificationsLoading(false);
        }
        setNotifications(data);
        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          setIsNotificationsLoading(false);
          toast.error(error?.response?.data?.message);
        } else if (axios.isCancel(error)) {
          console.log("canceled");
        } else {
          setIsNotificationsLoading(false);
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
      }
    };
    const putResetNotificationsCount = async (id) => {
      try {
        const { data } = await resetNotificationsCount(id);
      } catch (error) {
        if (error?.response?.data?.message) {
          console.error(error?.response?.data?.message);
          // toast.error(error?.response?.data?.message);
          console.log("notifications err", error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          // toast.error("Something went wrong! Please try again.");
        }
      } finally {
      }
    };
    const putReadOneNotification = async (id, payload) => {
      try {
        const { data } = await readOneNotification(id, payload);
        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          console.error(error?.response?.data?.message);
          console.log("notifications err", error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          // toast.error("Something went wrong! Please try again.");
        }
      } finally {
      }
    };
    const putReadAllNotifications = async (id) => {
      try {
        const { data } = await readAllNotifications(id);
        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          console.error(error?.response?.data?.message);
          console.log("notifications err", error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          // toast.error("Something went wrong! Please try again.");
        }
      } finally {
      }
    };

    return {
      getNotificationsHandler,
      isNotificationsLoading,
      setIsNotificationsLoading,
      notifications,
      setNotifications,
      putResetNotificationsCount,
      putReadOneNotification,
      putReadAllNotifications,
    };
  },
});

export default useNotifications;
