import React, { useEffect, useState } from "react";
import HomeFilters from "../filters";
import { useHomeDeliveries, useMyStore, userDataStore } from "../../../store";
import ListLoading from "../ListLoading";
import ListEmptyState from "../ListEmptyState";
import LitsItem from "../ListItem";
import classNames from "classnames";
import styles from "../styles/home.module.css";
import ListHeader from "../ListHeader";

import { homeColumns } from "../utils";
import EasyPagination from "../../shared/Pagination/EasyPagination";

const HomePageDeliveries = () => {
  const [columnData, setColumnData] = useState(homeColumns);

  const { authInfo } = userDataStore();
  const {
    homeFiltersDefinitions,
    statusesFilter,
    setStatusesFilter,
    homeDeliveriesHandler,
    storesFilteredArray,
    setStoresFilteredArray,
    homeDeliveriesData,
    isHomeDeliveriesLoading,
    setDateRangeForHomeDeliveries,
  } = useHomeDeliveries();
  const { isSandbox, userId } = useMyStore();

  useEffect(() => {
    if (authInfo?.merchantAccountId) {
      const userMerchantId = userId ?? authInfo.merchantAccountId;
      homeDeliveriesHandler(userMerchantId, { test_mode: isSandbox });
    }
    return () => {};
  }, [authInfo, isSandbox]);

  const renderTableBody = () => {
    if (isHomeDeliveriesLoading) {
      return <ListLoading />;
    } else if (!homeDeliveriesData?.items?.length) {
      return <ListEmptyState />;
    } else return homeDeliveriesData?.items.map((item) => <LitsItem isHome key={item?.id} columns={columnData} data={item} />);
  };

  const onGetPaginationData = async (paginationParams) => {
    const userMerchantId = userId ?? authInfo?.merchantAccountId;

    const retrievedData = await homeDeliveriesHandler(userMerchantId, {
      ending_before: paginationParams?.ending_before,
      starting_after: paginationParams?.starting_after,
      test_mode: isSandbox,
    });
    if (retrievedData === "disablePrevious") {
      return true;
    }
    return false;
  };

  const statusesFilterHandler = async (statuses) => {
    const statusesForApi = statuses.filter((item) => item.checked);
    const userMerchantId = userId ?? authInfo?.merchantAccountId;

    await homeDeliveriesHandler(userMerchantId, {
      status: statusesForApi,
      test_mode: isSandbox,
    });
  };

  const storesFilterHandler = async (storeIds) => {
    const userMerchantId = userId ?? authInfo?.merchantAccountId;
    await homeDeliveriesHandler(userMerchantId, {
      store_ids: storeIds,
      test_mode: isSandbox,
    });
  };
  const dateRangeHandler = async (dateRange) => {
    setDateRangeForHomeDeliveries(dateRange ?? undefined);
    const userMerchantId = userId ?? authInfo?.merchantAccountId;

    await homeDeliveriesHandler(userMerchantId, {
      start_date: dateRange?.start ?? "",
      end_date: dateRange?.end ?? "",
      test_mode: isSandbox,
    });
  };
  return (
    <>
      <HomeFilters
        setStatusesFilter={setStatusesFilter}
        statusesFilter={statusesFilter}
        searchFilterDefinitions={homeFiltersDefinitions}
        isHome
        columnData={columnData}
        setColumnData={setColumnData}
        setStoresFilteredArray={setStoresFilteredArray}
        storesFilteredArray={storesFilteredArray}
        storesFilterHandler={storesFilterHandler}
        statusesFilterHandler={statusesFilterHandler}
        dateRangeHandler={dateRangeHandler}
        listData={homeDeliveriesData}
      />
      <div className={classNames(styles.tableContainer, "scrollbar")}>
        <table className={styles.table}>
          <thead className={styles.tableHeader}>
            <ListHeader columns={columnData} />
          </thead>

          <tbody className={classNames(styles.tableBody, "kata")}>{renderTableBody()}</tbody>
        </table>
      </div>

      <div className={classNames(styles.paginationContainer, "flex items-center justify-start w-full")}>
        <EasyPagination data={homeDeliveriesData} onGetPaginationData={onGetPaginationData} isHome />
      </div>
    </>
  );
};

export default HomePageDeliveries;
