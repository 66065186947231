import classNames from "classnames";
import React, { memo, useEffect, useState } from "react";
import PODTracking from "./PODTracking";
import moment from "moment";
import momenttz from "moment-timezone";

import { isEtaVisible } from "../MapOnTracking/helpers";
import StatusBadgeReusable from "../../shared/common/StatusBadgeReusable";
import {
  badgesForDeliveryStatuses,
  iconHandler,
} from "../../Admin/DeliveriesForAdmin/utils";
import { Loading, MarkerSvg } from "../../../assets/svgs/Svgs";
import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";

import favIcon from "../../../assets/png/burqFavIcon.png";
import defaultDriver from "../../../assets/png/defaultDriver.png";
import styles from "./styles.module.css";
import DriverPhone from "./DriverPhone";
import Badge from "../../shared/atoms/Badge";

const DetailsOnTracking = memo(
  ({ deliveryInfo, logo, branding, brandingColor, isOnBranding }) => {
    const { isdesktop } = useDesktopOrLaptop();
    const splitedPath = window.location.pathname.split("/");
    const isOrderTracking = splitedPath[1] === "orders";

    const [estimatesStep, setEstimatesStep] = useState(0);
    useEffect(() => {
      switch (deliveryInfo?.status) {
        case "delivery_created":
          setEstimatesStep(1);
          break;
        case "enroute_to_return":
          setEstimatesStep(5);
          break;
        case "scheduled":
          setEstimatesStep(11);
          break;
        case "request":
          setEstimatesStep(12);
          break;
        case "driver_not_assigned":
          setEstimatesStep(2);
          break;

        case "driver_assigned":
          setEstimatesStep(3);
          break;

        case "enroute_pickup":
          setEstimatesStep(4);
          break;
        case "arrived_at_pickup":
          setEstimatesStep(5);
          break;
        case "pickup_complete":
          setEstimatesStep(6);
          break;
        case "enroute_dropoff":
          setEstimatesStep(7);
          break;
        case "arrived_at_dropoff":
          setEstimatesStep(8);
          break;
        case "dropoff_complete":
          setEstimatesStep(9);
          break;
        case "delivered":
          setEstimatesStep(10);
          break;
        case "returned":
          setEstimatesStep(10);
          break;

        default:
          setEstimatesStep(0);
          break;
      }
    }, [deliveryInfo]);
    const etaHandler = (status) => {
      const service =
        deliveryInfo?.service?.charAt(0).toUpperCase() +
        deliveryInfo?.service?.slice(1);
      switch (status) {
        case "delivery_created":
          return "";
        case "driver_not_assigned":
          return `Waiting for ${service} to assign a driver`;
        case "driver_assigned":
          return ``;
        case "arrived_at_pickup":
          return `Driver is at the pick-up location`;
        case "enroute_pickup":
          return `Driver is on his way to pick your order.`;
        case "pickup_complete":
          return `Driver has picked up your order.`;
        case "enroute_dropoff":
          return `Driver is heading your way.`;
        case "arrived_at_dropoff":
          return `Arriving Soon`;
          case "dropoff_complete":
          return `Driver is at the drop-off location.`;
        case "enroute_to_return":
          return `Driver is enroute to return`;

        default:
          return `Arriving Soon`;
      }
    };

    const timesHandler = (del) => {
      if (
        estimatesStep !== 10 &&
        estimatesStep !== 11 &&
        estimatesStep !== 12 &&
        moment(deliveryInfo?.dropoff_eta).isBefore(moment())
      ) {
        return "";
      }
      if (estimatesStep === 12) {
        return (
          "Created at " +
          moment(del?.created_at).format("D MMM YYYY, hh:mmA") +
          ", " +
          momenttz.tz(momenttz.tz.guess()).format("z")
        );
      } else if (estimatesStep === 11) {
        if (del?.pickup_at && del?.pickup_at !== "Invalid date") {
          return (
            <>
              at{" "}
              {moment(del?.pickup_at).format("D MMM YYYY, hh:mmA") ===
              "Invalid date"
                ? ""
                : moment(del?.pickup_at).format("D MMM YYYY, hh:mmA") +
                  ", " +
                  momenttz.tz(momenttz.tz.guess()).format("z")}
            </>
          );
        } else if (del?.dropoff_at) {
          return (
            <>
              {" "}
              at{" "}
              {moment(del?.dropoff_at).format("D MMM YYYY, hh:mmA") +
                ", " +
                momenttz.tz(momenttz.tz.guess()).format("z")}
            </>
          );
        }
      } else if (
        isEtaVisible(del?.status) &&
        moment().to(moment(del?.dropoff_eta)) !== "Invalid date"
      ) {
        return moment().to(moment(del?.dropoff_eta)) + "";
      } else if (
        del?.status_history &&
        moment(
          del?.status_history[del?.status_history?.length - 1]?.time
        ).format("MMM D, YYYY, h:mma") !== "Invalid date"
      ) {
        return (
          " at " +
          moment(
            del?.status_history[del?.status_history?.length - 1]?.time
          ).format("MMM D, YYYY, h:mma")
        );
      }
    };

    if (!deliveryInfo) {
      return (
        <div className={`w-full flex flex-col h-full overflow-y-auto kata`}>
          <Loading color="#ECF0F1" className="h-6 w-6 animate-spin" />
        </div>
      );
    }

    if (isOrderTracking && Object.keys(deliveryInfo).length === 0) {
      return (
        <div className={`w-full flex flex-col h-full overflow-y-auto kata`}>
          <div className="w-full flex flex-col items-center justify-center mt-20">
            <div className={styles.eta}>
              Your delivery has not began <br />
              please come back later
            </div>
            <div
              className={classNames(
                styles.estimatedTime,
                "mt-7 mb-7 w-full flex items-center justify-center"
              )}
            >
              <Badge
                label="Un-initiated"
                type={"info"}
                externalClassName={styles.badge}
              />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={`w-full flex flex-col h-full overflow-y-auto kata`}>
        {/* <div className={classNames(styles.absoluteCenter)}> dasdadas</div> */}
        <div>
          {logo ? (
            <div
              className={classNames(
                `flex items-center pl-4 pb-2 pt-2 h-24 justify-start`,
                styles.imageContainer
              )}
            >
              <img className={styles.image} src={logo} alt="providerLogo" />
            </div>
          ) : isOnBranding ? (
            <div
              className={classNames(
                `flex items-center pl-4 pb-2 pt-2 h-20 justify-start`,
                styles.imageContainer
              )}
            >
              <div
                className={classNames(
                  styles.logoBranding,
                  "flex justify-center items-center flex-col"
                )}
              >
                <div>Logo</div>
                {/* <div className={styles.ratio}>128 x 128</div> */}
              </div>
            </div>
          ) : (
            <div
              className={classNames(
                `flex items-center pl-4 pb-2 pt-2 h-24 justify-start`,
                styles.imageContainer
              )}
            >
              <img className={styles.image} src={favIcon} alt="providerLogo" />
            </div>
          )}
        </div>
        <div className="p-10 px-8">
          <div className="w-full flex flex-col items-center justify-center">
            <div className={styles.eta}>
              {estimatesStep === 11 && `Scheduled `}
              {/* {estimatesStep === 11 &&
              deliveryInfo?.pickup_at &&
              deliveryInfo?.pickup_at !== "Invalid date" ? (
                <>
                  {moment(deliveryInfo?.pickup_at).format(
                    "D MMM YYYY, hh:mmA"
                  ) === "Invalid date"
                    ? ""
                    : moment(deliveryInfo?.pickup_at).format(
                        "D MMM YYYY, hh:mmA"
                      ) +
                      ", " +
                      momenttz.tz(momenttz.tz.guess()).format("z")}
                </>
              ) : (
                deliveryInfo?.dropoff_at && (
                  <>
                    {moment(deliveryInfo?.dropoff_at).format(
                      "D MMM YYYY, hh:mmA"
                    ) +
                      ", " +
                      momenttz.tz(momenttz.tz.guess()).format("z")}
                  </>
                )
              )} */}
              {(estimatesStep === 0 || estimatesStep === 10) &&
              estimatesStep !== 11
                ? deliveryInfo?.status_history && "Completed"
                : estimatesStep !== 11 &&
                  estimatesStep !== 12 &&
                  moment(deliveryInfo?.dropoff_eta).isBefore(moment())
                ? etaHandler(deliveryInfo.status)
                : "Arrives"}{" "}
              {/* {isEtaVisible(deliveryInfo?.status)
                ? moment().to(moment(deliveryInfo?.dropoff_eta)) !==
                    "Invalid date" &&
                  moment().to(moment(deliveryInfo?.dropoff_eta))
                : estimatesStep !== 11 &&
                  deliveryInfo?.status_history &&
                  moment(
                    deliveryInfo?.status_history[
                      deliveryInfo?.status_history?.length - 1
                    ]?.time
                  ).format("MMM D, YYYY, h:mma") !== "Invalid date" &&
                  moment(
                    deliveryInfo?.status_history[
                      deliveryInfo?.status_history?.length - 1
                    ]?.time
                  ).format("MMM D, YYYY, h:mma")} */}
              {timesHandler(deliveryInfo)}
            </div>
            <div
              className={classNames(
                styles.estimatedTime,
                "mt-7 mb-7 w-full flex items-center justify-center"
              )}
            >
              {deliveryInfo?.status && (
                <StatusBadgeReusable
                  hideIcon={false}
                  leftIcon={iconHandler(deliveryInfo?.status, isdesktop)}
                  showDefault={true}
                  badgesObject={badgesForDeliveryStatuses}
                  defaultType="neutral"
                  status={deliveryInfo?.status}
                  externalClassName={styles.badge}
                  extraIconClasses={styles.statusClass}
                />
              )}
            </div>
          </div>

          <div className={styles.fullWidthLine}>
            {estimatesStep !== 11 && (
              <div
                style={{
                  width: `${estimatesStep * 10}%`,
                  backgroundColor: brandingColor,
                }}
                className={styles.insideFullLine}
              ></div>
            )}
          </div>

          <div
            className={classNames(
              "w-full flex flex-col items-center justify-start p-4 ",
              styles.delInfoContainer
            )}
          >
            <div className={classNames(`w-full`, styles.delIdCont)}>
              <div className={styles.idTitle}>Delivery ID</div>
              {isOnBranding ? (
                <div
                  className={classNames(
                    "w-full rounded-full h-6 mt-2",
                    styles.maskingColor
                  )}
                ></div>
              ) : (
                <div className={styles.deliveryId}>{deliveryInfo?.id}</div>
              )}
            </div>

            <div
              className={classNames(
                "kata pl-12 w-full mt-5 overflow-hidden",
                styles.borderBottom,
                styles.addresses
              )}
            >
              <div className="relative">
                <div
                  className={classNames(
                    styles.circle,
                    `flex items-center justify-center`,
                    styles.pickupBigCircleColor
                  )}
                >
                  <div
                    className={classNames(
                      styles.smallCircle,
                      styles.pickupSmallCircleColor
                    )}
                  ></div>
                </div>
              </div>
              <div className={styles.stopsTitles}>Pickup Address</div>
              {isOnBranding ? (
                <div
                  className={classNames(
                    "w-full rounded-full h-6",
                    styles.maskingColor
                  )}
                ></div>
              ) : (
                <div className={styles.stopsAddresses}>
                  {deliveryInfo?.pickup_address || ""}
                </div>
              )}
              {deliveryInfo?.dropoffs?.map((item, index) => {
                return (
                  <div className={classNames(`w-full`)} key={index}>
                    <div
                      className={classNames(
                        styles.stopsTitles,
                        "mt-6 relative"
                      )}
                    >
                      {/* Stop {index + 1} */}
                      Dropoff Address
                      <div
                        className={classNames(
                          styles.circle,
                          `flex items-center justify-center`,
                          styles.dropoffBigCircleColor
                        )}
                      >
                        <MarkerSvg
                          width={isdesktop ? "23" : "33"}
                          height={isdesktop ? "23" : "33"}
                          viewBox={isdesktop ? "0 0 25 25" : "0 0 24 24"}
                          color={brandingColor}
                        />
                      </div>
                      <div className={styles.line}></div>
                    </div>
                    {isOnBranding ? (
                      <div
                        className={classNames(
                          "w-full rounded-full h-6 ",
                          styles.maskingColor
                        )}
                      ></div>
                    ) : (
                      <div className={styles.stopsAddresses}>
                        {item.dropoff_address}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>

            <div
              className={classNames(
                "mt-7 mb-0 w-full flex items-center justify-between",
                deliveryInfo?.courier_name && styles.driverCont
              )}
            >
              <div className={classNames("flex items-center")}>
                {isOnBranding ? (
                  <div className={styles.default}>
                    <img
                      className={classNames(
                        "h-14 rounded-full mr-4",
                        styles.courierImg
                      )}
                      src={defaultDriver}
                      alt="courierImg"
                    />
                  </div>
                ) : (
                  deliveryInfo?.courier_img && (
                    <div>
                      <img
                        className={classNames(
                          "h-14 rounded-full mr-4",
                          styles.courierImg
                        )}
                        src={deliveryInfo?.courier_img}
                        alt="courierImg"
                      />
                    </div>
                  )
                )}

                {isOnBranding ? (
                  <div>
                    <div className={styles.stopsTitles}>Driver</div>
                    <div className={styles.stopsAddresses}>Alex Wills</div>
                    <div className={styles.stopsTitles}>Truck</div>
                  </div>
                ) : (
                  deliveryInfo?.courier_name && (
                    <div>
                      <div className={styles.stopsTitles}>Driver</div>
                      <div className={styles.stopsAddresses}>
                        {deliveryInfo?.courier_name}
                      </div>
                      <div className={styles.stopsTitles}>
                        {deliveryInfo?.vehicle ?? "Car"}
                      </div>
                    </div>
                  )
                )}
              </div>

              {deliveryInfo?.courier_name &&
                deliveryInfo?.courier_phone_number_for_customer && (
                  <DriverPhone
                    brandingColor={brandingColor}
                    phoneNumber={
                      deliveryInfo?.courier_phone_number_for_customer
                    }
                  />
                )}
            </div>
          </div>
          <div className="w-full mt-4 ">
            {deliveryInfo?.proof_of_delivery?.length > 0 && (
              <PODTracking
                title={"Proof of delivery"}
                images={deliveryInfo.proof_of_delivery}
              />
            )}
            {deliveryInfo?.signature && (
              <PODTracking
                title={"Signature"}
                images={[deliveryInfo.signature]}
              />
            )}
          </div>
          <div className="w-full mt-10 h-96">
            {branding ? (
              <img
                className="w-full object-cover overflow-hidden rounded-xl h-full"
                src={`${branding}`}
                alt=""
              />
            ) : isOnBranding ? (
              <div
                className={classNames(
                  styles.branding,
                  "flex justify-center items-center flex-col"
                )}
              >
                <div
                  className={classNames(styles.yourImageHere, "flex flex-col")}
                >
                  <span>Your background image</span>
                  <span>will appear here</span>
                </div>
                <div className={styles.ratio}>16 x 9</div>
              </div>
            ) : (
              <></>
            )}
            {/* {branding ? (
            <img
              className="w-full object-cover overflow-hidden rounded-xl"
              src={`${branding}`}
              alt=""
            />
          ) : (
            <img
              className="w-full object-cover overflow-hidden rounded-xl"
              src={dummyBg}
              alt=""
            />
          )} */}
          </div>
        </div>
      </div>
    );
  }
);

export default DetailsOnTracking;
