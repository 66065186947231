import React from "react";
import styles from "../styles/providerDetails/providerIndustries.module.css";
import { PARTNER_INDUSTRY_OPTIONS } from "../../utils";

const ProviderIndustries = ({ categories }) => {
  console.log("categories", categories);
  return (
    <div className={styles.itemsContainer}>
      {categories.map((category) => (
        <div key={category} className={styles.item}>
          {PARTNER_INDUSTRY_OPTIONS[category]
            ? PARTNER_INDUSTRY_OPTIONS[category]
            : category.replace(/_/g, " ")}
          {/* {category === "laundry_dry_cleaning"
            ? "Laundry / Dry Cleaning"
            : category.replace(/_/g, " ")} */}
        </div>
      ))}
    </div>
  );
};

export default ProviderIndustries;
