import { GiSandsOfTime } from "react-icons/gi";
import { TiCancel } from "react-icons/ti";
import { FaSquareCheck, FaRegCircleStop } from "react-icons/fa6";
import { MdFlashOn } from "react-icons/md";
import { IoCubeOutline } from "react-icons/io5";
import { LuCalendarCheck2 } from "react-icons/lu";
import ReturnedIcon from "./Svgs";

export const badgesForDeliveryStatuses = {
  delivery_created: { type: "warning", label: "Delivery Created" },
  driver_assigned: { type: "warning", label: "Driver Assigned" },
  driver_not_assigned: { type: "warning", label: "Assigning Driver" },
  pending: { type: "warning", label: "Pending" },

  enroute_pickup: { type: "pickup", label: "Enroute to Pick-up" },
  arrived_at_pickup: { type: "pickup", label: "Arrived at Pick-up" },
  pickup_complete: { type: "pickup", label: "Pick-up Complete" },
  pickup: { type: "pickup", label: "Pickup" },

  enroute_dropoff: { type: "dropoff", label: "Enroute to Drop-off" },
  arrived_at_dropoff: { type: "dropoff", label: "Arrived at Drop-off" },
  dropoff_complete: { type: "dropoff", label: "Drop-off Completed" },
  dropoff: { type: "dropoff", label: "Dropoff" },

  delivered: { type: "success", label: "Delivered" },
  completed: { type: "success", label: "Completed" },

  provider_canceled: { type: "error", label: "Provider Canceled" },
  customer_canceled: { type: "error", label: "Merchant Canceled" },
  burq_canceled: { type: "error", label: "Burq Canceled" },
  customer_cancelled: { type: "error", label: "Merchant Canceled" },
  expired: { type: "error", label: "Expired" },
  failed: { type: "error", label: "Failed" },
  disputed: { type: "error", label: "Disputed" },
  canceled: { type: "error", label: "Canceled" },

  contacting_provider: { type: "primary", label: "Contacting Provider" },
  scheduled: { type: "primary", label: "Scheduled" },
  returned: { type: "primary", label: "Returned" },
  enroute_to_return: { type: "primary", label: "Enroute to Return" },

  request: { type: "neutral", label: "Not Initiated" },
};

export const iconHandler = (status, isdesktop) => {
  switch (status) {
    case "delivery_created":
    case "driver_assigned":
    case "driver_not_assigned":
      return <GiSandsOfTime className="-mr-1" size={isdesktop ? 16 : 30} />;
    case "scheduled":
      return <LuCalendarCheck2 className="-mr-1" size={isdesktop ? 16 : 30} />;
    case "returned":
    case "enroute_to_return":
      return <ReturnedIcon className="-mr-1" size={isdesktop ? 16 : 30} />;

    case "canceled":
    case "provider_canceled":
    case "customer_canceled":
    case "burq_canceled":
    case "expired":
    case "failed":
    case "disputed":
      return <TiCancel className="-mr-1" size={isdesktop ? 20 : 30} />;

    case "delivered":
      return <FaSquareCheck size={isdesktop ? 17 : 30} />;
    case "pickup":
    case "enroute_pickup":
    case "arrived_at_pickup":
    case "pickup_complete":
    case "contacting_provider":
      return <MdFlashOn className="-mr-0.5" size={isdesktop ? 17 : 30} />;

    case "dropoff":
    case "enroute_dropoff":
    case "arrived_at_dropoff":
    case "dropoff_complete":
      return <IoCubeOutline className="-mr-1" size={isdesktop ? 17 : 30} />;

    case "request":
      return <FaRegCircleStop className="-mr-1" size={isdesktop ? 16 : 30} />;

    default:
      return null;
  }
};
