import React, { useState, useMemo, useEffect, useRef, memo, useCallback } from "react";
import { useDesktopOrLaptop } from "../../../../../services/useDesktopOrLaptop";
import { useTheme } from "../../../../../store";
import { LineChart, Line, Tooltip, ResponsiveContainer } from "recharts";
import { debounce } from "lodash";
import { getValueComparesion } from "../../../utils";
import DataComparisonSection from "./DataComparisonSection";
import CardFooter from "./CardFooter";
import StatusDropdown from "./StatusDropdown";

const initPayload = {
  data: [],
  percentageChangeDirection: "",
  percentageChange: "",
  leftLegend: "",
  rightLegend: "",
  primaryRangeTotal: "",
  secondaryRangeTotal: "",
};

const CustomizedPrimaryDot = (props) => {
  const { cx, cy } = props;
  const { Colors } = useTheme();

  if (cy) {
    return <circle cx={cx} cy={cy} r={3} stroke={Colors.bluePrimary} strokeWidth={3} fill={Colors.white} />;
  }
  return <></>;
};

const ChartSection = ({ payload, primaryLineLable }) => {
  const { mdfontsize } = useDesktopOrLaptop();
  const { Colors } = useTheme();
  const [lineChart, setLineChart] = useState(initPayload);
  const [hoveredState, setHoveredState] = useState({
    isHover: false,
    hoverPayload: {},
  });

  const hoverData = useRef({
    isHover: false,
    hoverPayload: {},
  });

  const styles = useMemo(() => {
    return {
      lineChartContainer: {
        display: "flex",
        flexDirection: "column",
        marginBottom: "10px",
        width: "100%",
        height: "100%",
        padding: "12px",
        boxSizing: "border-box",
      },
      customTooltip: {
        display: "flex",
        backgorund: Colors.bgGrey,
      },
      noRecord: { margin: "0px", marginTop: 20, color: Colors.fontDark, fontSize: mdfontsize },
    };
  }, [Colors, mdfontsize]);

  useEffect(() => {
    setLineChart(payload);
  }, [payload]);

  // const debounced = debounce((payload) => {
  //   setHoveredState(payload);
  // }, 100);

  const debounced = useCallback(
    debounce((payload) => {
      setHoveredState(payload);
    }, 200),
    []
  );

  const customTooltip = (props) => {
    const { active, payload } = props;
    if (active && payload && payload.length) {
      const hoveredData = {
        isHover: true,
        hoverPayload: {
          primaryDataKey: "",
          primaryDataValue: 0,
          // secondaryDataKey: "",
          // secondaryDataValue: 0,
        },
      };
      payload.forEach((coordinate, i) => {
        if (coordinate.name === "primaryDataValue" && coordinate?.payload?.primaryDataKey) {
          hoveredData.hoverPayload.primaryDataKey = coordinate.payload.primaryDataKey;
          hoveredData.hoverPayload.primaryDataValue = coordinate.value;
        }
        if (coordinate.name === "secondaryDataValue" && coordinate.payload.secondaryDataKey) {
          hoveredData.hoverPayload.secondaryDataKey = coordinate.payload.secondaryDataKey;
          hoveredData.hoverPayload.secondaryDataValue = coordinate.value;
        }
      });
      hoveredData.hoverPayload = {
        ...hoveredData.hoverPayload,
        ...getValueComparesion(hoveredData.hoverPayload.primaryDataValue, hoveredData.hoverPayload.secondaryDataValue),
      };
      hoverData.current = hoveredData;
      debounced(hoverData.current);
    } else {
      hoverData.current = { isHover: false, hoverPayload: {} };
      debounced(hoverData.current);
    }

    return null;
  };

  return (
    <div style={{ ...styles.lineChartContainer }}>
      <StatusDropdown />
      <DataComparisonSection lineChart={lineChart} hoveredState={hoveredState} />

      <div style={{ height: "100%" }}>
        {lineChart?.data?.length ? (
          <ResponsiveContainer width={"100%"} height={180}>
            <LineChart
              data={lineChart.data}
              margin={{
                top: 30,
                right: 10,
                left: 10,
                bottom: 10,
              }}
            >
              <Tooltip active={false} cursor={false} content={customTooltip} />
              <Line
                dataKey={primaryLineLable}
                stroke={Colors.bluePrimary}
                activeDot={<CustomizedPrimaryDot />}
                strokeWidth={2}
                dot={lineChart?.showPrimaryLineDot}
              />
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <div
            style={{
              height: "150px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p style={styles.noRecord}>No record found</p>
          </div>
        )}
      </div>
      <CardFooter data={lineChart} />
    </div>
  );
};

export default memo(ChartSection);
