import React from "react";
import styles from "../../../styles/DeliveryFailureLogs/deliveryAPILogsEmptyState.module.css";

const DeliveryAPILogsEmptyState = () => {
  return (
    <div className={styles.container}>
      No API information available for this delivery.
    </div>
  );
};

export default DeliveryAPILogsEmptyState;
