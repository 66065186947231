import React, { useState } from "react";

import classNames from "classnames";
import styles from "./styles.module.css";
import { ImagePreviewPopup } from "../../shared/common/ImagePreviewPopup";
import { useProofDownloader } from "../../../store";
import { DeleteIcon } from "../../Orders/Svgs";

function ImagesContainerOnSidebar({ images, id, isSignature, hideDownload = false, onDeleteImage = null }) {
  const [imageUrl, setImageUrl] = useState();
  const { onDownloadImages } = useProofDownloader();

  return (
    <div className={classNames("flex flex-col w-full", styles.cont)}>
      <ImagePreviewPopup imageUrl={imageUrl} isOpen={!!imageUrl} onClose={() => setImageUrl()} />
      <div className="flex items-start justify-between">
        <div className={classNames("noScroll flex items-start  overflow-x-auto")}>
          {images?.map((url, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  setImageUrl(url);
                }}
                className={classNames("cursor-pointer mr-7", styles.imagesContainer)}
              >
                {onDeleteImage && (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      onDeleteImage();
                    }}
                    className={styles.deleteImage}
                  >
                    <DeleteIcon />
                  </div>
                )}
                <img className={classNames("object-cover overflow-hidden rounded-xl", styles.images)} alt="" src={url} />
              </div>
            );
          })}
        </div>
        {!hideDownload && (
          <button
            className={classNames(styles.download, "whitespace-nowrap underline")}
            onClick={() => onDownloadImages(id, isSignature)}
          >
            Download Images
          </button>
        )}
      </div>
    </div>
  );
}

export default ImagesContainerOnSidebar;
