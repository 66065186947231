import React, { useEffect, useMemo, useState } from "react";
import { useAnalyticsStore, useFilter, useMyStore, userDataStore, useTheme } from "../../../../store";
import { timePickerOptions } from "../../utils";
import DatePickerSection from "./DatePickerSection";
import SelectComp from "../../../SelectComp/SelectComp";
import DropdownReusable from "../../../shared/common/DropdownReusable";
import classNames from "classnames";
import { CheckIcon } from "../../../../assets/svgs/Svgs";

import classStyles from "./index.module.css";
import OutsideClickHandler from "react-outside-click-handler";
import { BiCheckboxChecked, BiCheckbox } from "react-icons/bi";
import { useFlag } from "@unleash/proxy-client-react";
import { IoIosArrowDown } from "react-icons/io";

const InsightsSectionHeader = () => {
  const {
    datePayload,
    setDatePayload,
    compareDatePayload,
    setCompareDatePayload,
    selectedProviderArray,
    setSelectedProviderArray,
    isDateSelected,
    setIsDateSelected,
  } = useAnalyticsStore();
  const { getMerchantEnabledProvidersHandler } = useFilter();
  const { userId, isSandbox } = useMyStore();
  const { authInfo } = userDataStore();

  const [isCompareOpened, setIsCompareOpened] = useState(false);

  const isProvidersAnalyticsEnabled = useFlag("isProvidersAnalyticsEnabled");

  const [isDropdownOpened, setIsDropdownOpened] = useState(false);

  const [providers, setProviders] = useState();

  const getProviders = async (merchantId) => {
    const res = await getMerchantEnabledProvidersHandler(merchantId, { test_mode: isSandbox });
    if (res) {
      setProviders(res.providers_data);
    }
  };

  const filterArrayHandler = (item) => {
    let _selectedProviderArray = [...selectedProviderArray];
    if (_selectedProviderArray[0] === "all_providers") {
      _selectedProviderArray.splice(0, 1);
    }

    if (selectedProviderArray?.includes(item)) {
      _selectedProviderArray = _selectedProviderArray.filter((e) => e !== item);
      if (_selectedProviderArray.length === 0) {
        _selectedProviderArray.push("all_providers");
      }

      setSelectedProviderArray(_selectedProviderArray);
    } else {
      // if (selectedProviderArray && selectedProviderArray.length === 4) {
      //   return;
      // }
      _selectedProviderArray.push(item);
      setSelectedProviderArray(_selectedProviderArray);
    }
  };

  useEffect(() => {
    if (isProvidersAnalyticsEnabled && (authInfo || userId)) {
      getProviders(userId ?? authInfo?.merchantAccountId);
    }
  }, [userId, authInfo, isProvidersAnalyticsEnabled, isSandbox]);

  return (
    <div className={classStyles.container}>
      <h1 className={classStyles.headingText}>Insights</h1>
      <div className="flex justify-end items-center">
        {isProvidersAnalyticsEnabled && <div className={classStyles.compByText}>Compared by</div>}
        {isProvidersAnalyticsEnabled && (
          <div className={classStyles.cooseCont}>
            <div
              onClick={() => {
                setIsCompareOpened((prev) => !prev);
              }}
              className={classNames(classStyles.oneSide, "cursor-pointer", classStyles.activeButton)}
              // className={classNames(classStyles.oneSide, "cursor-pointer", {
              //   [classStyles.activeButton]: isDateSelected,
              // })}
            >
              {isDateSelected ? "Date Range" : "Providers"} <IoIosArrowDown className="ml-1" />
            </div>
            {isCompareOpened && (
              <OutsideClickHandler
                onOutsideClick={() => {
                  setIsCompareOpened(false);
                }}
              >
                <div className={classNames(classStyles.dropdownContainer, classStyles.minusLeft20)}>
                  <div
                    onClick={() => {
                      if (!isDateSelected) {
                        setIsDateSelected(true);
                        setSelectedProviderArray(undefined);
                      } else if (isDateSelected) {
                        setIsDateSelected(false);
                        setIsDropdownOpened(!isDropdownOpened);
                        if (!selectedProviderArray) {
                          setSelectedProviderArray(["all_providers"]);
                        }
                      }
                      setIsCompareOpened(false);
                    }}
                    className={classStyles.copareItem}
                  >
                    {" "}
                    {!isDateSelected ? "Date Range" : "Providers"}
                  </div>
                </div>
              </OutsideClickHandler>
            )}
          </div>
        )}
        <div className={classNames("flex items-center ml-2")}>
          {!isDateSelected && (
            <OutsideClickHandler
              onOutsideClick={() => {
                setIsDropdownOpened(false);
              }}
            >
              <div className="flex items-center">
                <div className="relative">
                  <div
                    onClick={() => {
                      setIsDateSelected(false);
                      setIsDropdownOpened(!isDropdownOpened);
                      if (!selectedProviderArray) {
                        setSelectedProviderArray(["all_providers"]);
                      }
                    }}
                    className={classStyles.providersDropdown}
                  >
                    Select Providers
                    <IoIosArrowDown className="ml-4" />
                  </div>

                  {isDropdownOpened && (
                    <div className={classNames(classStyles.dropdownContainer, classStyles.left0)}>
                      <div
                        className={classNames({
                          // [classStyles.disabledDropdown]: selectedProviderArray && selectedProviderArray.length === 4,
                        })}
                      >
                        <div
                          onClick={() => {
                            if (selectedProviderArray?.length > 0) {
                              setSelectedProviderArray(["all_providers"]);
                            }
                            // if(selectedProviderArray[0])
                            // setSelectedProviderArray(["all_providers"]);
                          }}
                          className={classNames(classStyles.oneItem)}
                        >
                          <div className={classStyles.icons}>
                            <BiCheckboxChecked size={22} color="#0062FF" />
                            {/* {selectedProviderArray[0] === "all_providers" ? (
                            ) : (
                              <BiCheckbox size={22} />
                            )} */}
                          </div>
                          <div className={classNames(classStyles.oneItemInDropdown)}>All</div>
                        </div>
                        {providers &&
                          providers.map((item, index) => {
                            return (
                              <div
                                onClick={() => {
                                  filterArrayHandler(item.display_name);
                                }}
                                key={index}
                                className={classStyles.oneItem}
                              >
                                <div className={classStyles.icons}>
                                  {selectedProviderArray?.find((provider) => provider === item.display_name) ? (
                                    <BiCheckboxChecked size={22} color="#0062FF" />
                                  ) : (
                                    <BiCheckbox size={22} />
                                  )}
                                </div>
                                <div className={classNames(classStyles.oneItemInDropdown)}>{item.display_name}</div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  )}
                </div>
                <p className={classStyles.conparedToText}>Date Ragnge</p>
              </div>
            </OutsideClickHandler>
          )}
          <DatePickerSection optionsList={timePickerOptions} datePayload={datePayload} setDatePayload={setDatePayload} />
          {!selectedProviderArray && (
            <>
              <p className={classStyles.conparedToText}>Compared to</p>
              <DatePickerSection
                optionsList={timePickerOptions}
                datePayload={compareDatePayload}
                setDatePayload={setCompareDatePayload}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default InsightsSectionHeader;
