import React from "react";
import Badge from "../atoms/Badge";

const StatusBadgeReusable = ({
  lookupKey = null,
  status = null,
  badgesObject,
  showDefault = false,
  defaultType,
  externalClassName,
  hideIcon = true,
  leftIcon = null,
  extraIconClasses = "",
  uppercase = false,
}) => {
  let badgeData = badgesObject[lookupKey || status] ?? { type: "", label: "" };
  if (badgeData || showDefault) {
    return (
      <Badge
        label={badgeData?.label || status}
        type={badgeData?.type || defaultType || "primary"}
        externalClassName={externalClassName}
        hideIcon={hideIcon}
        leftIcon={leftIcon}
        extraIconClasses={extraIconClasses}
        uppercase={uppercase}
      />
    );
  } else return null;
};

export default StatusBadgeReusable;
