import moment from "moment";
import React, { useMemo } from "react";
// import Colors from "../assets/Colors";
import { useTheme } from "../../../../store";
import SingleItemForModalResponsive from "./SingleItemForModalResponsive";
import Badge from "../../../shared/atoms/Badge";

function SingleAddressForModalResponsive({
  index,
  address,
  name,
  bussinessNmae,
  phoneNumber,
  note,
  length,
  smfontsize,
  mdfontsize,
  item,
  status,
  dropoff,
}) {
  const { Colors } = useTheme();

  const styles = useMemo(() => {
    return {
      circle: {
        position: "absolute",
        height: "30px",
        width: "30px",
        borderRadius: "100%",
        top: "15px",
        left: "0px",
      },
      pickupDropoff: {
        fontFamily: "Poppins",
        fontSize: "16px",
        color: Colors.fontGreyLight,
        fontWeight: "500",
        marginBottom: "2px",
        marginTop: "20px",
        display: "flex",
        flexDirection: "row",
        // justifyContent: "space-between",
      },
      singleAddress: {
        position: "relative",
        minHeight: "34%",
        padding: "0px 0px 0px 30px",
        marginLeft: "20px",
        width: "100%",
        marginRight: "30px",
      },
      addresses: {
        fontFamily: "Poppins",
        fontSize: "16px",
        color: Colors.fontDark,
        fontWeight: "500",
      },
      itemHeaderText: {
        width: "25%",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "30px",
        // lineHeight: "18px",
        color: Colors.fontGreyLight,
      },
    };
  }, [Colors]);

  return (
    <div
      style={{
        flexDirection: "row",
        display: "flex",
      }}
    >
      <div style={styles.singleAddress}>
        {index !== length && (
          <div
            style={{
              width: "1px",
              height: "100%",
              backgroundColor: Colors.fontDark,
              position: "absolute",
              top: "15px",
              left: "15px",
            }}
          ></div>
        )}

        <div
          style={{
            ...styles.circle,
            ...{
              backgroundColor:
                index !== 0 ? Colors.greenDark : Colors.yellowDark,
            },
          }}
        ></div>
        <div
          style={{
            ...styles.pickupDropoff,
            ...{
              marginLeft: "40px",
            },
          }}
        >
          <div
            style={{
              flexDirection: "column",
              display: "flex",
              // width: "99px",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "25px",
                // lineHeight: "18px",
                color: Colors.bluePrimary,
              }}
            >
              Stop {index + 1}
              {item.contactless_requested && dropoff && (
                <div className="h-6 mt-7 -ml-14">
                  <Badge
                    externalClassName=" mb-3"
                    label="Contactless"
                    type="warning"
                  />
                </div>
              )}
            </div>
            {!index &&
            (!item.pickup_eta ||
              status === "delivered" ||
              status === "provider_canceled" ||
              status === "customer_canceled" ||
              status === "burq_canceled" ||
              {
                /* status === "returned" ||
          status === "expired" ||
          status === "failed" */
              }) ? null : index &&
              (!item.dropoff_eta ||
                status === "delivered" ||
                status === "provider_canceled" ||
                status === "customer_canceled" ||
                status === "burq_canceled" ||
                {
                  /* status === "returned" ||
            status === "expired" ||
            status === "failed" */
                }) ? null : (
              <div>
                <div
                  style={{
                    marginTop: "11px",
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "25px",
                    width: "198px",
                    color: Colors.fontGreyLight,
                  }}
                >
                  {!index ? "Pick-up ETA" : "Drop-off ETA"}
                </div>
                <div
                  style={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: "25px",
                    // lineHeight: "15px",
                    color: Colors.fontDark,
                  }}
                >
                  {!index
                    ? moment(item.pickup_eta).format("D MMM YYYY") !==
                      "Invalid date"
                      ? moment(item.pickup_eta).format("D MMM YYYY")
                      : "---"
                    : moment(item.dropoff_eta).format("D MMM YYYY") !==
                      "Invalid date"
                    ? moment(item.dropoff_eta).format("D MMM YYYY")
                    : "---"}
                </div>
              </div>
            )}
          </div>
          <div
            style={{
              flexDirection: "column",
              display: "flex",
              marginLeft: "50px",
            }}
          >
            <div
              style={{
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "25px",
                // lineHeight: "18px",
                color: Colors.fontGreyLight,
              }}
            >
              Address
            </div>
            <div
              style={{
                ...styles.addresses,
                ...{ fontSize: smfontsize, marginBottom: "30px" },
              }}
            >
              {address}
            </div>
            {!index && !item.pickup_eta ? null : index &&
              !item.dropoff_eta ? null : (
              <div className="mb-4">
                <div
                  style={{
                    fontSize: "25px",
                    color: Colors.fontGreyLight,
                  }}
                >
                  {!index ? "Pick-up ETA" : "Drop-off ETA"}
                </div>
                <div
                  style={{
                    fontSize: "25px",
                    color: Colors.fontDark,
                  }}
                >
                  {!index
                    ? moment(item.pickup_eta).format("D MMM YYYY h:mm A") !==
                      "Invalid date"
                      ? moment(item.pickup_eta).format("D MMM YYYY h:mm A")
                      : "---"
                    : moment(item.dropoff_eta).format("D MMM YYYY h:mm A") !==
                      "Invalid date"
                    ? moment(item.dropoff_eta).format("D MMM YYYY h:mm A")
                    : "---"}
                </div>
              </div>
            )}
          </div>
        </div>

        <div
          style={{
            backgroundColor: Colors.detailsBg,
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            borderRadius: "40px 40px 40px 40px",
            width: "100%",
            marginLeft: "40px",
            paddingLeft: "50px",
            paddingBottom: "50px",
          }}
        >
          <div style={{ flexDirection: "row", display: "flex" }}>
            <div
              style={{
                marginTop: "20px",
                justifyContent: "space-between",
                flexDirection: "column",
              }}
              className="flex w-full items-center"
            >
              <div
                style={{
                  ...styles.pickupDropoff,
                  ...{
                    textAlign: "start",
                    width: "100%",
                    fontSize: "30px",
                  },
                }}
              >
                {index !== 0 ? "Merchant name" : "User Name"}
                <span style={{ color: Colors.fontDark, marginLeft: "10px" }}>
                  {name}
                </span>
              </div>
              {/* <div
                style={{
                  ...styles.pickupDropoff,
                  ...{
                    textAlign: "start",
                    width: "100%",
                    fontSize: "30px",
                  },
                }}
              >
                Business Name:
                <span style={{ color: Colors.fontDark, marginLeft: "10px" }}>
                  {bussinessNmae}
                </span>
              </div> */}
              <div
                style={{
                  ...styles.pickupDropoff,
                  ...{
                    textAlign: "start",
                    width: "100%",
                    fontSize: "30px",
                  },
                }}
              >
                Phone #:
                <span style={{ color: Colors.fontDark, marginLeft: "10px" }}>
                  +{phoneNumber}
                </span>
              </div>
            </div>
            {/* <div
              style={{ marginTop: "20px", flexDirection: "column" }}
              className="flex w-full"
            >
              <div
                style={{
                  ...styles.addresses,
                  ...{
                    textAlign: "start",
                    // width: "100%",
                    fontSize: "30px",
                    color: Colors.fontDark,
                  },
                }}
              >
                {name}
              </div>
              <div
                style={{
                  ...styles.addresses,
                  ...{
                    textAlign: "start",
                    // width: "100%",
                    fontSize: "30px",
                  },
                }}
              >
                {bussinessNmae}
              </div>
              <div
                style={{
                  ...styles.addresses,
                  ...{
                    textAlign: "start",
                    // width: "100%",
                    fontSize: "30px",
                  },
                }}
              >
                {phoneNumber}
              </div>
            </div> */}
          </div>
          <div
            style={{
              ...styles.pickupDropoff,
              ...{ marginTop: "32px", fontSize: "30px" },
            }}
          >
            Drop off notes
          </div>

          <div
            style={{
              minHeight: "124px",
              width: "90%",
              backgroundColor: Colors.white,
              borderRadius: "8px",
              padding: "10px",
              marginBottom: "10px",
            }}
          >
            <div style={{ ...styles.addresses, ...{ fontSize: "30px" } }}>
              {note}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingleAddressForModalResponsive;
