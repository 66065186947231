import React, { useEffect, useState } from "react";

import classNames from "classnames";

import useViewportMeta from "../../customHooks/useViewportMeta";

import styles from "./styles/home.module.css";

import TabButtonComp from "../TabButtonComp/TabButtonComp";
import HomePageDeliveries from "./DeliveriesListing/HomePageDeliveries";
import DisputedDeliveries from "./DisputedListing/DisputedDeliveries";
import { userDataStore, useStoreAndChild } from "../../store";

const Home = () => {
  const [activeTab, setActiveTab] = useState("home");
  const { getAllStoresByUser } = useStoreAndChild();

  const { authInfo } = userDataStore();

  const storeListHandler = async (accountId) => {
    await getAllStoresByUser(accountId);
    return;
  };

  useEffect(() => {
    if (authInfo?.merchantAccountId) {
      storeListHandler(authInfo.merchantAccountId);
    }
  }, [authInfo]);

  useViewportMeta();

  return (
    <div className={classNames("overflow-x-auto", styles.container)}>
      <div className={styles.header}>
        <h1 className={styles.heading}>Delivery Incidents</h1>
      </div>

      <div className="flex items-center my-4">
        <TabButtonComp
          bottomLineWidth={"30px"}
          extraButtonClasses={styles.tabButtonCont}
          isActive={activeTab === "home"}
          onClick={() => {
            setActiveTab("home");
          }}
          title={"All"}
          extraBottomLineClasses={styles.tabBottomLine}
        />
        <TabButtonComp
          extraButtonClasses={styles.tabButtonCont}
          isActive={activeTab === "disputed"}
          onClick={() => {
            setActiveTab("disputed");
          }}
          title={"Disputed"}
        />
      </div>

      {activeTab === "home" ? <HomePageDeliveries /> : <DisputedDeliveries />}
    </div>
  );
};

export default Home;
