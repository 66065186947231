import React, { useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";

import { stripePromise, stripeReturnUrl } from "../../runTimeConst";
import CheckoutForm from "./CheckoutForm";
import { useOnboardingStore, useTheme } from "../../store";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";

import styles from "./styles/paymentInfo.module.css";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.
const PaymentInfo = ({ invitationId }) => {
  const { Colors } = useTheme();
  const { mdfontsize } = useDesktopOrLaptop();
  const { invitationDetails, setStep } = useOnboardingStore();

  const appearance = {
    theme: "stripe",
  };

  const options = {
    clientSecret: invitationDetails?.stripe_setup_intent_client_secret,
    appearance,
  };

  useEffect(() => {
    if (invitationDetails.payment_method_added) {
      setStep(2);
    }
  }, [invitationDetails, setStep]);

  const paymentElementOptions = {
    layout: "tabs",
    defaultValues: {
      billingDetails: {
        email: invitationDetails?.connected_account?.email,
        name: invitationDetails?.connected_account?.name,
      },
    },
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>Set up a payment method</div>
      <div className={styles.titleHelper}>You’ll use this to pay for deliveries.</div>

      {invitationDetails?.stripe_setup_intent_client_secret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm returnUrl={stripeReturnUrl(invitationId)} options={paymentElementOptions} />
        </Elements>
      )}
    </div>
  );
};

export default PaymentInfo;
