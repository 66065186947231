import React, { useCallback, useEffect, useRef, useState } from "react";
import { useMyStore, useTheme } from "../../../../../store";
import { debounce } from "lodash";
import { MdSearch } from "react-icons/md";
import styles from "../styles/Filters/searchFilter.module.css";
import classNames from "classnames";

const SearchFilter = ({
  id,
  show,
  placeholder = "Search",
  filterState,
  setFilterState,
  activeFiltersIds,
  setActiveFiltersIds,
}) => {
  const [query, setQuery] = useState("");
  const { Colors } = useTheme();
  const { isSandbox } = useMyStore();
  const queryRef = useRef(null);

  useEffect(() => {
    if (queryRef.current) {
      queryRef.current.focus();
    }
  }, [queryRef.current]);

  const debounceFn = useCallback(
    debounce((search) => {
      setFilterState(search);
    }, 800),
    [activeFiltersIds]
  );

  useEffect(() => {
    setActiveFiltersIds([]);
  }, [isSandbox]);

  useEffect(() => {
    if (filterState && !activeFiltersIds.includes(id)) {
      setActiveFiltersIds([...activeFiltersIds, id]);
    }
    if (!filterState && activeFiltersIds.includes(id)) {
      setActiveFiltersIds([...activeFiltersIds.filter((f) => f !== id)]);
    }
  }, [filterState]);

  useEffect(() => {
    if (queryRef.current.value !== filterState) {
      setQuery("");
    }
  }, [filterState]);

  const onQueryChangeHandler = (value) => {
    debounceFn(value);
  };

  return (
    <div className={classNames(styles.container, { [styles.show]: show })}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        className={styles.form}
      >
        <MdSearch color={Colors.fontGreyLight} size={19} />
        <input
          ref={queryRef}
          type="text"
          placeholder={placeholder}
          value={query}
          onChange={(event) => {
            setQuery(event.target.value);
            onQueryChangeHandler(event.target.value);
          }}
          className={styles.input}
        />
      </form>
    </div>
  );
};

export default SearchFilter;
