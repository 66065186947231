import ColumnEditor from "./ColumnEditor";
import StatusFilters from "./StatusFilters";
import SearchFilters from "./SearchFilters";
import DateRangeComponent from "./DateRangeComponent";
import StoresMultiselectFilter from "./StoresMultiselectFilter";

const HomeFilters = ({
  columnData,
  setColumnData,
  isHome,
  searchFilterDefinitions,
  statusesFilter,
  setStatusesFilter,
  storesFilteredArray,
  setStoresFilteredArray,
  storesFilterHandler,
  statusesFilterHandler,
  dateRangeHandler,
  listData,
}) => {
  return (
    <div className="w-full pb-4 flex justify-between items-end xl:items-center xl:flex-row flex-col gap-2 xl:gap-0">
      <div className="flex items-end xl:items-center mt-0 xl:flex-row gap-2 xl:gap-0 flex-col">
        <div style={{ minWidth: "130px", marginRight: "16px" }}>
          <SearchFilters menuItems={searchFilterDefinitions} title="Search By" icon="search" />
        </div>
        <StoresMultiselectFilter
          storesFilterHandler={storesFilterHandler}
          storesFilteredArray={storesFilteredArray}
          setStoresFilteredArray={setStoresFilteredArray}
        />
        <StatusFilters
          setStatusesFilter={setStatusesFilter}
          statusesFilter={statusesFilter}
          isHome={isHome}
          statusesApiFilterHandler={statusesFilterHandler}
          listData={listData}
        />
        <DateRangeComponent dateRangeHandler={dateRangeHandler} />
      </div>
      <ColumnEditor columnData={columnData} setColumnData={setColumnData} />
    </div>
  );
};

export default HomeFilters;
