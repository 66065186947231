import React, { memo, useState } from "react";
import { toast } from "react-toastify";
import { TrashIcon } from "../../../assets/svgs/Svgs";
import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";
import {
  useTheme,
  useAccount,
  userDataStore,
  useMerchant,
} from "../../../store";
import { MdOutlineEdit } from "react-icons/md";

import AreYouSurePopup from "../../shared/common/AreYouSurePopup";
import { constructName } from "../../../utils/helpers";
import { ROLES, rolesUi } from "../../AccountSettings/RolesSidebar/helpers";
import { useFlag } from "@unleash/proxy-client-react";

const Member = memo(({ user, setIsSidePanelOpened }) => {
  const isUserRolesEnabled = useFlag("userRoles");

  const { Colors } = useTheme();
  const { deleteUserFromAccount, getUsersOfAccount, setEditUserData } =
    useAccount();
  const [isRemoveModalVisible, setIsRemoveModalVisible] = useState(false);
  const { mdfontsize, isdesktop } = useDesktopOrLaptop();

  const { authInfo } = userDataStore();
  const { isAdmin } = useMerchant();

  const isEditAndDeleteIconsVisible = (employeeRoles) => {
    let emplRole = employeeRoles?.user_roles[0]?.role_name;
    let thisUserRole = authInfo?.user_roles[0]?.role_name;

    if (isAdmin || thisUserRole === ROLES.ADMIN || !isUserRolesEnabled) {
      return true;
    }

    if (
      thisUserRole === "store_admin" &&
      (emplRole === ROLES.STORE_EMPLOEE || emplRole === "store_operator_admin")
    ) {
      return true;
    }

    if (
      thisUserRole === ROLES.STORE_MANAGER &&
      emplRole === ROLES.STORE_EMPLOEE
    ) {
      let roles = employeeRoles?.user_roles;
      let thisUserRoles = authInfo?.user_roles;
      for (let i = 0; i < roles.length; i++) {
        const hasStoreManagerAccess = thisUserRoles.some((item) => {
          return item?.resource_id === roles[i].resource_id;
        });

        if (hasStoreManagerAccess) {
          return true;
        } else {
          break;
        }
      }
    }

    return false;
  };

  return (
    <div
      style={{
        borderWidth: "1px",
        borderRadius: "10px",
        borderColor: Colors.borderPrimary,
        padding: isdesktop ? "14px 0px" : "20px 0px",
        fontSize: mdfontsize,
      }}
      className="grid grid-cols-12 gap-1 mt-6  "
    >
      <AreYouSurePopup
        isOpen={isRemoveModalVisible}
        onClose={() => {
          setIsRemoveModalVisible(false);
        }}
        onYes={async () => {
          deleteUserFromAccount(user.id).then((res) => {
            if (res.status === 200) {
              toast.success("User is removed successfully");
            }
            setIsRemoveModalVisible(false);
            getUsersOfAccount();
          });
        }}
      />
      <div className="col-start-1 col-span-4 flex items-center">
        <div
          className="w-full truncate pl-4"
          style={{ color: Colors.fontGreyLight }}
        >
          {user.email}
        </div>
      </div>
      <div
        style={{ color: Colors.fontGreyLight }}
        className="col-start-5 col-span-3 flex items-center capitalize"
      >
        {constructName(user.first_name, user.last_name)}
      </div>
      <div
        style={{ color: Colors.fontGreyLight }}
        className="col-start-8 col-span-2 flex items-center capitalize"
      >
        {rolesUi[user?.user_roles[0]?.role_name] ?? "Admin"}
      </div>
      <div
        style={{ color: Colors.fontGreyLight }}
        className="capitalize col-start-10 col-span-2 flex items-center"
      >
        {user.status === "pending_invitation_acceptance"
          ? "Pending"
          : user.status}
      </div>

      {isEditAndDeleteIconsVisible(user) && (
        <div className="col-start-12 col-span-1 flex items-center pl-2 pr-4">
          <MdOutlineEdit
            color={Colors.bluePrimary}
            size={24}
            style={{ marginRight: "10px", cursor: "pointer" }}
            onClick={() => {
              setEditUserData(user);
              setIsSidePanelOpened(true);
            }}
          />
          <button
            onClick={async () => {
              setIsRemoveModalVisible(true);
            }}
            type="button"
            className="outline-none pointer"
          >
            <TrashIcon />
          </button>
        </div>
      )}
    </div>
  );
});

export default Member;
