import React, { useState, useEffect, useMemo } from "react";
import {
  useTheme,
  useSingleItemStore,
  useMyStore,
  useClickStore,
} from "../../../store";
import SingleAddressForModalNew from "./common/SingleAddressForModalNew";
import SingleAddressForModalResponsive from "./common/SingleAddressForModalResponsive";
import axios from "axios";
import { IoCloseSharp } from "react-icons/io5";
import DriverContain from "./common/DriverDetails";
import { baseUrl, statusesObject } from "../../../runTimeConst";
import SingleItemForModalResponsive from "./common/SingleItemForModalResponsive";
import DeliveryStatusTimeline from "../../shared/DeliveryStatusTimeline";
import cancel from "../../../assets/png/statuses/cancel.png";
import contactingProvider from "../../../assets/png/statuses/contactingProvider.png";
import created from "../../../assets/png/statuses/created.png";
import delivered from "../../../assets/png/statuses/delivered.png";
import dropoff from "../../../assets/png/statuses/dropoff.png";
import pickupPng from "../../../assets/png/statuses/pickup.png";
import scheduled from "../../../assets/png/statuses/scheduled.png";
import returnPng from "../../../assets/png/statuses/return.png";
import moment from "moment";
import ImagesContainer from "../../shared/ImagesContainer";
import SignatureContainer from "../../shared/SignatureContainer";
import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";
import HeaderTabs from "../../shared/common/HeaderTabs";
import style from "../styles/detailsResponsive.module.css";
import DeliveryFailureLogs from "./DeliveryDetailsCollapsable/DeliveryFailureLogs";
import ActionButtons from "./DeliveryDetailsCollapsable/DeliverySummary/ActionButtons";

import AmountDetails from "./DeliveryDetailsCollapsable/DeliverySummary/AmountDetails";
const order = ["Delivery Fee", "Burq Fee", "Tip"];

const TABS = {
  SUMMARY: "Summary",
  DEVELOPER: "Logs",
};

export default function DeliveryDetailsResponsive({
  setIsDetails,
  setisCancelVisible,
  isAdmin,
  socketMessage,
}) {
  const [activeTab, setActiveTab] = useState(TABS.SUMMARY);

  const { isdesktop } = useDesktopOrLaptop();
  const { Colors, themeChanger } = useTheme();
  const { setIsCalendarVisible } = useClickStore();
  const smfontsize = isdesktop ? "14px" : "22px";
  const mdfontsize = isdesktop ? "18px" : "26px";
  const { item } = useSingleItemStore();

  const [statusSocket, setStatusSocket] = useState(item.status);

  const [statusesHystory, setStatusesHystory] = useState(item.events);

  useEffect(() => {
    if (socketMessage?.id === item?.id) {
      setStatusSocket(socketMessage?.status);
      setStatusesHystory(socketMessage?.status_history);
    }
  }, [socketMessage]);

  const [userEmail, setUserEmail] = useState();

  // const getUserEmailHandler = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${baseUrl}delivery/user?userId=${item.auth0UserId}`
  //     );
  //     setUserEmail(response.data.email);
  //   } catch (err) {
  //     return err;
  //   }
  // };
  // getUserEmailHandler();
  const styles = useMemo(() => {
    return {
      container: {
        fontFamily: "Poppins",
        fontSize: smfontsize,
        paddingBottom: isdesktop ? "40px" : "10px",
        backgroundColor: Colors.white,
      },
      providerContainer: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
        padding: "15px 66px 15px 25px",
        borderRadius: "8px",
      },
      bottomTextGrey: {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "30px",
        lineHeight: "18px",
        color: Colors.fontGreyLight,
        marginTop: "60px",
      },
      isdelivered: {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "20px",
        lineHeight: "12px",
        textAlign: "center",
        color: Colors.fontGreyLight,
        marginTop: "5px",
      },
      statusCont: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      },
      statusLine: {
        width: "100px",
        height: "10px",
        borderRadius: "2.5px",
        marginRight: "6px",
      },
      bottomTextBlack: {
        color: Colors.fontBlack,
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "30px",
      },
      itemHeaderText: {
        width: "24%",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "30px",
        // lineHeight: "18px",
        color: Colors.fontGreyLight,
      },
    };
  }, [themeChanger, isdesktop]);

  const returnDriverStatus = () => {
    if (
      statusSocket === "delivery_created" ||
      statusSocket === "driver_not_assigned"
    ) {
      return "Assigning Driver";
    } else return "Not available";
  };

  const tabs = [
    { label: TABS.SUMMARY, value: TABS.SUMMARY },
    {
      label: TABS.DEVELOPER,
      value: TABS.DEVELOPER,
      isHidden: statusSocket !== "failed",
    },
  ];

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        zIndex: 90,
        overflowY: "auto",
        backgroundColor: Colors.bgGrey,
      }}
    >
      <div>
        <div style={{ backgroundColor: Colors.bgGrey }}>
          <button
            onClick={() => {
              setIsDetails(false);
              setIsCalendarVisible(true);
            }}
            style={{
              fontSize: "50px",
              color: "white",
              marginTop: "30px",
              marginLeft: "30px",
            }}
          >
            <IoCloseSharp color={Colors.fontDark} size={50} />
          </button>
          <div
            style={{
              // backgroundColor: Colors.white,
              marginTop: "0px",
              marginBottom: "0px",
              padding: "10px 20px",
              overflow: "hidden",
              // height: "250px",
              paddingBottom: "30px",
            }}
          >
            <div
              // onClick={() => {
              //   setIsDetails(true);
              // }}
              style={{
                // height: "94px",
                width: "100%",
                flexDirection: "row",
                display: "flex",
                outline: "none",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", flexDirection: "row" }}>
                {statusSocket === "pending" ||
                statusSocket === "delivery_created" ||
                statusSocket === "driver_assigned" ||
                statusSocket === "driver_not_assigned" ? (
                  <img
                    src={created}
                    alt="logo"
                    style={{ width: "54px", height: "54px", marginTop: "25px" }}
                  />
                ) : statusSocket === "canceled" ||
                  statusSocket === "provider_canceled" ||
                  statusSocket == "customer_canceled" ||
                  statusSocket === "burq_canceled" ||
                  statusSocket === "expired" ||
                  statusSocket === "failed" ||
                  statusSocket === "disputed" ? (
                  <img
                    src={cancel}
                    alt="logo"
                    style={{ width: "54px", height: "54px", marginTop: "25px" }}
                  />
                ) : statusSocket === "delivered" ? (
                  <img
                    src={delivered}
                    alt="logo"
                    style={{ width: "54px", height: "54px", marginTop: "25px" }}
                  />
                ) : statusSocket === "pickup" ||
                  statusSocket === "enroute_pickup" ||
                  statusSocket === "arrived_at_pickup" ||
                  statusSocket === "pickup_complete" ? (
                  <img
                    src={pickupPng}
                    alt="logo"
                    style={{ width: "54px", height: "54px", marginTop: "25px" }}
                  />
                ) : statusSocket === "dropoff" ||
                  statusSocket === "enroute_dropoff" ||
                  statusSocket === "arrived_at_dropoff" ||
                  statusSocket === "dropoff_complete" ? (
                  <img
                    src={dropoff}
                    alt="logo"
                    style={{ width: "54px", height: "54px", marginTop: "25px" }}
                  />
                ) : statusSocket === "Paid order" ||
                  statusSocket === "returned" ? (
                  <img
                    src={returnPng}
                    alt="logo"
                    style={{ width: "54px", height: "54px", marginTop: "25px" }}
                  />
                ) : statusSocket === "contacting_provider" ? (
                  <img
                    src={contactingProvider}
                    alt="logo"
                    style={{ width: "54px", height: "54px", marginTop: "25px" }}
                  />
                ) : (
                  <img
                    src={scheduled}
                    alt="logo"
                    style={{ width: "54px", height: "54px", marginTop: "25px" }}
                  />
                )}
                <div
                  style={{
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      marginTop: "22.5px",
                      paddingRight: "34px",
                      justifyContent: "space-between",
                      minWidth: "300px",
                      marginLeft: "35px",
                      textAlign: "start",
                    }}
                  >
                    <div
                      style={{
                        flexDirection: "column",
                        color: Colors.white,
                      }}
                    >
                      <div
                        style={{
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: "600",
                          fontSize: "30px",
                          textAlign: "start",
                          color: Colors.fontBlack,
                        }}
                      >
                        To
                      </div>
                      <div
                        style={{
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: "600",
                          fontSize: "30px",
                          marginTop: "30px",
                          color: Colors.fontBlack,
                        }}
                      >
                        Drop off
                      </div>
                      <div
                        style={{
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: "600",
                          fontSize: "30px",
                          marginTop: "30px",
                          color: Colors.fontBlack,
                        }}
                      >
                        Driver
                      </div>
                    </div>
                    <div
                      style={{
                        flexDirection: "column",
                        marginLeft: smfontsize,
                        textAlign: "start",
                      }}
                    >
                      <div
                        style={{
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontSize: "30px",
                          width: "500px",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          color: Colors.fontDark,
                          fontWeight: "300",
                        }}
                      >
                        {item.dropoffs.map((dropoff, index) => {
                          if (item.dropoffs.length === index + 1) {
                            return dropoff.dropoff_name;
                          } else {
                            return dropoff.dropoff_name + ", ";
                          }
                        })}
                      </div>
                      <div
                        style={{
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontSize: "30px",
                          marginTop: "30px",
                          color: Colors.fontDark,
                          fontWeight: "300",
                        }}
                      >
                        {item?.dropoffs[item.dropoffs.length - 1]
                          ?.dropoff_address_street || "Not Available"}
                      </div>
                      <div
                        style={{
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontSize: "30px",
                          marginTop: "30px",
                          color: Colors.fontDark,
                          fontWeight: "300",
                        }}
                      >
                        {item.courier_name || returnDriverStatus()}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "36px",
                      marginTop: "30px",
                    }}
                  >
                    <div
                      style={{
                        height: "15px",
                        width: "15px",
                        borderRadius: "10px",
                        backgroundColor:
                          statusSocket === "completed"
                            ? " bg-green-50"
                            : statusSocket === "pending" ||
                              statusSocket === "delivery_created" ||
                              statusSocket === "driver_assigned" ||
                              statusSocket === "driver_not_assigned"
                            ? "#FF974A"
                            : statusSocket === "canceled" ||
                              statusSocket === "provider_canceled" ||
                              statusSocket == "customer_canceled" ||
                              statusSocket === "burq_canceled" ||
                              statusSocket === "expired" ||
                              statusSocket === "failed" ||
                              statusSocket === "disputed"
                            ? "#FC5A5A"
                            : statusSocket === "delivered"
                            ? "#3DD598"
                            : statusSocket === "pickup" ||
                              statusSocket === "enroute_pickup" ||
                              statusSocket === "arrived_at_pickup" ||
                              statusSocket === "pickup_complete"
                            ? "#50B5FF"
                            : statusSocket === "dropoff" ||
                              statusSocket === "enroute_dropoff" ||
                              statusSocket === "arrived_at_dropoff" ||
                              statusSocket === "dropoff_complete"
                            ? "#A461D8"
                            : statusSocket === "Paid order" ||
                              statusSocket === "contacting_provider" ||
                              statusSocket === "scheduled" ||
                              statusSocket === "returned"
                            ? "#0062FF"
                            : "#92929D",
                      }}
                    ></div>
                    <div
                      style={{
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "30px",
                        textAlign: "center",
                        marginLeft: "10px",
                        color:
                          statusSocket === "completed"
                            ? " bg-green-50"
                            : statusSocket === "pending" ||
                              statusSocket === "delivery_created" ||
                              statusSocket === "driver_assigned" ||
                              statusSocket === "driver_not_assigned"
                            ? "#FF974A"
                            : statusSocket === "canceled" ||
                              statusSocket === "provider_canceled" ||
                              statusSocket == "customer_canceled" ||
                              statusSocket === "burq_canceled" ||
                              statusSocket === "expired" ||
                              statusSocket === "failed" ||
                              statusSocket === "disputed"
                            ? "#FC5A5A"
                            : statusSocket === "delivered"
                            ? "#3DD598"
                            : statusSocket === "pickup" ||
                              statusSocket === "enroute_pickup" ||
                              statusSocket === "arrived_at_pickup" ||
                              statusSocket === "pickup_complete"
                            ? "#50B5FF"
                            : statusSocket === "dropoff" ||
                              statusSocket === "enroute_dropoff" ||
                              statusSocket === "arrived_at_dropoff" ||
                              statusSocket === "dropoff_complete"
                            ? "#A461D8"
                            : statusSocket === "Paid order" ||
                              statusSocket === "contacting_provider" ||
                              statusSocket === "scheduled" ||
                              statusSocket === "returned"
                            ? "#0062FF"
                            : "#92929D",
                      }}
                    >
                      {statusesObject[statusSocket]}
                      {/* {statusSocket === "delivery_created"
                        ? "Delivery Created"
                        : statusSocket === "driver_assigned"
                        ? "Driver Assigned"
                        : statusSocket === "driver_not_assigned"
                        ? "Assigning Driver"
                        : statusSocket === "enroute_pickup"
                        ? "Enroute to Pick-up"
                        : statusSocket === "arrived_at_pickup"
                        ? "Arrived at Pick-up"
                        : statusSocket === "pickup_complete"
                        ? "Pick-up Complete"
                        : statusSocket === "enroute_dropoff"
                        ? "Enroute to Drop-off"
                        : statusSocket === "arrived_at_dropoff"
                        ? "Arrived at Drop-off"
                        : statusSocket === "dropoff_complete"
                        ? "Drop-off Completed"
                        : statusSocket === "delivered"
                        ? "Delivered"
                        : statusSocket === "contacting_provider"
                        ? "Contacting Provider"
                        : statusSocket === "scheduled"
                        ? "Scheduled"
                        : statusSocket === "provider_canceled"
                        ? "Provider Canceled"
                        : statusSocket === "customer_canceled"
                        ? "Merchant Canceled"
                        : statusSocket === "burq_canceled"
                        ? "Burq Canceled"
                        : statusSocket === "expired"
                        ? "Expired"
                        : statusSocket === "failed"
                        ? "Failed"
                        : statusSocket === "disputed"
                        ? "Disputed"
                        : statusSocket === "pending"
                        ? "Pending"
                        : statusSocket === "pickup"
                        ? "Pickup"
                        : statusSocket === "dropoff"
                        ? "Dropoff"
                        : statusSocket === "canceled"
                        ? "Canceled"
                        : statusSocket === "returned"
                        ? "Returned"
                        : statusSocket === "request"
                        ? "Not Initiated"
                        : statusSocket} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="px-16 my-4">
          <HeaderTabs
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            tabs={tabs}
          />
        </div>
        <div
          style={{
            width: "95%",
            margin: "0px auto",
            backgroundColor: Colors.white,
            borderTopRightRadius: "60px",
            borderTopLeftRadius: "60px",
            padding: "0px 6px",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "30px 0px",
            }}
          >
            <div
              style={{
                height: "10px",
                width: "250px",
                backgroundColor: Colors.borderPrimary,
                borderRadius: "30px",
              }}
            ></div>
          </div>
          {activeTab === TABS.DEVELOPER && statusSocket === "failed" ? (
            <DeliveryFailureLogs deliveryId={item?.id} />
          ) : (
            <>
              <div className="flex flex-col px-7 pb-10 mt-4 justify-start w-full">
                <div>
                  <div
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "30px",
                      color: Colors.fontDark,
                      fontWeight: "400",
                      flexDirection: "row",
                      display: "flex",
                      // width: "80%",
                      alignSelf: "center",
                      marginBottom: "20px",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="flex items-center">
                      <div style={{ color: Colors.fontGreyLight }}>
                        Burq ID:
                      </div>
                      <div
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "30px",
                          color: Colors.fontGreyLight,
                          fontWeight: "400",
                          marginLeft: "30px",
                        }}
                      >
                        {item.id}
                      </div>
                    </div>
                    {/* {item.payment_status && (
                      <div
                        style={{
                          fontSize: smfontsize,
                          fontWeight: "500",
                          padding: "6px 16px",
                          backgroundColor:
                            item.payment_status === "unpaid"
                              ? "rgb(241, 109, 109)"
                              : item.payment_status === "paid"
                              ? "#3DD598"
                              : item.payment_status === "refunded" && "#0062FF",
                          borderRadius: "10px",
                          color: "white",
                        }}
                        className="capitalize"
                      >
                        {item.payment_status}
                      </div>
                    )} */}
                  </div>
                  {item.pickup_at && item.pickup_at !== "Invalid date" ? (
                    <div
                      style={{
                        fontSize: "30px",
                        color: Colors.fontDark,
                        fontWeight: "400",
                        flexDirection: "row",
                        display: "flex",
                        // width: "80%",
                        alignSelf: "center",
                        marginBottom: "20px",
                      }}
                    >
                      <div style={{ color: Colors.fontGreyLight }}>
                        Scheduled Pick-up:
                      </div>
                      <div
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "30px",
                          color: Colors.fontGreyLight,
                          fontWeight: "400",
                          marginLeft: "30px",
                        }}
                      >
                        {moment(item.pickup_at).format("D MMM YYYY, hh:mmA")}
                      </div>
                    </div>
                  ) : (
                    item.dropoff_at && (
                      <div
                        style={{
                          fontSize: "30px",
                          color: Colors.fontDark,
                          fontWeight: "400",
                          flexDirection: "row",
                          display: "flex",
                          // width: "80%",
                          alignSelf: "center",
                          marginBottom: "20px",
                        }}
                      >
                        <div style={{ color: Colors.fontGreyLight }}>
                          Scheduled Drop-up:
                        </div>
                        <div
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "30px",
                            color: Colors.fontGreyLight,
                            fontWeight: "400",
                            marginLeft: "30px",
                          }}
                        >
                          {moment(item.dropoff_at).format("D MMM YYYY, hh:mmA")}
                        </div>
                      </div>
                    )
                  )}
                </div>
                <div style={{ flexDirection: "row", display: "flex" }}>
                  {userEmail && (
                    <div
                      style={{
                        fontSize: smfontsize,
                        color: Colors.fontGreyLight,
                      }}
                      className="font-semibold mt-7"
                    >
                      User's Email: {userEmail}
                    </div>
                  )}
                  <div style={{ width: "100%" }}>
                    {isdesktop ? (
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          margin: "0px 0px 0px 0px",
                          padding: "0px 0px 0px 0px",
                        }}
                      >
                        <SingleAddressForModalNew
                          index={0}
                          styles={styles}
                          address={item.pickup_address}
                          bussinessNmae="Burq"
                          name={item.pickup_name}
                          note={item?.pickup_notes}
                          phoneNumber={item?.pickup_phone_number}
                          smfontsize={smfontsize}
                          mdfontsize={mdfontsize}
                          item={item}
                        />

                        {item.dropoffs?.map((dropOff, index) => {
                          return (
                            <SingleAddressForModalNew
                              item={item}
                              length={item.dropoffs.length}
                              key={index}
                              index={index + 1}
                              styles={styles}
                              address={dropOff.dropoff_address}
                              bussinessNmae="Burq"
                              name={dropOff.dropoff_name}
                              note={dropOff.dropoff_notes}
                              phoneNumber={dropOff.dropoff_phone_number}
                              smfontsize={smfontsize}
                              mdfontsize={mdfontsize}
                            />
                          );
                        })}
                      </div>
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          // height: "100%",
                          margin: "0px 0px 0px 0px",
                          padding: "0px 0px 0px 0px",
                        }}
                      >
                        <SingleAddressForModalResponsive
                          index={0}
                          styles={styles}
                          address={item.pickup_address}
                          bussinessNmae="Burq"
                          name={item.pickup_name}
                          note={item?.pickup_notes}
                          phoneNumber={item?.pickup_phone_number}
                          smfontsize={smfontsize}
                          mdfontsize={mdfontsize}
                          item={item}
                          status={statusSocket}
                        />
                        {item.dropoffs?.map((dropOff, index) => {
                          return (
                            <SingleAddressForModalResponsive
                              item={item}
                              length={item.dropoffs.length}
                              key={index}
                              index={index + 1}
                              styles={styles}
                              address={dropOff.dropoff_address}
                              bussinessNmae="Burq"
                              name={dropOff.dropoff_name}
                              note={dropOff.dropoff_notes}
                              phoneNumber={dropOff.dropoff_phone_number}
                              smfontsize={smfontsize}
                              mdfontsize={mdfontsize}
                              status={statusSocket}
                              dropoff
                            />
                          );
                        })}
                      </div>
                    )}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "91%",
                        marginLeft: "86px",
                        marginTop: "20px",
                        backgroundColor: Colors.detailsBg,
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                        borderRadius: "32px",
                        padding: "50px 0px 0px 50px",
                      }}
                    >
                      <div
                        style={{
                          // width: "70%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginBottom: "10px",
                        }}
                      >
                        <div style={styles.itemHeaderText}>Item #</div>
                        <div style={styles.itemHeaderText}>Item Name</div>
                        <div style={styles.itemHeaderText}>Quantity</div>
                        <div style={styles.itemHeaderText}>Size</div>
                      </div>
                      <div className="kata">
                        {item?.items?.map((i, index) => {
                          return (
                            <SingleItemForModalResponsive
                              name={i.name}
                              quantity={i.quantity}
                              size={i.size}
                              index={index}
                              key={index}
                              length={item.items.length}
                            />
                          );
                        })}
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "20px",
                          marginTop: "10px",
                          // backgroundColor: "red",
                        }}
                      >
                        <div
                          style={{
                            fontFamily: "Poppins",
                            fontSize: mdfontsize,
                            color: Colors.fontDark,
                            fontWeight: "500",
                          }}
                        >
                          Order value:
                        </div>
                        <div
                          style={{
                            fontFamily: "Poppins",
                            fontSize: mdfontsize,
                            color: Colors.fontDark,
                            fontWeight: "500",
                            marginLeft: "10px",
                          }}
                        >
                          {item.order_value
                            ? "$" + (item.order_value / 100).toFixed(2)
                            : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  paddingLeft: "110px",
                  paddingRight: "20px",
                }}
              >
                <ActionButtons item={item} />

                {/* <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    marginTop: "25px",
                  }}
                >
                  {statusesHystory?.length > 0 && (
                    <>
                      <div
                        style={{
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: mdfontsize,
                          lineHeight: "18px",
                          color: Colors.fontDark,
                          width: "100%",
                          backgroundColor: Colors.whiteBlue,
                          // height: "31px",
                          alignItems: "center",
                          display: "flex",
                          borderRadius: "5px 5px 0px 0px",
                          padding: "22px",
                        }}
                      >
                        Statuses
                      </div>
                      <DeliveryStatusTimeline
                        status={statusSocket}
                        statusesHystory={statusesHystory}
                        smfontsize={smfontsize}
                        mdfontsize={mdfontsize}
                        isdesktop={isdesktop}
                      />
                    </>
                  )}
                  {item?.cancellation_reason && (
                    <>
                      <div
                        style={{
                          fontFamily: "Poppins",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: mdfontsize,
                          lineHeight: "18px",
                          color: Colors.fontDark,
                          width: "100%",
                          backgroundColor: Colors.whiteBlue,
                          // height: "31px",
                          alignItems: "center",
                          display: "flex",
                          borderRadius: "5px 5px 0px 0px",
                          padding: "22px",
                          marginTop: "24px",
                        }}
                      >
                        Cancellation Reason
                      </div>
                      <div
                        className="kata"
                        style={{
                          width: "100%",
                          backgroundColor: Colors.whitestBlue,
                          padding: isdesktop ? "30px" : "50px",
                          maxHeight: "530px",
                          overflowY: "scroll",
                          fontSize: mdfontsize,
                        }}
                      >
                        {item.cancellation_reason || ""}
                      </div>
                    </>
                  )}

                  <a
                    target="_blank"
                    className="hover-blue"
                    // target={isdesktop ? "_blank" : ""}
                    rel="noreferrer"
                    href={`${process.env.REACT_APP_TRACKING_URL}/track/${item.id}`}
                    style={{
                      width: "100%",
                      height: "76px",
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                      borderRadius: "14px",
                      marginTop: "14px",
                      fontFamily: "Poppins",
                      fontWeight: "600",
                      fontSize: "30px",
                      textAlign: "center",
                      borderWidth: "4px",
                      color: Colors.fontGreyLight,
                      borderColor: Colors.borderPrimary,
                    }}
                  >
                    Track
                  </a>
                </div> */}
                {item?.proof_of_delivery?.length > 0 && (
                  <ImagesContainer
                    smfontsize={smfontsize}
                    isdesktop={isdesktop}
                    images={item.proof_of_delivery}
                    id={item.id}
                  />
                )}
                {item?.signature && (
                  <SignatureContainer image={item.signature} id={item.id} />
                )}

                <AmountDetails item={item} />
              </div>
              <DriverContain
                isdesktop={isdesktop}
                item={item}
                setCancelVisible={setisCancelVisible}
                statusSocket={statusSocket}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
