import React, { useEffect, useState } from "react";
import { useMyStore, usePaymentHistory, useTheme, userDataStore } from "../../store";
import PaymentHistoryItem from "./PaymentHistoryItem";

import PaymentHistoryLoading from "./PaymentHistoryLoading";
import PaymentHistoryEmptyState from "./PaymentHistoryEmptyState";
import Pagination from "../shared/Pagination";
import styles from "./styles/styles.module.css";
import PaymentHistoryListHeader from "./PaymentHistoryHeader";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
const PAGE_LIMIT = "";

const PaymentsHistoryList = () => {
  const navigate = useNavigate();

  const { getPaymentsHistory, paymentsHistoryData, isPaymentHistoryLoading } = usePaymentHistory();
  const { authInfo } = userDataStore();

  const { isSandbox } = useMyStore();

  const paymentHistoryListHandler = async (merchantId, PMId, storeId) => {
    let params = {
      store_id: storeId ?? "",
      ending_before: "",
      starting_after: "",
      limit: PAGE_LIMIT,
      payment_method_id: PMId ?? "",
    };
    if (authInfo?.merchantAccountId) {
      await getPaymentsHistory(merchantId, params);
    }
  };
  const getPaymentHistoryHandler = async (param = {}) => {
    const params = {
      starting_after: param.starting_after || null,
      ending_before: param.ending_before || null,
    };
    const id = authInfo.merchantAccountId;
    await getPaymentsHistory(id, params);
  };

  useEffect(() => {
    let subscribe = true;
    if (subscribe) {
      if (authInfo) {
        paymentHistoryListHandler(authInfo?.merchantAccountId);
      }
    }

    return () => {
      subscribe = false;
    };
  }, [authInfo, isSandbox]);

  const renderTableBody = () => {
    if (isPaymentHistoryLoading) {
      return <PaymentHistoryLoading />;
    } else if (!paymentsHistoryData?.data?.length) {
      return <PaymentHistoryEmptyState />;
    } else return paymentsHistoryData?.data?.map((item) => <PaymentHistoryItem paymentObject={item} />);
  };

  return (
    <div className={styles.container}>
      <div className={styles.dividerLine} />
      <div className={styles.historyHeaderWrapper}>
        <span
          className={styles.backButton}
          onClick={() => {
            navigate(`/v1/settings/payments`);
          }}
        >
          {"< Back"}
        </span>
        <h1 className={styles.heading}>Payment History</h1>
      </div>
      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <thead className={styles.tableHeader}>
            <PaymentHistoryListHeader />
          </thead>

          <tbody className={classNames(styles.tableBody, "kata")}>{renderTableBody()}</tbody>
          <tfoot className={styles.tableFooter}>
            <tr className="flex items-center justify-end pr-28 mt-4">
              <td colSpan="4">
                <Pagination data={paymentsHistoryData} onGetPaginationData={getPaymentHistoryHandler} dependencies={[]} />
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default PaymentsHistoryList;
