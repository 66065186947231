import React from "react";
import styles from "./styles.module.css";

export function DriverNumbers({ item }) {
  const numbers = () => {
    if (
      item?.courier_phone_number_for_store &&
      item?.courier_phone_number_for_customer
    ) {
      if (
        item?.courier_phone_number_for_store ===
        item?.courier_phone_number_for_customer
      ) {
        return (
          <div className="flex flex-col w-full">
            <div className={styles.title}>Courier phone number</div>
            <div className={styles.number}>
              {item.courier_phone_number_for_store ?? "Not available"}
            </div>
          </div>
        );
      } else {
        return (
          <div className="flex flex-col w-full">
            <div className={styles.title}>Courier phone number for store</div>
            <div className={styles.number}>
              {item.courier_phone_number_for_store}
            </div>
            <div className={styles.title}>
              Courier phone number for customer
            </div>
            <div className={styles.number}>
              {item.courier_phone_number_for_customer}
            </div>
          </div>
        );
      }
    } else if (item?.courier_phone_number_for_store) {
      return (
        <div className="flex flex-col w-full">
          <div className={styles.title}>Courier phone number for store</div>
          <div className={styles.number}>
            {item.courier_phone_number_for_store}
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex flex-col w-full">
          <div className={styles.title}>Courier phone number for customer</div>
          <div className={styles.number}>
            {item.courier_phone_number_for_customer ?? "Not Available"}
          </div>
        </div>
      );
    }
  };
  return (
    <div className="w-full flex items-start justify-between">{numbers()}</div>
  );
}
