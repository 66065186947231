import React from "react";
import onDemandIcon from "../../../../assets/png/onDemandIcon.png";
import scheduleIcon from "../../../../assets/png/scheduleIcon.png";
import styles from "../styles/providerDetails/providerDeliveryTypes.module.css";

const ProviderDeliveryTypes = ({ type }) => {
  return (
    <div className={styles.cardItem}>
      <img
        className={styles.icon}
        src={type === "scheduled" ? scheduleIcon : onDemandIcon}
        alt=""
      />
      <div className={styles.cartTitle}>
        {type === "scheduled" ? "Scheduled" : "On Demand"}
      </div>
    </div>
  );
};

export default ProviderDeliveryTypes;
