import React, { useEffect, useState } from "react";
import CommonToggle from "../../../CommonToggle";
import { useIncidents, useMyStore, useTheme } from "../../../../store";
import { useDesktopOrLaptop } from "../../../../services/useDesktopOrLaptop";
import ButtonComp from "../../../ButtonComp/ButtonComp";
import { numberWithCommas } from "../../../../utils/helpers";
import { BiInfoCircle } from "react-icons/bi";
import ImagesContainerOnSidebar from "../../../DeliveryDetailsOnSidePopup/ImagesContainerOnSidebar";
import styles from "./styles.module.css";
import Button from "../../../shared/atoms/Button";
import RejectionReasons from "./RejectionReasons";
import classNames from "classnames";
import { toast } from "react-toastify";

const LIMIT_ON_PAGE = 30;

const REASONS = {
  order_cancelled: "Order Cancelled",
  never_delivered: "Never Delivered",
  order_arrived_late: "Order Arrived Late",
  order_delivered_early: "Order Delivered Early",
  missing_or_incorrect_items: "Missing or Incorrect Items",
  poor_delivery_experience: "Poor Delivery Experience",
  stuck_delivery_status: "Stuck Delivery Status",
};

const UpdateIncident = ({ selectedIncident, delInfo }) => {
  const [incidentPayload, setIncidentPayload] = useState({
    internal_note: selectedIncident?.internal_note ?? "",
    provider_refunded_amount: numberWithCommas((selectedIncident?.provider_refunded_amount / 100)?.toFixed(2)) ?? "",
    delivery_fee_and_tips_refunded: selectedIncident?.delivery_fee_and_tips_refunded ? true : false,
    return_fee_refunded: selectedIncident?.return_fee_refunded ? true : false,
    order_value_refund_amount: numberWithCommas((selectedIncident?.order_value_refunded_amount / 100)?.toFixed(2)) ?? "",
    refund_rejection_reason: selectedIncident?.refund_rejection_reason?.internal_name,
  });
  const { Colors, themeChanger } = useTheme();
  const { isSandbox } = useMyStore();
  const {
    updateIncidentHandler,
    setIsCreateEditIncidentModalOpen,
    getAllIncidents,
    incidentStatusFilter,
    searchedId,
    incidents,
    updatingIncidentIndex,
    setIncidents,
    setMarkNotReroutableVisible,
  } = useIncidents();
  const { xsfontsize } = useDesktopOrLaptop();

  const getAllIncidentsHandler = async (params = {}) => {
    await getAllIncidents({
      test_mode: isSandbox,
      limit: LIMIT_ON_PAGE,
      ending_before: params?.ending_before || "",
      starting_after: params?.starting_after || "",
      delivery_id: searchedId,
      status: incidentStatusFilter,
    });
  };

  // useEffect(() => {
  //   setIncidentPayload({
  //     internal_note: selectedIncident?.internal_note ?? "",
  //     provider_refunded_amount: numberWithCommas((selectedIncident?.provider_refunded_amount / 100)?.toFixed(2)) ?? "",
  //     delivery_fee_and_tips_refunded: selectedIncident?.delivery_fee_and_tips_refunded ? true : false,
  //     return_fee_refunded: selectedIncident?.return_fee_refunded ? true : false,
  //     order_value_refund_amount: numberWithCommas((selectedIncident?.order_value_refunded_amount / 100)?.toFixed(2)) ?? "",
  //     refund_rejection_reason: selectedIncident?.refund_rejection_reason?.internal_name,
  //   });
  // }, [selectedIncident]);

  return (
    <div className="w-full flex flex-col px-8 pb-5">
      {(selectedIncident?.reroute_child_delivery_id || selectedIncident?.reroute_parent_delivery_id) && (
        <div
          style={{
            backgroundColor: Colors.whiteBlue,
            borderRadius: "10px",
            padding: "14px 20px",
          }}
          className="w-full flex items-center justify-between my-5"
        >
          {selectedIncident?.reroute_parent_delivery_id ? (
            <div>
              <span style={{ fontWeight: "500", color: Colors.fontDark }}>Rerouted From:</span>

              <span style={{ color: Colors.fontGreyLight, marginLeft: "10px" }}>
                {selectedIncident?.reroute_parent_delivery_id}
              </span>
            </div>
          ) : (
            selectedIncident?.reroute_child_delivery_id && (
              <div>
                <span style={{ fontWeight: "500", color: Colors.fontDark }}>Rerouted to:</span>

                <span style={{ color: Colors.fontGreyLight, marginLeft: "10px" }}>
                  {selectedIncident?.reroute_child_delivery_id}
                </span>
              </div>
            )
          )}
        </div>
      )}
      <div style={{ color: Colors.fontDark }} className="flex w-full justify-between items-start mt-4">
        <div>Provider Refunded Amount:</div>
        <div className="flex items-center">
          $
          <input
            className={`${themeChanger ? "focus:border-gray-400" : "focus:border-gray-300"}`}
            value={incidentPayload.provider_refunded_amount ?? ""}
            style={{
              padding: "10px 10px",
              width: "120px",
              outline: "none",
              backgroundColor: Colors.borderPrimary,
              resize: "none",
              maxHeight: "300px",
              borderRadius: "10px",
              marginLeft: "4px",
            }}
            onChange={(e) => {
              const value = e.target.value.replace(/[^.\d]/g, "");

              const _incidentPayload = { ...incidentPayload };
              _incidentPayload.provider_refunded_amount = value;
              if (value && value != 0) {
                _incidentPayload.refund_rejection_reason = undefined;
              }
              setIncidentPayload(_incidentPayload);
            }}
            placeholder={"Amount"}
          />
        </div>
      </div>

      <div style={{ color: Colors.fontDark }} className="flex w-full justify-between items-start mt-4">
        <div>Order Value Refunded Amount:</div>
        <div className="flex items-center">
          $
          <input
            className={`${themeChanger ? "focus:border-gray-400" : "focus:border-gray-300"}`}
            value={incidentPayload.order_value_refund_amount ?? ""}
            style={{
              padding: "10px 10px",
              width: "120px",
              outline: "none",
              backgroundColor: Colors.borderPrimary,
              resize: "none",
              maxHeight: "300px",
              borderRadius: "10px",
              marginLeft: "4px",
            }}
            onChange={(e) => {
              const value = e.target.value.replace(/[^.\d]/g, "");
              const _incidentPayload = { ...incidentPayload };
              _incidentPayload.order_value_refund_amount = value;
              if (value && value != 0) {
                _incidentPayload.refund_rejection_reason = undefined;
              }
              setIncidentPayload(_incidentPayload);
            }}
            placeholder={"Amount"}
          />
        </div>
      </div>
      <div style={{ color: Colors.fontDark }} className="flex w-full justify-between items-center mt-5">
        <div>Delivery Fee and Tip Refund</div>
        <div className="flex items-center">
          <div style={{ color: Colors.fontGreyLight, marginRight: "5px" }}>
            {incidentPayload.delivery_fee_and_tips_refunded ? "True" : "False"}
          </div>
          <CommonToggle
            id="headerToggle"
            smsHandler={() => {
              const _incidentPayload = { ...incidentPayload };
              if (!_incidentPayload.delivery_fee_and_tips_refunded) {
                _incidentPayload.refund_rejection_reason = undefined;
              }
              _incidentPayload.delivery_fee_and_tips_refunded = !_incidentPayload.delivery_fee_and_tips_refunded;
              setIncidentPayload(_incidentPayload);
            }}
            checked={incidentPayload.delivery_fee_and_tips_refunded}
            translateX={15}
            circleStyle={{
              width: "16px",
              height: "16px",
              backgroundColor: "white",
            }}
            style={{
              width: "37px",
              height: "20px",
              padding: "3px",
              backgroundColor: incidentPayload.delivery_fee_and_tips_refunded ? Colors.bluePrimary : "#979797",
            }}
          />
        </div>
      </div>
      {selectedIncident?.has_return_payment && (
        <div style={{ color: Colors.fontDark }} className="flex w-full justify-between items-center mt-5">
          <div>Return Fee Refund</div>
          <div className="flex items-center">
            <div style={{ color: Colors.fontGreyLight, marginRight: "5px" }}>
              {incidentPayload.return_fee_refunded ? "True" : "False"}
            </div>
            <CommonToggle
              id="headerToggle"
              smsHandler={() => {
                const _incidentPayload = { ...incidentPayload };
                if (!_incidentPayload.return_fee_refunded) {
                  _incidentPayload.refund_rejection_reason = undefined;
                }
                _incidentPayload.return_fee_refunded = !_incidentPayload.return_fee_refunded;
                setIncidentPayload(_incidentPayload);
              }}
              checked={incidentPayload.return_fee_refunded}
              translateX={15}
              circleStyle={{
                width: "16px",
                height: "16px",
                backgroundColor: "white",
              }}
              style={{
                width: "37px",
                height: "20px",
                padding: "3px",
                backgroundColor: incidentPayload.return_fee_refunded ? Colors.bluePrimary : "#979797",
              }}
            />
          </div>
        </div>
      )}
      <div style={{ color: Colors.fontDark }} className="flex w-full justify-between items-start mt-4">
        <div>Note:</div>
        <textarea
          maxLength={"900"}
          className={`${themeChanger ? "focus:border-gray-400" : "focus:border-gray-300"}`}
          value={incidentPayload.internal_note}
          style={{
            padding: "10px 20px",
            width: "60%",
            outline: "none",
            backgroundColor: Colors.borderPrimary,
            resize: "none",
            maxHeight: "800px",
            borderRadius: "10px",
          }}
          onChange={(e) => {
            const _incidentPayload = { ...incidentPayload };
            _incidentPayload.internal_note = e.target.value;
            setIncidentPayload(_incidentPayload);
          }}
          rows="8"
          placeholder={"Your Note goes here"}
        ></textarea>
      </div>
      {selectedIncident.external_note && (
        <div style={{ color: Colors.fontDark }} className="flex w-full justify-between items-start mt-4">
          <div>External note:</div>
          <div
            className={`${themeChanger ? "focus:border-gray-400" : "focus:border-gray-300"}`}
            style={{
              padding: "10px 20px",
              width: "60%",
              outline: "none",
              backgroundColor: Colors.borderPrimary,
              resize: "none",
              maxHeight: "800px",
              borderRadius: "10px",
            }}
          >
            {selectedIncident.external_note}
          </div>
        </div>
      )}
      {selectedIncident.delivery_incident_reason && (
        <div style={{ color: Colors.fontDark }} className="flex w-full justify-between items-start mt-4">
          <div>External incident reason:</div>
          <div
            className={`${themeChanger ? "focus:border-gray-400" : "focus:border-gray-300"}`}
            style={{
              padding: "10px 20px",
              width: "60%",
              outline: "none",
              backgroundColor: Colors.borderPrimary,
              resize: "none",
              maxHeight: "800px",
              borderRadius: "10px",
            }}
          >
            {REASONS[selectedIncident.delivery_incident_reason]}
          </div>
        </div>
      )}
      <RejectionReasons
        disabled={
          (incidentPayload.order_value_refund_amount && incidentPayload.order_value_refund_amount != 0) ||
          incidentPayload.return_fee_refunded ||
          incidentPayload.delivery_fee_and_tips_refunded
        }
        incidentPayload={incidentPayload}
        setIncidentPayload={setIncidentPayload}
      />
      {selectedIncident?.proof_of_incident && selectedIncident?.proof_of_incident?.length > 0 && (
        <div className={styles.podIncident}>Proof Of Incident</div>
      )}
      <div className="flex items-center w-full justify-start overflow-x-auto">
        {selectedIncident?.proof_of_incident &&
          selectedIncident?.proof_of_incident.map((value, index) => {
            return (
              <div className="mt-6">
                <ImagesContainerOnSidebar hideDownload images={[value]} />
              </div>
            );
          })}
      </div>
      <div className="flex w-full justify-between mt-8 ">
        <div
          className="flex items-center"
          style={{
            width: "75%",
            color: Colors.fontDark,
            // fontSize: "12px",
            fontSize: xsfontsize,
          }}
        >
          <span style={{ marginRight: "10px" }}>
            <BiInfoCircle size={26} color={Colors.fontGreyLight} />
          </span>
          Resolve will process and finalize the refund. Once resolved, the incident can no longer be updated, and refunded amount
          will be visible to the merchant.
        </div>
        {/* <div className={classNames(styles.buttonsContainer, "flex justify-end items-center relative nowra")}> */}
        <div className={classNames(styles.buttonsContainer)}>
          {!(selectedIncident.reroute_status === "rerouted" || selectedIncident.reroute_status === "not_reroutable") && (
            <Button
              externalClassName={styles.noWrap}
              primary
              text={"Not reroutable"}
              onClick={() => {
                setMarkNotReroutableVisible(true);
              }}
            />
          )}
          <Button
            externalClassName={"ml-2"}
            primary
            text={"Resolve"}
            onClick={async () => {
              if (
                (!incidentPayload.order_value_refund_amount || incidentPayload.order_value_refund_amount == 0) &&
                !incidentPayload.return_fee_refunded &&
                !incidentPayload.delivery_fee_and_tips_refunded &&
                (!incidentPayload?.provider_refunded_amount || incidentPayload?.provider_refunded_amount == 0)
              ) {
                if (!incidentPayload?.refund_rejection_reason) {
                  return toast.error("Please provide the denial reason");
                }
              }

              const _incidentPayload = { ...incidentPayload };
              _incidentPayload.order_value_refund_amount = _incidentPayload.order_value_refund_amount
                ? Number((_incidentPayload.order_value_refund_amount * 100).toFixed(0))
                : undefined;
              _incidentPayload.provider_refunded_amount = _incidentPayload.provider_refunded_amount
                ? Number((_incidentPayload.provider_refunded_amount * 100).toFixed(0))
                : undefined;

              const res = await updateIncidentHandler(selectedIncident.id, {
                ..._incidentPayload,
                ...{
                  status: "resolved",
                },
              });

              if (res) {
                const _incidents = { ...incidents };
                _incidents.data[updatingIncidentIndex].delivery_fee_and_tips_refunded =
                  _incidentPayload.delivery_fee_and_tips_refunded;
                _incidents.data[updatingIncidentIndex].return_fee_refunded = _incidentPayload.return_fee_refunded;
                _incidents.data[updatingIncidentIndex].internal_note = _incidentPayload.internal_note;
                _incidents.data[updatingIncidentIndex].order_value_refund_amount = _incidentPayload.order_value_refund_amount;
                _incidents.data[updatingIncidentIndex].provider_refunded_amount = _incidentPayload.provider_refunded_amount;
                _incidents.data[updatingIncidentIndex].status = "resolved";
                _incidents.data[updatingIncidentIndex].refund_rejection_reason = res.refund_rejection_reason;

                setIncidents(_incidents);
                setIsCreateEditIncidentModalOpen(false);
              }
            }}
          />
          <Button
            externalClassName={"ml-2"}
            primary
            text={"Save"}
            onClick={async () => {
              // if (
              //   (!incidentPayload.order_value_refund_amount || incidentPayload.order_value_refund_amount == 0) &&
              //   !incidentPayload.return_fee_refunded &&
              //   !incidentPayload.delivery_fee_and_tips_refunded &&
              //   (!incidentPayload?.provider_refunded_amount || incidentPayload?.provider_refunded_amount == 0)
              // ) {
              //   if (!incidentPayload?.refund_rejection_reason) {
              //     return toast.error("Please provide the denial reason");
              //   }
              // }

              const _incidentPayload = { ...incidentPayload };
              _incidentPayload.order_value_refund_amount = _incidentPayload.order_value_refund_amount
                ? Number((_incidentPayload.order_value_refund_amount * 100).toFixed(0))
                : undefined;
              _incidentPayload.provider_refunded_amount = _incidentPayload.provider_refunded_amount
                ? Number((_incidentPayload.provider_refunded_amount * 100).toFixed(0))
                : undefined;

              const res = await updateIncidentHandler(selectedIncident.id, _incidentPayload);
              if (res) {
                const _incidents = { ...incidents };
                _incidents.data[updatingIncidentIndex].delivery_fee_and_tips_refunded =
                  _incidentPayload.delivery_fee_and_tips_refunded;
                _incidents.data[updatingIncidentIndex].return_fee_refunded = _incidentPayload.return_fee_refunded;
                _incidents.data[updatingIncidentIndex].internal_note = _incidentPayload.internal_note;
                _incidents.data[updatingIncidentIndex].order_value_refund_amount = _incidentPayload.order_value_refund_amount;
                _incidents.data[updatingIncidentIndex].provider_refunded_amount = _incidentPayload.provider_refunded_amount;
                _incidents.data[updatingIncidentIndex].order_value_refunded_amount = res.order_value_refunded_amount;
                _incidents.data[updatingIncidentIndex].refund_rejection_reason = res.refund_rejection_reason;

                setIncidents(_incidents);
                setIsCreateEditIncidentModalOpen(false);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default UpdateIncident;
