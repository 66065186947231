import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { RiArrowDownSLine } from "react-icons/ri";

import StatusBadgeReusable from "../../shared/common/StatusBadgeReusable";

import DeliveryDetailsCollapsable from "./DeliveryDetailsCollapsable";

import { useClickStore, useTheme } from "../../../store";

import styles from "../styles/deliveriesListNew.module.css";
import Feedback from "./Feedback";
import DelLateCircle from "./DelLateCircle";
import { useFlag } from "@unleash/proxy-client-react";
import { useNavigate } from "react-router-dom";
import Badge from "../../shared/atoms/Badge";

import { badgesForDeliveryStatuses, iconHandler } from "../../Admin/DeliveriesForAdmin/utils";
import { incidentBadges } from "../../DeliveryDetailsOnSidePopup/IncidentInfoAndReason/utils";
import DeliveryListIcon from "./DeliveryListIcon";
import { getStatus } from "../utils";
import Hyperlink from "../../shared/atoms/Hyperlink";

const DeliveriesList = ({ index, item, activeIndex, setActiveIndex, scrollHandler, socketMessage }) => {
  const { setIsCalendarVisible } = useClickStore();

  const [socketItemDetails, setSocketItemDetails] = useState(item);

  let statusSocket = item?.status === "customer_canceled" ? "customer_canceled" : socketItemDetails?.status;

  let statusesHystory = socketItemDetails?.status_history || item?.events;

  const rerouteStatus = socketItemDetails?.reroute_status;

  const isFeedbackEnabled = useFlag("feedback");

  const returnDriverStatus = () => {
    if (statusSocket === "delivery_created" || statusSocket === "driver_not_assigned") {
      return "Assigning Driver";
    } else return "Not available";
  };

  let history = useNavigate();

  const lsitRef = useRef(null);

  useEffect(() => {
    if (socketMessage?.id === item?.id) {
      setSocketItemDetails({ ...item, ...socketMessage });
    }
  }, [socketMessage]);

  const { Colors } = useTheme();

  const getStatusColor = () => {
    if (rerouteStatus === "provider_search") {
      return "#656565";
    } else if (rerouteStatus === "rerouted") {
      return "#0077CC";
    } else if (statusSocket === "completed") {
      return "bg-green-50";
    } else if (
      statusSocket === "pending" ||
      statusSocket === "delivery_created" ||
      statusSocket === "driver_assigned" ||
      statusSocket === "driver_not_assigned"
    ) {
      return "#FF974A";
    } else if (
      statusSocket === "canceled" ||
      statusSocket === "provider_canceled" ||
      statusSocket === "customer_canceled" ||
      statusSocket === "burq_canceled" ||
      statusSocket === "expired" ||
      statusSocket === "failed" ||
      statusSocket === "disputed"
    ) {
      return "#FC5A5A";
    } else if (statusSocket === "delivered") {
      return "#3DD598";
    } else if (
      statusSocket === "pickup" ||
      statusSocket === "enroute_pickup" ||
      statusSocket === "arrived_at_pickup" ||
      statusSocket === "pickup_complete"
    ) {
      return "#50B5FF";
    } else if (
      statusSocket === "dropoff" ||
      statusSocket === "enroute_dropoff" ||
      statusSocket === "arrived_at_dropoff" ||
      statusSocket === "dropoff_complete"
    ) {
      return "#A461D8";
    } else if (
      statusSocket === "Paid order" ||
      statusSocket === "contacting_provider" ||
      statusSocket === "scheduled" ||
      statusSocket === "returned"
    ) {
      return "#0062FF";
    } else if (statusSocket === "request") {
      return "#92929D";
    } else return Colors.fontGreyLight;
  };

  return (
    <div
      className={styles.listContainer}
      id={`deliveryItem-${index}`}
      style={{
        marginTop: activeIndex === index ? "20px" : "0px",
        marginBottom: activeIndex === index ? "20px" : "0px",
        borderBottomWidth: activeIndex === index + 1 ? "1px" : "0px",
        borderTopWidth: activeIndex === index && "1px",
        boxShadow: activeIndex === index + 1 && "0px 2px 2px rgba(0, 0, 0, 0.07)",
        borderRadius:
          activeIndex === index
            ? "8px"
            : activeIndex === index - 1
            ? "8px 8px 0px 0px"
            : activeIndex === index + 1
            ? "0px 0px 8px 8px"
            : "0px",
      }}
    >
      <button
        type="button"
        ref={lsitRef}
        onClick={(e) => {
          e.stopPropagation();

          window.analytics.track("On item is clicked on desktop");
          history(`?deliveryId=${item.id}`);
          return;
          // }
          // setTimeout(() => {
          //   scrollHandler(330 + index * 94);
          //   return;
          // }, 200);

          // if (activeIndex === index) {
          //   setActiveIndex(null);
          // } else {
          //   setActiveIndex(index);
          // }
        }}
        className={classNames(styles.listItemContainer, {
          [styles.borderTop]: index !== activeIndex,
        })}
      >
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <DeliveryListIcon statusSocket={statusSocket} rerouteStatus={rerouteStatus} />

          <div style={{ display: "flex", flexDirection: "column", marginLeft: "16px", gap: "10px" }}>
            <div
              className={classNames(styles.directionNameCont, "flex items-center", {
                [styles.textPrimaryBlue]: activeIndex === index,
                [styles.textGrey]: activeIndex !== index,
              })}
            >
              <span
                style={{
                  color: Colors.fontGreyLight,
                  fontWeight: "500",
                  marginRight: "6px",
                }}
              >
                To
              </span>
              {item.dropoffs.map((dropoff, index) => {
                if (item.dropoffs.length === index + 1) {
                  return dropoff.dropoff_name;
                } else {
                  return dropoff.dropoff_name + ", ";
                }
              })}
            </div>

            <div className="flex items-center">
              <div
                style={{
                  height: "10px",
                  width: "10px",
                  borderRadius: "5px",
                  backgroundColor:
                    rerouteStatus === "provider_search"
                      ? "#656565"
                      : rerouteStatus === "rerouted"
                      ? "#0077CC"
                      : statusSocket === "completed"
                      ? "bg-green-50"
                      : statusSocket === "pending" ||
                        statusSocket === "delivery_created" ||
                        statusSocket === "driver_assigned" ||
                        statusSocket === "driver_not_assigned"
                      ? "#FF974A"
                      : statusSocket === "canceled" ||
                        statusSocket === "provider_canceled" ||
                        statusSocket === "customer_canceled" ||
                        statusSocket === "burq_canceled" ||
                        statusSocket === "expired" ||
                        statusSocket === "failed" ||
                        statusSocket === "disputed"
                      ? "#FC5A5A"
                      : statusSocket === "delivered"
                      ? "#3DD598"
                      : statusSocket === "pickup" ||
                        statusSocket === "enroute_pickup" ||
                        statusSocket === "arrived_at_pickup" ||
                        statusSocket === "pickup_complete"
                      ? "#50B5FF"
                      : statusSocket === "dropoff" ||
                        statusSocket === "enroute_dropoff" ||
                        statusSocket === "arrived_at_dropoff" ||
                        statusSocket === "dropoff_complete"
                      ? "#A461D8"
                      : statusSocket === "Paid order" ||
                        statusSocket === "contacting_provider" ||
                        statusSocket === "scheduled" ||
                        statusSocket === "returned" ||
                        statusSocket === "enroute_to_return"
                      ? "#0062FF"
                      : statusSocket === "request"
                      ? "#92929D"
                      : Colors.fontGreyLight,
                }}
              ></div>
              <div
                style={{
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "21px",
                  textAlign: "center",
                  marginLeft: "10px",
                  color: getStatusColor(),
                }}
              >
                {getStatus({ statusSocket, rerouteStatus })}
              </div>
              {item?.reroute_child_delivery_id && item?.reroute_status === "rerouted" && (
                <Hyperlink
                  text="View new delivery"
                  externalClassName={styles.viewLink}
                  onClick={(e) => {
                    e.stopPropagation();
                    history(`?deliveryId=${item?.reroute_child_delivery_id}`);
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <div className={styles.badgesAndinfoContainer}>
          {/* <DelLateCircle minutes={10} /> */}
          {isFeedbackEnabled && <Feedback item={item} />}

          {item?.delivery_incident ? (
            <StatusBadgeReusable
              externalClassName={styles.badgeExtraStyles}
              status={item.delivery_incident.status}
              badgesObject={incidentBadges}
              uppercase
            />
          ) : (
            <div
              style={{
                backgroundColor: "transparent",
                width: "150px",
              }}
            />
          )}
          <div
            style={{
              flexDirection: "column",
              color: Colors.fontGreyLight,
              marginRight: "10px",
              width: "70px",
            }}
          >
            <div className={styles.dropoffAddressAndDriverTexts}>Drop off</div>
            <div className={styles.dropoffAddressAndDriverTexts}>Driver</div>
          </div>

          <div
            style={{
              flexDirection: "column",
              marginLeft: "6px",
              color: activeIndex === index ? Colors.bluePrimary : Colors.fontGreyLight,
              width: "230px",
            }}
          >
            <div className={styles.dropoffAddressAndDriverTexts}>
              {item?.dropoffs[item.dropoffs.length - 1]?.dropoff_address_street || "Not Available"}
            </div>
            <div className={styles.dropoffAddressAndDriverTexts}>{item.courier_name || returnDriverStatus()}</div>
          </div>
          <RiArrowDownSLine size={24} color={activeIndex === index ? Colors.bluePrimary : Colors.fontGreyLight} />
        </div>
      </button>
      {activeIndex === index && (
        <DeliveryDetailsCollapsable
          item={socketItemDetails || item}
          setIsCalendarVisible={setIsCalendarVisible}
          statusesHystory={statusesHystory}
        />
      )}
    </div>
  );
};

export default DeliveriesList;
