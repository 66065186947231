import React, { useEffect, useState } from "react";
import { useDeliveriesStore } from "../../../../../store";
import Loader from "../../../../shared/atoms/Loader";
import { CiWarning } from "react-icons/ci";
import ActivityCard from "./ActivityCard";
import RequestDetails from "./RequestDetails";
import classNames from "classnames";
import styles from "../../../styles/DeliveryFailureLogs/styles.module.css";
import DeliveryAPILogsEmptyState from "./DeliveryAPILogsEmptyState";
import { useDesktopOrLaptop } from "../../../../../services/useDesktopOrLaptop";

const DeliveryFailureLogs = ({ deliveryId }) => {
  const [selectedRequest, setSelectedRequest] = useState(null);

  const {
    isDeliveriyApiErrorsLoading,
    deliveryApiErrors,
    setDeliveryApiErrors,
    loadDeliveriyApiErrors,
  } = useDeliveriesStore();
  const { isdesktop } = useDesktopOrLaptop();

  useEffect(() => {
    loadDeliveriyApiErrors(deliveryId);
    return () => {
      setDeliveryApiErrors(null);
    };
  }, [deliveryId]);

  useEffect(() => {
    if (deliveryApiErrors?.length && !selectedRequest)
      setSelectedRequest(deliveryApiErrors[0]);
  }, [deliveryApiErrors]);

  return (
    <div className="flex flex-col px-7 pb-10 mt-4 justify-start w-full">
      {isDeliveriyApiErrorsLoading && <Loader />}
      {!deliveryApiErrors?.length && !isDeliveriyApiErrorsLoading && (
        <DeliveryAPILogsEmptyState />
      )}

      {!!deliveryApiErrors?.length && !isDeliveriyApiErrorsLoading && (
        <>
          <div className={styles.containerRes}>
            <div className={classNames("kata", styles.leftRes)}>
              {deliveryApiErrors.map((activity, index) => (
                <ActivityCard
                  key={index}
                  data={activity}
                  selectedRequest={selectedRequest}
                  onClickHandler={(data) => setSelectedRequest(data)}
                  index={index}
                />
              ))}
            </div>
            <div className={styles.rightRes}>
              {selectedRequest && <RequestDetails request={selectedRequest} />}
            </div>
          </div>
          {/* <div className={isdesktop ? styles.container : styles.containerRes}>
            <div
              className={classNames(
                "kata",
                isdesktop ? styles.left : styles.leftRes
              )}
            >
              {deliveryApiErrors.map((activity, index) => (
                <ActivityCard
                  key={index}
                  data={activity}
                  selectedRequest={selectedRequest}
                  onClickHandler={(data) => setSelectedRequest(data)}
                  index={index}
                />
              ))}
            </div>
            <div className={isdesktop ? styles.right : styles.rightRes}>
              {selectedRequest && <RequestDetails request={selectedRequest} />}
            </div>
          </div> */}
          <div className={styles.noteText}>
            <CiWarning size={30} />
            Note API data is only available for 7 days after Burq received the
            request.
          </div>
        </>
      )}
    </div>
  );
};

export default DeliveryFailureLogs;
