import { Menu, Transition } from "@headlessui/react";
import classNames from "classnames";
import React, { Fragment, forwardRef, useEffect, useRef } from "react";
import styles from "./styles.module.css";
import { useNotifications, userDataStore } from "../../../../store";
import { dateHandler, dummyData, handleScroll, notificationsObject } from "./utils";
import moment from "moment";
import { FaBellSlash } from "react-icons/fa6";
import { Loading, NewFeature } from "../../../../assets/svgs/Svgs";
import NotificationBadge from "../NotificationBadge";

import { useNavigate } from "react-router-dom";

import { IoMdSettings } from "react-icons/io";

const sortedData = [
  ...dummyData
    .filter((item) => item.source_type === "new_feature")
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at)),
  ...dummyData
    .filter((item) => item.source_type !== "new_feature")
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at)),
];

export function NotificationsPopup({ source }) {
  const {
    getNotificationsHandler,
    isNotificationsLoading,
    setIsNotificationsLoading,
    notifications,
    setNotifications,
    putReadOneNotification,
    putReadAllNotifications,
  } = useNotifications();
  const { authInfo } = userDataStore();

  // const [notData, setNotData] = useState(sortedData);
  const markAsReadHandler = (item, index) => {
    const _notData = { ...notifications };
    if (!item.read) {
      _notData.data[index].read = true;
      setNotifications(_notData);
      // setNotData(_notData);
      putReadOneNotification(authInfo?.merchantAccountId, {
        notification_ids: [item?.id],
      });
    }
  };

  const markAllAsRead = async () => {
    setIsNotificationsLoading(true);
    const res = await putReadAllNotifications(authInfo?.merchantAccountId);
    if (res) {
      getNotificationsHandler(authInfo?.merchantAccountId, {}, source);
    }
  };

  const containerRef = useRef(null);

  let history = useNavigate();

  const container = containerRef.current;
  let MyListComp = forwardRef(function ({ children, ...props }, ref) {
    return (
      <div className="flex items-center" {...props}>
        {children}
      </div>
    );
  });

  useEffect(() => {
    // console.log("notificationz", notifications?.data?.length);
    if (container) {
      // Attach the scroll event listener to the container
      container.addEventListener("scroll", () =>
        handleScroll(container, notifications, setNotifications, authInfo?.merchantAccountId, getNotificationsHandler)
      );
    }

    // Clean up the event listener when the component unmounts
    return () => {
      if (container) {
        container.removeEventListener("scroll", () =>
          handleScroll(container, notifications, setNotifications, authInfo?.merchantAccountId, getNotificationsHandler)
        );
      }
    };
  }, [containerRef.current]);

  return (
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items
        className={classNames("absolute z-50 right-0 mt-2 origin-top-right rounded-xl focus:outline-none", styles.menuContainer)}
      >
        <Menu.Item className={""}>
          <>
            <div className={classNames("flex w-full items-center justify-between ", styles.notificationsHeader)}>
              <div className={styles.title}>Notifications</div>
              <div className="flex items-center">
                <button onClick={markAllAsRead} type="button" className={styles.markAsRead}>
                  Mark all as read
                </button>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    history("/v1/settings/notifications");
                  }}
                >
                  <IoMdSettings size={18} className={styles.settingsIcon} />
                </div>
              </div>
            </div>
          </>
        </Menu.Item>
        <div ref={containerRef} className={classNames(styles.scrollableCont, "noScroll")}>
          {isNotificationsLoading ? (
            <Menu.Item as={Fragment} className={""}>
              <>
                <div className={classNames(styles.loadingCont)}>
                  <Loading color={"#0062ff"} className="h-12 w-12 animate-spin" />
                </div>
              </>
            </Menu.Item>
          ) : notifications?.data?.length < 1 ? (
            <Menu.Item as={Fragment} className={""}>
              <>
                <div className={classNames(styles.emptyState)}>
                  <div className="flex flex-col items-center">
                    <div>
                      <FaBellSlash size={38} color="#0062ff" />
                    </div>
                    <div className={styles.orderIdTitle}>No notifications right now</div>
                  </div>
                </div>
              </>
            </Menu.Item>
          ) : (
            notifications?.data?.map((item, index) => {
              const formatString = item.source_type === "new_feature" ? "dddd D MMM, YYYY" : "hh:mma";

              return (
                <div
                  // id={item?.type === "new_feature" ? item.source_id : item.id}
                  id={item.source_id}
                  key={index}
                >
                  {item.source_type !== "new_feature" &&
                    dateHandler(item, styles, notifications?.data?.length - 1 === index, notifications?.data?.length)}
                  <Menu.Item
                    onClick={() => {
                      if (item.source_type === "new_feature") {
                        return;
                      }
                      if (!item.read) {
                        markAsReadHandler(item, index);
                      }
                      history(`?deliveryId=${item.source_id}`);
                    }}
                    as={MyListComp}
                  >
                    {({ active }) => (
                      <>
                        <button
                          className={classNames(`flex w-full items-start`, styles.oneNotification, {
                            [styles.active]: active,
                          })}
                        >
                          {item.source_type === "new_feature" && (
                            <div className="mr-3">
                              <NewFeature />
                            </div>
                          )}
                          <div>
                            <div>
                              <NotificationBadge
                                externalClassName={styles.badge}
                                notificationsTitle={item.source_type}
                                notificationsObject={notificationsObject}
                              />

                              {item?.source_type !== "new_feature" && (
                                <span className={classNames("m-0", styles.orderIdTitle)}>
                                  {" - "}
                                  Order ID:
                                </span>
                              )}
                              {item.source_type !== "new_feature" && (
                                <span className={classNames(styles.orderId)}>{item?.source_id}</span>
                              )}
                            </div>
                            <div className={classNames(styles.desc)}>{item.description}</div>
                            <div className={classNames(styles.timeInNotif)}>{moment(item.created_at).format(formatString)}</div>
                          </div>
                        </button>
                        {item.source_type !== "new_feature" && (
                          <button
                            className="ml-2 mb-8"
                            onClick={(e) => {
                              e.stopPropagation();
                              markAsReadHandler(item, index);
                            }}
                          >
                            {/* {item?.read ? (
                              <HiOutlineMailOpen
                                className={styles.readIcon}
                                size={20}
                              />
                            ) : (
                              <MdOutlineMarkEmailUnread
                                className={styles.unreadIcon}
                                size={20}
                              />
                            )} */}
                            {/* {item?.read ? (
                              <div className={styles.readCircle} />
                            ) : (
                              <div className={styles.unreadBigCirlce}>
                                <div className={styles.unreadSmallCirlce} />
                              </div>
                            )} */}
                            {!item?.read && (
                              <div className={styles.unreadBigCirlce}>
                                <div className={styles.unreadSmallCirlce} />
                              </div>
                            )}
                          </button>
                        )}
                      </>
                    )}
                  </Menu.Item>
                </div>
              );
            })
          )}
        </div>
      </Menu.Items>
    </Transition>
  );
}
