import { useTheme } from "../../store";

export const SettingSVG = ({ isSelected }) => {
  const { Colors } = useTheme();

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
      <path
        fill={isSelected ? Colors.bluePrimary : Colors.fontGreyLight}
        d="M18 6.75C19.3781 6.75 20.5312 7.8975 20.5312 9.28125C20.5312 10.6594 19.3781 11.8125 18 11.8125C16.6219 11.8125 15.4688 10.6594 15.4688 9.28125C15.4688 7.8975 16.6219 6.75 18 6.75Z"
        // fill="#9B9B9B"
      />
      <path
        d="M18 15.4688C19.3781 15.4688 20.5312 16.6163 20.5312 18C20.5312 19.3781 19.3781 20.5312 18 20.5312C16.6219 20.5312 15.4688 19.3781 15.4688 18C15.4688 16.6163 16.6219 15.4688 18 15.4688Z"
        fill={isSelected ? Colors.bluePrimary : Colors.fontGreyLight}
      />
      <path
        d="M18 24.1875C19.3781 24.1875 20.5312 25.335 20.5312 26.7188C20.5312 28.0969 19.3781 29.25 18 29.25C16.6219 29.25 15.4688 28.0969 15.4688 26.7188C15.4688 25.335 16.6219 24.1875 18 24.1875Z"
        fill={isSelected ? Colors.bluePrimary : Colors.fontGreyLight}
      />
    </svg>
  );
};

export const InfoSVG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clip-path="url(#clip0_15938_5725)">
        <path
          d="M8 0C3.57841 0 0 3.578 0 8C0 12.4215 3.578 16 8 16C12.4216 16 16 12.422 16 8C16 3.57847 12.422 0 8 0ZM8.82153 11.1757C8.82153 11.4284 8.45297 11.6811 8.00016 11.6811C7.52628 11.6811 7.18934 11.4284 7.18934 11.1757V7.16356C7.18934 6.86872 7.52631 6.66859 8.00016 6.66859C8.45297 6.66859 8.82153 6.86872 8.82153 7.16356V11.1757ZM8.00019 5.69987C7.51578 5.69987 7.13672 5.34184 7.13672 4.94166C7.13672 4.5415 7.51581 4.194 8.00019 4.194C8.47406 4.194 8.85319 4.5415 8.85319 4.94166C8.85319 5.34184 8.47403 5.69987 8.00019 5.69987Z"
          fill="#DFDFDF"
        />
      </g>
      <defs>
        <clipPath id="clip0_15938_5725">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
