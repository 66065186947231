import React, { useMemo, useState } from "react";
import {
  useTheme,
  useMyStore,
  userDataStore,
  useStoreAndChild,
  useMerchant,
  useFilter,
  useClickStore,
} from "../../../../store";
import { Loading } from "../../../../assets/svgs/Svgs";
import curierAvatar from "../../../../assets/png/curierAvatar.png";
import courierAvatarLight from "../../../../assets/png/courierAvatarLight.png";
import { useDesktopOrLaptop } from "../../../../services/useDesktopOrLaptop";

export default function DriverContain({
  item,
  setCancelVisible,
  statusSocket,
  isOnPopup,
}) {
  const { Colors, themeChanger } = useTheme();
  const { requestDriverHandler, pageCount, deliveries } = useMyStore();
  const { isAdmin } = useMerchant();
  const { isdesktop, smfontsize, xsfontsize, xxsfontsize } =
    useDesktopOrLaptop();
  const { startDate, search } = useFilter();
  const { setAllTimeVisible, setIsCalendarVisible } = useClickStore();

  const { selectedStore } = useStoreAndChild();

  const [isRequestLoading, setIsRequestLoading] = useState(false);

  const returnDriverStatus = () => {
    if (
      statusSocket === "delivery_created" ||
      statusSocket === "driver_not_assigned"
    ) {
      return "Assigning Driver";
    } else return "Not available";
  };

  const returCorrectNumberAndTitle = () => {
    let storeNumber =
      item?.courier_phone_number_for_store &&
      item?.courier_phone_number_for_store[0] !== "+"
        ? "+" + item?.courier_phone_number_for_store
        : item?.courier_phone_number_for_store;
    let customerNumber =
      item?.courier_phone_number_for_store &&
      item?.courier_phone_number_for_store[0] !== "+"
        ? "+" + item?.courier_phone_number_for_customer
        : item?.courier_phone_number_for_customer;
    if (
      item?.courier_phone_number_for_store &&
      item?.courier_phone_number_for_customer
    ) {
      if (
        item?.courier_phone_number_for_store ===
        item?.courier_phone_number_for_customer
      ) {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              fontFamily: "Poppins",
            }}
          >
            <div style={styles.bottomTextGrey}>Phone number</div>
            <div
              style={{
                color: Colors.fontBlack,
                fontSize: xsfontsize,
              }}
            >
              {storeNumber}
            </div>
          </div>
        );
      } else {
        return (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                fontFamily: "Poppins",
              }}
            >
              <div style={styles.bottomTextGrey}>Phone for store</div>
              <div
                style={{
                  color: Colors.fontBlack,
                  fontSize: xsfontsize,
                }}
              >
                {storeNumber}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                fontFamily: "Poppins",
              }}
            >
              <div style={styles.bottomTextGrey}>Phone for customer</div>
              <div
                style={{
                  color: Colors.fontBlack,
                  fontSize: xsfontsize,
                }}
              >
                {customerNumber}
              </div>
            </div>
          </>
        );
      }
    } else if (item?.courier_phone_number_for_store) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            fontFamily: "Poppins",
          }}
        >
          <div style={styles.bottomTextGrey}>Phone for store</div>
          <div
            style={{
              color: Colors.fontBlack,
              fontSize: xsfontsize,
            }}
          >
            {storeNumber || returnDriverStatus()}
          </div>
        </div>
      );
    } else {
      return (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              fontFamily: "Poppins",
            }}
          >
            <div style={styles.bottomTextGrey}>Phone for customer</div>
            <div
              style={{
                color: Colors.fontBlack,
                fontSize: xsfontsize,
              }}
            >
              {customerNumber || returnDriverStatus()}
            </div>
          </div>
        </>
      );
    }
  };

  const styles = useMemo(() => {
    return {
      providerContainer: {
        display: "flex",
        flexDirection: isdesktop ? (isOnPopup ? "column" : "row") : "column",
        width: "100%",
        backgroundColor: Colors.detailsBg,
        alignItems: "center",
        padding: isdesktop ? "15px 30px 15px 25px" : "0px 0px 15px 25px",
        borderRadius: isdesktop ? "8px" : "60px 60px 0px 0px",
        marginTop: isdesktop ? (isOnPopup ? "30px" : "0px") : "64px",
        marginBottom: isOnPopup ? "30px" : "0px",
      },
      oneCont: {
        borderWidth: "1px",
        borderColor: Colors.borderPrimary,
        flexDirection: "row",
        display: "flex",
        width: isdesktop
          ? isOnPopup
            ? "49%"
            : "49%"
          : isOnPopup
          ? "49%"
          : "80%",
        justifyContent: "space-between",
        height: isdesktop ? "80px" : "140px",
        borderRadius: "15px",
        alignSelf: "center",
        marginTop: isdesktop ? "0px" : "80px",
        backgroundColor: Colors.driverBoxesBg,
        padding: "0px 20px",
      },
      bottomTextGrey: {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: smfontsize,
        lineHeight: "18px",
        color: Colors.fontGreyLight,
      },
    };
  }, [Colors, isdesktop, isOnPopup]);
  return (
    <div style={styles.providerContainer}>
      <div
        className={`flex items-center justify-between w-full ${
          isOnPopup && !isdesktop && "pr-5"
        }`}
      >
        <div
          style={{ ...styles.oneCont, ...{ justifyContent: "space-evenly" } }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              fontFamily: "Poppins",
              marginRight: isdesktop ? "20px" : "40px",
            }}
          >
            <div style={styles.bottomTextGrey}>Provider ID</div>
            <div
              style={{
                color: Colors.fontBlack,
                fontSize: xxsfontsize,
                // maxWidth:
              }}
            >
              {item?.provider_id || "Not Available"}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              fontFamily: "Poppins",
              justifyContent: "center",
            }}
          >
            <div style={styles.bottomTextGrey}>Provider</div>
            <div
              style={{
                color: Colors.fontBlack,
                fontSize: xxsfontsize,
              }}
            >
              {item.service || "Not Available"}
            </div>
          </div>
        </div>
        <div
          style={{
            ...styles.oneCont,
            ...{ justifyContent: "space-evenly" },
          }}
        >
          <div
            className=""
            style={{ alignSelf: "center", marginRight: isdesktop && "20px" }}
          >
            {item?.courier_img ? (
              <img
                style={{
                  width: isdesktop ? "50px" : "80px",
                  height: isdesktop ? "50px" : "80px",
                  objectFit: "contain",
                }}
                src={item?.courier_img}
                alt=""
              />
            ) : (
              <>
                <img
                  style={{
                    width: isdesktop ? "50px" : "80px",
                    height: isdesktop ? "50px" : "80px",
                    objectFit: "contain",
                  }}
                  src={themeChanger ? courierAvatarLight : curierAvatar}
                  alt="avatar"
                />
              </>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              fontFamily: "Poppins",
            }}
          >
            <div style={styles.bottomTextGrey}>Driver Name</div>
            <div
              style={{
                color: Colors.fontBlack,
                fontSize: xxsfontsize,
              }}
            >
              {item.courier_name || returnDriverStatus()}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              fontFamily: "Poppins",
              marginRight: isdesktop && "0px",
              // marginLeft: "20px",
            }}
          >
            <div style={styles.bottomTextGrey}>Vehicle</div>
            <div
              style={{
                color: Colors.fontBlack,
                fontSize: xxsfontsize,
              }}
            >
              {item.courier_vehicle_type || returnDriverStatus()}
            </div>
          </div>
        </div>
      </div>
      <div
        className={`flex items-center justify-between w-full ${
          isOnPopup ? "mt-6" : "ml-7"
        }`}
      >
        <div
          style={{
            ...styles.oneCont,
            ...{ justifyContent: "space-evenly" },
            // ...{ justifyContent: !isdesktop && "space-evenly" },
          }}
        >
          {returCorrectNumberAndTitle()}
        </div>

        {statusSocket === "delivered" ||
        statusSocket === "canceled" ||
        statusSocket === "provider_canceled" ||
        statusSocket === "customer_canceled" ||
        statusSocket === "burq_canceled" ||
        statusSocket === "expired" ||
        statusSocket === "failed" ? (
          <div style={{ width: isdesktop ? "25%" : "80%" }} />
        ) : statusSocket === "request" ? (
          isRequestLoading ? (
            <div
              style={{
                width: isdesktop ? "25%" : "80%",
                height: "100%",
                display: "flex",
                justifyContent: "start",
                alignContent: "center",
                marginLeft: "30px",
              }}
            >
              <Loading
                color={Colors.bluePrimary}
                className="h-6 w-6 animate-spin"
              />
            </div>
          ) : (
            <button
              onClick={async () => {
                setIsRequestLoading(true);
                requestDriverHandler(item.id).then((res) => {
                  if (res) {
                    deliveries(search, pageCount, startDate, selectedStore?.id);
                  }
                });
              }}
              type="button"
              style={{
                width: isdesktop ? "48%" : "80%",
                padding: isdesktop ? "12px 0px" : "34px 0px",
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                backgroundColor: "#0062FF",
                borderRadius: isdesktop ? "10px" : "24px",
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: "600",
                fontSize: isdesktop ? "15px" : "30px",
                lineHeight: "21px",
                textAlign: "center",
                marginTop: isdesktop ? "0px" : "20px",
                color: " #FAFAFB",
              }}
            >
              Request a Driver
            </button>
          )
        ) : (
          <button
            className="hover-red"
            onClick={() => {
              setCancelVisible(true);
              setAllTimeVisible(false);
              setIsCalendarVisible(false);
            }}
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: isdesktop ? "15px" : "30px",
              lineHeight: "18px",
              borderWidth: "0.5px",
              borderRadius: isdesktop ? "10px" : "24px",
              borderColor: Colors.fontGreyLight,
              color: Colors.fontGreyLight,
              padding: isdesktop ? "12px 0px" : "34px 0px",
              width: isdesktop ? "48%" : "80%",
              alignSelf: "center",
              marginTop: isdesktop ? "0px" : "20px",
            }}
            type="button"
          >
            Cancel
          </button>
        )}
      </div>
    </div>
  );
}
