import React from "react";
import {
  useMyStore,
  useProvidersManagementStore,
  useTheme,
  userDataStore,
} from "../../../../store";
import { Formik } from "formik";
import { RiQuestionLine } from "react-icons/ri";
import InputErrorComp from "../../../shared/common/InputErrorComp";
import ButtonComp from "../../../ButtonComp/ButtonComp";
import { defaultValidationSchema } from "./validationSchema";
import Loader from "../../../shared/atoms/Loader";
import styles from "../styles/requestContact.module.css";
import classNames from "classnames";

const INITIAL_VALUES = {
  delivery_provider_name: "",
  delivery_volume_with_DSP: "",
  provider_recommendation_reason: "",
  issue_with_current_providers: "",
};

const RequestProviderTab = ({data}) => {
  const { authInfo } = userDataStore();
  const { Colors } = useTheme();
  const { isCreateMissingProviderRequestLoading, createMissingProviderRequestWorker } =
    useProvidersManagementStore();

  const requestProviderHandler = (values) => {
    const payload = {
      form_response: values,
    };
    createMissingProviderRequestWorker(
      authInfo.merchantAccountId,
      payload
    );
  };

  if (isCreateMissingProviderRequestLoading) {
    return (
      <div className={styles.loaderContainer}>
        <Loader />
      </div>
    );
  }

  return (
    <div
      className={classNames("w-full flex flex-col relative", styles.container)}
    >
      {data?.provider_contact_requests?.length > 0 && (
        <div className={classNames(styles.helperText)}>
          <span className="w-5 ">
            <RiQuestionLine color={Colors.fontGreyLight} size={18} />
          </span>
          <span className="ml-1 leading-4">
            You have already requested a contact request for this DSP.
          </span>
        </div>
      )}
      <div className="flex w-full flex-col  ">
        <Formik
          initialValues={INITIAL_VALUES}
          onSubmit={requestProviderHandler}
          validationSchema={defaultValidationSchema}
          enableReinitialize
        >
          {({
            handleChange,
            handleSubmit,
            values,
            errors,
            setFieldTouched,
            touched,
          }) => (
            <>
              <div>
                <div className={styles.inputLable}>
                  1. Which delivery provider would you like us to include in
                  our options?
                  <span className={styles.required}>Required*</span>
                </div>
                <div className={styles.inputForEdit}>
                  <textarea
                    onFocus={() => {
                      setFieldTouched("delivery_provider_name");
                    }}
                    onChange={handleChange}
                    placeholder={`Type your answer here...`}
                    name="delivery_provider_name"
                    value={values.delivery_provider_name}
                    type="text"
                    className={styles.input}
                  />
                </div>
                <InputErrorComp
                  error={
                    errors.delivery_provider_name &&
                    touched.delivery_provider_name
                      ? errors.delivery_provider_name
                      : undefined
                  }
                />
              </div>
              <div>
                <div className={styles.inputLable}>
                  2. Potential Monthly Delivery Volume you might transfer to
                  this DSP if your needs are met
                  <span className={styles.required}>Required*</span>
                </div>
                <div className={styles.inputForEdit}>
                  <textarea
                    onFocus={() => {
                      setFieldTouched("delivery_volume_with_DSP");
                    }}
                    onChange={handleChange}
                    placeholder={`Type your answer here...`}
                    name="delivery_volume_with_DSP"
                    value={values.delivery_volume_with_DSP}
                    type="text"
                    className={styles.input}
                  />
                </div>
                <InputErrorComp
                  error={
                    errors.delivery_volume_with_DSP &&
                    touched.delivery_volume_with_DSP
                      ? errors.delivery_volume_with_DSP
                      : undefined
                  }
                />
              </div>
              <div>
                <div className={styles.inputLable}>
                  3. Why would you like to work with this DSP?
                  <span className={styles.required}>Required*</span>
                </div>
                <div className={styles.inputForEdit}>
                  <textarea
                    onFocus={() => {
                      setFieldTouched("provider_recommendation_reason");
                    }}
                    onChange={handleChange}
                    placeholder={`Type your answer here...`}
                    name="provider_recommendation_reason"
                    value={values.provider_recommendation_reason}
                    type="text"
                    className={styles.input}
                  />
                </div>
                <InputErrorComp
                  error={
                    errors.provider_recommendation_reason && touched.provider_recommendation_reason
                      ? errors.provider_recommendation_reason
                      : undefined
                  }
                />
              </div>
              <div>
                <div className={styles.inputLable}>
                  4. What current delivery service issues are you experiencing,
                  if any?
                  <span className={styles.required}>Required*</span>
                </div>
                <div className={styles.inputForEdit}>
                  <textarea
                    onFocus={() => {
                      setFieldTouched("issue_with_current_providers");
                    }}
                    onChange={handleChange}
                    placeholder={`Type your answer here...`}
                    name="issue_with_current_providers"
                    value={values.issue_with_current_providers}
                    type="text"
                    className={styles.input}
                  />
                </div>
                <InputErrorComp
                  error={
                    errors.issue_with_current_providers && touched.issue_with_current_providers
                      ? errors.issue_with_current_providers
                      : undefined
                  }
                />
              </div>

              <ButtonComp
                title={"Done"}
                primary
                onClick={handleSubmit}
                extraStyles={{ marginTop: "40px" }}
              />
            </>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default RequestProviderTab;
