import React from "react";
import { VscTrash } from "react-icons/vsc";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import { useTheme } from "../../store";

const StoresDropdownList = ({ title, trash, onItemClick, item, index }) => {
  const { Colors } = useTheme();
  const { smfontsize, mdfontsize, isdesktop } = useDesktopOrLaptop();
  return (
    <div
      style={{
        backgroundColor: Colors.bgDarkSecondary,
        borderBottomWidth: "0px",
        borderColor: Colors.borderPrimary,
        padding: "10px 14px",
        margin: "6px 0px",
      }}
      className="flex hover:shadow-lg py-1 "
    >
      <button
        onClick={() => {
          onItemClick(item, index);
        }}
        type="button"
        style={{ width: "100%" }}
        className="flex flex-col focus:outline-none "
      >
        <p
          className="elipsis"
          style={{
            fontSize: mdfontsize,
            color: Colors.fontDark,
            marginBottom: !isdesktop && "10px",
          }}
        >
          {title}
        </p>
        <p
          className="elipsis"
          style={{
            fontSize: smfontsize,
            color: Colors.fontGreyLight,
          }}
        >
          {item.address}
        </p>
      </button>
      {trash && (
        <button
          onClick={() => {}}
          type="button"
          className="ml-4 focus:outline-none"
        >
          <VscTrash size={20} color={Colors.fontDark} />
        </button>
      )}
    </div>
  );
};

export default StoresDropdownList;
