import React, { useEffect, useMemo, useState } from "react";
import { debounce } from "lodash";
import { FaRegCheckCircle, FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { useOnboardingStore, useTheme } from "../../store";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";

import { atLeastOneCapital, atLeastOneDigit, atLeastOneNumber, minLength } from "./utils";

import classStyles from "./styles/password.module.css";
import classNames from "classnames";

const CheckValidation = ({ isFulfilled, text }) => {
  const { Colors } = useTheme();

  return (
    <div
      className={classStyles.validationTexts}
      style={{
        color: isFulfilled ? "#2F8C39" : Colors.fontGreyLight,
      }}
    >
      <FaRegCheckCircle />
      <span className="ml-2">{text}</span>
    </div>
  );
};

const NewPasswordComp = ({ invitationId, platformInfo, setIsButtonEnabled, password, setPassword }) => {
  const { Colors } = useTheme();

  const [isNumberMatch, setIsNumberMatch] = useState(false);
  const [isUpperCaseMatch, setIsUpperCaseMatch] = useState(false);
  const [isMinLengthMatch, setIsMinLengthMatch] = useState(false);
  const [isSymbolMatch, setIsSymbolMatch] = useState(false);

  const [repeatedPassword, setRepeatedPassword] = useState();
  const [isPassVisible, setIsPassVisible] = useState(false);
  const [isRepeatVisible, setIsRepeatVisible] = useState(false);

  const { invitationDetails, setStep, setAccountAgrementConcent, isAgreementChecked, isLoading } = useOnboardingStore();

  useEffect(() => {
    if (platformInfo && invitationDetails) {
      if (invitationDetails?.password_reset_completed && platformInfo?.redirect_url) {
        window.location.href = platformInfo.redirect_url;
      } else if (invitationDetails?.password_reset_completed) {
        setStep(3);
      }
    }
  }, [invitationDetails, setStep, platformInfo]);

  const passwordValidator = (value) => {
    setPassword(value);
    if (value.match(atLeastOneCapital)) {
      setIsUpperCaseMatch(true);
    } else {
      setIsUpperCaseMatch(false);
    }

    if (value.match(atLeastOneNumber)) {
      setIsNumberMatch(true);
    } else {
      setIsNumberMatch(false);
    }

    if (value.match(minLength)) {
      setIsMinLengthMatch(true);
    } else {
      setIsMinLengthMatch(false);
    }

    if (value.match(atLeastOneDigit)) {
      setIsSymbolMatch(true);
    } else {
      setIsSymbolMatch(false);
    }
  };

  const isButtonEnabled =
    (platformInfo?.connected_account_contract_pdf_url ? isAgreementChecked : true) &&
    isNumberMatch &&
    setIsUpperCaseMatch &&
    setIsMinLengthMatch &&
    setIsSymbolMatch &&
    !isLoading &&
    password === repeatedPassword;

  const consentChangeHandler = () => {
    const payload = {
      consent_file_version: platformInfo.connected_account_contract_pdf_url,
      consent_given: !isAgreementChecked,
    };
    setAccountAgrementConcent(invitationId, payload);
  };

  const debouncedConsentChangeHandler = debounce(() => {
    consentChangeHandler();
  }, 500);

  useEffect(() => {
    setIsButtonEnabled(isButtonEnabled);
  }, [isButtonEnabled]);

  return (
    <div className={classNames("flex flex-col items-center", classStyles.container)}>
      <div className={classStyles.title}>Create a password</div>
      <div className={classStyles.helperText}>You’ll use this to log in to create and manage deliveries. </div>
      <div className={classStyles.formContainer}>
        <div>
          <div className={classStyles.inputLable}>New Password</div>
          <div className={classStyles.inputs}>
            <input
              onChange={(event) => {
                passwordValidator(event.target.value);
              }}
              className={classStyles.passInput}
              type={isPassVisible ? "text" : "password"}
            />
            <button
              type="button"
              onClick={() => {
                setIsPassVisible((prev) => !prev);
              }}
            >
              {isPassVisible ? (
                <FaRegEye size={18} color={Colors.fontGreyLight} />
              ) : (
                <FaRegEyeSlash size={18} color={Colors.fontGreyLight} />
              )}
            </button>
          </div>
        </div>
        <div
          style={{
            marginLeft: "4px",
            marginTop: "4px",
          }}
        >
          <CheckValidation isFulfilled={isMinLengthMatch} text={"8 or more characters"} />
          <CheckValidation text={"At least 1 number"} isFulfilled={isNumberMatch} />
          <CheckValidation text={"Upper and lower case letters"} isFulfilled={isUpperCaseMatch} />
          <CheckValidation text={"At least 1 symbol"} isFulfilled={isSymbolMatch} />
        </div>
        <div className="mt-8 mb-4">
          <div className={classStyles.inputLable}>Repeat Password</div>
          <div className={classNames(classStyles.inputs, "mb-2")}>
            <input
              onChange={(event) => {
                setRepeatedPassword(event.target.value);
              }}
              type={isRepeatVisible ? "text" : "password"}
              className={classStyles.passInput}
            />
            <button
              type="button"
              onClick={() => {
                setIsRepeatVisible((prev) => !prev);
              }}
            >
              {isRepeatVisible ? (
                <FaRegEye size={18} color={Colors.fontGreyLight} />
              ) : (
                <FaRegEyeSlash size={18} color={Colors.fontGreyLight} />
              )}
            </button>
          </div>

          <CheckValidation isFulfilled={password === repeatedPassword && password && repeatedPassword} text={"Passwords match"} />
          {platformInfo?.connected_account_contract_pdf_url && (
            <div style={{ marginTop: "20px" }}>
              <input
                // id={pickup ? "pickSaveAddressId" : "dropoffSaveAddressId"}
                type="checkbox"
                onChange={(event) => {
                  debouncedConsentChangeHandler();
                }}
                checked={isAgreementChecked}
              />
              <span className={classStyles.faqText}>
                By clicking, you are confirming that you have read, understood, and agree to Burq's{" "}
                <a
                  style={{ color: Colors.bluePrimary }}
                  href={platformInfo.connected_account_contract_pdf_url}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  terms and conditions.
                </a>
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewPasswordComp;
