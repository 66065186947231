export const mapStatus = {
  pending: "requested",
  rejected: "rejected",
};

export const badgesForReuqestAccessStatus = {
  requested: { type: "info" },
  rejected: { type: "error" },
};

export const PARTNER_INDUSTRY_OPTIONS = {
  food: "Food",
  catering: "Catering",
  grocery: "Grocery",
  flowers: "Flowers",
  laundry_dry_cleaning: "Laundry / Dry Cleaning",
  pharmacy: "Pharmacy",
  ecommerce_parcel: "Ecommerce / Parcel",
  auto_parts: "Auto Parts",
  vape_tobacco: "Vape / Tobacco",
  alcohol: "Alcohol",
  cannabis_cbd: "Cannabis / CBD",
};
