import React, { useEffect, useMemo, useState } from "react";
import Header from "../../components/Header";

import { Link, useNavigate, useParams } from "react-router-dom";
import { BsChevronLeft } from "react-icons/bs";
import { Form, Formik } from "formik";

import { MdClose } from "react-icons/md";
import Cookies from "js-cookie";

import {
  useMyStore,
  useClickStore,
  useStoreAndChild,
  useTheme,
  useOrdersForMap,
  userDataStore,
  useMerchant,
  useSingleItemStore,
} from "../../store";

import "./CreateDelivery.css";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import CreateDeliveryTabsHeaders from "./CreateDeliveryTabsHeaders";
import StoreDetailsForOrder from "../OrderManagement/createOrder/StoreDetailsForOrder";
import StoreDetailsOnCreate from "./StoreDetailsOnCreate";
import CreateFieldsFormik from "../../components/formikFields/CreateFieldsFormik";
import VehicleTab from "../../components/formikFields/VehicleTab";
import TimeTab from "../../components/formikFields/TimeTab";
import ReviewTab from "../../components/formikFields/ReviewTab";
import QuotesList from "./QuotesList";
import MapForCreateDelivery from "../../components/MapForCreateDelivery";
import styles from "./styles/createDelivery.module.css";
import {
  continueHandler,
  coordinatesHandler,
  formSchemaHandler,
  initialValuesHandler,
  pickupSchemaHandler,
  submitRequestHandler,
} from "./helper";
import classNames from "classnames";

import { ROLES } from "../../components/AccountSettings/RolesSidebar/helpers";
import { useFlag } from "@unleash/proxy-client-react";

function CreateDelivery({ rerouteItem, edit, isReroute, create, isMerchantReroute }) {
  const analyticsAsHomeEnabled = useFlag("analyticsAsHomeEnabled");

  const { Colors, dropoffCoordinatesArr, setDropoffCoordinatesArr } = useTheme();

  const { selectedStore, setSelectedStore } = useStoreAndChild();

  const { loginAuth, userRole, authInfo } = userDataStore();
  const { getMerchantData, merchantData, isAdmin } = useMerchant();

  let history = useNavigate();

  const { id } = useParams();

  const { setOrdersArray, setDirectionsForOrders } = useOrdersForMap();
  const { rerouteDeliveryHandler, isAdminReroute, createRerouteInfo, rerouteLoading } = useSingleItemStore();

  const {
    quoteRes,
    setQuoteRes,
    // ! fields
    // ! create Delivery and state
    isSandbox,
    getSavedAddresses,
    deleteAddressData,
    createDeliveryInfo,
    postSaveAddres,
    deliveryLoading,
    setDeliveryLoading,
  } = useMyStore();
  const { sidepanelactive, setSidepanelactive, isBurgerMenuVisible, setIsBurgerMenuVisible } = useClickStore();

  // ! ASAP SCHEDULE

  // ! Quote field appears/disappears

  const [saveAddress, setSaveAddress] = useState(false);

  const [isClickedOnContinue, setIsClickedOnContinue] = useState(false);

  const [isStoresVisible, setIsStoresVisible] = useState(false);

  const [firstCoordinates, setFirstCoordinates] = useState({
    lat: 0,
    lng: 0,
  });

  const [zoom, setZoom] = useState(2);
  const [isAddressDefaulted, setIsAddressDefaulted] = useState(selectedStore ? true : false);

  // !  COORDINATES HANDLER
  const [pickupCoordinatesArr, setPickupCoordinatesArr] = useState([]);

  const firstLoadHandler = async () => {
    if (!authInfo) {
      await loginAuth().then((res) => {
        if (res.data?.user?.merchantAccountId) {
          if (!merchantData || !Cookies.get("prod") || !Cookies.get("sandbox")) getMerchantData(res.data.user.merchantAccountId);
        }
      });
    }
    if (rerouteItem) {
      coordinatesHandler(
        dropoffCoordinatesArr,
        setFirstCoordinates,
        setZoom,
        [{ pickup_address: rerouteItem.pickup_address }],
        setPickupCoordinatesArr,
        pickupCoordinatesArr,
        "pickup",
        true
      );

      coordinatesHandler(
        dropoffCoordinatesArr,
        setFirstCoordinates,
        setZoom,
        [{ dropoff_address: rerouteItem.dropoffs[0].dropoff_address }],
        setDropoffCoordinatesArr,
        dropoffCoordinatesArr,
        "dropoff",
        true,
        0
      );
    } else if (selectedStore) {
      let addresses = [];
      addresses.push({ pickup_address: selectedStore.address });
      coordinatesHandler(
        dropoffCoordinatesArr,
        setFirstCoordinates,
        setZoom,
        addresses,
        setPickupCoordinatesArr,
        pickupCoordinatesArr,
        "pickup",
        true
      );
    }
    if (create) {
      setDropoffCoordinatesArr([]);
    }

    setOrdersArray([]);
    setDirectionsForOrders([]);
    window.analytics.page();
  };

  useEffect(() => {
    getSavedAddresses();
  }, [deleteAddressData, saveAddress]);

  useEffect(() => {
    let subscribe = true;
    if (subscribe) {
      firstLoadHandler();
    }
    setDeliveryLoading(false);

    return () => {
      setSelectedStore();
      subscribe = false;
    };
  }, []);

  const pickupSchema = useMemo(() => {
    return pickupSchemaHandler(isAddressDefaulted);
  }, [isAddressDefaulted]);

  const formSchema = useMemo(() => {
    return formSchemaHandler(pickupSchema);
  }, [pickupSchema]);

  const initialValues = useMemo(() => {
    return initialValuesHandler(rerouteItem, isReroute);
  }, [rerouteItem, isReroute]);

  const [steps, setSteps] = useState(isReroute ? 4 : 1);

  const { isdesktop } = useDesktopOrLaptop();

  return (
    <div className="overflow-y-hidden">
      <Header
        noStores
        createBurger={"createBurgerId"}
        setQuoteRes={setQuoteRes}
        noMenu
        setSidepanelactive={setSidepanelactive}
        setIsBurgerMenuVisible={setIsBurgerMenuVisible}
        isBurgerMenuVisible={isBurgerMenuVisible}
        sidepanelactive={sidepanelactive}
      />
      <div
        style={{
          height: `calc(100vh - 76px)`,
          backgroundColor: Colors.white,
          overflowY: !isdesktop && "scroll",
        }}
        className={`flex ${isdesktop ? "flex-row" : "flex-col-reverse"}  w-full  `}
      >
        <div className={`${isdesktop ? " w-1/2 h-full kata" : "w-full h-3/4 kata"}  w-1/2 flex flex-col items-start p-6 pb-24`}>
          <div className="flex flex-row justify-between items-center w-full">
            <button
              className={`px-2 py-1 outline-none ${steps !== 1 ? "cursor-pointer" : "cursor-default"} `}
              type="button"
              onClick={() => {
                setQuoteRes();
                if (steps > 1) {
                  setSteps(steps - 1);
                }
              }}
            >
              {!isReroute ? <BsChevronLeft size={22} color={steps !== 1 ? Colors.fontDark : Colors.white} /> : <></>}
            </button>
            <h1 className={styles.createDelText}>Create Delivery</h1>
            <Link
              to={analyticsAsHomeEnabled ? "/v1/deliveries" : "/v1/home"}
              className=""
              onClick={() => {
                setQuoteRes();
              }}
            >
              <MdClose size={28} color={Colors.fontDark} />
            </Link>
          </div>

          <div className="w-full flex flex-col justify-start items-center rounded mt-6">
            <Formik
              // onSubmit={submitRequestHandler}
              validationSchema={formSchema}
              enableReinitialize={true}
              initialValues={initialValues}
            >
              {({ handleSubmit, handleChange, handleBlur, values, errors, touched, setFieldValue }) => {
                return (
                  <>
                    <CreateDeliveryTabsHeaders
                      steps={steps}
                      setSteps={setSteps}
                      rerouteItem={rerouteItem}
                      isReroute={isReroute}
                      edit={edit}
                      create={create}
                    />
                    <Form className="w-full flex flex-col justify-start relative">
                      {steps === 1 && !isReroute ? (
                        <>
                          <div className={classNames("rounded-md mb-8", styles.bgDarkSecondary)}>
                            {((isStoresVisible && !rerouteItem?.store_id) ||
                              (userRole !== ROLES.ADMIN && userRole !== ROLES.EMPLOEE && !isAdmin)) && (
                              <StoreDetailsForOrder
                                coordinatesHandler={async (addresses, arr, selected) => {
                                  setIsAddressDefaulted(true);
                                  coordinatesHandler(
                                    dropoffCoordinatesArr,
                                    setFirstCoordinates,
                                    setZoom,
                                    addresses,
                                    setPickupCoordinatesArr,
                                    pickupCoordinatesArr,
                                    arr,
                                    selected
                                  );
                                }}
                                isClickedOnContinue={isClickedOnContinue}
                              />
                            )}

                            {isAddressDefaulted || rerouteItem?.store_id ? (
                              <>
                                <StoreDetailsOnCreate
                                  store_id={rerouteItem?.store_id}
                                  setIsAddressDefaulted={setIsAddressDefaulted}
                                />
                              </>
                            ) : (
                              values.pickup.map((item, index) => {
                                return (
                                  <CreateFieldsFormik
                                    isStoresVisible={isStoresVisible}
                                    setIsStoresVisible={setIsStoresVisible}
                                    setSaveAddress={setSaveAddress}
                                    saveAddress={saveAddress}
                                    coordinatesHandler={(e) => {
                                      let addresses = [];
                                      addresses.push({ pickup_address: e });
                                      if (e) {
                                        coordinatesHandler(
                                          dropoffCoordinatesArr,
                                          setFirstCoordinates,
                                          setZoom,
                                          addresses,
                                          setPickupCoordinatesArr,
                                          pickupCoordinatesArr,
                                          "pickup",
                                          true
                                        );
                                      } else {
                                        coordinatesHandler(
                                          dropoffCoordinatesArr,
                                          setFirstCoordinates,
                                          setZoom,
                                          values.pickup,
                                          setPickupCoordinatesArr,
                                          pickupCoordinatesArr,
                                          "pickup",
                                          false
                                        );
                                      }
                                    }}
                                    handleBlur={handleBlur}
                                    item={item}
                                    index={index}
                                    key={index}
                                    arr={"pickup"}
                                    isClickedOnContinue={isClickedOnContinue}
                                    isReroute={isReroute}
                                  />
                                );
                              })
                            )}
                          </div>
                          <div className="rounded-xl">
                            {values.dropoff.map((item, index) => {
                              return (
                                <CreateFieldsFormik
                                  coordinatesHandler={(e) => {
                                    let addresses = [];
                                    addresses.push({ dropoff_address: e });

                                    if (e) {
                                      coordinatesHandler(
                                        dropoffCoordinatesArr,
                                        setFirstCoordinates,
                                        setZoom,
                                        addresses,
                                        setDropoffCoordinatesArr,
                                        dropoffCoordinatesArr,
                                        "dropoff",
                                        true,
                                        index
                                      );
                                    } else {
                                      coordinatesHandler(
                                        dropoffCoordinatesArr,
                                        setFirstCoordinates,
                                        setZoom,
                                        values.dropoff,
                                        setDropoffCoordinatesArr,
                                        dropoffCoordinatesArr,
                                        "dropoff",
                                        false,
                                        index
                                      );
                                    }
                                  }}
                                  handleBlur={handleBlur}
                                  item={item}
                                  index={index}
                                  key={index}
                                  arr={"dropoff"}
                                  coordinates={dropoffCoordinatesArr}
                                  setCoordinates={setDropoffCoordinatesArr}
                                  isClickedOnContinue={isClickedOnContinue}
                                  isReroute={isReroute}
                                />
                              );
                            })}
                          </div>
                        </>
                      ) : steps === 2 && !isReroute ? (
                        <VehicleTab isReroute={isReroute} />
                      ) : steps === 3 && !isReroute ? (
                        <TimeTab isReroute={isReroute} />
                      ) : steps === 4 ? (
                        /*
                     : steps === 3 ? (
                      <VehicleTab mdfontsize={mdfontsize} />
                    )
                     */

                        <ReviewTab
                          isReroute={isReroute}
                          setSteps={setSteps}
                          isAddressDefaulted={isAddressDefaulted}
                          isClickedOnContinue={isClickedOnContinue}
                          isMerchantReroute={isMerchantReroute}
                        />
                      ) : (
                        <QuotesList isReroute={isReroute} edit={edit} rerouteItem={rerouteItem} setSteps={setSteps} />
                      )}

                      {steps < 5 ? (
                        <div id="contDelButton" className={styles.bottomButtonCont}>
                          <button
                            onClick={() => {
                              continueHandler(
                                errors,
                                values,
                                steps,
                                setSteps,
                                setIsClickedOnContinue,
                                isAddressDefaulted,
                                isReroute
                              );
                            }}
                            type="button"
                            className={styles.continueButton}
                          >
                            Continue
                          </button>
                        </div>
                      ) : (
                        quoteRes &&
                        quoteRes.length > 0 && (
                          <div className={styles.bottomButtonCont} id="submitDelButton">
                            <button
                              id
                              type="button"
                              onClick={async () => {
                                if (Object.keys(errors).length === 0) {
                                  setDeliveryLoading(true);
                                  if (!deliveryLoading && !rerouteLoading) {
                                    await submitRequestHandler(
                                      analyticsAsHomeEnabled,
                                      values,
                                      selectedStore,
                                      isReroute,
                                      rerouteDeliveryHandler,
                                      rerouteItem,
                                      history,
                                      setSteps,
                                      saveAddress,
                                      postSaveAddres,
                                      edit,
                                      isAdminReroute,
                                      createRerouteInfo,
                                      isSandbox,
                                      id,
                                      createDeliveryInfo
                                    );
                                    // setDeliveryLoading(false);
                                    // setRerouteLoading(false);
                                  }
                                }
                              }}
                              style={{
                                backgroundColor:
                                  !deliveryLoading && !rerouteLoading ? Colors.bluePrimary : Colors.borderSecondary,
                              }}
                              className={styles.continueButton}
                            >
                              Submit
                            </button>
                          </div>
                        )
                      )}
                    </Form>
                  </>
                );
              }}
            </Formik>
          </div>
        </div>
        <div className={`w-1/2  ${isdesktop ? "w-1/2 h-full" : "w-full h-1/4"}`}>
          <MapForCreateDelivery
            zoom={zoom}
            firstCoordinates={firstCoordinates}
            pickupCoordsArr={pickupCoordinatesArr}
            isdesktop={isdesktop}
            dropoffCoordsArr={dropoffCoordinatesArr}
          />
        </div>
      </div>
    </div>
  );
}

export default CreateDelivery;
