import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { BiFilterAlt } from "react-icons/bi";
import { MdSearch } from "react-icons/md";

import Dropdown from "./Dropdown";

import { useTheme } from "../../../store";

import "./styles/SearchFilters.css";
import ActiveFiltersBarOnHome from "./ActiveFiltersBarOnHome";

const SearchFilters = ({ menuItems, title, icon = "filter" }) => {
  const [activeFiltersIds, setActiveFiltersIds] = useState([]);
  const [dropdown, setDropdown] = useState(false);

  const { Colors } = useTheme();

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setDropdown(false);
      }}
    >
      <div>
        <nav>
          <ul className="menus">
            <li className="menu-items">
              <button
                type="button"
                aria-haspopup="menu"
                aria-expanded={dropdown ? "true" : "false"}
                onClick={(e) => {
                  e.stopPropagation();
                  setDropdown((prev) => !prev);
                }}
                className={`flex flex-row items-center focus:outline-none buttonContainer`}
              >
                <div style={{ padding: "2px 0px 2px 10px" }}>
                  {icon === "search" ? (
                    <MdSearch color={Colors.fontGreyLight} size={19} />
                  ) : (
                    <BiFilterAlt color={Colors.fontGreyLight} size={19} />
                  )}
                </div>
                <div className={`titleCont`}>
                  <span className="ml-2">{title}</span>
                </div>

                <div
                  className="noScroll"
                  style={{
                    overflowX: "scroll",
                  }}
                >
                  <ActiveFiltersBarOnHome activeFilters={[...menuItems]} />
                </div>
              </button>
              <Dropdown
                depthLevel={0}
                submenus={menuItems}
                dropdown={dropdown}
                activeFiltersIds={activeFiltersIds}
                setActiveFiltersIds={setActiveFiltersIds}
              />
            </li>
          </ul>
        </nav>
      </div>
    </OutsideClickHandler>
  );
};

export default SearchFilters;
