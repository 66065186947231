import React, { useEffect, useState } from "react";
import { compose, withProps } from "recompose";
import {
  withGoogleMap,
  GoogleMap,
  DirectionsRenderer,
  Marker,
  OverlayView,
} from "react-google-maps";

// import moment from "moment";
import { geolocated } from "react-geolocated";
// import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import dropoffMarker from "../../../assets/png/pickup.png";

import pickupMarker from "../../../assets/png/dropoff.png";
import { useOrdersForMap, useTheme } from "../../../store";
import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";
import { LIGHT_MAP } from "../../../runTimeConst";

// const LIGHT_MAP = [
//   {
//     featureType: "administrative",
//     elementType: "all",
//     stylers: [
//       {
//         // saturation: "-100",
//         visibility: "off",
//       },
//     ],
//   },
//   {
//     featureType: "administrative.province",
//     elementType: "all",
//     stylers: [
//       {
//         visibility: "off",
//       },
//     ],
//   },
//   {
//     featureType: "landscape",
//     elementType: "all",
//     stylers: [
//       {
//         saturation: -100,
//       },
//       {
//         lightness: 65,
//       },
//       {
//         visibility: "on",
//       },
//     ],
//   },
//   {
//     featureType: "poi",
//     elementType: "all",
//     stylers: [
//       {
//         saturation: -100,
//       },
//       {
//         lightness: "50",
//       },
//       {
//         visibility: "off",
//       },
//     ],
//   },
// ];

const MapForOrders = compose(
  withProps({
    // googleMapURL:
    //   "https://maps.googleapis.com/maps/api/js?key=AIzaSyB56CDm80M7ITJq402N0J2H4qvuk1KNT6w&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  // withScriptjs,
  withGoogleMap
)(({}) => {
  const { ordersArray, directionsForOrders, setDirectionsForOrders } =
    useOrdersForMap();
  const { Colors } = useTheme();
  const { mdfontsize, lgfontsize, smfontsize, xsfontsize } =
    useDesktopOrLaptop();
  const [zoom, setZoom] = useState(2);

  const routeHandler = async () => {
    const DirectionsService = new window.google.maps.DirectionsService();

    let _directionsForOrders = [];

    for (let i = 0; i < ordersArray.length - 1; i++) {
      const data = await DirectionsService.route({
        origin: ordersArray[i].location,
        destination: ordersArray[i + 1].location,
        travelMode: window.google.maps.TravelMode.DRIVING,
      });
      _directionsForOrders.push(data);
    }

    setDirectionsForOrders(_directionsForOrders);
  };

  const getPixelPositionOffset = (width, height) => ({
    x: -30,
    y: -30,
  });

  useEffect(() => {
    let subscribe = true;
    if (subscribe) {
      if (ordersArray.length > 0 && zoom === 2) {
        setZoom(14);
      } else if (ordersArray.length === 0) {
        setZoom(2);
      }
      routeHandler();
    }
    return () => {
      subscribe = false;
    };
  }, [ordersArray]);

  return (
    <GoogleMap
      defaultOptions={{
        styles: LIGHT_MAP,
        clickableIcons: false,
        fullscreenControl: false,
        disableDefaultUI: true,
        mapTypeControl: false,
        gestureHandling: "greedy",
      }}
      zoom={zoom}
      center={
        ordersArray[ordersArray.length - 1]?.location || {
          lat: 0,
          lng: 0,
        }
      }
    >
      {ordersArray.length > 0 &&
        ordersArray.map((item, index) => {
          if (item.id === "store") {
            return (
              <OverlayView
                key={item.id}
                getPixelPositionOffset={getPixelPositionOffset}
                position={{
                  lat: item.location.lat,
                  lng: item.location.lng,
                }}
                mapPaneName={OverlayView?.OVERLAY_MOUSE_TARGET}
              >
                <div>
                  <div
                    style={{
                      position: "absolute",
                      top: "-80px",
                      paddingBottom: "45px",
                      left: "-50%",
                      height: "130px",
                    }}
                    className="hover:opacity-100 opacity-0"
                  >
                    <div
                      className="myShadow flex flex-col justify-between"
                      style={{
                        height: "75px",
                        width: "160px",
                        backgroundColor: Colors.white,
                        borderRadius: "12px",
                        padding: "14px 10px",
                      }}
                    >
                      <div
                        style={{
                          fontSize: xsfontsize,
                          color: Colors.fontGreyLight,
                        }}
                      >
                        Pickup:
                      </div>
                      <div
                        style={{ fontSize: smfontsize, color: Colors.fontDark }}
                        className="truncate"
                      >
                        {item.store.name}
                      </div>
                    </div>
                  </div>
                  <img
                    style={{
                      height: "42px",
                      width: "42px",
                    }}
                    src={pickupMarker}
                    alt=""
                  />
                </div>
              </OverlayView>
            );
          } else {
            return (
              <OverlayView
                key={item.id}
                getPixelPositionOffset={getPixelPositionOffset}
                position={{
                  lat: item.location.lat,
                  lng: item.location.lng,
                }}
                mapPaneName={OverlayView?.OVERLAY_MOUSE_TARGET}
              >
                <div>
                  <div
                    style={{
                      position: "absolute",
                      top: "-80px",
                      paddingBottom: "45px",
                      left: "-50%",
                      height: "130px",
                    }}
                    className="hover:opacity-100 opacity-0"
                  >
                    <div
                      className="myShadow flex flex-col justify-between"
                      style={{
                        height: "75px",
                        width: "160px",
                        backgroundColor: Colors.white,
                        borderRadius: "12px",
                        padding: "14px 10px",
                      }}
                    >
                      <div
                        style={{
                          fontSize: xsfontsize,
                          color: Colors.fontGreyLight,
                        }}
                      >
                        Stop {index} :
                      </div>
                      <div
                        className="truncate"
                        style={{ fontSize: smfontsize, color: Colors.fontDark }}
                      >
                        {item.address}
                      </div>
                    </div>
                  </div>
                  <img
                    style={{
                      height: "42px",
                      width: "42px",
                    }}
                    src={dropoffMarker}
                    alt=""
                  />
                </div>
              </OverlayView>
            );
          }
        })}

      {directionsForOrders?.length > 0 &&
        directionsForOrders.map((item, index) => {
          return (
            <DirectionsRenderer
              key={index}
              options={{
                suppressMarkers: true,
                polylineOptions: { strokeColor: "#1460EF" },
              }}
              directions={item}
            />
          );
        })}
    </GoogleMap>
  );
});

// !

function OrdersMap() {
  return (
    <div className=" w-full h-full relative ">
      <MapForOrders
        containerElement={<div style={{ height: "100%", width: "100%" }} />}
      />
    </div>
  );
}
export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 1,
})(OrdersMap);
