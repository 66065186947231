import { createStore } from "aniuta";
import { useState } from "react";
import { postCreateReferral } from "../utils/api/referrals";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";

const useReferralsStore = createStore({
  name: "useReferralsStore",
  Store: () => {
    const referralCreationHandler = async (id, payload) => {
      try {
        const { data } = await postCreateReferral(id, payload);
        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
        return "error";
      }
    };
    return { referralCreationHandler };
  },
});

export default useReferralsStore;
