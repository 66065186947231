import React from "react";

function PreferenceIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32">
      <path
        fill="#3DD598"
        d="M26.98 16.867c-.08 2.767-.483 6.597-2.98 8.636V29a1 1 0 01-2 0v-4c0-.334.167-.646.445-.832 2.125-1.416 2.465-4.878 2.536-7.358a.99.99 0 00-.891-1.015L18 15.117V16a1 1 0 01-2 0V8.5a.5.5 0 00-1 0V20a1 1 0 01-1.497.868s-1.942-1.111-2.585-1.49a2.665 2.665 0 00-2.363-.174c.625.626 1.481 1.482 2.15 2.149 1.331 1.327 2.255 2.247 3.611 2.699A.998.998 0 0115 25v4a1 1 0 01-2 0v-3.317c-1.42-.636-2.425-1.637-3.653-2.861-1.254-1.25-3.055-3.055-3.055-3.055a1 1 0 01.001-1.413c1.488-1.489 3.807-1.777 5.639-.7.235.138.644.375 1.068.619V8.5C13 7.122 14.122 6 15.5 6S18 7.122 18 8.5v4.605l6.311.701c1.541.172 2.714 1.516 2.669 3.061zM6 12c2.757 0 5-2.243 5-5S8.757 2 6 2 1 4.243 1 7s2.243 5 5 5zm0-8c1.654 0 3 1.346 3 3s-1.346 3-3 3-3-1.346-3-3 1.346-3 3-3z"
      ></path>
      <path fill="#3DD598" d="M29 2h-7a1 1 0 00-1 1v7a1 1 0 001 1h7a1 1 0 001-1V3a1 1 0 00-1-1zm-1 7h-5V4h5v5z"></path>
    </svg>
  );
}

function RerouteIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="none" viewBox="0 0 22 22">
      <g fill="#0062FF" clipPath="url(#clip0_15577_167616)">
        <path d="M11 0C9.883 0 8.974.91 8.974 2.027v1.381c.101 2.684 3.951 2.684 4.053 0V2.027C13.027.909 12.117 0 11 0zM19.98 8.98H18.6c-1.118 0-2.027.91-2.027 2.027s.91 2.026 2.027 2.026h1.381c2.685-.101 2.685-3.95 0-4.053zM5.428 11.007c0-1.117-.909-2.027-2.026-2.027H2.02c-2.684.102-2.684 3.952 0 4.053h1.382c1.117 0 2.026-.909 2.026-2.026zM17.806 7.068l.977-.977c.79-.79.79-2.075 0-2.866a2.029 2.029 0 00-2.866 0l-.977.977c-1.826 1.97.896 4.692 2.866 2.866zM4.194 14.947l-.976.977c-.79.79-.79 2.075 0 2.865s2.075.79 2.865 0l.977-.977c1.827-1.97-.896-4.692-2.866-2.865zM17.806 14.947c-1.97-1.827-4.692.896-2.866 2.865l.977.977c.79.79 2.076.79 2.866 0s.79-2.076 0-2.866l-.977-.976zM6.083 3.224c-1.97-1.826-4.692.896-2.865 2.866l.976.977c.79.79 2.076.79 2.866 0s.79-2.076 0-2.866l-.977-.977zM11 16.578c-1.117 0-2.026.91-2.026 2.027v1.381c.101 2.684 3.951 2.684 4.053 0v-1.381c0-1.118-.91-2.027-2.027-2.027z"></path>
      </g>
      <defs>
        <clipPath id="clip0_15577_167616">
          <path fill="#fff" d="M0 0H22V22H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

function VehicleTypeIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="none" viewBox="0 0 26 26">
      <path
        fill="#FF974A"
        d="M4.678 12.535A4.683 4.683 0 000 17.213c0 1.304.713 2.88 2.12 4.681a21.44 21.44 0 002.038 2.258l.52.485.52-.485c.042-.04 1.034-.97 2.039-2.258 1.406-1.802 2.119-3.377 2.119-4.68a4.683 4.683 0 00-4.678-4.679zm0 6.192a1.515 1.515 0 01-1.513-1.514c0-.834.678-1.513 1.513-1.513.834 0 1.513.679 1.513 1.513 0 .835-.679 1.514-1.513 1.514zM21.322 1.363a4.683 4.683 0 00-4.678 4.678c0 1.304.713 2.88 2.12 4.682a21.432 21.432 0 002.038 2.257l.52.485.52-.485c.042-.04 1.034-.97 2.039-2.257C25.287 8.92 26 7.345 26 6.04a4.683 4.683 0 00-4.678-4.678zm0 6.192a1.515 1.515 0 01-1.513-1.514c0-.834.678-1.513 1.513-1.513.835 0 1.513.679 1.513 1.513 0 .835-.678 1.514-1.513 1.514z"
      ></path>
      <path
        fill="#FF974A"
        d="M18.53 24.355H7.724V22.83h10.804c1.12 0 2.031-.91 2.031-2.03 0-1.12-.91-2.032-2.03-2.032h-4.26a3.558 3.558 0 01-3.555-3.554 3.558 3.558 0 013.554-3.555h4.006v1.523H14.27c-1.12 0-2.03.912-2.03 2.032s.91 2.03 2.03 2.03h4.26a3.558 3.558 0 013.555 3.555 3.558 3.558 0 01-3.555 3.555z"
      ></path>
    </svg>
  );
}

export { PreferenceIcon, RerouteIcon, VehicleTypeIcon };
