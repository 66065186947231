import axios from "axios";
import apiUrls from "../../constants/apiUrls";
import { generateQueryParams } from "./utils";

export const setPaymentsHistoryForMerchant = (id, params) => {
  const urlWithQueryParams = generateQueryParams(
    apiUrls.MERCHANT_ACCOUNTS_PAYMENTS_HISTORY(id),
    params
  );
  return axios.get(urlWithQueryParams, {
    headers: { "x-burq-client": "client-dashboard" },
  });
};
