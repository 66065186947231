import React from "react";
import { BiCheckbox, BiCheckboxChecked } from "react-icons/bi";
import Colors from "../../assets/Colors";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import { useTheme } from "../../store";

const CheckBoxComp = ({ name, label, checked = false, onChecked, margin, labelStyle={} }) => {
  const { smFontSize, mdFontSize, isdesktop } = useDesktopOrLaptop();
  const { Colors, themeChanger } = useTheme();

  const styles = {
    input: {
      minWidth: "20px",
      minHeight: "20px",
      backgroundColor: "red",
    },
    inputLable: {
      fontFamily: "Poppins",
      fontWeight: "500",
      color: Colors.fontGreyLight,
      fontSize: mdFontSize,
      marginLeft: "15px",
      ...labelStyle,
    },
    inputForEdit: {
      // width: "100%",
      padding: "6px 4px",
      display: "flex",
      margin: margin ? margin : undefined,
    },
  };

  const onChange = () => onChecked({ checked: !checked });

  return (
    <div onClick={onChange} style={styles.inputForEdit}>
      {checked ? (
        <BiCheckboxChecked size={isdesktop ? 30 : 28} color="#0062FF" />
      ) : (
        <BiCheckbox size={isdesktop ? 30 : 28} color={Colors.fontDark} />
      )}

      <label style={styles.inputLable}>{label}</label>
    </div>
  );
};

export default CheckBoxComp;
