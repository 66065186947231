import React from "react";

import {
  useTheme,
  useSingleItemStore,
  useMyStore,
  useClickStore,
  useStoreAndChild,
  useMerchant,
  useFilter,
  useAdmin,
} from "../store";

function CancelDeliveryRequest({ index, request_id, setCancelVisible, setIsCalendarVisible, insideSidebarFunc, isonAdmin }) {
  const { Colors } = useTheme();
  const { startDate } = useClickStore();
  const { cancelAdminDelivery, adminDelsListData, setAdminDelsListData } = useAdmin();

  const { item } = useSingleItemStore();

  const { selectedStore } = useStoreAndChild();

  const { cancelDelivery, isSandbox, pageCount, deliveries } = useMyStore();
  return (
    <div
      style={{ backgroundColor: Colors.white }}
      className="flex flex-col  py-16  justify-center items-startrounded-md w-full overflow-hidden"
    >
      <div style={{ color: Colors.fontDark }} className="text-2xl  font-bold text-center w-full mb-16">
        Are you Sure?
      </div>
      <div className="w-full flex justify-evenly">
        <button
          onClick={async () => {
            if (request_id) {
              if (!insideSidebarFunc) {
                setIsCalendarVisible(true);
              }

              if (isonAdmin) {
                cancelAdminDelivery({ test_mode: isSandbox }, request_id).then((res) => {
                  if (res !== "error") {
                    const _adminDelsListData = [...adminDelsListData.data];
                    _adminDelsListData[index] = res;
                    setAdminDelsListData({
                      ...adminDelsListData,
                      data: [..._adminDelsListData],
                    });
                  }

                  // if (isAdmin) {
                  //   let params = {
                  //     test_mode: isSandbox,
                  //     provider_id: selectedProvider?.id ?? "",
                  //     statuses: filterArray?.join(",") ?? "",
                  //     start_date: startDate?.start ?? "",
                  //     end_date: startDate?.end ?? "",
                  //   };
                  //   getAdminDeliveries(params);
                  // }
                  setCancelVisible(false);
                });
              } else {
                cancelDelivery("dsd", request_id).then((res) => {
                  if (insideSidebarFunc) {
                    insideSidebarFunc();
                  }
                  deliveries("", pageCount, startDate, selectedStore?.id);
                  setCancelVisible(false);
                });
              }
              // cancelDelivery("dsd", request_id).then((res) => {
              //   if (res) {
              //     if (isAdmin) {
              //       let params = {
              //         test_mode: isSandbox,
              //         provider_id: selectedProvider?.id ?? "",
              //         statuses: filterArray?.join(",") ?? "",
              //         start_date: startDate?.start ?? "",
              //         end_date: startDate?.end ?? "",
              //       };
              //       getAdminDeliveries(params);
              //     }
              //     deliveries("", pageCount, startDate, selectedStore?.id);
              //   }
              //   if (insideSidebarFunc) {
              //     insideSidebarFunc();
              //   }
              // });
            } else {
              cancelDelivery("dsd", item.id).then((res) => {
                if (res) {
                  setCancelVisible(false);
                }
              });
            }
          }}
          className="focus:outline-none px-12 py-1.5 bg-red-50 rounded-xl text-red-500 border border-red-500 text-center"
        >
          Yes
        </button>
        <button
          onClick={() => {
            setCancelVisible(false);
            if (request_id && !insideSidebarFunc) {
              setIsCalendarVisible(true);
            }
          }}
          className="focus:outline-none px-12 py-1.5 bg-blue-600 rounded-xl text-indigo-50 text-center"
        >
          No
        </button>
      </div>
    </div>
  );
}

export default CancelDeliveryRequest;
