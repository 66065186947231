import React from 'react';
import styles from "./styles/paymentMethodListEmptyState.module.css";

const PaymentMethodListEmptyState = () => {
    return (
      <tr className={styles.tableRow}>
        <td className="py-4 px-4 text-center" colSpan="5">
          No Payment Methods Found!
        </td>
      </tr>
    );
};

export default PaymentMethodListEmptyState;
