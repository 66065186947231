import { useState } from "react";
import * as Sentry from "@sentry/react";
import { createStore } from "aniuta";
import { toast } from "react-toastify";
import { getDeliveryIncidents } from "../utils/api/merchantIncidents";

const useDeliveryIncidents = createStore({
  name: "useDeliveryIncidents",
  Store: () => {
    const [searchDeliveryId, setSearchDeliveryId] = useState();
    const [searchByStoreId, setSearchByStoreId] = useState();
    const [searchByStatus, setSearchByStatus] = useState();
    const [searchByExternalOrderRef, setSearchByExternalOrderRef] = useState();

    const [deliveryIncidents, setDeliveryIncidents] = useState();
    const [isDeliveryIncidentLoading, setIsDeliveryIncidentLoading] = useState(false);

    const getDeliveryIncidentsWorker = async (id, params) => {
      setIsDeliveryIncidentLoading(true);
      try {
        const { data } = await getDeliveryIncidents(id, {
          store_id: searchByStoreId || null,
          delivery_id: searchDeliveryId || null,
          status: searchByStatus?.value || null,
          external_order_ref: searchByExternalOrderRef || null,
          limit: 30,
          ...params,
        });
        setDeliveryIncidents(data);
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsDeliveryIncidentLoading(false);
      }
    };

    return {
      searchDeliveryId,
      setSearchDeliveryId,
      searchByStoreId,
      setSearchByStoreId,
      searchByStatus,
      setSearchByStatus,
      searchByExternalOrderRef,
      setSearchByExternalOrderRef,
      deliveryIncidents,
      setDeliveryIncidents,
      isDeliveryIncidentLoading,
      setIsDeliveryIncidentLoading,
      getDeliveryIncidentsWorker,
    };
  },
});

export default useDeliveryIncidents;
