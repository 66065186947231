import React from "react";
import "./IllustrationElement.css";
import { useTheme } from "../../../../store";

const IllustrationElement = () => {
  const { Colors } = useTheme();

  return (
    <div
      style={{ backgroundColor: Colors.createFirstC }}
      className="illustration__container"
    >
      <div className="illustration__containerBackground">
        <svg
          width="160"
          height="160"
          viewBox="0 0 160 160"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.5"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M95.4364 -6.41777C121.205 -3.10253 147.172 0.915383 164.56 20.1773C183.302 40.9388 194.658 69.1155 188.155 96.2932C181.715 123.21 157.27 139.576 132.853 152.7C106.412 166.912 77.8003 180.117 49.1956 170.983C15.5703 160.245 -14.6761 136.331 -24.4277 102.475C-34.7443 66.6577 -27.9705 24.0501 -0.352662 -1.03982C24.5259 -23.6412 62.0665 -10.711 95.4364 -6.41777Z"
            stroke={Colors.createFirstColors}
          />
          <path
            opacity="0.5"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M90.9802 24.8397C107.335 26.9558 123.817 29.5205 134.853 41.8153C146.749 55.0673 153.956 73.0525 149.829 90.3999C145.742 107.581 130.226 118.027 114.729 126.404C97.9461 135.476 79.7865 143.905 61.631 138.074C40.2889 131.22 21.0914 115.956 14.9021 94.3457C8.35409 71.4836 12.6534 44.2873 30.1826 28.2725C45.9731 13.846 69.8002 22.0993 90.9802 24.8397Z"
            stroke={Colors.createFirstColors}
          />
          <path
            opacity="0.5"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M86.2972 45.9442C96.2986 47.2315 106.377 48.7916 113.126 56.271C120.4 64.3326 124.808 75.2736 122.284 85.8266C119.785 96.2782 110.297 102.633 100.82 107.729C90.557 113.248 79.4522 118.375 68.3499 114.828C55.299 110.659 43.5595 101.373 39.7746 88.227C35.7705 74.3192 38.3996 57.7748 49.1188 48.0324C58.7749 39.2563 73.3454 44.2771 86.2972 45.9442Z"
            stroke={Colors.createFirstColors}
          />
          <path
            opacity="0.5"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M93.8221 5.49329C115.943 8.33241 138.235 11.7733 153.161 28.2689C169.25 46.0487 178.999 70.1787 173.417 93.4532C167.888 116.504 146.903 130.52 125.942 141.759C103.244 153.93 78.6824 165.239 54.1268 157.416C25.2613 148.221 -0.703657 127.741 -9.07492 98.7472C-17.9312 68.0738 -12.1162 31.5855 11.5922 10.0989C32.9492 -9.2566 65.1758 1.81662 93.8221 5.49329Z"
            stroke={Colors.createFirstColors}
          />
        </svg>
      </div>
      <div className="illustration__elements">
        <div className="illustration__element illustration__element--right illustration__element--1">
          <div className="illustration__elementType">🚗</div>
          <div className="illustration__elementWireframe"></div>
        </div>
        <div className="illustration__element illustration__element--left illustration__element--2">
          <div className="illustration__elementType">🚴‍♀️</div>
          <div className="illustration__elementWireframe"></div>
        </div>
        <div className="illustration__element illustration__element--right illustration__element--3">
          <div className="illustration__elementType">🚚</div>
          <div className="illustration__elementWireframe"></div>
        </div>
      </div>
    </div>
  );
};

export default IllustrationElement;
