import React, { useEffect } from "react";
import { useFlag } from "@unleash/proxy-client-react";
import { useParams } from "react-router-dom";
import { useMyStore, useInvoices } from "../../../../store";
import Pagination from "../../../shared/Pagination";
import Transactions from "./Transactions";
import Loader from "../../../shared/atoms/Loader";
import { InvoiceCardsHeader } from "./InvoiceCardsHeader";
import styles from "./styles/styles.module.css";
import InvoiceCSVExport from "./InvoiceCSVExport";
import classNames from "classnames";
import InvoiceStoresDetails from "./InvoiceStoresDetails";
import StoreFilter from "./StoreFilter";
import Activities from "./Activities";
import InvoicePDF from "./DetailsSection/InvoicePDF";
import Cookies from "js-cookie";
import { setCookie } from "../../../../utils";

const InvoiceDetails = () => {
  const { setIsSandbox } = useMyStore();
  const {
    selectedInvoice,
    setSelectedInvoice,
    transactions,
    setTransactions,
    getTransactionsWorker,
    getInvoiceWorker,
    getInvoicesStoresWorker,
    filterStore,
    setFilterStore,
    setInvoiceStores,
    excludeStoreTransactions,
    setExcludeStoreTransactions,
    invoiceStores,
  } = useInvoices();

  const { id } = useParams();

  const getTransactionsHandler = async (param = {}) => {
    const params = {
      ending_before: param?.ending_before || null,
      starting_after: param?.starting_after || null,
      store_id: param?.storeId || null,
      exclude_store_transactions: param?.excludeStoreTransactions || null,
    };
    await getTransactionsWorker(selectedInvoice?.id, params);
  };

  useEffect(() => {
    if (id !== selectedInvoice?.id) {
      getInvoiceWorker(id).then((res) => {
        setSelectedInvoice(res);
        setIsSandbox(res?.test_mode);
        setCookie("testModeCookie", res?.test_mode ? "true" : "false");
      });
    }
  }, [id]);

  useEffect(() => {
    if (selectedInvoice) {
      getTransactionsHandler({ id: selectedInvoice?.id });
      getInvoicesStoresWorker(selectedInvoice.id);
    }
  }, [selectedInvoice]);

  useEffect(() => {
    if (selectedInvoice) {
      getTransactionsHandler({ storeId: filterStore?.id, excludeStoreTransactions });
    }
  }, [selectedInvoice, filterStore, excludeStoreTransactions]);

  useEffect(() => {
    return () => {
      setSelectedInvoice();
      setFilterStore();
      setExcludeStoreTransactions();
      setTransactions();
      setInvoiceStores();
    };
  }, []);

  if (!selectedInvoice) {
    return <Loader />;
  }
  return (
    <div className={classNames(styles.invoiceDetailsContainer, "kata")}>
      <InvoiceCardsHeader />
      <div className="w-full flex items-start justify-between mt-7 gap-7">
        <div className={styles.dataTablesContainer}>
          {!!(!selectedInvoice?.store_name && invoiceStores?.data?.length) && (
            <div className={styles.storesDataContainer}>
              <div className={styles.contTitle}>Stores Summary</div>
              <InvoiceStoresDetails />
            </div>
          )}
          <div className={styles.container}>
            <div className="w-full justify-between flex">
              <div className={styles.contTitle}>Transactions</div>
              <div className="flex justify-end">
                {!selectedInvoice?.store_name && <StoreFilter />}
                <InvoiceCSVExport
                  csvToolTip={
                    '"Transaction Time" refers to the time of delivery initiation. Usually this is the time when a delivery provider dispatches driver or otherwise begins its fulfillment process for the delivery. The timestamps are in ISO8601 format, using UTC time zone. "Amount" is represented in the smallest denomination of your local currency. In USD, for example, the unit is cent.'
                  }
                  invoiceId={selectedInvoice?.id}
                />
                <InvoicePDF />
              </div>
            </div>
            <Transactions />
            <div className="py-5">
              <Pagination
                data={transactions}
                onGetPaginationData={getTransactionsHandler}
                dependencies={[selectedInvoice, transactions]}
              />
            </div>
          </div>
        </div>
        <div
          className={styles.container}
          style={{
            width: "calc(25% - 20px)",
            height: "calc(100vh - 480px)",
          }}
        >
          <div className={styles.paymentsTitle}>Activities</div>
          <Activities />
        </div>
      </div>
    </div>
  );
};

export default InvoiceDetails;
