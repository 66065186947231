import React, { useRef } from "react";
import styles from "./styles.module.css";

import { BsFillInfoCircleFill } from "react-icons/bs";
import { RiFileCopyFill } from "react-icons/ri";
import classNames from "classnames";
import { ColorPicker } from "primereact/colorpicker";
import { DUMMY_ROUTE } from "./helper";
import ImageContainer from "./ImageContainer";
import ToolTip from "../../../Tooltip/Tooltip";
import CopyToClipboard from "../../../shared/atoms/CopyToClipboard";

const BrandingActionSection = ({
  title,
  infoText,
  isColor,
  setColorForMap,
  colorForMap,
  setRoutesForMap,
  noBorder,
  type,
  setImageUrl,
  imageUrl,
  tooltipPlace,
  backendImage,
}) => {
  const copyText = useRef();
  const handelCopy = () => {
    copyText.current.select();
    document.execCommand("copy");
  };

  return (
    <div className={styles.container}>
      <div
        className={classNames(styles.flexContainer, {
          [styles.noBorder]: noBorder,
        })}
      >
        <div className={styles.text}>
          {title}{" "}
          {infoText && (
            <span data-tip data-for={`tootltip-${infoText}`}>
              <ToolTip
                textStyles={{}}
                id={`tootltip-${infoText}`}
                disable={false}
                text={infoText}
                place={tooltipPlace}
              />

              <BsFillInfoCircleFill
                className={classNames("mt-1 ml-2", styles.infoIcon)}
              />
            </span>
          )}
        </div>
        <div>
          {isColor && (
            <div className={styles.colorInputContainer}>
              <ColorPicker
                inputClassName={classNames("w-full", styles.colorInput)}
                value={colorForMap}
                onChange={(e) => {
                  setColorForMap("#" + e.value);
                  setRoutesForMap(null);
                  setTimeout(() => {
                    setRoutesForMap(DUMMY_ROUTE);
                  }, 100);
                }}
              />
              <div
                style={{ color: colorForMap }}
                className={classNames(
                  "h-auto flex items-center px-1",
                  styles.colorText
                )}
              >
                {/* # */}
                <input
                  className={classNames(
                    "w-full h-auto focus:outline-none",
                    styles.colorText
                  )}
                  onChange={(e) => {
                    setColorForMap(e.target.value);
                    setRoutesForMap(null);
                    setTimeout(() => {
                      setRoutesForMap(DUMMY_ROUTE);
                    }, 100);
                  }}
                  type="text"
                  value={colorForMap}
                  name=""
                  id=""
                  ref={copyText}
                />
                <CopyToClipboard
                  extraContainerClasses={styles.copyIcon}
                  copyText={colorForMap}
                />
                {/* <span className="cursor-pointer" onClick={handelCopy}>
                  <RiFileCopyFill size={16} className={styles.infoIcon} />
                </span> */}
              </div>{" "}
            </div>
          )}

          {type && (
            <div className="cursor-pointer">
              <ImageContainer
                backendImage={backendImage}
                setImageUrl={setImageUrl}
                imageUrl={imageUrl}
                type={type}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BrandingActionSection;
