import { LetThemSignUpIcon, RewardIcon, SomeoneNewIcon } from "./Svgs";
import * as Yup from "yup";

export const cardsData = [
  {
    name: "Invite someone new",
    logo: <SomeoneNewIcon />,
    description: "Know a business that can benefit from Burq? Tell us about them!",
    id: 0,
  },
  {
    name: "Let them sign up",
    logo: <LetThemSignUpIcon />,
    description: "We'll help them get started with their Burq account.",
    id: 1,
  },
  {
    name: "Receive your reward!",
    logo: <RewardIcon />,
    description: "Once they subscribe and make 10 deliveries, you will receive your reward!",
    id: 2,
  },
];

export const submitHandler = async (values, setIsLoading, referralCreationHandler, merchantId, setResponseData) => {
  const data = await referralCreationHandler(merchantId, values);
  setResponseData(data);
  setIsLoading(false);
  return data;
};

export const referralsFormSchema = Yup.object().shape({
  referee_business_name: Yup.string().required("Field is required."),
  referee_phone_number: Yup.string().required("Field is required."),
  referee_business_email: Yup.string()
    .notRequired("")
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email format"),
  referee_business_poc: Yup.string().required("Field is required."),
  referrer_name: Yup.string().required("Field is required."),
  referrer_email: Yup.string()
    .required("Field is required.")
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email format"),
  referrer_phone_number: Yup.string().required("Field is required."),
});
