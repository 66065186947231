import axios from "axios";
import apiUrls from "../../constants/apiUrls";
import { generateQueryParams } from "./utils";

// export const getPresignedUrl = (merchantId, query) => {
//   const urlWithQueryParams = generateQueryParams(
//     apiUrls.GET_PRESIGNED_URL(merchantId),
//     query
//   );
//   return axios.put(urlWithQueryParams, {
//     headers: { "x-burq-client": "client-dashboard" },
//   });
// };
export const postStartUpload = (merchantId, payload) => {
  console.log("object", payload);
  return axios.post(apiUrls.POST_START_BULK_UPLOAD(merchantId), payload, {
    headers: { "x-burq-client": "client-dashboard" },
  });
};
export const getImports = (merchantId, query) => {
  const urlWithQueryParams = generateQueryParams(
    apiUrls.GET_CSV_ORDERS_LIST(merchantId),
    query
  );
  return axios.get(urlWithQueryParams, {
    headers: { "x-burq-client": "client-dashboard" },
  });
};
export const getCsvOrderCHild = (merchantId, orderId, query) => {
  const urlWithQueryParams = generateQueryParams(
    apiUrls.GET_CSV_ORDER_CHILD_INFO(merchantId, orderId),
    query
  );
  return axios.get(urlWithQueryParams, {
    headers: { "x-burq-client": "client-dashboard" },
  });
};
