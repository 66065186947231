import classNames from "classnames";

import styles from "./styles.module.css";
import Hyperlink from "../../shared/atoms/Hyperlink";
import { RerouteIcon } from "../Svgs";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { mapRerouteReason } from "../../../utils/helpers";

const ReroutedDeliveryBanner = ({ deliveryId, rerouteStatus, rerouteReason, deliveryStatus }) => {
  let history = useNavigate();
  const [label, setLabel] = useState(null);

  const getRerouteReason = () => {
    if (rerouteReason) {
      return mapRerouteReason[rerouteReason];
    } else if (rerouteStatus === "provider_search" && deliveryStatus === "provider_canceled") {
      return "Provider canceled"
    } else return "NA"
  }

  const getLabel = () => {
    if (rerouteStatus === "provider_search") {
      return "Attempting reroute"
    } else if (rerouteStatus === "rerouted") {
      return "Delivery rerouted"
    } else return null
  }

  useEffect(() => {
    const label = getLabel();
    setLabel(label);
  }, [rerouteStatus])


  if (!label) return null;
  return (
    <div className={classNames({ [styles.container]: true, [styles.borderAttemptingReroute]: rerouteStatus === "provider_search" })} >
      <div className={styles.bannerLeftSection}>
        <RerouteIcon />
        <div className={styles.bannerLeftContent}>
          <div className={styles.titleText}>{label}</div>
          {getRerouteReason() && <div className={styles.reasonText}>Reason: {getRerouteReason()}</div>}
        </div>
      </div>

      {deliveryId && <div className={styles.bannerRightSection}>
        <Hyperlink
          text="View new delivery"
          onClick={() => {
            history(`?deliveryId=${deliveryId}`);
          }}
          externalClassName={styles.hyperlink}
        />
        <div className={classNames(styles.deliveryLink)}>
          New delivery ID: {deliveryId}
        </div>
      </div>}
    </div >
  );
};

export default ReroutedDeliveryBanner;
