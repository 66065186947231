import React, { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import { AiOutlineClear } from "react-icons/ai";
import { MdStorefront } from "react-icons/md";
import {
  useMyStore,
  useStoreAndChild,
  useTheme,
  userDataStore,
} from "../../../../../store";
import { useDesktopOrLaptop } from "../../../../../services/useDesktopOrLaptop";
import CustomTypeAndSearch from "../../../../shared/CustomTypeAndSearch";

function FilterWithStores({
  statementsListHandler,
  selectedStoreForStatements,
  setSelectedStoreForStatements,
  connectedTriggerer,
  setStoresTrigerer,
}) {
  const { Colors } = useTheme();
  const { isSandbox } = useMyStore();
  const { smfontsize, isdesktop } = useDesktopOrLaptop();
  const { authInfo } = userDataStore();
  const { allStoresByUser, getAllStoresByUser } = useStoreAndChild();

  const [isListVisible, setIsListVisible] = useState(false);
  const [searchedStore, setSearchedStore] = useState("");

  const debounceFn = useCallback(
    debounce((search) => {
      getAllStoresByUser(authInfo.merchantAccountId, false, {
        search_phrase: search,
      });
    }, 500),
    []
  );

  useEffect(() => {
    if (searchedStore) {
      setSearchedStore("");
    }
    if (selectedStoreForStatements) {
      setSelectedStoreForStatements();
    }
    if (isListVisible) {
      setIsListVisible(false);
    }
  }, [isSandbox, connectedTriggerer]);

  useEffect(() => {
    if (authInfo?.merchantAccountId) {
      getAllStoresByUser(authInfo.merchantAccountId);
    }
  }, [authInfo]);

  const listItemClickHandler = (listItem) => {
    if (selectedStoreForStatements?.id === listItem.id) {
      setSearchedStore("");
      setSelectedStoreForStatements();
      setIsListVisible(false);
      statementsListHandler({
        merchantId: authInfo?.merchantAccountId,
        store: "empty",
      });
    } else {
      setSearchedStore(listItem.name);
      setSelectedStoreForStatements(listItem);
      setStoresTrigerer((prev) => !prev);
      statementsListHandler({
        merchantId: authInfo?.merchantAccountId,
        store: listItem,
      });
    }
  };

  const onQueryChangeHandler = (value) => {
    debounceFn(value);
    setSearchedStore(value);
  };

  return (
    <div className="relative">
      <div
        style={{
          position: "relative",
        }}
        className="flex items-center"
      >
        <button
          style={{
            borderWidth: "1px",
            borderColor: "#D8D8D8",
            borderRadius: "16px",
            padding: isdesktop ? "4px 0px" : "4px 4px",
            marginLeft: "0px",
            borderStyle: selectedStoreForStatements ? "solid" : "dashed",
          }}
          onClick={() => {
            setIsListVisible(true);
          }}
          type="button"
          className="flex flex-row items-center focus:outline-none "
        >
          {selectedStoreForStatements ? (
            <button
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                setSearchedStore("");
                setSelectedStoreForStatements();
                setIsListVisible(false);
                statementsListHandler({
                  merchantId: authInfo?.merchantAccountId,
                  store: "empty",
                });
              }}
              style={{ padding: "2px 0px 2px 10px" }}
            >
              <AiOutlineClear
                color={Colors.fontGreyLight}
                size={isdesktop ? 22 : 26}
              />
            </button>
          ) : (
            <div style={{ padding: "2px 0px 2px 10px" }}>
              <MdStorefront color={Colors.fontGreyLight} size={19} />
            </div>
          )}
          <div
            style={{
              fontFamily: "Poppins",
              fontSize: smfontsize,
              color: Colors.fontGreyLight,
              display: "flex",
              alignItems: "center",
              padding: selectedStoreForStatements
                ? "2px 6px 2px 0px"
                : "2px 10px 2px 0px",
            }}
          >
            <span style={{ marginLeft: "8px" }}>Store</span>
          </div>
          {selectedStoreForStatements && (
            <div
              style={{
                padding: "2px 10px 2px 6px",
                color: Colors.fontGreyLight,
                maxWidth: "170px",
                fontSize: smfontsize,
              }}
              className="border-l border-[#D8D8D8] border-dashed truncate"
            >
              {selectedStoreForStatements.name}
            </div>
          )}
        </button>

        <CustomTypeAndSearch
          isListVisible={isListVisible}
          setIsListVisible={setIsListVisible}
          list={allStoresByUser.data}
          onQueryChange={onQueryChangeHandler}
          listItemClickHandler={listItemClickHandler}
          selectedItem={selectedStoreForStatements}
          listContainerStyle={{ left: "-320px", right: "0px" }}
          searchQuery={searchedStore}
        />
      </div>
    </div>
  );
}

export default FilterWithStores;
