import React, { useState } from "react";
import Loader from "../../../../shared/atoms/Loader";
import { useInvoices } from "../../../../../store";
import TableHeader from "./TableHeader";
import NoDataState from "./NoDataState";
import TransactionsItem from "./TransactionItem";
import styles from "../styles/Transactions/styles.module.css";
import classNames from "classnames";

const Transactions = () => {
  const { transactions, isTransactionsLoading } = useInvoices();
  const [activeId, setActiveId] = useState();

  return (
    <div>
      <TableHeader />
      <div className={classNames(styles.container, "kata")}>
        {isTransactionsLoading ? <Loader />
        : transactions?.data?.length > 0 ? (
          transactions?.data.map((item) => (
            <TransactionsItem
              activeId={activeId}
              setActiveId={setActiveId}
              item={item}
              key={item.id}
            />
          ))
        ) : (
          <NoDataState />
        )}
      </div>
    </div>
  );
};

export default Transactions;
