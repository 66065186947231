import React, { useMemo, useState } from "react";
import { isEqual } from "lodash";
import { useDesktopOrLaptop } from "../../../../services/useDesktopOrLaptop";
import { useTheme } from "../../../../store";
import SelectComp from "../../../SelectComp/SelectComp";
import { createDateFromInterval, getDateObject } from "../../utils";
import CustomDatePicker from "./CustomDatePicker";
import classStyles from "./datePickerSection.module.css";
import classNames from "classnames";

const DatePickerSection = ({ optionsList, datePayload, setDatePayload }) => {
  const { isdesktop } = useDesktopOrLaptop();
  const { Colors } = useTheme();

  const [selectedOption, setSelectedOption] = useState("");

  const styles = useMemo(() => {
    return {
      dateSectionContainer: { display: "flex", alignItems: "center" },
      dateSectionText: {
        fontSize: "14px",
        color: Colors.fontDark,
        backgroundColor: "transparent",
      },
      dateInputContainer: { width: "140px" },
      dropdownContainer: {
        paddingBottom: !isdesktop ? 5 : 0,
      },
    };
  }, [Colors, isdesktop]);

  const dateChangeHandler = (payload) => {
    if (selectedOption) {
      const dateObject = createDateFromInterval(selectedOption);
      if (!isEqual(dateObject, payload)) {
        setSelectedOption("");
      }
    }
    if (!isEqual(datePayload, payload)) setDatePayload(payload);
  };

  const optionChangeHandler = (payload) => {
    const dateObject = createDateFromInterval(payload);
    setSelectedOption(payload);
    if (!isEqual(datePayload, dateObject)) setDatePayload(dateObject);
  };

  return (
    <div className={classStyles.container} style={styles.dateSectionContainer}>
      <div className={classNames(classStyles.dropdownContainer, "w-auto pr-2")} style={styles.dropdownContainer}>
        <SelectComp
          placeholder="Custom"
          list={optionsList}
          setSelectedValue={optionChangeHandler}
          value={selectedOption}
          title=""
          inputStyle={{ ...styles.dateSectionText }}
        />
      </div>
      <CustomDatePicker
        value={{
          from: getDateObject(datePayload.start_date),
          to: getDateObject(datePayload.end_date),
        }}
        range
        placeholder="Previous period"
        setRange={(payload) => dateChangeHandler(payload)}
        minDate={{ prevMonth: 6, addDay: 1 }}
        defaultValue={{
          from: getDateObject(datePayload.start_date),
          to: getDateObject(datePayload.end_date),
        }}
        containerStyle={styles.dateInputContainer}
        pickerInputStyle={styles.dateSectionText}
      />
    </div>
  );
};

export default DatePickerSection;
