import React, { useState } from "react";
import classNames from "classnames";

import styles from "./styles.module.css";
import ImagesContainerOnSidebar from "../../../../../DeliveryDetailsOnSidePopup/ImagesContainerOnSidebar";
import { UploadCloudIcon } from "../../../../../Orders/OrderCsvSvgs";
import Button from "../../../../../shared/atoms/Button";
import { FaPlus } from "react-icons/fa6";
import { toast } from "react-toastify";

const ImagesUpload = ({ images, setImages, errorMessage, setErrorMessage }) => {
  const handleFileChange = (event) => {
    if (event.target.files[0].size > 10485760) {
      // 1MB in bytes
      toast.error("File size should be 10MB or less.");
      return;
    }
    const files = Array.from(event.target.files).slice(0, 3);
    setErrorMessage("");
    // console.log("1312312", files, URL.createObjectURL(files[0]));

    setImages([...images, ...files].slice(0, 3));
  };

  const handleBrowseClick = () => {
    document.getElementById("incidentFileInput").click();
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = async (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files).slice(0, 3);
    setImages(files);
  };

  return (
    <div className={styles.container}>
      {/* {images && images?.length < 3 && (
        <div className={styles.uploadContainer} onDrop={handleDrop} onDragOver={handleDragOver}>
          <UploadCloudIcon />
          <div className={classNames(styles.textDark, "mt-2")}>Drag/Drop your files here</div>
          <div className={classNames(styles.textDark, "mt-2")}>OR</div>
          <Button externalClassName="mt-4" onClick={handleBrowseClick} text="Browse Files" />
          <input
            id="incidentFileInput"
            style={{ display: "none" }}
            type="file"
            accept=".jpeg, .jpg, .png, .svg, .heic, .heif"
            max={3}
            multiple
            onChange={handleFileChange}
            className={styles.inputFile}
          />
          {errorMessage && <div className={styles.errorMessage}>{errorMessage}</div>}
        </div>
      )} */}
      <input
        id="incidentFileInput"
        style={{ display: "none" }}
        type="file"
        accept=".jpeg, .jpg, .png, .svg"
        max={3}
        // multiple
        onChange={handleFileChange}
        className={styles.inputFile}
      />
      <div className="flex items-center w-full justify-start overflow-x-auto">
        {images &&
          images.map((value, index) => {
            const imageUrl = URL.createObjectURL(value);
            return (
              <div key={value?.lastModified}>
                <ImagesContainerOnSidebar
                  onDeleteImage={() => {
                    setImages((prevFiles) => prevFiles.filter((_, i) => i !== index));
                  }}
                  hideDownload
                  images={[imageUrl]}
                />
              </div>
            );
          })}
        {images && images?.length < 3 && (
          <div onClick={handleBrowseClick} className={classNames(styles.addImageCont, "mt-0")}>
            <div className={styles.iconCont}>
              <FaPlus size={30} color="#0062ff" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImagesUpload;
