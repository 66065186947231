import React from "react";
import styles from "./styles/listEmptyState.module.css";

const ListEmptyState = () => {
  return (
    <tr className={styles.tableRow}>
      <td className="py-4 px-4 text-center" colSpan="5">
        No delivery incidents have been generated yet.
      </td>
    </tr>
  );
};

export default ListEmptyState;
