import React, { useRef, useCallback } from "react";
import { CheckIcon } from "../../../../../assets/svgs/Svgs";
import { useDesktopOrLaptop } from "../../../../../services/useDesktopOrLaptop";
import {
    useInvoices,
    useClickStore,
    useTheme,
} from "../../../../../store";
import { paymentStatuses } from "../../../utlis";

function StatusFilterOptionList({
    isListVisible,
    setIsListVisible
}) {
    const { isdesktop } = useDesktopOrLaptop();
    const { paymentStatusfilter, setPaymentStatusfilter } = useInvoices();

    const { useOnClickOutside } = useClickStore();
    const clickRef = useRef();
    const handler = useCallback(() => {
        setIsListVisible(false);
    });
    useOnClickOutside(clickRef, handler);

    const { Colors, themeChanger } = useTheme();

    return (
        <div ref={clickRef}>
            <div
                style={{
                    position: "absolute",
                    top: isdesktop ? "46px" : "60px",
                    paddingTop: "0px",
                    paddingBottom: "5px",
                    backgroundColor: Colors.bgDarkSecondary,
                    zIndex: 9,
                    maxHeight: isdesktop ? "320px" : "350px",
                    width: "220px",
                    right: "0px",
                }}
                className={`${isListVisible ? "visible" : "hidden"} shadow-xl absolute kata overflow-y-scroll  rounded-md  flex flex-col z-50 `}
            >
                {paymentStatuses.map((status) => {
                    return (
                        <button
                            onClick={() => {
                                if (paymentStatusfilter?.value === status.value) {
                                    setPaymentStatusfilter(null);
                                } else {
                                    setPaymentStatusfilter(status)
                                }
                            }}
                            key={status.value}
                            type="button"
                            className={`flex justify-between items-center   ${!themeChanger ? "hover:bg-gray-700" : "hover:bg-gray-100"
                                }`}
                            style={{
                                height: "38px",
                                borderRadius: "8px",
                                padding: "4px 10px",
                                margin: isdesktop ? "4px 0px" : "18px 0px",
                                width: "100%",
                            }}
                        >
                            <div
                                style={{ color: Colors.fontGreyLight, width: "90%" }}
                                className=" flex flex-col items-start "
                            >
                                <div
                                    className="text-left truncate"
                                    style={{
                                        color: Colors.fontDark,
                                        width: "100%",
                                        fontSize: "16px",
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    {status.label}
                                </div>
                            </div>
                            {(paymentStatusfilter?.value === status.value) && <CheckIcon />}
                        </button>
                    );
                })}
            </div>
        </div>
    );
}

export default StatusFilterOptionList;
