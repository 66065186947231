import React from "react";
import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";
import AddressSection from "./AddressSection";
import DeliveryStatusSection from "./DeliveryStatusSection";
import DriverDetailSection from "./DriverDetailSection";
import OrderEtaSection from "./OrderEtaSection";
import { ORDER_STATUSES } from "../utils";

const OrderDetailsCard = ({ orderInfo }) => {
  const { isdesktop } = useDesktopOrLaptop();
  const showOrderEtaSection =
    orderInfo?.dropoff?.status !== "" &&
    orderInfo?.dropoff?.status !== ORDER_STATUSES.COMPLETED;
  return (
    <div
      style={{
        height: !isdesktop && "100%",
        maxHeight: "80vh",
      }}
      className="relative lg:px-10 lg:pb-14 lg:pt-7 lg:w-3/12 lg:shadow-2xl lg:bottom-20 lg:left-16 lg:absolute pt-5 w-full  px-10 py-10  z-10 flex flex-col justify-center items-center rounded-lg bg-white"
    >
      {showOrderEtaSection && <OrderEtaSection orderInfo={orderInfo} />}
      <DeliveryStatusSection orderInfo={orderInfo} />
      <div className="bg-gradient-to-l mb-5 from-white to-blue-400 h-2 w-full rounded-md border border-gray-100"></div>
      <AddressSection orderInfo={orderInfo} />
      <DriverDetailSection driverInfo={orderInfo.driver} />
    </div>
  );
};

export default OrderDetailsCard;
