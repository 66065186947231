import { useState } from "react";
import { createStore } from "aniuta";

const useFeatureFlags = createStore({
  name: "useFeatureFlags",
  Store: () => {
    const [isSnowflakeDisabled, setIsSnowflakeDisabled] = useState("yetUndefined");
    return { isSnowflakeDisabled, setIsSnowflakeDisabled };
  },
});

export default useFeatureFlags;
