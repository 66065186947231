import React, { useEffect, useState } from "react";
import { useClickStore, useIncidents } from "../../../store";
import DetailsContent from "./DetailsContent";

const Details = ({ deliveryId, onClose, _delInfo, index }) => {
  const [delInfo, setDelInfo] = useState();

  const { getDeliveryByIdHandler } = useIncidents();
  const { setAllTimeVisible } = useClickStore();

  const getDelInfo = async (id) => {
    const _delInfo = await getDeliveryByIdHandler(id);
    if (_delInfo) {
      setDelInfo(_delInfo);
    }
  };

  useEffect(() => {
    if (deliveryId) {
      getDelInfo(deliveryId);
    } else if (_delInfo) {
      setDelInfo(_delInfo);
    }
  }, [deliveryId, _delInfo]);

  return (
    <>
      {delInfo && (
        <DetailsContent
          index={index}
          isAdmin
          setIsCalendarVisible={setAllTimeVisible}
          delivery_id={delInfo.id}
          date={delInfo.createdAt}
          name={delInfo.pickup_name}
          setDetailsVisible={() => {}}
          courierName={delInfo.courier_name}
          item={delInfo}
          statusSocket={delInfo.status}
          statusesHistory={delInfo.events}
          onClose={onClose}
        />
      )}
    </>
  );
};

export default Details;
