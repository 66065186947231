import React, { useCallback, useEffect, useState } from "react";
import { useDesktopOrLaptop } from "../../../../../services/useDesktopOrLaptop";
import { useMyStore, useTheme } from "../../../../../store";
import { SearchSvg } from "../../../../../assets/svgs/Svgs";
import { cloneDeep, debounce, isEqual } from "lodash";
import styles from "../styles/Filters/typeAndSearchFilter.module.css";
import { TbFilterOff } from "react-icons/tb";
import classNames from "classnames";

const TypeAndSearchFilter = ({
  id,
  show,
  list,
  renderListItem = null,
  placeholder = "Search",
  filterState,
  setFilterState,
  activeFiltersIds,
  setActiveFiltersIds,
  filter,
}) => {
  const [query, setQuery] = useState("");
  const [options, setOptions] = useState([]);
  const { smfontsize, isdesktop } = useDesktopOrLaptop();
  const { Colors, themeChanger } = useTheme();
  const { isScalingDisabled } = useMyStore();
  const debounceFn = useCallback(
    debounce((search) => {
      const filteredOptions = (list || []).filter(
        (op) =>
          op[filter?.label] === search || op[filter?.label].includes(search)
      );
      setOptions([...filteredOptions]);
    }, 500),
    [list]
  );

  useEffect(() => {
    if (list) setOptions(cloneDeep(list));
  }, [list]);

  const onQueryChangeHandler = (value) => {
    setQuery(value);
    debounceFn(value);
  };

  const listItemClickHandler = (item) => {
    if (!isEqual(filterState, item)) setFilterState(item);
  };

  useEffect(() => {
    if (filterState && !activeFiltersIds.includes(id)) {
      setActiveFiltersIds([...activeFiltersIds, id]);
    }
    if (!filterState && activeFiltersIds.includes(id)) {
      setActiveFiltersIds([...activeFiltersIds.filter((f) => f !== id)]);
    }
  }, [filterState]);

  return (
    <div
      style={{
        paddingTop: "0px",
        backgroundColor: Colors.bgDarkSecondary,
        zIndex: 999,
        maxHeight: isdesktop || isScalingDisabled ? "320px" : "35px",
        maxWidth: "300px",
        width: "300px",
        display: show ? "flex" : "none",
        position: "absolute",
        left: "100%",
        top: 0,
      }}
      className={`visible absolute rounded-md shadow-xl flex flex-col justify-between z-50`}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        style={{
          width: "100%",
          borderWidth: "1px",
          borderRadius: "6px",
          borderColor: Colors.borderPrimary,
          padding: "12px 10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          backgroundColor: Colors.bgSettings,
        }}
      >
        <SearchSvg />
        <input
          type="text"
          placeholder={placeholder}
          value={query}
          onChange={(event) => {
            onQueryChangeHandler(event.target.value);
          }}
          style={{
            outline: "none",
            width: "100%",
            height: "100%",
            marginLeft: "10px",
            backgroundColor: "transparent",
            color: Colors.fontGreyLight,
            fontSize: isScalingDisabled ? "14px" : smfontsize,
          }}
        />
      </form>
      <div className="kata overflow-y-scroll" style={{ flex: 1 }}>
        {!options?.length ? (
          <div
            style={{
              textAlign: "center",
              margin: isdesktop || isScalingDisabled ? "4px 0px" : "18px 0px",
              color: Colors.fontGreyLight,
              height: "80%",
            }}
          >
            {" "}
            No options available{" "}
          </div>
        ) : (
          options.map((item) => (
            <div
              onClick={() => listItemClickHandler(item)}
              key={item.label}
              className={`flex justify-between items-center   ${!themeChanger ? "hover:bg-gray-700" : "hover:bg-gray-100"
                }`}
              style={{
                height: "50px",
                borderRadius: "8px",
                padding: "4px 10px",
                margin: isdesktop || isScalingDisabled ? "4px 0px" : "18px 0px",
                width: "100%",
                display: "flex",
                gap: "10px",
                cursor: "pointer",
              }}
            >
              <input
                type="checkbox"
                readOnly
                checked={
                  filterState &&
                  filterState[filter.label] === item[filter.label]
                }
              />
              {renderListItem ? (
                renderListItem(item)
              ) : (
                <div
                  style={{ color: Colors.fontGreyLight, width: "100%" }}
                  className=" flex flex-col items-start "
                >
                  <div
                    className="text-left truncate"
                    style={{
                      // color: Colors.fontDark,
                      width: "100%",
                      fontSize: isScalingDisabled ? "14px" : smfontsize,
                    }}
                  >
                    {item && filter?.label ? item[filter.label] : item?.name}
                  </div>
                  {item?.id && (
                    <div style={{ fontSize: isScalingDisabled ? "14px" : smfontsize }}>{item?.id}</div>
                  )}
                </div>
              )}
            </div>
          ))
        )}
      </div>
      <div
        className={classNames({ [styles.footer]: true, [styles.fontSize12]: isScalingDisabled })}
        onClick={() => {
          setFilterState(null);
        }}
      >
        <TbFilterOff color={Colors.fontGreyLight} size={19} /> Clear Filter
      </div>
    </div>
  );
};

export default TypeAndSearchFilter;
