import React, { useEffect, useRef, useState } from "react";
import { FiDownload } from "react-icons/fi";
import { useDesktopOrLaptop } from "../../../../services/useDesktopOrLaptop";
import { useStatementsCsv, useTheme } from "../../../../store";
import CustomModal from "../../../CustomModal/CustomModal";
import { Loading } from "../../../../assets/svgs/Svgs";
import { RiQuestionLine } from "react-icons/ri";
import ToolTip from "../../../Tooltip/Tooltip";
import CsvModalContent from "./CsvModalContent";
import { TIME_ZONES } from "./utils";

const DownloadStatementCSV = ({ statementId, csvToolTip }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTimeZone, setSelectedTimeZone] = useState("UTC");

  const { isdesktop, smfontsize } = useDesktopOrLaptop();
  const { Colors } = useTheme();
  const { isStatementCSVLoading } = useStatementsCsv();

  return (
    <div className="h-full mr-3 flex items-center">
      <CustomModal
        extraPanelStyles={{ maxWidth: isdesktop ? "50%" : "80%" }}
        isOpen={isModalVisible}
        onClose={() => {
          setIsModalVisible(false);
        }}
      >
        <CsvModalContent
          statementId={statementId}
          selectedTimeZone={selectedTimeZone}
          setIsModalVisible={setIsModalVisible}
          setSelectedTimeZone={setSelectedTimeZone}
        />
      </CustomModal>
      <button
        style={{
          marginLeft: "10px",
          color: Colors.fontDark,
          display: "flex",
          alignItems: "center",
          padding: "0px 16px",
          height: isdesktop ? "42px" : "50px",
          borderRadius: "6px",
          backgroundColor: Colors.bgGrey,
        }}
        type="button"
        onClick={async () => {
          setIsModalVisible(true);
          return;
          if (!isStatementCSVLoading) {
            // statementsDownloadHandler(statementId);
          } else {
            return;
          }
        }}
      >
        {isStatementCSVLoading ? (
          <div
            style={{ minHeight: "100px" }}
            className="w-full flex items-center justify-center kata"
          >
            <Loading
              color={Colors.bluePrimary}
              className="h-6 w-24 animate-spin"
            />
          </div>
        ) : (
          <>
            <FiDownload color={Colors.fontGreyLight} size={21} />{" "}
            <span
              style={{
                marginLeft: "6px",
                color: Colors.fontGreyLight,
                fontWeight: "500",
                fontSize: smfontsize,
              }}
            >
              Export
            </span>
          </>
        )}
      </button>
      {csvToolTip && (
        <div
          data-tip
          data-for={`csvToolTip`}
          className="cursor-pointer relative ml-2"
        >
          <RiQuestionLine color={Colors.fontGreyLight} size={24} />
          <ToolTip id={`csvToolTip`} disable={false} text={csvToolTip} />
        </div>
      )}
    </div>
  );
};

export default DownloadStatementCSV;
