import { useState } from "react";
import * as Sentry from "@sentry/react";
import { createStore } from "aniuta";
import { toast } from "react-toastify";
import { getRerouteRecommendations, rerouteIntentAction } from "../utils/api/admin";

const useRerouteRecommendations = createStore({
  name: "useRerouteRecommendations",
  Store: () => {
    const [selectedRerouteIntent, setSelectedRerouteIntent] = useState();

    const [searchByDeliveryId, setSearchByDeliveryId] = useState("");
    const [filterByAction, setFilterByAction] = useState();

    const [rerouteRecommendations, setRerouteRecommendations] = useState();
    const [isRerouteRecommendationsLoading, setIsRerouteRecommendationsLoading] = useState(false);

    const getRerouteRecommendationsWorker = async (params) => {
      setIsRerouteRecommendationsLoading(true);
      const queryParams = {
        test_mode: params?.test_mode ?? false,
        limit: params?.LIMIT ?? 30,
        ending_before: params?.ending_before || "",
        starting_after: params?.starting_after || "",
        delivery_id: params?.delivery_id ?? searchByDeliveryId,
        action: params?.action ?? filterByAction?.value ?? "",
      };

      try {
        const { data } = await getRerouteRecommendations(queryParams);
        if (params?.ending_before && !data?.data?.length) {
          toast.info('No newer records found.');
        } else {
          setRerouteRecommendations(data);
        }
        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsRerouteRecommendationsLoading(false);
      }
    };

    const [isRerouteIntentActionLoading, setIsRerouteIntentActionLoading] = useState(false);

    const rerouteIntentActionWorker = async (id, payload, callback) => {
      setIsRerouteIntentActionLoading(true);
      try {
        await rerouteIntentAction(id, payload);
        toast.success("Your action has been recorded.");
        callback();
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsRerouteIntentActionLoading(false);
      }
    }

    return {
      rerouteRecommendations,
      setRerouteRecommendations,
      isRerouteRecommendationsLoading,
      getRerouteRecommendationsWorker,

      searchByDeliveryId,
      setSearchByDeliveryId,
      filterByAction,
      setFilterByAction,

      selectedRerouteIntent,
      setSelectedRerouteIntent,

      rerouteIntentActionWorker,
      isRerouteIntentActionLoading,
      setIsRerouteIntentActionLoading,
    };
  },
});

export default useRerouteRecommendations;
