import React, { useEffect, useState } from 'react';
import { useRerouteRecommendations, useTheme } from '../../../../store';
import Button from '../../../shared/atoms/Button';
import { TbRoute } from 'react-icons/tb';
import styles from "./styles.module.css";
import classNames from 'classnames';
import { mapRerouteIntentActionReasons, mapRerouteIntentActions } from '../utils';
import DropdownReusable from '../../../shared/common/DropdownReusable';

const RerouteRecommandationReview = () => {
  const { Colors, themeChanger } = useTheme();
  const { rerouteRecommendations, setRerouteRecommendations, selectedRerouteIntent, setSelectedRerouteIntent, rerouteIntentActionWorker } = useRerouteRecommendations();
  const [rejectionReason, setRejectionReason] = useState();
  const [selectedReason, setSelectedReason] = useState("");
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);
  const [showRedOutline, setShowRedOutline] = useState(false);

  useEffect(() => {
    setRejectionReason();
  }, [selectedReason])

  const isValidRequest = (action) => {
    if ((!selectedReason || selectedReason === "Other") && !rejectionReason && action !== selectedRerouteIntent.action) {
      setShowRedOutline(true);
      return false;
    } else {
      setShowRedOutline(false);
      return true;
    }
  }

  const rerouteIntentActionHandler = async (action) => {
    if (!isValidRequest(action)) {
      return
    }
    const payload = {
      decision: action,
      rejection_reason: selectedReason && selectedReason !== "Other"
        ? selectedReason
        : rejectionReason
          ? rejectionReason
          : null,
    }
    const callback = () => {
      const updatedRerouteRecommendations = { ...rerouteRecommendations, data: rerouteRecommendations.data.filter(intent => intent.id !== selectedRerouteIntent.id) }
      setRerouteRecommendations(updatedRerouteRecommendations);
      setSelectedRerouteIntent();
    }
    await rerouteIntentActionWorker(selectedRerouteIntent.id, payload, callback);
  }

  if (!selectedRerouteIntent) return null;

  return (
    <div className=" py-10">
      <div
        style={{ borderColor: Colors.borderPrimary, marginBottom: "20px" }}
        className="w-full px-8 pb-5 border-b"
      >
        <div className="w-full flex items-start justify-between">
          <div
            style={{
              fontFamily: "Poppins",
              color: Colors?.fontDark,
              fontWeight: "500",
              width: "100%",
            }}
          >
            {selectedRerouteIntent && (
              <div>
                <div>
                  Review Reroute Intent:{" "}
                  <span style={{ color: Colors.fontGreyLight }}>
                    {selectedRerouteIntent.delivery_id}
                  </span>
                </div>
              </div>
            )}
          </div>
          <button
            onClick={() => {
              setSelectedRerouteIntent();
            }}
            type="button"
            style={{
              color: Colors?.fontDark,
              fontSize: "20px",
            }}
            className="material-icons outline-none"
          >
            close
          </button>
        </div>
      </div>
      <div className="w-full ">
        <div className="w-full flex flex-col px-8 pb-5">
          <div style={{ color: Colors.fontDark }} className="flex w-full justify-between items-start mt-4">
            <div style={{ width: '40%' }}>Recommended Action: </div>
            <div style={{
              width: "60%",
            }}>{mapRerouteIntentActions[selectedRerouteIntent.action]}</div>
          </div>

          <div style={{ color: Colors.fontDark }} className="flex w-full justify-between items-start mt-4">
            <div style={{ width: '40%' }}>Recommendation Reason: </div>
            <div style={{
              width: "60%",
            }}>{mapRerouteIntentActionReasons[selectedRerouteIntent.action_reason]}</div>
          </div>

          <div style={{ color: Colors.fontDark }} className="flex w-full justify-between items-start mt-4">
            <div style={{ width: '40%' }}>Recommendation Explaination: </div>
            <div style={{
              width: "60%",
            }}>{selectedRerouteIntent.action_explanation}</div>
          </div>

          <div style={{ color: Colors.fontDark }} className="flex w-full justify-between items-start mt-4">
            <div style={{ width: '40%' }}>Alternate Action Reason: <br />
              <span style={{ fontSize: '13px', fontWeight: 400, color: Colors.redDark }}>*Please provide a reason in case you want to take any action other then the recommended action.</span>
            </div>
            <div style={{ width: "60%", }}>
              <DropdownReusable
                selectedItem={selectedReason}
                setSelectedItem={setSelectedReason}
                items={["FTD", "Merchant Requested", "No longer applicable", "Other"]}
                isDropdownOpened={isDropdownOpened}
                setIsDropdownOpened={(value) => {
                  if (value) {
                    setShowRedOutline(false)
                  }
                  setIsDropdownOpened(value)
                }}
                onChange={() => { }}
                extraContainerClasses={
                  classNames({
                    [styles.dropDownComponent]: true,
                    [styles.redOutline]: showRedOutline && !selectedReason,
                  })
                }
                placeHolder="Alternate action reason"
              />
              {selectedReason === "Other" && <textarea
                maxLength={"150"}
                className={classNames({
                  [`focus:border-${themeChanger ? 'gray-400' : 'gray-300'}`]: true,
                  [styles.redOutline]: showRedOutline,
                })}
                value={rejectionReason}
                style={{
                  padding: "10px 20px",
                  width: "100%",
                  outline: "none",
                  backgroundColor: Colors.borderPrimary,
                  resize: "none",
                  maxHeight: "800px",
                  borderRadius: "10px",
                }}
                onChange={(e) => {
                  setRejectionReason(e.target.value);
                }}
                onFocus={() => setShowRedOutline(false)}
                rows="4"
                placeholder={"Alternate action reason goes here"}
              ></textarea>}
            </div>

          </div>
          <div className="flex w-full justify-between mt-8 ">
            <div className="flex justify-end items-center relative nowra gap-2">
              <Button
                externalClassName={classNames({ [styles.buttonContainer]: true, [styles.buttonRecommended]: selectedRerouteIntent.action === 'cancel' })}
                text='Cancel Delivery'
                onClick={() => rerouteIntentActionHandler('cancel')}
              />
              <Button
                externalClassName={classNames({ [styles.buttonContainer]: true, [styles.buttonRecommended]: selectedRerouteIntent.action === 'reroute' })}
                onClick={() => rerouteIntentActionHandler('reroute')}
              >
                <span
                  style={{
                    marginRight: "8px",
                  }}
                >
                  <TbRoute size={18} />
                </span>
                Reroute
              </Button>
              <Button
                text='Discard Request'
                variant='secondary'
                externalClassName={styles.buttonContainer}
                onClick={() => rerouteIntentActionHandler('discard')}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RerouteRecommandationReview;
