import React from "react";
import ReactTooltip from "react-tooltip";

const ToolTip = ({
  id = "tool-tip",
  text = "Text",
  place = "top",
  disable = false,
  textStyles,
  ...props
}) => {
  return (
    <ReactTooltip
      id={id}
      effect="solid"
      disable={disable}
      place={place}
      multiline={true}
      {...props}
    >
      <span style={textStyles}>{text}</span>
    </ReactTooltip>
  );
};

export default ToolTip;
