import React, { useEffect, useRef, useState } from "react";
import { useStatementsStore } from "../../../../../../store";
import Pagination from "../../../../../shared/Pagination";
import styles from "./styles/styles.module.css";
import { useParams } from "react-router-dom";
import Loader from "../../../../../shared/atoms/Loader";
import NavDataEmptyState from "./NavDataEmptyState";
import RelatedStatementsListItem from "./RelatedStatementsListItem";

const RelatedStatementsNav = ({
  selectedRelatedStatement,
  onRelatedStatementChangeHandler,
}) => {
  const containerRef = useRef(null);
  const [listItems, setListItems] = useState();

  const {
    isRelatedStatementsLoading,
    relatedStatementsData,
    getRelatedStatementsList,
  } = useStatementsStore();
  const { id } = useParams();

  const getRelatedStatementsHandler = async (param) => {
    let params = {
      ending_before: param?.ending_before,
      starting_after: param?.starting_after,
    };
    const items = await getRelatedStatementsList(id, params);
    return items;
  };

  useEffect(() => {
    const firstLoadHandler = async () => {
      if (id) {
        if (!listItems) {
          const res = await getRelatedStatementsHandler();
          if (res) {
            setListItems(res);
          }
        }
      }
    };
    firstLoadHandler();
  }, [id]);

  useEffect(() => {
    const container = containerRef.current;

    // Function to check if the container is scrolled to the bottom
    const handleScroll = async () => {
      const scrollTop = container.scrollTop;
      const scrollHeight = container.scrollHeight;
      const clientHeight = container.clientHeight;

      if (scrollTop + clientHeight >= scrollHeight) {
        let params = {
          ending_before: "",
          starting_after: listItems[listItems.length - 1].id,
        };

        const items = await getRelatedStatementsList(id, params, true);

        // Load new list items
        const newItems = [...listItems, ...items];
        setListItems(newItems);
      }
    };

    // Attach the scroll event listener to the container
    container.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [listItems]);

  return (
    <div style={{ width: "300px" }} className="h-full">
      <div ref={containerRef} className={styles.container}>
        {isRelatedStatementsLoading ? (
          <Loader />
        ) : listItems?.length > 0 ? (
          listItems.map((data) => (
            <RelatedStatementsListItem
              key={data.id}
              data={data}
              selectedRelatedStatement={selectedRelatedStatement}
              onRelatedStatementChangeHandler={onRelatedStatementChangeHandler}
            />
          ))
        ) : (
          <NavDataEmptyState />
        )}
        {/* {isRelatedStatementsLoading ? (
          <Loader />
        ) : relatedStatementsData?.data?.length > 0 ? (
          relatedStatementsData?.data.map((data) => (
            <RelatedStatementsListItem
              key={data.id}
              data={data}
              selectedRelatedStatement={selectedRelatedStatement}
              onRelatedStatementChangeHandler={onRelatedStatementChangeHandler}
            />
          ))
        ) : (
          <NavDataEmptyState />
        )} */}
      </div>
      {/* <Pagination
        data={relatedStatementsData}
        onGetPaginationData={getRelatedStatementsHandler}
        buttonClass={""}
      /> */}
    </div>
  );
};

export default RelatedStatementsNav;
