import React, { useState, useEffect } from "react";
import moment from "moment";

import "react-toastify/dist/ReactToastify.css";

import CancelDeliveryRequest from "../../../CancelDeliveryRequest";
import { useAdmin, useClickStore, useMyStore, useTheme } from "../../../../store";
import { DotsSvg, Loading } from "../../../../assets/svgs/Svgs";
import CustomModal from "../../../CustomModal/CustomModal";
import { useDesktopOrLaptop } from "../../../../services/useDesktopOrLaptop";
import Details from "../../Details";
import StatusBadgeReusable from "../../../shared/common/StatusBadgeReusable";
import { badgesForDeliveryStatuses, iconHandler } from "../utils";
import styles from "./styles.module.css";
import Button from "../../../shared/atoms/Button";
import DotsMenu from "./DotsMenu/Index";
import { calculateCostLineItemsTotalAmount } from "../../../../utils/helpers";
import Badge from "../../../shared/atoms/Badge";
import MarkDeliveryNotReroutable from "../../MarkDeliveryNotReroutable";

function DeliveriesListForAdmin({
  index,
  name,
  date,
  pickup,
  dropOff,
  status,
  item,
  requestId,
  isAdmin,
  service,
  currentTransitStatusDurationMins,
  socketMessage,
}) {
  const { Colors } = useTheme();
  const { isdesktop, smfontsize } = useDesktopOrLaptop();

  const [dotsMenuVisible, setDotsMenuVisible] = useState(false);
  const [detailsVisible, setDetailsVisible] = useState(false);
  const [cancelVisible, setCancelVisible] = useState(false);
  const [markNotReroutableVisible, setMarkNotReroutableVisible] = useState(false);
  const { setAllTimeVisible } = useClickStore();

  const { requestDriverHandler } = useMyStore();

  const [statusSocket, setStatusSocket] = useState(status);

  const [isRequested, setIsRequested] = useState(false);

  const { adminDelsListData, setAdminDelsListData } = useAdmin();

  const textStyles = {
    fontFamily: "Roboto",
    fontSize: smfontsize,
    color: Colors.fontGreyLight,
    fontWeight: "normal",
  };

  const timeBeforeOrAfterScheduled = item?.dropoff_at
    ? moment(Date.now()).diff(moment(item?.dropoff_at), "minutes")
    : item?.pickup_at &&
      moment(Date.now()).diff(moment(item?.pickup_at), "minutes");

  useEffect(() => {
    if (socketMessage?.id === requestId) {
      setStatusSocket(socketMessage?.status);
    }
  }, [socketMessage]);
  useEffect(() => {
    setStatusSocket(status);
  }, [status]);

  return (
    <div
      style={{ width: "100%" }}
      className={`items-center  text-xs relative  grid grid-cols-12 gap-1 w-full py-4 pl-4`}
    >
      <div
        id={"nameOfdropoffUser" + index}
        style={{
          fontFamily: "Roboto",
          fontSize: smfontsize,
          color: Colors.fontDark,
          fontWeight: "500",
          lineHeight: !isdesktop && "24px",
        }}
        className={`col-start-1 col-span-1`}
      >
        <p className="elipsis w-full">{name}</p>
        <p className="elipsis w-full">{item?.id}</p>
      </div>
      <div
        style={{
          ...textStyles,
          ...{ lineHeight: !isdesktop && "26px" },
        }}
        className={
          isdesktop
            ? "col-start-2 col-span-1 text-center"
            : "col-start-3 col-span-3 text-center"
        }
      >
        <div>{moment(date).format("MMM D, YYYY")}</div>
        <div className="mt-2">{moment(date).format("h:mma")}</div>
      </div>
      {isdesktop && (
        <div
          style={{
            ...textStyles,
            ...{
              lineHeight: !isdesktop && "26px",
            },
          }}
          className="col-start-3 col-span-3 text-center"
        >
          <div className="grid grid-cols-2">
          {isdesktop && (
        <div
          style={{
            ...textStyles,
            ...{
              lineHeight: !isdesktop && "26px",
            },
          }}
          className="col-start-1 col-span-1 text-center"
        >
          {pickup}
        </div>
      )}
          {isdesktop && (
        <div
          style={{
            ...textStyles,
            ...{
              lineHeight: !isdesktop && "26px",
              marginLeft: isAdmin ? "-0px" : "0px",
            },
          }}
          className="col-start-2 col-span-1 text-center"
        >
          {dropOff}
        </div>
      )}
          </div>
        </div>
      )}
      <div
        style={{
          ...textStyles,
          ...{
            fontWeight: "500",
            lineHeight: !isdesktop && "26px",
          },
        }}
        className={
          isdesktop
            ? "col-start-6 col-span-1 text-center"
            : "col-start-6 col-span-2 text-center"
        }
      >
        {/* ${(item?.payment_amount / 100).toFixed(2)} */}
        {(
          calculateCostLineItemsTotalAmount(item.cost_line_items) / 100
        ).toFixed(2)}
      </div>

      <div
        style={{
          ...textStyles,
        }}
        className={
          isdesktop
            ? "col-start-7 col-span-1 text-center"
            : "col-start-8 col-span-2 text-center"
        }
      >
        {service || ""}
      </div>

      {isdesktop && (
        <div
          style={{
            ...textStyles,
          }}
          className={"col-start-8 col-span-1 text-center"}
        >
          {item.reroute_status}
        </div>
      )}

      {isdesktop && (
        <div
          style={{
            ...textStyles,
          }}
          className={"col-start-9 col-span-1 text-center"}
        >
          {statusSocket === "scheduled"
            ? timeBeforeOrAfterScheduled >= -30 && timeBeforeOrAfterScheduled
            : currentTransitStatusDurationMins || ""}
        </div>
      )}

      {statusSocket === "request" ? (
        <div className="col-start-10 col-span-2 flex justify-center items-center">
          {isRequested ? (
            <Loading
              color={Colors.bluePrimary}
              className="h-6 w-6 animate-spin"
            />
          ) : (
            <div style={{ width: "140px" }}>
              <Button
                text="Request a driver"
                onClick={() => {
                  requestDriverHandler(requestId);
                  setIsRequested(true);
                }}
                externalClassName={styles.buttonTextSize}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="col-start-10 col-span-2 flex justify-center items-center">
          <div
            style={{
              width: "180px",
              textAlign: "center",
            }}
          >
            {statusSocket === "scheduled" ? (
              <Badge
                label={
                  item?.dropoff_at
                    ? "Scheduled D"
                    : item?.pickup_at
                    ? "Scheduled P"
                    : "Scheduled"
                }
                type={"primary"}
                externalClassName={styles.badge}
              />
            ) : (
              <StatusBadgeReusable
                hideIcon={false}
                leftIcon={iconHandler(statusSocket, isdesktop)}
                showDefault={true}
                status={statusSocket}
                badgesObject={badgesForDeliveryStatuses}
                defaultType="neutral"
                externalClassName={styles.badge}
                extraIconClasses={styles.statusClass}
              />
            )}
          </div>
        </div>
      )}

      <div
        style={{
          top: "18px",
          right: isdesktop ? "20px" : "0px",
        }}
        className="absolute items-center justify-center focus:outline-none"
      >
        <button
          style={{
            fontFamily: "Poppins",
            fontWeight: "600",
            color: "#92929D",
            padding: isdesktop ? "8px" : "10px",
            outline: "none",
          }}
          onClick={() => {
            setDotsMenuVisible(!dotsMenuVisible);
          }}
          className=" hover:bg-gray-100 rounded-md outline-none text-gray-500 items-center"
        >
          <DotsSvg width={isdesktop ? 18 : 30} height={isdesktop ? 4 : 24} />
        </button>

        {dotsMenuVisible && (
          <DotsMenu
            setCancelVisible={setCancelVisible}
            item={item}
            setDetailsVisible={setDetailsVisible}
            setDotsMenuVisible={setDotsMenuVisible}
            setMarkNotReroutableVisible={setMarkNotReroutableVisible}
          />
        )}
      </div>

      <CustomModal
        extraPanelStyles={{ maxWidth: isdesktop ? "70%" : "90%" }}
        isOpen={detailsVisible}
        onClose={() => {
          setDetailsVisible(false);
          setAllTimeVisible(true);
        }}
      >
        <Details
          _delInfo={item}
          onClose={() => {
            setDetailsVisible(false);
            setAllTimeVisible(true);
          }}
        />
      </CustomModal>
      <CustomModal
        extraPanelStyles={{ maxWidth: isdesktop ? "50%" : "80%" }}
        isOpen={cancelVisible}
        onClose={() => setCancelVisible(false)}
      >
        <CancelDeliveryRequest
          index={index}
          isonAdmin={true}
          setIsCalendarVisible={setAllTimeVisible}
          request_id={item.id}
          setCancelVisible={setCancelVisible}
        />
      </CustomModal>

      <CustomModal
        extraPanelStyles={{ maxWidth: isdesktop ? "50%" : "80%" }}
        isOpen={markNotReroutableVisible}
        onClose={() => {
          setMarkNotReroutableVisible(false);
          setAllTimeVisible(true);
        }}
      >
        <MarkDeliveryNotReroutable deliveryId={item.id} onClose={() => {
          setMarkNotReroutableVisible(false);
          setAllTimeVisible(true);
        }} 
          onCallback={(res, error) => {
            if (res) {
              const _adminDelsListData = { ...adminDelsListData };
              _adminDelsListData.data[
                index
              ].reroute_status = "not_reroutable";
              setAdminDelsListData(_adminDelsListData);
            }
          }} />
      </CustomModal>
    </div>
  );
}

export default DeliveriesListForAdmin;
