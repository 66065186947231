import React from "react";
import { useQuoteWorkflow, useMerchant, userDataStore, useStoreAndChild, useMyStore } from "../../../../store";

import { useDesktopOrLaptop } from "../../../../services/useDesktopOrLaptop";
import QuoteBoxes from "./QuoteBoxes";

function QuoteTempArea() {
  const { isdesktop } = useDesktopOrLaptop();
  const { authInfo } = userDataStore();
  const { selectedStore, putUpdateStore, setSelectedStore, getAllStoresByUser } = useStoreAndChild();
  const { userId } = useMyStore();

  const { setSelectedQuoteItem, selectedQuoteItem } = useQuoteWorkflow();

  const { merchantData, updateMerchantData } = useMerchant();

  const removeWorkflowItem = async (key) => {
    let payload = selectedStore ? structuredClone(selectedStore?.settings) : structuredClone(merchantData?.settings);

    // let payload = selectedStore
    //   ? { ...selectedStore?.settings }
    //   : { ...merchantData?.settings };
    // let payload = { ...merchantData?.settings };

    if (key === "Provider Preference") {
      payload.quote_provider_preference = [];
    } else if (key === "Subsidize" || key === "Upsell") {
      payload.delivery_fee_display_adjustment_percentage = null;
      payload.delivery_fee_display_adjustment_amount = null;
    } else if (key === "Limit Radius") {
      payload.max_delivery_radius = null;
    } else if (key === "Price Ceiling") {
      payload.max_cost_of_delivery = null;
    } else if (key === "Multi Quote") {
      payload.is_multi_quote_enabled = false;
    } else if (key === "Include Burq fee") {
      payload.is_burq_fee_included_in_fee = false;
    }

    if (selectedStore) {
      putUpdateStore(
        selectedStore.id,
        selectedStore.name,
        selectedStore.unit_number,
        selectedStore.phone_number,
        selectedStore.address,
        selectedStore.pickup_notes,
        "",
        selectedStore.timezone,
        payload
      ).then((data) => {
        setSelectedStore(data);
        getAllStoresByUser(authInfo.merchantAccountId).catch((err) => console.log(err));
      });
    } else {
      updateMerchantData(userId || authInfo.merchantAccountId, merchantData, payload)
        .then((res) => {
          if (res.data) {
            setSelectedQuoteItem();
          }
        })
        .catch((err) => {});
    }
  };

  const settings = selectedStore ? selectedStore.settings : merchantData?.settings;

  return (
    <div
      className="flex-wrap"
      style={{
        width: isdesktop ? `calc(100% - 440px)` : "100%",
        display: "flex",
        overflowY: "auto",
        maxHeight: "100%",
        padding: "30px 0px",
        justifyContent: !isdesktop && "center",
      }}
    >
      {settings?.delivery_fee_display_adjustment_amount && settings.delivery_fee_display_adjustment_amount < 0 ? (
        <QuoteBoxes
          removeWorkflowItem={removeWorkflowItem}
          selectedKey={selectedQuoteItem}
          item={{
            id: 0,
            key: "Subsidize",
            value: null,
            type: null,
            description: "Subsidize delivery quotes for your end customer",
            saved: false,
            example: "* For example, a $2 subsidy will bring an  original $7 quote  down to $5.",
          }}
        />
      ) : (
        settings?.delivery_fee_display_adjustment_amount > 0 && (
          <QuoteBoxes
            removeWorkflowItem={removeWorkflowItem}
            selectedKey={selectedQuoteItem}
            item={{
              id: 1,
              key: "Upsell",
              value: null,
              type: null,
              description: "Mark-up delivery prices for your business",
              saved: false,
              example: "* For example, a $2 mark-up will bring an original $7 quote  up to $9.",
            }}
          />
        )
      )}
      {settings?.delivery_fee_display_adjustment_percentage && settings.delivery_fee_display_adjustment_percentage < 0 ? (
        <QuoteBoxes
          removeWorkflowItem={removeWorkflowItem}
          selectedKey={selectedQuoteItem}
          item={{
            id: 0,
            key: "Subsidize",
            value: null,
            type: null,
            description: "Subsidize delivery quotes for your end customer",
            saved: false,
            example: "* For example, a $2 subsidy will bring an  original $7 quote  down to $5.",
          }}
        />
      ) : (
        settings?.delivery_fee_display_adjustment_percentage > 0 && (
          <QuoteBoxes
            removeWorkflowItem={removeWorkflowItem}
            selectedKey={selectedQuoteItem}
            item={{
              id: 1,
              key: "Upsell",
              value: null,
              type: null,
              description: "Mark-up delivery prices for your business",
              saved: false,
              example: "* For example, a $2 mark-up will bring an original $7 quote  up to $9.",
            }}
          />
        )
      )}

      {/* {(settings?.merchant_delivery_markup_percentage ||
        settings?.merchant_delivery_markup_amount) && (
        <QuoteBoxes
          removeWorkflowItem={removeWorkflowItem}
          selectedKey={selectedQuoteItem}
          item={{
            id: 1,
            key: "Upsell",
            value: null,
            type: null,
            description: "Mark-up delivery prices for your business",
            saved: false,
            example:
              "* For example, a $2 mark-up will bring an original $7 quote  up to $9.",
          }}
        />
      )} */}
      {(settings?.max_delivery_radius || settings?.max_delivery_radius === 0) && (
        <QuoteBoxes
          removeWorkflowItem={removeWorkflowItem}
          selectedKey={selectedQuoteItem}
          item={{
            id: 2,
            key: "Limit Radius",
            value: null,
            type: "mile",
            description: "Restrict delivery radius to your preferred mileage",
            saved: false,
            example: "* For example, with a 10 mile limit, any longer deliveries will be restricted.",
          }}
        />
      )}
      {(settings?.max_cost_of_delivery || settings?.max_cost_of_delivery === 0) && (
        <QuoteBoxes
          removeWorkflowItem={removeWorkflowItem}
          selectedKey={selectedQuoteItem}
          item={{
            id: 3,
            key: "Price Ceiling",
            value: null,
            type: "usd",
            description: "Hide all delivery options when the quote exceeds your pre-defined amount",
            saved: false,
            example: "* If price is over eg. $10 do not show any delivery options.",
          }}
        />
      )}
      {(settings?.reroute_config?.is_auto_reroute_enabled || settings?.reroute_config?.is_manual_reroute_enabled) && (
        <QuoteBoxes
          removeWorkflowItem={removeWorkflowItem}
          selectedKey={selectedQuoteItem}
          item={{
            id: 7,
            key: "Reroute",
            value: false,
            type: "boolean",
            description: "Automatically reroute your deliveries when ever they are provided canceled.",
            saved: false,
            example: "",
          }}
        />
      )}
      {settings?.is_multi_quote_enabled && (
        <QuoteBoxes
          removeWorkflowItem={removeWorkflowItem}
          selectedKey={selectedQuoteItem}
          item={{
            id: 4,
            key: "Multi Quote",
            value: false,
            type: "boolean",
            description: "Enable and receive quotes from multiple providers during your delivery creation process.",
            saved: false,
            example: "",
          }}
        />
      )}
      {settings?.is_burq_fee_included_in_fee && (
        <QuoteBoxes
          removeWorkflowItem={removeWorkflowItem}
          selectedKey={selectedQuoteItem}
          item={{
            id: 5,
            key: "Include Burq fee",
            value: false,
            type: "boolean",
            description:
              "When this setting is enabled, Burq fee is included in the prices in quotes. When disabled, Burq fee is excluded from the prices in quotes.",
            saved: false,
            example: "",
          }}
        />
      )}

      {settings?.quote_provider_preference[0] && (
        <QuoteBoxes
          removeWorkflowItem={removeWorkflowItem}
          selectedKey={selectedQuoteItem}
          item={{
            id: 6,
            key: "Provider Preference",
            value: null,
            type: null,
            description: "Please select providers from the list below to receive quotes from them exclusively on the quote page",
            saved: false,
            example: "* For example, a $2 subsidy will bring an  original $7 quote  down to $5.",
          }}
        />
      )}
    </div>
  );
}

export default QuoteTempArea;
