import React, { useEffect, useState } from "react";

import { useBranding, useMerchant, useMyStore, useTheme, userDataStore } from "../../../store";

import WorkflowSidebarOpener from "../HelperComponents/WorkflowSidebarOpener";
import styles from "./styles.module.css";
import BrandingActionSection from "./BrandingActionSection";
import MapOnTracking from "../../TrackingPage/MapOnTracking";
import { DUMMY_DELIVERY, DUMMY_ROUTE } from "./BrandingActionSection/helper";
import CommonToggle from "../../CommonToggle";
import classNames from "classnames";
import Button from "../../shared/atoms/Button";
import { Loading } from "../../../assets/svgs/Svgs";
import { toast } from "react-toastify";
import DetailsMemo from "./DetailsMemo";
import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";

const BrandingWorkflow = ({ isInsideStore }) => {
  const [isLeftOpened, setIsLeftOpened] = useState(true);
  const { Colors } = useTheme();
  const { userId } = useMyStore();

  const { isdesktop } = useDesktopOrLaptop();

  const [routesForMap, setRoutesForMap] = useState(DUMMY_ROUTE);

  const { merchantData, setAvatar, setMerchantData } = useMerchant();
  const { authInfo } = userDataStore();
  const {
    postBrandingSettingsHandler,
    putBrandingSettingsHandler,
    isPostBrandingLoading,
    postBrandingImageUpload,
    setIsPostBrandingLoading,
  } = useBranding();
  const [colorForMap, setColorForMap] = useState(merchantData?.branding_settings?.brand_color ?? "#0062ff");

  const [favIconUploadedUrl, setFavIconUploadedUrl] = useState();
  const [iconUploadedUrl, setIconUploadedUrl] = useState();
  const [brandingUploadedUrl, setBrandingUploadedUrl] = useState();
  const [platformOnboardingLogoUploadedUrl, setPlatformOnboardingLogoUploadedUrl] = useState();
  const [codeSnippet, setCodeSnippet] = useState(
    merchantData?.branding_settings?.merchant_chat_code_snippet
      ? Buffer.from(merchantData?.branding_settings?.merchant_chat_code_snippet, "base64").toString("utf-8")
      : ""
  );
  const [isChatEnabled, setIsChatEnabled] = useState(merchantData?.branding_settings?.burq_chat_enabled_on_tracking_page ?? true);

  const saveChangesHandler = async () => {
    setIsPostBrandingLoading(true);
    let favIconUrl;
    let logoUrl;
    let brandingUrl;
    let platformLogoUrl;
    if (favIconUploadedUrl?.formData) {
      const params = { type: "favIcon" };
      const uploadedData = await postBrandingImageUpload(authInfo?.merchantAccountId, params, favIconUploadedUrl?.formData);
      favIconUrl = uploadedData;
    }
    if (iconUploadedUrl?.formData) {
      const params = { type: "icon" };
      const uploadedData = await postBrandingImageUpload(authInfo?.merchantAccountId, params, iconUploadedUrl?.formData);
      logoUrl = uploadedData;
    }
    if (brandingUploadedUrl?.formData) {
      const params = { type: "branding" };
      const uploadedData = await postBrandingImageUpload(authInfo?.merchantAccountId, params, brandingUploadedUrl?.formData);
      brandingUrl = uploadedData;
    }
    if (platformOnboardingLogoUploadedUrl?.formData) {
      const params = { type: "branding" };
      const uploadedData = await postBrandingImageUpload(
        authInfo?.merchantAccountId,
        params,
        platformOnboardingLogoUploadedUrl?.formData
      );
      platformLogoUrl = uploadedData;
    }

    const payload = {
      icon_image_url: logoUrl ?? undefined,
      favIcon_image_url: favIconUrl ?? undefined,
      branding_image_url: brandingUrl ?? undefined,
      platform_logo_url: platformLogoUrl ?? undefined,
      brand_color: colorForMap ?? "#0062ff",
      burq_chat_enabled_on_tracking_page: isChatEnabled,
      merchant_chat_code_snippet: Buffer.from(codeSnippet).toString("base64"),
    };

    if (merchantData?.branding_settings) {
      const brandingData = await putBrandingSettingsHandler(merchantData?.id, payload);
      if (brandingData) {
        const _merchantData = structuredClone(merchantData);
        _merchantData.branding_settings = brandingData;
        setMerchantData(_merchantData);
        setAvatar(brandingData?.icon_image_url);
        // getMerchantData(merchantData?.id);
        setFavIconUploadedUrl();
        setIconUploadedUrl();
        setBrandingUploadedUrl();
        setPlatformOnboardingLogoUploadedUrl();
        toast.success("Changes saved successfully");
      }
    } else {
      const brandingData = await postBrandingSettingsHandler(userId ?? merchantData?.id, payload);
      if (brandingData) {
        const _merchantData = structuredClone(merchantData);
        _merchantData.branding_settings = brandingData;
        setMerchantData(_merchantData);
        setAvatar(brandingData?.icon_image_url);
        // getMerchantData(merchantData?.id);
        setFavIconUploadedUrl();
        setIconUploadedUrl();
        setBrandingUploadedUrl();
        setPlatformOnboardingLogoUploadedUrl();
        toast.success("Changes saved successfully");
      }
    }
  };

  useEffect(() => {
    return () => {
      setFavIconUploadedUrl();
      setIconUploadedUrl();
      setBrandingUploadedUrl();
      setPlatformOnboardingLogoUploadedUrl();
      setColorForMap();
      setCodeSnippet();
    };
  }, []);

  useEffect(() => {
    setCodeSnippet(
      merchantData?.branding_settings?.merchant_chat_code_snippet
        ? Buffer.from(merchantData?.branding_settings?.merchant_chat_code_snippet, "base64").toString("utf-8")
        : ""
    );
    setColorForMap(merchantData?.branding_settings?.brand_color ?? "#0062ff");
    setIsChatEnabled(merchantData?.branding_settings?.burq_chat_enabled_on_tracking_page ?? true);
    setRoutesForMap(null);

    // setFavIconUploadedUrl(merchantData?.branding_settings?.favIcon_image_url);
    // setIconUploadedUrl(merchantData?.branding_settings?.icon_image_url);
    // setBrandingUploadedUrl(merchantData?.branding_settings?.branding_image_url);
    setTimeout(() => {
      setRoutesForMap(DUMMY_ROUTE);
    }, 100);
  }, [merchantData]);
  return (
    <div
      style={{
        backgroundImage: `radial-gradient(${Colors.bgDots} 20%, ${Colors.bgWorkflow} 14%)`,
        backgroundPosition: "0px 0px",
        backgroundSize: "20px 20px",
        height: isdesktop
          ? isInsideStore
            ? `calc(100vh - 210px)`
            : `calc(100vh - 70px)`
          : isInsideStore
          ? `calc(100vh - 380px)`
          : `calc(100vh - 200px)`,
        overflowY: "auto",
        display: "flex",
        flexDirection: "row",
        width: "100%",
      }}
    >
      <WorkflowSidebarOpener isInsideStore={isInsideStore} isLeftOpened={isLeftOpened} setIsLeftOpened={setIsLeftOpened} />

      <div
        className="kata"
        style={{
          width: "350px",
          transform: isdesktop
            ? isLeftOpened
              ? `translate(0px)`
              : `translate(-350px)`
            : isLeftOpened
            ? `translate(0px)`
            : `translate(-334px)`,
          borderRightWidth: "1px",
          borderColor: Colors.borderSecondary,
          backgroundColor: Colors.white,
          paddingBottom: "70px",

          position: "absolute",
          top: isdesktop ? (isInsideStore ? "211px" : "70px") : isInsideStore ? "377px" : "200px",
          left: "0px",
          bottom: "0px",
          zIndex: 20,
        }}
      >
        <div className={styles.title}>Branding</div>
        <BrandingActionSection
          backendImage={merchantData?.branding_settings?.favIcon_image_url}
          setImageUrl={setFavIconUploadedUrl}
          imageUrl={favIconUploadedUrl}
          type={"favIcon"}
          infoText={
            "A smaller representation of your logo. it must be square and at least 16px by 16px with a max size of 512KB."
          }
          tooltipPlace={"right"}
          title={"Icon"}
        />

        <BrandingActionSection
          backendImage={merchantData?.branding_settings?.icon_image_url}
          setImageUrl={setIconUploadedUrl}
          imageUrl={iconUploadedUrl}
          infoText={
            "The full-sized version of your logo. The logo will be adjusted in a 72px by 144px container, with a maximum file size of 512KB"
          }
          type={"icon"}
          title={"Logo"}
          tooltipPlace={"right"}
        />
        <BrandingActionSection
          backendImage={merchantData?.branding_settings?.branding_image_url}
          setImageUrl={setBrandingUploadedUrl}
          imageUrl={brandingUploadedUrl}
          type={"branding"}
          title={"Background"}
        />
        {merchantData?.account_type === "platform" && (
          <BrandingActionSection
            backendImage={merchantData?.branding_settings?.platform_logo_url}
            setImageUrl={setPlatformOnboardingLogoUploadedUrl}
            imageUrl={platformOnboardingLogoUploadedUrl}
            type={"platformLogo"}
            title={"Connect Onboarding Logo"}
          />
        )}

        <BrandingActionSection
          title={"Brand color"}
          isColor
          setColorForMap={setColorForMap}
          colorForMap={colorForMap}
          setRoutesForMap={setRoutesForMap}
          noBorder
        />

        <div className={classNames("flex w-full items-center justify-between mb-7", styles.textAreaCont)}>
          <div className={styles.textTitle}>Burq chat</div>
          <CommonToggle
            id="chatToggle"
            smsHandler={() => {
              setIsChatEnabled((prev) => !prev);
            }}
            checked={isChatEnabled}
            translateX={isdesktop ? 14.5 : 20}
            circleStyle={{
              width: isdesktop ? "11px" : "20px",
              height: isdesktop ? "11px" : "20px",
              backgroundColor: Colors.white,
            }}
            style={{
              width: isdesktop ? "30px" : "46px",
              height: isdesktop ? "16px" : "22px",
              padding: "3px",
              backgroundColor: isChatEnabled ? Colors.bluePrimary : Colors.fontGreyLight,
            }}
          />
        </div>
        <div className={styles.textAreaCont}>
          {userId ? (
            <textarea
              style={{ opacity: isChatEnabled ? 0.5 : 1 }}
              disabled={isChatEnabled}
              className={styles.textArea}
              id="codeSnippet"
              placeholder="Add Code Snippet"
              rows={5} // You can adjust the number of rows as needed
              cols={80} // You can adjust the number of columns as needed
              value={codeSnippet}
              onChange={(e) => {
                setCodeSnippet(e.target.value);
              }}
            />
          ) : (
            <textarea
              // style={{ opacity: isChatEnabled ? 0.5 : 1 }}
              style={{ opacity: 0.5 }}
              disabled
              className={styles.textArea}
              id="codeSnippet"
              placeholder="Reach out to customer support for help with integrating a custom code snippet."
              rows={5} // You can adjust the number of rows as needed
              cols={80} // You can adjust the number of columns as needed
              value={codeSnippet}
              onChange={(e) => {
                setCodeSnippet(e.target.value);
              }}
            />
          )}
        </div>
        <div className="flex w-full justify-center mt-7 items-center">
          {isPostBrandingLoading ? (
            <div className="w-full flex justify-center">
              <Loading color="#ECF0F1" className="h-6 w-6 animate-spin" />{" "}
            </div>
          ) : (
            <Button onClick={saveChangesHandler} text="Save changes" externalClassName={styles.saveButton} />
          )}
        </div>
      </div>

      <div
        className="kata"
        style={{
          position: "absolute",
          left: "0px",
          top: isdesktop ? (isInsideStore ? "210px" : "70px") : isInsideStore ? "380px" : "200px",
          bottom: "0px",
          right: "0px",
          display: "flex",
          marginLeft: isdesktop ? "350px" : "30px",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <div className="w-full h-full flex items-start">
          <DetailsMemo
            brandingColor={colorForMap}
            iconUploadedUrl={iconUploadedUrl}
            merchantData={merchantData}
            brandingUploadedUrl={brandingUploadedUrl}
          />
          <div style={{ width: "54%", height: "100%" }}>
            <MapOnTracking
              containerElement={<div style={{ height: "100%", width: "100%" }} />}
              dropoffCoordsArr={[
                {
                  lat: 40.7453911,
                  lng: -73.9904866,
                },
              ]}
              pickupCoordinates={{
                lat: 40.7581699,
                lng: -73.981422,
              }}
              deliveryInfo={DUMMY_DELIVERY}
              // routesForMap={null}
              routesForMap={routesForMap}
              color={colorForMap}
              center={{
                lat: 40.7581699,
                lng: -73.981422,
              }}
              isOnBranding
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandingWorkflow;
