import React, { useEffect } from "react";
import styles from "./styles/orderDetails.module.css";
import TopHeaderSection from "./TopHeaderSection";
import { useParams } from "react-router-dom";
import { useMyStore, useOrders, useStoreAndChild, userDataStore } from "../../../store";
import SecondHeaderSection from "./SecondHeaderSection";
import ItemsSection from "./ItemsSection";
import CustomerDetails from "./CustomerDetails";
import useViewportMeta from "../../../customHooks/useViewportMeta";
import DriverDetails from "./DriverDetails";
import PaymentDetails from "./PaymentDetails";
import DeliveryPreference from "./DeliveryPreference";
import StatusTimeline from "./StatusTimeline";
import classNames from "classnames";
import ActionMenuSection from "./ActionMenuSection";
import Incidents from "./Incidents";
import { setCookie } from "../../../utils";

const OrderDetails = () => {
  useViewportMeta();
  const { id } = useParams();
  const { order, setOrder, getOrderWorker } = useOrders();
  const { setIsSandbox } = useMyStore();
  const { authInfo } = userDataStore();
  const { getAllStoresByUser } = useStoreAndChild();

  const getOrderHandler = async (id) => {
    try {
      const order = await getOrderWorker(id);
      setIsSandbox(order?.test_mode);
      setCookie("testModeCookie", order?.test_mode ? "true" : "false");
    } catch (error) {}
  };

  const getStoresHandler = async () => {
    const stores = await getAllStoresByUser(authInfo.merchantAccountId);
    const store = stores?.data?.find((store) => store.id === order.store_id);
    setOrder({ ...order, pickup_address: store.address });
  };

  useEffect(() => {
    if (order?.store_id && !order.pickup_address && authInfo) {
      getStoresHandler();
    }
  }, [order, authInfo]);

  useEffect(() => {
    if (id) {
      getOrderHandler(id);
    }
  }, [id]);

  useEffect(() => {
    return () => {
      setOrder();
    };
  }, []);

  return (
    <div className={classNames(styles.container, "kata")}>
      <TopHeaderSection />
      <div className={styles.gridContainer}>
        <div className={styles.leftSection}>
          <SecondHeaderSection />
          <div className={styles.leftActionMenuSection}>
            <ActionMenuSection />
          </div>
          <Incidents />
          <CustomerDetails />
          <ItemsSection />
          <StatusTimeline />
        </div>
        <div className={styles.rightSection}>
          <div className={styles.rightActionMenuSection}>
            <ActionMenuSection />
          </div>
          <PaymentDetails />
          <DriverDetails />
          {order?.current_delivery &&
            (order.current_delivery.proof_of_delivery?.length > 0 || order.current_delivery?.signature) && <DeliveryPreference />}
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
