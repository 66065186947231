import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import classNames from "classnames";

import bad from "../../../assets/png/ratings/bad.png";
import excellent from "../../../assets/png/ratings/excellent.png";
import good from "../../../assets/png/ratings/good.png";
import poor from "../../../assets/png/ratings/poor.png";
import satisfied from "../../../assets/png/ratings/satisfied.png";

import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";
import styles from "./styles.module.css";
import CustomModal from "../../CustomModal/CustomModal";
import ModalContent from "./ModalContent";

const ratingStatuses = [
  {
    name: "Excellent",
    img: excellent,
    id: 0,
    color: "#2FB24C",
    value: 5,
  },
  {
    name: "Satisfied",
    img: satisfied,
    id: 1,
    color: "#97CC3E",
    value: 4,
  },
  {
    name: "Good",
    img: good,
    id: 2,
    color: "#FFCE2B",
    value: 3,
  },
  {
    name: "Poor",
    img: poor,
    id: 3,
    color: "#F7941D",
    value: 2,
  },
  {
    name: "Very bad",
    img: bad,
    id: 4,
    color: "#FF3F32",
    value: 1,
  },
];

const RatingModal = ({ delId, getDeliveryInfo, itemInfo, delInfoHandler }) => {
  const { isdesktop, xlfontsize } = useDesktopOrLaptop();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isFeedbakcButtonVisible, setIsFeedbakcButtonVisible] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const sidePopupHandlerOnLoad = () => {
      const clientSecret = new URLSearchParams(window.location.search).get(
        "feedback"
      );

      if (itemInfo.status !== "delivered") {
        setIsFeedbakcButtonVisible(false);
        return;
      }

      if (clientSecret === "true") {
        setIsModalVisible(true);
      } else if (clientSecret === "false") {
        setIsModalVisible(false);
      } else if (
        !itemInfo?.rating?.rating_score &&
        itemInfo?.status === "delivered"
      ) {
        setIsModalVisible(true);
        setSearchParams({ feedback: true });
      }
    };
    if (itemInfo) sidePopupHandlerOnLoad();
  }, [window.location.href, itemInfo]);

  return (
    <div className="">
      {isFeedbakcButtonVisible && (
        <div
          onClick={() => {
            setIsModalVisible(true);
            setSearchParams({ feedback: true });
          }}
          style={{
            fontSize: xlfontsize,
            zIndex: 999,
          }}
          className={classNames(
            "absolute flex justify-center items-center cursor-pointer rotate-90 transform rounded-b-xl",
            !isdesktop ? "top-96 p-0 px-6 py-4" : "top-80 p-0 px-3 py-2",
            styles.giveFeedback,
            { [styles.giveDesktop]: isdesktop }
          )}
        >
          Give Feedback
        </div>
      )}
      <CustomModal
        onClose={() => {
          setIsModalVisible(false);
          setSearchParams({ feedback: false });
        }}
        isOpen={isModalVisible}
        extraPanelStyles={
          isdesktop
            ? {
                maxWidth: isdesktop ? "45%" : "98%",
                backgroundColor: "transparent",
                position: "absolute",
                width: "auto",
                boxShadow: "none",
              }
            : {
                position: "absolute",
                bottom: !isdesktop ? "10px" : "20%",
                backgroundColor: "transparent",
                maxWidth: isdesktop ? "50%" : "98%",
              }
        }
      >
        <ModalContent
          delInfoHandler={delInfoHandler}
          setSearchParams={setSearchParams}
          itemInfo={itemInfo}
          getDeliveryInfo={getDeliveryInfo}
          delId={delId}
          ratingStatuses={ratingStatuses}
          setIsModalVisible={setIsModalVisible}
        />
      </CustomModal>
    </div>
  );
};

export default RatingModal;
