import { createStore } from "aniuta";
import { useState } from "react";
import { toast } from "react-toastify";
import { getProviders } from "../utils/api/account";

const useDeliveryWorkflow = createStore({
  name: "useDeliveryWorkflow",
  Store: () => {
    const [merchantProviders, setMerchantProviders] = useState();
    const getMerchantProvidersHandler = async (id) => {
      try {
        const { data } = await getProviders(id);
        setMerchantProviders(data);
        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Something went wrong! Please try again.");
        }
      }
    };

    return { getMerchantProvidersHandler, merchantProviders, setMerchantProviders };
  },
});

export default useDeliveryWorkflow;
