import React from "react";
import { useTheme } from "../../../../store";
import ToolTip from "../../../Tooltip/Tooltip";

function SingleItemForModalResponsive({ name, quantity, size, index }) {
  const { Colors } = useTheme();

  return (
    <div
      style={{
        paddingBottom: "20px",
        marginBottom: "20px",
        width: "100%",
        marginTop: "4px",
      }}
      className="flex justify-around items-start"
    >
      <div
        style={{
          ...Styles.categoryText,
          ...{ color: Colors.fontDark },
        }}
      >
        {index+1}
      </div>
      <div style={{ ...Styles.categoryText, ...{ color: Colors.fontDark } }} 
        className="truncate" data-tip
        data-for={`modal-responsive-item-name-${index}`} >
        {name}
      </div>

      <div style={{ ...Styles.categoryText, ...{ color: Colors.fontDark } }}>
        {quantity}
      </div>

      <div
        style={{
          ...Styles.categoryText,
          ...{ color: Colors.fontDark },
        }}
      >
        {size}
      </div>
      <ToolTip id={`modal-responsive-item-name-${index}`} disable={name.length > 18? false: true} text={name} />
    </div>
  );
}
const Styles = {
  categoryText: {
    fontFamily: "Roboto",
    fontSize: "25px",
    fontWeight: "400",
    width: "100%",
    alignSelf: "center",
  },
};

export default SingleItemForModalResponsive;
