import React from 'react';
import Loader from '../../../../shared/atoms/Loader';
import { useInvoices } from '../../../../../store';
import NoDataState from './NoDataState';
import InvoiceStoreItem from './InvoiceStoreItem';
import styles from "../styles/InvoiceStoresDetails/tableBody.module.css";

const TableBody = () => {
  const { isInvoicesStoresLoading, invoiceStores } = useInvoices();

  if (isInvoicesStoresLoading) return <Loader extraClassName={styles.loader}/>
  return (
    <div>
      {invoiceStores?.data?.length > 0 ? (
        invoiceStores?.data.map((item) => (
          <InvoiceStoreItem
            item={item}
            key={item.id}
          />
        ))
      ) : (
        <NoDataState />
      )}
    </div>
  );
};

export default TableBody;
