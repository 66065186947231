import React from "react";
import styles from "./styles.module.css";
import InputErrorComp from "../../../shared/common/InputErrorComp";
import classNames from "classnames";

export const CommonInputWithErrorMessage = ({
  lable,
  required,
  error,
  value,
  placeholder,
  name,
  onFocus,
  onChange,
  type,
  extraInputClass,
  touched,
  ...props
}) => {
  return (
    <div className="relative">
      <div className={styles.inputLable}>
        {lable}
        {required && <span className={styles.required}>*</span>}
      </div>
      <div className={styles.inputForEdit}>
        <input
          onFocus={onFocus}
          onChange={onChange}
          placeholder={placeholder}
          name={name}
          value={value}
          type={type}
          className={classNames(styles.input, extraInputClass)}
          {...props}
        />
      </div>
      <div className="absolute -bottom-5">
        <InputErrorComp error={error && touched ? error : undefined} />
      </div>
    </div>
  );
};
