import classNames from "classnames";
import React from "react";
import styles from "../styles.module.css";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import { IoCheckbox } from "react-icons/io5";
import { notificationsSettingsObject } from "./utils";
import { useNotifSettings } from "../../../store";

const OneNotifRow = ({
  item,
  authInfo,
  index,
  notificationsSettings,
  setNotificationsSettings,
}) => {
  const { updateNotificationSetting } = useNotifSettings();

  return (
    <div
      className={classNames("flex items-center justify-start", styles.rowCont)}
    >
      <div className={styles.title}>
        <div>{notificationsSettingsObject[item.notification_type].label}</div>
        <div className={styles.desc}>
          {notificationsSettingsObject[item.notification_type].description}
        </div>
      </div>

      <div
        className={classNames(
          "flex w-40 items-center justify-between",
          styles.emailWeb
        )}
      >
        <div
          onClick={() => {
            const payload = {
              email_enabled: !item?.email_enabled,
              push_enabled: item?.push_enabled,
            };

            const _updateList = {
              data: [...notificationsSettings.data],
              has_more: notificationsSettings.has_more,
            };
            _updateList.data[index] = { ...item, ...payload };
            setNotificationsSettings(_updateList);

            updateNotificationSetting(authInfo?.id, item?.id, payload);
          }}
          className="w-1/2 flex items-center justify-center"
        >
          {item?.email_enabled ? (
            <IoCheckbox className={classNames(styles.checkBoxChecked)} />
          ) : (
            <MdCheckBoxOutlineBlank className={classNames(styles.checkBox)} />
          )}
        </div>
        <div
          onClick={() => {
            const payload = {
              email_enabled: item?.email_enabled,
              push_enabled: !item?.push_enabled,
            };
            const _updateList = {
              data: [...notificationsSettings.data],
              has_more: notificationsSettings.has_more,
            };
            _updateList.data[index] = { ...item, ...payload };
            setNotificationsSettings(_updateList);
            updateNotificationSetting(authInfo?.id, item?.id, payload);
          }}
          className="w-1/2 flex items-center justify-center"
        >
          {item?.push_enabled ? (
            <IoCheckbox className={classNames(styles.checkBoxChecked)} />
          ) : (
            <MdCheckBoxOutlineBlank className={classNames(styles.checkBox)} />
          )}
        </div>
      </div>
    </div>
  );
};
export default OneNotifRow;
