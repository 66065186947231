import React, { useEffect, useRef, useState } from "react";
import { useMyStore, userDataStore, useTheme, useWorkflow } from "../../../../store";
import CommonToggle from "../../../CommonToggle";
import VariableInput from "../../SmsWorkflow/VariableInput";
import { useFlag } from "@unleash/proxy-client-react";

const RightBar = ({ isdesktop, isInsideStore }) => {
  const { authInfo } = userDataStore();

  const {
    selectedStatus,
    workflowStatuses,
    setSelectedStatus,
    postCreateSmsSetting,
    getSmsSettings,
    updateSmsSetting,
    configuredSettings,
    getSmsworkflowHandler,
  } = useWorkflow();
  const { Colors } = useTheme();

  const [smsTextArea, setSmsTextArea] = useState("");
  const { userId } = useMyStore();

  const [statusName, setStatusName] = useState("");
  const [smsToPickup, setSmsToPickup] = useState(false);
  const [smsToDropoff, setSmsToDropoff] = useState(false);
  const [smsToTeam, setSmsToTeam] = useState(false);
  const [desc, setDesc] = useState("");
  const [textArray, setTextArray] = useState([]);

  const [selectedOption, setSelectedOption] = useState("");

  const checkToPickup = () => {
    setSmsToPickup(!smsToPickup);
    setSmsToDropoff(false);
  };
  const checkToDropoff = () => {
    setSmsToDropoff(!smsToDropoff);
    setSmsToPickup(false);
  };
  const checkToTeam = () => {
    setSmsToTeam(!smsToTeam);
  };

  useEffect(() => {
    const wording =
      selectedStatus?.name === "Feedback"
        ? "We’ve delivered your order! Your thoughts matter to us. Please share your feedback using the tracking link: {{feedback}}."
        : "Status of your delivery from {{businessName}} is {{status}}. You can track it here. {{trackingUrl}}";
    if (selectedStatus) {
      setStatusName(selectedStatus?.name ? selectedStatus?.name : "status");
      setDesc(selectedStatus?.desc || "");
      setSmsTextArea(selectedStatus?.message_template || wording);

      setTextArray(selectedStatus?.message_template?.split(" "));

      setSmsToTeam(selectedStatus?.recipient_type === "dropoff_contract");
      setSmsToPickup(selectedStatus?.recipient_type === "pickup_contact");
      setSmsToDropoff(selectedStatus?.recipient_type === "dropoff_contact");
    }
    setSelectedOption("");
  }, [selectedStatus, workflowStatuses, configuredSettings]);

  const styles = {
    inputs: {
      padding: "8px 12px",
      fontFamily: "Poppins",
      borderWidth: "1px",
      borderColor: "#E2E2EA",
      borderRadius: "10px",
      fontSize: "14px",
      fontWeight: "400",
      outline: "none",
    },
    lables: {
      fontFamily: "Poppins",
      fontSize: "16px",
      marginBottom: "10px",
      marginTop: "16px",
      fontWeight: "400",
    },
  };

  return (
    <div
      className="kata"
      style={{
        position: "absolute",
        transform: selectedStatus ? `translate(0px)` : `translate(440px)`,
        right: "0px",
        top: isInsideStore ? (isdesktop ? "210px" : "240px") : isdesktop ? "70px" : "200px",
        bottom: "0px",
        width: "440px",
        padding: "24px",
        backgroundColor: Colors.white,
        display: "flex",
        flexDirection: "column",
        justifyContent: isdesktop ? "space-between" : "start",
        zIndex: 3,
      }}
    >
      <div>
        <div
          style={{
            fontFamily: "Poppins",
            fontWeight: "500",
            fontSize: "18px",
            color: Colors.fontDark,
          }}
        >
          {statusName}
        </div>

        <div style={{ color: Colors.fontGreyLight, marginTop: "10px" }}>{desc}</div>

        <div
          style={{
            ...styles.inputs,
            ...{
              backgroundColor: Colors.white,
              color: Colors.fontDark,
              display: "flex",
              alignItems: "center",
            },
          }}
          id={`statusesDropdownId`}
        >
          +
          <select
            style={{
              width: "100%",
              outline: "none",
              backgroundColor: Colors.white,
              // textTransform: "capitalize",
            }}
            value={selectedOption}
            name="variables"
            draggable={true}
            onChange={(e) => {
              let text = smsTextArea;
              text = text + " " + e.target.value;
              setSmsTextArea(text);
              setSelectedOption(e.target.value);
            }}
          >
            <option value="">Choose the Variable</option>
            <option value="{{businessName}}">businessName</option>
            {/* <option value="{{userName}}">userName</option> */}
            <option value="{{pickupAddress}}">pickupAddress</option>
            <option value="{{pickupName}}">pickupName</option>
            <option value="{{pickupEta}}">pickupEta</option>
            <option value="{{driverName}}">driverName</option>
            <option value="{{dropoffName}}">dropoffName</option>
            <option value="{{dropoffEta}}">dropoffEta</option>
            <option value="{{status}}">status</option>
            <option value="{{providerName}}">providerName</option>
            <option value="{{trackingUrl}}">trackingUrl</option>

            {/* <option value="{{feedback}}">FeedbackUrl</option> */}
          </select>
        </div>

        <VariableInput value={smsTextArea} setValue={setSmsTextArea} />

        {/*     <div
          contentEditable
          onInput={(e) => {
          }}
          ref={highLightRef}
          value={smsTextArea}
          // onChange={(e) => {
          //   setSmsTextArea(e.target.value);
          // }}
          style={{
            backgroundColor: Colors.detailsBg,
            minHeight: "200px",
            width: "100%",
            marginTop: "20px",
            borderRadius: "10px",
            padding: "12px",
            color: Colors.fontDark,
            fontSize: "18px",
            position: "relative",
          }}
        >
          dasd
        </div>

 <div
          contentEditable
          value={smsTextArea}
          onInput={(e) => {
            setSmsTextArea(e.target.innerText);
          }}
          style={{
            backgroundColor: Colors.detailsBg,
            minHeight: "200px",
            width: "100%",
            marginTop: "20px",
            borderRadius: "10px",
            padding: "12px",
            color: Colors.fontDark,
            fontSize: "18px",
            position: "relative",
          }}
        >
          {textArray.map((item) => {
            if (
              item === "{businessName}" ||
              item === "{userName}" ||
              item === "{pickupName}" ||
              item === "{dropoffName}" ||
              item === "{pickupAddress}" ||
              item === "{dropoffAddress}" ||
              item === "{status}" ||
              item === "{trackingUrl}"
            ) {
              return (
                <span
                  key={item}
                  contentEditable={false}
                  style={{
                    marginRight: "5px",
                    backgroundColor: Colors.bluePrimary,
                    padding: "2px 6px",
                    borderRadius: "6px",
                    color: "white",
                    whiteSpace: "nowrap",
                  }}
                >
                  {item}
                </span>
              );
            } else {
              return (
                <span
                  key={item}
                  style={{
                    marginRight: "5px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {item}
                </span>
              );
            }
          })}
        </div> */}

        {/* // ? regular textarea without highlight /*}
        {/* <textarea
          value={smsTextArea}
          onChange={(e) => {
            setSmsTextArea(e.target.value);
          }}
          style={{
            backgroundColor: Colors.detailsBg,
            minHeight: "200px",
            width: "100%",
            marginTop: "20px",
            borderRadius: "10px",
            padding: "12px",
            color: Colors.fontDark,
            fontSize: "18px",
          }}
          name="sms"
          id=""
          cols="30"
          rows="10"
        ></textarea> */}
        {/* // ? regular textarea with library highlight /*}

        {/* <div
          style={{
            backgroundColor: Colors.detailsBg,
            minHeight: "200px",
            width: "100%",
            marginTop: "20px",
            borderRadius: "10px",
            padding: "12px",
            color: Colors.fontDark,
            fontSize: "18px",
          }}
        >
          <HighlightWithinTextarea
            highlight={["{businessName}"]}
            value={smsTextArea}
            onChange={(value) => setSmsTextArea(value)}
          />
        </div> */}

        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <CommonToggle
            smsHandler={checkToPickup}
            checked={smsToPickup}
            translateX={isdesktop ? 14 : 22}
            circleStyle={{
              width: isdesktop ? "18px" : "30px",
              height: isdesktop ? "18px" : "30px",
              backgroundColor: Colors.white,
            }}
            style={{
              width: isdesktop ? "42px" : "60px",
              height: isdesktop ? "20px" : "32px",
              padding: "3px",
              backgroundColor: smsToPickup ? "#50A63E" : Colors.fontGreyLight,
            }}
          />
          <div style={{ color: Colors.fontGreyLight, marginLeft: "12px" }}>Send SMS notification to the pick-up phone number</div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <CommonToggle
            smsHandler={checkToDropoff}
            checked={smsToDropoff}
            translateX={isdesktop ? 14 : 22}
            circleStyle={{
              width: isdesktop ? "18px" : "30px",
              height: isdesktop ? "18px" : "30px",
              backgroundColor: Colors.white,
            }}
            style={{
              width: isdesktop ? "42px" : "60px",
              height: isdesktop ? "20px" : "32px",
              padding: "3px",
              backgroundColor: smsToDropoff ? "#50A63E" : Colors.fontGreyLight,
            }}
          />
          <div style={{ color: Colors.fontGreyLight, marginLeft: "12px" }}>
            Send SMS notification to the drop-off phone number
          </div>
        </div>
        {/* <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <CommonToggle
            disabled
            smsHandler={checkToTeam}
            checked={smsToTeam}
            translateX={isdesktop ? 20 : 22}
            circleStyle={{
              width: isdesktop ? "18px" : "40px",
              height: isdesktop ? "18px" : "40px",
              backgroundColor: Colors.white,
            }}
            style={{
              width: isdesktop ? "42px" : "66px",
              height: isdesktop ? "20px" : "42px",
              padding: "3px",
              backgroundColor: smsToTeam ? "#50A63E" : Colors.fontGreyLight,
              opacity: 0.4,
            }}
          />
          <div
            style={{
              color: Colors.fontGreyLight,
              marginLeft: "12px",
              opacity: 0.4,
            }}
          >
            Send SMS to the team
          </div>
        </div> */}
      </div>
      <div style={{ width: "100%", marginTop: "50px" }}>
        <button
          disabled={!(smsToDropoff || smsToPickup)}
          type="button"
          onClick={() => {
            let recipient_type = smsToDropoff ? "dropoff_contact" : smsToPickup ? "pickup_contact" : "";
            if (selectedStatus.object === "sms_setting") {
              updateSmsSetting(selectedStatus.id, smsTextArea, recipient_type).then((res) => {
                if (res?.id) {
                  getSmsSettings(userId, authInfo?.merchantAccountId);
                  getSmsworkflowHandler(userId, authInfo?.merchantAccountId);
                }
              });
            } else {
              postCreateSmsSetting(authInfo?.merchantAccountId, selectedStatus.id, smsTextArea, recipient_type, userId).then(
                () => {
                  getSmsSettings(userId, authInfo?.merchantAccountId);
                  getSmsworkflowHandler(userId, authInfo?.merchantAccountId);
                }
              );
            }

            setSelectedStatus();
          }}
          style={{
            backgroundColor: Colors.bluePrimary,
            fontFamily: "Poppins",
            fontSize: isdesktop ? "16px" : "28px",
            fontWeight: "500",
            color: "#FAFAFB",
            textAlign: "center",
            borderRadius: "8px",
            padding: "10px 30px",
            opacity: smsToDropoff || smsToPickup ? 1 : 0.3,
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default RightBar;
