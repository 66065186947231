import React, { useState, useEffect } from "react";
import { useMyStore } from "../store";
import { AddressSvg } from "../assets/svgs/Svgs";
import AddEditAddress from "../modals/AddEditAddress";
import { VscTrash, VscEdit } from "react-icons/vsc";
// import Colors from "../assets/Colors";
import { useTheme } from "../store";
import CustomModal from "./CustomModal/CustomModal";
import { useDesktopOrLaptop } from "../services/useDesktopOrLaptop";
import AreYouSurePopup from "./shared/common/AreYouSurePopup";

function AddressBook() {
  const { isdesktop, smfontsize, mdfontsize } = useDesktopOrLaptop();

  const { Colors } = useTheme();

  const {
    getSavedAddresses,
    getAddressesData,
    deleteSavedAddress,
    deleteAddressData,
  } = useMyStore();
  const [isEditAddAddressVisible, setIsEditAddAddressVisible] = useState(false);

  const [itemForEditModal, setitemForEditModal] = useState();

  const [selectedAddress, setSelectedAddress] = useState();

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  useEffect(() => {
    getSavedAddresses();
    return () => {
      setSelectedAddress();
    };
  }, [isEditAddAddressVisible, deleteAddressData]);

  return (
    <div
      style={{
        height: "100%",
      }}
    >
      <div className="flex flex-row w-full justify-end items-center">
        <button
          id="addNewAddressButtonId"
          style={{
            backgroundColor: Colors.bluePrimary,
            borderRadius: "8px",
            fontFamily: "Poppins",
            fontWeight: "500",
            fontSize: mdfontsize,
            color: "#FAFAFB",
            padding: isdesktop ? "10px 14px" : " 16px 24px",
          }}
          onClick={() => {
            setIsEditAddAddressVisible(true);
          }}
          type="button"
          className="focus:outline-none  text-center"
        >
          Add new Address
        </button>
      </div>
      <div style={{ height: `calc(100% - 100px)`, overflowY: "auto" }}>
        {getAddressesData?.length > 0 ? (
          getAddressesData.map((item, index) => {
            return (
              <div
                style={{
                  paddingLeft: "16px",
                  paddingRight: "16px",
                  borderRadius: "8px",
                }}
                key={index.toString()}
                // type="button"
                className={`flex justify-between flex-row items-center mt-10  pb-2 w-full focus:outline-none `}
              >
                <div>
                  <div
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "600",
                      fontSize: smfontsize,
                      marginBottom: "12px",
                      color: Colors.fontDark,
                    }}
                    className="font-semibold  text-xl text-left "
                  >
                    {item.businessName ? item.businessName : "Business Name"}
                    {" - "}
                    {item.firstName + ` ` + item.lastName}
                  </div>
                  <div
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "500",
                      fontSize: smfontsize,
                      color: Colors.fontGreyLight,
                      paddingLeft: "4px",
                      paddingRight: "4px",
                    }}
                    className="font-normal text-gray-700 flex-row flex items-center"
                  >
                    <div className="mr-3 mb-1">
                      <AddressSvg />
                    </div>
                    <div
                      id={`${
                        index === getAddressesData?.length - 1
                          ? "lastAddedAddress"
                          : ""
                      }`}
                      className="text-center"
                    >
                      {item.address}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "70px",
                  }}
                >
                  <button
                    id="addNewAddressButtonId"
                    onClick={() => {
                      setitemForEditModal(item);
                      setIsEditAddAddressVisible(true);
                    }}
                    type="button"
                    className="focus:outline-none  text-center"
                  >
                    <VscEdit size={24} color={Colors.fontDark} />
                  </button>
                  <button
                    id="removeNewAddressTrashIconId"
                    type="button"
                    onClick={() => {
                      setIsConfirmationModalOpen(true);
                      setSelectedAddress(item);
                    }}
                    className=""
                  >
                    <VscTrash size={24} color={Colors.fontDark} />
                  </button>
                </div>
              </div>
            );
          })
        ) : (
          <div style={{ fontWeight: "500", color: Colors.fontGreyLight }}>
            {" "}
            You don't have saved addresses
          </div>
        )}
      </div>
      <CustomModal
        extraPanelStyles={{ maxWidth: isdesktop ? "50%" : "80%" }}
        isOpen={isEditAddAddressVisible}
        onClose={() => setitemForEditModal(false)}
      >
        <AddEditAddress
          item={itemForEditModal}
          setitemForEditModal={setitemForEditModal}
          deleteSavedAddress={deleteSavedAddress}
          addressId={itemForEditModal?.id}
          setIsEditAddAddressVisible={setIsEditAddAddressVisible}
        />
      </CustomModal>
      <AreYouSurePopup
        isOpen={isConfirmationModalOpen}
        onClose={() => {
          setIsConfirmationModalOpen(false);
          setSelectedAddress();
        }}
        onYes={async () => {
          deleteSavedAddress(selectedAddress.id);
          setIsConfirmationModalOpen(false);
          setSelectedAddress();
        }}
      />
    </div>
  );
}

export default AddressBook;
