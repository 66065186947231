import { useState } from "react";
import * as Sentry from "@sentry/react";
import { createStore } from "aniuta";
import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { baseUrl } from "../runTimeConst";
import { zendeskMessagingWidgetAuthToken } from "../utils/api/messagingWidget";
import { ZendeskAPI } from "react-zendesk";
import { ROLES } from "../components/AccountSettings/RolesSidebar/helpers";
import { useNavigate } from "react-router-dom";
import { removeCookie, setCookie, userRoleHandler } from "../utils";

const userDataStore = createStore({
  name: "userData",
  Store: () => {
    const [changedName, setChangedName] = useState();

    // const [nameForAdmin, setNameForAdmin] = useState();
    const [changedPhone, setChangedPhone] = useState();
    const [changedCompanyName, setChangedCompanyName] = useState();
    const [changedEmail, setChangedEmail] = useState();
    const [isPostUserDataLoading, setIsPostUserDataLoading] = useState(false);
    const [newPwd, setNewPwd] = useState();
    const [confirmPwd, setConfirmPwd] = useState();
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    const [isSmsTrue, setisSmsTrue] = useState(true);
    const [wordpress, setWordpress] = useState();
    const [authInfo, setAuthInfo] = useState();
    const [authMeData, setAuthMeData] = useState();

    const [loginError, setLoginError] = useState("");
    const [showQuoteWorkflow, setShowQuoteWorkflow] = useState(false);

    const [userRole, setUserRole] = useState();

    const loginAuth = async (isFromMaps) => {
      try {
        const response = await axios.get(`${baseUrl}auth/me`, {
          headers: { "x-burq-client": "client-dashboard" },
        });

        setWordpress(response?.data?.wordpress && response.data.wordpress);
        // since backend changed for auth/me api I added authMeData state for notification counts and authInfo has now response.data.user
        setAuthInfo(response.data.user);
        setAuthMeData(response.data);
        setUserRole(userRoleHandler(response?.data?.user?.user_roles));
        // if (response?.data?.user?.user_roles[0]) {
        //   if (response?.data?.user?.user_roles[0]?.role_name === "store_admin") {
        //     setUserRole(ROLES.STORE_MANAGER);
        //   } else if (response?.data?.user?.user_roles[0]?.role_name === "store_operator_admin") {
        //     setUserRole(ROLES.STORE_EMPLOEE);
        //   } else {
        //     setUserRole(response?.data?.user?.user_roles[0]?.role_name);
        //   }
        // } else {
        //   setUserRole(ROLES.ADMIN);
        // }
        setCookie("userId", response?.data?.user?.merchantAccountId);

        if (window?.fcWidget?.user) {
          window?.fcWidget?.user?.setFirstName(
            response.data.businessName
              ? response.data?.businessName
              : response.data?.userInfo?.businessName
              ? response.data.userInfo.businessName
              : response.data.firstName
          );
          window.fcWidget.user?.setEmail(response.data.email);
          window.fcWidget.user?.setPhone(response.data.userInfo?.phoneNumber);

          window.fcWidget.user?.setProperties({
            businessName: response.data.userInfo?.businessName,
            businessIndustry: response.data.userInfo?.businessIndustry,
            phoneNumber: response.data.userInfo?.phoneNumber,
            lastName: response.data.userInfo?.lastName,
            firstName: response.data.userInfo?.firstName,
            addressLineOne: response.data.userInfo?.addressLineOne,
            businesWebsite: response.data.userInfo?.businesWebsite,
            businessOtherIndustry: response.data.userInfo?.businessOtherIndustry,
            country: response.data.userInfo?.country,
            env:
              process?.env?.REACT_APP_STRIPE_RETURN_URL === "https://dashboard.burqup.com"
                ? "prod"
                : process?.env?.REACT_APP_STRIPE_RETURN_URL === "https://preprod-dashboard.burqup.com"
                ? "PreProd"
                : "Dev", // meta property 1
          });
        }

        return response;
      } catch (error) {
        // console.log("object", (500).toString()[0], error.response);
        if (error?.response?.status.toString()[0] === "5" || !error?.response) {
          window.location.href = "/status";
          return;
        }
        // if (error?.response?.status === 401 || error?.response?.status === 502 || error?.response?.status === 504) {
        //   window.location.href = "/status";
        //   return;
        // }

        if (error?.response?.data?.message && !isFromMaps) {
          if (error?.response?.status) {
            if (error?.response?.status === 401 || error.response.data?.message === "The user does not exist.") {
              Object.keys(Cookies.get()).forEach((cookieName) => {
                removeCookie(cookieName);
              });
              setTimeout(() => {
                window.location.href = `${baseUrl}auth/login`;
              }, 1000);
            } else if (error.response.data?.message === "There is already a account with the provided email.") {
              Object.keys(Cookies.get()).forEach((cookieName) => {
                removeCookie(cookieName);
              });
              toast.error(error.response.data.message);
              setTimeout(() => {
                ZendeskAPI("messenger", "logoutUser");
                window.location.href = `${baseUrl}auth/logout`;
              }, 1500);
            } else {
              Object.keys(Cookies.get()).forEach((cookieName) => {
                removeCookie(cookieName);
              });
              setTimeout(() => {
                ZendeskAPI("messenger", "logoutUser");
                window.location.href = `${baseUrl}auth/logout`;
              }, 1000);
            }
          }
        } else {
          Sentry.captureException(error);
        }
        return error;
      }
    };

    const verificationHandler = async (data) => {
      // ! /api/

      try {
        await axios.post(
          `${baseUrl}auth/UserData/verify`,
          {
            data: data,
          },
          { headers: { "x-burq-client": "client-dashboard" } }
        );
        toast.success("Success!");
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      }
    };

    const userDataPostRequest = async (photo, newPwd, confirmPwd, userId) => {
      setIsPostUserDataLoading(true);
      try {
        await axios.post(
          `${baseUrl}auth/UserData/${userId ? userId : ""}`,
          {
            imageUrl: photo && photo,
            fullName: changedName,
            phoneNumber: changedPhone,
            businessName: changedCompanyName,
            newPwd: newPwd ? newPwd : "",
            confirmPwd: confirmPwd ? confirmPwd : "",
          },
          { headers: { "x-burq-client": "client-dashboard" } }
        );
        toast.success("Success!!");
        setIsPostUserDataLoading(false);
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error("Password is too weak");
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
        setIsPostUserDataLoading(false);
      }
    };

    const smsNotificationsGetRequest = async (id, userId) => {
      try {
        await axios.get(`${baseUrl}auth/SendSMS?SMS=${id}`, {
          headers: { "x-burq-client": "client-dashboard" },
        });
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    const getZendeskMessagingAuthToken = async () => {
      try {
        const { data } = await zendeskMessagingWidgetAuthToken();
        return data;
      } catch (error) {
        Sentry.captureException(error);
        return error;
      }
    };

    return {
      // userDataGetRequest,
      userDataPostRequest,
      smsNotificationsGetRequest,
      changedCompanyName,
      setChangedCompanyName,
      changedPhone,
      setChangedPhone,
      changedName,
      setChangedName,
      changedEmail,
      setChangedEmail,
      isPostUserDataLoading,
      isSmsTrue,
      setisSmsTrue,
      isPasswordVisible,
      confirmPwd,
      setConfirmPwd,
      newPwd,
      setNewPwd,
      selectedFile,
      setSelectedFile,
      loginAuth,
      // avatar,
      // setAvatar,
      // isAdmin,
      wordpress,
      setWordpress,
      authInfo,
      // isUserINFOfilled,
      // setIsUserINFOfilled,
      verificationHandler,
      loginError,
      setIsPostUserDataLoading,
      showQuoteWorkflow,
      setShowQuoteWorkflow,
      getZendeskMessagingAuthToken,
      // !
      userRole,
      authMeData,
    };
  },
});

export default userDataStore;
