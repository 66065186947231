import { useState } from "react";
import { Link } from "react-router-dom";
import { FILTER_TYPES } from "../../../../constants";
import SearchFilter from "./Filters/SearchFilter";
import TypeAndSearchFilter from "./Filters/TypeAndSearchFilter";
import RadioFilter from "./Filters/RadioFilter";
import OutsideClickHandler from "react-outside-click-handler";
import MenuItemButton from "./MenuItemButton";
import classNames from "classnames";

const MenuItems = ({ items, activeFiltersIds, setActiveFiltersIds }) => {
  const [dropdown, setDropdown] = useState(false);

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setDropdown(false);
      }}
    >
      <li className={classNames("menu-items", { "item-active": dropdown })}>
        {items.type === FILTER_TYPES.SEARCH ? (
          <>
            <MenuItemButton
              title={items.title}
              filterState={items.filterState}
              dropdown={dropdown}
              setDropdown={setDropdown}
            />
            <SearchFilter
              id={items.id}
              show={dropdown}
              placeholder={items.placeholder}
              filterState={items.filterState}
              setFilterState={items.setFilterState}
              activeFiltersIds={activeFiltersIds}
              setActiveFiltersIds={setActiveFiltersIds}
            />
          </>
        ) : items.type === FILTER_TYPES.TYPE_AND_SEARCH ? (
          <>
            <MenuItemButton
              title={items.title}
              filterState={items.filterState}
              dropdown={dropdown}
              setDropdown={setDropdown}
            />
            <TypeAndSearchFilter
              filter={items}
              id={items.id}
              show={dropdown}
              list={items.list}
              renderListItem={items.renderListItem}
              placeholder={items.placeholder}
              filterState={items.filterState}
              setFilterState={items.setFilterState}
              activeFiltersIds={activeFiltersIds}
              setActiveFiltersIds={setActiveFiltersIds}
            />
          </>
        ) : items.type === FILTER_TYPES.RADIO ? (
          <>
            <MenuItemButton
              title={items.title}
              filterState={items.filterState}
              dropdown={dropdown}
              setDropdown={setDropdown}
            />
            <RadioFilter
              filter={items}
              id={items.id}
              show={dropdown}
              list={items.list}
              renderListItem={items.renderListItem}
              placeholder={items.placeholder}
              filterState={items.filterState}
              setFilterState={items.setFilterState}
              activeFiltersIds={activeFiltersIds}
              setActiveFiltersIds={setActiveFiltersIds}
            />
          </>
        ) : (
          <Link to={items.url}>{items.title}</Link>
        )}
      </li>
    </OutsideClickHandler>
  );
};

export default MenuItems;
