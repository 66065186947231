import { createStore } from "aniuta";
import { useState } from "react";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";
import {
  cancelAdminDeliveryApi,
  getAdminDelsApi,
  getUsers,
  markDeliveryAsNotReroutable,
} from "../utils/api/admin";

const useAdmin = createStore({
  name: "useAdmin",
  Store: () => {
    const [isUsersListLoading, setIsUsersListLoading] = useState(false);
    const [usersListData, setUsersListData] = useState();

    const getUsersList = async (params = {}) => {
      setIsUsersListLoading(true);
      try {
        const { data } = await getUsers(params);
        setUsersListData(data);
        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsUsersListLoading(false);
      }
    };

    const [isAdminDelsLoading, setIsAdminDelsLoading] = useState(false);
    const [adminDelsListData, setAdminDelsListData] = useState();

    // * FILTERS

    const [searchById, setSearchById] = useState();
    const [searchByAddress, setSearchByAddress] = useState();
    const [searchByName, setSearchByName] = useState();
    const [searchByPhoneNumber, setSearchByPhoneNumber] = useState();
    const [searchByDroppofName, setSearchByDroppofName] = useState();
    const [searchByDropoffPhoneNum, setSearchByDropoffPhoneNum] = useState();
    const [searchByDropoffAddress, setSearchByDropoffAddress] = useState();
    const [searchByProviderId, setSearchByProviderId] = useState();
    const [searchByBurqOrderId, setSearchByBurqOrderId] = useState();

    const getAdminDeliveries = async (param = {}) => {
      setIsAdminDelsLoading(true);

      let params = {
        ending_before: param?.ending_before ?? "",
        starting_after: param?.starting_after ?? "",
        search_by_pickup_id: param?.search_by_pickup_id ?? searchById,
        search_by_pickup_address:
          param?.search_by_pickup_address ?? searchByAddress,
        search_by_pickup_name: param?.search_by_pickup_name ?? searchByName,
        search_by_pickup_phone_number:
          param?.search_by_pickup_phone_number ?? searchByPhoneNumber,
        search_by_dropoff_name:
          param?.search_by_dropoff_name ?? searchByDroppofName,
        search_by_dropoff_phone_number:
          param?.search_by_dropoff_phone_number ?? searchByDropoffPhoneNum,
        search_by_dropoff_address:
          param?.search_by_dropoff_address ?? searchByDropoffAddress,
        test_mode: param?.test_mode,
        provider_id: param?.provider_id ?? "",
        short_id: param?.short_id ?? searchByProviderId,
        statuses: param?.statuses ?? "",
        start_date: param?.start_date ?? "",
        end_date: param?.end_date ?? "",
        search_by_order_id: param?.search_by_burq_order_id ?? searchByBurqOrderId,
      };
      try {
        const { data } = await getAdminDelsApi(params);
        setAdminDelsListData(data);
        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsAdminDelsLoading(false);
      }
    };

    const [isCancelAdminDelLoading, setIsCancelAdminDelLoading] =
      useState(false);

    const cancelAdminDelivery = async (param = {}, id) => {
      setIsCancelAdminDelLoading(true);
      try {
        const { data } = await cancelAdminDeliveryApi(param, id);
        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
        return "error";
      } finally {
        setIsCancelAdminDelLoading(false);
      }
    };

    const [isMarkDeliveryAsNotReroutableLoading, setIsMarkDeliveryAsNotReroutableLoading] = useState(false);
    const markDeliveryAsNotReroutableWorker = async (id, payload = {}, callback) => {
      setIsMarkDeliveryAsNotReroutableLoading(true);
      try {
        const { data } = await markDeliveryAsNotReroutable(id, payload);
        toast.success("The reroute info is updated successfully");
        callback({ message: "updated successfully"}, null);
        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsMarkDeliveryAsNotReroutableLoading(false);
      }
    };

    return {
      getUsersList,

      isUsersListLoading,
      setIsUsersListLoading,

      usersListData,
      setUsersListData,

      // cancel
      cancelAdminDelivery,
      isCancelAdminDelLoading,
      setIsCancelAdminDelLoading,

      // admin deliveries
      getAdminDeliveries,
      adminDelsListData,
      setAdminDelsListData,
      isAdminDelsLoading,
      setIsAdminDelsLoading,

      // filters
      searchByDropoffAddress,
      setSearchByDropoffAddress,
      searchByDropoffPhoneNum,
      setSearchByDropoffPhoneNum,
      searchByDroppofName,
      setSearchByDroppofName,
      searchByPhoneNumber,
      setSearchByPhoneNumber,
      searchByName,
      setSearchByName,
      searchByAddress,
      setSearchByAddress,
      searchById,
      setSearchById,
      searchByProviderId,
      setSearchByProviderId,
      searchByBurqOrderId, 
      setSearchByBurqOrderId,
      isMarkDeliveryAsNotReroutableLoading,
      markDeliveryAsNotReroutableWorker
    };
  },
});

export default useAdmin;
