import axios from "axios";
import apiUrls from "../../constants/apiUrls";
import { generateQueryParams } from "./utils";

// export const getMerchanIncidents = (params) => {
//   const urlWithQueryParams = generateQueryParams(
//     apiUrls.MERCHATN_INCIDENTS_REASONS,
//     params
//   );
//   return axios.get(urlWithQueryParams);
// };

export const getDeliveryIncidents = (merchantId, params) => {
  const urlWithQueryParams = generateQueryParams(apiUrls.MERCHANT_DELIVERY_INCIDENTS(merchantId), params);
  return axios.get(urlWithQueryParams);
};

export const getIncidentReasons = () =>
  axios.get(apiUrls.MERCHATN_INCIDENTS_REASONS, {
    headers: { "x-burq-client": "client-dashboard" },
  });

export const createMerchantIncident = (deliveryId, payload) =>
  axios.post(apiUrls.CREATE_MERCHANT_DELIVERY_INCIDENT(deliveryId), payload, {
    headers: { "x-burq-client": "client-dashboard" },
  });

// export const assigneAdminToIncident = (incidentId) =>
//   axios.put(apiUrls.ASSIGN_ADMIN_TO_INCIDENT(incidentId), {
//     headers: { "x-burq-client": "client-dashboard" },
//   });
