import { Menu } from "@headlessui/react";
import { forwardRef, useEffect, useState } from "react";

import styles from "./styles.module.css";
import classNames from "classnames";

import { NotificationsPopup } from "./NotificationsPopup";
import { useNotifications, usePusherUpdates, userDataStore } from "../../../store";
import axios from "axios";

import { BellSvg } from "../Svgs";

let MyCustomButton = forwardRef(function ({ count, ...props }, ref) {
  return (
    <button className={styles.bellIcon} ref={ref} {...props}>
      <div className={classNames("mt-2", styles.bellOnHeader)}>
        {count > 0 && (
          <div className={styles.bellBigCircle}>
            {" "}
            <div className={styles.numberBadge}>{count}</div>
          </div>
        )}
        <BellSvg />
      </div>
    </button>
  );
});

const Notifications = () => {
  // const isNotificationsEnabled = true;

  const { authInfo, authMeData } = userDataStore();
  const { notificationsUpdates, setNotificationsUpdates } = usePusherUpdates();
  const { getNotificationsHandler, putResetNotificationsCount } = useNotifications();

  const [isOnBellClicked, setIsOnBellClicked] = useState(false);
  const [count, setCount] = useState(0);

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  useEffect(() => {
    if (authInfo) {
      getNotificationsHandler(authInfo?.merchantAccountId, {}, source);
    }

    return () => {
      source.cancel("Operation canceled by the user.");
    };
  }, [isOnBellClicked, notificationsUpdates]);

  useEffect(() => {
    if (notificationsUpdates) {
      setCount(notificationsUpdates?.unopened_notification_count);
    } else {
      setCount(authMeData?.unopened_notification_count);
    }
  }, [authMeData, notificationsUpdates]);

  return (
    <div className="mr-5">
      <Menu as="div" className=" inline-block text-left">
        <div
          onClick={(e) => {
            setIsOnBellClicked((prev) => !prev);
            if (count > 0) {
              putResetNotificationsCount(authInfo.merchantAccountId);
              const _notUpdate = { ...notificationsUpdates };
              _notUpdate.unopened_notification_count = 0;
              setNotificationsUpdates(_notUpdate);
              // setNotificationsUpdates(null);
            }
            setCount(0);
          }}
        >
          <Menu.Button count={count} as={MyCustomButton} />
        </div>

        <NotificationsPopup source={source} />
      </Menu>
    </div>
  );
};

export default Notifications;
