import React from "react";

import { MdSearch } from "react-icons/md";
import { RiCheckboxBlankCircleFill } from "react-icons/ri";
import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";
import { useTheme } from "../../../store";

const MenuItemButton = ({ title, filterState, dropdown, setDropdown }) => {
  const { Colors } = useTheme();
  return (
    <button
      type="button"
      aria-haspopup="menu"
      aria-expanded={dropdown ? "true" : "false"}
      onClick={() => setDropdown((prev) => !prev)}
    >
      <div
        style={{
          paddingRight: "8px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <MdSearch size={16} />
      </div>
      <div
        style={{
          fontFamily: "Poppins",
          fontSize: "14px",
          display: "flex",
          alignItems: "center",
          padding: "2px 0",
          gap: "8px",
          fontWeight: "400",
          color: filterState && "#0062ff",
        }}
      >
        {title}
        {/* {filterState && <RiCheckboxBlankCircleFill color={Colors.greenDark} size={12} />} */}
      </div>
    </button>
  );
};

export default MenuItemButton;
