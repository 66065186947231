import React from "react";
import classNames from "classnames";
import styles from "../../styles/ProviderList/ProviderCard/cardFooter.module.css";
import { PARTNER_INDUSTRY_OPTIONS } from "../../utils";

const CardFooter = ({ data }) => (
  <div className={classNames(styles.itemsContainer, "kata")}>
    {data?.industry_names?.map((category, index) => (
      <div key={index} className={styles.item}>
        {PARTNER_INDUSTRY_OPTIONS[category]
          ? PARTNER_INDUSTRY_OPTIONS[category]
          : category.replace(/_/g, " ")}

        {/* {category === "laundry_dry_cleaning"
          ? "Laundry / Dry Cleaning"
          : category.replace(/_/g, " ")} */}
      </div>
    ))}
  </div>
);

export default CardFooter;
