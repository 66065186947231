import React from "react";
import { BsArrowRight } from "react-icons/bs";
import { useProvidersManagementStore } from "../../../../store";
import { badgesForReuqestAccessStatus, mapStatus } from "../../utils";
import Button from "../../../shared/atoms/Button";
import StatusBadgeReusable from "../../../shared/common/StatusBadgeReusable";
import { TABS } from "../../ProviderIntegrationDetails";
import styles from "../../styles/ProviderList/ProviderCard/requestStatus.module.css";

const RequestStatus = ({ data }) => {
  const { setSelectedProvider, setDefaultActiveTab } =
    useProvidersManagementStore();

  if (!data?.access_required) return null;

  if (
    data?.access_request_status === "pending" ||
    data?.access_request_status === "rejected"
  ) {
    return (
      <StatusBadgeReusable
        status={mapStatus[data?.access_request_status] || ""}
        badgesObject={badgesForReuqestAccessStatus}
        externalClassName={styles.badge}
        hideIcon={false}
      />
    );
  }

  return (
    <Button
      onClick={(e) => {
        setSelectedProvider(data);
        setDefaultActiveTab(TABS.REQUEST_ACCESS);
        e.stopPropagation();
      }}
      externalClassName={styles.requestAccessButtom}
    >
      Request Access
      <BsArrowRight />
    </Button>
  );
};

export default RequestStatus;
