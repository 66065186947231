import React, { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import { AiOutlineClear } from "react-icons/ai";
import { CgTimelapse } from "react-icons/cg";
import {
  useMyStore,
  useStatementsStore,
  useTheme,
  userDataStore,
} from "../../../../../store";
import { useDesktopOrLaptop } from "../../../../../services/useDesktopOrLaptop";
import CustomTypeAndSearch from "../../../../shared/CustomTypeAndSearch";
import ToolTip from "../../../../Tooltip/Tooltip";
import moment from "moment";

const PAGE_LIMIT = 30;

function FilterWithBillingEvent({
  selectedPeriod,
  statementsListHandler,
  setSelectedPeriod,
}) {
  const { billingEventsData, getBillingEventsHandler } = useStatementsStore();
  const { Colors } = useTheme();
  const { isSandbox } = useMyStore();
  const { smfontsize, isdesktop } = useDesktopOrLaptop();
  const { authInfo } = userDataStore();

  const [isListVisible, setIsListVisible] = useState(false);

  const billingPeriodHandler = async (param) => {
    let params = {
      test_mode: param?.isSandbox ?? isSandbox,

      ending_before: param?.ending_before,
      starting_after: param?.starting_after,
      limit: PAGE_LIMIT,
    };
    const res = await getBillingEventsHandler(
      authInfo.merchantAccountId,
      params
    );
    return res;
  };

  const listItemClickHandler = (listItem) => {
    if (selectedPeriod?.id === listItem.id) {
      // setIsListVisible(false);
      // setSelectedPeriod();
      // statementsListHandler({
      //   merchantId: authInfo?.merchantAccountId,
      //   billing_event_id: "",
      // });
    } else {
      setSelectedPeriod(listItem);
      statementsListHandler({
        merchantId: authInfo?.merchantAccountId,
        billing_event_id: listItem.id,
      });
    }
  };

  const renderListItem = (item) => {
    return (
      <div
        style={{ color: Colors.fontGreyLight, width: "90%", height: "30px" }}
        className=" flex flex-col items-start justify-center"
      >
        <div
          className="text-left truncate"
          style={{
            color: Colors.fontDark,
            width: "100%",
            fontSize: smfontsize,
          }}
          data-for={`list-item-${item.id}`}
        >
          {moment(item?.start_at).format("MMM D, YYYY")} -{" "}
          {moment(item?.end_at).format("MMM D, YYYY")}
        </div>
        <ToolTip
          id={`list-item-${item.id}`}
          disable={false}
          text={item?.name}
        />
      </div>
    );
  };

  const sortedItems =
    billingEventsData?.data?.length > 0 &&
    // { ...billingEventsData }.data.sort((a, b) => {
    structuredClone(billingEventsData).data.sort((a, b) => {
      const timeA = new Date(a.end_at);
      const timeB = new Date(b.end_at);
      return timeB - timeA; // Sort in descending order
    });

  return (
    <div className="relative">
      <div
        style={{
          position: "relative",
        }}
        className="flex items-center"
      >
        <button
          style={{
            borderWidth: "1px",
            borderColor: "#D8D8D8",
            borderRadius: "16px",
            padding: isdesktop ? "4px 0px" : "4px 4px",
            marginLeft: "10px",
            borderStyle: selectedPeriod ? "solid" : "dashed",
          }}
          onClick={() => {
            setIsListVisible(true);
          }}
          type="button"
          className="flex flex-row items-center focus:outline-none "
        >
          {/* {selectedPeriod ? (
            <button
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                setIsListVisible(false);
                setSelectedPeriod();
                statementsListHandler({
                  merchantId: authInfo?.merchantAccountId,
                  billing_event_id: "",
                });
              }}
              style={{ padding: "2px 0px 2px 10px" }}
            >
              <AiOutlineClear
                color={Colors.fontGreyLight}
                size={isdesktop ? 22 : 26}
              />
            </button>
          ) : (
            <div style={{ padding: "2px 0px 2px 10px" }}>
              <CgTimelapse color={Colors.fontGreyLight} size={19} />
            </div>
          )} */}
          <div style={{ padding: "2px 0px 2px 10px" }}>
            <CgTimelapse color={Colors.fontGreyLight} size={19} />
          </div>
          <div
            style={{
              fontFamily: "Poppins",
              fontSize: smfontsize,
              color: Colors.fontGreyLight,
              display: "flex",
              alignItems: "center",
              padding: selectedPeriod ? "2px 6px 2px 0px" : "2px 10px 2px 0px",
            }}
          >
            <span style={{ marginLeft: "8px" }}>Billing Period</span>
          </div>
          {selectedPeriod && (
            <div
              style={{
                padding: "2px 10px 2px 6px",
                color: Colors.fontGreyLight,
                maxWidth: "270px",
                fontSize: smfontsize,
              }}
              className="border-l border-[#D8D8D8] border-dashed truncate"
            >
              {moment(selectedPeriod?.start_at).format("MMM D, YYYY")} -{" "}
              {moment(selectedPeriod?.end_at).format("MMM D, YYYY")}
            </div>
          )}
        </button>

        <CustomTypeAndSearch
          isListVisible={isListVisible}
          setIsListVisible={setIsListVisible}
          list={billingEventsData?.data ? sortedItems : null}
          // list={billingEventsData?.data ?? null}
          listItemClickHandler={listItemClickHandler}
          selectedItem={selectedPeriod}
          listContainerStyle={{ left: "-320px", right: "0px" }}
          searchQuery={""}
          searchHidden
          renderListItem={renderListItem}
          paging
          pagingData={billingEventsData}
          onGetPaginationData={billingPeriodHandler}
        />
      </div>
    </div>
  );
}

export default FilterWithBillingEvent;
