export const mapRerouteIntentActionReasons = {
  too_many_reroute_attempts: 'Too Many Reroute Attempts',
  alternative_quote_too_expensive: 'Alternative Quote Too Expensive',
  merchant_disabled_auto_reroute: 'Merchant Disabled Auto Reroute',
  no_alternative_provider_available: 'No Alternative Provider Available',
  driver_assignment_time_expired: 'Driver Assignment Time Expired',
};

export const mapRerouteIntentActions = {
  cancel: 'Cancel Delivery',
  reroute: 'Reroute',
};

export const mapDeliveryType = {
  scheduled_pickup: "Scheduled Pickup",
  scheduled_dropoff: "Scheduled Dropoff",
  asap: "ASAP"
}

export const actionfilterListItems = [
  { label: 'Cancel Delivery', value: 'cancel' },
  { label: 'Reroute', value: 'reroute' },
]

export const mapRerouteIntentDecisions = {
  accepted: "Recommended action accepted",
  rejected: "Recommended action rejected",
};

export const mapRerouteIntentStatus = {
  canceled: "Delivery Canceled",
  rerouted: "Delivery Rerouted",
  failed: "Failed"
}
