import React, { useState } from "react";

import classNames from "classnames";
import styles from "./styles.module.css";
import { useMyStore, useTheme } from "../../../store";

const EasyPagination = ({ data, onGetPaginationData, buttonClass, isHome }) => {
  const { Colors } = useTheme();

  // const { isSandbox } = useMyStore();
  const [isPreviousDisabled, setIsPreviousDisabled] = useState(false);

  const disableStyleNext = () => {
    return {
      color: data?.has_more ? Colors.fontDark : Colors.fontGreyLight,
    };
  };

  return (
    <div className={styles.container}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <button
          type="button"
          disabled={isPreviousDisabled}
          onClick={async () => {
            const disablePrvious = await onGetPaginationData({
              ending_before: isHome ? data?.items[0]?.id : data?.data[0]?.id,
              starting_after: "",
              // isSandbox: isSandbox,
            });
            if (disablePrvious) {
              setIsPreviousDisabled(true);
            }
          }}
          className={classNames(styles.buttons, buttonClass)}
          style={{ color: isPreviousDisabled ? Colors.fontGreyLight : Colors.fontDark }}
        >
          Previous
        </button>

        <button
          type="button"
          disabled={!data?.has_more}
          onClick={() => {
            setIsPreviousDisabled(false);
            onGetPaginationData({
              ending_before: undefined,
              starting_after: isHome ? data?.items[data?.items?.length - 1]?.id : data?.data[data?.data?.length - 1]?.id,
              // isSandbox: isSandbox,
            });
          }}
          className={classNames(styles.buttons, buttonClass)}
          style={{
            ...disableStyleNext(),
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default EasyPagination;
