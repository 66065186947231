import React, { useState } from "react";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useOnboardingStore } from "../../../store";
import { Loading } from "../../../assets/svgs/Svgs";
import styles from "./styles/checkoutFormStyles.module.css";

const PaymentElementCheckoutForm = ({ options, returnUrl }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);

  const { isLoading } = useOnboardingStore();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: returnUrl,
      },
    });
    if (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <PaymentElement className={styles.paymentElement} options={options} />
      <button
        className={styles.button}
        disabled={isLoading || !stripe || !elements}
        id="submit"
      >
        <span>
          {isLoading ? (
            <div className="w-full flex justify-center">
              {" "}
              <Loading color="#ECF0F1" className="h-6 w-6 animate-spin" />{" "}
            </div>
          ) : (
            "Continue"
          )}
        </span>
      </button>
      {errorMessage && (
        <div className={styles.paymentMessage}>{errorMessage}</div>
      )}
    </form>
  );
};

export default PaymentElementCheckoutForm;
