import React from "react";
import styles from "./styles/listEmptyState.module.css";
import Loader from "../shared/atoms/Loader";

const ListLoading = () => {
  return (
    <tr className={styles.tableRow}>
      <td className="py-4 px-4 text-center" colSpan="5">
        <Loader />
      </td>
    </tr>
  );
};

export default ListLoading;
