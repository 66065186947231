import React, { useEffect, useMemo, useState } from "react";
import { useDesktopOrLaptop } from "../../../../services/useDesktopOrLaptop";
import { useAnalyticsStore, useFeatureFlags, useMyStore, userDataStore } from "../../../../store";
import { INSIGHT_DATA } from "../../utils";
import InsightCard from "./InsightCard";

const InsightChartsGrid = () => {
  const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight]);

  // Imports from stores
  const { isdesktop } = useDesktopOrLaptop();
  const { isSandbox, userId } = useMyStore();
  const { authInfo } = userDataStore();
  const { isSnowflakeDisabled } = useFeatureFlags();

  const {
    datePayload,
    compareDatePayload,
    deliveryVolume,
    getDeliveryVolume,
    // deliveryValue,
    // getDeliveryValue,
    // orderValue,
    // getOrderValue,
    averageDeliveryTime,
    getAverageDeliveryTime,
    averageMileage,
    getAverageMileage,
    deliverySuccessRate,
    getDeliverySuccessRate,
    // deliveryFailureRate,
    // getDeliveryFailureRate,
    deliveryReturns,
    getDeliveryReturns,
    averagePickupTime,
    getAveragePickupTime,
    averagePickupDelays,
    getAveragePickupDelays,
    averageDropoffTime,
    getAverageDropoffTime,
    averageDropoffDelays,
    getAverageDropoffDelays,
    isDateSelected,
    selectedProviderArray,
  } = useAnalyticsStore();

  // Styles Creation
  // (I don't like this approch, only creating styles here for the elments where we need to use
  // useDesktopOrLaptop or Colors form the theme store)

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const styles = useMemo(() => {
    return {
      gridContainer: {
        display: "grid",
        gridTemplateColumns: windowSize[0] > 1224 && isdesktop ? "48% 48%" : "100%",
        // gridTemplateColumns: windowSize[0] > 1224 && isdesktop ? "24% 24% 24% 24%" : "48% 48%",
        gap: "1.33%",
        rowGap: "15px",
      },
      gridItem: {
        fontSize: "30px",
        height: "340px",
      },
    };
  }, [isdesktop, windowSize]);

  const getPayloadData = (title) => {
    switch (title) {
      case INSIGHT_DATA.DELIVERY_VOLUME.title:
        return { chartData: deliveryVolume, getData: getDeliveryVolume };
      // case INSIGHT_DATA.DELIVERY_COST.title:
      //   return { chartData: deliveryValue, getData: getDeliveryValue };
      // case INSIGHT_DATA.ORDER_VALUE.title:
      //   return { chartData: orderValue, getData: getOrderValue };
      case INSIGHT_DATA.AVG_DELIVERY_TIME.title:
        return {
          chartData: averageDeliveryTime,
          getData: getAverageDeliveryTime,
        };
      case INSIGHT_DATA.AVG_MILEAGE.title:
        return {
          chartData: averageMileage,
          getData: getAverageMileage,
        };
      // case INSIGHT_DATA.DELIVERY_SUCCESS_RATE.title:
      //   return {
      //     chartData: deliverySuccessRate,
      //     getData: getDeliverySuccessRate,
      //   };
      // case INSIGHT_DATA.DELIVERY_FAILURE_RATE.title:
      //   return {
      //     chartData: deliveryFailureRate,
      //     getData: getDeliveryFailureRate,
      //   };
      case INSIGHT_DATA.RETURNS.title:
        return { chartData: deliveryReturns, getData: getDeliveryReturns };
      case INSIGHT_DATA.AVG_PICKUP_TIME.title:
        return { chartData: averagePickupTime, getData: getAveragePickupTime };
      case INSIGHT_DATA.AVG_PICK_UP_DELAYS.title:
        return {
          chartData: averagePickupDelays,
          getData: getAveragePickupDelays,
        };
      case INSIGHT_DATA.AVG_DROPOFF_TIME.title:
        return {
          chartData: averageDropoffTime,
          getData: getAverageDropoffTime,
        };
      case INSIGHT_DATA.AVG_DROP_OFF_DELAYS.title:
        return {
          chartData: averageDropoffDelays,
          getData: getAverageDropoffDelays,
        };

      default:
        return { chartData: {}, getData: null };
    }
  };

  const renderCard = (data) => {
    const { chartData, getData } = getPayloadData(data.title);

    return (
      <div key={data.title} style={styles.gridItem}>
        <InsightCard
          title={data.title}
          payload={chartData}
          primaryLineLable={data.primaryLineLable}
          secondaryLineLable={data.secondaryLineLable}
          valueFormat={data.valueFormat}
          getChartData={getData}
          isSandbox={isSandbox}
          datePayload={datePayload}
          compareDatePayload={compareDatePayload}
          userId={userId}
          merchantAccountId={authInfo?.merchantAccountId}
          isDateSelected={isDateSelected}
          selectedProviderArray={selectedProviderArray}
          isSnowflakeDisabled={isSnowflakeDisabled}
        />
      </div>
    );
  };

  return (
    <div style={styles.gridContainer}>
      {Object.values(INSIGHT_DATA).map((value) => {
        return renderCard(value);
      })}
    </div>
  );
};

export default InsightChartsGrid;
