import React from 'react';
import cancel from "../../../assets/png/statuses/cancel.png";
import contactingProvider from "../../../assets/png/statuses/contactingProvider.png";
import created from "../../../assets/png/statuses/created.png";
import delivered from "../../../assets/png/statuses/delivered.png";
import dropoff from "../../../assets/png/statuses/dropoff.png";
import pickupPng from "../../../assets/png/statuses/pickup.png";
import scheduled from "../../../assets/png/statuses/scheduled.png";
import returnPng from "../../../assets/png/statuses/return.png";
import attemptingReroute from "../../../assets/png/statuses/attemptingReroute.png";
import rerouted from "../../../assets/png/statuses/rerouted.png";
import styles from "../styles/deliveriesListNew.module.css"
import { useDesktopOrLaptop } from '../../../services/useDesktopOrLaptop';

const DeliveryListIcon = ({ statusSocket, rerouteStatus }) => {
  const { isdesktop } = useDesktopOrLaptop();


  const getIconImage = () => {
    if (rerouteStatus === "provider_search") {
      return attemptingReroute
    } else if (rerouteStatus === "rerouted") {
      return rerouted
    } else if (statusSocket === "pending" ||
      statusSocket === "delivery_created" ||
      statusSocket === "driver_assigned" ||
      statusSocket === "driver_not_assigned") {
      return created;
    } else if (statusSocket === "canceled" ||
      statusSocket === "provider_canceled" ||
      statusSocket === "customer_canceled" ||
      statusSocket === "burq_canceled" ||
      statusSocket === "expired" ||
      statusSocket === "failed" ||
      statusSocket === "disputed") {
      return cancel;
    } else if (statusSocket === "delivered") {
      return delivered
    } else if (statusSocket === "pickup" ||
      statusSocket === "enroute_pickup" ||
      statusSocket === "arrived_at_pickup" ||
      statusSocket === "pickup_complete") {
      return pickupPng
    } else if (statusSocket === "dropoff" ||
      statusSocket === "enroute_dropoff" ||
      statusSocket === "arrived_at_dropoff" ||
      statusSocket === "dropoff_complete") {
      return dropoff
    } else if (statusSocket === "Paid order" || statusSocket === "returned") {
      return returnPng
    } else if (statusSocket === "contacting_provider") {
      return contactingProvider
    } else return scheduled
  }
  return (
    <div>
      <img src={getIconImage()} alt="logo" className={isdesktop ? styles.statusLogos : styles.mobileStatusLogos} />
    </div>
  );
};

export default DeliveryListIcon;
