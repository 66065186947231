import React from "react";
import styles from "../styles/providerDetails/providerDetails.module.css";
import VehicleCard from "./ProviderVehicles";
import TypeOfDeliveryCard from "./ProviderDeliveryTypes";
import CategoriesList from "./ProviderIndustries";

const ProviderDetails = ({ data }) => {
  return (
    <div>
      <div>
        <h1 className={styles.sectionHeading}>Vehicles Offered</h1>
        <div>
          {data?.vehicles?.length ? (
            <div className={styles.cardsContainer}>
              {data.vehicles.map((vehicle) => (
                <VehicleCard vehicle={vehicle} />
              ))}
            </div>
          ) : (
            <div className={styles.noVehicles}>No vehicles defined</div>
          )}
        </div>
      </div>
      <div>
        <h1 className={styles.sectionHeading}>Type of Deliveries</h1>
        <div>
          {data?.supportOnDemand || data?.supportScheduled ? (
            <div className={styles.cardsContainer}>
              {data?.supportOnDemand && <TypeOfDeliveryCard />}
              {data?.supportScheduled && (
                <TypeOfDeliveryCard type="scheduled" />
              )}
            </div>
          ) : (
            <div className={styles.noVehicles}>No delivery types defined</div>
          )}
        </div>
      </div>
      <div>
        <h1 className={styles.sectionHeading}>Categories</h1>
        <div>
          {data?.industry_names?.length ? (
            <CategoriesList categories={data?.industry_names} />
          ) : (
            <div className={styles.noVehicles}>No catagories defined</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProviderDetails;
