import React from "react";
import { Loading } from "../../../assets/svgs/Svgs";
import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";
import {
  useConnectedAccounts,
  useStoreAndChild,
  useTheme,
} from "../../../store";
import StoreItem from "./StoreItem";
import Pagination from "../../shared/Pagination";

const StoresList = ({ storeListHandler, dependencies }) => {
  const { Colors } = useTheme();
  const { lgfontsize } = useDesktopOrLaptop();
  const { setSelectedConnectedAccount } = useConnectedAccounts();

  const {
    allStoresByUser,
    isAllStoresByUserLoading,
    setIsCreateStoreModalOpen,
  } = useStoreAndChild();

  return (
    <div className="h-full">
      <div
        style={{ height: `calc(100% - 100px)` }}
        className="w-full overflow-y-scroll"
      >
        {isAllStoresByUserLoading && (
          <div
            style={{ minHeight: "80px" }}
            className="w-full  flex items-center justify-center kata"
          >
            <Loading
              color={Colors.bluePrimary}
              className="h-10 w-10 animate-spin"
            />
          </div>
        )}
        {allStoresByUser?.data?.length < 1 && (
          <div
            style={{ fontSize: lgfontsize }}
            className="w-full text-center px-56 mt-16"
          >
            Stores allow you to create reusable pickup locations and use
            different payment methods for deliveries.{" "}
            <span
              onClick={() => {
                setIsCreateStoreModalOpen(true);
                setSelectedConnectedAccount();
              }}
              style={{
                color: Colors.bluePrimary,
                margin: "0px 5px",
                cursor: "pointer",
              }}
            >
              Create your first store{" "}
            </span>
            to get started.
          </div>
        )}
        {allStoresByUser?.data?.map((item) => {
          return (
            <StoreItem
              key={item.id}
              address={item.address}
              phoneNumber={item.phone_number}
              title={item.name}
              store={item}
              paymentMethod={item.payment_method ?? null}
            />
          );
        })}
      </div>

      <div
        className="flex w-full items-center justify-end pr-20 mt-6"
        style={{ display: !allStoresByUser?.data?.length ? "none" : "flex" }}
      >
        <Pagination
          dependencies={dependencies}
          data={allStoresByUser}
          onGetPaginationData={storeListHandler}
        />
      </div>
    </div>
  );
};

export default StoresList;
