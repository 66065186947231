import React from "react";
import {
  useClickStore,
  useIncidents,
  useSingleItemStore,
  useTheme,
} from "../../../../../store";
import { useDesktopOrLaptop } from "../../../../../services/useDesktopOrLaptop";
import { CancelSvg, InfoSvg } from "../../../../../assets/svgs/Svgs";
import { useNavigate } from "react-router-dom";
import { TbRoute } from "react-icons/tb";
import OutsideClickHandler from "react-outside-click-handler";

const DotsMenu = ({
  setDotsMenuVisible,
  setDetailsVisible,
  item,
  setCancelVisible,
  setMarkNotReroutableVisible,
}) => {
  const { setAllTimeVisible } = useClickStore();
  const { Colors, themeChanger } = useTheme();
  const { setRerouteItem, setIsAdminReroute, setIsMerchantReroute } = useSingleItemStore();

  const { mdfontsize, isdesktop } = useDesktopOrLaptop();
  const {
    setIsCreateEditIncidentModalOpen,
    setSelectedDeliveryForIncident,
    updatingIncidentIndex,
    setUpdatingIncidentIndex,
    setSelectedIncident,
  } = useIncidents();

  const history = useNavigate();

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setDotsMenuVisible(false);
      }}
    >
      <div
        style={{
          minWidth: "200px",
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          right: "0px",
          outline: "none",
          backgroundColor: Colors.white,
          zIndex: 20,
        }}
        className={`  shadow-md mt-2 rounded-md `}
      >
        <button
          style={{
            fontSize: mdfontsize,
            fontWeight: "500",
            color: Colors.fontDark,
            padding: isdesktop ? "14px 20px" : "18px 24px",
            borderBottomWidth: "1px",
            borderColor: Colors.borderPrimary,
          }}
          onClick={() => {
            setDetailsVisible(true);
            setDotsMenuVisible(false);
            setAllTimeVisible(false);
          }}
          className={`focus:outline-none px-4 py-2.5 ${
            themeChanger ? "hover:bg-gray-200 " : ""
          } flex items-center`}
        >
          <span
            style={{
              marginRight: "8px",
            }}
          >
            <InfoSvg color={Colors.fontDark} />
          </span>
          Details
        </button>
        {!(item.reroute_status === "rerouted" || item.reroute_status === "not_reroutable") && <button
          style={{
            fontSize: mdfontsize,
            fontWeight: "500",
            color: Colors.fontDark,
            padding: isdesktop ? "14px 20px" : "18px 24px",
          }}
          onClick={() => {
            setMarkNotReroutableVisible(true);
            setDotsMenuVisible(false);
            setAllTimeVisible(false);
          }}
          className={`focus:outline-none px-4 py-2.5 ${themeChanger ? "hover:bg-gray-200 " : ""
            } flex items-center`}
        >
          <span
            style={{
              marginRight: "8px",
            }}
          >
            <InfoSvg color={Colors.fontDark} />
          </span>
          Mark Not Rerouteable
        </button>}
        {!item.delivery_incident && (
          <button
            style={{
              fontSize: mdfontsize,
              fontWeight: "500",
              color: Colors.fontDark,
              padding: isdesktop ? "14px 20px" : "18px 24px",
              borderBottomWidth: "1px",
              borderColor: Colors.borderPrimary,
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
            onClick={() => {
              if (item.delivery_incident) {
                setSelectedIncident(item.delivery_inciden);
              }
              setIsCreateEditIncidentModalOpen(true);

              setDotsMenuVisible(false);
              setAllTimeVisible(false);
              setSelectedDeliveryForIncident(item);
            }}
            className={`focus:outline-none px-4 py-2.5 ${
              themeChanger ? "hover:bg-gray-200 " : ""
            }`}
          >
            <span
              style={{
                marginRight: "8px",
              }}
            >
              <InfoSvg color={Colors.fontDark} />
            </span>
            <span style={{ textAlign: "left" }}>
              Create Delivery Incident
              {/* {item.delivery_incident
                ? "Update Delivery Incident"
                : "Create Delivery Incident"} */}
            </span>
          </button>
        )}
        <button
          style={{
            fontSize: mdfontsize,
            fontWeight: "500",
            color: Colors.fontDark,
            padding: isdesktop ? "14px 20px" : "18px 24px",
          }}
          onClick={() => {
            setRerouteItem(item);
            setIsAdminReroute(true);
            setDotsMenuVisible(false);
            history(`/reroute/${item?.account_id}`);
            setAllTimeVisible(false);
            setIsMerchantReroute(false);
          }}
          className={`focus:outline-none px-4 py-2.5 ${
            themeChanger ? "hover:bg-gray-200 " : ""
          } flex items-center`}
        >
          <span
            style={{
              marginRight: "8px",
            }}
          >
            <TbRoute color={Colors.fontDark} />
          </span>
          Reroute
        </button>
        <button
          style={{
            fontSize: mdfontsize,
            fontWeight: "500",
            color: Colors.fontDark,
            padding: isdesktop ? "14px 20px" : "18px 24px",
            whiteSpace: "nowrap",
          }}
          onClick={() => {
            setRerouteItem(item);
            setIsAdminReroute(true);
            setDotsMenuVisible(false);
            history(`/rerouteEdit/${item?.account_id}`);
            setAllTimeVisible(false);
          }}
          className={`focus:outline-none px-4 py-2.5 ${
            themeChanger ? "hover:bg-gray-200 " : ""
          } flex items-center`}
        >
          <span
            style={{
              marginRight: "8px",
            }}
          >
            <TbRoute color={Colors.fontDark} />
          </span>
          Create Duplicate Delivery
        </button>
        <button
          style={{
            fontSize: mdfontsize,
            fontWeight: "500",
            color: Colors.fontDark,
            padding: isdesktop ? "14px 20px" : "18px 24px",
          }}
          onClick={() => {
            setCancelVisible(true);
            setDotsMenuVisible(false);
            setAllTimeVisible(false);
          }}
          className={`focus:outline-none px-4 py-2.5 ${
            themeChanger ? "hover:bg-gray-200 " : ""
          } flex items-center`}
        >
          <span
            style={{
              marginRight: "8px",
            }}
          >
            <CancelSvg color={Colors.fontDark} />
          </span>
          Cancel
        </button>
      </div>
    </OutsideClickHandler>
  );
};

export default DotsMenu;
