import React from "react";
import { compose, withProps } from "recompose";
import {
  withGoogleMap,
  GoogleMap,
  DirectionsRenderer,
  withScriptjs,
} from "react-google-maps";
import MapMarker from "./MapMarker";
import { mapStyles } from "../utils";

import marker from "../../../assets/png/pickup.png";
import dropoff from "../../../assets/png/dropoff.png";
import newCar from "../../../assets/png/carTrack.png";
import newVan from "../../../assets/png/vanTrack.png";
import newTruck from "../../../assets/png/truckTrack.png";

const Map = ({
  pickupCoordinates,
  dropoffCoordsArr,
  routesForMap,
  driverInfo,
  showDriverMarker = false,
}) => {
  const showDriver =
    driverInfo?.last_latitude && driverInfo?.last_longitude && showDriverMarker;

  const getOverlayViewMarkerSrc = () => {
    switch (driverInfo?.driver?.vehicle) {
      case "truck":
        return newTruck;
      case "van":
        return newVan;
      default:
        return newCar;
    }
  };

  if (pickupCoordinates?.lat) {
    return (
      <GoogleMap
        defaultOptions={{
          styles: mapStyles,
          clickableIcons: false,
          fullscreenControl: false,
          disableDefaultUI: true,
          mapTypeControl: false,
          gestureHandling: "greedy",
          scrollwheel: true,
          zoomControlOptions: true,
        }}
        center={{
          lat: driverInfo?.last_latitude
            ? parseFloat(driverInfo?.last_latitude)
            : pickupCoordinates?.lat,
          lng: driverInfo?.last_longitude
            ? parseFloat(driverInfo?.last_longitude)
            : pickupCoordinates?.lng,
        }}
        zoom={14}
        defaultZoom={14}
      >
        <MapMarker
          key="pickup-cordinates"
          icon={marker}
          lat={pickupCoordinates?.lat}
          lng={pickupCoordinates?.lng}
        />
        {showDriver && (
          <MapMarker
            key="driver-cordinates"
            icon={getOverlayViewMarkerSrc()}
            lat={parseFloat(driverInfo?.last_latitude)}
            lng={parseFloat(driverInfo?.last_longitude)}
          />
        )}
        {dropoffCoordsArr.length > 0 &&
          dropoffCoordsArr.map((item, index) => (
            <MapMarker
              key={`dropoffcord-${index}`}
              icon={dropoff}
              lat={item?.lat}
              lng={item?.lng}
            />
          ))}
        {routesForMap.length > 0 &&
          routesForMap.map((item, index) => (
            <DirectionsRenderer
              key={`dr-${index}`}
              options={{
                suppressMarkers: true,
                polylineOptions: { strokeColor: "#1460EF" },
              }}
              directions={item}
            />
          ))}
      </GoogleMap>
    );
  } else {
    return null;
  }
};

const mapProps = {
  googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_KEY}&v=3.exp&libraries=geometry,drawing,places`,
  loadingElement: <div style={{ height: `100%` }} />,
  mapElement: <div style={{ height: `100%` }} />,
};

export default compose(withProps(mapProps), withScriptjs, withGoogleMap)(Map);
