import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useFormikContext } from "formik";
import { getFieldValueIcon } from "../utils";
import SelectField from "../../../../shared/atoms/SelectField";
import InputErrorComp from "./InputErrorComp";
import InputField from "../../../../shared/atoms/InputField";
import styles from "../../styles/PricingRules/CreateRule/expressionForm.module.css";

const ExpressionForm = ({
  ruleExpressionFieldsList,
  operationsList,
  equalityOperatorsList,
  removeHandler,
  index,
}) => {
  const { values, errors, touched, handleChange, setFieldValue } =
    useFormikContext();

  const customstyles = {
    selectControl: {
      width: "100%",
      borderWidth: "1px",
      border: `solid #E5E7EB`,
      borderRadius: "10px",
      boxShadow: "none",
    },
    selectInput: {
      fontFamily: "Poppins",
      fontSize: '14px',
      border: "0px",
      borderRadius: "8px",
      outlineWidth: "0px",
      fontWeight: "500",
      margin: "5px",
    },
  };

  const selectStyles = (isErrors = false) => {
    return {
      control: (styles) => ({
        ...styles,
        ...customstyles.selectControl,
        ...{
          border: isErrors
            ? `solid #FF3F3F`
            : `solid #E5E7EB`,
        },
        ":hover": {
          ...styles[":hover"],
          border: isErrors
            ? `solid #FF3F3F`
            : `solid #E5E7EB`,
        },
      }),
      input: (styles) => ({
        ...styles,
        ...customstyles.selectInput,
      }),
      options: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ":active": {
            ...styles[":active"],
            backgroundColor: !isDisabled
              ? isSelected
                ? '#0062FF'
                : '#CDCDCD'
              : undefined,
          },
          borderRadius: "10px",
        };
      },
    };
  };

  return (
    <div className={styles.expressionContainer}>
      <div className={styles.expressionLeftContainer}>
        <div className={styles.expressionField}>
          <SelectField
            selectedValue={ruleExpressionFieldsList.find(
              (item) =>
                item.value ===
                values?.condition?.[index]?.value?.left_side_value?.variable
            )}
            onSelect={(payload) =>
              setFieldValue(
                `condition.${index}.value.left_side_value.variable`,
                payload?.value || null
              )
            }
            optionsList={ruleExpressionFieldsList}
            styleProps={selectStyles(
              touched?.condition?.[index]?.value?.left_side_value?.variable &&
                errors?.condition?.[index]?.value?.left_side_value?.variable
            )}
            useExternalState={true}
          />
          {touched?.condition?.[index]?.value?.left_side_value?.variable && (
            <InputErrorComp
              error={
                errors?.condition?.[index]?.value?.left_side_value?.variable
              }
            />
          )}
        </div>
        <div className={styles.expressionField}>
          <SelectField
            selectedValue={operationsList.find(
              (item) =>
                item.value ===
                values?.condition?.[index]?.value?.left_side_value?.operation
            )}
            onSelect={(payload) =>
              setFieldValue(
                `condition.${index}.value.left_side_value.operation`,
                payload?.value || null
              )
            }
            optionsList={operationsList}
            styleProps={selectStyles(
              touched?.condition?.[index]?.value?.left_side_value?.operation &&
                errors?.condition?.[index]?.value?.left_side_value?.operation
            )}
            isDisabled={
              !values?.condition?.[index]?.value?.left_side_value?.variable
            }
            resetSelection={
              !values?.condition?.[index]?.value?.left_side_value?.variable
            }
            useExternalState={true}
          />
          {touched?.condition?.[index]?.value?.left_side_value?.operation && (
            <InputErrorComp
              error={
                errors?.condition?.[index]?.value?.left_side_value?.operation
              }
            />
          )}
        </div>
        <InputField
          name={`condition.${index}.value.left_side_value.field_value`}
          onChange={handleChange}
          value={
            values.condition?.[index]?.value?.left_side_value?.field_value || ""
          }
          disabled={
            !values.condition?.[index]?.value?.left_side_value?.variable
          }
          borderStyles={
            errors?.condition?.[index]?.value?.left_side_value?.field_value &&
            touched?.condition?.[index]?.value?.left_side_value?.field_value
              ? { borderColor: '#FF3F3F' }
              : {}
          }
          leftIcon={getFieldValueIcon(
            ruleExpressionFieldsList,
            values?.condition?.[index]?.value?.left_side_value?.variable
          )}
        />
        {touched?.condition?.[index]?.value?.left_side_value?.field_value && (
          <InputErrorComp
            error={
              errors?.condition?.[index]?.value?.left_side_value?.field_value
            }
          />
        )}
      </div>
      <div className={styles.expressionOperatorContainer}>
        <SelectField
          selectedValue={equalityOperatorsList.find(
            (item) =>
              item.value ===
              values?.condition?.[index]?.value?.equality_operator
          )}
          onSelect={(payload) =>
            setFieldValue(
              `condition.${index}.value.equality_operator`,
              payload?.value || null
            )
          }
          optionsList={equalityOperatorsList}
          styleProps={selectStyles(
            touched?.condition?.[index]?.value?.equality_operator &&
              errors?.condition?.[index]?.value?.equality_operator
          )}
          useExternalState={true}
        />
        {touched?.condition?.[index]?.value?.equality_operator && (
          <InputErrorComp
            error={errors?.condition?.[index]?.value?.equality_operator}
          />
        )}
      </div>
      <div className={styles.expressionRightContainer}>
        <div className={styles.expressionField}>
          <SelectField
            selectedValue={ruleExpressionFieldsList.find(
              (item) =>
                item.value ===
                values?.condition?.[index]?.value?.right_side_value?.variable
            )}
            onSelect={(payload) =>
              setFieldValue(
                `condition.${index}.value.right_side_value.variable`,
                payload?.value || null
              )
            }
            optionsList={ruleExpressionFieldsList}
            styleProps={selectStyles(
              touched?.condition?.[index]?.value?.right_side_value?.variable &&
                errors?.condition?.[index]?.value?.right_side_value?.variable
            )}
            useExternalState={true}
          />
          {touched?.condition?.[index]?.value?.right_side_value?.variable && (
            <InputErrorComp
              error={
                errors?.condition?.[index]?.value?.right_side_value?.variable
              }
            />
          )}
        </div>
        <div className={styles.expressionField}>
          <SelectField
            selectedValue={operationsList.find(
              (item) =>
                item.value ===
                values?.condition?.[index]?.value?.right_side_value?.operation
            )}
            onSelect={(payload) =>
              setFieldValue(
                `condition.${index}.value.right_side_value.operation`,
                payload?.value || null
              )
            }
            optionsList={operationsList}
            styleProps={selectStyles(
              touched?.condition?.[index]?.value?.right_side_value?.operation &&
                errors?.condition?.[index]?.value?.right_side_value?.operation
            )}
            isDisabled={
              !values?.condition?.[index]?.value?.right_side_value?.variable
            }
            resetSelection={
              !values?.condition?.[index]?.value?.right_side_value?.variable
            }
            useExternalState={true}
          />
          {touched?.condition?.[index]?.value?.right_side_value?.operation && (
            <InputErrorComp
              error={
                errors?.condition?.[index]?.value?.right_side_value?.operation
              }
            />
          )}
        </div>
        <InputField
          name={`condition.${index}.value.right_side_value.field_value`}
          onChange={handleChange}
          value={
            values?.condition?.[index]?.value?.right_side_value?.field_value ||
            ""
          }
          borderStyles={
            errors?.condition?.[index]?.value?.right_side_value?.field_value &&
            touched?.condition?.[index]?.value?.right_side_value?.field_value
              ? { borderColor: '#FF3F3F' }
              : {}
          }
          leftIcon={getFieldValueIcon(
            ruleExpressionFieldsList,
            values?.condition?.[index]?.value?.left_side_value?.variable
          )}
        />
        {touched?.condition?.[index]?.value?.right_side_value?.field_value && (
          <InputErrorComp
            error={
              errors?.condition?.[index]?.value?.right_side_value?.field_value
            }
          />
        )}
      </div>
      <div style={{ marginLeft: "10px" }}>
        <AiOutlineClose onClick={removeHandler} />
      </div>
    </div>
  );
};

export default ExpressionForm;
