import { useField } from "formik";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";

// import Colors from "../../assets/Colors";
import { useTheme } from "../../store";

function FormikNewTextInput(props) {
  const { Colors, themeChanger } = useTheme();
  // const { setFieldValue, values, errors } = useFormikContext();

  const [field, meta] = useField(props.name);

  const { smfontsize } = useDesktopOrLaptop();

  const isdesktop = useMediaQuery({
    query: "(min-device-width: 1060px)",
  });

  return (
    <div
      style={{
        marginBottom: props.marginbottom,
        display: "flex",
        alignItems: "center",
        width: "100%",
      }}
    >
      <div
        style={{
          width: "100%",
          height: isdesktop ? "46px" : "70px",
        }}
      >
        {props.label && (
          <label
            style={{
              color: Colors.fontDark,
              marginLeft: "8px",
              marginBottom: "8px",
            }}
            htmlFor={props.name}
          >
            {props.label}
          </label>
        )}

        <input
          // onKeyPress={(event) => {
          //   if (/(\.|\+|=|_|,(?=[\s\n\r]|$))/.test(event.key)) {
          //     event.preventDefault();
          //   }
          // }}
          className={`${
            themeChanger ? "focus:border-gray-400" : "focus:border-gray-300"
          }  border border-transparent`}
          style={{
            borderRadius: "8px",
            fontSize: smfontsize,
            fontWeight: "400",
            color: Colors.fontDark,
            padding: "10px 20px",
            outline: "none",
            width: "100%",
            fontFamily: "Poppins",
            backgroundColor: Colors.borderPrimary,
            ...props.externalInputStyle,
          }}
          {...field}
          {...props}
        />

        {props.isClickedOnContinue && props.emptyErr ? (
          <p
            style={{
              color: Colors.redDark,
              fontFamily: "Poppins",
              fontSize: "12px",
              padding: "6px 0px 3px 12px",
              lineHeight: "12px",
            }}
          >
            {props.emptyErr}
          </p>
        ) : meta.error && meta.touched ? (
          <p
            style={{
              color: Colors.redDark,
              fontFamily: "Poppins",
              fontSize: "11px",
              padding: "6px 0px 3px 12px",
              lineHeight: "12px",
            }}
          >
            {meta.error}
          </p>
        ) : props.isClickedOnContinue && meta.error ? (
          <p
            style={{
              color: Colors.redDark,
              fontFamily: "Poppins",
              fontSize: "12px",
              padding: "6px 0px 3px 12px",
              lineHeight: "12px",
            }}
          >
            {meta.error}
          </p>
        ) : null}
      </div>
    </div>
  );
}

export default FormikNewTextInput;
