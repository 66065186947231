import axios from "axios";
import apiUrls from "../../constants/apiUrls";
import { generateQueryParams } from "./utils";

export const getBrandingSetting = (id) =>
  axios.get(apiUrls.GET_BRANDING_SETTINGS(id), {
    headers: { "x-burq-client": "client-dashboard" },
  });

export const postBrandingSetting = (id, payload) =>
  axios.post(apiUrls.POST_BRANDING_SETTINGS(id), payload, {
    headers: { "x-burq-client": "client-dashboard" },
  });

export const putBrandingSetting = (id, payload) =>
  axios.put(apiUrls.PUT_BRANDING_SETTINGS(id), payload, {
    headers: { "x-burq-client": "client-dashboard" },
  });

export const postBrandingUploadFile = (id, params, payload) => {
  const urlWithQueryParams = generateQueryParams(
    apiUrls.POST_UPLOAD_BRANDING_FILE(id),
    params
  );
  return axios.post(urlWithQueryParams, payload, {
    headers: { "x-burq-client": "client-dashboard" },
  });
};
export const deleteBranding = (id, params) => {
  const urlWithQueryParams = generateQueryParams(
    apiUrls.DELETE_BRANDING_IMAGE(id),
    params
  );
  return axios.delete(urlWithQueryParams, {
    headers: { "x-burq-client": "client-dashboard" },
  });
};
