import React from "react";
import moment from "moment";
import StatusBadgeReusable from "../../../../shared/common/StatusBadgeReusable";
import { badgesForApiReuqestStatus } from "../../../utils";
import classNames from "classnames";
import styles from "../../../styles/DeliveryFailureLogs/activityCard.module.css";

const ActivityCard = ({ data, selectedRequest, onClickHandler, index }) => {
  console.log(
    "123123",
    data.response_payload?.status,
    data.response_payload?.code
  );
  return (
    <div className={styles.container}>
      <div className={styles.circle} />
      {!!index && <div className={styles.line} />}
      <div
        onClick={() => onClickHandler(data)}
        className={classNames(styles.activityCard, {
          [styles.selected]: selectedRequest?.id === data?.id,
        })}
      >
        <div className={styles.activityHeader}>
          {data.response_payload?.status && data.response_payload?.code && (
            <span className={styles.statusBadge}>
              <StatusBadgeReusable
                badgesObject={badgesForApiReuqestStatus}
                lookupKey={data.response_payload.status ?? null}
                status={
                  data.response_payload?.status +
                  " " +
                  data.response_payload?.code
                }
              />
            </span>
          )}
          <span>{data.api_url}</span>
        </div>
        <div className={styles.requestDate}>
          {data.created_at &&
            moment(data.created_at).format("MMM D, YYYY, h:mma")}
        </div>
      </div>
    </div>
  );
};

export default ActivityCard;
