import React from 'react';
import { useTheme } from '../../../../../store';

const NoDataState = () => {
    const { Colors } = useTheme();
    return (
        <div
            style={{ color: Colors.fontDark }}
            className="w-full h-full flex justify-center items-center"
          >
            No transactions found
          </div>
    );
};

export default NoDataState;
