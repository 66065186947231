import React, { useState } from "react";
import ButtonComp from "../components/ButtonComp/ButtonComp";
import { useDesktopOrLaptop } from "../services/useDesktopOrLaptop";
import { useTheme, useStoreAndChild, useClickStore } from "../store";

const ROLES = [
  {
    name: "Member",
    desc: "will have access to all stores",
  },
];

const InviteMember = ({}) => {
  const { Colors } = useTheme();
  const { setAllTimeVisible } = useClickStore();
  const {
    setIsInviteMemberVisible,
    postAddUserToStore,
    selectedStore,
    getStoreById,
  } = useStoreAndChild();
  const { isdesktop, mdfontsize, smfontsize } = useDesktopOrLaptop();
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmited, setIsSubmited] = useState(false);
  const [email, setEmail] = useState("");
  const [selectedRole, setselectedRole] = useState("Member");

  const xlFontSize = isdesktop ? "20px" : "32px";
  const lgFontSize = isdesktop ? "18px" : "29px";

  return (
    <div className="overflow-y-auto py-10 px-12">
      <div
        style={{
          borderColor: Colors.borderPrimary,
          marginBottom: "20px",
        }}
        className="w-full flex items-center justify-between  pb-1 "
      >
        <div
          style={{
            fontFamily: "Poppins",
            fontSize: xlFontSize,
            color: Colors?.fontDark,
            fontWeight: "500",
            width: "100%",
          }}
        >
          Invite member
        </div>
        <button
          onClick={() => {
            setIsInviteMemberVisible(false);
            setAllTimeVisible(true);
          }}
          type="button"
          style={{
            color: Colors?.fontDark,
            fontSize: isdesktop ? "24px" : "40px",
          }}
          className="material-icons outline-none"
        >
          close
        </button>
      </div>

      <div className=" w-full">
        <div
          style={{
            color: Colors.fontGreyLight,
            fontSize: mdfontsize,
            marginBottom: "6px",
            paddingLeft: "1px",
          }}
        >
          Email
        </div>
        <input
          onChange={(e) => {
            const regex =
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (isSubmited) {
              setIsSubmited(false);
            }
            if (!e.target.value) {
              setErrorMessage("Cannot be blank");
            } else if (!regex.test(e.target.value)) {
              setErrorMessage("Invalid email format");
            } else {
              setErrorMessage("");
            }
            setEmail(e.target.value);
          }}
          style={{
            borderWidth: "1px",
            borderRadius: "8px",
            borderColor:
              errorMessage && isSubmited
                ? Colors.redDark
                : Colors.borderPrimary,
            fontSize: "14px",
            fontWeight: "400",
            color: Colors.fontDark,
            backgroundColor: Colors.white,
            padding: "8px 12px",
            outline: "none",
            width: "80%",
            fontFamily: "Poppins",
          }}
          type="email"
        />
      </div>

      <div
        style={{
          height: "24px",
          color: Colors.redDark,
          fontSize: smfontsize,
          marginTop: "6px",
        }}
      >
        {errorMessage && isSubmited ? errorMessage : ""}
      </div>

      {/* <div style={{ color: Colors.fontGreyLight, fontSize: mdfontsize }}>
        Roles
      </div>
      {ROLES.map((item) => {
        return (
          <button
            type="button"
            onClick={() => setselectedRole(item.name)}
            style={{ fontFamily: "Poppins" }}
            key={item.name}
            className="flex items-start w-full mt-5"
          >
            <div
              style={{
                backgroundColor: Colors.bluePrimary,
                height: "21px",
                width: "21px",
                marginTop: "4px",
                borderRadius: "12px",
              }}
              className=" flex items-center justify-center mr-7"
            >
              <div
                style={{
                  height: "18px",
                  width: "18px",
                  backgroundColor:
                    selectedRole === item.name
                      ? Colors.bluePrimary
                      : Colors.white,
                  borderWidth: "4px",
                  borderColor: Colors.white,
                }}
                className="rounded-full"
              ></div>
            </div>
            <div className="flex flex-col text-left">
              <div style={{ color: Colors.fontDark, fontSize: lgFontSize }}>
                {item.name}
              </div>
              <div
                style={{ color: Colors.fontGreyLight, fontSize: mdfontsize }}
              >
                {item.desc}
              </div>
            </div>
          </button>
        );
      })} */}

      <div className="w-full flex justify-end">
        <ButtonComp
          primary
          title={"Submit"}
          type="button"
          onClick={async () => {
            if (!email) {
              setIsSubmited(true);
              setErrorMessage("Cannot be blank");
              return;
            } else if (errorMessage) {
              setIsSubmited(true);
              return;
            }

            const res = await postAddUserToStore(
              selectedStore.id,
              email,
              selectedRole
            );
            await getStoreById(selectedStore.id);
            setAllTimeVisible(true);
            setIsInviteMemberVisible(false);

            setIsSubmited(true);
          }}
        />
      </div>
    </div>
  );
};

export default InviteMember;
