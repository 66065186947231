import React, { useEffect, useState } from "react";
import HomeFilters from "../filters";
import { useDisputeDeliveries, useMyStore, userDataStore } from "../../../store";
import ListLoading from "../ListLoading";
import ListEmptyState from "../ListEmptyState";
import LitsItem from "../ListItem";
import classNames from "classnames";
import styles from "../styles/home.module.css";
import ListHeader from "../ListHeader";
import { disputedColumns, disputedStatuses } from "../utils";

import EasyPagination from "../../shared/Pagination/EasyPagination";

const DisputedDeliveries = (props) => {
  const [columnData, setColumnData] = useState(disputedColumns);

  const { authInfo } = userDataStore();
  const {
    disputedFiltersDefinitions,
    setDisputedStatusesFilter,
    disputedStatusesFilter,
    disputedListHandler,
    disputedDeliveriesData,
    setDisputedDeliveriesData,
    isDisputedListLoading,
    storesFilteredArray,
    setStoresFilteredArray,
    setDateRangeForDisputed,
    setSearchByDelId,
    setSearchByDropoffName,
    setSearchByDropoffNumber,
  } = useDisputeDeliveries();
  const { isSandbox, userId } = useMyStore();

  useEffect(() => {
    setDisputedDeliveriesData();
    if (authInfo?.merchantAccountId) {
      const userMerchantId = userId ?? authInfo.merchantAccountId;
      disputedListHandler(userMerchantId, { test_mode: isSandbox });
    }
  }, [authInfo, isSandbox]);

  useEffect(() => {
    return () => {
      setSearchByDelId();
      setSearchByDropoffName();
      setSearchByDropoffNumber();
      setDateRangeForDisputed();
      setStoresFilteredArray();
      setDisputedStatusesFilter(disputedStatuses);
    };
  }, []);

  const renderTableBody = () => {
    if (isDisputedListLoading) {
      return <ListLoading />;
    } else if (!disputedDeliveriesData?.data?.length) {
      return <ListEmptyState />;
    } else return disputedDeliveriesData?.data.map((item) => <LitsItem columns={columnData} data={item} />);
  };

  const onGetPaginationData = async (paginationParams) => {
    const userMerchantId = userId ?? authInfo?.merchantAccountId;

    const retrievedData = await disputedListHandler(userMerchantId, {
      ending_before: paginationParams?.ending_before,
      starting_after: paginationParams?.starting_after,
      test_mode: isSandbox,
    });
    if (retrievedData === "disablePrevious") {
      return true;
    }
    return false;
  };
  const storesFilterHandler = async (storeIds) => {
    const userMerchantId = userId ?? authInfo?.merchantAccountId;

    await disputedListHandler(userMerchantId, {
      store_ids: storeIds,
      test_mode: isSandbox,
    });
  };
  const statusesFilterHandler = async (statuses) => {
    const statusesForApi = statuses.filter((item) => item.checked);
    const userMerchantId = userId ?? authInfo?.merchantAccountId;

    await disputedListHandler(userMerchantId, {
      status: statusesForApi,
      test_mode: isSandbox,
    });
  };

  const dateRangeHandler = async (dateRange) => {
    setDateRangeForDisputed(dateRange ?? undefined);

    const userMerchantId = userId ?? authInfo?.merchantAccountId;

    await disputedListHandler(userMerchantId, {
      start_date: dateRange?.start ?? "",
      end_date: dateRange?.end ?? "",
      test_mode: isSandbox,
    });
  };

  return (
    <>
      <HomeFilters
        setStatusesFilter={setDisputedStatusesFilter}
        statusesFilter={disputedStatusesFilter}
        searchFilterDefinitions={disputedFiltersDefinitions}
        columnData={columnData}
        setColumnData={setColumnData}
        storesFilteredArray={storesFilteredArray}
        setStoresFilteredArray={setStoresFilteredArray}
        storesFilterHandler={storesFilterHandler}
        statusesFilterHandler={statusesFilterHandler}
        dateRangeHandler={dateRangeHandler}
        listData={disputedDeliveriesData}
      />
      <div className={classNames(styles.tableContainer, "scrollbar")}>
        <table className={styles.table}>
          <thead className={styles.tableHeader}>
            <ListHeader columns={columnData} />
          </thead>

          <tbody className={classNames(styles.tableBody, "kata")}>{renderTableBody()}</tbody>
        </table>
        {/* <div className="relative">
          <div className="">
            <EasyPagination data={disputedDeliveriesData} onGetPaginationData={onGetPaginationData} />
          </div>
        </div> */}
      </div>
      <div className={classNames(styles.paginationContainer, "flex items-center justify-start w-full")}>
        <EasyPagination data={disputedDeliveriesData} onGetPaginationData={onGetPaginationData} isHome />
      </div>
    </>
  );
};

export default DisputedDeliveries;
