import React, { useEffect, useState } from "react";
import {
  useMyStore,
  useProvidersManagementStore,
  userDataStore,
} from "../../../../store";
import { Formik } from "formik";
import InputErrorComp from "../../../shared/common/InputErrorComp";
import ButtonComp from "../../../ButtonComp/ButtonComp";
import { defaultValidationSchema } from "./validationSchema";
import Loader from "../../../shared/atoms/Loader";
import styles from "../styles/requestAccess.module.css";
import classNames from "classnames";

const RequestAccessTab = () => {
  const { authInfo } = userDataStore();
  const { selectedProvider } = useProvidersManagementStore();
  const [payload, setPayload] = useState({});

  const {
    questions,
    getProviderAccessRequestQuestionnaireHandler,
    isPARLoading,
    createProviderAccessRequestWorker,
  } = useProvidersManagementStore();
  const { isSandbox } = useMyStore();

  useEffect(() => {
    if (!questions?.length) {
      getProviderAccessRequestQuestionnaireHandler();
    } else {
      const initial_payload = {};
      for (const question of questions) {
        initial_payload[question.id] = "";
      }
      setPayload(initial_payload);
    }
  }, [questions]);

  const requestAccessHandler = (values) => {
    const payload = {
      test_mode: isSandbox,
      form_response: values,
    };
    createProviderAccessRequestWorker(
      authInfo.merchantAccountId,
      selectedProvider.id,
      payload
    );
  };

  if (!questions?.length || isPARLoading) {
    return (
      <div className={styles.loaderContainer}>
        <Loader />
      </div>
    );
  }

  return (
    <div
      className={classNames("w-full flex flex-col relative", styles.container)}
    >
      <div className="flex w-full flex-col  ">
        <Formik
          initialValues={payload}
          onSubmit={requestAccessHandler}
          validationSchema={defaultValidationSchema}
          enableReinitialize
        >
          {({
            handleChange,
            handleSubmit,
            values,
            errors,
            setFieldTouched,
            touched,
          }) => (
            <>
              {questions?.map((question, index) => {
                return (
                  <div key={index}>
                    <div className={styles.inputLable}>
                      {index + 1}. {question.question}{" "}
                      <span className={styles.required}>Required*</span>
                    </div>
                    <div className={styles.inputForEdit}>
                      <textarea
                        onFocus={() => {
                          setFieldTouched(question.id);
                        }}
                        onChange={handleChange}
                        placeholder={`Type your answer here...`}
                        name={question.id}
                        value={values[question.id]}
                        type="text"
                        className={styles.input}
                      />
                    </div>
                    <InputErrorComp
                      error={
                        errors[question.id] && touched[question.id]
                          ? errors[question.id]
                          : undefined
                      }
                    />
                  </div>
                );
              })}
              <ButtonComp
                title={"Done"}
                primary
                onClick={handleSubmit}
                extraStyles={{ marginTop: "40px" }}
              />
            </>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default RequestAccessTab;
