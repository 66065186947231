import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AiFillBank } from "react-icons/ai";
import { useDesktopOrLaptop } from "../../../../../services/useDesktopOrLaptop";
import { useMyStore, useStatementsStore, useTheme } from "../../../../../store";
import { numberWithCommas } from "../../../../../utils/helpers";
import { cardLogoHandler } from "../../../../InsideOfStores/UtilsComponents";
import { Loading } from "../../../../../assets/svgs/Svgs";

import Pagination from "../../../../shared/Pagination";
import styles from "./styles.module.css";
import classNames from "classnames";
import RelatedStatementsNav from "./RelatedStatementsNav";
import DownloadStatementCSV from "../../DownloadStatementCSV";
import PaymentStatus from "../PaymentStatus";
import TransactionsItem from "./TransactionsItem";
import Button from "../../../../shared/atoms/Button";
import { paymentErrorCodes } from "../../../utlis";
import { useFlag } from "@unleash/proxy-client-react";
import Cookies from "js-cookie";
import { setCookie } from "../../../../../utils";

const PAGE_LIMIT = 20;

const StatementDetail = () => {
  const [selectedRelatedStatement, setSelectedRelatedStatement] = useState(null);
  const { isdesktop, smfontsize, mdfontsize } = useDesktopOrLaptop();
  const { isSandbox, setIsSandbox } = useMyStore();
  const { Colors } = useTheme();
  const {
    getTransactionsForStatement,
    isTransactionsForStatementLoading,
    selectedStatement,
    setSelectedStatement,
    getSingleDepositStatement,
    depositStatementsInitiatePaymentWorker,
  } = useStatementsStore();
  const [details, setDetails] = useState();

  const [activeId, setActiveId] = useState();

  const [isPayDisabled, setIsPayDisabled] = useState(false);

  const { id } = useParams();

  const navigate = useNavigate();

  const statementTransactionsHandler = async (params) => {
    const res = await getTransactionsForStatement(params.id, {
      ending_before: params?.ending_before || "",
      starting_after: params?.starting_after || "",
      limit: PAGE_LIMIT,
    });
    if (res) {
      setDetails(res);
    }
  };

  const submitPaymentHandler = async () => {
    if (!id) return;
    setIsPayDisabled(true);
    const cb = (err) => {
      if (err?.response?.data?.code && paymentErrorCodes.includes(err?.response?.data?.code)) {
        navigate("/v1/settings/payments");
      }
      setIsPayDisabled(true);
    };
    depositStatementsInitiatePaymentWorker(id, cb);
  };

  const onRelatedStatementChangeHandler = (statement) => {
    navigate(`/v1/settings/billing/statement/${statement.id}`);

    setSelectedRelatedStatement(statement);
    statementTransactionsHandler({ id: statement?.id });
  };

  useEffect(() => {
    if (id !== selectedStatement?.id) {
      getSingleDepositStatement(id).then((res) => {
        setSelectedStatement(res);
        setSelectedRelatedStatement(res);
        setIsSandbox(res?.test_mode);
        setCookie("testModeCookie", res?.test_mode ? "true" : "false");
      });
      statementTransactionsHandler({ id: id });
    } else {
      statementTransactionsHandler({ id: selectedStatement?.id });
      setSelectedRelatedStatement(selectedStatement);
    }
  }, [id]);

  const statement = !!selectedRelatedStatement ? selectedRelatedStatement : selectedStatement;

  const mostRecentPayment = !!statement?.most_recent_payment;

  return (
    <div className={styles.container}>
      <div className={classNames("w-full flex items-start justify-between", styles.detailsHeader)}>
        <div
          style={{
            width: isdesktop ? "85%" : "80%",
          }}
          className={`w-full pr-6`}
        >
          <div className="flex items-center w-full">
            <div
              style={{ marginRight: statement?.store_name && "20px" }}
              className={classNames("truncate capitalize", styles.headerTitleWithId)}
            >
              {/* Billing Statement: {statement?.id} */}
              {statement?.store_name}
            </div>
            <div className={classNames("truncate", styles.headerTitleDate)}>
              {statement?.billing_period_start_at ? moment(statement?.billing_period_start_at).format("MMM D, YYYY") : ""} -{" "}
              {statement?.billing_period_end_at ? moment(statement?.billing_period_end_at).format("MMM D, YYYY") : ""}
            </div>
            <div className="ml-4">
              <PaymentStatus data={statement} />
            </div>
          </div>
          <div className="flex items-center my-4 w-full">
            <div className={classNames("truncate capitalize mr-8 ", styles.paymentTitles)}>
              Starting Balance:{" "}
              <span
                style={{
                  color: Colors.percentUp,
                  fontSize: mdfontsize,
                }}
              >
                ${statement?.starting_balance ? numberWithCommas((statement?.starting_balance / 100)?.toFixed(2)) : "0"}
              </span>
            </div>
            <div className={classNames("truncate capitalize mr-8 ", styles.paymentTitles)}>
              Total Debit:{" "}
              <span
                style={{
                  color: Colors.percentUp,
                  fontSize: mdfontsize,
                }}
              >
                ${statement?.total_debit_amount ? numberWithCommas((statement?.total_debit_amount / 100)?.toFixed(2)) : "0"}
              </span>
            </div>
            <div className={classNames("truncate capitalize mr-8 ", styles.paymentTitles)}>
              Total Credit: {/* Ending Balance:{" "} */}
              <span
                style={{
                  color: Colors.percentUp,
                  fontSize: mdfontsize,
                }}
              >
                ${statement?.total_credit_amount ? numberWithCommas((statement?.total_credit_amount / 100)?.toFixed(2)) : "0"}
              </span>
            </div>

            <div
              className={classNames("truncate capitalize", styles.paymentTitles)}
              style={{
                minWidth: "170px",
              }}
            >
              Amount due:{" "}
              <span
                style={{
                  color: Colors.percentUp,
                  fontSize: mdfontsize,
                }}
              >
                ${statement?.ending_balance ? numberWithCommas((statement?.ending_balance / 100)?.toFixed(2)) : "0"}
              </span>
            </div>
            {/* <div
              className="truncate capitalize ml-8"
              style={{
                fontSize: smfontsize,
                color: Colors.fontGreyLight,
              }}
            >
              {statement?.store_name}
            </div> */}
          </div>
        </div>

        <div className="flex flex-col justify-center">
          {statement?.most_recent_payment?.payment_method && (
            <div
              style={{
                padding: "2px 0px",
                borderRadius: "8px",
                marginBottom: "4px",
                textAlign: "center",
              }}
            >
              <div className="flex items-center">
                {statement?.most_recent_payment?.payment_method?.type === "us_bank_account" ? (
                  <div
                    style={{
                      borderWidth: "1px",
                      borderColor: Colors.borderPrimary,
                    }}
                    className="p-1 rounded"
                  >
                    <AiFillBank size={20} color={Colors.fontGreyLight} />
                  </div>
                ) : (
                  cardLogoHandler(statement?.most_recent_payment?.payment_method?.card_issuer, smfontsize, Colors)
                )}
                <div className="text-gray-400 ml-3 font-medium">
                  <span
                    style={{
                      fontSize: smfontsize,
                      color: Colors.fontGreyLight,
                    }}
                    className="text-gray-800"
                  >
                    ****
                  </span>{" "}
                  <span
                    style={{
                      fontSize: smfontsize,
                      color: Colors.fontGreyLight,
                    }}
                    className="text-gray-800"
                  >
                    {" " + statement?.most_recent_payment?.payment_method?.public_identifier}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
        {!mostRecentPayment && statement?.ending_balance > 0 && (
          <Button
            text="Pay Statement"
            externalClassName={styles.payButton}
            onClick={() => submitPaymentHandler()}
            disabled={isPayDisabled}
          />
        )}

        <DownloadStatementCSV
          csvToolTip={
            '"Transaction Time" refers to the time of delivery initiation. Usually this is the time when a delivery provider dispatches driver or otherwise begins its fulfillment process for the delivery. The timestamps are in ISO8601 format, using UTC time zone. "Amount" is represented in the smallest denomination of your local currency. In USD, for example, the unit is cent.'
          }
          statementId={statement?.id ? statement.id : id}
        />
      </div>
      <div
        style={{
          padding: "6px 0px",
          height: "calc(100% - 30px)",
          // height: "calc(100% - 85px)",
        }}
        className="flex items-start"
      >
        <RelatedStatementsNav
          selectedRelatedStatement={selectedRelatedStatement}
          onRelatedStatementChangeHandler={onRelatedStatementChangeHandler}
        />
        <div style={{ width: "calc(100% - 300px)", height: "calc(100% - 200px)" }}>
          <div
            style={{
              borderBottomWidth: "1.5px",
              borderColor: Colors.borderPrimary,
              padding: "20px 20px",
            }}
            className="grid grid-cols-12 gap-1 w-full"
          >
            <div
              style={{
                fontSize: mdfontsize,
                fontWeight: "600",
                color: Colors.fontDark,
              }}
              className="col-start-1 col-span-2 "
            >
              Transaction
            </div>
            <div
              style={{
                fontSize: mdfontsize,
                fontWeight: "600",
                color: Colors.fontDark,
              }}
              className="col-start-5 col-span-3"
            >
              Date
            </div>

            <div
              style={{
                fontSize: mdfontsize,
                fontWeight: "600",
                color: Colors.fontDark,
              }}
              className="col-start-9 col-span-2 "
            >
              Amount
            </div>
          </div>
          <div
            style={{
              height: "calc(100% - 100px)",
              overflowY: "auto",
            }}
          >
            {isTransactionsForStatementLoading && (
              <div style={{ minHeight: "100px" }} className="w-full  flex items-center justify-center kata">
                <Loading color={Colors.bluePrimary} className="h-14 w-14 animate-spin" />
              </div>
            )}
            {details?.data?.length > 0 ? (
              details?.data.map((item) => {
                return <TransactionsItem activeId={activeId} setActiveId={setActiveId} item={item} key={item.id} />;
              })
            ) : (
              <div style={{ color: Colors.fontDark }} className="w-full h-full flex justify-center items-center">
                No transactions found
              </div>
            )}
          </div>
          <div className="flex items-center justify-end pr-40 mt-2">
            <Pagination
              dependencies={[selectedRelatedStatement]}
              data={details}
              onGetPaginationData={(params) =>
                statementTransactionsHandler({
                  ...params,
                  id: selectedRelatedStatement?.id,
                })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatementDetail;
