import React, { useEffect } from "react";
import styles from "./styles.module.css";
import favIco from "../../assets/png/BurqMiniLogo.png";
import logo from "../../assets/png/dark.png";
import errorImage from "./assets/error.png";
import { baseUrl } from "../../runTimeConst";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ErrorPage = (props) => {
  const navigate = useNavigate();
  const checkApiStatus = async () => {
    const response = await axios.get(`${baseUrl}auth/me`, {
      headers: { "x-burq-client": "client-dashboard" },
    });
    if (response) {
      navigate("/v1/home");
    }
  };
  useEffect(() => {
    checkApiStatus();
  }, []);
  return (
    <div className={styles.container}>
      <div
        className="cursor-pointer"
        onClick={() => {
          window.location.href = process.env.REACT_APP_STRIPE_RETURN_URL;
        }}
      >
        <img src={logo} alt="Octocat" className={styles.octocat} />
      </div>
      <img src={errorImage} alt="Octocat" className={styles.errorImage} />

      <div className="w-full mt-24">
        <div className={styles.err500}>Sorry! Burq dashboard is not available at the moment.</div>
        <p className={styles.title}>
          We track these errors automatically and are working on fixing them. For immediate operational assistance, please use the
          blue chat widget in the lower right corner of the screen to reach our support team. To subscribe and receive
          notification when we are back online, please visit our{" "}
          <a href="https://burq.statuspage.io/" className={styles.link} target="_blank" rel="noreferrer">
            status.burq.com
          </a>
        </p>
      </div>
    </div>
  );
};

export default ErrorPage;
