import React, { useEffect } from "react";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import { useTheme, useOnboardingStore } from "../../store";
import { Loading } from "../../assets/svgs/Svgs";

import styles from "./styles/finishElement.module.css";
import classNames from "classnames";

const FinishElement = () => {
  const { Colors } = useTheme();
  const { isVerificationRequired, platformInfo } = useOnboardingStore();
  const { lgfontsize } = useDesktopOrLaptop();

  useEffect(() => {
    if (platformInfo?.redirect_url) {
      window.location.href = platformInfo?.redirect_url;
    }
  }, [platformInfo]);

  if (!platformInfo || platformInfo?.redirect_url) {
    return (
      <div className="h-screen flex justify-center items-center bg-white absolute top-0 left-0 w-screen">
        <Loading color={Colors.bluePrimary} className="h-12 w-12 animate-spin" />
      </div>
    );
  }

  return (
    <>
      <div className={styles.container}>
        <h1 className={styles.title}>Setup complete</h1>
        <div className={classNames(styles.texts, "mt-9")}>Your delivery account setup is complete. </div>
        <div className={classNames(styles.texts, "mt-10 max-w-3xl")}>
          We are working on getting everything ready for your account. Once ready, you will receive additional communication on
          how to access the new delivery management portal.
        </div>
        <div className={classNames(styles.texts, "mt-10")}>You may now close this browser window. Thank you.</div>
      </div>
      {/* <div
        style={{
          fontSize: lgfontsize,
          fontWeight: "500",
          color: Colors.fontGreyLight,
          marginTop: "16px",
          padding: "24px 15%",
          textAlign: "center",
        }}
      >
        We are working on getting everything ready for your account. Once ready, you will receive additional communication on how
        to access the new delivery management portal.{" "}
        <div style={{ marginTop: "40px" }}>You may now close this browser window. Thank you.</div>
      </div>
      {isVerificationRequired && (
        <div
          style={{
            fontSize: lgfontsize,
            fontWeight: "500",
            color: Colors.yellowDark,
            marginTop: "16px",
          }}
        >
          Your bank account verification is pending. You will receive an email from stripe for the account verification
        </div>
      )} */}
    </>
  );
};

export default FinishElement;
