import React from "react";
import { useTheme } from "../../../../../store";
import { useDesktopOrLaptop } from "../../../../../services/useDesktopOrLaptop";

const TableHeader = () => {
  const { Colors } = useTheme();
  const { isdesktop } = useDesktopOrLaptop();

  return (
    <div
      style={{
        borderBottomWidth: "1.5px",
        borderColor: Colors.borderPrimary,
        padding: "20px 20px",
      }}
      className="grid grid-cols-12 gap-1 w-full"
    >
      <div
        style={{
          fontSize: "16px",
          fontWeight: "600",
          color: Colors.fontDark,
        }}
        className="col-start-1 col-span-5 "
      >
        Item
      </div>
      <div
        style={{
          fontSize: "16px",
          fontWeight: "600",
          color: Colors.fontDark,
        }}
        className="col-start-6 col-span-4 "
      >
        Balance
      </div>
      <div
        style={{
          fontSize: "16px",
          fontWeight: "600",
          color: Colors.fontDark,
          whiteSpace: isdesktop ? "nowrap" : "normal",
        }}
        className="col-start-10 col-span-3 "
      >
        Transactions
      </div>
    </div>
  );
};

export default TableHeader;
