import React from "react";
import moment from "moment";
import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";

const OrderEtaSection = ({ orderInfo }) => {
  const { smfontsize, isdesktop } = useDesktopOrLaptop();

  const renderDate = (label, dateValue) => {
    return (
      <div className="w-full flex justify-between mt-1 text-gray-50">
        <div
          style={{ fontSize: smfontsize }}
          className="text-gray-50 font-semibold "
        >
          {label}:
        </div>
        <div style={{ fontSize: smfontsize }} className=" ">
          {dateValue &&
          moment(dateValue).format("MMM D, YYYY, h:mm A") !== "Invalid date"
            ? moment(dateValue).format("MMM D, YYYY, h:mm A")
            : "---"}
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        top: isdesktop ? "-100px" : "-140px",
        left: isdesktop ? "0px" : "20px",
        width: !isdesktop && `calc(100vw - 40px)`,
      }}
      className="absolute bg-green-400 w-full   lg:px-10 px-10 py-2 lg:py-3 rounded-lg"
    >
      <div className="w-full flex items-center flex-col ">
        {renderDate("Created at", orderInfo.created_at)}
        {renderDate("Pickup ETA", orderInfo.est_start_at)}
        {renderDate("Drop-off ETA", orderInfo.est_complete_at)}
      </div>
    </div>
  );
};

export default OrderEtaSection;
