import React from 'react';
import styles from './styles/paymentMethodListHeader.module.css';

const PaymentMethodListHeader = () => {
    return (
        <>
            <tr>
                <th className={styles.columnTitle}>Type</th>
                <th className={styles.columnTitle}>Card Number</th>
                <th className={styles.columnTitle}>Status</th>
                <th className={styles.columnLastTitle}>Action</th>
            </tr>
        </>
    );
};

export default PaymentMethodListHeader;
