import React, { useEffect, useRef } from "react";
import { useDesktopOrLaptop } from "../../../../../services/useDesktopOrLaptop";
import { useStatementsCsv, useTheme } from "../../../../../store";
import SelectComp from "../../../../SelectComp/SelectComp";
import { TIME_ZONES } from "../utils";
import styles from "./styles.module.css";
import Button from "../../../../shared/atoms/Button";

const CsvModalContent = ({
  selectedTimeZone,
  setSelectedTimeZone,
  setIsModalVisible,
  statementId,
}) => {
  const { isdesktop, mdfontsize, xlfontsize } = useDesktopOrLaptop();
  const { Colors } = useTheme();

  const {
    isStatementCSVLoading,
    setIsStatementCSVLoading,
    getStatementDownloadCSV,
  } = useStatementsCsv();

  // let subscribeRef = useRef(true);

  const statementsDownloadHandler = async (id) => {
    setIsStatementCSVLoading(true);
    const params = {
      time_zone: selectedTimeZone,
    };

    const csvRes = await getStatementDownloadCSV(id, params);

    // if (!subscribeRef.current) {
    //   return;
    // }

    if (csvRes.status === "processing") {
      return setTimeout(() => {
        statementsDownloadHandler(id);
      }, 1000);
    } else if (csvRes.status === "completed") {
      setIsStatementCSVLoading(false);

      window.open(csvRes.presigned_url, "_blank");
    } else if (csvRes === "error") {
      setIsStatementCSVLoading(false);
    }
  };

  useEffect(() => {
    setIsStatementCSVLoading(false);
    return () => {
      // subscribeRef.current = false;
      setSelectedTimeZone("UTC");
    };
  }, []);

  return (
    <div className="w-full pb-6">
      <div
        style={{ borderColor: Colors.borderPrimary }}
        className="w-full flex items-center justify-between border-b py-6 px-8"
      >
        <div
          className={styles.title}
          style={{
            fontSize: mdfontsize,
          }}
        >
          Download CSV
        </div>
        <button
          onClick={() => {
            setIsModalVisible(false);
          }}
          type="button"
          style={{
            color: Colors?.fontDark,
            fontSize: xlfontsize,
          }}
          className="material-icons outline-none"
        >
          close
        </button>
      </div>

      <div className="mt-4 mb-16 px-8 w-full flex flex-col">
        <div className="flex items-center justify-between">
          <div style={{ color: Colors.fontDark }}>Choose Timezone</div>
          <div
            className={styles.dropdownCopm}
            style={{
              paddingBottom: !isdesktop ? 5 : 0,
            }}
          >
            <SelectComp
              list={TIME_ZONES}
              defaultValue={"UTC"}
              setSelectedValue={(val) => {
                setSelectedTimeZone(val);
              }}
              value={selectedTimeZone}
              placeholder="Select Time Zone"
              inputStyle={{
                maxWidth: "200px",
                padding: "4px 10px",
                color: Colors.fontGreyLight,
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex mt-4 justify-end px-8">
        <Button
          onClick={() => {
            setIsModalVisible(false);
          }}
          text="Cancel"
          type="button"
          variant="secondary"
        />
        <Button
          onClick={async () => {
            if (!isStatementCSVLoading) {
              await statementsDownloadHandler(statementId);
              setIsModalVisible(false);
            } else {
              return;
            }
          }}
          text="Download"
          type="button"
          variant="primary"
        />
      </div>
    </div>
  );
};

export default CsvModalContent;
