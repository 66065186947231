import React, { useMemo } from "react";
import { useDesktopOrLaptop } from "../../../../services/useDesktopOrLaptop";
import { useAnalyticsStore, useTheme } from "../../../../store";
import ButtonComp from "../../../ButtonComp/ButtonComp";

const HeatChartHeaderTab = () => {
  const { Colors } = useTheme();
  const { smfontsize } = useDesktopOrLaptop();
  const { topTimingDataGroupBy } = useAnalyticsStore();

  const styles = useMemo(() => {
    return {
      container: {
        display: "flex",
        borderRadius: "8px",
        margin: "0 12px",
        marginBottom: "10px",
        border: `1px solid ${Colors.borderSecondary}`,
        padding: "6px",
      },
      button: {
        padding: "8px 12px",
        fontSize: smfontsize,
        borderRadius: "5px",
      },
      buttonColor: {
        color: Colors.fontDark,
      },
    };
  }, [Colors, smfontsize]);

  return (
    <div style={styles.container}>
      <ButtonComp
        title={"Week"}
        extraStyles={
          topTimingDataGroupBy === "week"
            ? {
                ...styles.button,
              }
            : {
                ...styles.button,
                ...styles.buttonColor,
              }
        }
        primary={topTimingDataGroupBy === "week"}
        onClick={() => {
          // setTopTimingDataGroupBy("week");
        }}
      />
    </div>
  );
};

export default HeatChartHeaderTab;
