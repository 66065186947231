import axios from "axios";
import apiUrls from "../../constants/apiUrls";
import { generateQueryParams } from "./utils";

export const createCSVJobCall = (id, params) => {
  return axios.post(apiUrls.DELIVERY_CSV_JOB(id), params, {
    headers: { "x-burq-client": "client-dashboard" },
  });

  // const urlWithQueryParams = generateQueryParams(
  //   apiUrls.DELIVERY_CSV_JOB(id),
  //   params
  // );
  // return axios.get(urlWithQueryParams, {
  //   headers: { "x-burq-client": "client-dashboard" },
  // });
};

export const downloadCSV = (id) => {
  return axios.get(apiUrls.DELIVERY_CSV_DOWNLOAD(id), {
    headers: { "x-burq-client": "client-dashboard" },
  });
};
