export const generateQueryParams = (url, queryParams) => {
  let urlWithParams = `${url}?`;

  Object.keys(queryParams).forEach((key, index) => {
    const paramsElement = queryParams[key];

    if (paramsElement || paramsElement === false || paramsElement === 0) {
      let param = "";

      if (Array.isArray(paramsElement)) {
        // Handle array: create comma-separated values
        param = `${key}=${paramsElement.join(',')}`;
      } else if (typeof paramsElement === "object") {
        // Handle object: generate separate key-value pairs
        Object.keys(paramsElement).forEach((innerKey, innerIndex) => {
          const value = paramsElement[innerKey];
          param += _getAdjustedParameter(
            innerIndex,
            paramsElement,
            `${key}=${value}`
          );
        });
      } else {
        // Handle other types (string, number, boolean)
        param = `${key}=${paramsElement}`;
      }

      urlWithParams += _getAdjustedParameter(index, queryParams, param);
    }
  });

  return urlWithParams;
};

const _getAdjustedParameter = (index, params, value) => {
  return Object.keys(params).length === index + 1 ? value : `${value}&`;
};
