import React from "react";
import classNames from "classnames";
import classStyles from "./styles.module.css";

function TabButtonComp({ isActive, onClick, title, extraBottomLineClasses, extraButtonClasses }) {
  return (
    <button
      id="payments"
      type="button"
      onClick={onClick}
      className={classNames(
        `focus:outline-none flex flex-col whitespace-nowrap`,
        classStyles.buttonCont,
        {
          [classStyles.activeButton]: isActive,
        },
        extraButtonClasses
      )}
    >
      {title}
      <div
        className={classNames(classStyles.bottomLine, { [classStyles.activeBottomLine]: isActive }, extraBottomLineClasses)}
      ></div>
    </button>
  );
}

export default TabButtonComp;
