import React, { useEffect, useRef, useState } from "react";
import { useMyStore, useProvidersManagementStore } from "../../../../store";
import { useDesktopOrLaptop } from "../../../../services/useDesktopOrLaptop";
import Loader from "../../../shared/atoms/Loader";
import { DotsSvg } from "../../../../assets/svgs/Svgs";
import CardFooter from "./CardFooter";
import ActionMenu from "./ActionMenu";
import BadgeComponent from "./ProviderBadges";
import RequestStatus from "./RequestStatus";
import styles from "../../styles/ProviderList/ProviderCard/styles.module.css";

const ProviderCard = ({ provider }) => {
  const [dotsMenuVisible, setDotsMenuVisible] = useState(false);
  const { updateAccountProviderSettingHandler, setSelectedProvider } =
    useProvidersManagementStore();
  const actionMenuRef = useRef();
  const { isdesktop } = useDesktopOrLaptop();
  const { isSandbox } = useMyStore();

  const [data, setData] = useState(null);

  useEffect(() => {
    setData(provider ?? null);
  }, [provider]);

  const aps = data?.account_provider_setting;

  const accountProviderSettingUpdateHandler = async (payload) => {
    const res = await updateAccountProviderSettingHandler(aps?.id, payload);
    if (!(res instanceof Error)) {
      const prop = isSandbox ? "is_test_mode_enabled" : "is_live_mode_enabled";
      setData({
        ...data,
        is_enabled: res?.[prop],
        account_provider_setting: { ...res },
      });
    }
  };

  if (!data) {
    <div className={styles.cardContainer}>
      <Loader />
    </div>;
  }

  const renderActionMenu = () => (
    <div
      onClick={(e) => {
        e.stopPropagation();
        setDotsMenuVisible(!dotsMenuVisible);
      }}
      className={styles.actionMenu}
      ref={actionMenuRef}
    >
      <div className={styles.dotIcon}>
        <DotsSvg width={isdesktop ? 18 : 30} height={isdesktop ? 4 : 24} />
      </div>

      {dotsMenuVisible && (
        <ActionMenu
          refElement={actionMenuRef}
          provider={data}
          setDotsMenuVisible={setDotsMenuVisible}
          accountProviderSettingUpdateHandler={
            accountProviderSettingUpdateHandler
          }
        />
      )}
    </div>
  );

  return (
    <div
      className={styles.cardContainer}
      onClick={() => {
        setSelectedProvider(data);
      }}
    >
      <div className={styles.cardBody}>
        <div className={styles.topSection}>
          <img
            className={styles.logo}
            alt="provider logo"
            src={data?.provider_logo_url}
          />
          {!data?.access_required && aps && renderActionMenu()}
        </div>
        <div className={styles.name}>{data?.name}</div>
        {!data?.access_required && <BadgeComponent data={data} aps={aps} />}
        <RequestStatus data={data} />
      </div>
      <CardFooter data={data} />
    </div>
  );
};

export default ProviderCard;
