import { Menu, Transition } from "@headlessui/react";
import classNames from "classnames";
import { Fragment } from "react";
import styles from "./styles.module.css";

const DropdownHeadless = ({
  children,
  CustomButton,
  externalDropDownClasses,
  ...props
}) => {
  return (
    <div className="mr-5">
      <Menu as="div" className="relative inline-block text-left">
        <Menu.Button as={CustomButton} {...props} />

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className={classNames(
              "absolute z-50 right-0 mt-2 origin-top-right rounded-xl focus:outline-none",
              styles.menuContainer,
              externalDropDownClasses
            )}
          >
            {children}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default DropdownHeadless;
