import moment from "moment";
import momenttz from "moment-timezone";
import React from "react";
import { useTheme } from "../../../../../store";

import styles from "./styles.module.css";
import classNames from "classnames";
import Badge from "../../../../shared/atoms/Badge";

function SingleAddressForModalNew({
  index,
  address,
  name,
  phoneNumber,
  note,
  length,
  item,
  status,
  unit,
  dropoff,
}) {
  const { Colors } = useTheme();

  return (
    <div className="flex">
      <div className="flex flex-col" style={{ width: "99px" }}>
        <div className={styles.stopOrPickupText}>
          {index ? `Stop ${index}` : "Pickup"}
        </div>

        {(!index && !item.pickup_eta) ||
        status === "delivered" ||
        status === "provider_canceled" ||
        status === "customer_canceled" ||
        status === "burq_canceled" ||
        status === "failed" ? (
          <div style={{ width: "99px" }}></div>
        ) : (index && !item.dropoff_eta) ||
          status === "delivered" ||
          status === "provider_canceled" ||
          status === "customer_canceled" ||
          status === "burq_canceled" ? (
          <div style={{ width: "99px" }}></div>
        ) : (
          <>
            <div className={styles.leftSidePickupText}>
              {!index ? "Pick-up ETA" : "Drop-off ETA"}
            </div>
            <div className={styles.leftSideTime}>
              {!index
                ? moment(item.pickup_eta).format("D MMM YYYY") !==
                  "Invalid date"
                  ? moment(item.pickup_eta).format("D MMM YYYY")
                  : "---"
                : moment(item.dropoff_eta).format("D MMM YYYY") !==
                  "Invalid date"
                ? moment(item.dropoff_eta).format("D MMM YYYY")
                : "---"}
            </div>
            <div className={styles.leftSidePickupText}>Time</div>
            <div className={styles.leftSideTime}>
              {!index
                ? moment(item.pickup_eta).format("h:mm A") !== "Invalid date"
                  ? moment(item.pickup_eta).format("h:mm A") +
                    ", " +
                    momenttz.tz(momenttz.tz.guess()).format("z")
                  : "---"
                : moment(item.dropoff_eta).format("h:mm A") !== "Invalid date"
                ? moment(item.dropoff_eta).format("h:mm A") +
                  ", " +
                  momenttz.tz(momenttz.tz.guess()).format("z")
                : "---"}
            </div>
          </>
        )}
        {item.contactless_requested && dropoff && (
          <div className="h-6 ml-0 mt-2">
            <Badge
              externalClassName=" mb-3"
              label="Contactless"
              type="warning"
            />
          </div>
        )}
        {item.signature_requested && dropoff && (
          <div className="h-6 ml-0 mt-2">
            <Badge externalClassName=" mb-3" label="Signature" type="warning" />
          </div>
        )}
      </div>
      <div className={styles.singleAddress}>
        {index !== length && <div className={styles.leftLine}></div>}

        <div
          className={styles.circle}
          style={{
            backgroundColor: index !== 0 ? Colors.greenDark : Colors.yellowDark,
          }}
        ></div>
        <div className={styles.pickupDropoff}>Address</div>
        <div className={styles.addresses}>{address}</div>
        {unit && (
          <>
            <div className={styles.pickupDropoff}>Unit</div>
            <div className={styles.addresses}>{unit}</div>
          </>
        )}

        <div className={styles.addressCont}>
          <div className="flex w-full items-center mt-5 justify-between">
            <div
              className={classNames(styles.pickupDropoff, "text-left w-1/3")}
            >
              Name
            </div>
            <div
              className={classNames(styles.pickupDropoff, "text-left w-1/3")}
            >
              Phone #
            </div>
          </div>
          <div className="flex w-full items-center justify-between mt-0">
            <div className={classNames(styles.addresses, "w-1/3 text-left")}>
              {name}
            </div>
            <div className={classNames(styles.addresses, "w-1/3 text-left")}>
              {phoneNumber && phoneNumber[0] === "+" ? "" : "+"}
              {phoneNumber || ""}
            </div>
          </div>
          <div className={classNames(styles.pickupDropoff, "mt-5")}>Notes:</div>

          <div className={styles.notesCont}>
            <div className={classNames(styles.addresses, "w-1/3 text-left")}>
              {note}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingleAddressForModalNew;
