import React from "react";
import classNames from "classnames";
import styles from "./styles.module.css";
import { useMyStore, useTheme } from "../../../store";

const PaginationWithoutCursor = ({
  data,
  onGetPaginationData,
  buttonClass,
}) => {
  const { Colors } = useTheme();
  const { isSandbox } = useMyStore();

  const disableStyle = () => {
    return {
      color: data?.has_more ? Colors.fontDark : Colors.fontGreyLight,
    };
  };

  return (
    <div className={styles.container}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <button
          type="button"
          onClick={() => {
            onGetPaginationData({
              ending_before: data?.data[0]?.id,
              starting_after: "",
              isSandbox: isSandbox,
            });
          }}
          className={classNames(styles.buttons, buttonClass)}
        >
          Previous
        </button>

        <button
          type="button"
          disabled={!data?.has_more}
          onClick={() => {
            onGetPaginationData({
              ending_before: "",
              starting_after: data?.data[data?.data?.length - 1]?.id,
              isSandbox: isSandbox,
            });
          }}
          className={classNames(styles.buttons, buttonClass)}
          style={{
            ...disableStyle(),
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default PaginationWithoutCursor;
