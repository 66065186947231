import React, { useEffect, useLayoutEffect, useMemo, useRef } from "react";
import { Loading } from "../../assets/svgs/Svgs";
import CreateStoreModal from "../../modals/CreateStoreModal";
import PagingOrders from "../../screens/OrderManagement/OrdersSide/PagingOrders";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import { useConnectedAccounts, useStoreAndChild, useTheme } from "../../store";
import CustomModal from "../CustomModal/CustomModal";
import StoreItem from "../StoreSettings/StoresList/StoreItem";
import ButtonComp from "../ButtonComp/ButtonComp";
import PagingForStoresInsideConnectedAcc from "./PagingForStoresInsideConnectedAcc";

const ConnectedAccountDetails = ({
  setDetailsHeight,
  activeIndex,
  index,
  item,
  firstStoreInsideAccount,
}) => {
  const { Colors } = useTheme();
  const heightRef = useRef(null);
  const { isdesktop, mdfontsize, smfontsize, lgfontsize, xsfontsize } =
    useDesktopOrLaptop();
  const { setSelectedConnectedAccount } = useConnectedAccounts();

  const {
    storesForConnectedAccount,
    isConnectedAccountStoresLoading,
    setStoresForConnectedAccount,
    setIsCreateStoreModalOpen,
    allStoresByUser,
  } = useStoreAndChild();
  useLayoutEffect(() => {
    if (heightRef?.current) {
      setDetailsHeight(heightRef.current.clientHeight);
    }
  }, [heightRef]);

  const styles = useMemo(() => {
    return {
      container: {
        backgroundColor: Colors.white,
        marginTop: activeIndex === index ? "20px" : "0px",
        marginBottom: activeIndex === index ? "20px" : "0px",
        padding: "20px 20px",
        overflow: "hidden",
        borderColor: "rgba(0, 0, 0, 0.07)",
        borderBottomWidth: activeIndex === index + 1 ? "1px" : "0px",
        boxShadow:
          activeIndex === index + 1 && "0px 2px 2px rgba(0, 0, 0, 0.07)",
        borderTopWidth: activeIndex === index && "1px",
        maxHeight: "500px",
      },
      title: {
        fontSize: mdfontsize,
        color: Colors.fontDark,
        fontWeight: "500",
      },
      value: {
        fontSize: mdfontsize,
        color: Colors.fontGreyLight,
        fontWeight: "500",
      },
      borderDivide: {
        borderBottomWidth: "1px",
        borderColor: Colors.borderPrimary,
        width: isdesktop ? "50%" : "100%",
      },
    };
  }, [Colors, isdesktop, activeIndex, index]);

  useEffect(() => {
    return () => {
      setStoresForConnectedAccount();
    };
  }, []);

  return (
    <div ref={heightRef} style={styles.container}>
      {storesForConnectedAccount && (
        <div className="flex items-center justify-between w-full">
          <div
            style={{
              fontSize: lgfontsize,
              fontWeight: "500",
              color: Colors.fontDark,
            }}
          >
            Stores:
          </div>
          <ButtonComp
            onClick={() => {
              setSelectedConnectedAccount(item);
              setIsCreateStoreModalOpen(true);
            }}
            primary
            title={"Create Store"}
          />
        </div>
      )}
      <div
        style={{
          maxHeight: "400px",
          overflowY: "scroll",
          paddingBottom: "40px",
        }}
      >
        {isConnectedAccountStoresLoading && (
          <div
            style={{ minHeight: "100px" }}
            className="w-full  flex items-center justify-center kata"
          >
            <Loading
              color={Colors.bluePrimary}
              className="h-14 w-14 animate-spin"
            />
          </div>
        )}
        {storesForConnectedAccount?.data?.map((store) => {
          return (
            <StoreItem
              connectedAccount={item}
              key={store.id}
              address={store.address}
              phoneNumber={store.phone_number}
              title={store.name}
              store={store}
            />
          );
        })}
      </div>
      {/* {storesForConnectedAccount?.data?.length > 0 && (
        <div className="flex w-full justify-end">
          <PagingForStoresInsideConnectedAcc
            firstItemId={firstStoreInsideAccount}
          />
        </div>
      )} */}
    </div>
  );
};

export default ConnectedAccountDetails;
