import React, { useEffect, useState } from "react";
import { compose, withProps } from "recompose";
import {
  withGoogleMap,
  GoogleMap,
  DirectionsRenderer,
  Marker,
} from "react-google-maps";

import { geolocated } from "react-geolocated";

import dropoffMarker from "../assets/png/pickup.png";
import pickupMarker from "../assets/png/dropoff.png";

import { useOrdersForMap, useTheme } from "../store";

import OverlayView from "react-google-maps/lib/components/OverlayView";
import { useDesktopOrLaptop } from "../services/useDesktopOrLaptop";
import { LIGHT_MAP, MAPSTYLE } from "../runTimeConst";

const OnlyMapForDeliveries = compose(
  withProps({
    // googleMapURL:
    //   "https://maps.googleapis.com/maps/api/js?key=AIzaSyCld15MHiSxfee_McOLRIdzEQ-27Ul0sb4&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  // withScriptjs,
  withGoogleMap
)(({ zoom, pickupCoordsArr, firstCoordinates, providedDirections }) => {
  const [directions, setDirections] = useState();

  const { dropoffCoordinatesArr, setDropoffCoordinatesArr, Colors } =
    useTheme();
  const { smfontsize, xsfontsize } = useDesktopOrLaptop();
  const { ordersArray, directionsForOrders } = useOrdersForMap();

  const getPixelPositionOffset = (width, height) => ({
    x: -30,
    y: -30,
  });

  const calculateAndDisplayRoute = async (
    pickupCoordsArr,
    dropoffCoordinatesArr
  ) => {
    const _array = [...dropoffCoordinatesArr];
    const DirectionsService = new window.google.maps.DirectionsService();
    const _routesForMap = [];
    for (let i = 0; i < _array.length; i++) {
      if (i === 0) {
        const data = await DirectionsService.route({
          origin: {
            lat: pickupCoordsArr[0].lat,
            lng: pickupCoordsArr[0].lng,
          },
          destination: {
            lat: _array[i].lat,
            lng: _array[i].lng,
          },
          travelMode: window.google.maps.TravelMode.DRIVING,
        });
        _routesForMap.push(data);
      } else if (i > 0) {
        const data = await DirectionsService.route({
          origin: {
            lat: _array[i - 1].lat,
            lng: _array[i - 1].lng,
          },
          destination: {
            lat: _array[i].lat,
            lng: _array[i].lng,
          },
          travelMode: window.google.maps.TravelMode.DRIVING,
        });
        _routesForMap.push(data);
      }
    }
    setDirections(_routesForMap);
    return;
  };

  useEffect(() => {
    let subscribe = true;
    if (subscribe) {
      // if (pickupCoordsArr[0]?.lat && dropoffCoordinatesArr[0]?.lat) {
      if (
        pickupCoordsArr[0]?.lat &&
        pickupCoordsArr[0]?.lng &&
        dropoffCoordinatesArr
      ) {
        calculateAndDisplayRoute(pickupCoordsArr, dropoffCoordinatesArr);
      } else if (providedDirections) {
        // setDropoffCoordinatesArr([])
        setDirections(providedDirections);
      } else if (directionsForOrders) {
        // setDropoffCoordinatesArr([])
        setDirections(directionsForOrders);
      }
    }
    return () => {
      subscribe = false;
    };
  }, [pickupCoordsArr, dropoffCoordinatesArr, providedDirections]);

  useEffect(() => {
    if (directionsForOrders || providedDirections) {
      setDropoffCoordinatesArr([]);
    }
  }, [providedDirections, directionsForOrders]);

  return (
    <GoogleMap
      defaultOptions={{
        styles: LIGHT_MAP,
        clickableIcons: false,
        fullscreenControl: false,
        disableDefaultUI: true,
        mapTypeControl: false,
        gestureHandling: "greedy",
      }}
      zoom={zoom}
      center={firstCoordinates}
    >
      {ordersArray?.length > 0 &&
        ordersArray.map((item, index) => {
          if (item.id === "store") {
            return (
              <OverlayView
                key={item.id}
                getPixelPositionOffset={getPixelPositionOffset}
                position={{
                  lat: item.location.lat,
                  lng: item.location.lng,
                }}
                mapPaneName={OverlayView?.OVERLAY_MOUSE_TARGET}
              >
                <div>
                  <div
                    style={{
                      position: "absolute",
                      top: "-80px",
                      paddingBottom: "45px",
                      left: "-50%",
                      height: "130px",
                    }}
                    className="hover:opacity-100 opacity-0"
                  >
                    <div
                      className="myShadow flex flex-col justify-between"
                      style={{
                        height: "75px",
                        width: "160px",
                        backgroundColor: "white",
                        borderRadius: "12px",
                        padding: "14px 10px",
                      }}
                    >
                      <div
                        style={{
                          fontSize: xsfontsize,
                          color: "#92929D",
                        }}
                      >
                        Pickup:
                      </div>
                      <div
                        className="truncate"
                        style={{ fontSize: smfontsize, color: "black" }}
                      >
                        {item.store.name}
                      </div>
                    </div>
                  </div>
                  <img
                    style={{
                      height: "42px",
                      width: "42px",
                    }}
                    src={pickupMarker}
                    alt=""
                  />
                </div>
              </OverlayView>
            );
          } else {
            return (
              <OverlayView
                key={item.id}
                getPixelPositionOffset={getPixelPositionOffset}
                position={{
                  lat: item.location.lat,
                  lng: item.location.lng,
                }}
                mapPaneName={OverlayView?.OVERLAY_MOUSE_TARGET}
              >
                <div>
                  <div
                    style={{
                      position: "absolute",
                      top: "-80px",
                      paddingBottom: "45px",
                      left: "-50%",
                      height: "130px",
                    }}
                    className="hover:opacity-100 opacity-0"
                  >
                    <div
                      className="myShadow flex flex-col justify-between"
                      style={{
                        height: "75px",
                        width: "160px",
                        backgroundColor: "white",
                        borderRadius: "12px",
                        padding: "14px 10px",
                      }}
                    >
                      <div
                        style={{
                          fontSize: xsfontsize,
                          color: "#92929D",
                        }}
                      >
                        Stop {index} :
                      </div>
                      <div
                        className="truncate"
                        style={{ fontSize: smfontsize, color: "black" }}
                      >
                        {item.address}
                      </div>
                    </div>
                  </div>
                  <div
                    className="flex items-center justify-center"
                    style={{
                      height: "42px",
                      width: "42px",
                      borderRadius: "15px",
                      backgroundColor: Colors.bluePrimary,
                      fontWeight: "600",
                      fontSize: "20px",
                      color: "white",
                    }}
                  >
                    {index}
                  </div>
                  {/* <img
                    style={{
                      height: "42px",
                      width: "42px",
                    }}
                    src={dropoffMarker}
                    alt=""
                  /> */}
                </div>
              </OverlayView>
            );
          }
        })}
      {pickupCoordsArr?.length > 0 &&
        pickupCoordsArr.map((item, i) => {
          return (
            <Marker
              key={i}
              icon={{
                url: pickupMarker,
                scaledSize: new window.google.maps.Size(38, 38),
              }}
              position={{
                lat: item.lat,
                lng: item.lng,
              }}
            />
          );
        })}
      {dropoffCoordinatesArr?.length > 0 &&
        dropoffCoordinatesArr.map((item, i) => {
          return (
            <Marker
              key={i}
              icon={{
                url: dropoffMarker,
                scaledSize: new window.google.maps.Size(38, 38),
              }}
              position={{
                lat: item.lat,
                lng: item.lng,
              }}
            />
          );
        })}
      {directions?.length > 0 &&
        directions.map((item, index) => {
          return (
            <DirectionsRenderer
              key={index}
              options={{
                suppressMarkers: true,
                polylineOptions: { strokeColor: "#1460EF" },
              }}
              directions={item}
            />
          );
        })}
    </GoogleMap>
  );
});

// !

function MapForCreateDelivery({
  zoom,
  firstCoordinates,
  pickupCoordsArr,
  providedDirections,
  createOrder,
}) {
  const { setOrdersArray } = useOrdersForMap();
  const { isdesktop } = useDesktopOrLaptop();
  useEffect(() => {
    if (createOrder) {
      setOrdersArray([]);
    }
  }, [createOrder]);

  return (
    <div className=" w-full h-full relative ">
      <OnlyMapForDeliveries
        containerElement={
          <div
            style={{
              height: isdesktop ? `calc(100vh - 76px)` : "100%",
              width: "100%",
            }}
          />
        }
        zoom={zoom}
        pickupCoordsArr={pickupCoordsArr}
        firstCoordinates={firstCoordinates}
        providedDirections={providedDirections}
      />
    </div>
  );
}
export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 1,
})(MapForCreateDelivery);
