import axios from "axios";
import apiUrls from "../../constants/apiUrls";

export const merchantAccountsPaymentMethods = (id) =>
  axios.get(apiUrls.MERCHANT_ACCOUNTS_PAYMENT_METHODS(id), {
    headers: { "x-burq-client": "client-dashboard" },
  });

export const setStorePaymentMethod = (id, payment_method_id) =>
  axios.post(
    apiUrls.SET_STORE_PAYMENT_METHOD(id),
    { payment_method_id },
    { headers: { "x-burq-client": "client-dashboard" } }
  );

export const removePaymentMethod = (id) =>
  axios.delete(apiUrls.REMOVE_PAYMENT_METHOD(id), {
    headers: { "x-burq-client": "client-dashboard" },
  });

export const merchantAccountsSetDefaultPaymentMethod = (
  id,
  payment_method_id
) =>
  axios.put(
    apiUrls.MERCHANT_ACCOUNTS_SET_DEFAULT_PAYMENT_METHOD(id),
    { payment_method_id },
    {
      headers: { "x-burq-client": "client-dashboard" },
    }
  );
