import React from "react";
import OutsideClickHandler from "react-outside-click-handler";
import classNames from "classnames";
import { useTheme } from "../../../store";
import styles from "./styles.module.css";

const ActionMenu = ({ setDotsMenuVisible, refElement, options, modalPosition }) => {
  const { themeChanger } = useTheme();
  return (
    <OutsideClickHandler
      onOutsideClick={(e) => {
        e.preventDefault();
        if (!refElement.current.contains(e.target)) {
          setDotsMenuVisible(false);
        }
      }}
    >
      <div
        className={styles.container}
        style={{
          top: modalPosition.top,
          right: modalPosition.right,
          left: modalPosition.left,
          bottom: modalPosition.bottom,
        }}
      >
        {options.map((option) => (
          <button
            onClick={() => {
              setDotsMenuVisible(false);
              option.action();
            }}
            className={classNames(
              `focus:outline-none px-4 py-2.5 ${themeChanger ? "hover:bg-gray-200 " : ""}`,
              styles.actionItem
            )}
          >
            {option.label}
          </button>
        ))}
      </div>
    </OutsideClickHandler>
  );
};

export default ActionMenu;
