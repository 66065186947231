import React, { useMemo } from "react";
import ChartSection from "./ChartSection";
import KpisSection from "./KpisSection";

const OverviewDataSection = ({ payload }) => {
  const styles = useMemo(() => {
    return {
      container: {
        width: "100%",
        height: "327px",
        marginBottom: "26px",
        display: "flex",
        justifyContent: "space-between",
      },
      leftSection: {
        width: "72%",
        height: "100%",
      },
      rightSection: {
        width: "25%",
        height: "100%",
      },
    };
  }, []);

  return (
    <div style={styles.container}>
      <div style={styles.leftSection}>
        <ChartSection payload={payload} primaryLineLable="primaryDataValue" />
      </div>
      <div style={styles.rightSection}>
        <KpisSection />
      </div>
    </div>
  );
};

export default OverviewDataSection;
