import React, { Fragment, forwardRef } from "react";

import styles from "./styles.module.css";

import { Menu } from "@headlessui/react";
import classNames from "classnames";
import DropdownHeadless from "../../../shared/common/DropdownHeadless";
import { BsTelephone } from "react-icons/bs";
import { BiSolidDownArrow } from "react-icons/bi";
import { phoneNumberHandler } from "../../../../utils/helpers";

let MyCustomButton = forwardRef(function (props, ref) {
  return (
    <button className={styles.bellOnHeader} ref={ref} {...props}>
      <div className={classNames(styles.phoneIcon, "ml-4")}>
        <BsTelephone size={20} color={"white"} />
      </div>
      <div className="ml-1">Contact Numbers</div>
      <span>
        <BiSolidDownArrow size={10} className={styles.light} />
      </span>
    </button>
  );
});
export function PhoneNumbersOnSideDrawer({ item }) {
  let storeNumber =
    item?.courier_phone_number_for_store &&
    item?.courier_phone_number_for_store[0] !== "+"
      ? "+" + item?.courier_phone_number_for_store
      : item?.courier_phone_number_for_store;

  let customerNumber =
    item?.courier_phone_number_for_store &&
    item?.courier_phone_number_for_store[0] !== "+"
      ? "+" + item?.courier_phone_number_for_customer
      : item?.courier_phone_number_for_customer;

  if (
    item?.courier_phone_number_for_store &&
    item?.courier_phone_number_for_customer &&
    item?.courier_phone_number_for_customer !==
      item?.courier_phone_number_for_store
  ) {
    return (
      <div className="-mr-5">
        <DropdownHeadless
          externalDropDownClasses={styles.dropdown}
          CustomButton={MyCustomButton}
        >
          <Menu.Item as={Fragment}>
            {({ active }) => (
              <div
                onClick={() => (window.location.href = `tel:${storeNumber}`)}
                className="flex flex-col items-center"
              >
                <div className={styles.labels}>For Merchant</div>
                <div
                  className={classNames(
                    styles.orderIdTitle,
                    { [styles.active]: active },
                    " text-left mb-2 cursor-pointer px-0"
                  )}
                >
                  {phoneNumberHandler(storeNumber)}
                </div>
              </div>
            )}
          </Menu.Item>
          <Menu.Item as={Fragment}>
            {({ active }) => (
              <div
                onClick={() => (window.location.href = `tel:${customerNumber}`)}
                className="flex flex-col items-center"
              >
                <div
                  className={classNames(styles.labels, styles.bgForCustomer)}
                >
                  For customer
                </div>
                <div
                  className={classNames(
                    styles.orderIdTitle,
                    { [styles.active]: active },
                    "text-left px-5 cursor-pointer"
                  )}
                >
                  {phoneNumberHandler(customerNumber)}
                </div>
              </div>
            )}
          </Menu.Item>
        </DropdownHeadless>
      </div>
    );
  } else {
    if (customerNumber || storeNumber) {
      return (
        <div className={styles.oneNumber}>
          <div className={classNames(styles.phoneIcon, "ml-12")}>
            <BsTelephone color={"white"} />
          </div>
          {phoneNumberHandler(storeNumber || customerNumber)}
        </div>
      );
    } else {
      return <></>;
    }
  }
}
