import { createStore } from "aniuta";
import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import { baseUrl } from "../runTimeConst";
import {
  getCsvOrderCHild,
  getImports,
  postStartUpload,
} from "../utils/api/uploadCsvBulk";

const useOrdersCsvUpload = createStore({
  name: "useOrdersCsvUpload",
  Store: () => {
    const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);

    // const getPresignedUrlHandler = async (id, testMode, note) => {
    //   try {
    //     const { data } = await getPresignedUrl(id);

    //     return data;
    //   } catch (error) {
    //     if (error?.response?.data?.message) {
    //       toast.error(error?.response?.data?.message);
    //     } else {
    //       // Sentry.captureException(error);
    //       toast.error("Something went wrong! Please try again.");
    //     }
    //   }
    // };

    const [isUploadLoadin, setIsUploadLoadin] = useState(false);
    const startUploadHandler = async (id, payload) => {
      setIsUploadLoadin(true);
      try {
        const { data } = await postStartUpload(id, payload);
        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          // Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsUploadLoadin(false);
      }
    };

    const [csvImportListData, setCsvImportListData] = useState();
    const [isImportListLoading, setIsImportListLoading] = useState(false);

    const getImportsHandler = async (id, params) => {
      setIsImportListLoading(true);
      try {
        const { data } = await getImports(id, params);
        setCsvImportListData(data);
        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          // Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsImportListLoading(false);
      }
    };

    const getCsvOrderCHildHandler = async (id, orderId, query = {}) => {
      try {
        const { data } = await getCsvOrderCHild(id, orderId, query);
        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          // Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      }
    };

    return {
      isUploadModalVisible,
      setIsUploadModalVisible,
      getImportsHandler,
      startUploadHandler,
      csvImportListData,
      setCsvImportListData,
      isImportListLoading,
      isUploadLoadin,
      setIsUploadLoadin,
      getCsvOrderCHildHandler,
    };
  },
});

export default useOrdersCsvUpload;
