import React from "react";
import styles from "./styles.module.css";
import classNames from "classnames";

const InputField = ({ borderStyles, leftIcon, rightIcon, ...props }) => (
  <div className={classNames(styles.inputForEdit, borderStyles)}>
    {leftIcon && (
      <div className={styles.inputLeftIcon}>
        <p>{leftIcon}</p>
      </div>
    )}

    <div className={styles.inputWrapper}>
      <input {...props} className={styles.input} />
    </div>
    {rightIcon && (
      <div className={styles.inputRightIcon}>
        <p>{rightIcon}</p>
      </div>
    )}
  </div>
);

export default InputField;
