import { useState } from "react";
import * as Sentry from "@sentry/react";
import { createStore } from "aniuta";
import { toast } from "react-toastify";
import {
  getDeliveryApiErrors,
  getTrackIncidentLink,
} from "../utils/api/deliveries";

const useDeliveriesStore = createStore({
  name: "DeliveriesStore",
  Store: () => {
    const [isDeliveriyApiErrorsLoading, setIsDeliveriyApiErrorsLoading] =
      useState(false);
    const [deliveryApiErrors, setDeliveryApiErrors] = useState(null);

    const loadDeliveriyApiErrors = async (deliveryId, params) => {
      setIsDeliveriyApiErrorsLoading(true);
      try {
        const { data } = await getDeliveryApiErrors(deliveryId, {
          ending_before: "",
          starting_after: "",
          limit: 30,
          ...params,
        });
        setDeliveryApiErrors(data.data);
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsDeliveriyApiErrorsLoading(false);
      }
    };

    const [isGetTrackIncidentDataLoading, setIsGetTrackIncidentDataLoading] =
      useState(false);

    const getTrackIncidentData = async (id) => {
      setIsGetTrackIncidentDataLoading(true);
      try {
        const { data } = await getTrackIncidentLink(id);
        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
        return error;
      } finally {
        setIsGetTrackIncidentDataLoading(false);
      }
    };

    return {
      isDeliveriyApiErrorsLoading,
      deliveryApiErrors,
      setDeliveryApiErrors,
      loadDeliveriyApiErrors,
      isGetTrackIncidentDataLoading,
      getTrackIncidentData,
    };
  },
});

export default useDeliveriesStore;
