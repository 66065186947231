import React, { useMemo } from "react";

import { useTheme } from "../../../../../store";
import classStyles from "./cadFooter.module.css";
import classNames from "classnames";

const CardFooter = ({ data, isProvidersSelected }) => {
  const { Colors } = useTheme();

  const styles = useMemo(() => {
    return {
      monthText: {
        color: Colors.chartMonth,
      },
    };
  }, [Colors]);

  return (
    <div>
      <div className={classStyles.forstContainer}>
        <p style={styles.monthText} className={classStyles.monthText}>
          {data?.leftLegend || ""}
        </p>
        <p style={styles.monthText} className={classStyles.monthText}>
          {data?.rightLegend || ""}
        </p>
      </div>
      {isProvidersSelected && (
        <div className={classNames(classStyles.lastContainer, "noScroll")}>
          {data.providersList.map((provider) => {
            return (
              <div className={classStyles.providerLegend}>
                <div
                  style={{
                    height: "12px",
                    width: "12px",
                    borderRadius: "2px",
                    backgroundColor: provider.name === "others" || provider.name === "all_providers" ? "#00A0DE" : provider.color,
                  }}
                ></div>
                <div className={classStyles.providerTitle}>
                  {provider.name === "others" || provider.name === "all_providers" ? "All" : provider.name}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default CardFooter;
