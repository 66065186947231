import React from "react";
import ReactJson from "react-json-view";
import styles from "./styles.module.css";
import { useTheme } from "../../../../store";

const JsonView = ({ jsonObject = {} }) => {
  const { Colors } = useTheme();
  const ThemeObject = {
    base00: Colors.bgListHeader, //background
    // base00: "#ffffff", //background
    base01: "#0062ff",
    base02:"black", //track line and the background of null data type
    // base02: "#ffffff", //track line and the background of null data type
    base03: "#0062ff",
    base04: "#0062ff",
    base05: "#0062ff",
    base06: "#0062ff",
    base07: Colors.fontDark, //object keys and parantheses
    // base07: "#171725", //object keys and parantheses
    base08: "#0062ff",
    base09: "#0062ff", //string color
    base0A: "#ff974a", //Color of null
    base0B: "#0062ff",
    base0C: "#0062ff",
    base0D: "#92929d", //icon
    base0E: "#92929d", //boolean data type closed colapse icon
    base0F: "#ff3838", //5th //500
  };

  return (
    <div className={styles.container}>
      <ReactJson
        src={jsonObject}
        theme={ThemeObject}
        // theme={"colors"}
        indentWidth={10}
        displayDataTypes={false}
        name={null}
        enableClipboard={false}
        displayObjectSize={false}
        displayArrayKey={false}
        className={styles.textStyle}
      />
    </div>
  );
};

export default JsonView;
