import classNames from "classnames";
import React from "react";
import styles from "../styles.module.css";
import { PiPackageLight } from "react-icons/pi";
import { HiOutlineMapPin } from "react-icons/hi2";
import momenttz from "moment-timezone";

import moment from "moment";

export function OneAddressOnSideDrawer({
  isDropoff,
  name,
  eta,
  title,
  address,
  phoneNumber,
  note,
  unitNumber,
}) {
  const _phoneNumber =
    phoneNumber && phoneNumber[0] === "+" ? phoneNumber : "+" + phoneNumber;
  return (
    <div className={classNames("flex items-start mt-10", styles.container)}>
      {!isDropoff && <div className={styles.line} />}
      <div className={styles.iconCont}>
        {isDropoff ? (
          <HiOutlineMapPin className={styles.icon} />
        ) : (
          <PiPackageLight className={styles.icon} />
        )}
      </div>

      <div className={classNames(styles.timesCont)}>
        <div className={styles.fontDark}>{title}</div>
        <div>
          <div>{eta && moment(eta).format("ddd, DD MMM YYYY")}</div>
          <div>
            {eta &&
              moment(eta).format("hh:mm A") +
                "," +
                " " +
                momenttz.tz(momenttz.tz.guess()).format("z")}
          </div>
        </div>
      </div>
      <div className={classNames(styles.detailsCont)}>
        <div className="flex items-start justify-between">
          <div className={classNames(styles.ids, styles.addressHalf)}>
            <div className={styles.fontDark}>{address}</div>
            <div className={styles.fontDark}>{unitNumber}</div>
          </div>
          <div className={classNames(styles.ids, styles.namePhoneHalf)}>
            <div className={styles.fontDark}>{name}</div>
            <div>{_phoneNumber}</div>
          </div>
        </div>
        <div className={classNames(styles.notes, "mt-0")}>{note}</div>
      </div>
    </div>
  );
}
