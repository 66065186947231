import React from "react";
import SingleAddressForModalNew from "../../common/SingleAddressForModalNew";

const StopsDetails = ({ item, statusSocket, name }) => {
  return (
    <div>
      <SingleAddressForModalNew
        index={0}
        address={item?.pickup_address}
        bussinessNmae="Burq"
        name={name}
        note={item?.pickup_notes}
        phoneNumber={item?.pickup_phone_number}
        item={item}
        status={statusSocket}
        unit={item?.pickup_unit || undefined}
      />

      {item.dropoffs?.map((dropOff, index) => {
        return (
          <SingleAddressForModalNew
            item={item}
            length={item?.dropoffs.length}
            key={index}
            index={index + 1}
            address={dropOff.dropoff_address}
            bussinessNmae="Burq"
            name={dropOff?.dropoff_name}
            note={dropOff?.dropoff_notes}
            phoneNumber={dropOff.dropoff_phone_number}
            status={statusSocket}
            unit={item?.dropoff_unit || undefined}
            dropoff
          />
        );
      })}
    </div>
  );
};

export default StopsDetails;
