import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import styles from "./styles/deliveryPreferences.module.css";
import { IoIosInformationCircle } from "react-icons/io";
import CustomTooltip from "../../../../components/shared/atoms/CustomTooltip";
import classNames from "classnames";
import { useMerchant } from "../../../../store";
import { PARTNER_INDUSTRY_OPTIONS } from "../../../../components/ProviderIntegrationSettings/utils";
import { useFlag } from "@unleash/proxy-client-react";

const DeliveryPreferences = ({ quote, rerouteItem }) => {
  const { signature, contactless, undeliverable_actions } = quote.provider_capability || {};
  const { values, setFieldValue } = useFormikContext();
  const { merchantData } = useMerchant();
  const isNoDeliveryPreferenceDisabled = useFlag("isNoDeliveryPreferenceDisabled");
  const shouldAllowSignatureOnly =
    merchantData.standardized_industry === PARTNER_INDUSTRY_OPTIONS.pharmacy.toLowerCase() || values.contains_alcohol;

  const renderRadioOptions = () => {
    const radioOptions = [];

    if (signature) {
      radioOptions.push(
        <label className={styles.radioItem} key="signature">
          <input
            type="radio"
            checked={values.provider_settings.signature || shouldAllowSignatureOnly}
            onChange={() => {
              setFieldValue("provider_settings.signature", true);
              setFieldValue("provider_settings.contactless", false);
              if (Array.isArray(undeliverable_actions) && undeliverable_actions?.includes("return_to_store")) {
                setFieldValue("provider_settings.undeliverable_action", "return_to_store");
              }
            }}
          />
          <div className={styles.fieldLabel}>Signature Required</div>
        </label>
      );
    }

    if (contactless && !shouldAllowSignatureOnly) {
      radioOptions.push(
        <label className={styles.radioItem} key="proofOfDelivery">
          <input
            type="radio"
            checked={values.provider_settings.contactless}
            onChange={() => {
              setFieldValue("provider_settings.signature", false);
              setFieldValue("provider_settings.contactless", true);
            }}
          />
          <div className={styles.fieldLabel}>Contactless (with proof of delivery)</div>
        </label>
      );
    }

    return radioOptions.length ? radioOptions : null;
  };

  // if ((!signature && !contactless) || values.quote_id !== quote.id) {
  //   return <></>;
  // } else {
  //   if (signature && contactless && rerouteItem) {
  //     if (rerouteItem?.signature_requested) {
  //       setFieldValue("provider_settings.signature", true);
  //       setFieldValue("provider_settings.contactless", false);
  //     } else if (rerouteItem?.contactless_requested) {
  //       setFieldValue("provider_settings.signature", false);
  //       setFieldValue("provider_settings.contactless", true);
  //     }
  //   }
  // }

  // useEffect(() => {
  //   console.log("1231231", values.quote_id,"qupteId", quote.id);
  //   if ((!signature && !contactless) || values.quote_id !== quote.id) {
  //     return;
  //   } else {
  //     if (signature && contactless && rerouteItem) {
  //       if (rerouteItem?.signature_requested) {
  //         setFieldValue("provider_settings.signature", true);
  //         setFieldValue("provider_settings.contactless", false);
  //       } else if (rerouteItem?.contactless_requested) {
  //         setFieldValue("provider_settings.signature", false);
  //         setFieldValue("provider_settings.contactless", true);
  //       }
  //     }
  //   }
  // }, [quote, rerouteItem]);

  if ((!signature && !contactless) || values.quote_id !== quote.id) return null;

  return (
    <div
      className={styles.preferenceSection}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className={styles.header}>
        Delivery Preference
        <CustomTooltip
          extraTooltipClasses={styles.tooltipExtras}
          content={
            <div className={styles.contentContainer}>
              <div className={styles.contentTitles}>Signature Required:</div>
              <div className={styles.contentDescs}>The provider will be unable to complete this delivery without a signature</div>
              <div className={classNames(styles.contentTitles, "mt-3")}>Contactless:</div>
              <div className={styles.contentDescs}>A proof of delivery will be mandatory to complete this order.</div>
            </div>
          }
          extraContainerClasses="ml-2"
        >
          <span className={styles.infoIcon}>
            <IoIosInformationCircle />
          </span>
        </CustomTooltip>
      </div>
      <div className={styles.radioItems}>
        {!shouldAllowSignatureOnly && !isNoDeliveryPreferenceDisabled && (
          <label className={styles.radioItem} key="noPreferences">
            <input
              type="radio"
              checked={!values.provider_settings.signature && !values.provider_settings.contactless}
              onChange={() => {
                setFieldValue("provider_settings.signature", false);
                setFieldValue("provider_settings.contactless", false);
              }}
            />
            <div className={styles.fieldLabel}>No Preferences</div>
          </label>
        )}
        {renderRadioOptions()}
      </div>
    </div>
  );
};

export default DeliveryPreferences;
