import React, { useEffect, useState } from "react";
import { useIncidents, useMerchantIncidents, useTheme } from "../../../../store";
import { useDesktopOrLaptop } from "../../../../services/useDesktopOrLaptop";
import ButtonComp from "../../../ButtonComp/ButtonComp";
import DropdownReusable from "../../../shared/common/DropdownReusable";
import styles from "./styles.module.css";
import classNames from "classnames";

const CreateIncident = () => {
  const [internalNote, setInternalNote] = useState();
  const [selectedReason, setSelectedReason] = useState();
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);
  const [showIncidentReasonError, setShowIncidentReasonError] = useState(false);

  const [incidentReasons, setIncidentReasons] = useState([]);
  const { mdfontsize } = useDesktopOrLaptop();
  const { Colors, themeChanger } = useTheme();

  const { getIncidentReasonsHandler } = useMerchantIncidents();
  const { setIsCreateEditIncidentModalOpen, selectedDeliveryForIncident, createIncidentHandler, setUpdatingIncidentIndex } =
    useIncidents();
  useEffect(async () => {
    const data = await getIncidentReasonsHandler();
    if (data) {
      setIncidentReasons(data.data);
    }
  }, []);

  return (
    <div className="w-full flex flex-col px-8 pb-5">
      <div
        style={{
          fontSize: mdfontsize,
          color: Colors.fontDark,
          marginTop: "20px",
        }}
      >
        Select Incident Reason:
      </div>
      <DropdownReusable
        showCleaner={false}
        onChange={(e) => {
          setShowIncidentReasonError(false);
        }}
        selectedItem={incidentReasons?.find((item) => item?.id === selectedReason)?.reason}
        setSelectedItem={(value) => {
          const reasonId = incidentReasons.find((r) => r.reason === value)?.id;
          setSelectedReason(reasonId);
        }}
        items={incidentReasons.map((reason) => reason.reason)}
        placeHolder="Select Reason"
        extraContainerClasses={styles.incidentReasonDropDownComponent}
        inputExtras={classNames(styles.dropdownInput)}
        extraPlaceholderStyles={styles.placeholderStyle}
        isDropdownOpened={isDropdownOpened}
        setIsDropdownOpened={setIsDropdownOpened}
        extraDropDownContentStyles={styles.incidentReasonDropDownContentContainer}
      ></DropdownReusable>
      {showIncidentReasonError && <span className={styles.incidentReasonError}>Please Select Reason</span>}
      <div
        style={{
          fontSize: mdfontsize,
          color: Colors.fontDark,
          marginTop: "20px",
        }}
      >
        Add Some Note:
      </div>
      <textarea
        maxLength={"300"}
        className={`${themeChanger ? "focus:border-gray-400" : "focus:border-gray-300"}  border border-transparent`}
        value={internalNote}
        style={{
          padding: "10px 20px",
          width: "100%",
          outline: "none",
          backgroundColor: Colors.borderPrimary,
          resize: "none",
          maxHeight: "300px",
          borderRadius: "10px",
          marginTop: "20px",
        }}
        onChange={(e) => {
          setInternalNote(e.target.value);
        }}
        rows="3"
        placeholder={"Your Note goes here"}
      ></textarea>
      <div className="flex w-full justify-end mt-8">
        <ButtonComp
          primary
          title={"Create"}
          onClick={async () => {
            if (!selectedReason) {
              setShowIncidentReasonError(true);
            } else {
              setShowIncidentReasonError(false);
              const res = await createIncidentHandler({
                delivery_id: selectedDeliveryForIncident.id,
                internal_note: internalNote ?? "",
                delivery_incident_reason: selectedReason,
              });
              if (res) {
                setIsCreateEditIncidentModalOpen(false);

                setUpdatingIncidentIndex();
              }
            }
          }}
        />
      </div>
    </div>
  );
};

export default CreateIncident;
