import { useEffect } from "react";

import created from "../../../../assets/png/statuses/created.png";
import delivered from "../../../../assets/png/statuses/delivered.png";
import dropoff from "../../../../assets/png/statuses/dropoff.png";
import pickupPng from "../../../../assets/png/statuses/pickup.png";
import scheduled from "../../../../assets/png/statuses/scheduled.png";
import returnPng from "../../../../assets/png/statuses/return.png";
import cancel from "../../../../assets/png/statuses/cancel.png";
import contactingProvider from "../../../../assets/png/statuses/contactingProvider.png";

import {
  useMyStore,
  userDataStore,
  useTheme,
  useWorkflow,
} from "../../../../store";
import { useDesktopOrLaptop } from "../../../../services/useDesktopOrLaptop";
import { StarIcon } from "../../../../assets/svgs/Svgs";
import { useFlag } from "@unleash/proxy-client-react";

function TemporaryArea() {
  const { Colors } = useTheme();
  const { userId } = useMyStore();
  const { isdesktop, smfontsize } = useDesktopOrLaptop();

  const {
    setSelectedStatus,
    selectedStatus,
    getSmsSettings,
    configuredSettings,
    deleteSmsSetting,
    getSmsworkflowHandler,
  } = useWorkflow();
  const { authInfo } = userDataStore();

  useEffect(() => {
    // savedSettingshandler(userId, auth);
  }, []);

  return (
    <div
      className="flex-wrap"
      style={{
        width: isdesktop ? `calc(100% - 440px)` : "100%",
        display: "flex",
        overflowY: "auto",
        maxHeight: "100%",
        padding: "30px 0px",
        justifyContent: !isdesktop && "center",
      }}
    >
      {configuredSettings?.map((item, index) => {
        return (
          <div
            onClick={() => {
              setSelectedStatus(item);
            }}
            type="button"
            key={index}
            style={{
              margin: "10px",
              width: isdesktop ? "190px" : "210px",
              height: isdesktop ? "110px" : "130px",
              marginBottom: "20px",
              borderRadius: "10px",
              borderWidth: "1px",
              borderColor:
                selectedStatus?.name === item?.name
                  ? Colors.bluePrimary
                  : Colors.borderSecondary,
              backgroundColor: Colors.detailsBg,
              position: "relative",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "column",
              padding: "0px 0px 10px 0px",
            }}
          >
            <button
              onClick={(e) => {
                deleteSmsSetting(item.id).then(() => {
                  getSmsSettings(userId, authInfo?.merchantAccountId);
                  getSmsworkflowHandler(userId, authInfo?.merchantAccountId);
                });
                setSelectedStatus();
                e.stopPropagation();
              }}
              className="myShadow kata"
              style={{
                position: "absolute",
                top: "-13px",
                right: "-13px",
                zIndex: 2,
                height: "26px",
                width: "26px",
                borderRadius: "15px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: isdesktop ? Colors.bgGrey : Colors.white,
                color: Colors.fontDark,
              }}
            >
              -
            </button>
            {item.name === "pending" ||
            item.name === "Delivery Created" ||
            item.name === "Driver Assigned" ||
            item.name === "Assigning Driver" ? (
              <img
                src={created}
                alt="logo"
                style={{
                  width: "54px",
                  height: "54px",
                  marginTop: "10px",
                }}
              />
            ) : item.name === "Canceled" ||
              item.name === "Provider Canceled" ||
              item.name === "Merchant Canceled" ||
              item.name === "Burq Canceled" ||
              item.name === "Expired" ||
              item.name === "Failed" ||
              item.name === "Disputed" ? (
              <img
                src={cancel}
                alt="logo"
                style={{
                  width: "54px",
                  height: "54px",
                  marginTop: "10px",
                }}
              />
            ) : item.name === "Delivered" ? (
              <img
                src={delivered}
                alt="logo"
                style={{
                  width: "54px",
                  height: "54px",
                  marginTop: "10px",
                }}
              />
            ) : item.name === "pickup" ||
              item.name === "Enroute to Pick-up" ||
              item.name === "Arrived at Pick-up" ||
              item.name === "Pick-up Complete" ||
              item.name === "Pickup Complete" ? (
              <img
                src={pickupPng}
                alt="logo"
                style={{
                  width: "54px",
                  height: "54px",
                  marginTop: "10px",
                }}
              />
            ) : item.name === "dropoff" ||
              item.name === "Enroute to Drop-off" ||
              item.name === "Arrived at Drop-off" ||
              item.name === "Drop off Completed" ? (
              <img
                src={dropoff}
                alt="logo"
                style={{
                  width: "54px",
                  height: "54px",
                  marginTop: "10px",
                }}
              />
            ) : item.name === "Enroute to Return" ||
              item.name === "Returned" ? (
              <img
                src={returnPng}
                alt="logo"
                style={{
                  width: "54px",
                  height: "54px",
                  marginTop: "10px",
                }}
              />
            ) : item.name === "Feedback" ? (
              <div style={{ marginTop: "20px" }}>
                <StarIcon size="38" />
              </div>
            ) : item.name === "Contacting Provider" ? (
              <img
                src={contactingProvider}
                alt="logo"
                style={{
                  width: "54px",
                  height: "54px",
                  marginTop: "10px",
                }}
              />
            ) : (
              <img
                src={scheduled}
                alt="logo"
                style={{
                  width: "54px",
                  height: "54px",
                  marginTop: "10px",
                }}
              />
            )}
            <div
              style={{
                color: Colors.fontDark,
                marginTop: "6px",
                fontSize: smfontsize,
              }}
            >
              {item.name}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default TemporaryArea;
