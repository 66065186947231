import classNames from "classnames";
import React from "react";
import styles from "./styles.module.css";

const TableHeader = () => {
  return (
    <div
      className={classNames(
        "grid grid-cols-12 gap-1 w-full pb-2 items-center",
        styles.headerCont
      )}
    >
      <div
        className={classNames(
          "col-start-1 col-span-4 text-center flex justify-center items-center",
          styles.headerTexts
        )}
      >
        Delivery Id
      </div>
      <div
        className={classNames(
          "col-start-5 col-span-4 text-center",
          styles.headerTexts
        )}
      >
        Date
      </div>
      <div
        className={classNames(
          "col-start-10 col-span-2 text-center",
          styles.headerTexts
        )}
      >
        Amount due
      </div>
    </div>
  );
};

export default TableHeader;
