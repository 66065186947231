export const FILTER_TYPES = {
  TYPE_AND_SEARCH: "TYPE_AND_SEARCH",
  RADIO: "RADIO",
  SEARCH: "SEARCH",
};
export const LearnMoreLink =
  "https://support.stripe.com/questions/i-cant-find-the-micro-deposit-verification-code-in-my-bank-statement-descriptor-what-should-i-do";

export const costLineItemDisplayNames = {
  DELIVERY_FEE: "Delivery Fee",
  TRANSACTION_FEE: "Transaction Fee",
  REFERRAL_COMMISSION: "Referral Commission",
  TIP: "Tip",
};
