import React, { useState } from "react";
import { useFilter, useStoreAndChild, useTheme } from "../../store";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import moment from "moment";
import DatePicker, { utils } from "react-modern-calendar-datepicker";
import { AiOutlineClear } from "react-icons/ai";
import { BiCalendar } from "react-icons/bi";
import styles from "./styles/dateRangeComp.module.css";

const DateRangeComponent = ({ dateRangeHandler }) => {
  const { isdesktop } = useDesktopOrLaptop();
  const { Colors } = useTheme();
  const { filterStore, filterArray, startDate, setStartDate, search } = useFilter();

  const defaultFrom = {
    year: parseInt(moment().format("YYYY")),
    month: parseInt(moment().format("M")),
    day: parseInt(moment().format("D")),
  };
  const defaultTo = {
    year: parseInt(moment().format("YYYY")),
    month: parseInt(moment().format("M")),
    day: parseInt(moment().format("D")),
  };
  // const defaultValue = {
  //   from: defaultFrom,
  //   to: defaultTo,
  // };
  const defaultValue = {
    from: null,
    to: null,
  };

  // const [selectedDay, setSelectedDay] = useState();
  const [selectedDay, setSelectedDay] = useState(defaultValue);

  const formatInputValue = () => {
    if (!selectedDay?.from?.day || !selectedDay?.to?.day) return "From - To";
    // if (!selectedDay?.from?.day || !selectedDay?.to?.day || !startDate) return "From - To";

    return `${selectedDay?.from?.month}/${selectedDay?.from?.day}-${
      selectedDay?.to ? selectedDay.to.month + "/" + selectedDay.to.day : ""
    }`;
  };

  const renderCustomInput = ({ ref }) => (
    <input
      id="calendarPickerId"
      readOnly
      ref={ref} // necessary
      placeholder="Date"
      value={formatInputValue()}
      style={{
        fontFamily: "Poppins",
        fontSize: "12px",
        fontWeight: "400",
        backgroundColor: Colors.white,
        color: formatInputValue() === "From - To" ? "#92929d" : "#0062ff",
        textAlign: "center",
        width: !isdesktop ? "120px" : "80px",
      }}
      className="focus:outline-none placeholder-gray-800" // a styling class
    />
  );
  return (
    <div style={{ marginLeft: "0px" }} className="flex flex-row items-center">
      <div className={styles.container}>
        {selectedDay.from ? (
          <button
            type="button"
            onClick={() => {
              setSelectedDay(defaultValue);
              dateRangeHandler();
            }}
            style={{}}
          >
            <AiOutlineClear color={"#0062ff"} size={22} />
          </button>
        ) : (
          <BiCalendar color={Colors.fontGreyLight} size={22} />
        )}
        <div
          style={{
            // fontSize: isdesktop ? "13px" : "20px",
            fontSize: "13px",
            color: Colors.fontGreyLight,
            marginRight: isdesktop ? "1px" : "0px",
            marginLeft: "10px",
            whiteSpace: "nowrap",
          }}
        >
          Date range:
        </div>
        {true && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <DatePicker
              inputClassName="z-0"
              calendarPopperPosition="bottom"
              renderInput={renderCustomInput}
              value={selectedDay ? selectedDay : utils().getToday()}
              colorPrimary="#3DD598" // added this
              colorPrimaryLight="#ECFBF5"
              // and this
              onChange={(day) => {
                setSelectedDay(day);
                if (day.from && day.to) {
                  let date = {
                    start: `${day.from.year}-${day.from.month}-${day.from.day}`,
                    end: `${day.to.year}-${day.to.month}-${day.to.day}`,
                  };
                  dateRangeHandler(date);
                }
              }}
              // inputPlaceholder="From - To"
              maximumDate={utils().getToday()}
              formatInputText={formatInputValue}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DateRangeComponent;
