import { useMediaQuery } from "react-responsive";
import { useMyStore } from "../store";

export const useDesktopOrLaptop = () => {
  const { isScalingDisabled } = useMyStore();
  const isdesktop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const xxsfontsize = isdesktop ? "10px" : "16px";
  const xsfontsize = isdesktop ? "12px" : "18px";
  const smfontsize = isdesktop ? "14px" : "22px";
  const mdfontsize = isdesktop ? "16px" : "24px";
  const lgfontsize = isdesktop ? "18px" : "28px";
  const xlfontsize = isdesktop ? "20px" : "32px";
  const px24 = isdesktop ? "24px" : "34px";
  const xxlfontsize = isdesktop ? "28px" : "36px";

  return {
    isScalingDisabled,
    isdesktop,
    smfontsize,
    mdfontsize,
    lgfontsize,
    xlfontsize,
    xsfontsize,
    xxlfontsize,
    xxsfontsize,
    px24,
  };
};
