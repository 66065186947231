import React from "react";

function ReturnedIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <g
        fill="#0062FF"
        fillRule="evenodd"
        clipPath="url(#clip0_12850_8520)"
        clipRule="evenodd"
      >
        <path d="M9.065.71a.627.627 0 00-.63 0l-7.5 4.375a.625.625 0 000 1.08l7.5 4.375a.627.627 0 00.63 0l7.5-4.375a.625.625 0 000-1.08L9.065.71zM8.75 1.974l6.26 3.651-6.26 3.651-6.26-3.651 6.26-3.651z"></path>
        <path d="M8.125 10v8.75a.625.625 0 001.25 0V10a.625.625 0 00-1.25 0zM.625 5.625v8.75a.625.625 0 001.25 0v-8.75a.625.625 0 00-1.25 0zM15.625 5.625v6.25a.625.625 0 001.25 0v-6.25a.625.625 0 00-1.25 0z"></path>
        <path d="M.935 14.915l7.5 4.375a.625.625 0 00.63-1.08l-7.5-4.375a.625.625 0 00-.63 1.08z"></path>
        <path d="M10.286 17.13l-1.851 1.08a.625.625 0 00.63 1.08l1.85-1.08a.625.625 0 10-.629-1.08zM5.44 8.392l7.064-4.63a.626.626 0 00-.686-1.045l-7.063 4.63a.625.625 0 10.685 1.045zM18.125 14.375h-4.05a2.2 2.2 0 00-2.2 2.2v.6a2.2 2.2 0 002.2 2.2h4.675a.625.625 0 000-1.25h-4.675a.95.95 0 01-.95-.95v-.6a.95.95 0 01.95-.95h4.05a.625.625 0 000-1.25z"></path>
        <path d="M17.058 14.192l.808.808-.808.808a.625.625 0 00.884.884l1.25-1.25a.624.624 0 000-.884l-1.25-1.25a.625.625 0 00-.884.884z"></path>
      </g>
      <defs>
        <clipPath id="clip0_12850_8520">
          <path fill="#fff" d="M0 0H20V20H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default ReturnedIcon;
