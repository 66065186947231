import { createStore } from "aniuta";
import { useState } from "react";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";
import { createCSVJobCall, downloadCSV } from "../utils/api/deliveriesCsv";

const useCsvDeliveries = createStore({
  name: "useCsvDeliveries",
  Store: () => {
    const [selectedUserForCsv, setSelectedUserForCsv] = useState();

    const createCSVJob = async (userId, params = {}, setIsDownloadLoading) => {
      try {
        const { data } = await createCSVJobCall(userId, params);
        return data;
      } catch (error) {
        setIsDownloadLoading(false);
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
      }
    };

    const downloadDeliveryCSV = async (jobId) => {
      try {
        const { data } = await downloadCSV(jobId);
        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
      }
    };

    return {
      selectedUserForCsv,
      setSelectedUserForCsv,
      createCSVJob,
      downloadDeliveryCSV,
    };
  },
});

export default useCsvDeliveries;
