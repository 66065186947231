import React from "react";
import classNames from "classnames";
import { Loading } from "../../../../assets/svgs/Svgs";
import styles from "./styles.module.css";

const Loader = ({ extraClassName = null, color }) => {
  return (
    <div
      className={classNames(
        "w-full flex items-center justify-center kata",
        styles.container
      )}
    >
      <Loading
        color={color ? color : "#0062ff"}
        className={classNames("h-14 w-14 animate-spin", {
          [extraClassName]: extraClassName,
        })}
      />
    </div>
  );
};

export default Loader;
