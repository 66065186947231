import React, { useMemo } from "react";
import { useTheme } from "../../../../store";
import HeaderTabs from "./HeaderTabs";

const OverviewHeader = () => {
  const { Colors } = useTheme();

  const styles = useMemo(() => {
    return {
      container: {
        display: "flex",
        justifyContent: "space-between",
        allignItem: "center",
      },
      divider: {
        borderTop: `1px solid ${Colors.chartSecondLine}`,
        opacity: "0.2",
      },
    };
  }, [Colors]);

  return (
    <div>
      <div style={styles.container}>
        <HeaderTabs />
      </div>
      <hr style={styles.divider} />
    </div>
  );
};

export default OverviewHeader;
