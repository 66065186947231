import React, { useCallback, useEffect, useState } from "react";

import { useIncidents, useMyStore, useTheme } from "../../../store";
import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";

import Pagination from "../../shared/Pagination";
import { Loading, SearchSvg } from "../../../assets/svgs/Svgs";
import { debounce } from "lodash";
import DeliveryIncidentItem from "./DeliveryIncidentItem";
import DropdownReusable from "../../shared/common/DropdownReusable";
import { AiFillCheckCircle } from "react-icons/ai";
import { BiCheckbox, BiCheckboxChecked } from "react-icons/bi";
import { FILTER_TYPES } from "../../../constants";
import FiltersMenu from "../../shared/common/FiltersMenu";
import ActiveFiltersBar from "../../shared/common/FiltersMenu/ActiveFiltersBar";

const LIMIT_ON_PAGE = 30;
const STATUSES = ["pending", "processing", "resolved"];

const DeliveryIncident = () => {
  const {
    getAllIncidents,
    incidents,
    isIncidentsLoading,
    incidentStatusFilter,
    setIncidentStatusFilter,
    updateList,
    searchedId,
    setSearchedId,
    isAssignee,
    setIsAssignee,
    searchedShortId,
    setSearchedShortId,
    searchedDropoffName,
    setSearchedDropoffName,
    searchedIncidentId,
    setSearchedIncidentId,
  } = useIncidents();
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);

  const { isSandbox } = useMyStore();
  const { smfontsize } = useDesktopOrLaptop();
  const { Colors } = useTheme();

  const getAllIncidentsHandler = async (params = {}) => {
    await getAllIncidents({
      ...{
        test_mode: isSandbox,
        limit: LIMIT_ON_PAGE,
        ending_before: params?.ending_before || "",
        starting_after: params?.starting_after || "",
        // status: incidentStatusFilter,
        // assignee: isAssignee,
      },
      ...params,
    });
  };

  const debounceFn = useCallback(
    debounce((value) => {
      setSearchedId(value);
    }, 800),
    []
  );
  const filtersDefinition = [
    {
      id: "search_by_del_id",
      title: "By Delivery Id",
      type: FILTER_TYPES.SEARCH,
      placeholder: "Delivery Id",
      filterState: searchedId,
      setFilterState: (searchedId) => {
        setSearchedId(searchedId);
        getAllIncidentsHandler({ delivery_id: searchedId });
      },
    },
    {
      id: "search_by_short_id",
      title: "By Provider Id",
      type: FILTER_TYPES.SEARCH,
      placeholder: "Provider Id",
      filterState: searchedShortId,
      setFilterState: (id) => {
        setSearchedShortId(id);
        getAllIncidentsHandler({ short_id: id });
      },
    },
    {
      id: "search_by_dropoff_name",
      title: "By Dropoff Name",
      type: FILTER_TYPES.SEARCH,
      placeholder: "Dropoff Name",
      filterState: searchedDropoffName,
      setFilterState: (dropoffName) => {
        setSearchedDropoffName(dropoffName);
        getAllIncidentsHandler({ dropoff_name: dropoffName });
      },
    },
    {
      id: "search_by_incident_id",
      title: "By Incident Id",
      type: FILTER_TYPES.SEARCH,
      placeholder: "Incident Id",
      filterState: searchedIncidentId,
      setFilterState: (incidentId) => {
        setSearchedIncidentId(incidentId);
        getAllIncidentsHandler({ incident_id: incidentId });
      },
    },
  ];

  useEffect(() => {
    return () => {
      setSearchedId("");
    };
  }, []);

  useEffect(() => {
    getAllIncidentsHandler();
  }, [isSandbox]);

  return (
    <div>
      <div className="flex w-full justify-between">
        <div style={{ width: `calc(100% - 420px)` }} className="flex items-center">
          <div style={{ minWidth: "130px" }}>
            <FiltersMenu menuItems={filtersDefinition} title="Search by" />
          </div>
          <div
            className="noScroll"
            style={{
              maxWidth: `calc(100% - 130px)`,
              overflowX: "scroll",
            }}
          >
            <ActiveFiltersBar activeFilters={[...filtersDefinition]} />
          </div>
        </div>
        <div className="flex items-center w-min">
          <div
            onClick={() => {
              if (isAssignee === "") {
                setIsAssignee("self");
                getAllIncidentsHandler({ assignee: "self" });
              } else {
                setIsAssignee("");
                getAllIncidentsHandler({ assignee: "" });
              }
            }}
            className="cursor-pointer whitespace-nowrap "
            style={{
              padding: "10px 10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              marginRight: "10px",
              color: isAssignee === "self" ? Colors.bluePrimary : Colors.fontDark,
            }}
          >
            {isAssignee === "self" ? <BiCheckboxChecked size={24} color={Colors.bluePrimary} /> : <BiCheckbox size={24} />}
            <div>Assigned to me</div>
          </div>
          <DropdownReusable
            selectedItem={incidentStatusFilter}
            setSelectedItem={setIncidentStatusFilter}
            items={STATUSES}
            isDropdownOpened={isDropdownOpened}
            setIsDropdownOpened={setIsDropdownOpened}
            onChange={(statusItem) => {
              getAllIncidentsHandler({
                status: statusItem?.item,
                test_mode: statusItem?.isSandbox,
              });
            }}
          />
          {/* <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
            style={{
              borderWidth: "1px",
              borderRadius: "6px",
              borderColor: Colors.borderPrimary,
              backgroundColor: Colors.bgSettings,
              padding: "10px 10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              minWidth: "330px",
              marginRight: "10px",
            }}
          >
            <SearchSvg />
            <input
              type="search"
              placeholder="Delivery Id"
              onChange={(event) => {
                debounceFn(event.target.value ?? "");
              }}
              style={{
                outline: "none",
                width: "100%",
                height: "100%",
                marginLeft: "10px",
                backgroundColor: "transparent",
                color: Colors.fontGreyLight,
                fontSize: smfontsize,
              }}
            />
          </form> */}
        </div>
      </div>
      <div
        style={{
          borderBottomWidth: "1.5px",
          borderColor: Colors.borderPrimary,
          fontSize: smfontsize,
          fontWeight: "600",
          color: Colors.fontDark,
        }}
        className="grid grid-cols-12 gap-1 w-full py-4 "
      >
        <div className="col-start-1 col-span-1 ">Assigned User</div>
        <div className="col-start-2 col-span-1">Delivery ID</div>
        <div className="col-start-3 col-span-1 text-center">Delivery Fee and Tip Refund</div>
        <div className="col-start-4 col-span-1 text-center">Retrun Fee Refund</div>
        <div className="col-start-5 col-span-1 text-center">Status</div>
        <div className="col-start-6 col-span-1 text-center">Order Value Refund</div>
        <div className="col-start-7 col-span-1 text-center">Provider Refund</div>
        <div className="col-start-8 col-span-1 text-center">Created At</div>
        <div className="col-start-9 col-span-1 text-center">Rerouted</div>
        <div className="col-start-10 col-span-2 text-center">Internal Note</div>
        <div className="col-start-12 col-span-1 text-center">Action</div>
      </div>
      {isIncidentsLoading && (
        <div style={{ minHeight: "100px" }} className="w-full  flex items-center justify-center kata">
          <Loading color={Colors.bluePrimary} className="h-14 w-14 animate-spin" />
        </div>
      )}
      {incidents?.data?.map((incident, index) => {
        return (
          <DeliveryIncidentItem
            searchedId={searchedId}
            incidentsPaginationHandler={getAllIncidentsHandler}
            incident={incident}
            key={incident.id}
            index={index}
          />
        );
      })}
      <div className="flex w-full justify-end mb-16">
        {/* {incidents && ( */}
        <Pagination
          data={incidents}
          onGetPaginationData={getAllIncidentsHandler}
          dependencies={[isSandbox, searchedId, incidentStatusFilter, updateList]}
        />
        {/* )} */}
      </div>
    </div>
  );
};

export default DeliveryIncident;
