import React from "react";
import { useConnectedAccounts, useTheme, userDataStore } from "../../store";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import ButtonComp from "../ButtonComp/ButtonComp";

const DeleteAccountModal = ({ onDeleteCallback }) => {
  const { Colors } = useTheme();
  const { isdesktop, mdfontsize } = useDesktopOrLaptop();

  const {
    setIsDeleteAccountModalVisible,
    setAccountForEditing,
    accountForEditing,
    deleteAccountHandler,
  } = useConnectedAccounts();
  const { authInfo } = userDataStore();

  return (
    <div
      style={{
        borderRadius: "8px",
        padding: "30px 10px 60px 10px",

        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div className="w-full mr-16 mb-8 flex justify-end">
        <button
          onClick={() => {
            setIsDeleteAccountModalVisible(false);
            setAccountForEditing();
          }}
          type="button"
          style={{
            color: Colors?.fontDark,
            fontSize: isdesktop ? "28px" : "36px",
          }}
          className="material-icons outline-none"
        >
          close
        </button>
      </div>
      <div
        style={{
          color: Colors.fontDark,
          fontSize: mdfontsize,
          fontWeight: "500",
        }}
        className={`w-3/4 text-center`}
      >
        Account deletion will permanently delete all stores and users under this
        account. Once deleted, owners of the account can no longer log into the
        dashboard. Do you want to continue with deletion of this account?
      </div>
      <div className="w-3/4 flex justify-evenly items-center mt-10">
        <ButtonComp
          title={"Delete Account"}
          primary
          onClick={async () => {
            const res = await deleteAccountHandler(accountForEditing.id);
            if (!res) {
              onDeleteCallback()
              // searchHandler(
              //   authInfo.merchantAccountId,
              //   email,
              //   merchantId
              // );
              setIsDeleteAccountModalVisible(false);
              setAccountForEditing();
            }
          }}
        />
        <ButtonComp
          title={"Cancel"}
          extraStyles={{ color: Colors.fontDark }}
          onClick={() => {
            setIsDeleteAccountModalVisible(false);
            setAccountForEditing();
          }}
        />
      </div>
    </div>
  );
};

export default DeleteAccountModal;
