import React, { useState } from "react";

import classNames from "classnames";
import styles from "./styles.module.css";
import { ImagePreviewPopup } from "../../../shared/common/ImagePreviewPopup";

function PODTracking({ images, title }) {
  const [imageUrl, setImageUrl] = useState();

  return (
    <div className={classNames("flex flex-col w-full mt-6", styles.cont)}>
      <ImagePreviewPopup
        imageUrl={imageUrl}
        isOpen={!!imageUrl}
        onClose={() => setImageUrl()}
      />
      {/* <div
        className={classNames(
          styles.download,
          "flex items-center justify-between w-full"
        )}
      >
        <p className={styles.imagesTitle}> {title}</p>
        <button
          className={styles.downloadButton}
          onClick={() => onDownloadImages(id, title === "Signature")}
        >
          Download
        </button>
      </div> */}
      <div className={styles.pod}>
        {/* {typeof images === "string" ? "Signature" : "Proof of delivery"} */}
        {title}
      </div>
      <div
        className={classNames(
          "noScroll flex items-center overflow-x-auto",
          styles.imagesContainer
        )}
      >
        {typeof images === "string" ? (
          <a href={images} target="_blank" rel="noreferrer">
            <img
              className={classNames(
                "object-cover mt-1 overflow-hidden rounded-xl mr-7",
                styles.images
              )}
              alt=""
              src={images}
            />
          </a>
        ) : (
          images?.map((url, index) => {
            return (
              <div
                onClick={() => {
                  setImageUrl(url);
                }}
                className="cursor-pointer"
              >
                {/* <a
                  key={index}
                  href="https://burq-proof-of-delivery.s3.amazonaws.com/proof_of_delivery_d_2040555c-4445-4b08-9edb-b2e2c416bacc_1696788771.jpeg"
                  target="_blank"
                  rel="noreferrer"
                > */}
                <img
                  className={classNames(
                    "object-cover mt-1 overflow-hidden rounded-xl mr-7",
                    styles.images
                  )}
                  alt=""
                  src={url}
                />
                {/* </a> */}
              </div>
            );
          })
        )}
      </div>
    </div>
  );
}

export default PODTracking;
