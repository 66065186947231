import axios from "axios";
import apiUrls from "../../constants/apiUrls";
import { generateQueryParams } from "./utils";

export const getIncidents = (params) => {
  const urlWithQueryParams = generateQueryParams(apiUrls.DELIVERY_INCIDENT, params, {
    headers: { "x-burq-client": "client-dashboard" },
  });
  return axios.get(urlWithQueryParams);
};

export const createIncident = (payload) =>
  axios.post(apiUrls.DELIVERY_INCIDENT, payload, {
    headers: { "x-burq-client": "client-dashboard" },
  });

export const updateIncident = (incidentId, payload) =>
  axios.patch(apiUrls.UPDATE_DELIVERY_INCIDENT(incidentId), payload, {
    headers: { "x-burq-client": "client-dashboard" },
  });

export const assigneAdminToIncident = (incidentId) =>
  axios.put(apiUrls.ASSIGN_ADMIN_TO_INCIDENT(incidentId), {
    headers: { "x-burq-client": "client-dashboard" },
  });

export const getIncidentPresignedUrl = (params) => {
  const urlWithQueryParams = generateQueryParams(apiUrls.INCIDENT_PRESIGNED_URL, params, {
    headers: { "x-burq-client": "client-dashboard" },
  });
  return axios.get(urlWithQueryParams);
};
