import { useEffect } from 'react';
import { useMyStore } from '../store';

const useViewportMeta = () => {
  const { setIsScalingDisabled } = useMyStore();
  useEffect(() => {
    setIsScalingDisabled(true);
    let meta = document.querySelector("meta[name~='viewport']");
    if (!meta) {
      meta = document.createElement("meta");
      meta.name = "viewport";
      document.getElementsByTagName("head")[0].appendChild(meta);
    }
    meta.content = "width=device-width, initial-scale=1";

    return () => {
      meta.content = "width=1000px, user-scalable=1";
      setIsScalingDisabled(false);
    };
  }, []);
};

export default useViewportMeta;
