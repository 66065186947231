export const notificationsSettingsObject = {
  payment_failed: {
    type: "alert",
    label: "Payment Failed",
    description: "Receive a notification if a payment is marked as failed",
  },
  payment_card_expired: {
    type: "warning",
    label: "Card on File Expired",
    description:
      "Receive a notification if a delivery is failed due to expiration of linked card",
  },
  delivery_provider_canceled: {
    type: "alert",
    label: "Provider Canceled",
    description:
      "Receive a notification if a delivery is marked as canceled by the provider",
  },
  delivery_incident_result: {
    type: "alert",
    label: "Incident Result",
    description: "Receive a notification if a delivery incident is resolved",
  },
  delivery_reroute: {
    type: "info",
    label: "Rerouted Delivery",
    description:
      "Receive a notification if a delivery is successfully rerouted",
  },
};
