import React, { useMemo } from "react";
import { useDesktopOrLaptop } from "../../../../../services/useDesktopOrLaptop";
import { useTheme } from "../../../../../store";
import { getFormatedValue } from "../../../utils";

const DataComparisonSection = ({ lineChart, hoveredState, valueFormat }) => {
  const { xsfontsize, mdfontsize } = useDesktopOrLaptop();
  const { Colors } = useTheme();

  const styles = useMemo(() => {
    return {
      container: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "40px",
      },
      monthText: {
        fontFamily: "Poppins",
        fontWeight: 600,
        fontSize: xsfontsize,
        color: Colors.chartMonth,
        marginBottom: "0px",
      },
      monthValueCard: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        height: "100%",
      },
      monthValue: {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: mdfontsize,
        color: Colors.bluePrimary,
        margin: "0px",
      },
    };
  }, [Colors, mdfontsize, xsfontsize]);

  if (!lineChart?.data?.length) return null;
  if (hoveredState.isHover)
    return (
      <div style={styles.container}>
        <div style={{ ...styles.monthValueCard }}>
          <p style={styles.monthValue}>
            {getFormatedValue(
              hoveredState.hoverPayload.primaryDataValue,
              valueFormat
            )}
          </p>
          <p style={styles.monthText}>
            {hoveredState.hoverPayload.primaryDataKey}
          </p>
        </div>
        <div style={styles.monthValueCard}>
          <p style={{ ...styles.monthValue, color: Colors.chartMonth }}>
            {getFormatedValue(
              hoveredState.hoverPayload.secondaryDataValue,
              valueFormat
            )}
          </p>
          <p style={styles.monthText}>
            {hoveredState.hoverPayload.secondaryDataKey}
          </p>
        </div>
      </div>
    );
  return (
    <div style={styles.container}>
      <div style={{ ...styles.monthValueCard }}>
        <p style={styles.monthValue}>
          {getFormatedValue(lineChart?.primaryRangeTotal, valueFormat)}
        </p>
        <p style={{ ...styles.monthText, visibility: "hidden" }}>-</p>
      </div>
      <div style={styles.monthValueCard}>
        <p style={{ ...styles.monthValue, color: Colors.chartMonth }}>
          {getFormatedValue(lineChart?.secondaryRangeTotal, valueFormat)}
        </p>
        <p style={{ ...styles.monthText, visibility: "hidden" }}>-</p>
      </div>
    </div>
  );
};

export default DataComparisonSection;
