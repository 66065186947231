import { debounce } from "lodash";
import React, { useRef, useCallback, useState, useEffect } from "react";
import { CheckIcon, SearchSvg } from "../../../../assets/svgs/Svgs";
import { useDesktopOrLaptop } from "../../../../services/useDesktopOrLaptop";

import {
  useClickStore,
  useFilter,
  useMyStore,
  userDataStore,
  useStoreAndChild,
  useTheme,
} from "../../../../store";
import ToolTip from "../../../Tooltip/Tooltip";

function StoresFilterOnListHeader({
  isStoresListVisible,
  setIsStoresListVisible,
  isOnCsv = false,
  storeForCSV,
  setStoreForCSV,
}) {
  const { deliveries, setPageCount } = useMyStore();
  const { smfontsize, isdesktop } = useDesktopOrLaptop();

  const { useOnClickOutside } = useClickStore();
  const clickRef = useRef();
  const handler = useCallback(() => {
    setIsStoresListVisible(false);
  });
  useOnClickOutside(clickRef, handler);

  const [searchText, setSearchText] = useState();

  const { Colors, themeChanger } = useTheme();
  const { allStoresByUser, getAllStoresByUser } = useStoreAndChild();
  const { authInfo } = userDataStore();
  const { setFilterStore, filterStore, filterArray, startDate, search } =
    useFilter();

  const storeListHandler = async (accountId) => {
    await getAllStoresByUser(accountId);

    return;
  };

  const debounceFn = useCallback(
    debounce((search) => {
      getAllStoresByUser(authInfo.merchantAccountId, false, {
        search_phrase: search,
      });
    }, 500),
    []
  );

  useEffect(() => {
    if (authInfo?.merchantAccountId) {
      storeListHandler(authInfo.merchantAccountId);
    }
  }, [authInfo]);

  return (
    <div className="relative" ref={clickRef}>
      <div
        style={{
          left: isdesktop ? "-106px" : "-130px",
          right: isdesktop ? "-170px" : "-190px",
          top: isdesktop ? "27px" : "60px",
          paddingTop: "0px",
          paddingBottom: "5px",
          backgroundColor: Colors.bgDarkSecondary,
          zIndex: 9,
          maxHeight: isdesktop ? "320px" : "350px",
        }}
        className={`${isStoresListVisible ? "visible" : "hidden"} ${
          !isOnCsv ? "shadow-xl" : ""
        } absolute kata overflow-y-scroll  rounded-md  flex flex-col z-50 `}
      >
        <form
          onSubmit={(e) => {
            getAllStoresByUser(authInfo.merchantAccountId, false, {
              search_phrase: searchText,
            });
            e.preventDefault();
          }}
          style={{
            width: "100%",
            borderWidth: "1px",
            borderRadius: "6px",
            borderColor: Colors.borderPrimary,
            padding: "12px 10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            backgroundColor: Colors.bgSettings,
          }}
        >
          <SearchSvg />
          <input
            type="text"
            placeholder="Search Store"
            onChange={(event) => {
              setSearchText(event.target.value);
              debounceFn(event.target.value);
            }}
            style={{
              outline: "none",
              width: "100%",
              height: "100%",
              marginLeft: "10px",
              backgroundColor: "transparent",
              color: Colors.fontGreyLight,
              fontSize: smfontsize,
            }}
          />
        </form>
        {allStoresByUser?.data?.map((sto) => {
          return (
            <button
              data-for={`store-name-${sto.id}`}
              onClick={() => {
                if (isOnCsv) {
                  if (storeForCSV?.id === sto.id) {
                    setStoreForCSV();
                  } else {
                    setStoreForCSV(sto);
                  }
                } else {
                  if (filterStore?.id === sto.id) {
                    deliveries(search, 0, startDate, "", filterArray);
                    setFilterStore();
                  } else {
                    deliveries(search, 0, startDate, sto.id, filterArray);
                    setFilterStore(sto);
                  }
                }
                setPageCount(0);
              }}
              key={sto.id}
              type="button"
              className={`flex justify-between items-center   ${
                !themeChanger ? "hover:bg-gray-700" : "hover:bg-gray-100"
              }`}
              style={{
                height: "50px",
                borderRadius: "8px",
                padding: "4px 10px",
                margin: isdesktop ? "4px 0px" : "18px 0px",
                width: "100%",
              }}
            >
              <div
                style={{ color: Colors.fontGreyLight, width: "90%" }}
                className=" flex flex-col items-start "
              >
                <div
                  className="text-left truncate"
                  style={{
                    color: Colors.fontDark,
                    width: "100%",
                    fontSize: smfontsize,
                    whiteSpace: "nowrap",
                  }}
                  data-for={`store-name-${sto.name}`}
                >
                  {sto.name}
                </div>
                <ToolTip
                  id={`store-name-${sto.name}`}
                  // disable={sto.name.length > 24 ? false : true}
                  disable={false}
                  text={sto.name}
                />

                <div
                  className="truncate"
                  style={{
                    fontSize: smfontsize,
                    whiteSpace: "nowrap",
                    color: Colors.fontGreyLight,
                    width: "100%",
                    textAlign: "left",
                  }}
                  data-for={`store-name-${sto.id}`}
                >
                  {sto.id}
                </div>
                <ToolTip
                  id={`store-name-${sto.id}`}
                  disable={false}
                  text={sto.id}
                />
              </div>
              {((filterStore?.id === sto.id && !isOnCsv) ||
                (storeForCSV?.id === sto.id && isOnCsv)) && <CheckIcon />}
            </button>
          );
        })}
      </div>
    </div>
  );
}

export default StoresFilterOnListHeader;
