import React, { useEffect } from "react";

import { useMyStore, useRerouteRecommendations, useTheme } from "../../../store";
import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";

import PaginationWithoutCursor from "../../shared/PaginationWithoutCursor";
import { Loading } from "../../../assets/svgs/Svgs";
import { FILTER_TYPES } from "../../../constants";
import FiltersMenu from "../../shared/common/FiltersMenu";
import ActiveFiltersBar from "../../shared/common/FiltersMenu/ActiveFiltersBar";
import RerouteIntentItem from "./RerouteIntentItem";
import CustomModal from "../../CustomModal/CustomModal";
import RerouteRecommandationReview from "./RerouteRecommandationReview";
import { actionfilterListItems } from "./utils";
import useSocket from "../../../customHooks/useSocket";

const RerouteRecommendations = () => {
  const {
    rerouteRecommendations,
    setRerouteRecommendations,
    isRerouteRecommendationsLoading,
    getRerouteRecommendationsWorker,
    searchByDeliveryId,
    setSearchByDeliveryId,
    filterByAction,
    setFilterByAction,
    selectedRerouteIntent,
    setSelectedRerouteIntent
  } = useRerouteRecommendations();

  const { isSandbox } = useMyStore();
  const { smfontsize } = useDesktopOrLaptop();
  const { Colors } = useTheme();

  const { message, notificationsData: deletedRerouteRecommendationId } = useSocket({
    channelName: `admin@deliveries`,
    notificationsEvent: 'admin_reroute_intent_resolved',
  });

  const recreateRerouteRecommendationsList = (deletedRerouteRecommendationId) => {
    const updatedRerouteRecommendations = { ...rerouteRecommendations, data: rerouteRecommendations.data.filter(intent => intent.id !== deletedRerouteRecommendationId) }
    setRerouteRecommendations(updatedRerouteRecommendations);
  }

  const getRerouteRecommendationsHandler = async (param = {}) => {
    const params = {
      test_mode: isSandbox,
      starting_after: param?.starting_after || null,
      ending_before: param?.ending_before || null,
      ...param,
    };
    await getRerouteRecommendationsWorker(params);
  };

  useEffect(() => {
    if (deletedRerouteRecommendationId) {
      recreateRerouteRecommendationsList(deletedRerouteRecommendationId);
    }
  }, [deletedRerouteRecommendationId])

  useEffect(() => {
    let subscribe = true;
    if (subscribe) {
      getRerouteRecommendationsHandler();
    }

    return () => {
      subscribe = false;
    };
  }, [
    isSandbox,
    searchByDeliveryId,
    filterByAction,
  ]);

  const filtersDefinition = [
    {
      id: "search_by_del_id",
      title: "By Delivery Id",
      type: FILTER_TYPES.SEARCH,
      placeholder: "Delivery Id",
      filterState: searchByDeliveryId,
      setFilterState: setSearchByDeliveryId,
    },
    {
      id: "filter_by_action_recommended",
      title: "By Recommended Action",
      label: "label",
      type: FILTER_TYPES.TYPE_AND_SEARCH,
      list: actionfilterListItems,
      placeholder: "Select Action",
      filterState: filterByAction,
      setFilterState: setFilterByAction,
    },
  ];

  useEffect(() => {
    return () => {
      setSearchByDeliveryId("");
      setFilterByAction();
    };
  }, []);

  useEffect(() => {
    getRerouteRecommendationsHandler();
  }, [isSandbox]);

  return (
    <div>
      <div className="flex w-full justify-between">
        <div style={{ width: `calc(100% - 420px)` }} className="flex items-center">
          <div style={{ minWidth: "130px" }}>
            <FiltersMenu menuItems={filtersDefinition} title="Search by" />
          </div>
          <div
            className="noScroll"
            style={{
              maxWidth: `calc(100% - 130px)`,
              overflowX: "scroll",
            }}
          >
            <ActiveFiltersBar activeFilters={[...filtersDefinition]} />
          </div>
        </div>
      </div>
      <div
        style={{
          borderBottomWidth: "1.5px",
          borderColor: Colors.borderPrimary,
          fontSize: smfontsize,
          fontWeight: "600",
          color: Colors.fontDark,
        }}
        className="grid grid-cols-12 gap-1 w-full py-4 "
      >
        <div className="col-start-1 col-span-1">Delivery ID</div>
        <div className="col-start-2 col-span-1 text-center">Evaluated At</div>
        <div className="col-start-3 col-span-1 text-center">Action Proposed At</div>
        <div className="col-start-4 col-span-2 text-center">Merchant Email</div>
        <div className="col-start-6 col-span-1 text-center">Action Recommended</div>
        <div className="col-start-7 col-span-1 text-center">Action Reason</div>
        <div className="col-start-8 col-span-2 text-center">Action Explanation</div>
        <div className="col-start-10 col-span-1 text-center">Delivery Type</div>
        <div className="col-start-11 col-span-1 text-center">Delivery Status</div>
        <div className="col-start-12 col-span-1 text-center"></div>
      </div>
      {isRerouteRecommendationsLoading && (
        <div style={{ minHeight: "100px" }} className="w-full  flex items-center justify-center kata">
          <Loading color={Colors.bluePrimary} className="h-14 w-14 animate-spin" />
        </div>
      )}
      {rerouteRecommendations?.data?.map((rerouteIntent, index) => {
        return (
          <RerouteIntentItem
            item={rerouteIntent}
            key={rerouteIntent.id}
            index={index}
            socketMessage={message}
          />
        );
      })}
      <div className="flex w-full justify-end mb-16">
        <PaginationWithoutCursor
          data={rerouteRecommendations}
          onGetPaginationData={getRerouteRecommendationsHandler}
        />
      </div>
      <CustomModal
        isOpen={!!selectedRerouteIntent}
        onClose={() => {
          setSelectedRerouteIntent();
        }}
      >
        <RerouteRecommandationReview />
      </CustomModal>
    </div>
  );
};

export default RerouteRecommendations;
