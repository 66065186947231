import React from "react";
import SingleItemForModalNew from "../../../common/SingleItemForModalNew";
import styles from "./styles.module.css";
import classNames from "classnames";

const ItemsDetails = ({ item }) => {
  return (
    <div className={styles.container}>
      <div className={styles.headerCont}>
        <div className={styles.itemHeaderText}>Item #</div>
        <div className={styles.itemHeaderText}>Item Name</div>
        <div className={styles.itemHeaderText}>Quantity</div>
        <div className={styles.itemHeaderText}>Size</div>
      </div>
      <div className="kata">
        {item?.items?.map((i, index) => {
          return (
            <SingleItemForModalNew
              name={i.name}
              quantity={i.quantity}
              size={i.size}
              index={index}
              key={index}
              length={item.items.length}
            />
          );
        })}
      </div>
      <div className={styles.bottomCont}>
        <div className={styles.bottomContTexts}>Order value:</div>
        <div className={classNames(styles.bottomContTexts, "ml-3")}>
          {item.order_value == 0
            ? "$ 0"
            : item.order_value
            ? "$" + (item.order_value / 100).toFixed(2)
            : ""}
        </div>
      </div>
    </div>
  );
};

export default ItemsDetails;
