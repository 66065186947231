import React from "react";
import Avatar from "react-avatar";
import classNames from "classnames";
import styles from "../../styles/PricingRules/PartnerUsersList/partnerUserListItem.module.css";

const PartnerUserListItem = ({
  index,
  partnerUser,
  setSelectedPartner,
  setActiveStep,
}) => {

  return (
    <div
      onClick={() => {
        setActiveStep("list_rules");
        setSelectedPartner(partnerUser);
      }}
      className={classNames(styles.rowContainer, {
        [styles.evenRowContainer]: index % 2 === 0,
      })}
    >
      <div className={styles.businessNameContainer}>
        <div
          className={classNames(styles.logoContainer, {
            [styles.logoContainerEven]: index % 2 === 0,
          })}
        >
          {partnerUser?.logo ? (
            <img
              className={styles.logo}
              src={partnerUser.logo}
              alt="user avatar"
            />
          ) : (
            <Avatar
              name={partnerUser?.email.split("").join(" ")}
              size={30}
              maxInitials={2}
            />
          )}
        </div>

        <div className={styles.rowText}>{partnerUser.businessName}</div>
      </div>

      <div className={styles.rowText}>{partnerUser.email}</div>
    </div>
  );
};

export default PartnerUserListItem;
