import React, { useRef, useCallback } from "react";
import { CheckIcon } from "../../../../../assets/svgs/Svgs";
import { useDesktopOrLaptop } from "../../../../../services/useDesktopOrLaptop";
import {
    useInvoices, 
    useClickStore,
    useTheme, } from "../../../../../store";
import ToolTip from "../../../../Tooltip/Tooltip";

function StoreFilterOptionList({
    isStoresListVisible,
    setIsStoresListVisible
}) {
    const { isdesktop } = useDesktopOrLaptop();
    const { setFilterStore, filterStore, invoiceStores, excludeStoreTransactions, setExcludeStoreTransactions } = useInvoices();

    const { useOnClickOutside } = useClickStore();
    const clickRef = useRef();
    const handler = useCallback(() => {
        setIsStoresListVisible(false);
    });
    useOnClickOutside(clickRef, handler);

    const { Colors, themeChanger } = useTheme();

    return (
        <div className="absolute" ref={clickRef}>
            <div
                style={{
                    top: isdesktop ? "27px" : "60px",
                    paddingTop: "0px",
                    paddingBottom: "5px",
                    backgroundColor: Colors.bgDarkSecondary,
                    zIndex: 9,
                    maxHeight: isdesktop ? "320px" : "350px",
                    width: "300px",
                }}
                className={`${isStoresListVisible ? "visible" : "hidden"} shadow-xl absolute kata overflow-y-scroll  rounded-md  flex flex-col z-50 `}
            >
                <button
                    data-for={`no_store`}
                    onClick={() => {
                        setFilterStore();
                        setExcludeStoreTransactions(!excludeStoreTransactions);
                    }}
                    key={`no_store`}
                    type="button"
                    className={`flex justify-between items-center   ${!themeChanger ? "hover:bg-gray-700" : "hover:bg-gray-100"
                        }`}
                    style={{
                        height: "50px",
                        borderRadius: "8px",
                        padding: "4px 10px",
                        margin: isdesktop ? "4px 0px" : "18px 0px",
                        width: "100%",
                    }}
                >
                    <div
                        style={{ color: Colors.fontGreyLight, width: "90%" }}
                        className=" flex flex-col items-start "
                    >
                        <div
                            className="text-left truncate"
                            style={{
                                color: Colors.fontDark,
                                width: "100%",
                                fontSize: "14px",
                                whiteSpace: "nowrap",
                            }}
                        >
                            Transactions without Store
                        </div>
                    </div>
                    {excludeStoreTransactions && <CheckIcon />}
                </button>
                {invoiceStores?.data?.map((store) => {
                    return (
                        <button
                            data-for={`store-name-${store.id}`}
                            onClick={() => {
                                if (filterStore?.id === store.id) {
                                    setFilterStore();
                                } else {
                                    setFilterStore(store);
                                }
                                setExcludeStoreTransactions(false);
                            }}
                            key={store.id}
                            type="button"
                            className={`flex justify-between items-center   ${!themeChanger ? "hover:bg-gray-700" : "hover:bg-gray-100"
                                }`}
                            style={{
                                height: "50px",
                                borderRadius: "8px",
                                padding: "4px 10px",
                                margin: isdesktop ? "4px 0px" : "18px 0px",
                                width: "100%",
                            }}
                        >
                            <div
                                style={{ color: Colors.fontGreyLight, width: "90%" }}
                                className=" flex flex-col items-start "
                            >
                                <div
                                    className="text-left truncate"
                                    style={{
                                        color: Colors.fontDark,
                                        width: "100%",
                                        fontSize: "14px",
                                        whiteSpace: "nowrap",
                                    }}
                                    data-for={`store-name-${store.name}`}
                                >
                                    {store.name}
                                </div>
                                <ToolTip
                                    id={`store-name-${store.name}`}
                                    disable={false}
                                    text={store.name}
                                />

                                <div
                                    className="truncate"
                                    style={{
                                        fontSize: "14px",
                                        whiteSpace: "nowrap",
                                        color: Colors.fontGreyLight,
                                        width: "100%",
                                        textAlign: "left",
                                    }}
                                    data-for={`store-name-${store.id}`}
                                >
                                    {store.id}
                                </div>
                                <ToolTip
                                    id={`store-name-${store.id}`}
                                    disable={false}
                                    text={store.id}
                                />
                            </div>
                            {(filterStore?.id === store.id) && <CheckIcon />}
                        </button>
                    );
                })}
            </div>
        </div>
    );
}

export default StoreFilterOptionList;
