import React from "react";

import ImagesUpload from "./ImagesUpload";
import styles from "./styles.module.css";
import { useFlag } from "@unleash/proxy-client-react";

const UploadIncidentProofs = ({ images, setImages, errorMessage, setErrorMessage }) => {
  const isPOIDisabled = useFlag("disablePOIonHomePage");

  if (isPOIDisabled) {
    return <></>;
  }
  return (
    <div>
      <h1 className={styles.uploadTitle}>
        Upload itemized receipt <span className={styles.maxText}>(Max 3 images)</span>{" "}
        {errorMessage && <span className={styles.errorMessage}>{errorMessage}</span>}
      </h1>
      <ImagesUpload setErrorMessage={setErrorMessage} errorMessage={errorMessage} images={images} setImages={setImages} />
    </div>
  );
};

export default UploadIncidentProofs;
