import axios from "axios";
import apiUrls from "../../constants/apiUrls";
import { generateQueryParams } from "./utils";

export const getDeliveryById = (id) =>
  axios.get(
    apiUrls.DELIVERY_BY_ID(id),
    {
      withCredentials: false,
    },
    {
      headers: { "x-burq-client": "client-dashboard" },
    }
  );

export const getDeliveryApiErrors = (id, params) => {
  const urlWithQueryParams = generateQueryParams(apiUrls.DELIVERY_API_ERRORS(id), params);
  return axios.get(urlWithQueryParams, {
    headers: { "x-burq-client": "client-dashboard" },
  });
};

export const getTrackIncidentLink = (id) =>
  axios.get(apiUrls.GET_TRACK_INCIDENT_LINK(id), {
    headers: { "x-burq-client": "client-dashboard" },
  });

export const getRejectionReasons = () =>
  axios.get(apiUrls.GET_REJECTION_REASONS, {
    headers: { "x-burq-client": "client-dashboard" },
  });

export const getDisputedList = (id, params) => {
  const urlWithQueryParams = generateQueryParams(apiUrls.GET_DISPUTED_DELIVERIES_LIST(id), params);
  return axios.get(urlWithQueryParams, {
    headers: { "x-burq-client": "client-dashboard" },
  });
};
export const getHomeDeliveriesList = (id, params) => {
  const urlWithQueryParams = generateQueryParams(apiUrls.GET_HOME_DELIVERIES_LIST(id), params);
  return axios.get(urlWithQueryParams, {
    headers: { "x-burq-client": "client-dashboard" },
  });
};
