import React, { useEffect, useState } from "react";
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar, utils } from "@hassanmojab/react-modern-calendar-datepicker";
import moment from "moment";
import momenttz from "moment-timezone";
import { useMyStore, useSingleItemStore, useTheme } from "../store";
import { useFormikContext } from "formik";
import { BiCheckbox, BiCheckboxChecked } from "react-icons/bi";
import { useDesktopOrLaptop } from "../services/useDesktopOrLaptop";

function AsapOrSchedule({ isReroute }) {
  const { Colors } = useTheme();
  const { isdesktop, xlfontsize } = useDesktopOrLaptop();
  const { rerouteItem } = useSingleItemStore();

  const styles = {
    inputs: {
      width: "50%",
      padding: "8px 12px",
      fontFamily: "Poppins",
      borderWidth: "1px",
      borderColor: "#E2E2EA",
      borderRadius: "10px",
      fontSize: "14px",
      fontWeight: "400",
      marginBottom: "14px",
      outline: "none",
    },
    lables: {
      fontFamily: "Poppins",
      fontSize: "16px",
      marginBottom: "10px",
      marginTop: "16px",
      fontWeight: "400",
    },
  };
  const { setQuoteRes } = useMyStore();
  const { selectedDay, setSelectedDay, orderTime, setOrderTime } = useSingleItemStore();
  const { setFieldValue, values } = useFormikContext();

  const [timeArray, setTimeArray] = useState([]);

  const formatInputValue = () => {
    if (!selectedDay) return "";

    return `${selectedDay.month}-${selectedDay.day}-${selectedDay.year}`;
  };

  const setDateAndTime = (date) => {
    const check = moment(date);
    const month = check.format("M");
    const day = check.format("D");
    const year = check.format("YYYY");
    const time = moment(date).format("hh:mmA");
    const orderDate = {
      year: parseInt(year),
      month: parseInt(month),
      day: parseInt(day),
    };
    return { time, orderDate };
  };

  useEffect(() => {
    if (values.eta) {
      const { orderDate } = setDateAndTime(values.eta);
      setSelectedDay(orderDate);
    }
    return () => {
      const { orderDate } = setDateAndTime(new Date());
      setSelectedDay(orderDate);
      setOrderTime("");
    };
  }, []);

  useEffect(() => {
    if (selectedDay && orderTime) {
      const date = `${selectedDay.month}/${selectedDay.day}/${selectedDay.year} ${orderTime}`;
      setFieldValue("eta", moment(date, "M/D/YYYY hh:mmA").toISOString());
    }
  }, [selectedDay, orderTime]);

  const createTimeArray = () => {
    var x = 15; //minutes interval
    var times = [];
    var tt =
      selectedDay?.day && selectedDay.day === parseInt(moment().format("D"))
        ? moment().format("H") * 60 + Math.ceil((parseInt(moment().format("m")) + 10) / 15) * 15
        : 0; // start time
    var ap = ["AM", "PM"]; // AM-PM
    //loop to increment the time and push results in array
    for (var i = 0; tt < 24 * 60; i++) {
      var hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
      var mm = tt % 60; // getting minutes of the hour in 0-55 format
      // console.log("hh", hh, "mm", mm);
      times[i] = ("0" + (hh % 12)).slice(-2) + ":" + ("0" + mm).slice(-2) + ap[Math.floor(hh / 12)]; // pushing data in array in [00:00 - 12:00 AM/PM format]
      tt = tt + x;
    }
    const timeIssues = {
      "00:00PM": "12:00PM",
      "00:15PM": "12:15PM",
      "00:30PM": "12:30PM",
      "00:45PM": "12:45PM",
    };
    times = times.map((time) => (timeIssues[time] ? timeIssues[time] : time));

    setTimeArray(times);
  };

  useEffect(() => {
    createTimeArray();
  }, [selectedDay]);

  useEffect(() => {
    if (timeArray.length > 0) {
      if (!values.eta) {
        setOrderTime(timeArray[0]);
      } else if (values.eta) {
        const { time } = setDateAndTime(values.eta);
        setOrderTime(time);
      }
    }
  }, [timeArray]);

  return (
    <div style={{ fontFamily: "Poppins" }} className="w-full mb-8  justify-center items-center flex flex-col pt-10">
      <div className="w-full flex items-center justify-center">
        <div
          onClick={() => {
            setFieldValue("isPickupAt", true);
          }}
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        >
          {values.isPickupAt ? (
            <BiCheckboxChecked size={isdesktop ? 24 : 28} color="#0062FF" />
          ) : (
            <BiCheckbox color={Colors.fontDark} size={isdesktop ? 22 : 28} />
          )}
          <div
            style={{
              fontSize: xlfontsize,
              fontWheight: "500",
              color: values.isPickupAt ? Colors.bluePrimary : Colors.fontDark,
            }}
          >
            Request Pickup Time{" "}
          </div>
        </div>
        <div
          onClick={() => {
            setFieldValue("isPickupAt", false);
          }}
          className="ml-14"
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        >
          {!values.isPickupAt ? (
            <BiCheckboxChecked size={isdesktop ? 24 : 28} color="#0062FF" />
          ) : (
            <BiCheckbox color={Colors.fontDark} size={isdesktop ? 22 : 28} />
          )}
          <div
            style={{
              color: !values.isPickupAt ? Colors.bluePrimary : Colors.fontDark,
              fontSize: xlfontsize,
              fontWheight: "500",
            }}
          >
            Request Dropoff Time
          </div>
        </div>
      </div>
      <div style={{ marginTop: "30px" }}>
        {rerouteItem && (
          <div style={{ color: Colors.fontGreyLight, marginBottom: "10px" }}>
            <span
              style={{
                fontWeight: "500",
                color: Colors.fontDark,
                marginRight: "10px",
              }}
            >
              Schedule Time:
            </span>{" "}
            {moment(values.eta).format("MMM D, YYYY, h:mma")}
            {/* {values.isPickupAt
              ? rerouteItem.pickup_at &&
                moment(rerouteItem.pickup_at).format("MMM D, YYYY, h:mma")
              : rerouteItem.dropoff_at &&
                moment(rerouteItem.dropoff_at).format("MMM D, YYYY, h:mma")} */}
          </div>
        )}
        <Calendar
          minimumDate={utils().getToday()}
          value={selectedDay}
          onChange={(e) => {
            if (!isReroute) {
              setSelectedDay(e);
            }
          }}
          // shouldHighlightWeekends
          colorPrimary="#0062FF"
        />
        <div style={{ display: "flex", alignItems: "center", marginTop: "20px" }} className="relative">
          <div
            style={{
              marginBottom: "10px",
              marginRight: "20px",
              width: "50%",
              color: Colors.fontDark,
            }}
          >
            Pick a time <br /> {"(" + momenttz.tz(momenttz.tz.guess()).format("z") + ")"}
          </div>
          <select
            name="typeOfBusiness"
            value={orderTime}
            draggable={true}
            onChange={(e) => {
              if (!isReroute) {
                setOrderTime(e.target.value);
              }
            }}
            style={{
              ...styles.inputs,
              ...{ backgroundColor: Colors.white, color: Colors.fontDark },
            }}
          >
            {timeArray?.map((item, index) => {
              return (
                <option key={index} value={item}>
                  {item}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    </div>
  );
}

export default AsapOrSchedule;
