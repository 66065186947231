import axios from "axios";
import apiUrls from "../../constants/apiUrls";
import { generateQueryParams } from "./utils";

export const getPartnerUsers = () =>
  axios.get(apiUrls.PARTNER_USERS, {
    headers: { "x-burq-client": "client-dashboard" },
  });

export const getPartnerRules = (params) => {
  const urlWithQueryParams = generateQueryParams(
    apiUrls.PARTNER_PRICING_RULES,
    params
  );
  return axios.get(urlWithQueryParams);
};

export const createPartnerRules = (payload) =>
  axios.post(apiUrls.PARTNER_PRICING_RULES, payload, {
    headers: { "x-burq-client": "client-dashboard" },
  });

export const updatePartnerRule = (id, payload) =>
  axios.put(apiUrls.PARTNER_PRICING_RULE(id), payload, {
    headers: { "x-burq-client": "client-dashboard" },
  });

export const deletePartnerRule = (id) =>
  axios.delete(apiUrls.PARTNER_PRICING_RULE(id), {
    headers: { "x-burq-client": "client-dashboard" },
  });
