import React from "react";
import styles from "./styles.module.css";
import classNames from "classnames";
import Hyperlink from "../../../../shared/atoms/Hyperlink";

export function OneCard({ title, middleTitle, desc, icon, externalClassNames, showPayByBankTransfer, onHyperlinkClick }) {
  return (
    <div className={styles.card}>
      <div className={classNames("flex items-center w-full justify-between mb-6", externalClassNames)}>
        <div className={styles.cardTitle}>{title}</div>
        {icon && <div className={styles.cardTitle}>{icon}</div>}

      </div>

      <div className={styles.middleTitle}>{middleTitle}</div>
      <div className={styles.desc}>{desc}</div>
      {showPayByBankTransfer && <Hyperlink text="Pay by bank transfer" onClick={onHyperlinkClick} externalClassName={styles.payByBankTransferLink} />}
    </div>
  );
}
