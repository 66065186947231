import { createStore } from "aniuta";

import { toast } from "react-toastify";

import * as Sentry from "@sentry/react";
import { useState } from "react";
import axios from "axios";
import {
  getNotificationsSettings,
  putNotificationSettings,
} from "../utils/api/notificationsSettings";

const useNotifSettings = createStore({
  name: "useNotifSettings",
  Store: () => {
    const [notificationsSettings, setNotificationsSettings] = useState();
    const getNotificationsSettingsHandler = async (id) => {
      try {
        const { data } = await getNotificationsSettings(id);
        setNotificationsSettings(data);
        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else if (axios.isCancel(error)) {
          console.log("canceled");
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
      }
    };
    const updateNotificationSetting = async (
      userId,
      notificationId,
      payload
    ) => {
      try {
        const { data } = await putNotificationSettings(
          userId,
          notificationId,
          payload
        );
        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else if (axios.isCancel(error)) {
          console.log("canceled");
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
      }
    };

    return {
      getNotificationsSettingsHandler,
      notificationsSettings,
      setNotificationsSettings,

      updateNotificationSetting,
    };
  },
});

export default useNotifSettings;
