import React from "react";
import cancel from "../../../../assets/png/statuses/cancel.png";
import contactingProvider from "../../../../assets/png/statuses/contactingProvider.png";
import created from "../../../../assets/png/statuses/created.png";
import delivered from "../../../../assets/png/statuses/delivered.png";
import dropoff from "../../../../assets/png/statuses/dropoff.png";
import pickupPng from "../../../../assets/png/statuses/pickup.png";
import scheduled from "../../../../assets/png/statuses/scheduled.png";
import returnPng from "../../../../assets/png/statuses/return.png";
import { useTheme, useWorkflow } from "../../../../store";
import { useDesktopOrLaptop } from "../../../../services/useDesktopOrLaptop";
import { StarIcon } from "../../../../assets/svgs/Svgs";
import { useFlag } from "@unleash/proxy-client-react";

const AllStatuses = () => {
  const { Colors } = useTheme();
  const { workflowStatuses, selectedStatus, setSelectedStatus } = useWorkflow();
  const { isdesktop, smfontsize, xsfontsize } = useDesktopOrLaptop();

  return (
    <div
      style={{
        width: "100%",
        flexWrap: "wrap",
        // backgroundColor: "green",
        display: "flex",
        justifyContent: "space-between",
        marginTop: "20px",
      }}
    >
      {workflowStatuses?.map((item, index) => {
        return (
          <button
            onClick={() => {
              setSelectedStatus(item);
            }}
            type="button"
            key={index}
            style={{
              width: "47.5%",
              height: isdesktop ? "110px" : "130px",
              fontSize: xsfontsize,
              marginBottom: "16px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              borderRadius: "10px",
              borderWidth: "1px",
              borderColor:
                selectedStatus === item
                  ? Colors.bluePrimary
                  : Colors.borderSecondary,
              backgroundColor: Colors.detailsBg,
            }}
          >
            {item.name === "pending" ||
            item.name === "Delivery Created" ||
            item.name === "Driver Assigned" ||
            item.name === "Assigning Driver" ? (
              <img
                src={created}
                alt="logo"
                style={{ width: "54px", height: "54px", marginTop: "10px" }}
              />
            ) : item.name === "canceled" ||
              item.name === "Provider Canceled" ||
              item.name === "Customer Canceled" ||
              item.name === "Merchant Canceled" ||
              item.name === "Burq Canceled" ||
              item.name === "Expired" ||
              item.name === "Failed" ||
              item.name === "Disputed" ? (
              <img
                src={cancel}
                alt="logo"
                style={{ width: "54px", height: "54px", marginTop: "10px" }}
              />
            ) : item.name === "Delivered" ? (
              <img
                src={delivered}
                alt="logo"
                style={{ width: "54px", height: "54px", marginTop: "10px" }}
              />
            ) : item.name === "pickup" ||
              item.name === "Enroute to Pick-up" ||
              item.name === "Arrived at Pick-up" ||
              item.name === "Pick-up Complete" ||
              item.name === "Pickup Complete" ? (
              <img
                src={pickupPng}
                alt="logo"
                style={{ width: "54px", height: "54px", marginTop: "10px" }}
              />
            ) : item.name === "dropoff" ||
              item.name === "Enroute to Drop-off" ||
              item.name === "Arrived at Drop-off" ||
              item.name === "Drop off Completed" ? (
              <img
                src={dropoff}
                alt="logo"
                style={{ width: "54px", height: "54px", marginTop: "10px" }}
              />
            ) : item.name === "Enroute to Return" ||
              item.name === "Returned" ? (
              <img
                src={returnPng}
                alt="logo"
                style={{ width: "54px", height: "54px", marginTop: "10px" }}
              />
            ) : item.name === "Contacting Provider" ? (
              <img
                src={contactingProvider}
                alt="logo"
                style={{ width: "54px", height: "54px", marginTop: "10px" }}
              />
            ) : item.name === "Feedback" ? (
              <StarIcon size="30" />
            ) : (
              item.name === "Scheduled" && (
                <img
                  src={scheduled}
                  alt="logo"
                  style={{ width: "54px", height: "54px", marginTop: "10px" }}
                />
              )
            )}
            <div style={{ color: Colors.fontDark, marginTop: "6px" }}>
              {item.name}
            </div>
          </button>
        );
      })}
    </div>
  );
};

export default AllStatuses;
