import React, { useState } from "react";
import { MdCall } from "react-icons/md";
import { ChooseFileAvatar } from "../../../assets/svgs/Svgs";
import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";
import { constructName } from "../utils";

const DriverDetailSection = ({ driverInfo }) => {
  const [phoneNumberVisible, setPhoneNumberVisible] = useState(false);
  const { smfontsize, isdesktop } = useDesktopOrLaptop();

  const driverName = constructName(driverInfo.first_name, driverInfo.last_name);

  return (
    <div className="flex justify-start items-center w-full mt-8 mb-2">
      {driverInfo.image_url ? (
        <img className="h-12 w-12" src={driverInfo.image_url} alt="Driver" />
      ) : (
        <ChooseFileAvatar width={50} height={50} />
      )}
      <div className="ml-4">
        <div style={{ fontSize: smfontsize }} className="font-semibold">
          {driverName ? driverName : " Driver Name: Not Available"}
        </div>

        <div style={{ fontSize: smfontsize }} className="capitalize ">
          Vehicle: {driverInfo.vehicle || "Not Available"}
        </div>
      </div>
      <div className="absolute  -bottom-5 lg:bottom-4 right-8  flex items-center  lg:right-14">
        <button
          onClick={() => {
            setPhoneNumberVisible(!phoneNumberVisible);
          }}
          type="button"
          style={{
            height: isdesktop ? "46px" : "66px",
            width: isdesktop ? "46px" : "66px",
          }}
          className=" bg-gray-200 justify-center items-center flex rounded-full focus:outline-none"
        >
          <MdCall color="#2563eb" size={isdesktop ? 22 : 28} />
        </button>
        {phoneNumberVisible && (
          <a
            style={{ fontSize: smfontsize }}
            href={`tel:${driverInfo?.phone_number}`}
            className="font-medium  text-gray-800 ml-2"
          >
            {driverInfo?.phone_number || "Not Available"}
          </a>
        )}
      </div>
    </div>
  );
};

export default DriverDetailSection;
