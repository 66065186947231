import React from "react";
import { AiFillCheckCircle } from "react-icons/ai";
import styles from "../../../styles/rerouteTracking.module.css";
import { useTheme } from "../../../../../store";

const RerouteTracking = ({ id, label }) => {
  const { Colors } = useTheme();
  return (
    <div className={styles.trackRerouteDelivery}>
      <div className={styles.trackRerouteText}>
        <AiFillCheckCircle size={24} color={Colors.greenDark} />
        <p>{label}: {id}</p>
      </div>
      <a
        className={styles.trackLink}
        href={`${process.env.REACT_APP_TRACKING_URL}/track/${id}`}
        target="_blank"
        rel="noreferrer"
      >
        Track
      </a>
    </div>
  );
};

export default RerouteTracking;
