import React, { useEffect } from "react";
import { Loading } from "../../../assets/svgs/Svgs";
import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";
import {
  useAccount,
  userDataStore,
  useStoreAndChild,
  useTheme,
} from "../../../store";
import Member from "./Member";

const MembersList = ({ accountMembers, setIsSidePanelOpened }) => {
  const {
    getStoreById,
    storeById,
    setStoreById,
    isStoreByIdLoading,
    selectedStore,
  } = useStoreAndChild();
  const { usersForAccount, getUsersOfAccount, usersForAccountLoading } =
    useAccount();
  const { Colors } = useTheme();
  const { lgfontsize } = useDesktopOrLaptop();

  const { authInfo } = userDataStore();

  useEffect(() => {
    if (!accountMembers) {
      if (selectedStore) {
        getStoreById(selectedStore.id);
      } else {
        setStoreById();
      }
    } else if (accountMembers) {
      if (authInfo) {
        getUsersOfAccount();
      }
    }
  }, [selectedStore, authInfo]);

  return (
    <div style={{ height: "100%" }}>
      <div className="grid grid-cols-12 gap-1 mt-6 ">
        <div
          style={{
            fontSize: lgfontsize,
            fontWeight: "600",
            color: Colors.fontDark,
          }}
          className="col-start-1 col-span-4 pl-5"
        >
          Email
        </div>
        <div
          style={{
            fontSize: lgfontsize,
            fontWeight: "600",
            color: Colors.fontDark,
          }}
          className="col-start-5 col-span-3"
        >
          Name
        </div>
        <div
          style={{
            fontSize: lgfontsize,
            fontWeight: "600",
            color: Colors.fontDark,
          }}
          className="col-start-8 col-span-2"
        >
          Role
        </div>
        {accountMembers && (
          <div
            style={{
              fontSize: lgfontsize,
              fontWeight: "600",
              color: Colors.fontDark,
            }}
            className="col-start-10 col-span-2"
          >
            Status
          </div>
        )}
        <div
          style={{
            fontSize: lgfontsize,
            fontWeight: "600",
            color: Colors.fontDark,
          }}
          className="col-start-12 col-span-1"
        >
          Action
        </div>
      </div>
      <div
        style={{ height: "calc(100% - 130px)", overflowY: "scroll" }}
        className="w-full"
      >
        {(isStoreByIdLoading || usersForAccountLoading) && (
          <div
            style={{ minHeight: "100px" }}
            className="w-full  flex items-center justify-center kata"
          >
            <Loading
              color={Colors.bluePrimary}
              className="h-14 w-14 animate-spin"
            />
          </div>
        )}
        {usersForAccount?.map((user, index) => {
          return (
            <Member
              acountMember
              key={index}
              user={user}
              setIsSidePanelOpened={setIsSidePanelOpened}
            />
          );
        })}
      </div>
    </div>
  );
};

export default MembersList;
