import React, { useCallback, useRef, useState } from "react";
import styles from "./styles/columnEdtior.module.css";

import { IoSettingsOutline } from "react-icons/io5";
import OutsideClickHandler from "react-outside-click-handler";
import classNames from "classnames";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import CheckBoxComp from "../CheckBoxComp/CheckboxComp";
import { BiCheckbox, BiCheckboxChecked } from "react-icons/bi";
import { VerticalHandlerSvg } from "./Svgs";

export const ItemTypes = {
  CARD: "card",
};

const DraggableColumn = ({ id, text, index, moveCard, isActive, columnsState, setColumnsState }) => {
  const ref = useRef(null);
  const dragRef = useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag, preview] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(dragRef));
  preview(ref);
  return (
    <div ref={ref} className={styles.oneItem} style={{ opacity }} data-handler-id={handlerId}>
      <div
        onClick={() => {
          const _columnsState = structuredClone(columnsState);
          _columnsState[index].isActive = !isActive;
          setColumnsState(_columnsState);
        }}
        className="flex items-center"
      >
        {isActive ? <BiCheckboxChecked size={24} color="#0062FF" /> : <BiCheckbox size={24} />}
        <div className={styles.draggableText}>{text}</div>
      </div>
      <div className={classNames("flex items-center justify-center pl-3 pr-1", styles.grab)} ref={dragRef}>
        <VerticalHandlerSvg />
      </div>
    </div>
  );
};

const ColumnEditor = ({ columnData, setColumnData }) => {
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);

  function update(array, changes) {
    const { $splice } = changes;

    // Create a shallow copy of the array to maintain immutability
    const newArray = array.slice();

    // Apply all splices
    $splice.forEach(([start, deleteCount, item]) => {
      if (deleteCount === 0 && item !== undefined) {
        // Insert operation
        newArray.splice(start, 0, item);
      } else if (item === undefined) {
        // Remove operation
        newArray.splice(start, deleteCount);
      } else {
        // Replace operation
        newArray.splice(start, deleteCount, item);
      }
    });

    return newArray;
  }
  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setColumnData((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      })
    );
  }, []);

  return (
    <div className={styles.container}>
      <div
        onClick={() => {
          setIsDropdownOpened((prev) => !prev);
        }}
        className={styles.placeHolder}
      >
        <IoSettingsOutline className="mr-2" color="#0062ff" />
        Edit Columns
      </div>
      {isDropdownOpened && (
        <OutsideClickHandler
          onOutsideClick={() => {
            setIsDropdownOpened(false);
          }}
        >
          <div className={classNames(styles.dropdownComponent, "scrollbar")}>
            <DndProvider backend={HTML5Backend}>
              <div className={classNames(styles.fixedContainer, styles.borderBottom)}>
                <div className={styles.fixedTitle}>Fixed Columns</div>
                {columnData.map((column, index) => {
                  if (column.isFixed) {
                    return (
                      <div key={column.header} className={styles.fixedHeaderText}>
                        {column.header}
                      </div>
                    );
                  } else {
                    return <></>;
                  }
                })}
              </div>
              <div className={styles.fixedContainer}>
                <div className={styles.fixedTitle}>Active Columns</div>
                {columnData.map((column, index) => {
                  if (!column.isFixed) {
                    return (
                      <DraggableColumn
                        columnsState={columnData}
                        key={column.header}
                        index={index}
                        id={column.header}
                        text={column.header}
                        isActive={column.isActive}
                        moveCard={moveCard}
                        setColumnsState={setColumnData}
                      />
                    );
                  }
                })}
              </div>
            </DndProvider>
          </div>
        </OutsideClickHandler>
      )}
    </div>
  );
};
export default ColumnEditor;
