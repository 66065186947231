import React, { useEffect, useMemo } from "react";
import { useFormikContext } from "formik";
import { useStoreAndChild, useTheme } from "../../store";
import SingleAddressInFormik from "./SingleAddressInFormik";
import { CalendarSvg } from "../../assets/svgs/Svgs";
import ToolTip from "../Tooltip/Tooltip";

import moment from "moment";
import momenttz from "moment-timezone";
import DefaultVehicle from "../../assets/png/vehicles/devaultVehicle.png";
import car from "../../assets/png/vehicles/car.png";
import van from "../../assets/png/vehicles/van.png";
import truck from "../../assets/png/vehicles/truck.png";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import TipsForOrder from "../../screens/OrderManagement/createOrder/TipsForOrder";

function ReviewTab({ setSteps, isAddressDefaulted, isOrder, isReroute, isClickedOnContinue, isMerchantReroute }) {
  const { Colors, themeChanger } = useTheme();
  const { isdesktop, mdfontsize, smfontsize } = useDesktopOrLaptop();
  const { selectedStore } = useStoreAndChild();

  const { values, setFieldValue } = useFormikContext();

  useEffect(()=>{
    if(isMerchantReroute) {
      setFieldValue('reroute_reason', 'merchant_rerouted');
    }
  }, [isMerchantReroute])

  const styles = useMemo(() => {
    return {
      container: {
        backgroundColor: Colors.white,
        width: "100%",
        padding: "20px 20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        borderRadius: "8px",
      },
      title: {
        fontFamily: "Poppins",
        fontSize: mdfontsize,
        fontWeight: "600",
        color: Colors.fontDark,
      },
      cardContainer: {
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "20px",
      },
      card: {
        display: "flex",
        flexDirection: "column",
        width: "31%",
        backgroundColor: Colors.buttonBg,
        borderRadius: "8px",
        justifyContent: "center",
        alignItems: "center",
        padding: "30px 22px 20px 22px",
        outline: "none",
      },
      cardTitle: {
        marginTop: "10px",
        color: "#4D89DA",
        fontFamily: "Poppins",
        fontSize: mdfontsize,
        fontWeight: "600",
        marginBottom: "12px",
      },
      cardText: {
        textAlign: "center",
        fontFamily: "Poppins",
        fontSize: smfontsize,
        color: Colors.fontGreyLight,
      },
      bottomCont: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: "20px 0px",
      },
      bottomCard: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: Colors.bgListHeader,
        borderRadius: "8px",
        padding: "20px",
        justifyContent: "space-between",
      },
      bottomContTitle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "10px",
      },
      bottomCardText: {
        fontSize: mdfontsize,
        fontFamily: "Poppins",
        fontWeight: "500",
      },
      bottomCardValue: {
        marginLeft: "10px",
        fontWeight: "700",
        fontFamily: "Poppins",
        fontSize: smfontsize,
        color: "#4D89DA",
        textTransform: "capitalize",
      },
      checkBtn: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "16px",
        width: "16px",
        borderRadius: "8px",
      },
      rerouteReasonContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: '10px',
        justifyContent: "flex-start",
      },
    };
  }, [themeChanger]);

  return (
    <div style={styles.container}>
      <div style={styles.title}>Confirm the delivery information</div>
      <div
        className="kata"
        style={{
          width: "100%",
          margin: "20px 0px 0px 0px",
          padding: "0px 0px 0px 0px",
          overflowY: "scroll",
        }}
      >
        <div
          style={{
            backgroundColor: Colors.bgDarkSecondary,
            padding: "20px",
            borderRadius: "8px",
            position: "relative",
          }}
        >
          {!isReroute && (
            <button
              type="button"
              onClick={() => {
                setSteps(1);
              }}
              style={{
                position: "absolute",
                top: "2px",
                right: "4px",
                fontSize: mdfontsize,
                fontFamily: "Poppins",
                fontWeight: "500",
                color: Colors.bluePrimary,
                zIndex: 50,
                padding: "10px",
                outline: "none",
              }}
            >
              Edit
            </button>
          )}
          <SingleAddressInFormik
            isPlus
            index={0}
            styles={styles}
            address={isAddressDefaulted ? selectedStore.address : values.pickup[0].pickup_address}
            unit={isAddressDefaulted ? selectedStore.unit_number : values.pickup[0].pickup_unit}
            bussinessNmae={isAddressDefaulted ? "" : values?.pickup[0]?.pickup_businessName}
            name={isAddressDefaulted ? selectedStore.name : values.pickup[0].pickup_name}
            note={isAddressDefaulted ? selectedStore?.pickup_notes : values.pickup[0].pickup_notes}
            phoneNumber={isAddressDefaulted ? selectedStore.phone_number : values.pickup[0].pickup_phone_number}
          />

          {values.dropoff?.map((item, index) => {
            return (
              <SingleAddressInFormik
                key={index}
                isPlus
                length={values.dropoff.length}
                index={index + 1}
                styles={styles}
                unit={item.dropoff_unit}
                address={item.dropoff_address}
                bussinessNmae={values.dropoff[0].dropoff_businessName}
                name={`${item.dropoff_name}`}
                note={item.dropoff_notes}
                phoneNumber={item.dropoff_phone_number}
              />
            );
          })}
        </div>
      </div>
      {!isOrder && (
        <div style={{ ...styles.bottomCont, ...{ padding: "20px 0px 0px 0px" } }}>
          <div style={styles.bottomCard}>
            <div
              style={{
                ...styles.bottomCardText,
                ...{ color: Colors.fontGreyLight },
              }}
            >
              Order Value:
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ ...styles.bottomCardValue, ...{ marginLeft: "0px" } }}>$ {values.order_value}</div>
            </div>
          </div>

          {values.external_order_ref && (
            <div style={styles.bottomCard}>
              <div
                style={{
                  ...styles.bottomCardText,
                  ...{ color: Colors.fontGreyLight },
                }}
              >
                External order Id:
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    ...styles.bottomCardValue,
                    ...{ marginLeft: "0px" },
                  }}
                >
                  {values.external_order_ref}
                </div>
              </div>
            </div>
          )}
          <div style={styles.bottomCard}>
            <div
              style={{
                ...styles.bottomCardText,
                ...{ color: Colors.fontGreyLight },
              }}
            >
              Contains Alcohol:
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  ...styles.bottomCardValue,
                  ...{ marginLeft: "0px" },
                }}
              >
                {values.contains_alcohol ? "true" : "false"}
              </div>
            </div>
          </div>
        </div>
      )}
      <div style={{ ...styles.bottomCont, ...{ padding: "10px 0px 0px 0px" } }}>
        <div style={styles.bottomCard}>
          <div
            style={{
              ...styles.bottomCardText,
              ...{ color: Colors.fontGreyLight },
            }}
          >
            Items:
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
              marginTop: "10px",
            }}
          >
            <div
              style={{
                textAlign: "center",
                fontWeight: "bold",
                color: Colors.fontGreyLight,
                fontSize: smfontsize,
              }}
            >
              Item #
            </div>
            <div
              style={{
                width: "31%",
                textAlign: "center",
                fontWeight: "bold",
                color: Colors.fontGreyLight,
                fontSize: smfontsize,
              }}
            >
              Name
            </div>
            {!isOrder ? (
              <div
                style={{
                  width: "31%",
                  textAlign: "center",
                  fontWeight: "bold",
                  color: Colors.fontGreyLight,
                  fontSize: smfontsize,
                }}
              >
                Size
              </div>
            ) : (
              <div
                style={{
                  width: "31%",
                  textAlign: "center",
                  fontWeight: "bold",
                  color: Colors.fontGreyLight,
                  fontSize: smfontsize,
                }}
              >
                Value
              </div>
            )}
            <div
              style={{
                width: "31%",
                textAlign: "center",
                fontWeight: "bold",
                color: Colors.fontGreyLight,
                fontSize: smfontsize,
              }}
            >
              Quantity
            </div>
          </div>
          {values.dropoff[0].items.map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  padding: "10px 0px",
                  textTransform: "capitalize",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "6%",
                    color: "#4D89DA",
                    fontWeight: "600",
                    fontSize: smfontsize,
                  }}
                >
                  # {index + 1}{" "}
                </div>
                <div
                  className="truncate"
                  style={{
                    width: "31%",
                    textAlign: "center",
                    color: "#4D89DA",
                    fontWeight: "600",
                    fontSize: smfontsize,
                  }}
                  data-tip
                  data-for={`review-item-name-${index}`}
                >
                  {item.name}
                </div>
                {!isOrder ? (
                  <div
                    style={{
                      width: "31%",
                      textAlign: "center",
                      color: "#4D89DA",
                      fontWeight: "600",
                      fontSize: smfontsize,
                    }}
                  >
                    {item.size === "xlarge" ? "Extra Large" : item.size}
                  </div>
                ) : (
                  <div
                    style={{
                      width: "31%",
                      textAlign: "center",
                      color: "#4D89DA",
                      fontWeight: "600",
                      fontSize: smfontsize,
                    }}
                  >
                    {item.merchandise_value}
                  </div>
                )}
                <div
                  style={{
                    width: "31%",
                    textAlign: "center",
                    color: "#4D89DA",
                    fontWeight: "600",
                    fontSize: smfontsize,
                  }}
                >
                  {item.quantity}
                </div>
                <ToolTip id={`review-item-name-${index}`} disable={item.name.length > 18 ? false : true} text={item.name} />
              </div>
            );
          })}
        </div>
      </div>
      <div style={{ ...styles.bottomCont, ...{ padding: "10px 0px 10px 0px" } }}>
        <div style={styles.bottomCard}>
          <div style={styles.bottomContTitle}>
            <div
              style={{
                ...styles.bottomCardText,
                ...{ color: Colors.fontGreyLight },
              }}
            >
              Time:
            </div>
            {!isReroute && (
              <button
                type="button"
                onClick={() => {
                  if (isOrder) {
                    setSteps(2);
                  } else {
                    setSteps(3);
                  }
                }}
                style={{
                  ...styles.bottomCardText,
                  ...{ color: Colors.bluePrimary },
                }}
              >
                Edit
              </button>
            )}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <CalendarSvg />{" "}
              <div style={styles.bottomCardValue}>
                {values.initiate === "now"
                  ? "ASAP"
                  : values.initiate === "later"
                  ? "Save Delivery Info"
                  : values.initiate === "scheduled" && "Scheduled"}
              </div>
            </div>
            {values.eta && values.eta !== "Invalid date" && (
              <div
                style={{
                  ...styles.bottomCardText,
                  ...{ color: Colors.bluePrimary },
                }}
              >
                {moment(values.eta).format("D MMM YYYY, hh:mmA") + ", " + momenttz.tz(momenttz.tz.guess()).format("z")}
              </div>
            )}
          </div>
        </div>
      </div>
      {!isOrder && (
        <div style={{ ...styles.bottomCard, ...{ marginBottom: "10px" } }}>
          <div style={styles.bottomContTitle}>
            <div
              style={{
                ...styles.bottomCardText,
                ...{ color: Colors.fontGreyLight },
              }}
            >
              Vehicle:
            </div>
            {!isReroute && (
              <button
                type="button"
                onClick={() => {
                  setSteps(2);
                }}
                style={{
                  ...styles.bottomCardText,
                  ...{ color: Colors.bluePrimary },
                }}
              >
                Edit
              </button>
            )}
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {values.vehicleType === "car" ? (
              <img
                style={{
                  height: isdesktop ? "32px" : "36px",
                  width: isdesktop ? "50px" : "56px",
                  objectFit: "contain",
                }}
                src={car}
                alt=""
              />
            ) : values.vehicleType === "suv" ? (
              <img
                style={{
                  height: isdesktop ? "32px" : "36px",
                  width: isdesktop ? "50px" : "56px",
                  objectFit: "contain",
                }}
                src={van}
                alt=""
              />
            ) : values.vehicleType === "truck" ? (
              <img
                style={{
                  height: isdesktop ? "32px" : "36px",
                  width: isdesktop ? "50px" : "56px",
                  objectFit: "contain",
                }}
                src={truck}
                alt=""
              />
            ) : (
              <img
                style={{
                  height: isdesktop ? "28px" : "34px",
                  width: isdesktop ? "46px" : "52px",
                  objectFit: "contain",
                }}
                src={DefaultVehicle}
                alt=""
              />
            )}
            <div style={styles.bottomCardValue}>
              {values.vehicleType === "truck" ? "Pickup Truck" : values.vehicleType || "Default"}
            </div>
          </div>
        </div>
      )}
      {isReroute && !isMerchantReroute && (
        <div style={styles.rerouteReasonContainer}>
          <div
            style={{
              ...styles.bottomCardText,
              ...{ color: Colors.fontGreyLight },
            }}
          >
            Reroute Reason:
          </div>
          <select
            disabled={false}
            draggable={true}
            value={values.reroute_reason || ""}
            placeholder="Select Reroute Reason"
            onChange={(e) => {
              setFieldValue(`reroute_reason`, e.target.value);
            }}
            style={{
              ...{
                fontFamily: "Poppins",
                borderRadius: "8px",
                fontSize: "14px",
                lineHeight: "21px",
                fontWeight: "400",
                color: Colors.fontDark,
                backgroundColor: Colors.buttonBg,
                padding: "10px",
                minHeight: "43px",
              },
              ...{ backgroundColor: Colors.borderPrimary },
            }}
            className="w-full focus:outline-none "
          >
            <option value="" disabled hidden>
              Select Reroute Reason
            </option>
            <option id="provider_canceled" value="provider_canceled">
              Provider Canceled
            </option>
            <option id="no_driver_assignment" value="no_driver_assignment">
              No Driver Assignment
            </option>
            <option id="customer_request" value="customer_request">
              Customer Request
            </option>
          </select>
          {isClickedOnContinue && !values.reroute_reason ? (
            <p
              style={{
                color: Colors.redDark,
                fontFamily: "Poppins",
                fontSize: "12px",
                padding: "0 10px 3px 12px",
              }}
            >
              Reroute reason is required
            </p>
          ) : null}
        </div>
      )}
      {isOrder && <TipsForOrder />}
    </div>
  );
}

export default ReviewTab;
