import React, { useMemo } from "react";
import { useDesktopOrLaptop } from "../../../../../services/useDesktopOrLaptop";
import { useTheme } from "../../../../../store";


const CardFooter = ({data}) => {
  const { Colors } = useTheme();
  const { xsfontsize } = useDesktopOrLaptop();

  const styles = useMemo(() => {
    return {
      monthContainer: {
        display: "flex",
        justifyContent: "space-between",
        borderTop: `1px solid ${Colors.borderSecondary}`,
        height: "25px",
      },
      monthText: {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: xsfontsize,
        color: Colors.chartMonth,
        marginBottom: "0px",
        marginTop: "5px",
      },
    };
  }, [Colors, xsfontsize]);

  return (
    <div style={styles.monthContainer}>
      <p style={styles.monthText}>{data?.leftLegend || ""}</p>
      <p style={styles.monthText}>{data?.rightLegend || ""}</p>
    </div>
  );
};

export default CardFooter;
