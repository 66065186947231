import { createStore } from "aniuta";
import * as Sentry from "@sentry/react";
import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import { baseUrl } from "../runTimeConst";
import { updateMerchantUser } from "../utils/api/merchantUsers";

const useAccount = createStore({
  name: "useAccount",
  Store: () => {
    const [usersForAccount, setUsersForAccount] = useState();
    const [usersForAccountLoading, setUsersForAccountLoading] = useState(false);
    const [updateMerchantUserLoading, setUpdateMerchantUserLoading] =
      useState(false);

    const getUsersOfAccount = async () => {
      setUsersForAccountLoading(true);
      try {
        const response = await axios.get(`${baseUrl}user/merchant`, {
          headers: { "x-burq-client": "client-dashboard" },
        });
        setUsersForAccount(response.data?.users);
        setUsersForAccountLoading(false);
        return response.data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setUsersForAccountLoading(false);
      }
    };

    const [isInviteUserModalVisible, setIsInviteUserModalVisible] =
      useState(false);
    const [editUserData, setEditUserData] = useState(null);

    const [addingUserTostoreLoading, setAddingUserTostoreLoading] =
      useState(false);

    const postAddUserToAccount = async (payload) => {
      setAddingUserTostoreLoading(true);

      try {
        const response = await axios.post(`${baseUrl}user`, payload, {
          headers: { "x-burq-client": "client-dashboard" },
        });
        return response.data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setAddingUserTostoreLoading(false);
        setEditUserData();
      }
    };

    const deleteUserFromAccount = async (userId) => {
      try {
        const response = await axios.delete(`${baseUrl}user/${userId}`, {
          headers: { "x-burq-client": "client-dashboard" },
        });
        return response;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      }
    };

    const updateMerchantUserWorker = async (userId, payload) => {
      try {
        setUpdateMerchantUserLoading(true);
        const response = await updateMerchantUser(userId, payload);
        return response;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setUpdateMerchantUserLoading(false);
      }
    };

    return {
      isInviteUserModalVisible,
      setIsInviteUserModalVisible,
      postAddUserToAccount,
      addingUserTostoreLoading,
      setAddingUserTostoreLoading,
      //! GET USERS FOR ACCOUNT
      usersForAccount,
      getUsersOfAccount,
      deleteUserFromAccount,
      usersForAccountLoading,
      updateMerchantUserWorker,
      updateMerchantUserLoading,
      editUserData,
      setEditUserData,
    };
  },
});

export default useAccount;
