import React from "react";

function GiftIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 14 14">
      <g fill="#0062FF" clipPath="url(#clip0_16133_7051)">
        <path d="M13.087 2.727h-1.02c.135-.213.224-.452.25-.704A1.796 1.796 0 0011.247.17a1.79 1.79 0 00-1.95.314L8.01 1.651a1.366 1.366 0 00-2.023.002L4.7.485A1.79 1.79 0 002.748.172a1.796 1.796 0 00-1.067 1.851c.025.253.114.491.248.704H.913A.913.913 0 000 3.64v1.37c0 .252.204.456.457.456h13.086A.457.457 0 0014 5.009V3.64a.913.913 0 00-.913-.912zM5.63 2.575v.152H3.483a.9.9 0 01-.873-1.12.896.896 0 01.494-.594.879.879 0 01.983.149l1.544 1.4v.013zm5.78-.673c-.034.474-.462.825-.938.825H8.37v-.152-.016L9.89 1.18c.233-.212.56-.311.863-.221.434.13.69.499.657.942zM.913 6.38v6.695c0 .504.409.913.913.913h4.261V6.379H.913zM7.913 6.38v7.608h4.261a.913.913 0 00.913-.913V6.379H7.913z"></path>
      </g>
      <defs>
        <clipPath id="clip0_16133_7051">
          <path fill="#fff" d="M0 0H14V14H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

function GiftBoxIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="none" viewBox="0 0 30 30">
      <g fill="#0062FF" clipPath="url(#clip0_17466_7415)">
        <path d="M23.79 14.12H11.87L22.28 8.11a.879.879 0 00.322-1.2l-2.637-4.568a.879.879 0 00-1.2-.322l-2.69 1.549A3.52 3.52 0 0010.8.47a3.51 3.51 0 00-1.756 3.166l.093 2.801-2.33-1.452a3.511 3.511 0 00-3.619-.062A3.492 3.492 0 001.551 7.06a3.493 3.493 0 00.352 2.668c.308.535.742.968 1.261 1.272L.44 12.567a.879.879 0 00-.318 1.208l2.695 4.567a.879.879 0 001.197.315l1.318-.761V29.12c0 .485.394.879.879.879h17.578a.879.879 0 00.879-.88V15a.879.879 0 00-.879-.88zM14.12 15.88h1.758v5.788l-.258-.257a.879.879 0 00-1.242 0l-.258.257V15.88zM11.68 1.994a1.76 1.76 0 012.401.643c.462.8.226 1.848-.505 2.37L10.9 6.548l-.098-2.97a1.756 1.756 0 01.878-1.583zM4.492 9.667a1.746 1.746 0 01-1.067-.819 1.746 1.746 0 01-.176-1.334 1.757 1.757 0 012.63-1.036l2.469 1.538L5.58 9.61a1.744 1.744 0 01-1.088.058zm-.603 7.032l-1.802-3.052 16.796-9.666 1.758 3.046L3.89 16.7zM22.91 28.242H7.09V16.881l1.735-1.002h3.538v7.91a.879.879 0 001.5.621L15 23.274l1.136 1.136a.879.879 0 001.5-.621v-7.91h5.274v12.363zM29.121 10.605h-.879v-.878a.879.879 0 00-1.758 0v.878h-.879a.879.879 0 000 1.758h.88v.88a.879.879 0 001.757 0v-.88h.88a.879.879 0 000-1.758zM25.547 7.09a.879.879 0 100-1.758.879.879 0 000 1.758z"></path>
        <path d="M22.031 12.363a.879.879 0 100-1.758.879.879 0 000 1.758z"></path>
      </g>
      <defs>
        <clipPath id="clip0_17466_7415">
          <path fill="#fff" d="M0 0H30V30H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export { GiftIcon, GiftBoxIcon };
