import React, { useMemo } from "react";
import { useAnalyticsStore, useTheme } from "../../../../../store";
import { numberWithCommas } from "../../../utils";

const KpisSection = () => {
  const { Colors } = useTheme();
  const { overviewKPIs } = useAnalyticsStore();

  const styles = useMemo(() => {
    return {
      container: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        paddingBottom: "50px",
      },
      kpiTitle: {
        fontFamily: "Poppins",
        fontWeight: 600,
        fontSize: "15px",
        lineHeight: "22px",
        marginBottom: "12px",
        color: Colors.chartSecondLine,
      },
      kpiValue: {
        fontFamily: "Poppins",
        fontWeight: 600,
        fontSize: "20px",
        lineHeight: "22px",
        marginBottom: "12px",
        color: Colors.fontDark,
      },
    };
  }, [Colors]);

  return (
    <div style={styles.container}>
      <div>
        <div style={styles.kpiTitle}>Completed</div>
        <span style={styles.kpiValue}>
          {overviewKPIs?.delivered?.count &&
            numberWithCommas(overviewKPIs?.delivered?.count)}
        </span>
      </div>
      <div>
        <div style={styles.kpiTitle}>Scheduled</div>
        <span style={styles.kpiValue}>
          {overviewKPIs?.scheduled?.count &&
            numberWithCommas(overviewKPIs?.scheduled?.count)}
        </span>
      </div>
      {/* <div>
        <div style={styles.kpiTitle}>Cost</div>
        <span style={styles.kpiValue}>
          $
          {overviewKPIs?.totalCost &&
            numberWithCommas((overviewKPIs?.totalCost / 100).toFixed(2))}
        </span>
      </div> */}
    </div>
  );
};

export default KpisSection;
