import Cookies from "js-cookie";
import React, { useState } from "react";
import PlacesAutocomplete, { geocodeByAddress } from "react-places-autocomplete";
import { useMyStore } from "../store";
import { IoCloseSharp } from "react-icons/io5";
import CommonInput from "../components/CommonInput";
// import Colors from "../assets/Colors";
import { useTheme } from "../store";
import CustomFormikPhoneNumber from "../components/CustomFormikPhoneNumber";
import PhoneInput from "react-phone-input-2";
import FormikNewTextInput from "../components/formikFields/FormikNewTextInput";
import { useDesktopOrLaptop } from "../services/useDesktopOrLaptop";

function AddEditAddress({ setIsEditAddAddressVisible, item, setitemForEditModal, deleteSavedAddress, addressId }) {
  const { Colors, themeChanger } = useTheme();
  const [isPhoneValid, setIsPhoneValid] = useState(true);

  const { isdesktop, smfontsize, mdfontsize } = useDesktopOrLaptop();

  const styles = {
    nameInputs: {
      height: isdesktop ? "38px" : "52px",
      width: "230px",
      borderRadius: "10px",
      borderWidth: "1px",
      borderColor: Colors.borderPrimary,
      // marginRight: "32px",
      marginTop: "16px",
      paddingLeft: "20px",
      paddingRight: "20px",
      fontFamily: "Roboto",
      fontSize: smfontsize,
      fontWeight: "400",
      color: Colors.fontDark,
      backgroundColor: Colors.white,
    },
  };
  const { postSaveAddres } = useMyStore();
  const [changedName, setChangedName] = useState((item?.firstName || "") + (item?.lastName || "") || "");
  const [changedLastName, setChangedLastName] = useState(item?.lastName || "");
  const [changedPhone, setChangedPhone] = useState(item?.phoneNumber || "");
  const [changedCompanyName, setChangedCompanyName] = useState(item?.businessName || "");
  const [changedNote, setChangedNote] = useState(item?.notes || "");
  const [changedAddress, setChangedAddress] = useState(item?.address || "");
  const [changedCustomerMail, setChangedCustomerMail] = useState(item?.customerEmail || "");
  const [reviewed, setReviewed] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const pickupHandleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    // const latLng = await getLatLng(results[0]);
    setChangedAddress(results[0].formatted_address);
    // setPickupCoordinates(latLng);
  };
  return (
    <div style={{ backgroundColor: Colors.white }} className="h-full p-5 flex flex-col justify-between ">
      <div className="">
        <div className="w-full flex flex-row items-center justify-between">
          <div
            style={{
              fontFamily: "Poppins",
              fontSize: smfontsize,
              fontWeight: "600",
              color: Colors.fontDark,
            }}
            className="font-semibold text-xl  "
          >
            New Address
          </div>
          <button
            id="closeAddNewAddressModalButtonId"
            type="button"
            onClick={() => {
              setIsEditAddAddressVisible(false);
              setitemForEditModal();
            }}
          >
            <IoCloseSharp color={Colors.fontDark} size={22} />
          </button>
        </div>
        <div className="mb-4">
          {/* <div className="uppercase font-bold text-xs mt-6">contact info</div> */}
          <PlacesAutocomplete value={changedAddress} onChange={setChangedAddress} onSelect={pickupHandleSelect}>
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
              return (
                <>
                  <input
                    {...getInputProps({
                      placeholder: reviewed && !changedAddress ? "Please enter Pickup Address" : "Pickup Address *",
                    })}
                    className={`${themeChanger ? "focus:border-gray-400" : "focus:border-gray-300"}  border border-transparent`}
                    style={{
                      borderRadius: "8px",
                      fontSize: smfontsize,
                      fontWeight: "400",
                      color: Colors.fontDark,
                      padding: "10px 20px",
                      outline: "none",
                      width: "100%",
                      fontFamily: "Poppins",
                      backgroundColor: Colors.borderPrimary,
                      marginTop: "30px",
                      borderWidth: "1px",
                      borderColor: reviewed && !changedAddress ? "red" : "transparent",
                    }}
                  />

                  <div className="w-full">
                    {loading ? <div>...loading</div> : null}
                    {suggestions.map((sug) => {
                      const className = sug.active ? "bg-blue-400 text-blue-50" : "";
                      const style = { color: Colors.fontDark };
                      return (
                        <div
                          key={sug.index}
                          {...getSuggestionItemProps(sug, {
                            className,
                            style,
                          })}
                        >
                          {sug.description}
                        </div>
                      );
                    })}
                  </div>
                </>
              );
            }}
          </PlacesAutocomplete>
          <div style={{ marginTop: "16px" }} className="flex flex-row w-full justify-between mt-2">
            <div style={{ width: "100%" }}>
              <div
                style={{
                  fontWeight: "600",
                  fontFamily: "Poppins",
                  fontSize: smfontsize,
                  color: Colors.fontDark,
                }}
              >
                Name
              </div>
              <CommonInput
                placeholder="Name *"
                id="addNewAddressNameId"
                className={`${themeChanger ? "focus:border-gray-400" : "focus:border-gray-300"}  border border-transparent`}
                style={{
                  borderRadius: "8px",
                  fontSize: smfontsize,
                  fontWeight: "400",
                  color: Colors.fontDark,
                  padding: "10px 20px",
                  outline: "none",
                  width: "100%",
                  fontFamily: "Poppins",
                  backgroundColor: Colors.borderPrimary,
                  marginTop: "20px",
                  borderColor: reviewed && !changedName ? "red" : "transparent",
                }}
                type="text"
                value={changedName}
                onChange={(e) => {
                  setChangedName(e.target.value);
                }}
                onBlur={() => {
                  if (changedName.trim() === " ") {
                    setChangedName();
                  } else {
                    setChangedName((val) => val.trim());
                  }
                }}
              />
            </div>
          </div>
          <div style={{ width: "100%", marginTop: "30px" }}>
            <div
              style={{
                fontWeight: "600",
                fontFamily: "Poppins",
                fontSize: smfontsize,
                color: Colors.fontDark,
              }}
            >
              Customer Email
            </div>
            <CommonInput
              placeholder="Email Address"
              id="addNewAddressEmailId"
              className={`${themeChanger ? "focus:border-gray-400" : "focus:border-gray-300"}  border border-transparent`}
              style={{
                borderRadius: "8px",
                fontSize: smfontsize,
                fontWeight: "400",
                color: Colors.fontDark,
                padding: "10px 20px",
                outline: "none",
                width: "100%",
                fontFamily: "Poppins",
                backgroundColor: Colors.borderPrimary,
                marginTop: "20px",
                borderColor: reviewed && !isEmailValid ? "red" : "transparent",
              }}
              type="text"
              value={changedCustomerMail}
              onChange={(e) => {
                const emailValue = e.target.value;
                setChangedCustomerMail(emailValue);
                if (!emailValue) {
                  setIsEmailValid(true);
                  return;
                }
                const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                if (emailValue.trim().length < 5) {
                  // If the email length is less than 5 characters, set validation to false
                  setIsEmailValid(false);
                } else if (emailPattern.test(emailValue)) {
                  // If the value matches the email pattern, set validation to true
                  setIsEmailValid(true);
                } else {
                  // Otherwise, set validation to false
                  setIsEmailValid(false);
                }
              }}
              onBlur={() => {
                if (changedCustomerMail.trim() === " ") {
                  setChangedCustomerMail();
                  setIsEmailValid(true);
                } else {
                  setChangedCustomerMail((val) => val.trim());
                }
              }}
            />
          </div>
        </div>
        <div className="flex flex-row w-full justify-between items-center">
          <div style={{ width: "45%", display: "flex" }}>
            <div style={{ width: "100%" }}>
              <div
                style={{
                  fontWeight: "600",
                  fontFamily: "Poppins",
                  fontSize: smfontsize,
                  color: Colors.fontDark,
                }}
              >
                Phone Number
              </div>
              <PhoneInput
                countryCodeEditable={false}
                isValid={(value) => {
                  if (value.trim().length < 3) {
                    setIsPhoneValid(false);
                  } else if (
                    value.match(/^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/) &&
                    value.trim().length === 11
                  ) {
                    setIsPhoneValid(true);
                  } else {
                    setIsPhoneValid(false);
                  }
                }}
                autoFormat
                country={"us"}
                onlyCountries={["us"]}
                onChange={(value, country, e, formattedValue) => {
                  setChangedPhone(formattedValue.trim());
                  // console.log("formattedValue", formattedValue.trim());
                }}
                value={changedPhone}
                buttonStyle={{
                  borderWidth: "0px",
                  backgroundColor: Colors.borderPrimary,
                }}
                disableDropdown
                inputStyle={{
                  borderWidth: "0px",
                  fontFamily: "Poppins",
                  borderRadius: "8px",
                  fontSize: "14px",
                  fontWeight: "400",
                  color: Colors.fontDark,
                  backgroundColor: Colors.borderPrimary,
                  width: "100%",
                  padding: "18px 40px",
                }}
                placeholder="+1 (###) ###-####"
                style={{
                  marginTop: "20px",
                  padding: "1px",
                  paddingLeft: "2px",
                  borderColor: (reviewed && !isPhoneValid) || (reviewed && !changedPhone) ? "red" : Colors.borderPrimary,
                  borderWidth: (reviewed && !isPhoneValid) || (reviewed && !changedPhone) ? "1px" : "0px",
                  borderRadius: "8px",
                }}
              />
            </div>
          </div>
        </div>
        {/* <div
          style={{ marginTop: "0px" }}
          className="flex flex-col w-full justify-between mt-2"
        > */}
        <textarea
          maxLength={"200"}
          className={`${themeChanger ? "focus:border-gray-400" : "focus:border-gray-300"}  border border-transparent`}
          style={{
            borderRadius: "8px",
            fontSize: smfontsize,
            fontWeight: "400",
            color: Colors.fontDark,
            padding: "10px 20px",
            outline: "none",
            width: "100%",
            fontFamily: "Poppins",
            backgroundColor: Colors.borderPrimary,
            marginTop: "30px",
            // borderColor: reviewed && !changedName ? "red" : "transparent",
          }}
          value={changedNote}
          onChange={(e) => {
            setChangedNote(e.target.value);
          }}
          rows="3"
          placeholder={"Add instructions"}
        ></textarea>
        {/* </div> */}
      </div>
      <div className=" w-full flex flex-row items-center justify-end  px-7">
        <button
          id="addNewAddressSaveButtonId"
          style={{
            height: "44px",
            width: "120px",
            fontFamily: "Poppins",
            fontSize: mdfontsize,
            fontWeight: "600",
            color: "#FAFAFB",
            borderRadius: "8px",
            marginTop: "30px",
          }}
          className="bg-blue-600  "
          type="button"
          onClick={async () => {
            setReviewed(true);

            if (
              changedAddress &&
              changedName &&
              // changedLastName &&
              changedPhone &&
              isPhoneValid
            ) {
              if (changedCustomerMail && !isEmailValid) {
                return;
              }
              if (item) {
                await deleteSavedAddress(addressId);
                setIsEditAddAddressVisible(false);
                await postSaveAddres(
                  changedAddress,
                  changedName,
                  // changedLastName,
                  changedPhone,
                  changedCompanyName,
                  changedNote,
                  changedCustomerMail
                );
                setitemForEditModal();
              } else {
                await postSaveAddres(
                  changedAddress,
                  changedName,
                  // changedLastName,
                  changedPhone,
                  changedCompanyName,
                  changedNote,
                  changedCustomerMail
                );
                setIsEditAddAddressVisible(false);
              }
            }
          }}
        >
          Save
        </button>
        {/* <button
          className="bg-red-600 px-8 py-2 rounded-lg text-gray-50 text-lg font-semibold "
          type="button"
          onClick={() => {
            setIsEditAddAddressVisible(false);
          }}
        >
          Delete
        </button> */}
      </div>
    </div>
  );
}

export default AddEditAddress;
