import moment from "moment";
import React from "react";
import { useTheme } from "../../../../../store";
import { useDesktopOrLaptop } from "../../../../../services/useDesktopOrLaptop";
import { numberWithCommas } from "../../../../../utils/helpers";
import { BiDetail } from "react-icons/bi";
import styles from './styles.module.css';
import classNames from "classnames";

const RecentTrxItem = ({ invoiceObject, activeId, setActiveId }) => {
  const { Colors, themeChanger } = useTheme();
  const { mdfontsize, smfontsize } = useDesktopOrLaptop();

  return (
    <div className={classNames("w-full", styles.itemCont)}>
      <div
        style={{
          padding: "30px 0px",
          borderBottomWidth: "1.5px",
          borderColor: Colors.borderPrimary,
        }}
        className={`grid grid-cols-12 gap-1 w-full pb-2 items-center ${themeChanger ? "hover:bg-gray-100" : "hover:bg-gray-800"
          }`}
      >
        <div
          style={{
            fontSize: mdfontsize,
            fontWeight: "400",
            color: Colors.fontGreyLight,
          }}
          className="col-start-1 col-span-4 text-center flex justify-center items-center"
        >
          {invoiceObject.title}
        </div>
        <div
          style={{
            fontSize: mdfontsize,
            fontWeight: "400",
            color: Colors.fontGreyLight,
          }}
          className="col-start-5 col-span-4 text-center"
        >
          {invoiceObject?.settled_at
            ? moment(invoiceObject.settled_at).format("MMM DD, yyyy")
            : ""}
        </div>
        <div
          style={{
            fontSize: mdfontsize,
            fontWeight: "400",
            color: Colors.fontGreyLight,
          }}
          className="col-start-10 col-span-2 text-center"
        >
          $
          {invoiceObject?.amount
            ? numberWithCommas((invoiceObject?.amount / 100)?.toFixed(2))
            : "0"}
        </div>
        <div
          style={{
            fontSize: smfontsize,
            fontWeight: "400",
            color: Colors.fontDark,
          }}
          className="col-start-12 col-span-1 cursor-pointer"
          onClick={() => {
            if (invoiceObject.id === activeId) {
              setActiveId();
            } else {
              setActiveId(invoiceObject.id);
            }
          }}
        >
          {!!invoiceObject?.journal_entries && invoiceObject.journal_entries.length > 0 && (
            <BiDetail color={Colors.fontDark} size={22} />
          )}
        </div>
      </div>
      <div className="w-full px-7 pr-14">
        {activeId === invoiceObject.id && (
          <table className={styles.gridTable}>
            <thead>
              <tr>
                <th></th>
                <th>Charged</th>
                <th>Refunded</th>
              </tr>
            </thead>
            <tbody>
              {invoiceObject.journal_entries.map((row) => (
                <tr key={`je-${row.display_name}`}>
                  <td className="font-semibold">{row.display_name}</td>
                  <td>${numberWithCommas((row.amount / 100).toFixed(2))}</td>
                  <td>
                    ${numberWithCommas((row.reversed_amount / 100).toFixed(2))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default RecentTrxItem;
