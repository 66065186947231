import React from 'react';
import { useInvoices } from '../../../../store';
import StatusBadgeReusable from '../../../shared/common/StatusBadgeReusable';
import { numberWithCommas } from '../../../../utils/helpers';
import moment from 'moment';
import styles from "./styles/activities.module.css";
import { statusesBadgesForStatements } from '../../utlis';
import classNames from 'classnames';

const Activities = () => {
  const {
    selectedInvoice,
  } = useInvoices();

  const combinedPayments = [...selectedInvoice.payments, ...selectedInvoice.process_payment_attempts]
    .slice()
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

  if (combinedPayments.length <= 0) {
    return (
      <div className={styles.cardNumbers}>
        This invoice has no payment
      </div>
    );
  }

  return (<div className={classNames(styles.paymentsScrollable, "kata")}>
    {combinedPayments.map((item) => (
      <div className="my-6" key={item.id}>
        <div className={styles.paymentItem}>
          <div>
            <StatusBadgeReusable
              badgesObject={statusesBadgesForStatements}
              status={item.object === "process_payment_attempt" ? "failed" : item.status}
              externalClassName={styles.status}
            />
            <div className={styles.cardNumbers}>
              {item.failure_reason
                ? item.failure_reason
                : !item?.payment_method
                  ? item?.is_paid_out_of_band
                    ? "Paid out-of-band"
                    : "No payment method data"
                  : "x" + item.payment_method?.public_identifier}
            </div>
          </div>
          {item.object === "payment" && <div className={styles.amount}>
            ${numberWithCommas((item.amount / 100).toFixed(2))}
          </div>}
        </div>
        <div className={styles.cardNumbers}>
          {item.object === "process_payment_attempt" ? 'Attempted on ' : 'Processed on '}
          {moment(item.created_at).format("MMM DD, YYYY")}
        </div>
      </div>
    ))}
  </div>)
};

export default Activities;
