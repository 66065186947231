import { useState } from "react";
import * as Sentry from "@sentry/react";
import { createStore } from "aniuta";
import {
  assigneAdminToIncident,
  createIncident,
  getIncidentPresignedUrl,
  getIncidents,
  updateIncident,
} from "../utils/api/incidents";
import { toast } from "react-toastify";
import { getDeliveryById } from "../utils/api/deliveries";
import { createMerchantIncident, getIncidentReasons } from "../utils/api/merchantIncidents";
import axios from "axios";

const useMerchantIncidents = createStore({
  name: "useMerchantIncidents",
  Store: () => {
    // const getAllIncidentsForMerchant = async (params) => {
    //   try {
    //     const { data } = await getIncidents(params);

    //     return data;
    //   } catch (error) {
    //     if (error?.response?.data?.message) {
    //       toast.error(error?.response?.data?.message);
    //     } else {
    //       Sentry.captureException(error);
    //       toast.error("Something went wrong! Please try again.");
    //     }
    //   } finally {
    //   }
    // };
    const getIncidentReasonsHandler = async () => {
      try {
        const { data } = await getIncidentReasons();

        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
      }
    };

    const createMerchantIncidentHandler = async (id, payload) => {
      try {
        const { data } = await createMerchantIncident(id, payload);
        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      }
    };

    const getPresignedUrl = async (fileName) => {
      const payload = {
        original_file_name: fileName,
      };
      const response = await getIncidentPresignedUrl(payload);

      return response.data;
    };

    const uploadToS3 = async (file, presignedUrl, setProgress) => {
      const response = await axios.put(presignedUrl, file, {
        headers: {
          "Content-Type": file.type,
          "x-burq-client": "client-dashboard",
        },
        // onUploadProgress: (progressEvent) => {
        //   setProgress((prevProgress) => ({
        //     ...prevProgress,
        //     [file.name]: Math.round((progressEvent.loaded * 100) / progressEvent.total),
        //   }));
        // },
      });
      return response;
    };

    return { getIncidentReasonsHandler, createMerchantIncidentHandler, getPresignedUrl, uploadToS3 };
  },
});

export default useMerchantIncidents;
