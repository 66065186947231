import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./styles/colors.css";
import "./styles/font-sizes.css";
import App from "./App";
import axios from "axios";
import * as Sentry from "@sentry/react";
import { mapEnvironment } from "./utils/helpers";
axios.defaults.withCredentials = true;

Sentry.init({
  environment: mapEnvironment(process.env.REACT_APP_ENVIRONMENT),
  dsn: "https://2a22c032ecb84483b69c838a8798fcc4@o1083182.ingest.sentry.io/6102557",
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        // "localhost",
        // /^https:\/\/preprod-api\.burqup\.com/,
        /^https:\/\/api\.burqup\.com/,
      ],
    }),
  ],
  tracesSampleRate: 1.0,
  // beforeSend: (event, hint) => {
  //   // Check if the error has been manually captured
  //   if (hint && hint.originalException) {
  //     // Allow the event to be sent to Sentry
  //     return event;
  //   }
  //   // Prevent automatic error capturing by returning null
  //   return null;
  // },

  captureUnhandledRejections: false, // Disable capturing unhandled rejections
  autoBreadcrumbs: false, // Disable automatic breadcrumb tracking
  autoSessionTracking: false, // Disable automatic session tracking
});

ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
