import * as Yup from "yup";

export const defaultValidationSchema = Yup.lazy((value) => {
  if (!!value) {
    const newEntries = Object.keys(value).reduce(
      (acc, val) => ({
        ...acc,
        [val]: Yup.string().required("Please provide your answer"),
      }),
      {}
    );

    return Yup.object().shape(newEntries);
  }
  return Yup.mixed().notRequired();
});
