import React from "react";
import styles from "./styles/paymentHistoryLoading.module.css";

const PaymentHistoryEmptyState = () => {
  return (
    <tr className={styles.tableRow}>
      <td className="py-4 px-4 text-center" colSpan="5">
        No transactions Found!
      </td>
    </tr>
  );
};

export default PaymentHistoryEmptyState;
