import * as Yup from "yup";

export const defaultValidationSchema = Yup.object().shape({
    is_active: Yup.boolean().required(),
    secrets: Yup.array().of(
      Yup.object().shape({
        property_name: Yup.string().required(""),
        property_value: Yup.string()
          .nullable()
          .required("This value is required"),
      })
    ),
  });
