import React from "react";
import {
  AllDeliveriesSvg,
  AllCompletedSvg,
  AllCanceledSvg,
  AllPaymentSvg,
} from "../../assets/svgs/Svgs";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import { useTheme } from "../../store";

function AnalyticsMiniCardsNew({ usd, title, sum, percent }) {
  const { Colors, themeChanger } = useTheme();
  const { isdesktop, mdfontsize } = useDesktopOrLaptop();

  return (
    <div
      style={{
        width: isdesktop ? "100%" : "48%",
        paddingTop: "30px",
        paddingBottom: "30px",
        position: "relative",
        borderRadius: "10px",
        // backgroundColor: Colors.bgDarkSecondary,
      }}
      className=" flex justify-center items-center "
    >
      <div
        // style={{ paddingTop: "30px", paddingBottom: "30px" }}
        className="w-1/3 "
      >
        {title === "Deliveries" ? (
          <AllDeliveriesSvg />
        ) : title === "Completed" ? (
          <AllCompletedSvg />
        ) : title === "Canceled" ? (
          <AllCanceledSvg themeChanger={themeChanger} />
        ) : (
          title === "Payments" && <AllPaymentSvg />
        )}
      </div>
      <div>
        <h2
          style={{
            fontFamily: "Poppins",
            fontSize: mdfontsize,
            color: Colors.bluePrimary,
          }}
          className="font-semibold  text-gray-700 truncate"
        >
          {title}
        </h2>
        <div
          style={{
            fontFamily: "Poppins",
            fontSize: mdfontsize,
            color: Colors.fontGreyLight,
          }}
          className="w-full flex mt-3"
        >
          <div className="w-1/2 font-semibold ">
            {usd && "$"}
            {sum && sum}
          </div>
        </div>
        {/* <div
          className={` ${
            percent < 0 ? "text-red-500" : "text-green-600"
          } flex items-center font-semibold `}
        >
          {percent}%
          {percent > 0 ? <AiOutlineArrowUp /> : <AiOutlineArrowDown />}
        </div> */}
      </div>
      {title !== "Canceled" && (
        <div
          style={{
            height: "50%",
            width: "2px",
            backgroundColor: Colors.fontGreyLight,
            justifySelf: "end",
            position: "absolute",
            right: 0,
          }}
        ></div>
      )}
    </div>
  );
}

export default AnalyticsMiniCardsNew;
