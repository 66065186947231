import React from "react";
import styles from "./styles.module.css"; // Importing the CSS module
import classNames from "classnames";

const ToolTip = ({
  heading = "",        // Optional heading
  description = "",    // Optional description
  place = "top",       // Tooltip position
  disable = false,     // Disable flag
  textStyles,          // Inline styles for text
  children,
  extraHeadingClass,
  extraDescriptionClass,
  extraTooltipClass,
}) => {

  if (disable || (!heading && !description)) return children;
  return (
    <div className={styles.tooltipWrapper}>
      <div className={classNames(styles.tooltip, styles.left, extraTooltipClass)}
      >
        <div className={styles.tooltipContent}>
          {heading && <h4 className={styles.heading}>{heading}</h4>}
          {description && <p className={styles.description}>{description}</p>}
        </div>
        <div className={styles.tooltipArrow}></div>
      </div>
      {children}
    </div>
  );
};

export default ToolTip;
