import React, { useMemo } from "react";
import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";
import { useTheme } from "../../../store";

const OverviewTitle = () => {
  const { Colors } = useTheme();
  const { px24, isdesktop } = useDesktopOrLaptop();

  const styles = useMemo(() => {
    return {
      container: {
        display: "flex",
        justifyContent: "space-between",

        margin: isdesktop ? "0px 0px 26px 0px" : "16px 0px 26px 0px",
      },
      headingText: {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: px24,
        lineHeight: "36px",
        color: Colors.fontDark,
      },
    };
  }, [Colors]);

  return (
    <div style={styles.container}>
      <h1 style={styles.headingText}>Overview</h1>
    </div>
  );
};

export default OverviewTitle;
