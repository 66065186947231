import classNames from "classnames";
import React from "react";
import styles from "./styles.module.css";

const DeliveryItemsOnSideDrawer = ({ items }) => {
  return (
    <div className={classNames("my-3", styles.container)}>
      <div className={classNames("grid grid-cols-4 gap-1", styles.titles)}>
        <div className={"text-center"}>Index</div>
        <div className={"text-center"}>Item name</div>
        <div className={"text-center"}>Size</div>
        <div className={"text-center"}>Quantity</div>
      </div>
      {items?.map((item, index) => {
        return (
          <div
            key={index}
            className={classNames("grid grid-cols-4 gap-1 my-3", styles.items)}
          >
            <div className={"text-center"}>
              <span className={styles.sharp}></span>
              {index + 1}
            </div>
            <div className={"text-center"}>{item.name}</div>
            <div className={"text-center"}>{item.size}</div>
            <div className={"text-center"}>{item.quantity}</div>
          </div>
        );
      })}
    </div>
  );
};

export default DeliveryItemsOnSideDrawer;
