import { createStore } from "aniuta";
import { useState } from "react";

const useQuoteWorkflow = createStore({
  name: "useQuoteWorkflow",
  Store: () => {
    const [selectedQuoteItem, setSelectedQuoteItem] = useState("");

    return {
      selectedQuoteItem,
      setSelectedQuoteItem,
    };
  },
});

export default useQuoteWorkflow;
