import React, { useState } from 'react';
import { AiOutlineClear } from 'react-icons/ai';
import { MdStorefront } from 'react-icons/md';
import StoreFilterOptionList from './StoreFilterOptionList';
import { useDesktopOrLaptop } from '../../../../../services/useDesktopOrLaptop';
import { useInvoices, useTheme } from '../../../../../store';

const StoreFilter = () => {
    const { isdesktop, smfontsize } = useDesktopOrLaptop();
    const { Colors } = useTheme();
    const { filterStore, setFilterStore, excludeStoreTransactions, setExcludeStoreTransactions } = useInvoices();
    const [isStoresListVisible, setIsStoresListVisible] = useState(false);


    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                width: !isdesktop && "100%",
                marginRight: '20px',
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: !isdesktop && "100%",
                    justifyContent: "end",
                }}
                className="items-center w-full"
            >
                <div
                    style={{
                        position: "relative",
                    }}
                    className="flex items-center"
                >
                    <button
                        style={{
                            borderWidth: "1px",
                            borderColor: "#D8D8D8",
                            borderRadius: "16px",
                            padding: isdesktop ? "4px 0px" : "4px 4px",
                            marginLeft: "0px",
                            borderStyle: filterStore ? "solid" : "dashed",
                        }}
                        onClick={() => {
                            setIsStoresListVisible(true);
                        }}
                        type="button"
                        className="flex flex-row items-center focus:outline-none "
                    >
                        {(filterStore || excludeStoreTransactions) ? (
                            <button
                                type="button"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setFilterStore();
                                    setExcludeStoreTransactions(false);
                                }}
                                style={{ padding: "2px 0px 2px 10px" }}
                            >
                                <AiOutlineClear
                                    color={Colors.fontGreyLight}
                                    size={isdesktop ? 22 : 26}
                                />
                            </button>
                        ) : (
                            <div style={{ padding: "2px 0px 2px 10px" }}>
                                <MdStorefront color={Colors.fontGreyLight} size={19} />
                            </div>
                        )}
                        <div
                            style={{
                                fontFamily: "Poppins",
                                fontSize: smfontsize,
                                color: Colors.fontGreyLight,
                                display: "flex",
                                alignItems: "center",
                                padding: filterStore
                                    ? "2px 6px 2px 0px"
                                    : "2px 10px 2px 0px",
                            }}
                        >
                            <span style={{ marginLeft: "8px" }}>Store</span>
                        </div>
                        {(filterStore || excludeStoreTransactions) && (
                            <div
                                style={{
                                    padding: "2px 10px 2px 6px",
                                    color: Colors.fontGreyLight,
                                    maxWidth: "170px",
                                    fontSize: smfontsize,
                                }}
                                className="border-l border-[#D8D8D8] border-dashed truncate"
                            >
                                {excludeStoreTransactions ? "Transactions without Store": filterStore.name}
                            </div>
                        )}
                    </button>

                    <StoreFilterOptionList
                        isStoresListVisible={isStoresListVisible}
                        setIsStoresListVisible={setIsStoresListVisible}
                    />
                </div>
            </div>
        </div>
    );
};

export default StoreFilter;
