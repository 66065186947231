import * as React from "react";
import { useTheme } from "../../store";

function DeliveryBox({color}) {
  const { Colors } = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 16 16"
    >
      <g clipPath="url(#clip0_11476_5723)">
        <path
          fill={color ?? Colors.fontDark}
          d="M.43 3.405a.5.5 0 01.31-.463L7.81.038a.5.5 0 01.38 0l7.07 2.904a.5.5 0 01.31.463v9.19a.5.5 0 01-.31.463l-7.07 2.905a.5.5 0 01-.38 0L.74 13.057a.5.5 0 01-.31-.463v-9.19zM8 5.811l2.605-1.07-5.63-2.457-2.78 1.142L8 5.811zm6.57-1.617l-2.248.923v2.605a.5.5 0 01-1.001 0V5.53L8.5 6.687v8.066l6.069-2.494V4.194zM8 1.042l-1.735.713 5.63 2.456 1.91-.785L8 1.042zm-.5 5.645L1.43 4.194v8.065l6.07 2.494V6.687z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_11476_5723">
          <path
            fill="#fff"
            d="M0 0H16V16H0z"
            transform="matrix(-1 0 0 1 16 0)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

function GroupUp() {
  const { Colors } = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      fill="none"
      viewBox="0 0 10 10"
    >
      <path
        fill={Colors.fontDark}
        d="M9.637 2.243a.364.364 0 00-.007-.068l-.002-.01a.365.365 0 00-.048-.114l-.01-.018a.385.385 0 00-.042-.05h-.001L7.668.11A.378.378 0 007.4 0H2.197c-.102 0-.2.041-.27.114L.106 1.987a.38.38 0 00-.04.048l-.009.015a.382.382 0 00-.047.113l-.004.014a.37.37 0 00-.006.066L0 2.25v7.373C0 9.83.169 10 .377 10H9.26c.21 0 .378-.169.378-.377V2.25l-.001-.007zm-1.284-.37H5.196V.754h2.048l1.11 1.117zM2.356.754h2.085v1.117h-3.17L2.355.755zm6.527 8.49H.755V2.627h8.128v6.618z"
      ></path>
      <path
        fill={Colors.fontDark}
        d="M3.348 6.144a.377.377 0 00.267-.11l.826-.827v2.371a.377.377 0 10.755 0V5.207l.827.826a.377.377 0 10.534-.534L5.086 4.03c-.005-.006-.01-.009-.016-.014a.375.375 0 00-.251-.097c-.016 0-.032.003-.048.005-.008 0-.016 0-.024.002a.375.375 0 00-.176.088l-.019.016-1.471 1.47a.377.377 0 00.267.645z"
      ></path>
    </svg>
  );
}

function GroupDown() {
  const { Colors } = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      fill="none"
      viewBox="0 0 10 10"
    >
      <path
        fill={Colors.fontDark}
        d="M9.64 2.242a.375.375 0 00-.008-.067l-.002-.01a.35.35 0 00-.048-.115l-.01-.016a.38.38 0 00-.042-.05v-.001L7.67.11A.378.378 0 007.402 0H2.197c-.102 0-.2.041-.27.114L.107 1.986a.38.38 0 00-.04.049l-.009.015a.382.382 0 00-.047.113l-.004.013a.374.374 0 00-.006.067L0 2.25v7.374C0 9.83.169 10 .378 10h8.884c.209 0 .378-.17.378-.377V2.249v-.007zm-1.285-.37H5.198V.755h2.047l1.11 1.117zM2.357.755h2.085v1.117H1.271L2.357.755zm6.528 8.49H.755V2.627h8.13v6.618z"
      ></path>
      <path
        fill={Colors.fontDark}
        d="M4.553 7.84v.001a.376.376 0 00.534 0L6.56 6.37a.377.377 0 10-.534-.534l-.827.826V4.291a.377.377 0 00-.755 0v2.372l-.827-.826a.378.378 0 00-.534.533l1.47 1.47z"
      ></path>
    </svg>
  );
}

function CarIcon({ isSelected }) {
  const { Colors } = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 42 30"
    >
      <path
        fill={isSelected ? Colors.bluePrimary : Colors.fontGreyLight}
        d="M10.172 21.707a2.543 2.543 0 100 5.086 2.543 2.543 0 000-5.086zm0 3.938a1.395 1.395 0 110-2.79 1.395 1.395 0 010 2.79zM30.844 21.707a2.543 2.543 0 100 5.086 2.543 2.543 0 000-5.086zm0 3.938a1.395 1.395 0 110-2.79 1.395 1.395 0 010 2.79z"
      ></path>
      <path
        fill={isSelected ? Colors.bluePrimary : Colors.fontGreyLight}
        d="M38.596 14.692l-6.826-.745-6.025-5.945a2.527 2.527 0 00-1.786-.732H7.356a2.554 2.554 0 00-2.188 1.246l-3.09 5.223c-.233.393-.355.84-.355 1.296v7.762a.574.574 0 00.375.54l3.583 1.32a4.508 4.508 0 008.962.167h11.73a4.507 4.507 0 008.942 0h4.388a.574.574 0 00.574-.574v-7.682a1.883 1.883 0 00-1.681-1.876zM2.87 17.807h2.133v.305a.409.409 0 01-.153.32l-1.024.826a.742.742 0 01-.464.164h-.492v-1.615zm7.3 9.806a3.364 3.364 0 11.001-6.727 3.364 3.364 0 010 6.727zm20.673 0a3.364 3.364 0 110-6.728 3.364 3.364 0 010 6.728zm4.47-3.937a4.507 4.507 0 00-8.941 0h-11.73a4.507 4.507 0 00-8.908-.222l-2.864-1.057V20.57h.492c.431 0 .85-.148 1.185-.418l1.024-.827a1.552 1.552 0 00.58-1.214v-.88a.574.574 0 00-.574-.572H2.871v-1.624c0-.25.067-.495.195-.71l3.09-5.223a1.4 1.4 0 011.2-.684H23.96c.366 0 .718.144.977.402l6.17 6.085c.091.09.212.147.34.161l7.024.768a.738.738 0 01.659.734v.598l-4.613-.504a.565.565 0 00-.522.227.574.574 0 00-.07.564l.81 1.953a1.88 1.88 0 001.743 1.164h2.652v3.106h-3.815zm3.815-4.254h-2.652a.735.735 0 01-.682-.455l-.438-1.057 3.773.412-.001 1.1z"
      ></path>
      <path
        fill={isSelected ? Colors.bluePrimary : Colors.fontGreyLight}
        d="M24.362 9.402a.576.576 0 00-.403-.164H7.297a.574.574 0 00-.493.281L5.21 12.207a.574.574 0 00.26.816l4.469 1.995c.073.034.153.05.234.05h18.533a.575.575 0 00.404-.984l-4.747-4.682zm-5.49.985l.898 3.533H13.7v-3.535l5.172.002zm-12.346 1.85l1.098-1.852h4.927v3.535h-2.257l-3.768-1.683zm14.428 1.683l-.898-3.535h3.668l3.582 3.535h-6.352zM22.148 16.04h-1.64a.574.574 0 000 1.148h1.64a.574.574 0 100-1.149z"
      ></path>
    </svg>
  );
}

function StarIcon({ isSelected }) {
  const { Colors } = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 30 30"
    >
      <g fill={isSelected ? Colors.bluePrimary : Colors.fontGreyLight} clipPath="url(#clip0_13077_7291)">
        <path d="M24.26 8.77l-4.953-.72-2.215-4.488c-.855-1.734-3.329-1.733-4.184 0L10.693 8.05l-4.953.72c-1.913.277-2.677 2.63-1.293 3.98l3.584 3.493-.846 4.933c-.327 1.905 1.674 3.358 3.385 2.46l4.43-2.33 4.43 2.33c1.709.897 3.712-.555 3.386-2.46l-.846-4.933 3.584-3.494c1.384-1.35.619-3.702-1.294-3.98zm-.103 2.547l-3.96 3.86a1 1 0 00-.288.886l.935 5.45a.333.333 0 01-.483.352l-4.896-2.573a1 1 0 00-.93 0l-4.896 2.573a.333.333 0 01-.483-.351l.935-5.451a1 1 0 00-.288-.885l-3.96-3.86a.333.333 0 01.184-.57l5.474-.795a1 1 0 00.753-.547l2.447-4.96a.332.332 0 01.598 0l2.448 4.96a1 1 0 00.753.547l5.473.796c.274.04.383.374.184.568zM7.894 2.761L6.698 1.115a1 1 0 00-1.617 1.176l1.196 1.646a1 1 0 001.617-1.176zM3.926 17.515a1 1 0 00-1.26-.642l-1.975.642a1 1 0 00.618 1.902l1.975-.641a1 1 0 00.642-1.26zM24.698.894a1 1 0 00-1.396.221l-1.196 1.646a1 1 0 101.618 1.176l1.196-1.646a1 1 0 00-.222-1.397zM29.309 17.515l-1.975-.641a1 1 0 10-.618 1.902l1.975.641a1 1 0 00.618-1.902zM15 25.266a1 1 0 00-1 1v2.03a1 1 0 002 0v-2.03a1 1 0 00-1-1z"></path>
      </g>
      <defs>
        <clipPath id="clip0_13077_7291">
          <path fill="#fff" d="M0 0H30V30H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

function TruckIcon({ isSelected }) {
  const { Colors } = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 36 39"
    >
      <g fill={isSelected ? Colors.bluePrimary : Colors.fontGreyLight} clipPath="url(#clip0_13077_7330)">
        <path d="M7.734 8.86a.703.703 0 100 1.406.703.703 0 000-1.407z"></path>
        <path d="M33.112 14.873l-1.017-2.035a2.098 2.098 0 00-1.887-1.166h-5.88V6.75c0-1.163-.947-2.11-2.11-2.11H2.109C.946 4.64 0 5.588 0 6.75v18.28c0 1.163.946 2.11 2.11 2.11h2.109c0 1.08.408 2.065 1.077 2.812H.703a.703.703 0 100 1.406h34.592a.703.703 0 000-1.406h-1.78a4.201 4.201 0 001.077-2.812l-.003-.12A2.113 2.113 0 0036 25.03v-3.886c0-.82.162-.055-2.887-6.271zm-1.064 1.017l2.11 4.219H27.14V15.89h4.908zM1.406 21.515h1.406v1.406H1.406v-1.406zm7.031 8.437a2.816 2.816 0 01-2.755-3.376 2.819 2.819 0 012.755-2.249c1.332 0 2.491.946 2.756 2.25v.002a2.816 2.816 0 01-2.756 3.373zm0-7.03a4.233 4.233 0 00-3.977 2.811H2.11a.704.704 0 01-.704-.703v-.703h2.11a.703.703 0 00.703-.703v-2.812a.703.703 0 00-.704-.703H1.406V6.749c0-.387.316-.702.703-.702h20.109c.387 0 .703.315.703.703v18.983H12.414a4.233 4.233 0 00-3.977-2.812zm3.141 7.03a4.201 4.201 0 001.078-2.812h13.5c0 1.08.407 2.065 1.077 2.812H11.578zm18.796 0a2.816 2.816 0 01-2.756-3.376 2.819 2.819 0 012.756-2.249 2.819 2.819 0 012.812 2.813 2.815 2.815 0 01-2.812 2.812zm4.218-4.922c0 .234-.115.441-.291.57a4.234 4.234 0 00-3.927-2.679 4.233 4.233 0 00-3.978 2.813h-2.069V13.077h5.88a.7.7 0 01.63.389l.508 1.017h-4.909a.703.703 0 00-.703.703v5.625c0 .388.315.703.703.703h8.156v3.515z"></path>
        <path d="M19.405 8.86h-8.859a.703.703 0 000 1.406h8.86a.703.703 0 100-1.407zM19.405 11.672H13.36a.703.703 0 000 1.406h6.046a.703.703 0 000-1.406zM30.374 25.734a1.408 1.408 0 000 2.812c.775 0 1.406-.63 1.406-1.405 0-.776-.63-1.407-1.406-1.407zM8.437 25.734a1.408 1.408 0 000 2.812c.775 0 1.406-.63 1.406-1.405 0-.776-.63-1.407-1.406-1.407z"></path>
      </g>
      <defs>
        <clipPath id="clip0_13077_7330">
          <path fill="#fff" d="M0 0H36V36H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

function VanIcon({ isSelected }) {
  const { Colors } = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <g fill={isSelected ? Colors.bluePrimary : Colors.fontGreyLight} clipPath="url(#clip0_13077_7323)">
        <path d="M40 26.83A6.776 6.776 0 0038.818 23a.632.632 0 00-.183-.244 6.802 6.802 0 00-5.45-2.741h-.861l-5.726-5.378a6.326 6.326 0 00-4.344-1.719H3.321A3.325 3.325 0 000 16.24v9.881c0 .094.007.185.014.276-.003.024-.014.045-.014.07 0 .091.02.178.055.257a3.574 3.574 0 003.516 2.968h.019a4.322 4.322 0 004.173 3.226 4.322 4.322 0 004.173-3.226h17.369a4.323 4.323 0 004.173 3.226c2.023 0 3.72-1.4 4.188-3.28A2.846 2.846 0 0040 26.83zM7.763 31.628a3.031 3.031 0 01-3.028-3.029 3.032 3.032 0 013.028-3.028 3.032 3.032 0 013.028 3.028 3.032 3.032 0 01-3.028 3.029zm25.715 0a3.031 3.031 0 01-3.028-3.029 3.032 3.032 0 013.028-3.028 3.032 3.032 0 013.029 3.028 3.032 3.032 0 01-3.029 3.029zm4.302-3.368a4.322 4.322 0 00-4.302-3.98 4.32 4.32 0 00-4.309 4.122H12.072a4.32 4.32 0 00-4.31-4.122 4.32 4.32 0 00-4.307 4.11 2.272 2.272 0 01-1.929-1.278h.566a.645.645 0 000-1.29H1.29V16.24c0-1.12.911-2.032 2.031-2.032h18.933c1.29 0 2.52.487 3.46 1.37l5.913 5.552c.12.113.277.175.442.175h1.116a5.5 5.5 0 013.55 1.298.641.641 0 00.04 1.283h1.077c.54.853.858 1.861.858 2.944a1.56 1.56 0 01-.93 1.43z"></path>
        <path d="M27.742 23.887H12.903a.645.645 0 100 1.29h14.839a.645.645 0 100-1.29zM18.065 21.305v-5.793a.645.645 0 00-.646-.645H3.226a.645.645 0 00-.645.645v5.793c0 .357.289.646.645.646h14.193a.645.645 0 00.646-.646zm-1.29-.645H3.87v-4.503h12.903v4.503zM25.002 16.103a4.559 4.559 0 00-3.128-1.236H20a.645.645 0 00-.645.645v5.793c0 .357.289.646.645.646h9.614a.646.646 0 00.441-1.116l-5.053-4.732zm-4.357 4.557v-4.503h1.229c.837 0 1.635.315 2.245.887l3.862 3.616h-7.336zM7.763 26.398a2.206 2.206 0 00-2.204 2.204c0 1.216.988 2.204 2.204 2.204a2.206 2.206 0 002.204-2.204 2.206 2.206 0 00-2.204-2.204zm0 3.118a.914.914 0 11.001-1.829.914.914 0 01-.001 1.829zM33.478 26.398a2.206 2.206 0 00-2.204 2.204c0 1.216.989 2.204 2.204 2.204a2.206 2.206 0 002.204-2.204 2.206 2.206 0 00-2.204-2.204zm0 3.118a.915.915 0 010-1.827.914.914 0 010 1.827z"></path>
      </g>
      <defs>
        <clipPath id="clip0_13077_7323">
          <path fill="#fff" d="M0 0H40V40H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

function AvatarIcon() {
  const { Colors } = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <path
        fill={Colors.bluePrimary}
        d="M11.714 8.346a4.612 4.612 0 001.901-3.73A4.62 4.62 0 009 0a4.62 4.62 0 00-4.615 4.615c0 1.532.75 2.891 1.901 3.731-2.863 1.096-4.901 3.872-4.901 7.115 0 1.4 1.138 2.539 2.538 2.539h10.154c1.4 0 2.538-1.139 2.538-2.539 0-3.243-2.038-6.02-4.901-7.115zm-5.945-3.73C5.77 2.833 7.22 1.384 9 1.384s3.23 1.449 3.23 3.23c0 1.782-1.449 3.231-3.23 3.231a3.234 3.234 0 01-3.23-3.23zm8.308 12H3.923A1.155 1.155 0 012.77 15.46 6.238 6.238 0 019 9.231a6.238 6.238 0 016.23 6.23c0 .637-.517 1.154-1.153 1.154z"
      ></path>
    </svg>
  );
}

function CalendarIcon({ isSelected }) {
  const { Colors } = useTheme();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <g fill={isSelected ? Colors.bluePrimary : Colors.fontGreyLight} clipPath="url(#clip0_13077_4309)">
        <path d="M12.063 7.188a.625.625 0 100-1.25.625.625 0 000 1.25z"></path>
        <path d="M13.5 1.25h-.813V.625a.625.625 0 10-1.25 0v.625H8.595V.625a.625.625 0 10-1.25 0v.625H4.53V.625a.625.625 0 10-1.25 0v.625H2.5A2.503 2.503 0 000 3.75v9.75C0 14.879 1.121 16 2.5 16h4.781a.625.625 0 100-1.25H2.5c-.69 0-1.25-.56-1.25-1.25V3.75c0-.69.56-1.25 1.25-1.25h.781v.625a.625.625 0 101.25 0V2.5h2.813v.625a.625.625 0 101.25 0V2.5h2.844v.625a.625.625 0 101.25 0V2.5h.812c.69 0 1.25.56 1.25 1.25v3.563a.625.625 0 101.25 0V3.75c0-1.378-1.121-2.5-2.5-2.5z"></path>
        <path d="M12.219 8.438a3.785 3.785 0 00-3.781 3.78A3.785 3.785 0 0012.217 16 3.785 3.785 0 0016 12.219a3.785 3.785 0 00-3.781-3.781zm0 6.312a2.534 2.534 0 01-2.531-2.531 2.534 2.534 0 012.53-2.531 2.534 2.534 0 012.532 2.53 2.534 2.534 0 01-2.531 2.532z"></path>
        <path d="M13.125 11.594h-.281v-.656a.625.625 0 00-1.25 0v1.28c0 .346.28.626.625.626h.906a.625.625 0 000-1.25zM9.344 7.188a.625.625 0 100-1.25.625.625 0 000 1.25zM6.625 9.906a.625.625 0 100-1.25.625.625 0 000 1.25zM3.906 7.188a.625.625 0 100-1.25.625.625 0 000 1.25zM3.906 9.906a.625.625 0 100-1.25.625.625 0 000 1.25zM3.906 12.625a.625.625 0 100-1.25.625.625 0 000 1.25zM6.625 12.625a.625.625 0 100-1.25.625.625 0 000 1.25zM6.625 7.188a.625.625 0 100-1.25.625.625 0 000 1.25z"></path>
      </g>
      <defs>
        <clipPath id="clip0_13077_4309">
          <path fill="#fff" d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

function ClockIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 22 22"
    >
      <path
        fill="#0062FF"
        d="M11 19.402a8.403 8.403 0 118.403-8.403.615.615 0 001.229 0c0-5.32-4.313-9.632-9.632-9.632-5.32 0-9.632 4.313-9.632 9.632 0 5.32 4.312 9.633 9.632 9.633a.615.615 0 000-1.23z"
      ></path>
      <path
        fill="#0062FF"
        d="M10.385 4.443v6.763H6.901a.615.615 0 000 1.23H11c.34 0 .615-.276.615-.615V4.443a.615.615 0 00-1.23 0zM18.186 14.073a.615.615 0 00-.861.12l-1.96 2.592-1.39-1.05a.615.615 0 00-.741.98l1.88 1.422c.27.205.656.151.861-.12l2.33-3.082a.615.615 0 00-.12-.862z"
      ></path>
      <path
        fill="#0062FF"
        d="M15.918 11.203a4.714 4.714 0 100 9.427 4.714 4.714 0 000-9.427zm0 8.198a3.484 3.484 0 110-6.967 3.484 3.484 0 010 6.967z"
      ></path>
    </svg>
  );
}

function GroupDeleteIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="54"
      height="54"
      fill="none"
      viewBox="0 0 54 54"
    >
      <circle cx="27" cy="27" r="27" fill="#D92D21" opacity="0.05"></circle>
      <circle cx="27" cy="27" r="21" fill="#D92D21" opacity="0.1"></circle>
      <circle cx="27" cy="27" r="15" fill="#D92D21" opacity="0.1"></circle>
      <path
        fill="#FB3737"
        d="M32.25 23.25a.75.75 0 00-.75.75v8.393a1.44 1.44 0 01-1.508 1.357h-5.985a1.44 1.44 0 01-1.507-1.357V24a.75.75 0 10-1.5 0v8.393a2.939 2.939 0 003.008 2.857h5.985A2.939 2.939 0 0033 32.393V24a.75.75 0 00-.75-.75zM33 21h-3v-1.5a.75.75 0 00-.75-.75h-4.5a.75.75 0 00-.75.75V21h-3a.75.75 0 100 1.5h12a.75.75 0 100-1.5zm-7.5 0v-.75h3V21h-3z"
      ></path>
      <path
        fill="#FB3737"
        d="M26.25 30.75V25.5a.75.75 0 10-1.5 0v5.25a.75.75 0 101.5 0zM29.25 30.75V25.5a.75.75 0 10-1.5 0v5.25a.75.75 0 101.5 0z"
      ></path>
    </svg>
  );
}

function GroupCancelIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="54"
      height="54"
      fill="none"
      viewBox="0 0 54 54"
    >
      <circle cx="27" cy="27" r="27" fill="#D92D21" opacity="0.05"></circle>
      <circle cx="27" cy="27" r="21" fill="#D92D21" opacity="0.1"></circle>
      <circle cx="27" cy="27" r="15" fill="#D92D21" opacity="0.1"></circle>
      <g fill="#EB4A4A" clipPath="url(#clip0_16014_32034)">
        <path d="M35.997 22.44a.563.563 0 00-.322-.45l-8.437-3.937a.563.563 0 00-.476 0l-8.437 3.937a.562.562 0 00-.325.51v9.563c0 .23.14.436.354.522l8.437 3.375a.563.563 0 00.418 0l2.286-.915c.69.595 1.587.955 2.567.955A3.942 3.942 0 0036 32.062V22.5l-.003-.06zM27 19.182l7.022 3.277-2.704 1.082-7.154-3.035L27 19.183zm-.563 15.424l-7.312-2.925V23.33l7.313 2.925v8.35zM27 25.269l-7.022-2.809 2.814-1.313 7.043 2.988L27 25.269zm5.063 9.606a2.816 2.816 0 01-2.813-2.813 2.816 2.816 0 012.813-2.812 2.816 2.816 0 012.812 2.813 2.816 2.816 0 01-2.813 2.812zm0-6.75a3.942 3.942 0 00-3.938 3.938c0 .763.219 1.476.596 2.08l-1.159.464v-8.351l7.313-2.925v5.979a3.926 3.926 0 00-2.813-1.185z"></path>
        <path d="M32.858 32.063l.446-.447a.562.562 0 10-.796-.795l-.446.446-.445-.446a.562.562 0 10-.796.795l.446.447-.446.445a.562.562 0 10.795.796l.447-.446.446.446a.56.56 0 00.795 0 .562.562 0 000-.796l-.446-.446z"></path>
      </g>
      <defs>
        <clipPath id="clip0_16014_32034">
          <path
            fill="#fff"
            d="M0 0H18V18H0z"
            transform="translate(18 18)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

function PinIcon({color}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 12"
    >
      <path
        fill={color}
        d="M8.859 1.525A4.53 4.53 0 005.097.59 4.5 4.5 0 003.26 8.567a7.625 7.625 0 011.96 2.109l.35.582a.5.5 0 00.858 0l.335-.56a6.36 6.36 0 011.79-2 4.495 4.495 0 00.305-7.175v.002zm-2.86 5.476a2 2 0 110-4 2 2 0 010 4z"
      ></path>
    </svg>
  );
}

function DeleteIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="14"
      fill="none"
      viewBox="0 0 12 14"
    >
      <path
        fill="#fff"
        d="M10.31 3.697H1.69a.177.177 0 00-.176.187l.453 8.738a.73.73 0 00.73.693h6.606a.73.73 0 00.73-.693l.453-8.738a.178.178 0 00-.177-.187zM3.186 5.458a.445.445 0 11.894-.024l.164 6.134a.447.447 0 01-.435.459h-.012a.445.445 0 01-.446-.435l-.165-6.134zm2.366 6.115V5.44a.448.448 0 01.894 0v6.134a.448.448 0 01-.894 0zm3.095.019a.445.445 0 01-.446.435H8.19a.444.444 0 01-.434-.46l.164-6.132a.447.447 0 01.457-.436h.003a.445.445 0 01.434.46l-.165 6.133zm2.547-9.262v.47H.805v-.47c0-.26.21-.471.47-.471h3.418c.09 0 .167-.068.177-.158A1.135 1.135 0 016 .687c.581 0 1.067.436 1.13 1.014.01.09.086.158.177.158h3.418c.26 0 .47.211.47.47z"
      ></path>
    </svg>
  );
}

function AddTabItemIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="16"
      fill="none"
      viewBox="0 0 22 16"
    >
      <rect width="22" height="15" y="0.5" fill="#0062FF" rx="7"></rect>
      <g clipPath="url(#clip0_14790_15726)">
        <path
          fill="#fff"
          d="M15.121 7.121H11.88V3.88a.879.879 0 10-1.758 0V7.12H6.88a.879.879 0 100 1.758h3.242v3.242a.879.879 0 001.758 0V8.88h3.242a.879.879 0 000-1.758z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_14790_15726">
          <path fill="#fff" d="M0 0H10V10H0z" transform="translate(6 3)"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export {
  DeliveryBox,
  GroupUp,
  GroupDown,
  CarIcon,
  StarIcon,
  TruckIcon,
  VanIcon,
  AvatarIcon,
  CalendarIcon,
  ClockIcon,
  GroupDeleteIcon,
  PinIcon,
  DeleteIcon,
  AddTabItemIcon,
  GroupCancelIcon,
};
