import { useState } from "react";
import * as Sentry from "@sentry/react";
import { createStore } from "aniuta";
import { assigneAdminToIncident, createIncident, getIncidents, updateIncident } from "../utils/api/incidents";
import { toast } from "react-toastify";
import { getDeliveryById, getRejectionReasons } from "../utils/api/deliveries";

const useIncidents = createStore({
  name: "useIncidents",
  Store: () => {
    const [searchedId, setSearchedId] = useState("");
    const [isAssignee, setIsAssignee] = useState("");
    const [searchedShortId, setSearchedShortId] = useState("");
    const [searchedDropoffName, setSearchedDropoffName] = useState("");
    const [searchedIncidentId, setSearchedIncidentId] = useState("");
    const [markNotReroutableVisible, setMarkNotReroutableVisible] = useState(false);

    const [updatingIncidentIndex, setUpdatingIncidentIndex] = useState();

    const [isCreateEditIncidentModalOpen, setIsCreateEditIncidentModalOpen] = useState(false);
    const [selectedIncident, setSelectedIncident] = useState();
    const [selectedDeliveryForIncident, setSelectedDeliveryForIncident] = useState();

    const [isIncidentsLoading, setIsIncidentsLoading] = useState(false);

    const [incidents, setIncidents] = useState();

    const [incidentStatusFilter, setIncidentStatusFilter] = useState("");

    const [updateList, setUpdateList] = useState(false);

    const getAllIncidents = async (params) => {
      setIsIncidentsLoading(true);
      const queryParams = {
        test_mode: params?.test_mode ?? false,
        limit: params?.LIMIT_ON_PAGE ?? 50,
        ending_before: params?.ending_before || "",
        starting_after: params?.starting_after || "",
        status: params?.status ?? incidentStatusFilter,
        assignee: params?.assignee ?? isAssignee,
        delivery_id: params?.delivery_id ?? searchedId,
        short_id: params?.short_id ?? searchedShortId,
        dropoff_name: params?.dropoff_name ?? searchedDropoffName,
        incident_id: params?.incident_id ?? searchedIncidentId,
      };
      // console.log("params", queryParams, "-0-0-0-0-0-0-", params);

      try {
        const { data } = await getIncidents(queryParams);
        setIncidents(data);
        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsIncidentsLoading(false);
      }
    };

    const createIncidentHandler = async (payload) => {
      setIsIncidentsLoading(true);
      try {
        const { data } = await createIncident(payload);
        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      }
    };

    const updateIncidentHandler = async (id, payload) => {
      setIsIncidentsLoading(true);
      try {
        const { data } = await updateIncident(id, payload);
        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsIncidentsLoading(false);
      }
    };

    const assignAdminToincidentHandler = async (id) => {
      try {
        const { data } = await assigneAdminToIncident(id);
        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      }
    };

    const getDeliveryByIdHandler = async (id) => {
      try {
        const response = await getDeliveryById(id);
        return response.data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      }
    };
    const getRejectionReasonsHandler = async (id) => {
      try {
        const response = await getRejectionReasons(id);
        return response.data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      }
    };

    return {
      getAllIncidents,
      isIncidentsLoading,
      setIsIncidentsLoading,
      isCreateEditIncidentModalOpen,
      setIsCreateEditIncidentModalOpen,
      selectedIncident,
      setSelectedIncident,
      selectedDeliveryForIncident,
      setSelectedDeliveryForIncident,
      createIncidentHandler,
      updateIncidentHandler,
      incidents,
      setIncidents,
      assignAdminToincidentHandler,

      getDeliveryByIdHandler,
      incidentStatusFilter,
      setIncidentStatusFilter,
      //filetrs
      updateList,
      setUpdateList,
      searchedId,
      setSearchedId,
      isAssignee,
      setIsAssignee,
      searchedShortId,
      setSearchedShortId,
      searchedDropoffName,
      setSearchedDropoffName,

      updatingIncidentIndex,
      setUpdatingIncidentIndex,

      searchedIncidentId,
      setSearchedIncidentId,

      // attachment upload
      markNotReroutableVisible,
      setMarkNotReroutableVisible,

      getRejectionReasonsHandler,
    };
  },
});

export default useIncidents;
