import React from "react";
import styles from "../styles/DetailsSection/invoicePDF.module.css";
import { FiDownload } from "react-icons/fi";
import Loader from "../../../../shared/atoms/Loader";
import classNames from "classnames";
import { useInvoices } from "../../../../../store";

const InvoicePDF = () => {
  const { selectedInvoice, isInvoicePDFLinkLoading, getInvoicePDFLinkWorker } =
    useInvoices();

  const getInvoicePDFLinkHandler = async () => {
    const res = await getInvoicePDFLinkWorker(selectedInvoice?.id);
    if (res?.invoicePDFPresignedUrl) {
      window.open(res.invoicePDFPresignedUrl, "_blank");
    }
  };
  if (!selectedInvoice?.has_pdf) return null;
  return (
    <div>
      <button
        className={styles.btn}
        type="button"
        onClick={async () => {
          getInvoicePDFLinkHandler();
        }}
      >
        {isInvoicePDFLinkLoading ? (
          <div
            className={classNames(
              styles.loaderWrapper,
              "w-full flex items-center justify-center kata"
            )}
          >
            <Loader
              extraClassName={classNames(styles.loader, "animate-spin")}
            />
          </div>
        ) : (
          <>
            <FiDownload className={styles.icon} size={18} />{" "}
            <span className={styles.text}>PDF</span>
          </>
        )}
      </button>
    </div>
  );
};

export default InvoicePDF;