import React, { useEffect } from "react";
import Cookies from "js-cookie";
import Loader from "../shared/atoms/Loader";
import styles from "./styles.module.css";
import { baseUrl } from "../../runTimeConst";
import { userDataStore } from "../../store";
import { useNavigate } from "react-router-dom";
import { removeCookie } from "../../utils";

const ReferralSignup = () => {
  const { authInfo } = userDataStore();
  const navigate = useNavigate();

  const referrer = new URLSearchParams(window.location.search).get("referrer");
  const referrerRequestId = new URLSearchParams(window.location.search).get("referrer_request_id");

  useEffect(() => {
    if (!referrer || !referrerRequestId || authInfo) {
      navigate(`/v1/home`);
    } else {
      Object.keys(Cookies.get()).forEach((cookieName) => {
        removeCookie(cookieName);
      });
      setTimeout(() => {
        // console.log("first,", `${baseUrl}auth/login?referrer=${referrer}&referrer_request_id=${referrerRequestId}`);
        window.location.href = `${baseUrl}auth/login?referrer=${referrer}&referrer_request_id=${referrerRequestId}`;
      }, 1000);
    }
  }, []);

  return (
    <div className={styles.loader}>
      <Loader />
    </div>
  );
};

export default ReferralSignup;
// referral_signup?referrer=acct_01j3z41qddft1rrzfkjebf7gbe&referrer_request_id=refreq_01j41p1x0weajv7hzs42asmczm
