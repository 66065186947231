// File: DeliveryDetails.js

import React from "react";
import styles from "./styles.module.css";
import classNames from "classnames";
import { OneAddressOnSideDrawer } from "./OneAddressOnSideDrawer";
import CopyToClipboard from "../../shared/atoms/CopyToClipboard";

const AddressesDetails = ({ item }) => {
  return (
    <div className={"flex flex-col w-full pb-3"}>
      <div className={classNames("flex w-full justify-between mt-2")}>
        {item?.external_order_ref && (
          <div className={classNames("break-all", styles.ids)}>
            <>
              <div className="flex items-center">
                External Order ID <CopyToClipboard copyText={item.external_order_ref} />
              </div>
              <div className={styles.fontDark}>{item.external_order_ref} </div>
            </>
          </div>
        )}
        {/* {item?.provider_id && (
          <div className={classNames("break-all flex flex-col justify-start", styles.ids)}>
            <div className="flex flex-col h-full">
              <div className="flex items-center">
                Provider Id <CopyToClipboard copyText={item.provider_id} />
              </div>
              <div className={styles.fontDark}>{item.provider_id} </div>
            </div>
          </div>
        )} */}
      </div>

      <div className={classNames("flex w-full flex-col pl-10", styles.container)}>
        <OneAddressOnSideDrawer
          address={item?.pickup_address}
          eta={item?.pickup_eta}
          name={item?.pickup_name}
          note={item?.pickup_notes}
          phoneNumber={item?.pickup_phone_number}
          unitNumber={item?.pickup_unit}
          title={"Pickup"}
        />
        {item?.dropoffs?.map((address, index) => {
          return (
            <OneAddressOnSideDrawer
              address={address?.dropoff_address}
              eta={address?.dropoff_eta}
              name={address?.dropoff_name}
              note={address?.dropoff_notes}
              phoneNumber={address?.dropoff_phone_number}
              unitNumber={item?.dropoff_unit}
              key={index}
              title={"Dropoff"}
              isDropoff
            />
          );
        })}
      </div>
    </div>
  );
};

export default AddressesDetails;
