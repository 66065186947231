import React from "react";
import { AiOutlineCodeSandbox } from "react-icons/ai";
import { FcFlashOn } from "react-icons/fc";
import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";
import { currentOrderStatus, mapStatus, ORDER_STATUSES } from "../utils";

const DeliveryStatusSection = ({ orderInfo }) => {
  const { mdfontsize } = useDesktopOrLaptop();
  const currentStatus = currentOrderStatus(orderInfo);
  const renderIcon = () => {
    switch (currentStatus) {
      case ORDER_STATUSES.DRIVER_ENROUTE_FOR_PICKUP:
      case ORDER_STATUSES.ARRIVED_AT_PICKUP:
      case ORDER_STATUSES.PICKUP_COMPLETED:
        return <FcFlashOn color="#50B5FF" size={20} />;
      case ORDER_STATUSES.DRIVER_ENROUTE_FOR_DROPOFF:
      case ORDER_STATUSES.ARRIVED_AT_DROPOFF:
      case ORDER_STATUSES.DROPOFF_COMPLETED:
        return <AiOutlineCodeSandbox color="#A461D8" />;
      default:
        return null;
    }
  };

  const statusTextColor = () => {
    switch (currentStatus) {
      case ORDER_STATUSES.DRIVER_ENROUTE_FOR_PICKUP:
      case ORDER_STATUSES.ARRIVED_AT_PICKUP:
      case ORDER_STATUSES.PICKUP_COMPLETED:
        return "#50B5FF";
      case ORDER_STATUSES.DRIVER_ENROUTE_FOR_DROPOFF:
      case ORDER_STATUSES.ARRIVED_AT_DROPOFF:
      case ORDER_STATUSES.DROPOFF_COMPLETED:
        return "#A461D8";
      case ORDER_STATUSES.PICKUP_SCHEDULED:
      case ORDER_STATUSES.DROPOFF_SCHEDULED:
        return "#0062FF";
      default:
        return "#92929D";
    }
  };

  return (
    <div
      style={{ fontSize: mdfontsize }}
      className="font-semibold mb-6 items-start w-full justify-start  "
    >
      <div className="">
        <div className="flex items-center ">
          {renderIcon()}
          <div style={{ color: statusTextColor() }} className="ml-1 capitalize">
            {mapStatus[currentStatus]}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryStatusSection;
