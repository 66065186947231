import React from "react";
import { useNavigate } from "react-router-dom";

import { useTheme } from "../../../../../store";
import { useDesktopOrLaptop } from "../../../../../services/useDesktopOrLaptop";
import { numberWithCommas } from "../../../../../utils/helpers";
import PaymentStatus from "../PaymentStatus";
import { BiDetail } from "react-icons/bi";

const StatementsItem = ({ invoiceObject, disabled }) => {
  const { Colors, themeChanger } = useTheme();
  const { mdfontsize } = useDesktopOrLaptop();
  const navigate = useNavigate();

  return (
    <div
      // disabled={disabled}
      // type="button"
      // onClick={(event) => {
      //   if (event.metaKey || event.ctrlKey) {
      //     const newTabURL = `${process.env.REACT_APP_STRIPE_RETURN_URL}/v1/settings/billing/statement/${invoiceObject.id}`; // Replace this URL with the one you want to open
      //     window.open(newTabURL, "_blank");
      //   } else {
      //     navigate(`/v1/settings/billing/statement/${invoiceObject.id}`);
      //   }
      // }}
      style={{
        padding: "30px 0px",
        borderBottomWidth: "1.5px",
        borderColor: Colors.borderPrimary,
      }}
      className={`grid grid-cols-12 gap-1 w-full pb-2 items-center ${
        themeChanger ? "hover:bg-gray-100" : "hover:bg-gray-800"
      }`}
    >
      <div
        style={{
          fontSize: mdfontsize,
          fontWeight: "400",
          color: Colors.fontGreyLight,
        }}
        className="col-start-1 col-span-3 text-center"
      >
        {invoiceObject?.id}
      </div>
      {/* <div
        style={{
          fontSize: mdfontsize,
          fontWeight: "400",
          color: Colors.fontGreyLight,
        }}
        className="col-start-3 col-span-2 text-center flex justify-center items-center"
      >
        {moment(invoiceObject?.billing_period_start_at).format("MMM D, YYYY")} -{" "}
        {moment(invoiceObject?.billing_period_end_at).format("MMM D, YYYY")}
      </div> */}
      <div
        style={{
          fontSize: mdfontsize,
          fontWeight: "400",
          color: Colors.fontGreyLight,
        }}
        className="col-start-4 col-span-3 text-center"
      >
        {invoiceObject?.store_name}
      </div>
      {/* <div
        style={{
          fontSize: mdfontsize,
          fontWeight: "400",
          color: Colors.fontGreyLight,
        }}
        className="col-start-8 col-span-2 text-center"
      >
        ${numberWithCommas((invoiceObject?.starting_balance / 100)?.toFixed(2))}
      </div> */}
      <div
        style={{
          fontSize: mdfontsize,
          fontWeight: "400",
          color: Colors.fontGreyLight,
        }}
        className="col-start-7 col-span-3 flex justify-center items-center"
      >
        <PaymentStatus data={invoiceObject} />
      </div>
      {/* <div
        style={{
          fontSize: mdfontsize,
          fontWeight: "400",
          color: Colors.fontGreyLight,
        }}
        className="col-start-9 col-span-2 text-center"
      >
        ${numberWithCommas((invoiceObject?.starting_balance / 100)?.toFixed(2))}
      </div>
      <div
        style={{
          fontSize: mdfontsize,
          fontWeight: "400",
          color: Colors.fontGreyLight,
        }}
        className="col-start-11 col-span-2 text-center"
      >
        ${numberWithCommas((invoiceObject?.ending_balance / 100)?.toFixed(2))}
      </div> */}
      <div
        style={{
          fontSize: mdfontsize,
          fontWeight: "400",
          color: Colors.fontGreyLight,
        }}
        className="col-start-10 col-span-2 text-center"
      >
        $
        {invoiceObject?.ending_balance
          ? numberWithCommas((invoiceObject.ending_balance / 100)?.toFixed(2))
          : "0"}
      </div>
      <button
        type="button"
        style={{
          fontSize: mdfontsize,
          fontWeight: "400",
          color: Colors.fontGreyLight,
        }}
        className="col-start-12 col-span-1 cursor-pointer flex justify-center"
        disabled={disabled}
        onClick={(event) => {
          if (event.metaKey || event.ctrlKey) {
            const newTabURL = `${process.env.REACT_APP_STRIPE_RETURN_URL}/v1/settings/billing/statement/${invoiceObject.id}`; // Replace this URL with the one you want to open
            window.open(newTabURL, "_blank");
          } else {
            navigate(`/v1/settings/billing/statement/${invoiceObject.id}`);
          }
        }}
      >
        <BiDetail color={Colors.fontDark} size={22} />
      </button>
    </div>
  );
};

export default StatementsItem;
