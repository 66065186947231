import { useState, useRef } from "react";
import { useTheme } from "../../../../store";
import "./styles.css";

const REGEX = /({{.*?}})/g;

export default function VariableInput({ value, setValue }) {
  const ref = useRef(null);
  const { Colors } = useTheme();

  const syncScroll = (e) => {
    ref.current.scrollTop = e.target.scrollTop;
    ref.current.scrollLeft = e.target.scrollLeft;
    ref.current.scrollBottom = e.target.scrollBottom;
  };

  return (
    <div
      id={`textAreaForCustomSms`}
      style={{
        backgroundColor: Colors.detailsBg,
        minHeight: "200px",
        width: "100%",
        marginTop: "20px",
        borderRadius: "10px",
        padding: "12px",
        color: Colors.fontDark,
        fontSize: "14px",
        position: "relative",
        maxHeight: "100vh",
      }}
      className="input-container"
    >
      <textarea
        maxLength={190}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onScroll={syncScroll}
        style={{
          fontSize: "14px",
          width: "100%",
          maxHeight: "100vh",
          minHeight: "100%",
          whiteSpace: "break-spaces",
          resize: "none",
        }}
        placeholder="This is a placeholder!"
      />
      <div
        ref={ref}
        className="input-renderer"
        style={{
          fontSize: "14px",
          whiteSpace: "break-spaces",
          margin: "0px 0px",
          overflowY: "auto",
        }}
      >
        {value.split(REGEX).map((word, i) => {
          if (word.match(REGEX) !== null) {
            const innerWord = word.slice(2, -2);
            const modifiedWord = innerWord[0].toLowerCase() + innerWord.slice(1);
            return (
              <span style={{ backgroundColor: Colors.bluePrimary }} key={i} className="highlighted ">
                {`{{${modifiedWord}}}`}
              </span>
            );
          } else {
            return (
              <span style={{}} key={i}>
                {word}
              </span>
            );
          }
        })}
      </div>
    </div>
  );
}
