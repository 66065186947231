import React, { useMemo } from "react";
import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";
import InsightChartsGrid from "./InsightChartsGrid";
import InsightsSectionHeader from "./InsightsSectionHeader";

const InsightsSection = () => {
  const { isdesktop } = useDesktopOrLaptop();

  const styles = useMemo(() => {
    return {
      container: {
        fontFamily: "Poppins",
        marginBottom: isdesktop ? "24px" : "10px",
      },
    };
  }, [isdesktop]);

  return (
    <div style={styles.container}>
      <InsightsSectionHeader />
      <InsightChartsGrid />
    </div>
  );
};

export default InsightsSection;
