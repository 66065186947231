import React, { useEffect, useState } from "react";
import burq from "../../../../assets/png/g10.png";
import {
  useMerchant,
  useMyStore,
  useProvidersManagementStore,
  userDataStore,
  useTheme,
} from "../../../../store";
import { Formik } from "formik";
import InputErrorComp from "../../../shared/common/InputErrorComp";
import CommonToggle from "../../../CommonToggle";
import ButtonComp from "../../../ButtonComp/ButtonComp";
import { useDesktopOrLaptop } from "../../../../services/useDesktopOrLaptop";
import { defaultValidationSchema } from "./validationSchema";
import styles from "../styles/byopk.module.css";
import Loader from "../../../shared/atoms/Loader";
import { toast } from "react-toastify";

let initPayload = {
  is_active: false,
  secrets: [],
};

const BYOPKTab = () => {
  const { smFontSize, lgfontsize, xxsfontsize } = useDesktopOrLaptop();
  const { Colors } = useTheme();
  const { putMerchantCredentials } = useMerchant();
  const { authInfo } = userDataStore();
  const { isSandbox } = useMyStore();
  const [payload, setPayload] = useState(initPayload);
  const {
    selectedProvider,
    setSelectedProvider,
    isCredentialsLoading,
    merchantCredentials,
    getProviderCredentialWorker,
    getMerchantProvidersHandler,
  } = useProvidersManagementStore();

  useEffect(() => {
    getProviderCredentialWorker(
      authInfo.merchantAccountId,
      selectedProvider.id,
      { test_mode: isSandbox }
    );
  }, [authInfo, selectedProvider, isSandbox]);

  useEffect(() => {
    setPayload({
      is_active: merchantCredentials?.is_active,
      secrets: merchantCredentials?.secrets,
    });
  }, [merchantCredentials]);

  if (isCredentialsLoading) {
    return (
      <div className={styles.loaderContainer}>
        <Loader />
      </div>
    );
  }

  return (
    <div
      style={{ fontFamily: "Poppins", marginTop: "36px" }}
      className="w-full flex flex-col relative"
    >
      <div style={{ padding: "10px 0" }} className="flex w-full flex-col  ">
        <div
          style={{ marginBottom: "20px" }}
          className="w-full flex justify-center items-center"
        >
          <div className={styles.imagesCont}>
            <img
              className={styles.providerLogo}
              alt="provider logo"
              src={selectedProvider?.provider_logo_url}
            />
          </div>
          <div className={styles.imagesCont}>
            <img className={styles.burqLogo} alt="burq logo" src={burq} />
          </div>
        </div>
        <div
          draggable
          style={{ fontWeight: "600", fontSize: lgfontsize , color: Colors.fontDark}}
          className="flex justify-center w-ful "
        >
          Accounting tool integration
        </div>
        <div
          style={{
            fontSize: smFontSize,
            color: Colors.fontGreyLight,
            textAlign: "center",
            marginTop: "24px",
          }}
          className="flex w-ful "
        >
          Login with the credentials of your accounting tool to get a more
          accurates view of your company’s finances{" "}
        </div>
        <Formik
          initialValues={payload}
          onSubmit={async (values) => {
            await putMerchantCredentials(merchantCredentials?.id, values).then(
              (res) => {
                if (res.data?.secrets) {
                  toast.success("credentials are saved successfully.");
                  setSelectedProvider(null);
                  getMerchantProvidersHandler(
                    authInfo.merchantAccountId,
                    isSandbox
                  );
                }
              }
            );
          }}
          validationSchema={defaultValidationSchema}
          enableReinitialize
        >
          {({
            handleChange,
            handleSubmit,
            isSubmitting,
            values,
            errors,
            setFieldValue,
            setFieldTouched,
            touched,
            setFieldError,
          }) => (
            <>
              {values.secrets?.map((secret, index) => {
                return (
                  <div key={index}>
                    <div className={styles.inputLable}>
                      Enter {secret.property_name}{" "}
                      <span
                        style={{
                          fontSize: xxsfontsize,
                          color: Colors.fontGreyLight,
                          marginLeft: "4px",
                        }}
                      >
                        Required*
                      </span>
                    </div>
                    <div className={styles.inputForEdit}>
                      <input
                        onFocus={() => {
                          setFieldTouched(`secrets[${index}].property_value`);
                        }}
                        onChange={handleChange}
                        placeholder={`Enter ${secret.property_name}`}
                        name={`secrets[${index}].property_value`}
                        value={values.secrets[index].property_value}
                        type="text"
                        className={styles.input}
                      />
                    </div>
                    <InputErrorComp
                      error={
                        errors?.secrets && touched.secrets
                          ? errors?.secrets[index]?.property_value &&
                            touched.secrets[index]?.property_value
                            ? errors?.secrets[index]?.property_value
                            : undefined
                          : undefined
                      }
                    />
                  </div>
                );
              })}

              <div
                style={{ marginTop: "20px" }}
                className="flex w-full justify-between"
              >
                <div
                  style={{
                    fontSize: smFontSize,
                    fontFamily: "400",
                    color: Colors.fontGreyLight,
                  }}
                >
                  Enable API Key
                </div>

                <CommonToggle
                  id="headerToggle"
                  smsHandler={() => {
                    if (touched.secrets && !errors.secrets) {
                      setFieldValue("is_active", !values.is_active);
                    } else {
                      const secrets = merchantCredentials?.secrets;

                      for (const secret of secrets) {
                        if (!secret.property_value) {
                          return;
                        } else {
                          setFieldValue("is_active", !values.is_active);
                        }
                      }
                    }
                  }}
                  checked={values.is_active}
                  translateX={15}
                  circleStyle={{
                    width: "16px",
                    height: "16px",
                    backgroundColor: "white",
                  }}
                  style={{
                    width: "37px",
                    height: "20px",
                    padding: "3px",
                    backgroundColor: values.is_active
                      ? Colors.bluePrimary
                      : "#979797",
                  }}
                />
              </div>

              <ButtonComp
                title={"Save"}
                primary
                onClick={handleSubmit}
                extraStyles={{ marginTop: "40px" }}
              />
            </>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default BYOPKTab;
