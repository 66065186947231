import React, { useEffect, useMemo, useState } from "react";

import { BsExclamationCircle } from "react-icons/bs";
import NumberFormat from "react-number-format";
import { INDUSTRIES } from "../../runTimeConst";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import { useMerchant, useMyStore, userDataStore, useTheme } from "../../store";

const AddIndustryModal = () => {
  const { Colors, themeChanger } = useTheme();
  const { updateMerchantData, merchantData, phoneErr, setPhoneErr, phoneIsNeeded, setIsIndustriesModalVisible } = useMerchant();
  const { authInfo } = userDataStore();
  const { userId } = useMyStore();

  const { isdesktop, lgfontsize, mdfontsize } = useDesktopOrLaptop();

  const [industry, setIndustry] = useState("food");
  const [phoneNumber, setPhoneNumber] = useState();

  const saveHandler = async () => {
    if (!phoneIsNeeded) {
      updateMerchantData(userId || authInfo.merchantAccountId, merchantData, undefined, industry);
    } else if (phoneIsNeeded) {
      updateMerchantData(userId || authInfo.merchantAccountId, merchantData, undefined, industry, phoneNumber);
    }
  };

  useEffect(() => {
    if (merchantData?.standardized_industry) {
      setIndustry(merchantData.standardized_industry);
    }
  }, [merchantData]);

  const styles = useMemo(() => {
    return {
      inputs: {
        width: "100%",
        padding: "8px 12px",
        fontFamily: "Poppins",
        borderWidth: "1px",
        borderColor: "#E2E2EA",
        borderRadius: "10px",
        fontSize: "14px",
        fontWeight: "400",
        marginBottom: "14px",
        outline: "none",
        backgroundColor: "white",
      },
      lables: {
        fontFamily: "Poppins",
        fontSize: "16px",
        marginBottom: "10px",
        marginTop: "16px",
        fontWeight: "400",
      },
    };
  }, [themeChanger]);

  return (
    <div style={{ padding: "20px 20px" }} className="overflow-y-auto relative">
      {merchantData?.account_type !== "connected" && (
        <>
          <div
            style={{
              fontWeight: "500",
              fontSize: lgfontsize,
              color: Colors.fontDark,
            }}
          >
            Please provide the Industry
          </div>

          <div style={{ marginTop: "30px" }} className="w-full flex items-center">
            <select
              name="standardized_industry"
              value={industry}
              draggable={true}
              onChange={(e) => {
                console.log("industry", e.target.value);
                setIndustry(e.target.value);
              }}
              style={{
                ...styles.inputs,
                ...{ backgroundColor: Colors.white, color: Colors.fontDark },
              }}
            >
              {INDUSTRIES.map((item) => {
                return (
                  <option key={item.back} value={item.back}>
                    {item.front}
                  </option>
                );
              })}
            </select>
          </div>
          <div
            className="flex items-start"
            style={{
              marginTop: "24px",
              color: Colors.fontGreyLight,
              padding: "0px 4px",
            }}
          >
            <div style={{ marginTop: "4px" }}>
              <BsExclamationCircle />
            </div>
            <div style={{ marginLeft: "8px" }}>
              Please make a selection based on the type of transported items. Delivery providers available to you will be based on
              this selection. For example, select "Food" if you operate a restaurant or food delivery platform. If you plan to
              send deliveries across multiple industries or do not see an option that matches your industry, please reach out to
              customer support. You can change this selection later from account setting.
            </div>
          </div>
        </>
      )}
      {phoneIsNeeded && (
        <div style={{ marginTop: "30px" }} className="w-full flex flex-col items-start">
          <div
            style={{
              fontWeight: "500",
              fontSize: mdfontsize,
              color: Colors.fontDark,
              marginBottom: "10px",
            }}
          >
            Please provide valid Phone number
          </div>
          <div style={{ width: "100%", height: "60px" }}>
            <NumberFormat
              // disabled={authInfo.role === "admin"}
              name="phone_number"
              style={{ ...styles.inputs, ...{ marginBottom: "6px" } }}
              placeholder="+1 (###) ###-####"
              type="tel"
              format="+# (###) ###-####"
              onValueChange={(e) => {
                if (phoneErr) {
                  setPhoneErr("");
                }
                setPhoneNumber(e.formattedValue);
              }}
              value={phoneNumber}
              className={`focus:outline-none`}
            />
            {phoneErr && (
              <p
                style={{
                  marginTop: "0px",
                  color: Colors.redDark,
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  padding: "0px 10px 8px 0px",
                }}
              >
                {phoneErr}
              </p>
            )}
          </div>
        </div>
      )}
      <div className="w-full flex justify-end items-center mt-8">
        <button
          onClick={() => {
            setIsIndustriesModalVisible(false);
          }}
          type="button"
          style={{
            backgroundColor: Colors.bluePrimary,
            fontFamily: "Poppins",
            fontSize: isdesktop ? "16px" : "28px",
            fontWeight: "500",
            color: "#FAFAFB",
            textAlign: "center",
            borderRadius: "8px",
            padding: "10px 30px",
            marginLeft: "10px",
          }}
        >
          Cancel
        </button>
        <button
          onClick={() => {
            saveHandler();
          }}
          type="button"
          style={{
            backgroundColor: Colors.bluePrimary,
            fontFamily: "Poppins",
            fontSize: isdesktop ? "16px" : "28px",
            fontWeight: "500",
            color: "#FAFAFB",
            textAlign: "center",
            borderRadius: "8px",
            padding: "10px 30px",
            marginLeft: "10px",
          }}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default AddIndustryModal;
