import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { AiOutlineClear } from "react-icons/ai";
import { MdOutlineAccountTree } from "react-icons/md";
import {
  useConnectedAccounts,
  useMyStore,
  useTheme,
  userDataStore,
} from "../../../../../store";
import { useDesktopOrLaptop } from "../../../../../services/useDesktopOrLaptop";
import CustomTypeAndSearch from "../../../../shared/CustomTypeAndSearch";

const FilterWithConnectedAccounts = ({
  statementsListHandler,
  selectedConnectedAccount,
  setSelectedConnectedAccount,
  setConnectedTriggerer,
  storesTrigerer,
}) => {
  const { Colors } = useTheme();
  const { isSandbox } = useMyStore();
  const { smfontsize, isdesktop } = useDesktopOrLaptop();
  const { authInfo } = userDataStore();
  const { getConnectedAccounts, connectedAccountsList } =
    useConnectedAccounts();

  const [searchedText, setSearchedText] = useState();
  const [isListVisible, setIsListVisible] = useState(false);

  // on search query change
  const searchHandler = async (id, email, merchantId) => {
    const encodeEmail = encodeURIComponent(email);
    const encodeId = encodeURIComponent(merchantId);
    const payload = {
      ending_before: "",
      starting_after: "",
      account_email: encodeEmail,
      merchant_store_id: encodeId,
    };
    await getConnectedAccounts(id, payload);
  };

  const debounceFn = useCallback(
    debounce(async (id, email, merchantId) => {
      searchHandler(id, email, merchantId);
    }, 800),
    []
  );
  ////

  useEffect(() => {
    if (searchedText) {
      setSearchedText("");
    }
    if (selectedConnectedAccount) {
      setSelectedConnectedAccount();
    }
    if (isListVisible) {
      setIsListVisible(false);
    }
  }, [isSandbox, storesTrigerer]);

  // Get data to populate list in case no query
  useEffect(() => {
    if (authInfo?.merchantAccountId) {
      getConnectedAccounts(authInfo.merchantAccountId);
    }
  }, []);

  const listItemClickHandler = (listItem) => {
    if (selectedConnectedAccount?.id === listItem.id) {
      setSelectedConnectedAccount();
      setSearchedText("");
      setIsListVisible(false);
      statementsListHandler({ merchantId: authInfo.merchantAccountId });
    } else {
      setSelectedConnectedAccount(listItem);
      setSearchedText(listItem.email);
      statementsListHandler({ merchantId: listItem.id });
      setConnectedTriggerer((prev) => !prev);
    }
  };

  const onQueryChangeHandler = (value) => {
    debounceFn(authInfo.merchantAccountId, value, "");
    setSearchedText(value);
  };

  const renderListItem = (item) => (
    <div style={{ width: "90%" }} className="flex flex-col items-start">
      <div style={{ width: "100%" }} className="truncate text-left">
        {item?.email}
      </div>
      <div
        style={{
          fontSize: smfontsize,
          color: Colors.fontGreyLight,
        }}
      >
        {item.id}
      </div>
    </div>
  );

  return (
    <div className="relative">
      <div
        style={{
          position: "relative",
        }}
        className="flex items-center"
      >
        <button
          style={{
            borderWidth: "1px",
            borderColor: "#D8D8D8",
            borderRadius: "16px",
            padding: isdesktop ? "4px 0px" : "4px 4px",
            marginRight: "10px",
            borderStyle: selectedConnectedAccount ? "solid" : "dashed",
          }}
          onClick={() => {
            setIsListVisible(true);
          }}
          type="button"
          className="flex flex-row items-center focus:outline-none "
        >
          {selectedConnectedAccount ? (
            <button
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedConnectedAccount();
                setSearchedText("");
                setIsListVisible(false);
                statementsListHandler({
                  merchantId: authInfo.merchantAccountId,
                });
              }}
              style={{ padding: "2px 0px 2px 10px" }}
            >
              <AiOutlineClear
                color={Colors.fontGreyLight}
                size={isdesktop ? 22 : 26}
              />
            </button>
          ) : (
            <div style={{ padding: "2px 0px 2px 10px" }}>
              <MdOutlineAccountTree color={Colors.fontGreyLight} size={19} />
            </div>
          )}
          <div
            style={{
              fontFamily: "Poppins",
              fontSize: smfontsize,
              color: Colors.fontGreyLight,
              display: "flex",
              alignItems: "center",
              padding: selectedConnectedAccount
                ? "2px 6px 2px 0px"
                : "2px 10px 2px 0px",
            }}
          >
            <span style={{ marginLeft: "8px" }}>Connected Account</span>
          </div>
          {selectedConnectedAccount && (
            <div
              style={{
                padding: "2px 10px 2px 6px",
                color: Colors.fontGreyLight,
                maxWidth: "170px",
                fontSize: smfontsize,
              }}
              className="border-l border-[#D8D8D8] border-dashed truncate"
            >
              {selectedConnectedAccount.name}
            </div>
          )}
        </button>

        <CustomTypeAndSearch
          isListVisible={isListVisible}
          setIsListVisible={setIsListVisible}
          list={connectedAccountsList?.data}
          onQueryChange={onQueryChangeHandler}
          listItemClickHandler={listItemClickHandler}
          selectedItem={selectedConnectedAccount}
          renderListItem={renderListItem}
          listContainerStyle={{ left: "-320px", right: "0px" }}
          searchQuery={searchedText}
          placeholder="Search Connected Account"
        />
      </div>
    </div>
  );
};

export default FilterWithConnectedAccounts;
