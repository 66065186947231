import { useField } from "formik";
import React, { useState } from "react";
// import Colors from "../assets/Colors";
import PlacesAutocomplete from "react-places-autocomplete";
import CommonInput from "./CommonInput";
import { geocodeByAddress } from "react-google-places-autocomplete";

import { useTheme } from "../store";
import { useDesktopOrLaptop } from "../services/useDesktopOrLaptop";

function FormikTextInput(props) {
  const [field, meta] = useField(props.name);
  const { Colors } = useTheme();
  const { smfontsize ,xsfontsize} = useDesktopOrLaptop();

  return (
    <div
      style={{
        marginBottom: props.marginbottom,
        display: "flex",
        alignItems: "center",
        width: "100%",
      }}
    >
      <div style={{ width: "100%" }}>
        {props.label && <label htmlFor={props.name}>{props.label}</label>}

        {props.address ? (
          <PlacesAutocomplete
            value={meta.value}
            onChange={(e) => {
              props.setFieldValue(props.name, e.trimLeft());
            }}
            onSelect={async (e) => {
              const geocode = await geocodeByAddress(e);
              props.setFieldValue(props.name, geocode[0].formatted_address);
            }}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => {
              return (
                <>
                  <input
                    style={{
                      borderWidth: "1px",
                      borderRadius: "8px",
                      borderColor: Colors.borderPrimary,
                      fontSize: smfontsize,
                      fontWeight: "400",
                      color: Colors.fontDark,
                      backgroundColor: Colors.white,
                      padding: "8px 12px",
                      outline: "none",
                      width: "100%",
                      fontFamily: "Poppins",
                    }}
                    {...field}
                    {...props}
                    id="pickupAddressInput"
                    {...getInputProps({
                      placeholder: "Search Address *",
                      disabled: props.disabled,
                    })}
                    className={` py-3 px-5 w-full `}
                    type="text"
                  />
                  <div className="w-full">
                    {loading ? (
                      <div style={{ color: Colors.fontDark }}>...loading</div>
                    ) : null}
                    {suggestions.map((sug, index) => {
                      const className = sug.active
                        ? "bg-blue-400 text-blue-50"
                        : "";
                      const style = { color: Colors.fontDark };
                      return (
                        <div
                          style={{ fontSize: smfontsize }}
                          id={index}
                          key={sug.index}
                          {...getSuggestionItemProps(sug, {
                            className,
                            style,
                          })}
                        >
                          {sug.description}
                        </div>
                      );
                    })}
                  </div>
                </>
              );
            }}
          </PlacesAutocomplete>
        ) : (
          <input
            style={{
              borderWidth: "1px",
              borderColor: Colors.borderPrimary,
              borderRadius: "8px",
              fontSize: smfontsize,
              fontWeight: "400",
              color: Colors.fontDark,
              padding: "8px 12px",
              outline: "none",
              width: "100%",
              fontFamily: "Poppins",
              backgroundColor: Colors.white,
            }}
            {...field}
            {...props}
          />
        )}
        {meta.error && (meta.touched || props.isClicked) && (
          <p
            style={{
              color: Colors.redDark,
              fontFamily: "Poppins",
              fontSize: xsfontsize,
              padding: "6px 10px 0px 12px",
            }}
          >
            {meta.error}
          </p>
        )}
      </div>
      {props.nonreq && (
        <div
          style={{
            padding: "4px 8px",
            backgroundColor: Colors.bgGrey,
            borderRadius: "8px",
            marginLeft: "10px",
          }}
        >
          optional
        </div>
      )}
    </div>
  );
}

export default FormikTextInput;
