import React, { useEffect, useState } from "react";
import {
  useMyStore,
  useStatementsStore,
  useTheme,
  userDataStore,
} from "../../../../store";
import TableHeader from "./TableHeader";
import { Loading } from "../../../../assets/svgs/Svgs";
import Pagination from "../../../shared/Pagination";
import RecentTrxItem from "./RecentTrxItem";

const PAGE_LIMIT = 30;

const RecentTrx = () => {
  const {
    isRecentTransactionsLoading,
    getRecentTransactions,
    recentTransactionsData,
  } = useStatementsStore();
  const { Colors } = useTheme();
  const { authInfo } = userDataStore();
  const { isSandbox } = useMyStore();
  const [activeId, setActiveId] = useState();

  const recentTransactionsHandler = async (param) => {
    let params = {
      test_mode: param.isSandbox ?? isSandbox,
      ending_before: param.ending_before,
      starting_after: param.starting_after,
      limit: PAGE_LIMIT,
    };
    await getRecentTransactions(
      param.merchantId ?? authInfo.merchantAccountId,
      params
    );
  };

  useEffect(() => {
    let subscribe = true;
    if (subscribe) {
      if (authInfo) {
        recentTransactionsHandler({ merchantId: authInfo.merchantAccountId });
      }
    }

    return () => {
      subscribe = false;
    };
  }, [authInfo, isSandbox]);

  return (
    <>
      <TableHeader />
      <div
        style={{
          height: "calc(100% - 210px)",
          overflowY: "scroll",
        }}
      >
        {isRecentTransactionsLoading && (
          <div
            style={{ minHeight: "100px" }}
            className="w-full flex items-center justify-center kata"
          >
            <Loading
              color={Colors.bluePrimary}
              className="h-14 w-14 animate-spin"
            />
          </div>
        )}
        {recentTransactionsData?.data?.length > 0 ? (
          recentTransactionsData.data.map((invoiceObject, index) => {
            return (
              <RecentTrxItem
                activeId={activeId}
                setActiveId={setActiveId}
                disabled
                key={invoiceObject.id}
                invoiceObject={invoiceObject}
              />
            );
          })
        ) : (
          <div
            style={{ color: Colors.fontDark }}
            className="w-full h-full flex justify-center items-center"
          >
            No transactions found
          </div>
        )}
      </div>
      <div className="flex items-center justify-end pr-28 mt-9">
        <Pagination
          data={recentTransactionsData}
          onGetPaginationData={recentTransactionsHandler}
        />
      </div>
    </>
  );
};

export default RecentTrx;
