import axios from "axios";
import apiUrls from "../../constants/apiUrls";
import { generateQueryParams } from "./utils";

export const setStatementsForMerchant = (id, params) => {
  const urlWithQueryParams = generateQueryParams(
    apiUrls.MERCHANT_ACCOUNTS_STATEMENTS(id),
    params
  );
  return axios.get(urlWithQueryParams, {
    headers: { "x-burq-client": "client-dashboard" },
  });
};
export const transactionsForStatement = (id, params = {}) => {
  const urlWithQueryParams = generateQueryParams(
    apiUrls.DEPOSIT_STATEMENT_TRANSACTIONS(id),
    params
  );
  return axios.get(urlWithQueryParams, {
    headers: { "x-burq-client": "client-dashboard" },
  });
};

export const setStatementById = (id) =>
  axios.get(apiUrls.DEPOSIT_STATEMENT(id), {
    headers: { "x-burq-client": "client-dashboard" },
  });

export const setBillingEvents = (id, params) => {
  const urlWithQueryParams = generateQueryParams(
    apiUrls.BILLING_EVENTS(id),
    params
  );
  return axios.get(urlWithQueryParams, {
    headers: { "x-burq-client": "client-dashboard" },
  });
};

export const setRecentTransactions = (id, params) => {
  const urlWithQueryParams = generateQueryParams(
    apiUrls.RECENT_TRANSACTIONS(id),
    params
  );
  return axios.get(urlWithQueryParams, {
    headers: { "x-burq-client": "client-dashboard" },
  });
};

export const downloadStatementCSVCall = (id, params) => {
  const urlWithQueryParams = generateQueryParams(
    apiUrls.STATEMENT_CSV(id),
    params
  );
  return axios.get(urlWithQueryParams, {
    headers: { "x-burq-client": "client-dashboard" },
  });
};

export const getRelatedStatements = (id, params) => {
  const urlWithQueryParams = generateQueryParams(
    apiUrls.RELATED_STATEMENTS(id),
    params
  );
  return axios.get(urlWithQueryParams, {
    headers: { "x-burq-client": "client-dashboard" },
  });
};

export const depositStatementsInitiatePayment = (id) =>
  axios.post(apiUrls.DEPOSIT_STATEMENTS_INITIATE_PAYMENT(id));
