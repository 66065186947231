import React, { useEffect } from "react";
import { Form, Formik } from "formik";
import { initialValues } from "./utils";
import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";
import {
  useTheme,
  useConnectedAccounts,
  userDataStore,
  useMerchant,
  useStoreAndChild,
} from "../../../store";
import FormFields from "./FormFields";
import { validationSchema } from "./validationSchema";
import { Loading } from "../../../assets/svgs/Svgs";
import { toast } from "react-toastify";

const ModalContent = () => {
  const { Colors } = useTheme();
  const { authInfo } = userDataStore();
  const {
    createAccount,
    createAccountLoading,
    accountForEditing,
    setAccountForEditing,
    setIsCreateAccountModalOpen,
    getConnectedAccounts,
    setSelectedConnectedAccount,
    setIsNewAccountCreated,
  } = useConnectedAccounts();

  const { setIsCreateStoreModalOpen } = useStoreAndChild();

  const { updateMerchantData } = useMerchant();
  const { lgfontsize, mdfontsize } = useDesktopOrLaptop();

  const submitHandler = async (values) => {
    if (accountForEditing) {
      const payload = { ...accountForEditing };
      payload.name = values.name;
      payload.phone_number = values.phone_number;
      payload.email = values.email;
      await updateMerchantData(
        accountForEditing.id,
        payload,
        undefined,
        undefined,
        undefined,
        true
      );
      await getConnectedAccounts(authInfo?.merchantAccountId);
      setIsCreateAccountModalOpen(false);
    } else {
      const cb = (connectAccountData) => {
        setSelectedConnectedAccount(connectAccountData?.data);
        setIsCreateStoreModalOpen(true);
        setIsNewAccountCreated(true);
        toast.success("The account is created successfully.");
      };
      await createAccount(authInfo.merchantAccountId, values, cb);
    }
    setAccountForEditing();
  };

  return (
    <div>
      <Formik
        onSubmit={submitHandler}
        validationSchema={validationSchema}
        enableReinitialize={true}
        initialValues={
          accountForEditing
            ? {
                name: accountForEditing.name,
                email: accountForEditing.email,
                phone_number: accountForEditing.phone_number,
              }
            : initialValues
        }
      >
        {({ values, errors, setFieldValue }) => {
          return (
            <Form>
              <section
                style={{
                  marginTop: "24px",
                  borderRadius: "8px",
                  padding: "16px",
                  height: "100%",
                  width: "100%",
                  // fontSize: mdfontsize,
                }}
                className="shadow-md"
              >
                <div
                  style={{
                    fontFamily: "Poppins",
                    fontSize: lgfontsize,
                    fontWeight: "500",
                    color: Colors.fontDark,
                    marginBottom: "20px",
                    padding: "0px 24px",
                  }}
                >
                  {accountForEditing
                    ? "Edit Connected Account"
                    : "Create Connected Account"}
                </div>
                <div
                  style={{
                    width: "100%",
                    borderBottomWidth: "2px",
                    borderColor: Colors.borderPrimary,
                    marginBottom: "20px",
                  }}
                ></div>

                <FormFields
                  values={values}
                  setFieldValue={setFieldValue}
                  errors={errors}
                />

                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    height: "45px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <button
                    type="submit"
                    style={{
                      width: "30%",
                      height: "100%",
                      backgroundColor: Colors.bluePrimary,
                      borderRadius: "8px",
                      textAlign: "center",
                      color: "#FFFFFF",
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      fontSize: mdfontsize,
                      opacity: Object.keys(errors).length > 0 ? 0.6 : 1,
                    }}
                  >
                    {createAccountLoading ? (
                      <div className="w-full flex justify-center">
                        {" "}
                        <Loading
                          color="#ECF0F1"
                          className="h-6 w-6 animate-spin"
                        />{" "}
                      </div>
                    ) : (
                      "Continue"
                    )}
                  </button>
                </div>
              </section>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ModalContent;
