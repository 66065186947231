import React from "react";
import FormikNewTextInput from "./FormikNewTextInput";
import { useFormikContext } from "formik";
// import Colors from "../../assets/Colors";
import { MdClose } from "react-icons/md";
import { useTheme } from "../../store";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";

function ManifestItemsFormik({ index, styles, isOrder, isClickedOnContinue, isReroute }) {
  const { Colors, themeChanger } = useTheme();

  const { setFieldValue, values, errors } = useFormikContext();
  const { smfontsize, mdfontsize } = useDesktopOrLaptop();

  const itemsTemplate = isOrder
    ? { name: "", quantity: "", merchandise_value: undefined }
    : { name: "", quantity: "", size: "small" };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        marginTop: "30px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {values.dropoff[index].items.map((item, _index) => {
        return (
          <div
            key={_index}
            style={{
              width: "100%",
              marginBottom: "10px",
              position: "relative",
              alignItems: "center",
            }}
          >
            {values.dropoff[index].items.length === 1 && _index === 0 ? null : (
              <button
                onClick={() => {
                  if (isReroute) {
                    return;
                  }
                  const _items = values.dropoff[index].items;
                  if (_items.length > 1) {
                    _items.splice(_index, 1);
                  }

                  setFieldValue(`dropoff[${index}].items`, _items);
                }}
                type="button"
                style={{
                  position: "absolute",
                  right: "-30px",
                  top: "10px",
                  outline: "none",
                }}
              >
                <MdClose size={20} color={Colors.fontDark} />
              </button>
            )}
            <div className="flex flex-row gap-4 w-full justify-between">
              <FormikNewTextInput
                disabled={isReroute}
                isClickedOnContinue={isClickedOnContinue}
                smfontsize={smfontsize}
                value={values.dropoff[index].items[_index].name}
                name={`dropoff[${index}].items[${_index}].name`}
                placeholder={`Item Name`}
                marginbottom="20px"
                onBlur={() => {
                  setFieldValue(`dropoff[${index}].items[${_index}].name`, values.dropoff[index].items[_index].name.trim());
                }}
              />

              <div style={{ width: "30%" }}>
                <FormikNewTextInput
                  disabled={isReroute}
                  onBlur={(e) => {
                    if (!isReroute) {
                      if (e.target.value < 0) {
                        // console.log("value", e.target.value * -1);
                        setFieldValue(`dropoff[${index}].items[${_index}].quantity`, e.target.value * -1);
                      }
                    }
                  }}
                  isClickedOnContinue={isClickedOnContinue}
                  value={values.dropoff[index].items[_index].quantity}
                  name={`dropoff[${index}].items[${_index}].quantity`}
                  placeholder="Quantity"
                  marginbottom="20px"
                  type="number"
                  min={1}
                />
              </div>
              {!isOrder ? (
                <div style={{ width: "40%" }}>
                  <select
                    disabled={isReroute}
                    draggable={true}
                    value={values.dropoff[index].items[_index].size}
                    onChange={(e) => {
                      setFieldValue(`dropoff[${index}].items[${_index}].size`, e.target.value);
                    }}
                    style={{
                      ...styles.inputs,
                      ...{ backgroundColor: Colors.borderPrimary },
                    }}
                    className="py-3 px-5  w-full focus:outline-none "
                  >
                    <option id={"sizeSmallId" + index} value="small">
                      Small
                    </option>
                    <option id={"sizeMediumId" + index} value="medium">
                      Medium
                    </option>
                    <option id={"sizeLargeId" + index} value="large">
                      Large
                    </option>
                    <option id={"sizeExtraId" + index} value="xlarge">
                      Extra Large
                    </option>
                  </select>
                </div>
              ) : (
                <div className="flex flex-col ">
                  <div className="flex items-center" style={{ width: "100%" }}>
                    <div
                      style={{
                        marginBottom: "20px",
                        fontSize: mdfontsize,
                        marginRight: "8px",
                        color: Colors.fontDark,
                      }}
                    >
                      $
                    </div>
                    <input
                      placeholder="Item Value"
                      type="number"
                      onChange={(e) => {
                        const value = e.target.value.replace(/[^.\d]/g, "");
                        // console.log("value", +value, typeof +value);
                        setFieldValue(`dropoff[${index}].items[${_index}].merchandise_value`, value);
                      }}
                      value={values?.dropoff[index]?.items[_index]?.merchandise_value}
                      className={`${themeChanger ? "focus:border-gray-400" : "focus:border-gray-300"}  border border-transparent`}
                      style={{
                        borderRadius: "8px",
                        fontSize: smfontsize,
                        fontWeight: "400",
                        color: Colors.fontDark,
                        padding: "10px 20px",
                        outline: "none",
                        width: "100%",
                        fontFamily: "Poppins",
                        backgroundColor: Colors.borderPrimary,
                        marginBottom: "20px",
                      }}
                    />
                  </div>
                  {errors?.dropoff && errors?.dropoff[index]?.items[_index]?.merchandise_value && isClickedOnContinue && (
                    <p
                      style={{
                        color: Colors.redDark,
                        fontFamily: "Poppins",
                        fontSize: "12px",
                        padding: "6px 10px 3px 12px",
                        marginTop: "-20px",
                      }}
                    >
                      {errors?.dropoff[index]?.items[_index]?.merchandise_value}
                    </p>
                  )}
                  {/* <FormikNewTextInput
                    onBlur={(e) => {
                      const value = e.target.value.replace(/[^.\d]/g, "");
                      console.log("value", +value, typeof +value);
                      setFieldValue(
                        `dropoff[${index}].items[${_index}].merchandise_value`,
                        value
                      );
                      // if (e.target.value < 0) {
                      //   setFieldValue(
                      //     `dropoff[${index}].items[${_index}].merchandise_value`,
                      //     e.target.value * -1
                      //   );
                      // }
                    }}
                    isClickedOnContinue={isClickedOnContinue}
                    value={
                      values.dropoff[index].items[_index].merchandise_value
                    }
                    name={`dropoff[${index}].items[${_index}].merchandise_value`}
                    placeholder="Item Value"
                    marginbottom="20px"
                    type="number"
                    min={1}
                  /> */}
                </div>
              )}
            </div>
          </div>
        );
      })}
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              gap: 8,
              color: "#AAA",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "24px",
              letterSpacing: "0.1px",
              cursor: "pointer",
            }}
            onClick={() => {
              setFieldValue("contains_alcohol", !values.contains_alcohol);
            }}
          >
            <input type="checkbox" checked={values.contains_alcohol} />
            <span>Contains Alcohol</span>
          </div>
        </div>
        <button
          type="button"
          style={{
            fontFamily: "Poppins",
            fontSize: smfontsize,
            fontWeight: "600",
            color: Colors.fontDark,
            outline: "none",
            marginLeft: "auto",
          }}
          onClick={() => {
            if (isReroute) {
              return;
            }
            const _items = values.dropoff[index].items;
            _items.push(itemsTemplate);
            setFieldValue(`dropoff[${index}].items`, _items);
          }}
        >
          + Add Item
        </button>
      </div>
    </div>
  );
}

export default ManifestItemsFormik;
