import React, { useMemo } from "react";
import { useDesktopOrLaptop } from "../../../../../services/useDesktopOrLaptop";
import { useTheme } from "../../../../../store";
import { numberWithCommas } from "../../../utils";

const DataComparisonSection = ({ lineChart, hoveredState }) => {
  const { xsfontsize, mdfontsize } = useDesktopOrLaptop();
  const { Colors } = useTheme();

  const styles = useMemo(() => {
    return {
      container: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "40px",
        paddingLeft: "4px",
      },
      monthText: {
        fontFamily: "Poppins",
        fontWeight: 600,
        fontSize: xsfontsize,
        color: Colors.chartMonth,
        marginBottom: "0px",
      },
      monthValue: {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: mdfontsize,
        color: Colors.bluePrimary,
        margin: "0px",
      },
    };
  }, [Colors, mdfontsize, xsfontsize]);

  if (!lineChart?.data?.length) return null;
  if (hoveredState.isHover)
    return (
      <div style={styles.container}>
        <div>
          <p style={styles.monthValue}>{numberWithCommas(hoveredState.hoverPayload.primaryDataValue)}</p>
          <p style={styles.monthText}>{hoveredState.hoverPayload.primaryDataKey}</p>
        </div>
        {hoveredState?.hoverPayload?.secondaryDataValue && (
          <div>
            <p style={{ ...styles.monthValue, color: Colors.chartMonth }}>
              {numberWithCommas(hoveredState.hoverPayload.secondaryDataValue)}
            </p>
            <p style={styles.monthText}>{hoveredState.hoverPayload.secondaryDataKey}</p>
          </div>
        )}
      </div>
    );
  return (
    <div style={styles.container}>
      <div>
        <p style={styles.monthValue}>{numberWithCommas(lineChart?.primaryRangeTotal)}</p>
        <p style={{ ...styles.monthText, visibility: "hidden" }}>-</p>
      </div>
      {lineChart?.secondaryRangeTotal && (
        <div>
          <p style={{ ...styles.monthValue, color: Colors.chartMonth }}>{numberWithCommas(lineChart?.secondaryRangeTotal)}</p>
          <p style={{ ...styles.monthText, visibility: "hidden" }}>-</p>
        </div>
      )}
    </div>
  );
};

export default DataComparisonSection;
