import React, { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import StatusBadgeReusable from "../../shared/common/StatusBadgeReusable";
import { statusesBadgesForStatements } from "../utlis";

const PaymentStatusForInvoices = ({ data, externalClassName }) => {
  // const [lookupStatus, setLookupStatus] = useState(null);

  // useEffect(() => {
  //   const isRecentPayment = !!data?.most_recent_payment;
  //   const recentPaymentStatus = data?.most_recent_payment?.status;

  //   if (!isRecentPayment && data?.ending_balance !== 0) {
  //     setLookupStatus("unpaid");
  //   } else if (recentPaymentStatus === "unpaid") {
  //     setLookupStatus("processing");
  //   } else if (recentPaymentStatus === "paid") {
  //     setLookupStatus("paid");
  //   } else if (recentPaymentStatus === "failed") {
  //     setLookupStatus("failed");
  //   } else {
  //     Sentry.captureException(
  //       new Error(
  //         "Unexpected payment status or no condition matches the status",
  //         { status: recentPaymentStatus }
  //       )
  //     );
  //   }
  // }, [data]);

  return (
    <StatusBadgeReusable
      lookupKey={data}
      externalClassName={externalClassName}
      // status={}
      showDefault
      defaultType="neutral"
      badgesObject={statusesBadgesForStatements}
    />
  );
};

export default PaymentStatusForInvoices;
