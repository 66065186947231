import React from "react";
import { useTheme } from "../../../../../../store";
import { CheckIcon } from "../../../../../../assets/svgs/Svgs";
import moment from "moment";
import ToolTip from "../../../../../Tooltip/Tooltip";
import classNames from "classnames";
import styles from "./styles/styles.module.css";

const RelatedStatementsListItem = ({
  data,
  selectedRelatedStatement,
  onRelatedStatementChangeHandler,
}) => {
  const { Colors } = useTheme();

  return (
    <div
      onClick={() => {
        onRelatedStatementChangeHandler(data);
      }}
      key={data.id}
      className={classNames(
        "my-2 w-full flex items-center justify-between px-5 py-2",
        styles.periodItem,
        {
          [styles.selectedBilling]: selectedRelatedStatement?.id === data.id,
        }
      )}
    >
      <div
        style={{ color: Colors.fontGreyLight, width: "90%" }}
        className=" flex flex-col items-start "
      >
        <div
          className="text-left truncate"
          style={{
            color: Colors.fontDark,
            width: "100%",
          }}
          data-for={`list-item-${data.id}`}
        >
          {moment(data?.billing_period_start_at).format("MMM D, YYYY")} -{" "}
          {moment(data?.billing_period_end_at).format("MMM D, YYYY")}
        </div>
        <ToolTip
          id={`list-item-${data.id}`}
          disable={false}
          text={data?.name}
        />

        <div style={{}}>{data?.id}</div>
      </div>
      {selectedRelatedStatement?.id === data.id && <CheckIcon />}
    </div>
  );
};

export default RelatedStatementsListItem;
