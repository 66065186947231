import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";
import { useClickStore, useMerchant, useMyStore, useStoreAndChild, useTheme } from "../../../store";
import { canCreateDeliveriesService, errorMessagesSerive } from "../../../utils/helpers";
import { IoIosAddCircle } from "react-icons/io";

const CreateDeliveryButton = () => {
  const { merchantData } = useMerchant();
  const { isSandbox } = useMyStore();
  const { Colors } = useTheme();
  const { isdesktop } = useDesktopOrLaptop();
  const { setIsCalendarHidden, isBurgerMenuVisible } = useClickStore();
  const { setSelectedStore } = useStoreAndChild();

  let history = useNavigate();

  const canCreateDelivery = canCreateDeliveriesService(merchantData?.capabilities, isSandbox);

  const redirectionService = (key) => {
    switch (key) {
      case "payment_method":
        history("/v1/settings/payments");
        break;
      case "account_overdrafted":
        history("/v1/settings/payments");
        break;
      default:
        break;
    }
  };

  const showRequiredAction = (capabilityObj) => {
    let pendingRequirment = "";
    let errorMessage = "Not authorized to create delivery";
    if (capabilityObj?.pendingRequirments?.includes("account_overdrafted")) {
      errorMessage = errorMessagesSerive["account_overdrafted"];
      pendingRequirment = "account_overdrafted";
    }
    if (capabilityObj?.pendingRequirments?.includes("payment_method")) {
      errorMessage = errorMessagesSerive["payment_method"];
      pendingRequirment = "payment_method";
    }

    toast.error(errorMessage);
    redirectionService(pendingRequirment);
  };
  return (
    <button
      id="goToCreatePage"
      onClick={() => {
        setSelectedStore();
        if (canCreateDelivery.isEnabled) {
          history("/delivery");
        } else {
          setIsCalendarHidden(true);
          showRequiredAction(canCreateDelivery);
        }
      }}
      style={{
        width: isdesktop && "100%",
        backgroundColor: Colors.bluePrimary,
        fontSize: isdesktop ? "16px" : "28px",
        fontWeight: "500",
        color: "white",
        height: isdesktop ? "44px" : "80px",
        textAlign: "center",
        borderRadius: "8px",
        padding: !isdesktop && "12px 22px",
      }}
    >
      {isBurgerMenuVisible ? (
        <div className="flex items-center justify-center w-full">
          <IoIosAddCircle className="mr-1.5" size={22} /> Create Delivery
        </div>
      ) : (
        <div className="flex items-center justify-center w-full">
          <IoIosAddCircle className={!isdesktop && "mr-1.5"} size={22} />
          {!isdesktop && "Create Delivery"}
        </div>
      )}
    </button>
  );
};

export default CreateDeliveryButton;
