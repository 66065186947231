import React, { useEffect, useState } from 'react';
import Menu from './Menu';
import onboarding1 from "../../assets/png/onboarding1.png";
import onboarding2 from "../../assets/png/onboarding2.png";
import onboarding3 from "../../assets/png/onboarding3.png";
import styles from './styles/imageCarousal.module.css';


const images = [
  onboarding1,
  onboarding2,
  onboarding3
];

const ImageCarousal = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className={styles.carouselContainer}>
      <Menu activeIndex={currentIndex} onDotClick={handleDotClick} />
      <div className={styles.carousel}>
        <img src={images[currentIndex]} alt={`Slide ${currentIndex + 1}`} />
      </div>
    </div>
  );
};

export default ImageCarousal;
