import React from "react";
import CustomModal from "../../CustomModal/CustomModal";
import { useConnectedAccounts } from "../../../store";
import ModalContent from "./ModalContent";
import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";

const CreateAccountModal = () => {
  const {
    isCreateAccountModalOpen,
    setIsCreateAccountModalOpen,
    setAccountForEditing,
  } = useConnectedAccounts();
  const { isdesktop } = useDesktopOrLaptop();

  return (
    <div>
      <CustomModal
        extraPanelStyles={{ maxWidth: isdesktop ? "50%" : "80%" }}
        isOpen={isCreateAccountModalOpen}
        onClose={() => {
          setIsCreateAccountModalOpen(false);
          setAccountForEditing();
        }}
      >
        <ModalContent />
      </CustomModal>
    </div>
  );
};

export default CreateAccountModal;
