import React, { useEffect, useState } from "react";
import styles from "../styles.module.css";
import DropdownReusable from "../../../../shared/common/DropdownReusable";
import classNames from "classnames";
import { CheckIcon } from "../../../../../assets/svgs/Svgs";
import { IoCubeOutline } from "react-icons/io5";
import { FaRegTrashAlt } from "react-icons/fa";

const DropdownContainer = ({ preference, idx, payloadData, setPayloadData, providers, isPreferenceAdded }) => {
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);

  return (
    <div key={idx} className="mt-4">
      {idx !== 0 && <div className={styles.dividerText}>If not available then</div>}
      <DropdownReusable
        icon={
          <div className={styles.iconCont}>
            <IoCubeOutline color="#0062FF" />
          </div>
        }
        disabled={idx === 4 || idx <= payloadData?.length - 2}
        showCleaner={false}
        rightComponent={
          !isPreferenceAdded && idx === 0 ? null : idx === payloadData.length - 1 ? (
            <div
              onClick={(e) => {
                e.stopPropagation();
                setPayloadData((prev) => prev.slice(0, idx));
              }}
            >
              <FaRegTrashAlt className={styles.trashIcon} />
            </div>
          ) : null
        }
        onChange={() => {
          const _payloadData = [...payloadData];
          _payloadData[idx] = {
            preference_type: "cheapest",
            provider_id: undefined,
          };
          setPayloadData(_payloadData);
        }}
        selectedItem={
          preference?.preference_type === "cheapest"
            ? "Cheapest"
            : providers?.find((item) => item?.id === preference?.provider_id)?.name
        }
        items={providers}
        placeHolder="Select Provider"
        extraContainerClasses={classNames(styles.dropDownComponent, {
          [styles.disabledDropdown]: idx <= payloadData?.length - 2,
        })}
        inputExtras={classNames(styles.dropdownInput)}
        isDropdownOpened={isDropdownOpened}
        setIsDropdownOpened={setIsDropdownOpened}
      >
        <div className={styles.dropdownContainer}>
          <div className={styles.oneItem}>
            <div
              onClick={() => {
                const _payloadData = [...payloadData];
                _payloadData[idx] = {
                  preference_type: "cheapest",
                  provider_id: undefined,
                };
                setPayloadData(_payloadData.slice(0, idx + 1));
                setIsDropdownOpened(false);
              }}
              className={classNames(styles.oneItemInDropdown)}
            >
              Cheapest Option
            </div>
            {preference.preference_type === "cheapest" && <CheckIcon />}
          </div>
          {providers.map((item, index) => {
            if (payloadData.some((pref) => pref.provider_id === item.id)) {
              return <></>;
            }
            return (
              <div key={item.id} className={styles.oneItem}>
                <div
                  onClick={() => {
                    const _payloadData = [...payloadData];
                    if (!_payloadData[idx + 1]) {
                      _payloadData.push(
                        (_payloadData[idx] = {
                          preference_type: "cheapest",
                          provider_id: undefined,
                        })
                      );
                    }
                    _payloadData[idx] = {
                      preference_type: "select_provider",
                      provider_id: item.id,
                    };

                    setPayloadData(_payloadData);
                    setIsDropdownOpened(false);
                  }}
                  className={classNames(styles.oneItemInDropdown)}
                >
                  {item.name}
                </div>
                {item.id === preference.provider_id && <CheckIcon />}
              </div>
            );
          })}
        </div>
      </DropdownReusable>
    </div>
  );
};

export default DropdownContainer;
