import React, { useState } from "react";
import TimelineItem from "./TimeLineItem";
import DisclosureComp from "../../shared/common/DisclosureComp";
import styles from "./styles.module.css";
import { BsChevronContract, BsChevronExpand } from "react-icons/bs";

const Timeline = ({ events }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <div className={"w-full relative px-9"}>
      {/* {events?.length > 4 ? (
        <DisclosureComp
          defaultOpened
          // titleClasses={styles.summaryTitle}
          // containerClasses={styles.disclosure}
          title={
            <div className={styles.expandCircle}>
              <BsChevronExpand className={styles.expandIcon} />
            </div>
          }
        >
          <div>dasdakdjals</div>
        </DisclosureComp>
      ) : (
        events.map((event, index) => {
          const isLast = events?.length - 1 === index;
          return (
            <TimelineItem
              key={index}
              status={event.status}
              time={event.time}
              index={index}
              length={events.length}
              isLast={isLast}
            />
          );
        })
      )} */}

      {events.slice(0).map((event, index) => {
        const length = events?.length;
        const isLast = length - 1 === index;
        if (length > 4) {
          if ((index < 2 || index > length - 3) && !isExpanded) {
            return (
              <TimelineItem
                key={index}
                status={event.status}
                time={event.time}
                index={index}
                length={events.length}
                isLast={isLast}
              />
            );
          } else if (isExpanded) {
            return (
              <TimelineItem
                key={index}
                status={event.status}
                time={event.time}
                index={index}
                length={events.length}
                isLast={isLast}
              />
            );
          }
        } else {
          return (
            <TimelineItem
              key={index}
              status={event.status}
              time={event.time}
              index={index}
              length={events.length}
              isLast={isLast}
            />
          );
        }
      })}
      {!isExpanded && events?.length > 4 && (
        <div
          onClick={() => {
            setIsExpanded(true);
          }}
          className={styles.expandCircle}
        >
          <BsChevronExpand className={styles.expandIcon} />
        </div>
      )}
      {/* {isExpanded && events?.length > 4 && (
        <div
          onClick={() => {
            setIsExpanded((prev) => !prev);
          }}
          className={styles.expandCircle}
        >
          <BsChevronContract className={styles.expandIcon} />
        </div>
      )} */}
    </div>
  );
};

export default Timeline;
