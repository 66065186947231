import { useFormikContext } from "formik";
import { debounce } from "lodash";
import { BsExclamationCircle } from "react-icons/bs";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { PickupSvg } from "../../../../assets/svgs/Svgs";
import ButtonComp from "../../../../components/ButtonComp/ButtonComp";
import ReusableDropdown from "../../../../components/ReusableDropdown/ReusableDropdown";
import StoresDropdownList from "../../../../components/ReusableDropdown/StoresDropdownList";

import {
  useClickStore,
  useConnectedAccounts,
  useMerchant,
  userDataStore,
  useStoreAndChild,
  useTheme,
} from "../../../../store";
import styles from "./styles.module.css";
import classNames from "classnames";
import { ROLES } from "../../../../components/AccountSettings/RolesSidebar/helpers";

const StoreDetailsForOrder = ({ isClickedOnContinue, coordinatesHandler }) => {
  const { Colors } = useTheme();
  const { userRole } = userDataStore();
  const { isAdmin } = useMerchant();

  const [isStoresVisible, setIsStoresVisible] = useState(false);
  const { values, setFieldValue } = useFormikContext();

  const { useOnClickOutside } = useClickStore();
  const { setSelectedConnectedAccount } = useConnectedAccounts();
  let {
    allStoresByUser,
    getAllStoresByUser,
    setIsCreateStoreModalOpen,
    selectedStore,
    setSelectedStore,
  } = useStoreAndChild();

  const clickRef = useRef();
  const handler = useCallback(() => {
    setIsStoresVisible(false);
  }, []);
  const { authInfo } = userDataStore();

  useOnClickOutside(clickRef, handler);

  const dataHandler = async () => {
    await getAllStoresByUser(authInfo.merchantAccountId).catch((err) =>
      console.log(err)
    );
  };

  useEffect(() => {
    let subscribe = true;
    if (subscribe) {
      if (allStoresByUser && allStoresByUser?.data?.length > 0) {
        setIsStoresVisible(true);
      } else {
        if (authInfo) {
          dataHandler();
          if (!values.storeId) {
            setSelectedStore();
          }
        }
      }

      return () => {
        subscribe = false;
      };
    }
  }, [allStoresByUser, authInfo]);

  const debounceFn = useCallback(
    debounce((search) => {
      getAllStoresByUser(authInfo.merchantAccountId, false, {
        search_phrase: search,
      });
    }, 800),
    []
  );

  const onChange = (search) => {
    debounceFn(search);
  };

  return (
    <div className={styles.container}>
      <div
        className={classNames(
          styles.title,
          "font-semibold text-base flex flex-row items-center justify-between"
        )}
      >
        <div className="flex items-center">
          <div className={styles.svgBg}>
            <PickupSvg />
          </div>
          <span>Set up Pick Up Location</span>
        </div>
        {(userRole === ROLES.ADMIN || isAdmin) && (
          <ButtonComp
            onClick={() => {
              setIsCreateStoreModalOpen(true);
              setSelectedConnectedAccount();
            }}
            primary
            title={"Create Store"}
            extraStyles={{ fontWeight: "500" }}
          />
        )}
      </div>
      <ReusableDropdown
        onChange={onChange}
        placeHolderStyles={
          selectedStore ? { color: Colors.fontDark, marginLeft: "10px" } : {}
        }
        placeHolder={selectedStore ? selectedStore.name : "Select Store"}
        isDropdownVisible={isStoresVisible}
        setIsDropdownVisible={setIsStoresVisible}
        height={
          allStoresByUser?.data?.length > 0
            ? `-${allStoresByUser?.data?.length * 48}px`
            : "-18px"
        }
      >
        {allStoresByUser?.data?.length > 0 &&
          allStoresByUser?.data?.map((item, index) => {
            return (
              <StoresDropdownList
                onItemClick={() => {
                  let addresses = [{ pickup_address: item.address }];
                  coordinatesHandler(addresses, "pickup", true);
                  setSelectedStore(item);
                  setFieldValue("storeId", item.id);
                  setIsStoresVisible(false);
                }}
                index={index}
                item={item}
                title={item.name}
                key={item.id}
              />
            );
          })}
      </ReusableDropdown>
      <div className="h-3 w-full">
        {isClickedOnContinue && !selectedStore && (
          <p className={styles.errorText}>Store is required</p>
        )}
      </div>
      {selectedStore && (
        <div
          className={classNames(
            "flex items-center mt-6 px-1",
            styles.fontLight
          )}
        >
          <div className={classNames("font-medium", styles.addressText)}>
            Address:{" "}
          </div>
          <span className={classNames("ml-2", styles.addressText)}>
            {selectedStore?.address}
          </span>
        </div>
      )}
      {(userRole === ROLES.ADMIN || isAdmin) && (
        <div
          className={classNames(
            "flex items-center mt-6 px-1",
            styles.fontLight
          )}
        >
          <BsExclamationCircle />
          <span className="ml-2">
            If the address you want doesn't appear in the dropdown, you can add
            a new store by clicking the "Create Store" button.
          </span>
        </div>
      )}
    </div>
  );
};

export default StoreDetailsForOrder;
