import * as Yup from "yup";

export const pricingRulesSchema = Yup.object().shape({
  condition: Yup.array().of(
    Yup.object().shape({
      type: Yup.mixed().oneOf(["expression", "logical_operator"]),
      value: Yup.mixed().when("type", {
        is: (v) => v === "logical_operator",
        then: Yup.mixed()
          .oneOf(["OR", "AND"])
          .required(
            "logical operation is required. Please check the missing logical operation."
          ),
        otherwise: Yup.object().shape({
          left_side_value: Yup.object().shape(
            {
              variable: Yup.string().nullable().required("Field is required"),
              operation: Yup.string().when("field_value", {
                is: (v) => !!v,
                then: Yup.string()
                  .nullable()
                  .required(
                    "If field value is set, operation is also required"
                  ),
                otherwise: Yup.string().nullable().optional(),
              }),
              field_value: Yup.number().when("operation", {
                is: (v) => !!v,
                then: Yup.number()
                  .nullable()
                  .required(
                    "If operation is set, field value is also required"
                  ),
                otherwise: Yup.number().nullable().optional(),
              }),
            },
            [["operation", "field_value"]]
          ),
          right_side_value: Yup.object().shape(
            {
              variable: Yup.string().when("operation", {
                is: (v) => !!v,
                then: Yup.string()
                  .nullable()
                  .required("If operation is set, variable is also required."),
                otherwise: Yup.string().nullable().optional(),
              }), // optional - name of variable if used in case of calculations to set final value.
              operation: Yup.string().when("variable", {
                is: (v) => !!v,
                then: Yup.string()
                  .nullable()
                  .required("If variable is set, operation is also required"),
                otherwise: Yup.string().nullable().optional(),
              }), //enum// optional - multiplication/division/subtraction/addition. If provided will perform this operation with variable and value.
              field_value: Yup.number()
                .nullable()
                .required("Value is required."),
            },
            [["variable", "operation"]]
          ),
          equality_operator: Yup.string()
            .nullable()
            .required("Operator is required."),
        }),
      }),
    })
  ),
  result: Yup.object().shape({
    field: Yup.string().nullable().required("Field is required."),
    value: Yup.object().shape(
      {
        variable: Yup.string().when("operation", {
          is: (v) => !!v,
          then: Yup.string()
            .nullable()
            .required("If operation is set, variable is also required."),
          otherwise: Yup.string().nullable().optional(),
        }), // optional - name of variable if used in case of calculations to set final value.
        operation: Yup.string().when("variable", {
          is: (v) => !!v,
          then: Yup.string()
            .nullable()
            .required("If variable is set, operation is also required"),
          otherwise: Yup.string().nullable().optional(),
        }), //enum// optional - multiplication/division/subtraction/addition. If provided will perform this operation with variable and value.
        field_value: Yup.number().nullable().required("Value is required."),
      },
      [["operation", "variable"]]
    ),
  }),
});
