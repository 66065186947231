import { statusesObject } from "../../runTimeConst";

export const deliveryStatuses = [
  { back: "pending", front: "Pending" },
  { back: "pickup", front: "Pickup" },
  { back: "dropoff", front: "Dropoff" },
  { back: "delivered", front: "Delivered" },
  { back: "canceled", front: "Canceled" },
  { back: "returned", front: "Returned" },
  { back: "delivery_created", front: "Delivery Created" },
  { back: "driver_assigned", front: "Driver Assigned" },
  { back: "driver_not_assigned", front: "Assigning Driver" },
  { back: "enroute_pickup", front: "Enroute to Pick-up" },
  { back: "arrived_at_pickup", front: "Arrived at Pick-up" },
  { back: "pickup_complete", front: "Pick-up Complete" },
  { back: "enroute_dropoff", front: "Enroute to Drop-off" },
  { back: "arrived_at_dropoff", front: "Arrived at Drop-off" },
  { back: "dropoff_complete", front: "Drop off Completed" },
  { back: "contacting_provider", front: "Contacting Provider" },
  { back: "scheduled", front: "Scheduled" },
  { back: "enroute_to_return", front: "Enroute to Return" },
  { back: "provider_canceled", front: "Provider Canceled" },
  { back: "customer_canceled", front: "Merchant Canceled" },
  { back: "burq_canceled", front: "Burq Canceled" },
  { back: "expired", front: "Expired" },
  { back: "failed", front: "Failed" },
  { back: "disputed", front: "Disputed" },
];

// export const badgesForApiReuqestStatus = {
//   200: { type: "success", label: "200 OK" },
//   201: { type: "success", label: "201 Created" },
//   202: { type: "success", label: "202 Accepted" },
//   203: { type: "success", label: "203 Non-Authoritative Information" },
//   204: { type: "success", label: "204 No Content" },
//   500: { type: "error", label: "500 Internal Server Error" },
//   502: { type: "error", label: "502 Bad Gateway" },
//   503: { type: "error", label: "503 Service Unavailable" },
//   504: { type: "error", label: "504 Gateway Timeout" },
//   400: { type: "error", label: "400 Bad Request" },
//   401: { type: "error", label: "401 Unauthorized" },
//   402: { type: "error", label: "402 Payment Required" },
//   403: { type: "error", label: "403 Forbidden" },
//   404: { type: "error", label: "404 Not Found" },
//   408: { type: "error", label: "408 Request Timeout" },
// };

export const badgesForApiReuqestStatus = {
  200: { type: "success" },
  201: { type: "success" },
  202: { type: "success" },
  203: { type: "success" },
  204: { type: "success" },
  500: { type: "error" },
  502: { type: "error" },
  503: { type: "error" },
  504: { type: "error" },
  400: { type: "error" },
  401: { type: "error" },
  402: { type: "error" },
  403: { type: "error" },
  404: { type: "error" },
  408: { type: "error" },
};

export const getStatus = ({statusSocket, rerouteStatus}) => {
  if (statusSocket === "provider_canceled" && rerouteStatus === "not_reroutable") {
    return "Canceled - Not Reroutable"
  } else if (rerouteStatus === "provider_search") {
    return "Attempting Reroute"
  } else if (rerouteStatus === "rerouted") {
    return "Rerouted"
  } else
    return statusesObject[statusSocket];
}
