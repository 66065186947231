import React from "react";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import { useClickStore, useTheme } from "../../store";
import { IoIosAddCircle } from "react-icons/io";

const CreateOrderButton = () => {
  const { Colors } = useTheme();
  const { isdesktop } = useDesktopOrLaptop();
  const { isBurgerMenuVisible } = useClickStore();

  return (
    <button
      id="goToCreateOrderPage"
      onClick={() => {
        window.location.href = `${process.env.REACT_APP_NEW_REACT_DOMAIN}/v1/orders?create_order=true`;
      }}
      style={{
        width: isdesktop && "100%",
        backgroundColor: Colors.bluePrimary,
        fontSize: isdesktop ? "16px" : "28px",
        fontWeight: "500",
        color: "white",
        height: isdesktop ? "44px" : "80px",
        textAlign: "center",
        borderRadius: "8px",
        padding: !isdesktop && "12px 22px",
      }}
    >
      {isBurgerMenuVisible ? (
        <div className="flex items-center justify-center w-full">
          <IoIosAddCircle className="mr-1.5" size={22} /> Create Order
        </div>
      ) : (
        <div className="flex items-center justify-center w-full">
          <IoIosAddCircle className={!isdesktop && "mr-1.5"} size={22} />
          {!isdesktop && "Create Order"}
        </div>
      )}
    </button>
  );
};

export default CreateOrderButton;
