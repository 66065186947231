import React, { useRef, useCallback, useState, useEffect } from "react";
import {
  useMyStore,
  useClickStore,
  useTheme,
  useStoreAndChild,
  useFilter,
} from "../store";
import { BiCheckboxChecked, BiCheckbox } from "react-icons/bi";
import { useDesktopOrLaptop } from "../services/useDesktopOrLaptop";
// import { statuses } from "../runTimeConst";
const statuses = [
  // { back: "pending", front: "Pending" },
  // { back: "pickup", front: "Pickup" },
  // { back: "dropoff", front: "Dropoff" },
  // { back: "canceled", front: "Canceled" },
  { back: "returned", front: "Returned" },
  { back: "delivered", front: "Delivered" },
  { back: "delivery_created", front: "Delivery Created" },
  { back: "driver_assigned", front: "Driver Assigned" },
  { back: "driver_not_assigned", front: "Assigning Driver" },
  { back: "enroute_pickup", front: "Enroute to Pick-up" },
  { back: "arrived_at_pickup", front: "Arrived at Pick-up" },
  { back: "pickup_complete", front: "Pick-up Complete" },
  { back: "enroute_dropoff", front: "Enroute to Drop-off" },
  { back: "arrived_at_dropoff", front: "Arrived at Drop-off" },
  { back: "dropoff_complete", front: "Drop off Completed" },
  { back: "contacting_provider", front: "Contacting Provider" },
  { back: "scheduled", front: "Scheduled" },
  { back: "enroute_to_return", front: "Enroute to Return" },
  { back: "provider_canceled", front: "Provider Canceled" },
  { back: "customer_canceled", front: "Merchant Canceled" },
  { back: "burq_canceled", front: "Burq Canceled" },
  { back: "expired", front: "Expired" },
  { back: "failed", front: "Failed" },
  { back: "disputed", front: "Disputed" },
];

function Filter({
  isFilterVisible,
  setIsFilterVisible,
  startDate,
  setPageCount,
  isAdmin,
}) {
  const { isdesktop, smfontsize, mdfontsize } = useDesktopOrLaptop();

  const { Colors } = useTheme();
  const { selectedStore } = useStoreAndChild();
  const { filterStore, filterArray, setFilterArray, search, selectedProvider } =
    useFilter();

  const { useOnClickOutside, isCalendarVisible, setIsCalendarVisible } =
    useClickStore();
  const clickRef = useRef();
  const handler = useCallback(() => {
    setIsFilterVisible(false);
  });
  useOnClickOutside(clickRef, handler);
  const { deliveries } = useMyStore();
  const buttonStyle = {
    fontSize: smfontsize,
    fontWeight: "400",
    fontFamily: "Poppins",
    textAlign: "start",
    marginRight: "10px",
    paddingLeft: "16px",
    display: "none",
    color: Colors.fontGreyLight,
  };
  const labelStyle = {
    fontSize: smfontsize,
    fontWeight: "400",
    fontFamily: "Poppins",
    textAlign: "start",
    paddingLeft: "12px",
    paddingRight: "12px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "4px 0px",
    color: Colors.fontGreyLight,
  };
  const [isAllFilter, setIsAllFilter] = useState(false);

  const filterHandler = (_filterArray) => {
    if (isAdmin) {
      return;
    } else {
      deliveries(
        search,
        0,
        startDate,
        filterStore?.id ?? selectedStore?.id ?? "",
        _filterArray
      );
    }
  };

  const filterArrayHandler = (item) => {
    let _filterArray = [...filterArray];

    if (filterArray?.includes(item)) {
      _filterArray = _filterArray.filter((e) => e !== item);

      setFilterArray(_filterArray);
      filterHandler(_filterArray);
    } else {
      _filterArray.push(item);
      setFilterArray(_filterArray);
      filterHandler(_filterArray);
    }
  };

  return (
    <div className="relative" ref={clickRef}>
      <div
        style={{
          left: !isAdmin
            ? isdesktop
              ? "-110px"
              : "-280px"
            : isdesktop
            ? "-220px"
            : "-300px",
          right: !isAdmin
            ? isdesktop
              ? "-100px"
              : "-20px"
            : isdesktop
            ? "0px"
            : "0px",
          top: isdesktop ? "48px" : "60px",
          paddingTop: "5px",
          paddingBottom: "5px",
          // backgroundColor: "red",
          backgroundColor: Colors.bgDarkSecondary,
          zIndex: 999,
          maxHeight: isdesktop ? "280px" : "900px",
          
        }}
        className={`${
          isFilterVisible ? "visible" : "hidden"
        } absolute overflow-y-scroll  rounded-md shadow-xl flex flex-col z-50 scrollbar`}
      >
        {statuses.map((item, index) => {
          return (
            <label
              key={index}
              style={{
                ...labelStyle,
                ...{
                  color: !filterArray?.includes(item.back)
                    ? Colors.fontGreyLight
                    : Colors.fontDark,
                },
              }}
              htmlFor={`${item.front}Label`}
            >
              <input
                checked={filterArray?.includes(item.back)}
                onChange={() => {
                  filterArrayHandler(item.back);
                  setPageCount(0);
                  setIsAllFilter(false);
                }}
                id={`${item.front}Label`}
                type="checkBox"
                name="Delivered"
                style={buttonStyle}
              ></input>
              {filterArray?.includes(item.back) ? (
                <BiCheckboxChecked size={isdesktop ? 22 : 28} color="#0062FF" />
              ) : (
                <BiCheckbox size={isdesktop ? 22 : 28} />
              )}
              {item.front}
            </label>
          );
        })}

        <label
          style={{
            ...labelStyle,
            ...{
              color: !isAllFilter ? Colors.fontGreyLight : Colors.fontDark,
              marginLeft: "4px",
            },
          }}
          htmlFor="allLabel"
        >
          <input
            checked={isAllFilter}
            onChange={() => {
              setFilterArray([]);
              setIsAllFilter(!isAllFilter);
              setIsCalendarVisible(true);
              setIsFilterVisible(false);
              setPageCount(0);
              filterHandler("");
            }}
            id="allLabel"
            type="checkBox"
            name="Delivered"
            style={buttonStyle}
          ></input>
          All
        </label>
      </div>
    </div>
  );
}

export default Filter;
