import React from "react";
import { useProvidersManagementStore } from "../../../store";
import Loader from "../../shared/atoms/Loader";
import styles from "../styles/ProviderList/styles.module.css";
import ProviderCard from "./ProviderCard";

const ProviderList = ({ providers }) => {
  const { isMerchantProvidersLoading } = useProvidersManagementStore();

  if (isMerchantProvidersLoading) {
    return (
      <div className={styles.cardsGrid}>
        <div className={styles.loader}>
          <Loader />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.cardsGrid}>
      {providers?.length ? (
        providers.map((item) => <ProviderCard key={item.id} provider={item} />)
      ) : (
        <div className={styles.noProvidersText}>
          No providers are available.
        </div>
      )}
    </div>
  );
};

export default ProviderList;
