import React, { useEffect, useState } from "react";

import { useMyStore, userDataStore, useStoreAndChild, useTheme, useWorkflow } from "../../../store";
import AllStatuses from "./AllStatuses/AllStatuses";
import TemporaryArea from "./TemporaryArea";
import RightBar from "./RightSidebar/RightBar";
import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";
import WorkflowSidebarOpener from "../HelperComponents/WorkflowSidebarOpener";

function SmsWorkflow({ isInsideStore }) {
  const [isLeftOpened, setIsLeftOpened] = useState(true);
  const { Colors } = useTheme();
  const { mdfontsize } = useDesktopOrLaptop();
  const { loginAuth } = userDataStore();
  const { getSmsworkflowHandler, getSmsSettings } = useWorkflow();
  const { selectedStore } = useStoreAndChild();
  const { userId } = useMyStore();
  const { isdesktop } = useDesktopOrLaptop();

  const firstLoadHandler = async () => {
    const authData = await loginAuth();
    getSmsSettings(userId, authData.data.user.merchantAccountId);
    getSmsworkflowHandler(userId, authData.data.user.merchantAccountId);
  };
  // };

  const loadHandler = async (kata) => {
    if (kata) return;
    if (userId) {
      getSmsworkflowHandler(userId);
    } else {
      await firstLoadHandler();
    }
  };

  useEffect(() => {
    loadHandler();
    return () => {
      loadHandler(true);
    };
  }, [selectedStore]);

  return (
    <div
      style={{
        backgroundImage: `radial-gradient(${Colors.bgDots} 20%, ${Colors.bgWorkflow} 14%)`,
        backgroundPosition: "0px 0px",
        backgroundSize: "20px 20px",
        height: isdesktop
          ? isInsideStore
            ? `calc(100vh - 210px)`
            : `calc(100vh - 70px)`
          : isInsideStore
          ? `calc(100vh - 380px)`
          : `calc(100vh - 200px)`,
        overflowY: "auto",
        display: "flex",
        flexDirection: "row",
        width: "100%",
      }}
    >
      <WorkflowSidebarOpener isInsideStore={isInsideStore} isLeftOpened={isLeftOpened} setIsLeftOpened={setIsLeftOpened} />

      <div
        className="kata"
        style={{
          width: "350px",
          transform: isdesktop
            ? isLeftOpened
              ? `translate(0px)`
              : `translate(-350px)`
            : isLeftOpened
            ? `translate(0px)`
            : `translate(-334px)`,
          borderRightWidth: "1px",
          borderColor: Colors.borderSecondary,
          backgroundColor: Colors.white,
          padding: "20px",
          position: "absolute",
          top: isdesktop ? (isInsideStore ? "211px" : "70px") : isInsideStore ? "377px" : "200px",
          left: "0px",
          bottom: "0px",
          zIndex: 20,
        }}
      >
        <div
          style={{
            fontFamily: "Poppins",
            fontWeight: "500",
            fontSize: mdfontsize,
            color: Colors.fontDark,
          }}
        >
          SMS Workflow{" "}
        </div>
        <AllStatuses />
      </div>
      <div
        className="kata"
        style={{
          position: "absolute",
          left: "0px",
          top: isdesktop ? (isInsideStore ? "210px" : "70px") : isInsideStore ? "380px" : "200px",
          bottom: "0px",
          right: "0px",
          display: "flex",
          marginLeft: isdesktop ? "350px" : "30px",
          alignItems: "center",
        }}
      >
        <TemporaryArea isdesktop={isdesktop} />
      </div>

      {/* <RightSidebar isdesktop={isdesktop} /> */}
      <RightBar isdesktop={isdesktop} isInsideStore={isInsideStore} />
    </div>
  );
}

export default SmsWorkflow;
