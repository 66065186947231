import React, { useEffect, useState } from "react";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../assets/svgs/Svgs";
import styles from "./styles/checkoutFormStyles.module.css";
import { useOnboardingStore } from "../../store";

const CheckoutForm = ({ options, returnUrl, isInsideStore = false }) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState(null);

  const { isLoading, retrieveSetupIntent, setIsLoading } = useOnboardingStore();

  useEffect(() => {
    // Retrieve the "setup_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    const clientSecret = new URLSearchParams(window.location.search).get("setup_intent_client_secret");
    if (!stripe || !clientSecret) return;
    // Retrieve the SetupIntent
    retrieveSetupIntent(stripe, clientSecret, isInsideStore);
    navigate({ replace: true });
  }, [window.location.search, stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: returnUrl,
      },
    });
    if (error) {
      setErrorMessage(error.message);
    }
    setIsLoading(false);
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <PaymentElement className={styles.paymentElement} options={options} />
      <button className={styles.button} disabled={isLoading || !stripe || !elements} id="submitForElemntId" type="submit">
        <span>
          {isLoading ? (
            <div className="w-full flex justify-center">
              <Loading color="#ECF0F1" className="h-6 w-6 animate-spin" />{" "}
            </div>
          ) : (
            "Continue"
          )}
        </span>
      </button>
      {errorMessage && <div className={styles.paymentMessage}>{errorMessage}</div>}
    </form>
  );
};

export default CheckoutForm;
