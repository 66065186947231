import { useField, useFormikContext } from "formik";
import React, { useState } from "react";
import { BsCheck } from "react-icons/bs";
import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";
import { useTheme } from "../../../store";
// import Colors from "../../assets/Colors";
// import "../AdminPage.css";

const TipsForOrder = ({}) => {
  const { Colors } = useTheme();

  const { mdfontsize, smfontsize } = useDesktopOrLaptop();

  const { values, setFieldValue } = useFormikContext();
  const [meta] = useField("tip");

  const styles = {
    checkBtn: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "16px",
      width: "16px",
      borderRadius: "8px",
    },
    bottomCardText: {
      fontSize: mdfontsize,
      fontFamily: "Poppins",
      fontWeight: "500",
    },
  };

  return (
    <div
      style={{
        width: "100%",
        borderRadius: "8px",
        backgroundColor: Colors.bgListHeader,
        display: "flex",
        flexDirection: "column",
        padding: "20px",
      }}
    >
      <div
        style={{
          ...styles.bottomCardText,
          ...{
            color: Colors.fontGreyLight,
            marginBottom: "10px",
          },
        }}
      >
        Tip:{" "}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <button
            onClick={() => {
              setFieldValue("tip", 0);
            }}
            type="button"
            style={{
              display: "flex",
              alignItems: "center",
              outline: "none",
              marginRight: "40px",
            }}
          >
            <div
              style={{
                ...styles.checkBtn,
                ...{
                  backgroundColor:
                    values.tip === 0 ? "#6ED29D" : Colors.buttonBg,
                  border: values.tip === 0 ? "0px" : "solid 1px #92929D",
                },
              }}
            >
              <BsCheck color={values.tip === 0 ? "white" : Colors.buttonBg} />
            </div>
            <div
              className="whitespace-nowrap"
              style={{
                ...styles.bottomCardText,
                ...{ color: Colors.fontGreyLight, marginLeft: "10px" },
              }}
            >
              $ 0
            </div>
          </button>
          <button
            onClick={() => {
              setFieldValue("tip", 1);
            }}
            type="button"
            style={{
              display: "flex",
              alignItems: "center",
              outline: "none",
              marginRight: "40px",
            }}
          >
            <div
              style={{
                ...styles.checkBtn,
                ...{
                  backgroundColor:
                    values.tip === 1 ? "#6ED29D" : Colors.buttonBg,
                  border: values.tip === 1 ? "0px" : "solid 1px #92929D",
                },
              }}
            >
              <BsCheck color={values.tip === 1 ? "white" : Colors.buttonBg} />
            </div>
            <div
              className="whitespace-nowrap"
              style={{
                ...styles.bottomCardText,
                ...{ color: Colors.fontGreyLight, marginLeft: "10px" },
              }}
            >
              $ 1
            </div>
          </button>
          <button
            onClick={() => {
              setFieldValue("tip", 2);
            }}
            type="button"
            style={{
              display: "flex",
              alignItems: "center",
              outline: "none",
              marginRight: "40px",
            }}
          >
            <div
              style={{
                ...styles.checkBtn,
                ...{
                  backgroundColor:
                    values.tip === 2 ? "#6ED29D" : Colors.buttonBg,
                  border: values.tip === 2 ? "0px" : "solid 1px #92929D",
                },
              }}
            >
              <BsCheck color={values.tip === 2 ? "white" : Colors.buttonBg} />
            </div>
            <div
              className="whitespace-nowrap"
              style={{
                ...styles.bottomCardText,
                ...{ color: Colors.fontGreyLight, marginLeft: "10px" },
              }}
            >
              $ 2
            </div>
          </button>
          <button
            onClick={() => {
              setFieldValue("tip", 3);
            }}
            type="button"
            style={{
              display: "flex",
              alignItems: "center",
              outline: "none",
              marginRight: "40px",
            }}
          >
            <div
              style={{
                ...styles.checkBtn,
                ...{
                  backgroundColor:
                    values.tip === 3 ? "#6ED29D" : Colors.buttonBg,
                  border: values.tip === 3 ? "0px" : "solid 1px #92929D",
                },
              }}
            >
              <BsCheck color={values.tip === 3 ? "white" : Colors.buttonBg} />
            </div>
            <div
              className="whitespace-nowrap"
              style={{
                ...styles.bottomCardText,
                ...{ color: Colors.fontGreyLight, marginLeft: "10px" },
              }}
            >
              $ 3
            </div>
          </button>
          <button
            onClick={() => {
              setFieldValue("tip", 4);
            }}
            type="button"
            style={{
              display: "flex",
              alignItems: "center",
              outline: "none",
              marginRight: "40px",
            }}
          >
            <div
              style={{
                ...styles.checkBtn,
                ...{
                  backgroundColor:
                    values.tip === 4 ? "#6ED29D" : Colors.buttonBg,
                  border: values.tip === 4 ? "0px" : "solid 1px #92929D",
                },
              }}
            >
              <BsCheck color={values.tip === 4 ? "white" : Colors.buttonBg} />
            </div>
            <div
              className="whitespace-nowrap"
              style={{
                ...styles.bottomCardText,
                ...{ color: Colors.fontGreyLight, marginLeft: "10px" },
              }}
            >
              $ 4
            </div>
          </button>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              marginRight: "6px",
              fontSize: mdfontsize,
              color: Colors.fontDark,
            }}
          >
            $
          </div>
          <input
            placeholder="Custom tip"
            type="number"
            onChange={(e) => {
              const value = e.target.value.replace(/[^.\d]/g, "");
              setFieldValue("tip", +value);
            }}
            value={values.tip}
            style={{
              padding: "10px 0px 10px 20px",
              borderRadius: "8px",
              outline: "none",
              fontSize: smfontsize,
              backgroundColor: Colors.borderPrimary,
              borderWidth: "1px",
              borderColor: Colors.borderPrimary,
              color: Colors.fontDark,
              width: "130px",
            }}
          />
        </div>
      </div>
      {meta.error && (
        <div
          style={{
            textAlign: "end",
            fontFamily: "Poppins",
            color: Colors.redDark,
            marginTop: "10px",
          }}
        >
          {meta.error}
        </div>
      )}
    </div>
  );
};

export default TipsForOrder;
