import { createStore } from "aniuta";
import * as Sentry from "@sentry/react";
import axios from "axios";
import { toast } from "react-toastify";
import { baseUrl } from "../runTimeConst";

const useProofDownloader = createStore({
  name: "useProofDownloader",
  Store: () => {
    const onDownloadImages = async (id, signature) => {
      try {
        let url = `${baseUrl}delivery/downloadImages/${id}`;
        if (signature) {
          url = url + "?signature=true";
        }
        const response = await axios({
          method: "get",
          url,
          responseType: "blob",
          headers: { "x-burq-client": "client-dashboard" },
        });
        const link = document.createElement("a");
        const blobUrl = URL.createObjectURL(response.data);
        link.href = blobUrl;
        link.download = `${id}.zip`;
        link.click();
      } catch (error) {
        if (error.response.status === 400) {
          toast.error(`delivery not contains any image!`);
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
        return error;
      }
    };
    return { onDownloadImages };
  },
});

export default useProofDownloader;
