import moment from "moment";

export const notificationsObject = {
  payment_failed: { type: "alert", label: "Payment Failed" },
  payment_card_expired: { type: "warning", label: "Card on File" },
  delivery_provider_canceled: { type: "alert", label: "Provider Canceled" },
  delivery_incident_result: { type: "alert", label: "Incident Result" },
  delivery_reroute: { type: "info", label: "Rerouted Delivery" },
  new_feature: { type: "primary", label: "New Feature" },
};

export const dummyData = [
  {
    type: "payment_failed",
    desc: "11111descriotion lorem ipsum aman iman egi qna da racxa unda dasa sd asd as da sd asd as da ",
    // created_at: new Date(),
    created_at: "2024-02-18T10:27:30.000Z",
    id: "0_bcnsi36e_snhdi636",
    object: "merchant_account_notification",
    source_id: "id can be of different items based on what type is(optional)",
    read: false,
  },
  {
    type: "payment_failed",
    desc: "222222s aris romelzec vcvli droebs lorem ipsum aman iman egi qna da racxa unda dasa sd asd as da sd asd as da ",
    // created_at: new Date(),
    created_at: "2024-02-16T05:27:30.000Z",
    id: "0_bcnsi36e_snhdi6326",
    object: "merchant_account_notification",
    source_id: "id can be of different items based on what type is(optional)",
    read: true,
  },
  {
    type: "payment_failed",
    desc: "33333descriotion lorem ipsum aman iman egi qna da racxa unda dasa sd asd as da sd asd as da ",
    // created_at: new Date(),
    created_at: "2024-02-19T02:17:30.000Z",
    id: "0_bcnsi36e_snhaddi6326",
    object: "merchant_account_notification",
    source_id: "id can be of different items based on what type is(optional)",
    read: true,
  },
  {
    type: "payment_failed",
    desc: "444444 lorem ipsum aman iman egi qna da racxa unda dasa sd asd as da sd asd as da ",
    // created_at: new Date(),
    created_at: "2024-02-17T02:17:30.000Z",
    id: "0_bcnsi36e_snhadsddi6326",
    object: "merchant_account_notification",
    source_id: "id can be of different items based on what type is(optional)",
    read: true,
  },
  {
    type: "card_expired",
    desc: "555 lorem ipsum aman iman egi qna da racxa unda dasa sd asd as da sd asd as da ",
    // created_at: new Date(),
    created_at: "2024-02-17T12:17:30.000Z",
    id: "0_bcnsi36e_sndashadsddi6326",
    object: "merchant_account_notification",
    source_id: "id can be of different items based on what type is(optional)",
    read: true,
  },
  {
    type: "card_expired",
    desc: "4444descriotion lorem ipsum aman iman egi qna da racxa unda dasa sd asd as da sd asd as da ",
    created_at: "2024-01-29T19:52:45.000Z",
    id: "0_bcnsi36e_snhdi636",
    object: "merchant_account_notification",
    source_id: "id can be of different items based on what type is(optional)",
    read: false,
  },
  {
    type: "provider_canceled",
    desc: "descriotion lorem ipsum aman iman egi qna da racxa unda dasa sd asd as da sd asd as da ",
    created_at: "2024-01-29T10:14:20.000Z",
    id: "0_bcnsi36e_snhdi636",
    object: "merchant_account_notification",
    source_id: "id can be of different items based on what type is(optional)",
    read: false,
  },
  {
    type: "incident_result",
    desc: "descriotion lorem ipsum aman iman egi qna da racxa unda dasa sd asd as da sd asd as da ",
    created_at: "2024-01-27T22:36:58.000Z",
    id: "0_bcnsi36e_snhdi636",
    object: "merchant_account_notification",
    source_id: "id can be of different items based on what type is(optional)",
    read: true,
  },
  {
    type: "rerouted_delivery",
    desc: "descriotion lorem ipsum aman iman egi qna da racxa unda dasa sd asd as da sd asd as da ",
    created_at: "2024-01-26T16:09:03.000Z",
    id: "0_bcnsi36e_snhdi636",
    object: "merchant_account_notification",
    source_id: "id can be of different items based on what type is(optional)",
    read: true,
  },
  {
    type: "new_feature",
    desc: "descriotion lorem ipsum aman iman egi qna da racxa unda ",
    created_at: "2024-01-25T12:38:25.000Z",
    id: "FeatureId",
    object: "merchant_account_notification",
    source_id: "id can be of different items based on what type is(optional)",
    read: true,
  },
  {
    type: "new_feature",
    desc: "descriotion lorem ipsum aman iman egi qna da racxa unda ",
    created_at: "2024-01-23T17:22:44.000Z",
    id: "FeatureId",
    object: "merchant_account_notification",
    source_id: "id can be of different items based on what type is(optional)",
    read: true,
  },
  {
    type: "rerouted_delivery",
    desc: "descriotion lorem ipsum aman iman egi qna da racxa unda dasa sd asd as da sd asd as da ",
    created_at: "2024-01-22T23:59:01.000Z",
    id: "0_bcnsi36e_snhdi636",
    object: "merchant_account_notification",
    source_id: "id can be of different items based on what type is(optional)",
    read: true,
  },

  {
    type: "incident_result",
    desc: "descriotion lorem ipsum aman iman egi qna da racxa unda dasa sd asd as da sd asd as da ",
    created_at: "2024-01-21T13:40:55.000Z",
    id: "0_bcnsi36e_snhdi636",
    object: "merchant_account_notification",
    source_id: "id can be of different items based on what type is(optional)",
    read: true,
  },

  {
    type: "provider_canceled",
    desc: "descriotion lorem ipsum aman iman egi qna da racxa unda dasa sd asd as da sd asd as da ",
    created_at: "2024-01-20T18:03:27.000Z",
    id: "0_bcnsi36e_snhdi636",
    object: "merchant_account_notification",
    source_id: "id can be of different items based on what type is(optional)",
    read: true,
  },

  {
    type: "card_expired",
    desc: "descriotion lorem ipsum aman iman egi qna da racxa unda dasa sd asd as da sd asd as da ",
    created_at: "2024-01-19T20:30:16.000Z",
    id: "0_bcnsi36e_snhdi636",
    object: "merchant_account_notification",
    source_id: "id can be of different items based on what type is(optional)",
    read: true,
  },
  {
    type: "payment_failed",
    desc: "descriotion lorem ipsum aman iman egi qna da racxa unda dasa sd asd as da sd asd as da ",
    created_at: "2024-01-18T11:16:02.000Z",
    id: "0_bcnsi36e_snhdi636",
    object: "merchant_account_notification",
    source_id: "id can be of different items based on what type is(optional)",
    read: true,
  },

  {
    type: "provider_canceled",
    desc: "descriotion lorem ipsum aman iman egi qna da racxa unda dasa sd asd as da sd asd as da ",
    created_at: "2024-01-17T11:12:02.000Z",
    id: "0_bcnsi36e_snhdi636",
    object: "merchant_account_notification",
    source_id: "id can be of different items based on what type is(optional)",
    read: true,
  },
  {
    type: "incident_result",
    desc: "descriotion lorem ipsum aman iman egi qna da racxa unda dasa sd asd as da sd asd as da ",
    created_at: "2024-01-13T09:05:37.000Z",
    id: "0_bcnsi36e_snhdi636",
    object: "merchant_account_notification",
    source_id: "id can be of different items based on what type is(optional)",
    read: true,
  },
  {
    type: "provider_canceled",
    desc: "descriotion lorem ipsum aman iman egi qna da racxa unda dasa sd asd as da sd asd as da ",
    created_at: "2024-01-13T11:12:02.000Z",
    id: "0_bcnsi36e_snhdi636",
    object: "merchant_account_notification",

    source_id: "id can be of different items based on what type is(optional)",
    read: true,
  },
];

let lastDisplayedDate = null;
export const dateHandler = (item, styles, isLast, length) => {
  // console.log("islast", isLast, length);
  // const itemDate = new Date(item.created_at);
  // const now = new Date();
  // const timeDiff = now - itemDate;
  // const hoursDiff = timeDiff / (1000 * 60 * 60);
  // const daysDiff = hoursDiff / 24;
  // const yesterday = moment().subtract(1, "days");

  // console.log(
  //   "moment()",
  //   moment(item.created_at).format("dddd D MMM, YYYY, HH:mma"),
  //   moment().isSame(item.created_at, "day"),
  //   lastDisplayedDate
  // );

  // if (hoursDiff < 2 && lastDisplayedDate !== "today") {
  //   lastDisplayedDate = "new";
  //   return <div className={styles.date}>New - Last 2 hours</div>;
  // } else
  // if (
  //   moment().isSame(item.created_at, "day") &&
  //   lastDisplayedDate !== "today"
  // ) {
  //   lastDisplayedDate = "today";
  //   return <div className={styles.date}>Today</div>;
  // } else if (
  //   moment(item.created_at).isSame(yesterday, "day") &&
  //   lastDisplayedDate !== "yesterday"
  // ) {
  //   lastDisplayedDate = "yesterday";
  //   return <div className={styles.created_at}>Yesterday</div>;
  // } else if (
  //   moment(item.created_at).isBefore(yesterday, "day") &&
  //   lastDisplayedDate !== moment(item.created_at).format("dddd D MMM, YYYY")
  // ) {
  //   lastDisplayedDate = moment(item.created_at).format("dddd D MMM, YYYY");
  //   return (
  //     <div className={styles.created_at}>
  //       {moment(item.created_at).format("dddd D MMM, YYYY")}
  //     </div>
  //   );
  // }
  if (length === 1) {
    // lastDisplayedDate === null;
    return (
      <div className={styles.created_at}>
        {moment(item.created_at).format("dddd D MMM, YYYY")}
      </div>
    );
  } else if (
    lastDisplayedDate !== moment(item.created_at).format("dddd D MMM, YYYY")
  ) {
    lastDisplayedDate = moment(item.created_at).format("dddd D MMM, YYYY");
    return (
      <div className={styles.created_at}>
        {moment(item.created_at).format("dddd D MMM, YYYY")}
      </div>
    );
  }
  if (isLast) {
    lastDisplayedDate = null;
  }
};

let lastUpdatedItems;

export const handleScroll = async (
  container,
  notifications,
  setNotifications,
  merchantAccountId,
  getNotificationsHandler
) => {
  const scrollTop = container.scrollTop;
  const scrollHeight = container.scrollHeight;
  const clientHeight = container.clientHeight;

  // console.log("obje2312ct", scrollTop + clientHeight, scrollHeight);

  if (scrollTop + clientHeight >= scrollHeight) {
    if (notifications?.has_more) {
      let params = {
        ending_before: "",
        starting_after: lastUpdatedItems
          ? lastUpdatedItems.data[lastUpdatedItems?.data?.length - 1].id
          : notifications?.data[notifications?.data?.length - 1].id,
      };
      // console.log("obje2312ct", lastUpdatedItems, notifications);

      const items = await getNotificationsHandler(
        merchantAccountId,
        params,
        false,
        true
      );

      // Load new list items
      // const newItems = [...allStoresByUser?.data, ...items?.data];
      const newItems = lastUpdatedItems
        ? {
            data: [...lastUpdatedItems?.data, ...items?.data],
            has_more: items?.has_more,
          }
        : {
            data: [...notifications?.data, ...items?.data],
            has_more: items?.has_more,
          };
      setNotifications(newItems);
      lastUpdatedItems = newItems;
    }
  }
};
