import React, { useEffect } from "react";

import { isEqual } from "lodash";
import { useDesktopOrLaptop } from "../../../../services/useDesktopOrLaptop";
import { useTheme } from "../../../../store";

const RadioFilter = ({
  id,
  show,
  list = [],
  renderListItem = null,
  filterState,
  setFilterState,
  activeFiltersIds,
  setActiveFiltersIds,
  filter,
}) => {
  const { smfontsize, isdesktop } = useDesktopOrLaptop();
  const { Colors, themeChanger } = useTheme();

  const listItemClickHandler = (item) => {
    if (!isEqual(filterState, item)) setFilterState(item);
  };

  useEffect(() => {
    if (filterState && !activeFiltersIds.includes(id)) {
      setActiveFiltersIds([...activeFiltersIds, id]);
    }
    if (!filterState && activeFiltersIds.includes(id)) {
      setActiveFiltersIds([...activeFiltersIds.filter((f) => f !== id)]);
    }
  }, [filterState]);

  return (
    <div
      style={{
        paddingTop: "0px",
        backgroundColor: Colors.bgDarkSecondary,
        zIndex: 999,
        height: "auto",
        maxHeight: isdesktop ? "320px" : "35px",
        maxWidth: "300px",
        width: "230px",
        display: show ? "flex" : "none",
        position: "absolute",
        left: "100%",
        top: 0,
      }}
      className={`visible absolute rounded-md shadow-xl flex flex-col justify-between z-50`}
    >
      <div className="kata overflow-y-scroll" style={{ flex: 1 }}>
        {!list?.length ? (
          <div
            style={{
              textAlign: "center",
              margin: isdesktop ? "4px 0px" : "18px 0px",
              color: Colors.fontGreyLight,
              height: "80%",
            }}
          >
            {" "}
            No options available{" "}
          </div>
        ) : (
          list.map((item) => (
            <div
              onClick={() => listItemClickHandler(item)}
              key={item.label}
              className={`flex justify-between items-center   ${!themeChanger ? "hover:bg-gray-700" : "hover:bg-gray-100"}`}
              style={{
                // height: "40px",
                borderRadius: "8px",
                padding: "4px 10px",
                margin: isdesktop ? "4px 0px" : "18px 0px",
                width: "100%",
                display: "flex",
                gap: "10px",
                cursor: "pointer",
              }}
            >
              <input type="checkbox" readOnly checked={filterState && filterState[filter.label] === item[filter.label]} />
              {renderListItem ? (
                renderListItem(item)
              ) : (
                <div style={{ color: Colors.fontGreyLight, width: "100%" }} className=" flex flex-col items-start ">
                  <div
                    className="text-left truncate"
                    style={{
                      // color: Colors.fontDark,
                      width: "100%",
                      fontSize: smfontsize,
                    }}
                  >
                    {item && filter?.label ? item[filter.label] : item?.name}
                  </div>
                  {item?.id && <div style={{ fontSize: smfontsize }}>{item?.id}</div>}
                </div>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default RadioFilter;
