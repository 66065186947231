import React from "react";
import {
  useIncidents,
  useSingleItemStore,
  useTheme,
} from "../../../../../store";
import { useNavigate } from "react-router-dom";

import { TbRoute } from "react-icons/tb";
import styles from "./styles.module.css";
import classNames from "classnames";

const RerouteButtonForIncident = ({ delivery_id }) => {
  const { setRerouteItem, setIsAdminReroute } = useSingleItemStore();

  const history = useNavigate();

  const { getDeliveryByIdHandler } = useIncidents();

  return (
    <button
      onClick={async () => {
        const _delInfo = await getDeliveryByIdHandler(delivery_id);
        if (_delInfo) {
          setRerouteItem(_delInfo);
        }

        setIsAdminReroute(true);
        history(`/reroute/${delivery_id}`);
      }}
      className={classNames(
        `focus:outline-none px-4 py-2.5 flex items-center`,
        styles.buttonContainer
      )}
    >
      <span
        style={{
          marginRight: "8px",
        }}
      >
        <TbRoute className={styles.iconColor} size={18} />
      </span>
      Reroute
    </button>
  );
};

export default RerouteButtonForIncident;
