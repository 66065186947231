import React, { useMemo } from "react";
import { useTheme } from "../../../../store";
import HeatChartHeaderTab from "./HeatChartHeaderTab";

const HeatChartHeader = () => {
  const { Colors } = useTheme();

  const styles = useMemo(() => {
    return {
      container: {
        display: "flex",
        justifyContent: "space-between",
        borderRadius: "8px",
        margin: "0 12px",
        marginBottom: "10px"
      },
      headingText: {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "18px",
        lineHeight: "27px",
        color: Colors.fontDark,
      },
    };
  }, [Colors]);

  return (
    <div style={styles.container}>
      <h1 style={styles.headingText}>Top Timing</h1>
      <HeatChartHeaderTab />
    </div>
  );
};

export default HeatChartHeader;
