import React, { useEffect, useState } from "react";
import DropdownReusable from "../../shared/common/DropdownReusable";
import styles from "./styles.module.css";
import useWebhooks from "../../../store/useWebhooks";
import { useTheme } from "../../../store";
import classNames from "classnames";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { IoMdRemoveCircleOutline } from "react-icons/io";
import { toast } from "react-toastify";
import Button from "../../shared/atoms/Button";
import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";
import AreYouSurePopup from "../../shared/common/AreYouSurePopup";
import Banner from "../../shared/common/Banner";
import Changelog from "./Changlog";

const WebhooksSubscription = ({ webhookId, isLatestVersion, setCreateWebhookVisible, webhook }) => {
  const {
    getSubscribleEventTypes,
    subscriblesData,
    postAddEventTypes,
    getSubscribedEvents,
    subscribedEventsData,
    subscribedEventsLoading,
    setSubscribedEventsLoading,
    deteleEventType,
    isDeleteEventLoading,
    setWebhookForUpdate,
    setIsUpgrading,
  } = useWebhooks();
  const { Colors } = useTheme();

  const [isDropdownOpened, setIsDropdownOpened] = useState(false);

  const [isSurePopupOpened, setIsSurePopupOpened] = useState(false);
  const [eventForSurePopup, setEventForSurePopup] = useState();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setSubscribedEventsLoading(true);
    if (webhookId) {
      getSubscribleEventTypes(webhookId);
      getSubscribedEvents(webhookId);
    }
  }, [webhookId]);

  const postEventsHandler = async (wbId, event_type) => {
    setIsLoading(true);
    const res = await postAddEventTypes(wbId, {
      event_type,
    });
    if (res) {
      await getSubscribedEvents(webhookId);
      setIsDropdownOpened(false);
      await getSubscribleEventTypes(webhookId);
    }
    setIsLoading(false);
  };
  const deleteEventHandler = async (id) => {
    const res = await deteleEventType(id);
    if (res) {
      getSubscribedEvents(webhookId);
      getSubscribleEventTypes(webhookId);
    }
  };

  return (
    <div className="w-full flex justify-between mt-4">
      <div className={` ${webhookId ? "mr-32" : ""} mb-3`}>
        <AreYouSurePopup
          helperText={`You are requesting to remove "${eventForSurePopup?.event_type}"?`}
          onClose={() => {
            setIsSurePopupOpened(false);
            setEventForSurePopup();
          }}
          onYes={async () => {
            if (!isDeleteEventLoading) {
              await deleteEventHandler(eventForSurePopup.id);
            }
            setIsSurePopupOpened(false);
          }}
          isOpen={isSurePopupOpened}
        />
        {webhookId && (
          <>
            <DropdownReusable
              extraContainerClasses={styles.dropdownCont}
              onChange={() => {}}
              placeHolder="Select Event"
              isDropdownOpened={isDropdownOpened}
              setIsDropdownOpened={setIsDropdownOpened}
            >
              <div
                className={classNames(styles.dropdownContainer, "noScroll")}
                style={{
                  borderColor: Colors.borderPrimary,
                }}
              >
                {subscriblesData?.data &&
                  subscriblesData.data.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={styles.oneItem}
                        onClick={() => {
                          if (isLatestVersion) {
                            if (!isLoading) {
                              postEventsHandler(webhookId, item.id);
                            }
                          } else {
                            toast.error("Please upgrade to the latest version to be able to make changes");
                          }
                        }}
                      >
                        <div className={styles.oneItemTitle}>
                          <div className={classNames(styles.oneItemInDropdown)}>{item.name}</div>
                          <AiOutlinePlusCircle className={styles.plusCircleIcon} size={24} />
                        </div>
                        <div className={classNames("w-full px-5", styles.desc)}>{item.description}</div>
                      </div>
                    );
                  })}
              </div>
            </DropdownReusable>
            <div className="mt-4">
              {subscribedEventsData?.data?.length > 0
                ? subscribedEventsData.data.map((item, index) => {
                    return (
                      <div className={styles.subscribedEventItem} key={item.id}>
                        <div className="flex w-full justify-between items-center">
                          <div>{item.event_type}</div>
                          <div
                            onClick={() => {
                              if (isLatestVersion) {
                                setIsSurePopupOpened(true);
                                setEventForSurePopup(item);
                              } else {
                                toast.error("Please upgrade to the latest version to be able to make changes");
                              }
                            }}
                            className={styles.remove}
                          >
                            <IoMdRemoveCircleOutline size={22} />
                          </div>
                        </div>
                        <div className={classNames("w-full mt-2 pr-7", styles.desc)}>{item.description}</div>
                      </div>
                    );
                  })
                : isLatestVersion &&
                  !subscribedEventsLoading && (
                    <div className="w-4/6">
                      <Banner
                        type="warning"
                        customTitle={"Incomplete webhook configuration"}
                        body={
                          "Please select from the dropdown to subscribe to the events you want to receive webhooks for. Until subscriptions are selected, no webhooks will be triggered"
                        }
                      />
                    </div>
                  )}
            </div>
          </>
        )}
      </div>
      {!isLatestVersion && webhookId && (
        <div className={styles.explanation}>
          <div>
            <div>
              <div className={classNames("mb-10", styles.warningText)}>Your webhook version is outdated.</div>
              Please review the changes below and click the button at the bottom to accept these changes and upgrade your webhook
              traffic immediately. For more information, please refer to our{" "}
              <a
                rel="noreferrer"
                href="https://burq.readme.io/v1.3.0/reference/webhooks"
                target="_blank"
                className={styles.apiUrl}
              >
                API documentation
              </a>{" "}
              on webhooks.
            </div>
            <br />
            <Changelog />
          </div>
          <div className="w-full flex justify-end mt-9">
            {!isLatestVersion && (
              <Button
                onClick={() => {
                  setCreateWebhookVisible(true);
                  setWebhookForUpdate(webhook || undefined);
                  setIsUpgrading(true);
                }}
                text="Accept all changes and upgrade webhook traffic"
                externalClassName={styles.button}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default WebhooksSubscription;
