import { useTheme } from "../../store";

const SettingSVG = ({ isSelected }) => {
  const { Colors } = useTheme();

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
      <path
        fill={isSelected ? Colors.bluePrimary : Colors.fontGreyLight}
        d="M18 6.75C19.3781 6.75 20.5312 7.8975 20.5312 9.28125C20.5312 10.6594 19.3781 11.8125 18 11.8125C16.6219 11.8125 15.4688 10.6594 15.4688 9.28125C15.4688 7.8975 16.6219 6.75 18 6.75Z"
        // fill="#9B9B9B"
      />
      <path
        d="M18 15.4688C19.3781 15.4688 20.5312 16.6163 20.5312 18C20.5312 19.3781 19.3781 20.5312 18 20.5312C16.6219 20.5312 15.4688 19.3781 15.4688 18C15.4688 16.6163 16.6219 15.4688 18 15.4688Z"
        fill={isSelected ? Colors.bluePrimary : Colors.fontGreyLight}
      />
      <path
        d="M18 24.1875C19.3781 24.1875 20.5312 25.335 20.5312 26.7188C20.5312 28.0969 19.3781 29.25 18 29.25C16.6219 29.25 15.4688 28.0969 15.4688 26.7188C15.4688 25.335 16.6219 24.1875 18 24.1875Z"
        fill={isSelected ? Colors.bluePrimary : Colors.fontGreyLight}
      />
    </svg>
  );
};

const VerticalHandlerSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" fill="none" viewBox="0 0 8 8">
      <g fill="#92929D" fillOpacity="0.3" clipPath="url(#clip0_17757_13546)">
        <path d="M1.46 3.522h5.08a.168.168 0 00.132-.274L4.132.064a.169.169 0 00-.263 0l-2.54 3.184a.168.168 0 00.131.274zM6.54 4.479H1.46a.168.168 0 00-.132.273l2.54 3.185a.169.169 0 00.264 0l2.54-3.185a.168.168 0 00-.132-.273z"></path>
      </g>
      <defs>
        <clipPath id="clip0_17757_13546">
          <path fill="#fff" d="M0 0H8V8H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

const EnterSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 14 14">
      <path
        fill="#0062FF"
        d="M2.625 1.75h.583a.292.292 0 01.292.292v5.833a.292.292 0 00.291.292h5.763L7.548 6.16a.292.292 0 010-.412l.412-.413a.292.292 0 01.413 0l3.208 3.209a.293.293 0 010 .412l-3.208 3.209a.292.292 0 01-.413 0l-.412-.413a.292.292 0 010-.412l2.006-2.007H3.791a1.46 1.46 0 01-1.458-1.458V2.042a.292.292 0 01.292-.292z"
      ></path>
    </svg>
  );
};

const StoreIcon = ({ color }) => {
  const { Colors } = useTheme();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 18 18">
      <path
        fill={color || Colors.fontGreyLight}
        d="M16.59 6.205l-.946-2.736a1.755 1.755 0 00-1.657-1.18H4.028c-.747 0-1.413.474-1.657 1.18l-.947 2.736c-.225.65-.147 1.313.213 1.818.37.519 1 .817 1.729.817.714 0 1.431-.287 1.983-.767.427.478 1.067.767 1.778.767a2.68 2.68 0 001.88-.766 2.68 2.68 0 001.881.766c.711 0 1.352-.29 1.778-.767a3.125 3.125 0 001.607.737v4.747c0 .565-.46 1.026-1.026 1.026h-1.961v-2.518a2.18 2.18 0 00-2.179-2.179h-.165a2.18 2.18 0 00-2.178 2.179v2.518H4.768c-.565 0-1.026-.46-1.026-1.026V10.21a.563.563 0 00-1.125 0v3.347c0 1.186.965 2.15 2.151 2.15h8.479a2.153 2.153 0 002.15-2.15V8.724c.405-.131.746-.371.981-.701.36-.505.438-1.168.213-1.818zM7.89 14.583v-2.518c0-.581.472-1.054 1.053-1.054h.165c.581 0 1.054.473 1.054 1.054v2.518H7.889zm7.573-7.213c-.158.222-.447.345-.812.345-.614 0-1.258-.362-1.603-.9a.562.562 0 00-1.012.14c-.14.461-.59.76-1.147.76-.584 0-1.135-.337-1.372-.837a.563.563 0 00-1.017 0c-.236.5-.788.837-1.372.837-.557 0-1.007-.299-1.147-.76a.563.563 0 00-1.012-.14c-.345.538-.989.9-1.602.9-.366 0-.655-.123-.813-.345-.148-.208-.171-.49-.066-.797l.947-2.737a.63.63 0 01.594-.423h9.959a.63.63 0 01.594.423l.946 2.737c.107.306.083.59-.065.797z"
      ></path>
    </svg>
  );
};

export { SettingSVG, VerticalHandlerSvg, EnterSvg, StoreIcon };
