import { createStore } from "aniuta";

import { useState } from "react";
import { toast } from "react-toastify";

import * as Sentry from "@sentry/react";
import {
  deleteBranding,
  getBrandingSetting,
  postBrandingSetting,
  postBrandingUploadFile,
  putBrandingSetting,
} from "../utils/api/branding";

const useBranding = createStore({
  name: "useBranding",
  Store: () => {
    const [isGetBrandingLoading, setIsGetBrandingLoading] = useState(false);

    const [isPostBrandingLoading, setIsPostBrandingLoading] = useState(false);

    const postBrandingSettingsHandler = async (id, payload) => {
      // setIsPostBrandingLoading(true);
      try {
        const { data } = await postBrandingSetting(id);

        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
        return "error";
      } finally {
        setIsPostBrandingLoading(false);
      }
    };
    const putBrandingSettingsHandler = async (id, payload) => {
      // setIsPostBrandingLoading(true);
      try {
        const { data } = await putBrandingSetting(id, payload);

        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
        return "error";
      } finally {
        setIsPostBrandingLoading(false);
      }
    };

    const [isUploadLoading, setIsUploadLoading] = useState(false);

    const postBrandingImageUpload = async (id, params, payload) => {
      setIsUploadLoading(true);
      try {
        const { data } = await postBrandingUploadFile(id, params, payload);
        return data;
      } catch (error) {
        if (error?.response?.status === 413) {
          toast.error(error?.response?.statusText);
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
        return;
      } finally {
        setIsUploadLoading(false);
      }
    };

    const deleteBrandingImage = async (id, params) => {
      try {
        const data = await deleteBranding(id, params);
        return data;
      } catch (error) {
        if (error?.response?.status === 413) {
          toast.error(error?.response?.statusText);
        } else if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
        return;
      }
    };

    return {
      isGetBrandingLoading,
      setIsGetBrandingLoading,
      isPostBrandingLoading,
      setIsPostBrandingLoading,
      postBrandingSettingsHandler,
      postBrandingImageUpload,
      isUploadLoading,

      putBrandingSettingsHandler,

      deleteBrandingImage,
    };
  },
});

export default useBranding;
