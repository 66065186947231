import React, { useEffect } from "react";
import {
  useMyStore,
  useOrders,
  useStoreAndChild,
  userDataStore,
  useOrdersCsvUpload,
} from "../../../store";
import Loader from "../../shared/atoms/Loader";
import { useFlag } from "@unleash/proxy-client-react";
import classNames from "classnames";
import Button from "../../shared/atoms/Button";
import OrdersListItem from "./OrdersListItem";
import OrdersListEmptyState from "./OrdersListEmptyState";
import useViewportMeta from "../../../customHooks/useViewportMeta";
import OrdersListHeader from "./OrdersListHeader";
import { BiSolidPlusCircle } from "react-icons/bi";
import Pagination from "../../shared/Pagination";
import OrderListFilters from "./OrderListFilters";
import styles from "./styles/ordersList.module.css";
import OrdersListLoading from "./OrdersListLoading";
import { useNavigate } from "react-router-dom";
import { UploadIcon } from "../OrderCsvSvgs";
import { UploadCsvForBulk } from "./UploadCsvForBulk";

const OrdersList = () => {
  useViewportMeta();
  const navigate = useNavigate();
  const { authInfo } = userDataStore();
  const { isSandbox } = useMyStore();
  const {
    orders,
    isOrdersLoading,
    setOrder,
    getOrdersWorker,
    searchByPickupId,
    searchByPickupAddress,
    searchByPickupName,
    searchByPickupPhoneNumber,
    searchByDropoffAddress,
    searchByDropoffName,
    searchByDropoffPhoneNumber,
    searchByOrderId,
    formatedDateRange,
    activeStatus,
    filterByStore,
    filterByExternalOrderId,
  } = useOrders();
  const { getAllStoresByUser } = useStoreAndChild();

  const isCsvUploadEnabled = useFlag("uploadBulkCsv");

  const { setIsUploadModalVisible } = useOrdersCsvUpload();

  const isOrdersEnabled = useFlag("orders");

  const getOrdersHandler = async ({ starting_after, ending_before } = {}) => {
    const params = {
      test_mode: isSandbox,
      starting_after: starting_after || null,
      ending_before: ending_before || null,
    };
    const id = authInfo.merchantAccountId;
    await getOrdersWorker(id, params);
  };

  useEffect(() => {
    let subscribe = true;
    if (subscribe) {
      if (authInfo && isOrdersEnabled) {
        getOrdersHandler();
      }
    }

    return () => {
      subscribe = false;
    };
  }, [
    authInfo,
    isSandbox,
    isOrdersEnabled,
    searchByPickupId,
    searchByPickupAddress,
    searchByPickupName,
    searchByPickupPhoneNumber,
    searchByDropoffAddress,
    searchByDropoffName,
    searchByDropoffPhoneNumber,
    searchByOrderId,
    formatedDateRange,
    activeStatus,
    filterByStore,
    filterByExternalOrderId,
  ]);

  const storeListHandler = async (accountId) => {
    await getAllStoresByUser(accountId);
    return;
  };

  useEffect(() => {
    if (authInfo?.merchantAccountId) {
      storeListHandler(authInfo.merchantAccountId);
    }
  }, [authInfo]);

  const renderTableBody = () => {
    if (isOrdersLoading) {
      return <OrdersListLoading />;
    } else if (!orders?.data?.length) {
      return <OrdersListEmptyState />;
    } else return orders.data.map((item) => <OrdersListItem data={item} />);
  };
  // if (!isOrdersEnabled) {
  //   return <Loader />;
  // }

  return (
    <div className={classNames("overflow-x-auto", styles.container)}>
      <UploadCsvForBulk />
      <div className={styles.header}>
        <h1 className={styles.heading}>Orders</h1>
        <div>
          {isCsvUploadEnabled && (
            <Button
              externalClassName={classNames(styles.button, "mr-3")}
              variant="secondary"
              onClick={() => {
                setIsUploadModalVisible(true);
                // setOrder();
              }}
            >
              <div className={styles.buttonContent}>
                <UploadIcon />

                <div className={styles.uploadButtonText}>Upload Orders</div>
              </div>
            </Button>
          )}
          <Button
            externalClassName={styles.button}
            onClick={() => {
              navigate(`/v1/orders/create`);
              setOrder();
            }}
          >
            <div className={styles.buttonContent}>
              <BiSolidPlusCircle size={22} />
              <div>Create Order</div>
            </div>
          </Button>
        </div>
      </div>
      <OrderListFilters />
      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <thead className={styles.tableHeader}>
            <OrdersListHeader />
          </thead>

          <tbody className={classNames(styles.tableBody, "kata")}>
            {renderTableBody()}
          </tbody>
          <tfoot className={styles.tableFooter}>
            <tr className="flex items-center justify-end pr-28 mt-4">
              <td colSpan="4">
                <Pagination
                  data={orders}
                  onGetPaginationData={getOrdersHandler}
                  dependencies={[
                    isSandbox,
                    searchByPickupId,
                    searchByPickupAddress,
                    searchByPickupName,
                    searchByPickupPhoneNumber,
                    searchByDropoffAddress,
                    searchByDropoffName,
                    searchByDropoffPhoneNumber,
                    searchByOrderId,
                    formatedDateRange,
                    activeStatus,
                    filterByExternalOrderId,
                  ]}
                />
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default OrdersList;
