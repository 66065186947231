import axios from "axios";
import apiUrls from "../../constants/apiUrls";
import { generateQueryParams } from "./utils";

// export const getConnectedAccountInvitationDetails = (id) =>
//   axios.get(apiUrls.CONNECTED_ACCOUNT_INVITATIONS(id), {
//     withCredentials: false,
//   });

export const getOnboardingPlatformInfo = (id) =>
  axios.get(apiUrls.PLATFORM_INFO(id));

export const getConnectedAccountInvitationDetails = (params) => {
  const urlWithQueryParams = generateQueryParams(
    apiUrls.CONNECTED_ACCOUNT_INVITATIONS,
    params
  );
  return axios.get(urlWithQueryParams);
};

export const setConnectedAccountPassword = (id, password) =>
  axios.post(
    apiUrls.CONNECTED_ACCOUNT_INVITATIONS_SET_PASSWORD(id),
    { password },
    { headers: { "x-burq-client": "client-dashboard" } }
  );

export const setConnectedAccountInvitationsDefaultPaymentMethod = (
  id,
  paymentMethodId
) =>
  axios.post(
    apiUrls.CONNECTED_ACCOUNT_INVITATIONS_SET_DEFAULT_PAYMENT_METHOD(id),
    { payment_method_id: paymentMethodId },
    { headers: { "x-burq-client": "client-dashboard" } }
  );

export const setConnectedAccountInvitationsAccountAgreementConsent = (
  id,
  payload
) =>
  axios.post(
    apiUrls.CONNECTED_ACCOUNT_INVITATIONS_ACCOUNT_AGREEMENT_CONSENT(id),
    { ...payload },
    { headers: { "x-burq-client": "client-dashboard" } }
  );
