import React, { useEffect, useState } from "react";
import { useTheme, useQuoteWorkflow, useMerchant, userDataStore } from "../../../store";
import { Loading } from "../../../assets/svgs/Svgs";
import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";
import CommonToggle from "../../CommonToggle";
import styles from "./styles.module.css";

const RerouteWorkflow = () => {
  const { selectedQuoteItem, setSelectedQuoteItem } = useQuoteWorkflow();

  const { getMerchantData, merchantData, isUpdateRerouteConfigLoading,
    updateRerouteConfigWorker } = useMerchant();

  const { authInfo } = userDataStore();

  const { isdesktop } = useDesktopOrLaptop();

  const { Colors } = useTheme();

  const [isAutoRerouteEnabled, setIsAutoRerouteEnabled] = useState(false);
  const [isManualRerouteEnabled, setIsManualRerouteEnabled] = useState(false);

  useEffect(() => {
    let subscribe = true;
    if (subscribe) {
      const reroute_config = { ...merchantData?.settings?.reroute_config };
      setIsAutoRerouteEnabled(reroute_config.is_auto_reroute_enabled);
      setIsManualRerouteEnabled(reroute_config.is_manual_reroute_enabled);
    }
    return () => {
      subscribe = false;
      setIsAutoRerouteEnabled(false);
      setIsManualRerouteEnabled(false);
    };
  }, [merchantData]);

  return (
    <div
      className="kata"
      style={{
        position: "absolute",
        transform: selectedQuoteItem ? `translate(0px)` : `translate(440px)`,
        right: "0px",
        top: isdesktop ? "70px" : "200px",
        bottom: "0px",
        width: "440px",
        padding: "24px",
        backgroundColor: Colors.white,
        display: "flex",
        flexDirection: "column",
        justifyContent: isdesktop ? "space-between" : "start",
        zIndex: 4,
      }}
    >
      <div>
        <div
          className={styles.heading}
        >
          Reroute Settings
        </div>

        <div
          className={styles.description}
        >
          When reroute is turned on, Burq will attempt to reroute deliveries to another delivery provider if there is an issue with the original provider. The price may change, but won’t increase more than $5.
        </div>

        <div className={styles.toggle}>
          <div className={styles.toggleLabel}>
            Provider cancellations
          </div>
          <CommonToggle
            checked={isManualRerouteEnabled}
            translateX={isdesktop ? 19 : 24}
            smsHandler={() => {
              setIsManualRerouteEnabled(!isManualRerouteEnabled);
            }}
            circleStyle={{
              width: "13.75px",
              height: "13.75px",
              backgroundColor: Colors.white,
            }}
            style={{
              width: "39.88px",
              height: "20.63px",
              padding: "3px",
              backgroundColor: isManualRerouteEnabled ? Colors.bluePrimary : Colors.fontGreyLight,
            }}
          />
        </div>
        <div className={styles.toggleDescription}>
          When turned on, Burq will attempt to reroute deliveries that were canceled by the provider.
        </div>

        <div className={styles.toggle}>
          <div className={styles.toggleLabel}>
            Continuous monitoring
          </div>
          <CommonToggle
            checked={isAutoRerouteEnabled}
            translateX={isdesktop ? 19 : 24}
            smsHandler={() => {
              setIsAutoRerouteEnabled(!isAutoRerouteEnabled);
            }}
            circleStyle={{
              width: "13.75px",
              height: "13.75px",
              backgroundColor: Colors.white,
            }}
            style={{
              width: "39.88px",
              height: "20.63px",
              padding: "3px",
              backgroundColor: isAutoRerouteEnabled ? Colors.bluePrimary : Colors.fontGreyLight,
            }}
          />
        </div>
        <div className={styles.toggleDescription}>
          When turned on, Burq will continuously monitor deliveries and reroute them when deliveries get too close to their delivery window without a driver assigned. Burq will also attempt to automatically reroute if any delivery fails to initiate with providers due to technical issues.
        </div>
      </div>


      <div style={{ width: "100%", marginTop: !isdesktop && "50px" }}>
        <button
          type="button"
          onClick={() => {
            const payload = {
              is_auto_reroute_enabled: isAutoRerouteEnabled,
              is_manual_reroute_enabled: isManualRerouteEnabled,
            }            
            updateRerouteConfigWorker(authInfo.merchantAccountId, payload).then((res) => {
              getMerchantData(authInfo.merchantAccountId);
              setSelectedQuoteItem();
            });
          }}
          disabled={isUpdateRerouteConfigLoading}
          style={{
            backgroundColor: Colors.bluePrimary,
            fontFamily: "Poppins",
            fontSize: isdesktop ? "16px" : "28px",
            fontWeight: "500",
            color: "#FAFAFB",
            textAlign: "center",
            borderRadius: "8px",
            padding: "10px 30px",
            opacity: 1,
          }}
        >
          {isUpdateRerouteConfigLoading ? (
            <div className="w-full flex justify-center">
              <Loading color="#ECF0F1" className="h-6 w-6 animate-spin" />{" "}
            </div>
          ) : (
            "Save"
          )}
        </button>
      </div>
    </div>
  );
};

export default RerouteWorkflow;
