import React, { useState } from "react";

import { useAccount, useMerchant, useTheme, userDataStore } from "../../store";

// import StoresList from "./StoresList/StoresList";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import MembersList from "../StoreSettings/MembersList/MembersList";
import ButtonComp from "../ButtonComp/ButtonComp";
import CustomDetailsRightModal from "../CustomModal/CustomDetailsRightModal";
import SlideDrawer from "../shared/atoms/SlideDrawer";
import { RolesSidebar } from "./RolesSidebar";
import Button from "../shared/atoms/Button";
import { ROLES } from "./RolesSidebar/helpers";
import styles from "./styles.module.css";
import classNames from "classnames";

const AccountSettings = ({}) => {
  const { Colors } = useTheme();

  const { setEditUserData } = useAccount();
  const { userRole } = userDataStore();
  const { isAdmin } = useMerchant();

  const [isSidePanelOpened, setIsSidePanelOpened] = useState(false);

  return (
    <div
      style={{
        backgroundColor: Colors.white,
        padding: "17px 24px",
        borderRadius: "10px",
        height: "100%",
      }}
      className="flex flex-col"
    >
      <SlideDrawer
        isOpen={isSidePanelOpened}
        onClose={() => {
          setIsSidePanelOpened(false);
          setEditUserData();
        }}
      >
        <RolesSidebar setIsSidePanelOpened={setIsSidePanelOpened} />
      </SlideDrawer>

      <div className="flex flex-row items-center justify-between mt-5">
        <div></div>

        <div className="flex items-center">
          <Button
            disabled={userRole === ROLES.EMPLOEE && !isAdmin}
            externalClassName={classNames(
              {
                [styles.disabled]: userRole === ROLES.EMPLOEE && !isAdmin,
              },
              styles.textOnButton
            )}
            onClick={() => {
              setIsSidePanelOpened(true);
            }}
            text="Add member"
          />
        </div>
      </div>

      <MembersList setIsSidePanelOpened={setIsSidePanelOpened} accountMembers />
    </div>
  );
};

export default AccountSettings;
