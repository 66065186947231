import React from "react";
import { useTheme } from "../../../../../store";
import { useDesktopOrLaptop } from "../../../../../services/useDesktopOrLaptop";

const TableHeader = () => {
  const { Colors } = useTheme();

  return (
    <div
      style={{
        borderBottomWidth: "1.5px",
        borderColor: Colors.borderPrimary,
        padding: "20px 20px",
      }}
      className="grid grid-cols-12 gap-1 w-full"
    >
      <div
        style={{
          fontSize: "16px",
          fontWeight: "600",
          color: Colors.fontDark,
        }}
        className="col-start-1 col-span-2 "
      >
        Item
      </div>
      <div
        style={{
          fontSize: "16px",
          fontWeight: "600",
          color: Colors.fontDark,
        }}
        className="col-start-5 col-span-3"
      >
        Date
      </div>

      <div
        style={{
          fontSize: "16px",
          fontWeight: "600",
          color: Colors.fontDark,
        }}
        className="col-start-9 col-span-2 "
      >
        Amount
      </div>
    </div>
  );
};

export default TableHeader;
