import axios from "axios";
import apiUrls from "../../constants/apiUrls";
import { generateQueryParams } from "./utils";

export const getOrderfulfillment = (id) =>
  axios.get(apiUrls.ORDER_FULFILLMENT(id), {
    withCredentials: false,
  });

export const getOrders = (merchantAccountId, params) => {
  const urlWithQueryParams = generateQueryParams(
    apiUrls.GET_ORDERS(merchantAccountId),
    params
  );
  return axios.get(urlWithQueryParams, {
    headers: { "x-burq-client": "client-dashboard" },
  });
};

export const getOrder = (id) =>
  axios.get(apiUrls.GET_ORDER(id));

export const createOrderNote = (orderId, query, payload) => {
  const urlWithQueryParams = generateQueryParams(
    apiUrls.CREATE_ORDER_NOTE(orderId),
    query
  );
  return axios.post(urlWithQueryParams, payload, {
    headers: { "x-burq-client": "client-dashboard" },
  })
}

export const createOrder = (accountId, params, payload) => {
  const urlWithQueryParams = generateQueryParams(
    apiUrls.CREATE_ORDER(accountId),
    params
  );
  return axios.post(urlWithQueryParams, payload, {
    headers: { "x-burq-client": "client-dashboard" },
  })
}

export const updateOrder = (accountId, orderId, query, payload) => {
  const urlWithQueryParams = generateQueryParams(
    apiUrls.UPDATE_ORDER(accountId, orderId),
    query
  );
  return axios.put(urlWithQueryParams, payload, {
    headers: { "x-burq-client": "client-dashboard" },
  })
}

export const getOrderQuotes = (accountId, orderId, query, payload) => {
  const urlWithQueryParams = generateQueryParams(
    apiUrls.GET_ORDER_QUOTES(accountId, orderId),
    query
  );
  return axios.post(urlWithQueryParams, payload, {
    headers: { "x-burq-client": "client-dashboard" },
  })
}

export const acceptQuote = (accountId, quoteId, query, payload) => {
  const urlWithQueryParams = generateQueryParams(
    apiUrls.ACCEPT_QUOTE(accountId, quoteId),
    query
  );
  return axios.post(urlWithQueryParams, payload, {
    headers: { "x-burq-client": "client-dashboard" },
  })
}

export const deleteOrder = (accountId, orderId, query) => {
  const urlWithQueryParams = generateQueryParams(
    apiUrls.DELETE_ORDER(accountId, orderId),
    query
  );
  return axios.delete(urlWithQueryParams, {}, {
    headers: { "x-burq-client": "client-dashboard" },
  })
}

export const cancelOrder = (accountId, orderId, query) => {
  const urlWithQueryParams = generateQueryParams(
    apiUrls.CANCEL_ORDER(accountId, orderId),
    query
  );
  return axios.post(urlWithQueryParams, {}, {
    headers: { "x-burq-client": "client-dashboard" },
  })
}
