import React, { useEffect, useState } from "react";
import { useDesktopOrLaptop } from "../../../../services/useDesktopOrLaptop";
import { useMyStore, useTheme, useQuoteWorkflow, useMerchant, userDataStore, useDeliveryWorkflow } from "../../../../store";

import DropdownContainer from "./DropdownContainer";
import { Loading } from "../../../../assets/svgs/Svgs";
import { QUOTE_ITEMS } from "../../QuoteWorkflow/utils";

const OrderWorkflowRightSidebar = ({ setIsLeftOpened, isInsideStore }) => {
  const [providers, setProviders] = useState([]);
  const [isUpdateDataLoading, setIsUpdateDataLoading] = useState(false);
  const { getMerchantProvidersHandler } = useDeliveryWorkflow();

  const [providersPreferencePayload, setProvidersPreferencePayload] = useState([]);

  const { selectedQuoteItem, setSelectedQuoteItem } = useQuoteWorkflow();

  const { merchantData, updateMerchantData } = useMerchant();

  const { userId } = useMyStore();
  const { authInfo } = userDataStore();

  const { isdesktop, smfontsize } = useDesktopOrLaptop();

  const { Colors } = useTheme();

  const [itemForFrontend, setItemForFrontend] = useState();

  useEffect(() => {
    const _selectedOrdWrkf = QUOTE_ITEMS.filter((item) => item?.key === selectedQuoteItem);

    setItemForFrontend(_selectedOrdWrkf[0]);
  }, [selectedQuoteItem]);

  const getProviders = async (id) => {
    const res = await getMerchantProvidersHandler(id);
    if (res) {
      setProviders(res.data);
    }
  };

  useEffect(() => {
    if (authInfo?.merchantAccountId) {
      getProviders(authInfo?.merchantAccountId);
    }
    if (merchantData) {
      const quote_provider_preference = structuredClone(merchantData?.settings?.quote_provider_preference);

      if (quote_provider_preference.length < 1) {
        setProvidersPreferencePayload([
          {
            preference_type: "cheapest",
            provider_id: undefined,
          },
        ]);
      } else if (quote_provider_preference.length > 0) {
        setProvidersPreferencePayload(merchantData.settings.quote_provider_preference);
      }
    }
    return () => {};
  }, [merchantData]);

  return (
    <div
      className="kata"
      style={{
        position: "absolute",
        transform: selectedQuoteItem ? `translate(0px)` : `translate(440px)`,
        right: "0px",
        top: isInsideStore ? (isdesktop ? "211px" : "240px") : isdesktop ? "70px" : "200px",
        bottom: "0px",
        width: "440px",
        padding: "24px",
        backgroundColor: Colors.white,
        display: "flex",
        flexDirection: "column",
        justifyContent: isdesktop ? "space-between" : "start",
        zIndex: 4,
      }}
    >
      <div className="pb-12">
        <div
          style={{
            fontFamily: "Poppins",
            fontWeight: "500",
            color: Colors.fontDark,
          }}
        >
          {itemForFrontend?.key}
        </div>

        <div
          style={{
            color: Colors.fontGreyLight,
            marginTop: "10px",
            fontSize: smfontsize,
          }}
        >
          {itemForFrontend?.description}
        </div>
        {itemForFrontend?.id === 6 &&
          providersPreferencePayload.map((preference, idx) => {
            return (
              <DropdownContainer
                key={idx}
                idx={idx}
                payloadData={providersPreferencePayload}
                preference={preference}
                providers={providers}
                setPayloadData={setProvidersPreferencePayload}
                quote_provider_preference={structuredClone(merchantData?.settings?.quote_provider_preference)}
                isPreferenceAdded={merchantData?.settings?.quote_provider_preference.length > 0}
              />
            );
          })}
      </div>
      <div style={{ width: "100%", marginTop: !isdesktop && "50px" }}>
        <button
          onClick={async () => {
            setIsUpdateDataLoading(true);
            const payload = structuredClone(merchantData?.settings);
            if (itemForFrontend?.key === "Provider Preference") {
              payload.quote_provider_preference = providersPreferencePayload;
            }
            await updateMerchantData(userId || authInfo.merchantAccountId, merchantData, payload).then((res) => {
              setSelectedQuoteItem();
            });
            setIsUpdateDataLoading(false);
          }}
          type="button"
          style={{
            backgroundColor: Colors.bluePrimary,
            fontFamily: "Poppins",
            fontSize: isdesktop ? "16px" : "28px",
            fontWeight: "500",
            color: "#FAFAFB",
            textAlign: "center",
            borderRadius: "8px",
            padding: "10px 30px",
            opacity: 1,
          }}
        >
          {isUpdateDataLoading ? (
            <div className="w-full flex justify-center">
              <Loading color="#ECF0F1" className="h-6 w-6 animate-spin" />{" "}
            </div>
          ) : (
            "Save"
          )}
        </button>
      </div>
    </div>
  );
};

export default OrderWorkflowRightSidebar;
