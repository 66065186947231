import * as Sentry from "@sentry/react";
import { createStore } from "aniuta";
import { useState } from "react";

import axios from "axios";
import { baseUrl, baseUrlOfApi } from "../../runTimeConst";

const useWorkflow = createStore({
  name: "useWorkflow",
  Store: () => {
    const [workflowStatuses, setWorkflowStatuses] = useState();
    const [statusesForBackend, setStatusesForBackend] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState();

    const [activeWorkflow, setActiveWorkflow] = useState("sms");

    // new
    const postCreateSmsSetting = async (merchant_id, configurable_setting_id, message_template, recipient_type, userId) => {
      try {
        await axios.post(
          `${baseUrl}merchant_accounts/${userId ? userId : merchant_id}/sms_settings`,

          {
            sms_setting: {
              configurable_setting_id: configurable_setting_id, // this is the id of SmsSettings model
              message_template: message_template,
              recipient_type: recipient_type,
            },
          }
        );
      } catch (error) {
        Sentry.captureException(error);
        console.warn(error);
      }
    };
    const updateSmsSetting = async (id, message_template, recipient_type) => {
      try {
        const response = await axios.put(
          `${baseUrl}sms_settings/${id}`,

          {
            sms_setting: {
              message_template: message_template,
              recipient_type: recipient_type,
            },
          }
        );
        return response.data;
      } catch (error) {
        Sentry.captureException(error);
        console.warn(error);
      }
    };
    // new

    const [configuredSettings, setConfiguredSettings] = useState([]);
    const getSmsSettings = async (userId, merchant_id) => {
      try {
        const response = await axios.get(`${baseUrl}merchant_accounts/${userId ? userId : merchant_id}/sms_settings`);
        setConfiguredSettings(response.data);
        return response;
      } catch (error) {
        Sentry.captureException(error);
        console.warn(error);
      }
    };
    const deleteSmsSetting = async (id) => {
      try {
        const response = await axios.delete(`${baseUrl}sms_settings/${id}`);
        return response;
      } catch (error) {
        Sentry.captureException(error);
        console.warn(error);
      }
    };

    const getSmsworkflowHandler = async (userId, merchanId) => {
      try {
        const response = await axios.get(
          // `${baseUrl}configurable_sms_settings`
          `${baseUrl}merchant_accounts/${userId ? userId : merchanId}/configurable_sms_settings`
          // `${baseUrl}auth/smsWorkflow${userId ?? ""}`
        );
        setWorkflowStatuses(response.data);
      } catch (error) {
        Sentry.captureException(error);
        console.warn(error);
      }
    };

    return {
      workflowStatuses,
      setWorkflowStatuses,
      selectedStatus,
      setSelectedStatus,
      statusesForBackend,
      setStatusesForBackend,
      getSmsworkflowHandler,
      activeWorkflow,
      setActiveWorkflow,
      getSmsSettings,
      postCreateSmsSetting,
      configuredSettings,
      deleteSmsSetting,
      updateSmsSetting,
    };
  },
});

export default useWorkflow;
