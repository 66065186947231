import React from "react";
import styles from "./styles.module.css";
import classNames from "classnames";

const Badge = ({
  label = "",
  type = "neutral", //neutral | success | warning | error
  externalClassName = "",
  leftIcon,
  hideIcon = true,
  smallerText = false,
  extraIconClasses = "",
  uppercase,
}) => {
  return (
    <div
      className={classNames(
        styles.bdg,
        {
          [styles.fontSize]: smallerText,
          [styles.neutral]: type === "neutral",
          [styles.success]: type === "success",
          [styles.warning]: type === "warning",
          [styles.error]: type === "error",
          [styles.primary]: type === "primary",
          [styles.pickup]: type === "pickup",
          [styles.dropoff]: type === "dropoff",
          [styles.info]: type === "info",
          [styles.primaryWithUnderLine]: type === "primaryWithUnderLine",
          [styles.caution]: type === "caution",
          [styles.progress]: type === "progress",
          [styles.uppercase]: uppercase,
          [styles.reroutedBadge]: type === "reroute",
          [styles.attemptRerouteBadge]: type === "attempting_reroute",
        },
        externalClassName
      )}
    >
      {!hideIcon && (
        <div className={classNames(styles.iconContainer, extraIconClasses)}>
          {leftIcon || <div className={styles.iconDefault} />}
        </div>
      )}
      <div>{label}</div>
    </div>
  );
};

export default Badge;
