import React from "react";
import styles from "./styles.module.css";
import classNames from "classnames";

const InputErrorComp = ({ error, extraClasses }) => {
  return error ? (
    <span className={classNames(styles.errorMessage, extraClasses)}>
      {error}
    </span>
  ) : (
    ""
  );
};

export default InputErrorComp;
