import React from "react";
import classNames from "classnames";
import styles from "./styles.module.css";

const SlideDrawer = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;
  return (
    <>
      <div
        className={classNames(styles.sideDrawer, "kata", {
          [styles.sideDrawerOpen]: isOpen,
        })}
      >
        {children}
      </div>
      {isOpen && <div onClick={onClose} className={styles.backdrop} />}
    </>
  );
};

export default SlideDrawer;
