import React, { useEffect } from "react";
import { userDataStore, usePaymentStore, useMerchant } from "../../store";
import StripePaymentElement from "../shared/StripePaymentElement";
import { stripeReturnUrlFromSettingTab } from "../../runTimeConst";
import Button from "../shared/atoms/Button";
import styles from "./styles/styles.module.css";
import { ROLES } from "../AccountSettings/RolesSidebar/helpers";
import classNames from "classnames";
import Pagination from "../shared/Pagination";
import { BiSolidPlusCircle } from "react-icons/bi";
import PaymentMethodListLoading from "./PaymentMethodListLoading";
import PaymentMethodListEmptyState from "./PaymentMethodListEmptyState";
import PaymentMethodListItem from "./PaymentMethodListItem";
import PaymentMethodListHeader from "./PaymentMethodListHeader";
import { BsEyeFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

function PaymentSettings() {
  const navigate = useNavigate();

  const { authInfo, userRole } = userDataStore();
  const { isAdmin } = useMerchant();

  const { customerCards, setSelectedCardId, setAddCardModal, updater, loadCardList, getSetupIntent, cardsLoading } =
    usePaymentStore();

  useEffect(() => {
    if (customerCards) {
      for (let i = 0; i < customerCards.length; i++) {
        customerCards[i].isDefault && setSelectedCardId(customerCards[i].id);
      }
    }
  }, [customerCards]);

  useEffect(() => {
    if (authInfo?.merchantAccountId) getSetupIntent(authInfo.merchantAccountId);
  }, [authInfo]);

  const getPaymentMethodHandler = async (param = {}) => {
    const params = {
      starting_after: param.starting_after || null,
      ending_before: param.ending_before || null,
    };
    const id = authInfo.merchantAccountId;
    await loadCardList(id, params);
  };

  useEffect(() => {
    if (!!authInfo) {
      getPaymentMethodHandler(authInfo.merchantAccountId);
    }
  }, [updater, authInfo]);

  const renderTableBody = () => {
    if (cardsLoading) {
      return <PaymentMethodListLoading />;
    } else if (!customerCards?.length) {
      return <PaymentMethodListEmptyState />;
    } else return customerCards?.map((item) => <PaymentMethodListItem data={item} />);
  };

  return (
    <div className={classNames("overflow-x-auto", styles.container)}>
      <div className={styles.header}>
        <h1 className={styles.heading}>Payment Methods</h1>
        <div className={styles.paymentButtons}>
          {
            <Button
              externalClassName={classNames(styles.button, "mr-3", styles.uploadButton)}
              variant="secondary"
              onClick={() => {
                navigate(`/v1/settings/payments/history`);
              }}
            >
              <div className={styles.buttonContent}>
                <BsEyeFill />
                <div className={styles.uploadButtonText}>View Payment History</div>
              </div>
            </Button>
          }
          <Button
            disabled={userRole === ROLES.EMPLOEE && !isAdmin}
            externalClassName={styles.button}
            onClick={() => {
              setAddCardModal(true);
            }}
          >
            <div className={styles.buttonContent}>
              <BiSolidPlusCircle size={22} />
              <div className={styles.addPaymentText}>Add Payment Method</div>
            </div>
          </Button>
        </div>
      </div>
      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <thead className={styles.tableHeader}>
            <PaymentMethodListHeader />
          </thead>

          <tbody className={classNames(styles.tableBody, "kata")}>{renderTableBody()}</tbody>
          <tfoot className={styles.tableFooter}>
            <tr className="flex items-center justify-end pr-28 mt-4">
              <td colSpan="4">
                <Pagination data={customerCards} onGetPaginationData={getPaymentMethodHandler} dependencies={[updater]} />
              </td>
            </tr>
          </tfoot>
        </table>
        <StripePaymentElement returnUrl={stripeReturnUrlFromSettingTab} />
      </div>
    </div>
  );
}

export default PaymentSettings;
