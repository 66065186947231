import { loadStripe } from "@stripe/stripe-js";

export const baseUrl = `${process.env.REACT_APP_BASE_API_URL}/api/`;
export const baseUrlWithoutApi = `${process.env.REACT_APP_BASE_API_URL}/`;
// export const baseUrl = "/api/";
// export const baseUrlWithoutApi = "/";
export const baseUrlOfApi = `${process.env.REACT_APP_BASE_API_URL}/v1/`;

export const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export const stripeReturnUrl = (id) => `${process.env.REACT_APP_STRIPE_RETURN_URL}/onboarding/${id}`;
export const stripeReturnUrlFromSettingTab = `${process.env.REACT_APP_STRIPE_RETURN_URL}/v1/settings/payments`;
export const stripeReturnUrlForStore = (id) => `${process.env.REACT_APP_STRIPE_RETURN_URL}/v1/stores/${id}?active=paymentMethods`;

export const connectedAccountAgreementConsentTermsUrl = "burq_dickeys_franchisee_agreement_02202023.pdf";

export const INDUSTRIES = [
  { front: "Food", back: "food" },
  { front: "Catering", back: "catering" },
  { front: "Grocery", back: "grocery" },
  { front: "Flowers", back: "flowers" },
  { front: "Laundry / Dry Cleaning", back: "laundry_dry_cleaning" },
  { front: "Pharmacy", back: "pharmacy" },
  { front: "Ecommerce / Parcel", back: "ecommerce_parcel" },
  { front: "Auto Parts", back: "auto_parts" },
  { front: "Vape / Tobacco", back: "vape_tobacco" },
  { front: "Alcohol", back: "alcohol" },
  { front: "Cannabis / CBD", back: "cannabis_cbd" },
];

export const HOURS = [
  "12:00 AM",
  "12:15 AM",
  "12:30 AM",
  "12:45 AM",
  "01:00 AM",
  "01:15 AM",
  "01:30 AM",
  "01:45 AM",
  "02:00 AM",
  "02:15 AM",
  "02:30 AM",
  "02:45 AM",
  "03:00 AM",
  "03:15 AM",
  "03:30 AM",
  "03:45 AM",
  "04:00 AM",
  "04:15 AM",
  "04:30 AM",
  "04:45 AM",
  "05:00 AM",
  "05:15 AM",
  "05:30 AM",
  "05:45 AM",
  "06:00 AM",
  "06:15 AM",
  "06:30 AM",
  "06:45 AM",
  "07:00 AM",
  "07:15 AM",
  "07:30 AM",
  "07:45 AM",
  "08:00 AM",
  "08:15 AM",
  "08:30 AM",
  "08:45 AM",
  "09:00 AM",
  "09:15 AM",
  "09:30 AM",
  "09:45 AM",
  "10:00 AM",
  "10:15 AM",
  "10:30 AM",
  "10:45 AM",
  "11:00 AM",
  "11:15 AM",
  "11:30 AM",
  "11:45 AM",
  "12:00 PM",
  "12:15 PM",
  "12:30 PM",
  "12:45 PM",
  "01:00 PM",
  "01:15 PM",
  "01:30 PM",
  "01:45 PM",
  "02:00 PM",
  "02:15 PM",
  "02:30 PM",
  "02:45 PM",
  "03:00 PM",
  "03:15 PM",
  "03:30 PM",
  "03:45 PM",
  "04:00 PM",
  "04:15 PM",
  "04:30 PM",
  "04:45 PM",
  "05:00 PM",
  "05:15 PM",
  "05:30 PM",
  "05:45 PM",
  "06:00 PM",
  "06:15 PM",
  "06:30 PM",
  "06:45 PM",
  "07:00 PM",
  "07:15 PM",
  "07:30 PM",
  "07:45 PM",
  "08:00 PM",
  "08:15 PM",
  "08:30 PM",
  "08:45 PM",
  "09:00 PM",
  "09:15 PM",
  "09:30 PM",
  "09:45 PM",
  "10:00 PM",
  "10:15 PM",
  "10:30 PM",
  "10:45 PM",
  "11:00 PM",
  "11:15 PM",
  "11:30 PM",
  "11:45 PM",
];

export const TIME_HOURS = [
  "12:00 AM",
  "01:00 AM",
  "02:00 AM",
  "03:00 AM",
  "04:00 AM",
  "05:00 AM",
  "06:00 AM",
  "07:00 AM",
  "08:00 AM",
  "09:00 AM",
  "10:00 AM",
  "11:00 AM",
  "12:00 PM",
  "01:00 PM",
  "02:00 PM",
  "03:00 PM",
  "04:00 PM",
  "05:00 PM",
  "06:00 PM",
  "07:00 PM",
  "08:00 PM",
  "09:00 PM",
  "10:00 PM",
  "11:00 PM",
];

export const DARK_MAP = [
  {
    featureType: "administrative",
    elementType: "all",
    stylers: [
      {
        saturation: 36,
      },
      {
        color: "#000000",
      },
      {
        lightness: 40,
      },
    ],
  },
  {
    featureType: "all",
    elementType: "labels.text.stroke",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#000000",
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    featureType: "all",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: "administrative.province",
    elementType: "all",
    stylers: [
      {
        color: "#000000",
      },
      // {
      //   lightness: 17,
      // },
      // {
      //   weight: 1.2,
      // },
    ],
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 20,
      },
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 21,
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 17,
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 29,
      },
      {
        weight: 0.2,
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 18,
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 19,
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 17,
      },
    ],
  },
];

export const LIGHT_MAP = [
  {
    featureType: "administrative",
    elementType: "all",
    stylers: [
      {
        // saturation: "-100",
        visibility: "on",
      },
    ],
  },
  {
    featureType: "administrative.province",
    elementType: "all",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 65,
      },
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: "50",
      },
      {
        visibility: "off",
      },
    ],
  },
];

export const MAPSTYLE = [
  {
    featureType: "poi.business",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
];

export const statusesObject = {
  returned: "Returned",
  delivered: "Delivered",
  delivery_created: "Delivery Created",
  driver_assigned: "Driver Assigned",
  driver_not_assigned: "Assigning Driver",
  enroute_pickup: "Enroute to Pick-up",
  arrived_at_pickup: "Arrived at Pick-up",
  pickup_complete: "Pick-up Complete",
  enroute_dropoff: "Enroute to Drop-off",
  arrived_at_dropoff: "Arrived at Drop-off",
  dropoff_complete: "Drop off Completed",
  contacting_provider: "Contacting Provider",
  scheduled: "Scheduled",
  enroute_to_return: "Enroute to Return",
  provider_canceled: "Provider Canceled",
  customer_canceled: "Merchant Canceled",
  burq_canceled: "Burq Canceled",
  expired: "Expired",
  failed: "Failed",
  disputed: "Disputed",
  request: "Not Initiated",
};
export const statuses = [
  // { back: "pending", front: "Pending" },
  // { back: "pickup", front: "Pickup" },
  // { back: "dropoff", front: "Dropoff" },
  // { back: "canceled", front: "Canceled" },
  { value: "delivery_created", name: "Delivery Created" },
  { value: "scheduled", name: "Scheduled" },

  { value: "driver_not_assigned", name: "Assigning Driver" },
  { value: "driver_assigned", name: "Driver Assigned" },

  { value: "enroute_pickup", name: "Enroute to Pick-up" },
  { value: "arrived_at_pickup", name: "Arrived at Pick-up" },
  { value: "pickup_complete", name: "Pick-up Complete" },

  { value: "enroute_dropoff", name: "Enroute to Drop-off" },
  { value: "arrived_at_dropoff", name: "Arrived at Drop-off" },
  { value: "dropoff_complete", name: "Drop off Completed" },

  { value: "delivered", name: "Delivered" },

  { value: "contacting_provider", name: "Contacting Provider" },
  { value: "enroute_to_return", name: "Enroute to Return" },
  { value: "returned", name: "Returned" },
  { value: "provider_canceled", name: "Provider Canceled" },
  { value: "customer_canceled", name: "Merchant Canceled" },
  { value: "burq_canceled", name: "Burq Canceled" },
  { value: "expired", name: "Expired" },
  { value: "failed", name: "Failed" },
  { value: "disputed", name: "Disputed" },
];
