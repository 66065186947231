import { useState } from "react";
import * as Sentry from "@sentry/react";
import { createStore } from "aniuta";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { baseUrl } from "../runTimeConst";
import {
  getConnectedAccountInvitationDetails,
  getOnboardingPlatformInfo,
  setConnectedAccountInvitationsAccountAgreementConsent,
  setConnectedAccountInvitationsDefaultPaymentMethod,
  setConnectedAccountPassword,
} from "../utils/api/onboarding";
import { ZendeskAPI } from "react-zendesk";
import _ from "lodash";
import { removeCookie, setCookie } from "../utils";

const useOnboardingStore = createStore({
  name: "OnboardingStore",
  Store: () => {
    const [isLoading, setIsLoading] = useState(false);
    const [step, setStep] = useState(0);
    const [invitationDetails, setInvitationDetails] = useState(null);
    const [isVerificationRequired, setIsVerificationRequired] = useState(false);
    const [isAgreementChecked, setIsAgreementChecked] = useState(false);

    const redirectToLogin = () => {
      Object.keys(Cookies.get()).forEach((cookieName) => {
        removeCookie(cookieName);
      });
      setTimeout(() => {
        ZendeskAPI("messenger", "logoutUser");
        window.location.href = `${baseUrl}auth/logout`;
      }, 3000);
    };

    // const savePaymentMethod = async (invitationId, paymentMethodId) => {
    //   try {
    //     await setConnectedAccountInvitationsDefaultPaymentMethod(
    //       invitationId,
    //       paymentMethodId
    //     );
    //     getInvitationDetails();
    //   } catch (error) {
    //     toast.error(
    //       error?.message
    //         ? error?.message
    //         : "Something went wrong! Please try again",
    //       {
    //         position: "top-center",
    //         autoClose: 7000,
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //         pauseOnHover: true,
    //         draggable: true,
    //         closeButton: false,
    //       }
    //     );
    //   }
    // };

    const [isGetPlatformInfoLoading, setIsGetPlatformInfoLoading] = useState(false);
    const [platformInfo, setPlatformInfo] = useState();

    const getPlatformInfo = async (id) => {
      setIsGetPlatformInfoLoading(true);
      try {
        const { data } = await getOnboardingPlatformInfo(id);
        setCookie("platformInfo", data);

        setPlatformInfo(data);
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsGetPlatformInfoLoading(false);
      }
    };

    const setAccountAgrementConcent = async (id, payload) => {
      try {
        setIsLoading(true);
        const { data } = await setConnectedAccountInvitationsAccountAgreementConsent(id, payload);
        setIsAgreementChecked(data?.data?.account_agreement_consent_given);
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsLoading(false);
      }
    };

    const retrieveSetupIntent = async (stripe, clientSecret, successMessage) => {
      if (!stripe || !clientSecret) return;
      setIsLoading(true);
      setIsVerificationRequired(false);
      try {
        const result = await stripe.retrieveSetupIntent(clientSecret);
        if (result.error) {
          toast.error(result.error.message ? result.error.message : "Something went wrong! Please try again");
        } else {
          // const paymentMethodId = result.setupIntent.payment_method;
          // const intentId = result.setupIntent.id;
          switch (result?.setupIntent?.status) {
            case "succeeded": {
              toast.success(successMessage ? successMessage : "Success! Your payment method has been saved.");
              setStep(2);
              break;
            }
            case "processing": {
              toast.warning("Processing payment details. We'll update you when processing is complete.");
              setStep(2);
              break;
            }
            case "requires_action": {
              toast.warning("Verification Required, You will receive an email from stripe for the account verification.");
              setIsVerificationRequired(true);
              setStep(2);
              break;
            }
            case "requires_payment_method": {
              toast.error("Failed to process payment details. Please try another payment method.");
              break;
            }
            default: {
              toast.error("Something went wrong! Please try again");
              break;
            }
          }
        }
      } catch (error) {
        Sentry.captureException(error);
        toast.error("Something went wrong! Please try again");
      } finally {
        setIsLoading(false);
      }
    };

    const getInvitationDetails = async (params, redirect_url) => {
      try {
        setIsLoading(true);
        const response = await getConnectedAccountInvitationDetails(params);
        const { onboarding_completed, payment_method_added } = response.data.data;

        // if (onboarding_completed && redirect_url) {
        //   window.location.href = redirect_url;
        // } else if (onboarding_completed) {
        //   setStep(3);
        // }
        setInvitationDetails(response.data.data);
        setIsAgreementChecked(response.data.data.account_agreement_consent_given);
        if (onboarding_completed && redirect_url) {
          window.location.href = redirect_url;
        } else if (onboarding_completed && !redirect_url) {
          setStep(3);
        } else if (payment_method_added) {
          setStep(2);
        } else {
          setStep(1);
        }
        return response.data.data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else if (error?.message) {
          Sentry.captureException(error);
          toast.error(error?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsLoading(false);
      }
    };

    const savePassword = async (id, password, redirect_url) => {
      try {
        setIsLoading(true);
        await setConnectedAccountPassword(id, password);
        toast.success("Your password is created successfully!");
        if (redirect_url) {
          window.location.href = redirect_url;
        } else {
          setStep(3);
        }
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else if (error?.message) {
          Sentry.captureException(error);
          toast.error(error?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsLoading(false);
      }
    };

    return {
      //steps
      isLoading,
      isVerificationRequired,
      step,
      setStep,
      invitationDetails,
      setInvitationDetails,

      //functions
      getInvitationDetails,
      savePassword,
      retrieveSetupIntent,
      redirectToLogin,
      setAccountAgrementConcent,
      isAgreementChecked,
      setIsAgreementChecked,

      // * get platform info
      isGetPlatformInfoLoading,
      setIsGetPlatformInfoLoading,
      platformInfo,
      setPlatformInfo,
      getPlatformInfo,
      setIsLoading,
    };
  },
});

export default useOnboardingStore;
