import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import CreateRule from "./CreateRule";
import RulesList from "./RulesList";
import styles from "../styles/PricingRules/styles.module.css";
import classNames from "classnames";
import PartnerUsersList from "./PartnerUsersList";
import { usePartnerStore } from "../../../../store";
import { toast } from "react-toastify";

const initialValues = {
  rules: [],
};

const PricingRules = ({ selectedUser, setSelectedUser }) => {
  const [selectedRuleIndex, setSelectedRuleIndex] = useState(null);
  const [activeStep, setActiveStep] = useState("select_partner");
  const [rulesPayload, setRulesPayload] = useState(initialValues);
  const {
    getPartnerRulesWorker,
    createPartnerRulesWorker,
    updatePartnerRulesWorker,
    deletePartnerRulesWorker,
  } = usePartnerStore();
  const [selectedPartner, setSelectedPartner] = useState(null);
  const [selectedRule, setSelectedRule] = useState(null);

  const getRules = async (selectedUser, selectedPartner) => {
    const payload = {
      merchant_account_id: selectedUser.merchantAccountId,
      partner_user_id: selectedPartner.id,
    };
    const callback = (err, response) => {
      if (err) {
        setActiveStep("select_partner");
        setSelectedPartner(null);
      } else {
        const data = response?.data || [];
        if (data?.length) {
          setRulesPayload({ rules: data[0].rules });
          setSelectedRule(data[0]);
        } else {
          setRulesPayload({ rules: [] });
          setSelectedRule(null);
        }
      }
    };
    await getPartnerRulesWorker(payload, callback);
  };

  const deleteRulesHandler = async () => {
    const callback = (err) => {
      if (err) {
        setActiveStep("select_partner");
        setSelectedPartner(null);
      } else {
        getRules(selectedUser, selectedPartner);
      }
    };

    if (selectedRule) {
      await deletePartnerRulesWorker(
        selectedRule.partner_pricing_rule_id,
        callback
      );
    }
  };

  useEffect(() => {
    if (selectedUser && selectedPartner) {
      getRules(selectedUser, selectedPartner);
    }
  }, [selectedUser, selectedPartner]);

  const onSubmit = async (values) => {
    const payload = {
      rules: values.rules,
      merchant_account_id: selectedUser.merchantAccountId,
      partner_user_id: selectedPartner.id,
    };

    const callback = (err) => {
      if (!err) {
        setSelectedUser(null);
      }
    };

    if (selectedRule) {
      updatePartnerRulesWorker(
        selectedRule.partner_pricing_rule_id,
        payload,
        callback
      );
    } else {
      createPartnerRulesWorker(payload, callback);
    }
  };

  const renderStep = (values, setFieldValue, handleSubmit) => {
    switch (activeStep) {
      case "select_partner":
        return (
          <PartnerUsersList
            selectedPartner={selectedPartner}
            setSelectedPartner={setSelectedPartner}
            setActiveStep={setActiveStep}
          />
        );
      case "create_rule":
        return (
          <CreateRule
            rules={values.rules}
            setFieldValue={setFieldValue}
            selectedRuleIndex={selectedRuleIndex}
            setSelectedRuleIndex={setSelectedRuleIndex}
            setActiveStep={setActiveStep}
          />
        );
      case "list_rules":
        return (
          <RulesList
            rules={values.rules}
            setFieldValue={setFieldValue}
            setActiveStep={setActiveStep}
            setSelectedRuleIndex={setSelectedRuleIndex}
            handleSubmit={handleSubmit}
            deleteRulesHandler={deleteRulesHandler}
            selectedRule={selectedRule}
          />
        );
      default:
        return (
          <PartnerUsersList
            selectedPartner={values.partnerUser}
            setFieldValue={setFieldValue}
            setActiveStep={setActiveStep}
          />
        );
    }
  };

  return (
    <div className={styles.mainContainer}>
      <Formik
        initialValues={rulesPayload}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ handleSubmit, values, setFieldValue }) => {
          return (
            <div className={classNames(styles.container, "kata")}>
              {renderStep(values, setFieldValue, handleSubmit)}
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default PricingRules;
