import React from "react";
import StatusBadgeReusable from "../shared/common/StatusBadgeReusable";
import styles from "./styles/listItem.module.css";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import classNames from "classnames";
import { incidentBadges } from "../DeliveryDetailsOnSidePopup/IncidentInfoAndReason/utils";

const ListItem = ({ data }) => {
  const navigate = useNavigate();

  const dateHandler = (dateString) => {
    const date = moment(dateString).format("YYYY");
    const currentYear = moment().format("YYYY");

    const formattedDate = moment(dateString).format("MMM D, YYYY");

    return date !== currentYear ? formattedDate : moment(dateString).format("MMMM D");
  };

  return (
    <>
      <tr
        key={data.id}
        className={styles.tableRow}
        onClick={() => {
          navigate(`?deliveryId=${data.delivery_id}`);
        }}
      >
        <td className={classNames(styles.tableRowText, "xl:w-72 truncate")}>{data.id}</td>
        <td className={styles.tableRowText}>{dateHandler(data.created_at).toUpperCase()}</td>

        <td className={styles.tableRowText}>
          <div className={styles.badgeWrapper}>
            <StatusBadgeReusable
              status={data?.status ?? "unassigned"}
              badgesObject={incidentBadges}
              showDefault={true}
              defaultType="neutral"
              externalClassName={styles.badge}
            />
          </div>
        </td>
        <td className={classNames(styles.tableRowText, "xl:w-72 truncate")}>{data.delivery_id} </td>
        <td className={styles.tableRowText}>{"$" + (data.delivery_fee_and_tip_refunded_amount / 100).toFixed(2)}</td>
        <td className={styles.tableRowText}>{"$" + (data.order_value_refunded_amount / 100).toFixed(2)}</td>
      </tr>
    </>
  );
};

export default ListItem;
