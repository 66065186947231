import moment from "moment";
import React, { useState } from "react";
import { useTheme } from "../../../../../../store";
import { useDesktopOrLaptop } from "../../../../../../services/useDesktopOrLaptop";
import { numberWithCommas } from "../../../../../../utils/helpers";
import styles from "./styles.module.css";
import classNames from "classnames";
import { BiDetail } from "react-icons/bi";

const TransactionsItem = ({ item, activeId, setActiveId }) => {
  const { Colors } = useTheme();
  const { smfontsize, isdesktop } = useDesktopOrLaptop();

  return (
    <div className={classNames("w-full", styles.itemCont)}>
      <div
        className={classNames(
          "grid grid-cols-12 gap-1 w-full pb-2",
          styles.itemGridCont
        )}
      >
        <div
          style={{
            color: Colors.fontDark,
            width: isdesktop ? "250px" : "300px",
            fontSize: smfontsize,
            fontWeight: "400",
          }}
          className="col-start-1 col-span-2"
        >
          {item?.title}
        </div>

        <div
          style={{
            color: Colors.fontDark,
            fontSize: smfontsize,
            fontWeight: "400",
          }}
          className="col-start-5 col-span-3 "
        >
          {moment(item?.settled_at).format("MMM D, YYYY")}
        </div>

        <div
          style={{
            fontSize: smfontsize,
            fontWeight: "400",
            color: Colors.fontDark,
          }}
          className="col-start-9 col-span-2 "
        >
          $
          {item?.amount
            ? numberWithCommas((item?.amount / 100)?.toFixed(2))
            : "0"}
        </div>
        <div
          style={{
            fontSize: smfontsize,
            fontWeight: "400",
            color: Colors.fontDark,
          }}
          className="col-start-12 col-span-1 cursor-pointer"
          onClick={() => {
            if (item.id === activeId) {
              setActiveId();
            } else {
              setActiveId(item.id);
            }
          }}
        >
          {!!item?.journal_entries && item.journal_entries.length > 0 && (
            <BiDetail color={Colors.fontDark} size={22} />
          )}
        </div>
      </div>
      <div className="w-full px-7 pr-14">
        {activeId === item.id && (
          <table className={styles.gridTable}>
            <thead>
              <tr>
                <th></th>
                <th>Charged</th>
                <th>Refunded</th>
              </tr>
            </thead>
            <tbody>
              {item.journal_entries.map((row) => (
                <tr key={row.id}>
                  <td className="font-semibold">{row.display_name}</td>
                  <td>${numberWithCommas((row.amount / 100).toFixed(2))}</td>
                  <td>
                    ${numberWithCommas((row.reversed_amount / 100).toFixed(2))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default TransactionsItem;
