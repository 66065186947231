import axios from "axios";

const categories = {
  active: [
    "delivery_created",
    "driver_not_assigned",
    "driver_assigned",
    "enroute_pickup",
    "arrived_at_pickup",
    "pickup_complete",
    "enroute_dropoff",
    "arrived_at_dropoff",
    "dropoff_complete",
    "scheduled",
  ],
  canceled: [
    "contacting_provider",
    "enroute_to_return",
    "provider_canceled",
    "customer_canceled",
    "burq_canceled",
    "expired",
    "failed",
    "disputed",
  ],
  completed: ["delivered", "returned"],
};

export const classNameHandler = (column) => {
  switch (column) {
    case "Delivery ID":
      return "w-80";
    case "Drop-off Name":
      return "w-64";
    case "Date Created":
      return "w-60";
    case "Pick-up Address":
      return "w-96";
    case "Status":
      return "w-60";
    case "Customer phone number":
      return "w-60";
    case "Provider Name":
      return "w-60";
    case "Scheduled Time":
      return "w-64";
    case "Drop-off address":
      return "w-96";
    case "Order Value":
      return "w-48";
    case "External delivery ID":
      return "w-52";
    case "Delivery & Fee Refunded":
      return "w-52";
    case "Order Refunded":
      return "w-52";

    default:
      return "w-80";
  }
};

export const homeStatuses = [
  // active
  { back: "delivery_created", front: "Delivery Created", checked: false },
  { back: "driver_not_assigned", front: "Assigning Driver", checked: false },
  { back: "driver_assigned", front: "Driver Assigned", checked: false },
  { back: "enroute_pickup", front: "Enroute to Pick-up", checked: false },
  { back: "arrived_at_pickup", front: "Arrived at Pick-up", checked: false },
  { back: "pickup_complete", front: "Pick-up Complete", checked: false },
  { back: "enroute_dropoff", front: "Enroute to Drop-off", checked: false },
  { back: "arrived_at_dropoff", front: "Arrived at Drop-off", checked: false },
  { back: "dropoff_complete", front: "Drop off Completed", checked: false },
  { back: "scheduled", front: "Scheduled", checked: false },
  //canceled
  { back: "contacting_provider", front: "Contacting Provider", checked: false },
  { back: "enroute_to_return", front: "Enroute to Return", checked: false },
  { back: "provider_canceled", front: "Provider Canceled", checked: false },
  { back: "customer_canceled", front: "Merchant Canceled", checked: false },
  { back: "burq_canceled", front: "Burq Canceled", checked: false },
  { back: "expired", front: "Expired", checked: false },
  { back: "failed", front: "Failed", checked: false },
  { back: "disputed", front: "Disputed", checked: false },
  //completed
  { back: "delivered", front: "Delivered", checked: false },
  { back: "returned", front: "Returned", checked: false },
];
export const disputedStatuses = [
  { back: "active", front: "Active", checked: false },
  { back: "refunded", front: "Refunded", checked: false },
  { back: "rejected", front: "Rejected", checked: false },
];

export const statusesHandler = (status) => {
  if (
    status === "delivery_created" ||
    status === "driver_not_assigned" ||
    status === "driver_assigned" ||
    status === "enroute_pickup" ||
    status === "arrived_at_pickup" ||
    status === "pickup_complete" ||
    status === "enroute_dropoff" ||
    status === "arrived_at_dropoff" ||
    status === "dropoff_complete" ||
    status === "scheduled"
  ) {
    return "active";
  } else if (
    status === "contacting_provider" ||
    status === "enroute_to_return" ||
    status === "provider_canceled" ||
    status === "customer_canceled" ||
    status === "burq_canceled" ||
    status === "expired" ||
    status === "failed" ||
    status === "disputed"
  ) {
    return "canceled";
  } else if (status === "delivered" || status === "returned") {
    return "completed";
  }
};

export const getCheckedCategories = (statuses) => {
  const result = [];

  for (const [category, keys] of Object.entries(categories)) {
    const checkedCount = statuses.filter((status) => keys.includes(status.back) && status.checked).length;

    if (checkedCount > 0) {
      result.push({ category, count: checkedCount });
    }
  }

  return result;
};

export const uncheckStatus = (setStatusesArray, statusesArray,statusesApiFilterHandler) => {
  const updatedStatuses = statusesArray.map((status) => {
    return { ...status, checked: false };
  });
  statusesApiFilterHandler(updatedStatuses);

  setStatusesArray(updatedStatuses);
};

export const uncheckCategory = (category, setStatusesArray, statusesArray, statusesApiFilterHandler) => {
  const updatedStatuses = statusesArray.map((status) => {
    if (categories[category]?.includes(status.back)) {
      return { ...status, checked: false };
    }
    return status;
  });

  statusesApiFilterHandler(updatedStatuses);
  setStatusesArray(updatedStatuses);
};

export const homeColumns = [
  { header: "Delivery ID", isActive: true, isFixed: true },
  { header: "Drop-off Name", isActive: true, isFixed: false },
  { header: "Date Created", isActive: true, isFixed: false },
  { header: "Status", isActive: true, isFixed: false },
  { header: "Drop-off address", isActive: true, isFixed: false },
  { header: "Pick-up Address", isActive: false, isFixed: false },
  { header: "Drop-off phone number", isActive: false, isFixed: false },
  { header: "Provider Name", isActive: false, isFixed: false },
  { header: "Scheduled Time", isActive: false, isFixed: false },
  { header: "Order Value", isActive: false, isFixed: false },
  { header: "External delivery ID", isActive: false, isFixed: false },
];
export const disputedColumns = [
  { header: "Delivery ID", isActive: true, isFixed: true },
  { header: "Delivery & Fee Refunded", isActive: true, isFixed: true },
  { header: "Order Refunded", isActive: true, isFixed: true },
  { header: "Status", isActive: true, isFixed: true },
  { header: "Date Created", isActive: false, isFixed: false },
  { header: "Provider Name", isActive: false, isFixed: false },
  { header: "Order Value", isActive: false, isFixed: false },
  { header: "Drop-off phone number", isActive: false, isFixed: false },
  { header: "Drop-off Name", isActive: false, isFixed: false },
];

const CancelToken = axios.CancelToken;
export const source = CancelToken.source();
