import React from "react";
import { Marker } from "react-google-maps";

const MapMarker = ({ icon, lat, lng }) => {
  return (
    <Marker
      icon={{
        url: icon,
        scaledSize: new window.google.maps.Size(48, 48),
      }}
      position={{ lat, lng }}
    />
  );
};

export default MapMarker;
