import React, { useRef } from "react";
import Avatar from "react-avatar";
import axios from "axios";
import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";
import { useMerchant, useTheme, userDataStore } from "../../../store";
import { baseUrl } from "../../../runTimeConst";

function ProfileImageContainer({ values, saveDataHandler }) {
  const { smfontsize } = useDesktopOrLaptop();
  const { setAvatar, avatar } = useMerchant();

  const { Colors } = useTheme();

  const photoInputRef = useRef();

  const { merchantData } = useMerchant();
  const { setIsPostUserDataLoading, authInfo } = userDataStore();

  const photoUploadHandler = async (event) => {
    setIsPostUserDataLoading(true);
    let formData = new FormData();
    formData.append("file", event?.target?.files[0]);
    try {
      const response = await axios.post(`${baseUrl}auth/uploadFile`, formData, {
        headers: {
          "Content-Type": event?.target?.files[0]?.type,
        },
      });
      await saveDataHandler(values, response.data);

      setIsPostUserDataLoading(false);
      setTimeout(() => {
        window.location.reload(true);
      }, 500);
    } catch (error) {
      setIsPostUserDataLoading(false);
    }
  };
  const styles = {
    avatarBg: {
      height: "100px",
      width: "100px",
      borderRadius: "50px",
      backgroundColor: Colors.bgGrey,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    chooseFile: {
      fontFamily: "Poppins",
      fontSize: smfontsize,
      fontWeight: "600",
      marginLeft: "32px",
      color: Colors.bluePrimary,
    },
  };

  return (
    <form
      style={{
        marginTop: "24px",
        borderRadius: "8px",
        height: "100%",
        marginBottom: "24px",
      }}
    >
      <div className="flex flex-row items-center ">
        <div className="overflow-hidden" style={styles.avatarBg}>
          {avatar ? (
            <img
              style={{ objectFit: "cover", height: "100%", width: "100%" }}
              src={avatar}
              alt=""
            />
          ) : (
            <Avatar
              name={
                merchantData?.email
                  ? merchantData.email.split("").join(" ")
                  : merchantData?.name
              }
              maxInitials={2}
            />
          )}
        </div>
        <div className="flex" style={styles.chooseFile}>
          <input
            ref={photoInputRef}
            type="file"
            onChange={photoUploadHandler}
            // onChange={imageHandler}
            style={{ display: "none" }}
          />
          <button
            className="focus:outline-none"
            style={{
              marginRight: "6px",
              fontWeight: "500",
              fontSize: smfontsize,
            }}
            type="button"
            onClick={() => {
              photoInputRef.current.click();
            }}
          >
            Choose a file
          </button>
        </div>
      </div>
    </form>
  );
}

export default ProfileImageContainer;
