import { FaSquareCheck } from "react-icons/fa6";

import { TiCancel } from "react-icons/ti";

import { FaExclamationTriangle } from "react-icons/fa";
import { RiRefund2Line } from "react-icons/ri";

export const statusesBadgesObject = {
  unpaid: { type: "error", label: "Unpaid" },
  failed: { type: "error", label: "Failed" },
  paid: { type: "success", label: "Paid" },
  refunded: { type: "warning", label: "Refunded" },
  refunding: { type: "warning", label: "Refunding" },
  disputed: { type: "neutral", label: "Disputed" },
};

export const iconHandlerForPayments = (status, isdesktop) => {
  switch (status) {
    case "paid":
    case "refunded":
      return <FaSquareCheck size={isdesktop ? 17 : 30} />;
    case "failed":
    case "unpaid":
      return <TiCancel className="-mr-1" size={isdesktop ? 20 : 30} />;
    case "disputed":
      return <FaExclamationTriangle className="-mr-1" size={isdesktop ? 15 : 30} />;
    case "refunding":
      return <RiRefund2Line className="-mr-1" size={isdesktop ? 17 : 30} />;

    default:
      return null;
  }
};
