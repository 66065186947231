import React from "react";

import { useDesktopOrLaptop } from "../../../../services/useDesktopOrLaptop";
import styles from "./styles.module.css";
import classNames from "classnames";
import { PiCheckCircleLight, PiInfo, PiWarningCircle } from "react-icons/pi";
import { IoIosCloseCircleOutline } from "react-icons/io";

const Banner = ({
  type = "neutral",
  customTitle,
  body,
  extraContainerClasses,
}) => {
  const title =
    customTitle ||
    (type === "success"
      ? "Success"
      : type === "warning"
      ? "Warning"
      : type === "error"
      ? "Error"
      : "Info");
  return (
    <div
      className={classNames(styles.container, extraContainerClasses, {
        [styles.borderNeutral]: type === "neutral",
        [styles.borderSuccess]: type === "success",
        [styles.borderWarning]: type === "warning",
        [styles.borderError]: type === "error",
      })}
    >
      <div className="mr-3">
        {type === "warning" ? (
          <PiWarningCircle size={50} className={styles.borderWarning} />
        ) : type === "neutral" ? (
          <PiInfo size={50} className={styles.borderNeutral} />
        ) : type === "success" ? (
          <PiCheckCircleLight size={50} className={styles.borderSuccess} />
        ) : (
          type === "error" && (
            <IoIosCloseCircleOutline size={50} className={styles.borderError} />
          )
        )}
      </div>
      <div>
        <div className={styles.title}>{title}</div>
        <div className={classNames("mt-3", styles.body)}>{body}</div>
      </div>
    </div>
  );
};

export default Banner;
