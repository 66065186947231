import React, { useMemo } from "react";

import { CalendarSchedule, LaterSvg, NowSvg } from "../../../assets/svgs/Svgs";
import { useFormikContext } from "formik";
import AsapOrSchedule from "../../AsapOrSchedule";
import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";
import { useTheme } from "../../../store";
import styles from "./styles.module.css";

function TimeTab({ isOrder, isReroute }) {
  const { Colors, themeChanger } = useTheme();

  const { values, setFieldValue } = useFormikContext();

  const styless = useMemo(() => {
    return {
      button: {
        width: "100%",
        borderRadius: "8px",
        display: "flex",
        padding: "31px 20px",
        backgroundColor: Colors.buttonBg,
        marginTop: "20px",
        alignItems: "center",
        borderColor: Colors.bluePrimary,
      },
    };
  }, [themeChanger]);

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        How quickly do you want us to send a driver?
      </div>
      <button
        type="button"
        onClick={() => {
          if (!isReroute) {
            setFieldValue("initiate", "now");
            setFieldValue("eta", "");
          }
        }}
        style={{
          ...styless.button,
          ...{
            borderWidth: values.initiate === "now" ? "1px" : "0px",
            backgroundColor: themeChanger ? "#FEFCF5" : Colors.bgDarkSecondary,
          },
        }}
      >
        <div style={{ marginRight: "14px" }}>
          <NowSvg />
        </div>
        <div className={styles.textContainer}>
          <div className={styles.buttonTitle}>Now</div>
          <div className={styles.buttonText}>Driver will be assigned ASAP</div>
        </div>
      </button>
      <button
        type="button"
        style={{
          ...styless.button,
          ...{
            borderWidth: values.initiate === "scheduled" ? "1px" : "0px",
            backgroundColor: themeChanger ? "#EFFBF5" : Colors.bgDarkSecondary,
          },
        }}
        onClick={() => {
          if (!isReroute) {
            setFieldValue("initiate", "scheduled");
            setFieldValue("isPickupAt", true);
          }
        }}
      >
        <div style={{ marginRight: "14px" }}>
          <CalendarSchedule />
        </div>
        <div className={styles.textContainer}>
          <div className={styles.buttonTitle}>Schedule</div>
          <div className={styles.buttonText}>
            Select a time and date in the future for us to route a driver
          </div>
        </div>
      </button>
      {values.initiate === "scheduled" && (
        <AsapOrSchedule isReroute={isReroute} />
      )}
      {!isOrder && (
        <button
          type="button"
          onClick={() => {
            if (!isReroute) {
              setFieldValue("initiate", "later");
              setFieldValue("eta", "");
            }
          }}
          style={{
            ...styless.button,
            ...{
              borderWidth: values.initiate === "later" ? "1px" : "0px",
              backgroundColor: themeChanger
                ? "#EFFBF5"
                : Colors.bgDarkSecondary,
            },
          }}
        >
          <div style={{ marginRight: "14px" }}>
            <LaterSvg />
          </div>
          <div className={styles.textContainer}>
            <div className={styles.buttonTitle}> Save Delivery Info</div>
            <div className={styles.buttonText}>
              You can save delivery information now and initiate when you need a
              driver
            </div>
          </div>
        </button>
      )}
    </div>
  );
}

export default TimeTab;
