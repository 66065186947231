import axios from "axios";
import apiUrls from "../../constants/apiUrls";
import { generateQueryParams } from "./utils";

// export const getNotificationsSettings = (id, queryParams, source) => {
//   const urlWithQueryParams = generateQueryParams(
//     apiUrls.GET_NOTIFICATIONS(id),
//     queryParams
//   );
//   return axios.get(urlWithQueryParams, {
//     headers: { "x-burq-client": "client-dashboard" },
//     cancelToken: source.token,
//   });
// };

export const getNotificationsSettings = (id) =>
  axios.get(apiUrls.GET_NOTIFICATIONS_SETTINGS(id), {
    headers: { "x-burq-client": "client-dashboard" },
  });

export const putNotificationSettings = (userId, notificationId, payload) =>
  axios.put(
    apiUrls.PUT_UPDATE_NOTIFICATIONS_SETTING(userId, notificationId),
    payload,
    {
      headers: { "x-burq-client": "client-dashboard" },
    }
  );
