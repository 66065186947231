import React from "react";
import moment from "moment";
import momenttz from "moment-timezone";
import { CheckSvg } from "../../assets/svgs/Svgs";
import { useTheme } from "../../store";
import { RiCloseLine } from "react-icons/ri";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import { statusesObject } from "../../runTimeConst";

function DeliveryStatusTimeline({ status, statusesHystory, onAdmin }) {
  const { Colors } = useTheme();
  const { isdesktop, smfontsize, mdfontsize } = useDesktopOrLaptop();
  return (
    <div
      className="kata"
      style={{
        width: "100%",
        backgroundColor: Colors.whitestBlue,
        padding: isdesktop ? "30px" : "50px",
        maxHeight: "530px",
        overflowY: "scroll",
      }}
    >
      {statusesHystory
        .slice(0)
        .reverse()
        .map((item, idx) => {
          return (
            <div
              key={idx}
              style={{
                width: "100%",
                position: "relative",
                paddingBottom: "25px",
                borderLeftWidth:
                  idx === statusesHystory.length - 1 ? "0px" : "1px",
                borderColor: "#DFDFDF",
                paddingLeft: isdesktop ? "30px" : "50px",
              }}
            >
              {item.status === "provider_canceled" ||
              item.status === "customer_canceled" ||
              item.status === "burq_canceled" ||
              item.status === "failed" ? (
                <div
                  style={{
                    height: isdesktop ? "30px" : "50px",
                    width: isdesktop ? "30px" : "50px",
                    borderRadius: isdesktop ? "15px" : "25px",
                    position: "absolute",
                    left: isdesktop ? "-15px" : "-25px",
                    top: isdesktop ? "-5px" : "-10px",
                    backgroundColor: Colors.redDark,
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <RiCloseLine size={22} color="white" />
                </div>
              ) : idx === 0 ? (
                <div
                  style={{
                    height: isdesktop ? "30px" : "50px",
                    width: isdesktop ? "30px" : "50px",
                    borderRadius: isdesktop ? "15px" : "25px",
                    position: "absolute",
                    left: isdesktop ? "-15px" : "-25px",
                    top: isdesktop ? "-5px" : "-10px",
                    backgroundColor: Colors.bluePrimary,
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <CheckSvg color={Colors.bluePrimary} />
                </div>
              ) : (
                <div
                  style={{
                    height: isdesktop ? "20px" : "30px",
                    width: isdesktop ? "20px" : "30px",
                    borderRadius: isdesktop ? "15px" : "20px",
                    position: "absolute",
                    left: isdesktop ? "-10px" : "-15px",
                    top: isdesktop ? "-5px" : "-2px",
                    backgroundColor: "#DFDFDF",
                  }}
                ></div>
              )}
              <div
                className="flex w-full items-center"
                style={{
                  width: "100%",
                  fontSize: smfontsize,
                  color: Colors.fontDark,
                }}
              >
                <div className="mr-32">{statusesObject[item.status]}</div>
                {item?.previous_courier_name && (
                  <div className="mr-8">
                    <span style={{ color: Colors.fontDark }}>Driver: </span>
                    <span
                      style={{ color: Colors.fontGreyLight, marginLeft: "4px" }}
                    >
                      {item?.previous_courier_name}
                    </span>
                  </div>
                )}
                {item?.created_by && onAdmin && (
                  <div>
                    <span style={{ color: Colors.fontDark }}>User: </span>
                    <span
                      style={{ color: Colors.fontGreyLight, marginLeft: "4px" }}
                    >
                      {item?.created_by}
                    </span>
                  </div>
                )}

                {/* {item.status === "delivery_created"
                  ? "Delivery Created"
                  : item.status === "driver_assigned"
                  ? "Driver Assigned"
                  : item.status === "driver_not_assigned"
                  ? "Assigning Driver"
                  : item.status === "enroute_pickup"
                  ? "Enroute to Pick-up"
                  : item.status === "arrived_at_pickup"
                  ? "Arrived at Pick-up"
                  : item.status === "pickup_complete"
                  ? "Pick-up Complete"
                  : item.status === "enroute_dropoff"
                  ? "Enroute to Drop-off"
                  : item.status === "arrived_at_dropoff"
                  ? "Arrived at Drop-off"
                  : item.status === "dropoff_complete"
                  ? "Drop-off Completed"
                  : item.status === "delivered"
                  ? "Delivered"
                  : item.status === "contacting_provider"
                  ? "Contacting Provider"
                  : item.status === "scheduled"
                  ? "Scheduled"
                  : item.status === "provider_canceled"
                  ? "Provider Canceled"
                  : item.status === "customer_canceled"
                  ? "Merchant Canceled"
                  : item.status === "burq_canceled"
                  ? "Burq Canceled"
                  : item.status === "expired"
                  ? "Expired"
                  : item.status === "failed"
                  ? "Failed"
                  : item.status === "disputed"
                  ? "Disputed"
                  : item.status === "pending"
                  ? "Pending"
                  : item.status === "pickup"
                  ? "Pickup"
                  : item.status === "dropoff"
                  ? "Dropoff"
                  : item.status === "canceled"
                  ? "Canceled"
                  : item.status === "returned"
                  ? "Returned"
                  : item.status === "request"
                  ? "Not Initiated"
                  : item.status} */}
              </div>
              <div
                style={{
                  width: "100%",
                  fontSize: smfontsize,
                  color: Colors.fontGreyLight,
                }}
              >
                {moment(item.time).format("h:mm A") !== "Invalid date" &&
                item.time
                  ? moment(item.time).format("ddd, DD MMM YYYY, hh:mm A") +
                    ", " +
                    momenttz.tz(momenttz.tz.guess()).format("z")
                  : "---"}
              </div>
            </div>
          );
        })}
      {/* 
      <div
        style={{
          width: "100%",
          position: "relative",
          paddingBottom: "20px",
          borderLeftWidth: "1px",
          borderColor: "#DFDFDF",
          paddingLeft: "30px",
        }}
      >
        <div
          style={{
            height: "20px",
            width: "20px",
            borderRadius: "15px",
            position: "absolute",
            left: "-10px",
            top: "-5px",
            backgroundColor: "#DFDFDF",
          }}
        ></div>
        <div style={{ width: "100%" }}>Pending</div>
        <div style={{ width: "100%" }}>TIME</div>
      </div> */}
    </div>
  );
}

export default DeliveryStatusTimeline;
