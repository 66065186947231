import React from "react";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import logo from "../../assets/png/dark.png";

const TrackingMobileViewHeader = ({ id }) => {
  const { isdesktop } = useDesktopOrLaptop();

  return (
    <header
      style={{
        height: "100px",
        position: "sticky",
        top: 0,
        left: 0,
        right: 0,
        paddingLeft: "20px",
        paddingRight: "20px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        backgroundColor: "white",
        zIndex: 200,
        borderBottom: "solid 2px #F2F2F7",
      }}
    >
      <a href="https://burqup.com">
        <div
          style={{
            width: isdesktop ? "68px" : "130px",
          }}
        >
          <img src={logo} alt="Burq logo" />
        </div>
      </a>
      <div
        style={{
          fontSize: "22px",
          fontFamily: "Poppins",
          display: "flex",
          alignItems: "center",
        }}
      >
        Id:
        <div
          style={{ marginLeft: "10px", fontSize: "20px" }}
          className="text-gray-400"
        >
          {id || ""}
        </div>
      </div>
    </header>
  );
};

export default TrackingMobileViewHeader;
