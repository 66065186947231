import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { useInvoices, useMyStore } from "../../../../../store";
import moment from "moment";
import { OneCard } from "./OneCard";
import { numberWithCommas } from "../../../../Analytics/utils";
import { LuDollarSign } from "react-icons/lu";
import { CiCreditCard1 } from "react-icons/ci";
import { paymentErrorCodes } from "../../../utlis";
import { useNavigate } from "react-router-dom";
import Button from "../../../../shared/atoms/Button";
import PaymentStatusForInvoices from "../../PaymentStatusForInvoices";
import classNames from "classnames";
import CustomModal from "../../../../CustomModal/CustomModal";
import DirectTransferBankAccountDetails from "../DirectTransferBankAccountDetails";
import Cookies from "js-cookie";
import { setCookie } from "../../../../../utils";

export function InvoiceCardsHeader(props) {
  const navigate = useNavigate();
  const { selectedInvoice, setSelectedInvoice, invoiceInitiatePaymentWorker, getInvoiceWorker } = useInvoices();
  const { setIsSandbox } = useMyStore();

  const [isPayDisabled, setIsPayDisabled] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const submitPaymentHandler = async () => {
    if (!selectedInvoice?.id) return;
    setIsPayDisabled(true);
    const cb = (err) => {
      if (err?.response?.data?.code && paymentErrorCodes.includes(err?.response?.data?.code)) {
        navigate("/v1/settings/payments");
      }
      if (!err) {
        getInvoiceWorker(selectedInvoice?.id).then((res) => {
          setSelectedInvoice(res);
          setIsSandbox(res?.test_mode);
          setCookie("testModeCookie", res?.test_mode ? "true" : "false");
        });
      }
    };
    invoiceInitiatePaymentWorker(selectedInvoice.id, cb);
  };

  const paymentDesc = () => {
    if (selectedInvoice?.is_autopay_enabled && selectedInvoice?.payment_method) {
      return `Account has autopay enabled. Payment will be automatically collected from payment method x${selectedInvoice?.payment_method?.public_identifier} when it's due.`;
    } else if (selectedInvoice?.is_autopay_enabled && !selectedInvoice?.payment_method) {
      return "Account has autopay enabled but no payment method has been provided. Please add a payment method to avoid any delay in processing payment.";
    } else if (!selectedInvoice?.is_autopay_enabled) {
      return `Account does not have autopay enabled. Please click on the “Pay Invoice” button to submit payment before due date.`;
    } else if (
      selectedInvoice?.amountPaid &&
      selectedInvoice?.amountDue &&
      selectedInvoice?.amountPaid > selectedInvoice?.amountDue
    ) {
      return `This invoice received an excessive payment of $${numberWithCommas(
        (selectedInvoice?.amountPaid - selectedInvoice?.amountDue) / 100
      ).toFixed(2)}. This amount will be carried over and deducted from subsequent invoices.`;
    } else {
      return "";
    }
  };

  const paymentHandler = () => {
    if (
      !selectedInvoice?.payment_method &&
      selectedInvoice?.payment_status !== "paid" &&
      selectedInvoice?.payment_status !== "processing" &&
      selectedInvoice?.payment_status !== "payment_not_required"
    ) {
      return (
        <Button
          text="Add Payment Method"
          externalClassName={styles.addPayment}
          onClick={() => navigate("/v1/settings/payments")}
          // disabled={isPayDisabled}
          variant={"secondary"}
        />
      );
    }
    if (
      selectedInvoice?.payment_status !== "paid" &&
      selectedInvoice?.payment_status !== "processing" &&
      selectedInvoice?.payment_status !== "payment_not_required"
    )
      return (
        <Button
          text="Pay Invoice"
          externalClassName={styles.payButton}
          onClick={() => submitPaymentHandler()}
          disabled={isPayDisabled}
          variant={isPayDisabled ? "disabled" : "primary"}
        />
      );
  };

  useEffect(() => {
    return () => {
      // setSelectedInvoice();
    };
  }, []);

  return (
    <div className={styles.container}>
      <OneCard
        desc={
          selectedInvoice?.store_name
            ? `This invoice includes all deliveries from your store (${selectedInvoice?.store_name}) during the billing period.`
            : `This invoice includes all deliveries from your account during the billing period (except for those under stores with their own payment methods, if applicable).`
        }
        middleTitle={`${
          selectedInvoice?.billing_period_start_at ? moment(selectedInvoice?.billing_period_start_at).format("MMM D") : ""
        } - ${
          selectedInvoice?.billing_period_end_at ? moment(selectedInvoice?.billing_period_end_at).format("MMM D, YYYY") : ""
        }`}
        title={"Billing Period"}
      />

      <OneCard
        desc={
          <div>
            <div>Due Date: {moment(selectedInvoice?.payment_due_at).format("MMM D, YYYY")}</div>
            <div>
              Charged: $
              {selectedInvoice?.total_debit_amount
                ? numberWithCommas((selectedInvoice?.total_debit_amount / 100)?.toFixed(2))
                : "0"}
            </div>
            <div>
              Refunded: $
              {selectedInvoice?.total_credit_amount
                ? numberWithCommas((selectedInvoice?.total_credit_amount / 100)?.toFixed(2))
                : "0"}
            </div>
          </div>
        }
        middleTitle={`$${selectedInvoice?.amount_due ? numberWithCommas((selectedInvoice?.amount_due / 100)?.toFixed(2)) : "0"}`}
        title={"Amount Due"}
        icon={<LuDollarSign />}
      />

      <OneCard
        desc={
          <div>
            {selectedInvoice?.starting_credit ? (
              <div>
                Credit applied from previous invoice: $
                {numberWithCommas((Math.abs(selectedInvoice.starting_credit) / 100)?.toFixed(2))}
              </div>
            ) : (
              ""
            )}
            {selectedInvoice?.prev_invoice_payment_surplus_amount ? (
              <div>
                Extra payment applied from previous invoice: $
                {numberWithCommas((selectedInvoice.prev_invoice_payment_surplus_amount / 100)?.toFixed(2))}
              </div>
            ) : (
              ""
            )}
            {!selectedInvoice?.starting_credit && !selectedInvoice?.prev_invoice_payment_surplus_amount && (
              <div>No applicable credit from previous invoice.</div>
            )}
          </div>
        }
        middleTitle={
          selectedInvoice?.starting_credit
            ? "$" + numberWithCommas((Math.abs(selectedInvoice?.starting_credit) / 100)?.toFixed(2))
            : "$0"
        }
        title={"Credit"}
        icon={<CiCreditCard1 size={22} />}
      />

      <OneCard
        desc={paymentDesc()}
        middleTitle={
          <div className={styles.statusCont}>
            <PaymentStatusForInvoices externalClassName={classNames(styles.status)} data={selectedInvoice?.payment_status} />
          </div>
        }
        title={"Payment"}
        icon={paymentHandler()}
        externalClassNames={styles.paymentCardTitleSection}
        showPayByBankTransfer={
          selectedInvoice?.payment_status !== "paid" &&
          selectedInvoice?.payment_status !== "processing" &&
          selectedInvoice?.payment_status !== "payment_not_required"
        }
        onHyperlinkClick={openModal}
      />
      <CustomModal isOpen={isModalOpen} onClose={closeModal} extraPanelStyles={{ overflow: "visible" }}>
        <DirectTransferBankAccountDetails />
      </CustomModal>
    </div>
  );
}
