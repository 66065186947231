import React from 'react';
import styles from './styles/menu.module.css';
import classNames from 'classnames';

const Menu = ({ activeIndex, onDotClick }) => {
  return (
    <div className={styles.menu}>
      <div
        className={classNames({
          [styles.dot]: true,
          [styles.active]: activeIndex === 0
        })}
        onClick={() => onDotClick(0)}
      ></div>
      <div
        className={classNames({
          [styles.dot]: true,
          [styles.active]: activeIndex === 1
        })}
        onClick={() => onDotClick(1)}
      ></div>
      <div
        className={classNames({
          [styles.dot]: true,
          [styles.active]: activeIndex === 2
        })}
        onClick={() => onDotClick(2)}
      ></div>
    </div>
  );
};

export default Menu;
