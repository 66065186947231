import React from "react";
import {
  useMyStore,
  useProvidersManagementStore,
  useTheme,
  userDataStore,
} from "../../../../store";
import { Formik } from "formik";
import { RiQuestionLine } from "react-icons/ri";
import InputErrorComp from "../../../shared/common/InputErrorComp";
import ButtonComp from "../../../ButtonComp/ButtonComp";
import { defaultValidationSchema } from "./validationSchema";
import Loader from "../../../shared/atoms/Loader";
import styles from "../styles/requestContact.module.css";
import classNames from "classnames";

const INITIAL_VALUES = {
  monthly_delivery_volume: "",
  delivery_volume_with_DSP: "",
  contact_reason: "",
  contact_concern: "",
};

const RequestContactTab = ({data}) => {
  const { authInfo } = userDataStore();
  const { selectedProvider } = useProvidersManagementStore();
  const { Colors } = useTheme();

  const { isPCRLoading, createProviderContactRequestWorker } =
    useProvidersManagementStore();
  const { isSandbox } = useMyStore();

  const requestContactHandler = (values) => {
    const payload = {
      test_mode: isSandbox,
      form_response: values,
    };
    createProviderContactRequestWorker(
      authInfo.merchantAccountId,
      selectedProvider.id,
      payload
    );
  };

  if (isPCRLoading) {
    return (
      <div className={styles.loaderContainer}>
        <Loader />
      </div>
    );
  }

  return (
    <div
      className={classNames("w-full flex flex-col relative", styles.container)}
    >
      {data?.provider_contact_requests?.length > 0 && (
        <div className={classNames(styles.helperText)}>
          <span className="w-5 ">
            <RiQuestionLine color={Colors.fontGreyLight} size={18} />
          </span>
          <span className="ml-1 leading-4">
            You have already requested a contact request for this DSP.
          </span>
        </div>
      )}
      <div className="flex w-full flex-col  ">
        <Formik
          initialValues={INITIAL_VALUES}
          onSubmit={requestContactHandler}
          validationSchema={defaultValidationSchema}
          enableReinitialize
        >
          {({
            handleChange,
            handleSubmit,
            values,
            errors,
            setFieldTouched,
            touched,
          }) => (
            <>
              <div>
                <div className={styles.inputLable}>
                  1. Approximately how many deliveries do you conduct per month?
                  <span className={styles.required}>Required*</span>
                </div>
                <div className={styles.inputForEdit}>
                  <textarea
                    onFocus={() => {
                      setFieldTouched("monthly_delivery_volume");
                    }}
                    onChange={handleChange}
                    placeholder={`Type your answer here...`}
                    name="monthly_delivery_volume"
                    value={values.monthly_delivery_volume}
                    type="text"
                    className={styles.input}
                  />
                </div>
                <InputErrorComp
                  error={
                    errors.monthly_delivery_volume &&
                    touched.monthly_delivery_volume
                      ? errors.monthly_delivery_volume
                      : undefined
                  }
                />
              </div>
              <div>
                <div className={styles.inputLable}>
                  2. Approximately how many deliveries do you conduct through
                  this DSP?
                  <span className={styles.required}>Required*</span>
                </div>
                <div className={styles.inputForEdit}>
                  <textarea
                    onFocus={() => {
                      setFieldTouched("delivery_volume_with_DSP");
                    }}
                    onChange={handleChange}
                    placeholder={`Type your answer here...`}
                    name="delivery_volume_with_DSP"
                    value={values.delivery_volume_with_DSP}
                    type="text"
                    className={styles.input}
                  />
                </div>
                <InputErrorComp
                  error={
                    errors.delivery_volume_with_DSP &&
                    touched.delivery_volume_with_DSP
                      ? errors.delivery_volume_with_DSP
                      : undefined
                  }
                />
              </div>
              <div>
                <div className={styles.inputLable}>
                  3. Why would you like to contact this DSP?
                  <span className={styles.required}>Required*</span>
                </div>
                <div className={styles.inputForEdit}>
                  <textarea
                    onFocus={() => {
                      setFieldTouched("contact_reason");
                    }}
                    onChange={handleChange}
                    placeholder={`Type your answer here...`}
                    name="contact_reason"
                    value={values.contact_reason}
                    type="text"
                    className={styles.input}
                  />
                </div>
                <InputErrorComp
                  error={
                    errors.contact_reason && touched.contact_reason
                      ? errors.contact_reason
                      : undefined
                  }
                />
              </div>
              <div>
                <div className={styles.inputLable}>
                  4. Are you currently experiencing any issues or see
                  opportunities for growth with this DSP?
                  <span className={styles.required}>Required*</span>
                </div>
                <div className={styles.inputForEdit}>
                  <textarea
                    onFocus={() => {
                      setFieldTouched("contact_concern");
                    }}
                    onChange={handleChange}
                    placeholder={`Type your answer here...`}
                    name="contact_concern"
                    value={values.contact_concern}
                    type="text"
                    className={styles.input}
                  />
                </div>
                <InputErrorComp
                  error={
                    errors.contact_concern && touched.contact_concern
                      ? errors.contact_concern
                      : undefined
                  }
                />
              </div>

              <ButtonComp
                title={"Done"}
                primary
                onClick={handleSubmit}
                extraStyles={{ marginTop: "40px" }}
              />
            </>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default RequestContactTab;
