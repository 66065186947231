import React, { useEffect, useState } from "react";
import { Workflows, WorkflowsMobile } from "../../assets/svgs/Svgs";
import { CgArrowsExchange } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import {
  useTheme,
  useMyStore,
  useWorkflow,
  userDataStore,
  useFilter,
} from "../../store";
import { BsChevronDown } from "react-icons/bs";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";

function DropdownResponsive({
  setSidepanelactive,
  setBurger,
  sidepanelactive,
  active,
  id,
  setIsModal,
}) {
  const { Colors } = useTheme();
  const { activeWorkflow, setActiveWorkflow } = useWorkflow();

  const { isdesktop, mdfontsize } = useDesktopOrLaptop();

  const [isDropdownOpened, setIsDropdownOpened] = useState(false);

  let history = useNavigate();
  const { setFilterArray } = useFilter();

  const { showQuoteWorkflow } = userDataStore();

  // useEffect(() => {
  //   if (sidepanelactive === "workflows") {
  //     setActiveWorkflow("sms");
  //   }
  // }, [sidepanelactive]);
  useEffect(() => {
    if (sidepanelactive !== "workflows") {
      setIsDropdownOpened(false);
    }
  }, [sidepanelactive]);

  return (
    <div>
      <button
        id={id}
        onClick={() => {
          setIsDropdownOpened(!isDropdownOpened);
          setFilterArray([]);
          setSidepanelactive(active);
          history(`/v1/${active}`);
          if (!isdesktop) {
            setBurger(false);
          }
        }}
        className={` flex w-full items-center justify-start hover:border-blue-600 mb-5 focus:outline-none kata outline-none`}
        style={{
          display: "flex",
          width: "90%",
          marginLeft: "52px",
          marginRight: "30px",
          alignItems: "center",
          borderBottomWidth: "3px",
          borderColor: Colors.black,
          marginTop: "30px",
        }}
      >
        <div
          style={{ paddingBottom: "10px", paddingTop: "10px" }}
          className="w-full flex items-center ml-7 relative"
        >
          {isdesktop ? (
            <Workflows
              sidepanelactive={sidepanelactive}
              isdesktop={isdesktop}
            />
          ) : (
            <WorkflowsMobile
              sidepanelactive={sidepanelactive}
              isdesktop={isdesktop}
            />
          )}

          <div
            style={{
              fontSize: mdfontsize,
              color:
                sidepanelactive === active
                  ? Colors.bluePrimary
                  : Colors.fontGreyLight,
              fontFamily: "Poppins",
              transition: `all 0.5s`,
            }}
            className={` ${
              active === "workflows" ? "ml-5" : "ml-6"
            } font-semibold capitalize`}
          >
            {active}
          </div>

          <div
            className="kata"
            style={{
              position: "absolute",
              right: "20px",
              transform: isDropdownOpened ? `rotate(180deg)` : `rotate(0deg)`,
            }}
          >
            <BsChevronDown
              color={isDropdownOpened ? Colors.bluePrimary : Colors.fontDark}
            />
          </div>
        </div>
      </button>

      {/* {sidepanelactive === "workflows" && ( */}
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          paddingLeft: "120px",
          opacity: isDropdownOpened ? 1 : 0,
          transition: "opacity 0.3s linear 0s",
        }}
      >
        {isDropdownOpened && (
          <>
            <button
              type="button"
              onClick={() => {
                setActiveWorkflow("sms");
                setIsModal(false);
              }}
            >
              <div
                className="mb-5"
                style={{
                  fontSize: mdfontsize,
                  color:
                    activeWorkflow === "sms"
                      ? Colors.bluePrimary
                      : Colors.fontGreyLight,
                  fontFamily: "Poppins",
                  transition: `all 0.5s`,
                  fontWeight: "600",
                  textTransform: "capitalize",
                  textAlign: "left",
                }}
              >
                sms workflows
              </div>
            </button>
            {/* {showQuoteWorkflow && ( */}
            <button
              type="button"
              onClick={() => {
                setActiveWorkflow("quote");
                setIsModal(false);
              }}
            >
              <div
                className="mb-5"
                style={{
                  fontSize: mdfontsize,
                  color:
                    activeWorkflow === "quote"
                      ? Colors.bluePrimary
                      : Colors.fontGreyLight,
                  fontFamily: "Poppins",
                  transition: `all 0.5s`,
                  fontWeight: "600",
                  textTransform: "capitalize",
                  textAlign: "left",
                }}
              >
                Quote workflows
              </div>
            </button>
            {/* )} */}
          </>
        )}
      </div>
      {/* )} */}
    </div>
  );
}

export default DropdownResponsive;
