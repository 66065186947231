import { useState } from "react";
import { createStore } from "aniuta";
import * as Sentry from "@sentry/react";
import { getAllProviders, getMerchantEnabledProviders } from "../utils/api/providers";
import { toast } from "react-toastify";

const useFilter = createStore({
  name: "useFilter",
  Store: () => {
    const [filterStore, setFilterStore] = useState();
    const [filterArray, setFilterArray] = useState([]);
    const [startDate, setStartDate] = useState();
    const [search, setSearch] = useState("");
    const [selectedProvider, setSelectedProvider] = useState("");

    const [providerIdForFilter, setProviderIdForFilter] = useState("");

    const [selectedBillingPeriod, setSelectedBillingPeriod] = useState();

    const getAllProvidersHandler = async (params) => {
      try {
        const { data } = await getAllProviders(params);
        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      }
    };
    const getMerchantEnabledProvidersHandler = async (merchantAccountId, params) => {
      try {
        const { data } = await getMerchantEnabledProviders(merchantAccountId, params);
        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      }
    };

    return {
      filterStore,
      setFilterStore,
      filterArray,
      setFilterArray,
      startDate,
      setStartDate,
      search,
      setSearch,
      providerIdForFilter,
      setProviderIdForFilter,
      selectedProvider,
      setSelectedProvider,
      getAllProvidersHandler,
      selectedBillingPeriod,
      setSelectedBillingPeriod,
      getMerchantEnabledProvidersHandler,
    };
  },
});

export default useFilter;
