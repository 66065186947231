export const TIME_ZONES = [
  { name: "UTC", value: "UTC" },
  { name: "Pacific/Honolulu", value: "Pacific/Honolulu" },
  { name: "America/Juneau", value: "America/Juneau" },
  { name: "America/Los_Angeles", value: "America/Los_Angeles" },
  { name: "America/Tijuana", value: "America/Tijuana" },
  { name: "America/Denver", value: "America/Denver" },
  { name: "America/Phoenix", value: "America/Phoenix" },
  { name: "America/Chicago", value: "America/Chicago" },
  { name: "America/New_York", value: "America/New_York" },
  {
    name: "America/Indiana/Indianapolis",
    value: "America/Indiana/Indianapolis",
  },
];
