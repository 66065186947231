import React from "react";
import styles from "./styles/statusFilters.module.css";
import { TbFilter } from "react-icons/tb";
import { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import classNames from "classnames";
import { getCheckedCategories, statusesHandler, uncheckCategory, uncheckStatus } from "./utils";
import { BiCheckbox, BiCheckboxChecked, BiChevronDown, BiChevronUp } from "react-icons/bi";
import { IoCloseCircle } from "react-icons/io5";

const StatusFilters = ({ isHome, statusesFilter, setStatusesFilter, statusesApiFilterHandler, listData }) => {
  const [isFilterOpened, setIsFilterOpened] = useState(false);
  const [isActivesOpened, setIsActivesOpened] = useState(false);
  const [isCancelledOpen, setIsCancelledOpen] = useState(false);
  const [isCompletedOpen, setIsCompletedOpen] = useState(false);

  const statusFilterHandler = (statusObj, index, statusState, setStatusState, isDispute) => {
    const _statusState = structuredClone(statusState);

    if (isDispute) {
      _statusState.forEach((item) => {
        if (item.back === statusObj?.back) {
          item.checked = !item.checked;
        } else {
          item.checked = false;
        }
      });
      setStatusState(_statusState);
      statusesApiFilterHandler(_statusState);

      return;
    }
    const findStatus = _statusState.find((statusStateObj, index) => statusStateObj.back === statusObj.back);
    if (findStatus.checked) {
      _statusState[index].checked = false;
      setStatusState(_statusState);
    } else {
      _statusState[index].checked = true;
      setStatusState(_statusState);
    }
    statusesApiFilterHandler(_statusState);
  };

  const homeCategories = [
    {
      cat: "active",
      setIsOpened: setIsActivesOpened,
      isOpened: isActivesOpened,
      styles: styles.activeSubHeight,
      count: listData?.active_recods_count?.total_active_count,
    },
    { cat: "canceled", setIsOpened: setIsCancelledOpen, isOpened: isCancelledOpen, styles: styles.canceledSubHeight },
    { cat: "completed", setIsOpened: setIsCompletedOpen, isOpened: isCompletedOpen, styles: styles.completedSubHeight },
  ];

  return (
    <div className={styles.container}>
      <OutsideClickHandler
        onOutsideClick={() => {
          setIsFilterOpened(false);
        }}
      >
        <div className="flex items-center">
          <div
            onClick={() => {
              setIsFilterOpened(true);
            }}
            className={styles.buttonContainer}
          >
            <TbFilter size={18} className="mr-1" />
            Filters
            {isHome
              ? getCheckedCategories(statusesFilter).map((categoryObj, index) => {
                  if (index === 0)
                    return (
                      <div
                        key={index}
                        className={styles.categorie}
                        onClick={(e) => {
                          e.stopPropagation();
                          uncheckCategory(categoryObj.category, setStatusesFilter, statusesFilter, statusesApiFilterHandler);
                        }}
                      >
                        <div className={styles.count}>{categoryObj.count}</div>
                        <div className={styles.catName}>
                          {categoryObj.category} {getCheckedCategories(statusesFilter)?.length > 1 && "+"}
                        </div>
                        <IoCloseCircle color="#0062ff" className="ml-2" size={16} />
                      </div>
                    );
                  return <></>;
                })
              : statusesFilter
                  .filter((status) => status?.checked)
                  .map((categoryObj, index) => {
                    if (index === 0)
                      return (
                        <div
                          key={index}
                          className={styles.categorie}
                          onClick={(e) => {
                            e.stopPropagation();
                            uncheckStatus(setStatusesFilter, statusesFilter, statusesApiFilterHandler);
                          }}
                        >
                          <div className={styles.count}>{statusesFilter.filter((status) => status.checked).length}</div>
                          <div className={styles.catName}>
                            {categoryObj.front} {statusesFilter.filter((status) => status.checked).length > 1 && "+"}
                          </div>
                          <IoCloseCircle color="#0062ff" className="ml-1" size={16} />
                        </div>
                      );
                    return <></>;
                  })}
          </div>
        </div>
        {isHome
          ? isFilterOpened && (
              <div className={styles.filterDropdown}>
                <div className={styles.dropdownTitle}>Filter by status</div>
                {homeCategories.map((cat) => {
                  return (
                    <OutsideClickHandler
                      key={cat.cat}
                      onOutsideClick={() => {
                        cat.setIsOpened(false);
                      }}
                    >
                      <div className={styles.subDropdownCont}>
                        <div
                          onClick={() => {
                            cat.setIsOpened((prev) => !prev);
                          }}
                          className="flex items-center justify-between cursor-pointer"
                        >
                          <div className="flex items-center">
                            <div className="cursor-pointer capitalize">{cat.cat}</div>
                            {cat.count || cat.count == 0 ? (
                              <div className={classNames(styles.count, "ml-2")}>{cat.count}</div>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="cursor-pointer text-xl">{cat.isOpened ? <BiChevronUp /> : <BiChevronDown />}</div>
                        </div>

                        <div
                          className={classNames(styles.subDropdown, cat.styles, {
                            [styles.isClosed]: !cat.isOpened,
                          })}
                        >
                          {statusesFilter.map((statusObj, index) => {
                            const statusCount = listData?.active_recods_count?.count_for_statuses?.find(
                              (item) => item?.status === statusObj.back
                            );
                            if (statusesHandler(statusObj.back) === cat.cat) {
                              return (
                                <div
                                  key={statusObj.back}
                                  onClick={() => {
                                    statusFilterHandler(statusObj, index, statusesFilter, setStatusesFilter);
                                  }}
                                  className={classNames("flex items-center justify-between py-1 cursor-pointer")}
                                >
                                  <div className={classNames("flex items-center")}>
                                    <div>
                                      {statusObj.checked ? (
                                        <BiCheckboxChecked size={24} color="#0062FF" />
                                      ) : (
                                        <BiCheckbox size={24} />
                                      )}
                                    </div>
                                    <div>{statusObj.front}</div>
                                  </div>
                                  {statusCount?.count && <div className={styles.countCont}>{statusCount.count}</div>}
                                </div>
                              );
                            }
                            return <></>;
                          })}
                        </div>
                      </div>
                    </OutsideClickHandler>
                  );
                })}
              </div>
            )
          : isFilterOpened && (
              <div className={classNames(styles.filterDropdown)}>
                <div className={styles.dropdownTitle}>Filter by status</div>
                <div className={classNames(styles.subDropdownCont)}>
                  {statusesFilter.map((statusObj, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => {
                          statusFilterHandler(statusObj, index, statusesFilter, setStatusesFilter, true);
                        }}
                        className={classNames("flex items-center justify-between py-1 cursor-pointer")}
                      >
                        <div className={classNames("flex items-center")}>
                          <div>
                            {statusObj.checked ? <BiCheckboxChecked size={24} color="#0062FF" /> : <BiCheckbox size={24} />}
                          </div>
                          <div>{statusObj.front}</div>
                        </div>
                        {statusObj?.back === "active" && listData?.active_recods_count && (
                          <div className={styles.count}>{listData.active_recods_count}</div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
      </OutsideClickHandler>
    </div>
  );
};

export default StatusFilters;
