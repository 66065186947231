import React, { useEffect } from 'react';
import { useInvoices, userDataStore } from '../../../../store';
import styles from "./styles/directTransferBankAccountDetails.module.css";
import Loader from '../../../shared/atoms/Loader';
import CopyToClipboard from '../../../shared/atoms/CopyToClipboard';

const DirectTransferBankAccountDetails = () => {
  const {
    selectedInvoice,
    bankAccountDetails,
    isBankAccountDetailsLoading,
    getStripePaymentAccountWorker,
  } = useInvoices();

  const { authInfo } = userDataStore();

  useEffect(() => {
    const getBankAccountDetailsHandler = async () => {
      await getStripePaymentAccountWorker(authInfo.merchantAccountId);
    };
    getBankAccountDetailsHandler();
  }, [authInfo]);

  if (!bankAccountDetails || isBankAccountDetailsLoading) return <Loader />

  return (
    <div className={styles.bankDetailsContainer}>
      <h3>Bank Information</h3>
      <p>Transfer funds using the following bank information:</p>
      <div className={styles.bankDetailsCard}>
        <div className={styles.tableRow}>
          <div className={styles.tableRowText}>Bank name</div>
          <div className={styles.tableRowTextCol2}>
            <div className={styles.flexBetween}>
              {bankAccountDetails.bank_name}
              {bankAccountDetails.bank_name && <CopyToClipboard copyText={bankAccountDetails.bank_name} />}
            </div>
          </div>
        </div>
        <div className={styles.tableRow}>
          <div className={styles.tableRowText}>Account number</div>
          <div className={styles.tableRowTextCol2}>
            <div className={styles.flexBetween}>
              {bankAccountDetails.bank_account_number}
              {bankAccountDetails.bank_account_number && <CopyToClipboard copyText={bankAccountDetails.bank_account_number} />}
            </div>
          </div>
        </div>
        <div className={styles.tableRow}>
          <div className={styles.tableRowText}>Routing number</div>
          <div className={styles.tableRowTextCol2}>
            <div className={styles.flexBetween}>
              {bankAccountDetails.bank_routing_number}
              {bankAccountDetails.bank_routing_number && <CopyToClipboard copyText={bankAccountDetails.bank_routing_number} />}
            </div>
          </div>
        </div>
        <div className={styles.tableRow}>
          <div className={styles.tableRowText}>SWIFT code</div>
          <div className={styles.tableRowTextCol2}>
            <div className={styles.flexBetween}>
              {bankAccountDetails.swift_code}
              {bankAccountDetails.swift_code && <CopyToClipboard copyText={bankAccountDetails.swift_code} />}
            </div>
          </div>
        </div>
        <div className={styles.tableRow}>
          <div className={styles.tableRowText}>Reference</div>
          <div className={styles.tableRowTextCol2}>
            <div className={styles.flexBetween}>
              {selectedInvoice.id}
              {selectedInvoice.id && <CopyToClipboard copyText={selectedInvoice.id} />}
            </div>
          </div>
        </div>
      </div>

      <p className={styles.note}>Please make sure to <strong>include your invoice number {selectedInvoice.id} </strong> when sending the bank transfer to ensure payment can be properly reconciled against your invoice.</p>
    </div>
  );
};

export default DirectTransferBankAccountDetails;
