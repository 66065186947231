import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Name can be min of 3 charactors")
    .required("Name is required"),
  email: Yup.string()
    .email("Please enter valid email")
    .required("Email is required"),
  phone_number: Yup.string().matches(
    // "^\\+1 \\([0-9]{3}\\) [0-9]{3}-[0-9]{4}$",
    "^\\+1 ?(\\([0-9]{3}\\)|[0-9]{3})[\\s.-]?[0-9]{3}[\\s.-]?[0-9]{4}$",
    "Invalid Phone Number"
  ),
});
