import React from 'react';
import styles from './changelog.module.css';
import JsonView from '../../shared/common/JsonView';
import { useMyStore } from '../../../store';

const supportedWebhookVersions = [
  '220101_delivery_update_only',
  '230720_add_event_type',
  '240510_add_courier_location_event',
];

const Changelog = () => {

  const { webhooksList } = useMyStore();
  const currentVersion = webhooksList[0].current_version;

  const isVersionEligible = (version) => {
    const currentVersionIndex = supportedWebhookVersions.indexOf(currentVersion);
    const targetVersionIndex = supportedWebhookVersions.indexOf(version);
    return currentVersionIndex < targetVersionIndex;

  };

  return (
    <div className={styles.container}>
      {isVersionEligible('240510_add_courier_location_event') && (
        <>
          <h2 className={styles.title}>2024-05-10</h2>
          <ul className={styles.list}>
            <li className={styles.listItem}>
              Added a new event type ‘delivery.courier_location_updated’. Subscribe to this event to receive courier location updates. Previously these updates triggered ‘delivery.updated’ webhooks. We have since created this separate event type to allow for more granular management of webhook traffic.
            </li>
          </ul>
          <JsonView
            jsonObject={{
              object: "event",
              type: "delivery.courier_location_updated",
              data: {
                id: "19g68lawsqlrf",
                delivery_type: "asap",
                service: "doordash",
                status: "delivery_created",
                "...": "...",
              },
            }}
          />
        </>
      )}

      {isVersionEligible('230720_add_event_type') && (
        <>
          <h2 className={styles.title}>2023-07-20</h2>
          <ul className={styles.list}>
            <li className={styles.listItem}>
              Updated our webhook payload format to support subscription of multiple event types. This release changes the top level data structure in the webhook request payload (new structure shown below).
            </li>
          </ul>
          <JsonView
            jsonObject={{
              object: "event",
              type: "delivery.updated",
              data: {
                id: "19g68lawsqlrf",
                delivery_type: "asap",
                service: "doordash",
                status: "delivery_created",
                "...": "...",
              },
            }}
          />
        </>
      )}
    </div>
  );
};

export default Changelog;
