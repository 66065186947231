import React from "react";
import styles from "./styles/listHeader.module.css";
import classNames from "classnames";

const ListHeader = () => {
  return (
    <>
      <tr>
        <th className={classNames(styles.columnTitle, "xl:w-72 truncate")}>Incident Id</th>
        <th className={styles.columnTitle}>Created</th>
        <th className={styles.columnTitle}>Status</th>
        <th className={classNames(styles.columnTitle, "xl:w-72 truncate")}>Delivery Id</th>
        <th className={styles.columnTitle}>Delivery & Tip Refunded Amount</th>
        <th className={styles.columnTitle}>Order Refunded Amount</th>
      </tr>
    </>
  );
};

export default ListHeader;
