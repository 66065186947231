import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { usePaymentStore, userDataStore, useTheme } from "../../../../store";
import Button from "../../atoms/Button";
import styles from "./styles.module.css";

function PaymentMethodsZeroState() {
  let history = useNavigate();
  const { Colors } = useTheme();
  const { authInfo } = userDataStore();
  const { loadCardList } = usePaymentStore();

  const fetchCards = async () => {
    await loadCardList(authInfo?.merchantAccountId);
  };

  useEffect(() => {
    fetchCards();
  }, [authInfo]);

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        padding: "0px 100px 70px 100px",
        borderRadius: "12px",
        alignItems: "center",
        flexDirection: "column",
        backgroundColor: Colors.white,
      }}
    >
      <div
        style={{
          fontFamily: "Poppins",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "16px",
          color: Colors.fontDark,
        }}
      >
        <div
          style={{
            marginTop: "2vh",
            height: "90px",
            width: "90px",
            borderRadius: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: `linear-gradient(45deg, rgba(255,255,255,1) 21%, rgba(222,239,255,1) 63%, rgba(124,192,255,1) 100%)`,
          }}
        >
          <span
            style={{ fontSize: "46px", color: "#0570D4" }}
            className="material-icons-outlined "
          >
            account_balance
          </span>
        </div>
        <div
          style={{
            fontWeight: "600",
            fontSize: "20px",
            marginBottom: "16px",
            alignSelf: "center",
          }}
        >
          Add a payment method
        </div>
        <div
          style={{
            fontWeight: "300",
            fontSize: "20px",
          }}
        >
          In order to start using your Burq account,
        </div>
        <div
          style={{
            fontWeight: "300",
            fontSize: "20px",
          }}
        >
          you'll need to add a payment method.
        </div>
      </div>

      <Button
        onClick={() => {
          history("/v1/settings/payments", {
            state: { showPaymentElement: true },
          });
        }}
        externalClassName={styles.addPaymentButton}
      >
        Add Payment
      </Button>
    </div>
  );
}

export default PaymentMethodsZeroState;
