import { createStore } from "aniuta";
import * as Sentry from "@sentry/react";
import axios from "axios";
import Cookies from "js-cookie";
import { useState } from "react";
import { utils } from "react-modern-calendar-datepicker";
import { toast } from "react-toastify";
import { baseUrl } from "../runTimeConst";

const useSingleItemStore = createStore({
  name: "useSingleItemStore",
  Store: () => {
    const [item, setitem] = useState();
    const [selectedDay, setSelectedDay] = useState(utils().getToday());
    const [orderTime, setOrderTime] = useState("");
    const [rerouteItem, setRerouteItem] = useState();
    const [isAdminReroute, setIsAdminReroute] = useState(false);
    const [isMerchantReroute, setIsMerchantReroute] = useState(false);

    const [rerouteResponse, setRerouteResponse] = useState();

    const [rerouteLoading, setRerouteLoading] = useState(false);

    const getRerouteQuote = async (rerouteItemId) => {
      try {
        const response = await axios.post(`${baseUrl}deliveries/${rerouteItemId}/reroute/quotes`);

        return response.data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
        return error?.response?.data;
      }
    };

    const rerouteDeliveryHandler = async (rerouteItemId, quoteId, info) => {
      setRerouteLoading(true);
      const providerSettings = {
        signature: !!info.provider_settings.signature,
        contactless: !!info.provider_settings.contactless,
      };
      if (info.provider_settings.undeliverable_action) {
        providerSettings.undeliverable_action = info.provider_settings.undeliverable_action;
      }
      if (info.provider_settings.age_verification) {
        providerSettings.recipient_minimum_age = info.provider_settings.recipient_minimum_age;
      }
      try {
        const response = await axios.post(
          `${baseUrl}deliveries/${rerouteItemId}/reroute/delivery`,
          {
            quote_id: quoteId,
            required_provider_settings: providerSettings,
            reroute_reason: info.reroute_reason,
          },
          { headers: { "x-burq-client": "client-dashboard" } }
        );
        return response.data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(
            error.response.data.message === "Store does not have a payment method associated to it."
              ? "Please select a default payment method for the store to create the delivery using this store."
              : error.response.data.message
          );
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setRerouteLoading(false);
      }
    };

    const createRerouteInfo = async (info, items, isSandbox, id, storeId) => {
      setRerouteLoading(true);
      let body = {};

      const providerSettings = {
        signature: !!info.provider_settings.signature,
        contactless: !!info.provider_settings.contactless,
      };
      if (info.provider_settings.undeliverable_action) {
        providerSettings.undeliverable_action = info.provider_settings.undeliverable_action;
      }

      if (info.provider_settings.age_verification) {
        providerSettings.recipient_minimum_age = info.provider_settings.recipient_minimum_age;
      }
      

      if (info.dropoff.length > 1) {
        body = {
          testMode: isSandbox,
          pickup_name: info.pickup[0].pickupName + " " + info.pickup[0].pickupLastName,
          pickup_address: info.pickup[0].pickup_address,
          pickup_unit: info.pickup[0].pickup_unit,

          pickup_phone_number: info.pickup[0].pickup_phone_number,
          pickup_notes: info.pickup[0].pickup_notes,
          pickup_at: info.isPickupAt ? info.eta : "",
          items_description: "Things",
          // vehicle: info.vehicleType,
          items: items,
          initiate: info.initiate === "later" ? false : true,
          quote_id: info.quote_id,
          tip: info.tip * 100,
          dropoff: info.dropoff,
          order_value: info.order_value * 100,
          dropoff_at: !info.isPickupAt ? info.eta : "",
          store_id: storeId ?? null,
          external_order_ref: info.external_order_ref ?? "",
        };
      } else if (info.dropoff.length > 0 && info.dropoff.length < 2) {
        body = {
          testMode: isSandbox,
          pickup_name: !storeId ? info.pickup[0].pickup_name : "",
          pickup_address: !storeId ? info.pickup[0].pickup_address : "",
          pickup_unit: !storeId ? info.pickup[0].pickup_unit : "",

          pickup_phone_number: !storeId ? info.pickup[0].pickup_phone_number : "",
          pickup_notes: !storeId ? info.pickup[0].pickup_notes : "",
          pickup_at: !storeId ? (info.isPickupAt ? info.eta : "") : "",
          items_description: "Things",
          items: items,
          initiate: info.initiate === "later" ? false : true,
          quote_id: info.quote_id,
          tip: info.tip * 100,
          // vehicle: info.vehicleType,
          dropoff_at: !info.isPickupAt ? info.eta : "",
          dropoff_address: info.dropoff?.length > 0 ? info.dropoff[0].dropoff_address : "",
          dropoff_name: info.dropoff?.length > 0 ? info.dropoff[0].dropoff_name : "",
          dropoff_unit: info.dropoff?.length > 0 ? info.dropoff[0].dropoff_unit : "",

          dropoff_phone_number: info.dropoff?.length > 0 ? info.dropoff[0].dropoff_phone_number : "",
          dropoff_notes: info.dropoff?.length > 0 ? info.dropoff[0].dropoff_notes : "",
          order_value: info.order_value * 100,
          store_id: storeId ?? null,
          external_order_ref: info.external_order_ref ?? "",
          required_provider_settings: providerSettings,
          contains_alcohol: info.contains_alcohol,
        };
      }
      // console.log("info", body, storeId);

      // ! /api/

      try {
        const response = await axios.post(`${baseUrl}delivery/create/${id}`, body, {
          headers: { "x-burq-client": "client-dashboard" },
        });
        // console.log("REROUTE RESPONSE", response);
        setRerouteResponse(response);
        setRerouteLoading(false);
        return true;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
        setRerouteLoading(false);
        return false;
      }
    };

    return {
      item,
      setitem,
      selectedDay,
      setSelectedDay,
      orderTime,
      setOrderTime,
      rerouteItem,
      setRerouteItem,
      createRerouteInfo,
      rerouteResponse,
      rerouteLoading,
      setRerouteLoading,
      isAdminReroute,
      setIsAdminReroute,
      // NEW REROUTE
      getRerouteQuote,
      rerouteDeliveryHandler,
      isMerchantReroute,
      setIsMerchantReroute,
    };
  },
});

export default useSingleItemStore;
