import React from "react";
import SlideDrawer from "../shared/atoms/SlideDrawer";
import ProviderIntegrationDetails from "./ProviderIntegrationDetails";
import { useProvidersManagementStore } from "../../store";

const ProviderSettingsDrawer = () => {
  const { selectedProvider, setSelectedProvider, isMissingProviderRequestFormOpen, setIsMissingProviderRequestFormOpen } =
    useProvidersManagementStore();

  const closeHandler = () => {
    setSelectedProvider(null);
    setIsMissingProviderRequestFormOpen(false);
  }

  return (
    <SlideDrawer isOpen={!!selectedProvider || isMissingProviderRequestFormOpen} onClose={closeHandler}>
      <ProviderIntegrationDetails
        provider={selectedProvider}
        onClose={closeHandler}
      />
    </SlideDrawer>
  );
};

export default ProviderSettingsDrawer;
