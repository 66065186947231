import React from "react";
import styles from "./styles.module.css";
import momenttz from "moment-timezone";

import Hyperlink from "../../shared/atoms/Hyperlink";
import StatusBadgeReusable from "../../shared/common/StatusBadgeReusable";
import { iconHandler } from "../../Admin/DeliveriesForAdmin/utils";
import { LiaUserSolid } from "react-icons/lia";

import moment from "moment";

import classNames from "classnames";
import { PhoneNumbersOnSideDrawer } from "./PhoneNumbersOnSideDrawer";
import {
  CalendarIcon,
  CarIcon,
  DriverIcon,
  PackageIcon,
  ProviderIcon,
  RequirementIcon,
  StatusIcon,
  WineGlassSvg,
  MinAgeIcon,
} from "../Svgs";

import { Actions } from "../HeaderForDrawer/Actions";
import { Loading } from "../../../assets/svgs/Svgs";
import Button from "../../shared/atoms/Button";
import { useFilter, useMyStore, useStoreAndChild } from "../../../store";
import { badgesForDeliveryStatuses, getStatusForBadge, mapNotRerouteReason } from "../../../utils/helpers";

const DetailedInfo = ({ item, setCancelVisible, setIsCreateIncidentOpened, getDeliveryInfo }) => {
  const format = "ddd, DD MMM YYYY, hh:mmA";
  const { requestDriverHandler, pageCount, deliveries, isDriverRequestLoading } = useMyStore();
  const { startDate, search } = useFilter();

  const { selectedStore } = useStoreAndChild();
  const returnDriverStatus = () => {
    if (item?.status === "delivery_created" || item?.status === "driver_not_assigned") {
      return "Assigning Driver";
    } else return "Not available";
  };

  const computedStatus = getStatusForBadge({ deliveryStatus: item?.status, rerouteStatus: item?.reroute_status });

  return (
    <div className={styles.orderDetails}>
      <div className="w-full flex items-center justify-between mb-6">
        <div className={styles.summaryTitle}>Summary</div>

        <div className="flex items-center">
          {item?.status === "request" &&
            (isDriverRequestLoading ? (
              <div className={classNames(styles.container, "w-full h-full flex items-center justify-center")}>
                <Loading color={"#0062ff"} className="h-10 w-10 animate-spin" />
              </div>
            ) : (
              <Button
                onClick={() => {
                  requestDriverHandler(item.id).then((res) => {
                    if (res) {
                      getDeliveryInfo(item.id);
                      deliveries(search, pageCount, startDate, selectedStore?.id);
                    }
                  });
                }}
                variant="lightPrimary"
                text="Request Driver"
              />
            ))}
          <Actions
            setCancelVisible={setCancelVisible}
            item={item}
            setIsCreateIncidentOpened={setIsCreateIncidentOpened}
            getDeliveryInfo={getDeliveryInfo}
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.icon}>
          <ProviderIcon />
        </div>

        <div className={styles.label}>Provider</div>

        <img src={item.provider_logo_url} alt="" className={styles.providerLogo} />

        <div className={classNames(styles.value, "capitalize")}>{item?.service}</div>
      </div>
      <div className={styles.row}>
        <div className={styles.icon}>
          <DriverIcon />
        </div>

        <div className={styles.label}>Driver</div>
        {item?.courier_img ? (
          <img src={item.courier_img} alt="" className={styles.providerLogo} />
        ) : (
          item.courier_name && (
            <div className={styles.avatarCircle}>
              <LiaUserSolid color="white" size={30} />
            </div>
          )
        )}

        <div className={styles.value}>{item.courier_name ?? returnDriverStatus()}</div>
        {item.courier_vehicle_type && (
          <div className={styles.carType}>
            <span className={styles.carIconCircle}>
              <CarIcon />
            </span>
            {item.courier_vehicle_type}
          </div>
        )}

        <PhoneNumbersOnSideDrawer item={item} />
      </div>

      <div className={styles.row}>
        <div className={styles.icon}>
          <StatusIcon />
        </div>
        <div className={styles.label}>Status</div>
        <div className={styles.valueDelivered}>
          {item?.status && (
            <StatusBadgeReusable
              leftIcon={iconHandler(item?.status, true)}
              hideIcon={true}
              status={computedStatus}
              badgesObject={badgesForDeliveryStatuses}
              showDefault={true}
              defaultType="neutral"
              externalClassName={styles.badge}
              extraIconClasses={styles.statusClass}
              uppercase
            />
          )}
          {item?.not_rerouted_reason && computedStatus === "canceled_not_reroutable" && (
            <div className={styles.notReroutedReason}>
              Reason: {mapNotRerouteReason[item?.not_rerouted_reason] || item?.not_rerouted_reason}
            </div>
          )}
        </div>

        <Hyperlink
          onClick={() => {
            window.open(`${process.env.REACT_APP_TRACKING_URL}/orders/track/${item.order_id}`, "_blank");
          }}
          text="Track your order"
        />
      </div>
      <div className={styles.row}>
        <div className={styles.smallerIcon}>
          <PackageIcon />
        </div>
        {/* <PiPackageLight className={styles.smallerIcon} /> */}

        <div className={styles.label}>Delivery type</div>
        <div className={styles.value}>
          {item?.dropoff_at ? "Scheduled Dropoff" : item?.pickup_at ? "Scheduled Pickup" : "ASAP"}
        </div>

        {/* {item.signature_requested && (
          <div className="ml-12">
            <Badge externalClassName="" label="Signature" type="warning" />
          </div>
        )} */}
      </div>
      <div className={styles.row}>
        <div className={styles.smallerIcon}>
          <CalendarIcon />
        </div>
        {/* <RxCalendar className={styles.smallerIcon} /> */}

        <div className={styles.label}>{item?.dropoff_at || item?.pickup_at ? "Scheduled time" : "Created at"}</div>
        <div className={styles.value}>
          {((!item?.dropoff_at && !item?.pickup_at) || item.status === "request") &&
            (moment(item?.created_at).format(format) === "Invalid date" ? "" : moment(item?.created_at).format(format))}

          {item?.dropoff_at &&
            item.status !== "request" &&
            (moment(item.dropoff_at).format(format) === "Invalid date" ? "" : moment(item.dropoff_at).format(format))}

          {item?.pickup_at &&
            item.status !== "request" &&
            (moment(item.pickup_at).format(format) === "Invalid date" ? "" : moment(item.pickup_at).format(format))}
          {", " + momenttz.tz(momenttz.tz.guess()).format("z")}
        </div>
      </div>
      {(item?.contactless_requested || item.signature_requested || item?.undeliverable_action_requested) && (
        <div className={styles.row}>
          <div className={styles.smallerIcon}>
            <RequirementIcon />
          </div>
          {/* <LuFileSignature className={styles.smallerIcon} /> */}

          <div className={styles.label}>Preference</div>
          <div className={styles.value}>
            {item.contactless_requested && item.undeliverable_action_requested
              ? "Proof of delivery / Return"
              : item.contactless_requested && !item.undeliverable_action_requested
              ? "Proof of delivery"
              : item.signature_requested && item.undeliverable_action_requested
              ? "Signature / Return"
              : item.undeliverable_action_requested
              ? "Return"
              : "Signature"}
          </div>
        </div>
      )}
      {item?.contains_alcohol && (
        <div className={styles.row}>
          <div className={styles.icon}>
            <WineGlassSvg />
          </div>

          <div className={styles.label}>Special Content</div>

          <div className={classNames(styles.value, "capitalize")}>Contains Alcohol</div>
        </div>
      )}
      {item?.recipient_minimum_age && (
        <div className={styles.row}>
          <div className={styles.icon}>
            <MinAgeIcon />
          </div>

          <div className={styles.label}>Minimum age</div>

          <div className={classNames(styles.value, "capitalize")}>{item.recipient_minimum_age} years</div>
        </div>
      )}
    </div>
  );
};

export default DetailedInfo;
