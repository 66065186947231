import classNames from "classnames";
import React from "react";
import styles from "./styles.module.css";

const TabButton = ({
  onClick,
  active = false,
  name,
  extraButtonClasses,
  extraBottomLineClasses,
  extraBottomLineClassesInActive,
  extraBottomLineClassesActive,
}) => {
  return (
    <button
      id="payments"
      className={classNames(
        `focus:outline-none flex flex-col`,
        styles.button,
        {
          [styles.textPrimary]: active,
        },
        extraButtonClasses
      )}
      type="button"
      onClick={onClick}
    >
      {name}
      <div
        className={classNames(
          styles.bottomLine,
          extraBottomLineClasses,
          {
            [styles.bottomLineActive]: active,
            [extraBottomLineClassesActive]: active,
            [extraBottomLineClassesInActive]: !active,
          },
        )}
      ></div>
    </button>
  );
};

export default TabButton;
