import React, { useState, useMemo, useEffect, useRef } from "react";
import { PercentDownSvg, PercentUpSvg, MenuSvg } from "../../assets/svgs/Svgs";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import { useAnalyticsStore, useMyStore, userDataStore, useTheme } from "../../store";
import ToolTip from "../Tooltip/Tooltip";
import { getPreviousMonthDate } from "../../utils/helpers";
import moment from "moment";

const initFormatedDate = getPreviousMonthDate({
  prevMonth: 0,
  isStart: true,
  format: "MMM DD",
});

const initPayload = {
  data: [],
  percent: "",
  percent_value: "",
  current_month: "",
  previous_month: "",
  current_month_value: initFormatedDate.monthStart,
  previous_month_value: moment().format("MMM DD"),
};

let page = 0;

const ChartTableComp = ({
  title,
  payload,
  style,
  isAmount,
  isFooter,
  isPercent,
  isAction,
  isSeeAll,
  isImage,
  tableContentStyleProp = {},
  handleTopLocations,
}) => {
  const { isdesktop, smfontsize, mdfontsize, xsfontsize, lgfontsize } = useDesktopOrLaptop();
  const { Colors } = useTheme();
  const [chartTable, setChartTable] = useState(initPayload);
  const { userId, isSandbox } = useMyStore();
  const { authInfo } = userDataStore();
  const { datePayload } = useAnalyticsStore();

  const styles = useMemo(() => {
    return {
      chartTableContainer: {
        display: "flex",
        border: `1px solid ${Colors.borderSecondary}`,
        borderRadius: "8px",
        padding: "20px",
        flexDirection: "column",
        marginBottom: "10px",
        height: "100%",
      },
      heading: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
      },
      headingTitle: {
        fontFamily: "Poppins",
        fontWeight: 600,
        fontSize: mdfontsize,
        color: Colors.fontDark,
        marginRight: "20px",
      },
      headingPercent: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline",
      },
      headingPercentText: {
        fontFamily: "Poppins",
        fontWeight: 600,
        fontSize: smfontsize,
        marginLeft: "5px",
        marginBottom: "0px",
        color: chartTable.percent === "up" ? Colors.percentUp : Colors.percentDown,
      },
      headingButton: {
        cursor: "pointer",
        height: "0px",
      },
      seeAllButton: {
        cursor: "pointer",
        color: Colors.bluePrimary,
        height: "0px",
      },
      monthContainer: {
        display: "flex",
        justifyContent: "space-between",
        borderBottom: `1px solid ${Colors.borderSecondary}`,
        paddingBottom: "10px",
      },
      monthText: {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: xsfontsize,
        color: Colors.chartMonth,
        marginBottom: "0px",
      },
      monthValueContainer: {
        padding: "10px 0px",
        display: "flex",
        justifyContent: "space-between",
      },
      monthValueCard: {
        border: `1px solid ${Colors.borderSecondary}`,
        borderRadius: "8px",
        padding: "10px",
        minWidth: "100px",
        maxWidth: "200px",
      },
      monthValue: {
        fontFamily: "Poppins",
        fontWeight: 600,
        fontSize: isdesktop ? lgfontsize : mdfontsize,
        color: Colors.bluePrimary,
        marginBottom: "5px",
      },
      tableContainer: {
        // width: isdesktop ? 420 : 380,
        height: isFooter ? "80%" : "100%",
        overflowY: "scroll",
        display: "flex",
        flexDirection: "column",
      },
      tableContent: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "4px 16px",
        borderRadius: "4px",
        minHeight: "40px",
      },
      tableContentEvenRow: {
        backgroundColor: Colors.tableRow,
      },
      tableItemLable: {
        display: "flex",
        alignItems: "center",
        width: "75%",
        overflowX: "hidden",
      },
      tableItemLableImage: {
        minWidth: "32px",
        height: "32px",
        width: "32px",
        background: "#9DA6B7",
        borderRadius: "100%",
        marginRight: "10px",
        overflow: "hidden",
      },

      tableContentProvier: {
        fontFamily: "Poppins",
        fontWeight: 500,
        fontSize: isdesktop ? smfontsize : xsfontsize,
        color: Colors.fontBlack,
        margin: "0px",
        textTransform: "capitalize",
        overflowX: "hidden",
        textOverflow: "ellipsis",
      },
      tableContentDelivery: {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: isdesktop ? smfontsize : xsfontsize,
        color: Colors.chartSecondLine,
        margin: "0",
      },
      noRecord: { margin: "0px", color: Colors.fontDark, fontSize: mdfontsize },
    };
  }, [Colors, isdesktop, chartTable]);

  useEffect(() => {
    setChartTable(payload);
  }, [payload]);
  const chartTableRef = useRef(null);
  const container = chartTableRef.current;

  const handleScroll = () => {
    const scrollTop = container.scrollTop;
    const scrollHeight = container.scrollHeight;
    const clientHeight = container.clientHeight;

    if (scrollTop + clientHeight >= scrollHeight) {
      // console.log("scrollled", scrollTop + clientHeight, scrollHeight);
      page = page + 1;
      // console.log("12312", page);
      handleTopLocations(page);
      // handleTopLocations(page, userId ?? authInfo?.merchantAccountId);
    }
  };
  useEffect(() => {
    page = 0;
  }, [userId, isSandbox, datePayload, userId, authInfo]);

  useEffect(() => {
    // console.log("notificationz", notifications?.data?.length);
    if (container && title === "Top Locations") {
      // Attach the scroll event listener to the container
      container.addEventListener("scroll", handleScroll);
    }

    // Clean up the event listener when the component unmounts
    return () => {
      if (container && title === "Top Locations") {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [chartTableRef.current, userId, payload, page]);

  return (
    <div style={{ ...styles.chartTableContainer, ...style }}>
      <div style={styles.heading}>
        <div style={styles.headingPercent}>
          <p style={styles.headingTitle}>{title}</p>
          {isPercent && chartTable.percent && (
            <>
              {chartTable.percent === "up" ? <PercentUpSvg /> : <PercentDownSvg />}
              <p style={styles.headingPercentText}>{chartTable.percent_value}%</p>
            </>
          )}
        </div>

        {isAction && (
          <button style={styles.headingButton}>
            <MenuSvg />
          </button>
        )}
        {isSeeAll && <button style={styles.seeAllButton}>See All</button>}
      </div>

      <div ref={chartTableRef} style={styles.tableContainer} className="kata">
        {chartTable?.data?.length ? (
          chartTable.data.map((provider, i) => (
            <div
              style={
                i % 2 !== 0
                  ? { ...styles.tableContent }
                  : {
                      ...styles.tableContent,
                      ...styles.tableContentEvenRow,
                      ...tableContentStyleProp,
                    }
              }
              key={i}
            >
              <div style={styles.tableItemLable}>
                {isImage && (
                  <div style={styles.tableItemLableImage}>
                    {provider.provider_logo_url && (
                      <img className={`object-cover h-full w-full`} src={provider.provider_logo_url} alt="" />
                    )}
                  </div>
                )}
                <p
                  style={styles.tableContentProvier}
                  className="kata"
                  data-tip
                  data-for={`chart-table-${provider.provider_name}`}
                >
                  {provider.provider_name}
                </p>
                {provider?.provider_name?.length > 40 && (
                  <ToolTip id={`chart-table-${provider.provider_name}`} text={provider.provider_name} />
                )}
              </div>
              <p style={styles.tableContentDelivery}>
                {provider.provider_deliveries} {provider.provider_deliveries > 1 ? "Deliveries" : "Delivery"}
              </p>
            </div>
          ))
        ) : (
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p style={styles.noRecord}>No record found</p>
          </div>
        )}
      </div>

      {isFooter && (
        <>
          <div style={styles.monthContainer}>
            <p style={styles.monthText}>{chartTable.current_month}</p>
            <p style={styles.monthText}>{chartTable.previous_month}</p>
          </div>

          <div style={styles.monthValueContainer}>
            <div style={styles.monthValueCard}>
              <p style={styles.monthValue}>{isAmount ? `$${chartTable.current_month_value}` : chartTable.current_month_value}</p>
              <p style={styles.monthText}>
                {/* {moment(chartTable.current_month).format("MMM YYYY")} */}
                This Month
              </p>
            </div>
            <div style={styles.monthValueCard}>
              <p style={{ ...styles.monthValue, color: Colors.chartMonth }}>
                {isAmount ? `$${chartTable.previous_month_value}` : chartTable.previous_month_value}
              </p>
              <p style={styles.monthText}>
                {/* {moment(chartTable.previous_month).format("MMM YYYY")} */}
                Previous Period
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ChartTableComp;
