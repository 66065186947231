import { useState } from "react";
import * as Sentry from "@sentry/react";
import { createStore } from "aniuta";
import { toast } from "react-toastify";
import { setPaymentsHistoryForMerchant } from "../utils/api/paymentHistory.";

const usePaymentHistory = createStore({
  name: "usePaymentHistory",
  Store: () => {
    const [paymentsHistoryData, setPaymentsHistoryData] = useState();
    const [isPaymentHistoryLoading, setIsPaymentHistoryLoading] = useState(false);

    const getPaymentsHistory = async (id, params) => {
      setIsPaymentHistoryLoading(true);
      try {
        const { data } = await setPaymentsHistoryForMerchant(id, params);
        setPaymentsHistoryData(data);
        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsPaymentHistoryLoading(false);
      }
    };

    return { getPaymentsHistory, paymentsHistoryData, isPaymentHistoryLoading };
  },
});

export default usePaymentHistory;
