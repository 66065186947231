export const statusesBadgesForStatements = {
  unpaid: { type: "error", label: "Unpaid" },
  processing: { type: "", label: "Payment processing" },
  paid: { type: "success", label: "Paid" },
  failed: { type: "error", label: "Failed" },
  payment_not_required: { type: "success", label: "Not required" },
  refunded: { type: "", label: "Refunded" },
  refunding: { type: "", label: "Refunding" },
  disputed: { type: "error", label: "Disputed" },
};

export const findLatestBillingPeriod = (data) => {
  if (!data?.length) return null;
  return data.reduce((result, currentPeriod) => {
    const selectedDate = result?.end_at
      ? new Date(result?.end_at).getTime()
      : 0;
    const nextDate = currentPeriod?.end_at
      ? new Date(currentPeriod?.end_at).getTime()
      : 0;
    return nextDate - selectedDate > 0 ? currentPeriod : result;
  }, data[0]);
};

export const paymentErrorCodes = [
  "missing_or_unverified_payment_method",
  "payment_error",
  "skipped_due_to_declined_pm",
];

export const paymentStatuses = [
  { label: "Completed", value: "completed"},
  { label: "Processing", value: "processing" },
  { label: "Payment Needed", value: "payment_needed" }
];
