import React from "react";
import { numberWithCommas } from "../../../../../utils/helpers";
import classNames from "classnames";
import { useTheme } from "../../../../../store";
import styles from "../styles/InvoiceStoresDetails/invoiceStoreItem.module.css";

const InvoiceStoreItem = ({ item }) => {
  const { Colors } = useTheme();

  return (
    <div className={classNames("w-full", styles.itemCont)}>
      <div
        className={classNames(
          "grid grid-cols-12 gap-1 w-full pb-2",
          styles.itemGridCont
        )}
      >
        <div
          style={{ color: Colors.fontGreyLight, width: "90%" }}
          className=" flex flex-col items-start col-start-1 col-span-5"
        >
          <div
            className="text-left"
            style={{
              color: Colors.fontDark,
              width: "100%",
              fontSize: "14px",
              fontWeight: "400",
              whiteSpace: "nowrap",
            }}
          >
            {item.name}
          </div>
          <div
            style={{
              fontSize: "14px",
              whiteSpace: "nowrap",
              color: Colors.fontGreyLight,
              width: "100%",
              textAlign: "left",
            }}
          >
            {item.id}
          </div>
        </div>
        <div
          style={{
            fontSize: "14px",
            fontWeight: "400",
            color: Colors.fontDark,
          }}
          className="col-start-6 col-span-4 "
        >
          $
          {item.balance
            ? numberWithCommas((item?.balance / 100).toFixed(2))
            : "0"}
        </div>
        <div
          style={{
            fontSize: "14px",
            fontWeight: "400",
            color: Colors.fontDark,
          }}
          className="col-start-10 col-span-3 "
        >
          {item.transactionCount}
        </div>
      </div>
    </div>
  );
};

export default InvoiceStoreItem;
