import classNames from "classnames";
import React, { useState } from "react";
import styles from "./styles.module.css";

export const ReasonForIncident = ({
  reason,
  reasonActive,
  setReasonActive,
}) => {
  return (
    <div
      onClick={() => {
        setReasonActive(reason.id);
      }}
      className={classNames("flex items-center cursor-pointer my-8")}
    >
      <div
        className={classNames(styles.circle, {
          [styles.activeBorderColor]: reason.id === reasonActive,
        })}
      >
        <div
          className={classNames(styles.smallCircle, {
            [styles.activeBgColor]: reason.id === reasonActive,
          })}
        ></div>
      </div>
      <div className={classNames(styles.reason)}>{reason.reason}</div>
    </div>
  );
};
