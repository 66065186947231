import { createStore } from "aniuta";
import { useState } from "react";
import { giveFeedback } from "../utils/api/ratings";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";

const useRatings = createStore({
  name: "useRatings",
  Store: () => {
    const [isFeedbackLoading, setIsFeedbackLoading] = useState(false);

    const giveFeedbackHandler = async (id, payload) => {
      setIsFeedbackLoading(true);
      try {
        const { data } = await giveFeedback(id, payload);
        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsFeedbackLoading(false);
      }
    };
    return {
      isFeedbackLoading,
      setIsFeedbackLoading,
      giveFeedbackHandler,
    };
  },
});

export default useRatings;
