import React from "react";
import { useDesktopOrLaptop } from "../services/useDesktopOrLaptop";
import { useTheme } from "../store";

const PagingWithNextAndPrevButtons = ({
  allPages,
  setPage,
  page,
  result,
  itemsPerPage,
  areAllUsers,
}) => {
  const { mdfontsize } = useDesktopOrLaptop();

  const { Colors } = useTheme();
  const styles = {
    button: {
      borderColor: Colors.borderPrimary,
      borderRadius: "4px",
      borderWidth: "1px",
      padding: "4px 12px",
      outline: "none",
      marginLeft: "10px",
      // color: "white",
      fontFamily: "Poppins",
      fontWeight: "500",
    },
  };
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px 30px",
      }}
    >
      {allPages ? (
        <div
          style={{
            fontFamily: "Poppins",
            fontWeight: "500",
            fontSize: mdfontsize,
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ marginLeft: "8px" }}></div>
        </div>
      ) : (
        <div></div>
      )}
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          id={`pageCountId${page + 1}`}
          style={{
            fontFamily: "Poppins",
            fontWeight: "500",
            fontSize: mdfontsize,
            marginLeft: "10px",
            color: Colors.fontDark,
          }}
        >
          Page: {page + 1}
        </div>
        <button
          id="previousButtonId"
          onClick={() => {
            if (page > 0) {
              setPage(page - 1);
            }
            // window.scrollTo(0, 0);
          }}
          style={{
            ...styles.button,
            ...{ color: page === 0 ? Colors.fontGreyLight : Colors.fontDark },
          }}
        >
          Previous
        </button>

        <button
          id="nextButtonId"
          onClick={() => {
            setPage(page + 1);
            // window.scrollTo(0, 0);

            // if (allPages) {
            // if (allPages > page + 1) {
            //   setPage(page + 1);
            // }
            // } else {
            // if (result / itemsPerPage >= 1) {
            //   setPage(page + 1);
            // }
            // }
          }}
          style={{
            ...styles.button,
            ...{
              color:
                allPages === page + 1 || result / itemsPerPage < 1
                  ? Colors.fontGreyLight
                  : Colors.fontDark,
            },
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default PagingWithNextAndPrevButtons;
