import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import axios from "axios";
import { BiCheckbox, BiCheckboxChecked } from "react-icons/bi";
import { IoCloseSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { TbRoute } from "react-icons/tb";

import { useDesktopOrLaptop } from "../../../../services/useDesktopOrLaptop";
import { useAdmin, useFilter, useMyStore, useSingleItemStore, useTheme } from "../../../../store";
import { baseUrl } from "../../../../runTimeConst";
import CustomModal from "../../../CustomModal/CustomModal";
import SingleAddressForModal from "../../../SingleAddressForModal";
import DeliveryStatusTimeline from "../../../shared/DeliveryStatusTimeline";
import ImagesContainer from "../../../shared/ImagesContainer";
import SignatureContainer from "../../../shared/SignatureContainer";
import SingleItemForModal from "../SingleItemForModal";
import TabButton from "../../../shared/common/TabButton";
import DeliveryFailureLogs from "../../../DeliveriesAnalytics/DeliveriesListSection/DeliveryDetailsCollapsable/DeliveryFailureLogs";

import AmountDetails from "../../../DeliveriesAnalytics/DeliveriesListSection/DeliveryDetailsCollapsable/DeliverySummary/AmountDetails";
import { DriverNumbers } from "./DriverNumbers";
import { mapUndeliverableActions } from "../../utils";
import { mapRerouteIntentActionReasons, mapRerouteIntentActions, mapRerouteIntentStatus } from "../../RerouteRecommendation/utils";

const statuses = [
  { back: "delivered", front: "Delivered" },
  { back: "driver_assigned", front: "Driver Assigned" },
  { back: "driver_not_assigned", front: "Assigning Driver" },
  { back: "enroute_pickup", front: "Enroute to Pick-up" },
  { back: "delivery_created", front: "Delivery Created" },
  { back: "arrived_at_pickup", front: "Arrived at Pick-up" },
  { back: "pickup_complete", front: "Pick-up Complete" },
  { back: "enroute_dropoff", front: "Enroute to Drop-off" },
  { back: "arrived_at_dropoff", front: "Arrived at Drop-off" },
  { back: "dropoff_complete", front: "Drop off Completed" },
  { back: "contacting_provider", front: "Contacting Provider" },
  { back: "scheduled", front: "Scheduled" },
  { back: "enroute_to_return", front: "Enroute to Return" },
  { back: "provider_canceled", front: "Provider Canceled" },
  // { back: "customer_canceled", front: "Merchant Canceled" },
  // { back: "burq_canceled", front: "Burq Canceled" },
  { back: "expired", front: "Expired" },
  // { back: "failed", front: "Failed" },
  { back: "disputed", front: "Disputed" },
  { back: "returned", front: "Returned" },
];

function DetailsContent({
  setIsCalendarVisible,
  delivery_id,
  date,
  name,
  setDetailsVisible,
  courierName,

  item,
  isAdmin,
  statusSocket,
  statusesHistory,
  onClose,
}) {
  const { isdesktop, smfontsize, mdfontsize } = useDesktopOrLaptop();

  const { Colors, themeChanger } = useTheme();
  const { getAdminDeliveries } = useAdmin();

  const { setRerouteItem, setIsAdminReroute } = useSingleItemStore();

  const history = useNavigate();

  const styles = useMemo(() => {
    return {
      header: {
        width: "100%",
        padding: "0px 50px 0px 50px",
        backgroundColor: Colors.white,
        borderRadius: "8px",
        zIndex: 100,
      },
      buttons: {
        fontFamily: "Poppins",
        fontWeight: "600",
        justifyContent: "center",
        alignItems: "center",
      },
      bottomLine: {
        width: "80px",
        height: "4px",
        borderBottomLeftRadius: "4px",
        borderBottomRightRadius: "4px",
      },
      tabContainer: {
        height: "100%",
        marginTop: "20px",
        padding: "0px 50px 0px 50px",
        overflowY: "scroll",
        width: "100%",
      },
    };
  }, [themeChanger]);

  const { isSandbox } = useMyStore();
  const { search, startDate, selectedProvider, filterArray } = useFilter();
  const [userEmail, setUserEmail] = useState();
  const [usersMetaData, setUsersMetaData] = useState();
  const [activePage, setActivePage] = useState("addresses");

  const [chosenStatus, setChosenStatus] = useState();

  const xsFontSize = isdesktop ? "12px" : "18px";
  const getUserEmailHandler = async () => {
    try {
      const response = await axios.get(`${baseUrl}merchant_accounts/${item.account_id}`);
      setUsersMetaData(response.data);
      setUserEmail(response.data.email);
    } catch (err) {
      return err;
    }
  };

  const changeStatusHandler = async (id, status) => {
    try {
      const response = await axios.patch(`${baseUrl}delivery/delivery/${id}?status=${status}`);
      console.log("Status Response", response.data.email);
    } catch (err) {
      console.log("err", err);
      return err;
    }
  };
  useEffect(() => {
    if (isAdmin) {
      getUserEmailHandler();
    }
  }, []);
  return (
    <div
      style={{
        fontFamily: "Poppins",
        fontSize: smfontsize,
        paddingBottom: isdesktop ? "20px" : "10px",
        backgroundColor: Colors.white,
      }}
      className="flex flex-col justify-center items-start rounded-md w-full overflow-hidden "
    >
      <div
        style={{
          fontFamily: "Poppins",
          fontSize: "16px",
          color: Colors?.fontDark,
          fontWeight: "500",
          borderBottomWidth: "1px",
          borderColor: Colors.borderPrimary,
          backgroundColor: themeChanger ? "#F1f1f1" : Colors.bgListHeader,
          marginBottom: "20px",
        }}
        className="py-3 w-full text-center relative  "
      >
        Delivery Information
        <button
          // onClick={() => {
          //   setDetailsVisible(false);
          //   setIsCalendarVisible(true);
          // }}

          onClick={onClose}
          type="button"
          style={{ position: "absolute", top: "10px", right: "20px" }}
          className="material-icons outline-none z-50"
        >
          close
        </button>
      </div>
      <CustomModal
        extraPanelStyles={{ maxWidth: isdesktop ? "50%" : "80%" }}
        extraClasses={`${isdesktop ? "px-80" : "px-56"}`}
        isOpen={chosenStatus ? true : false}
        onClose={() => setChosenStatus()}
      >
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: "20px 20px",
            position: "relative",
            flexDirection: "column",
            backgroundColor: Colors.white,
            justifySelf: "center",
          }}
        >
          <button
            style={{ position: "absolute", right: "10px", top: "10px" }}
            id="bu"
            type="button"
            onClick={() => setChosenStatus()}
          >
            <IoCloseSharp color={Colors.fontDark} size={22} />
          </button>

          <div
            style={{
              fontSize: mdfontsize,
              color: Colors.fontDark,
              marginTop: "6px",
            }}
          >
            {chosenStatus?.front}
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
              marginTop: "30px",
            }}
          >
            <button
              type="button"
              onClick={async () => {
                await changeStatusHandler(item.id, chosenStatus?.back);
                await getAdminDeliveries({
                  test_mode: isSandbox,
                  provider_id: selectedProvider?.id ?? "",
                  statuses: filterArray?.join(",") ?? "",
                  start_date: startDate?.start ?? "",
                  end_date: startDate?.end ?? "",
                });
                onClose();
              }}
              style={{
                padding: "8px 14px",
                backgroundColor: Colors.bluePrimary,
                borderRadius: "8px",
                color: "white",
              }}
            >
              Submit
            </button>
            <button
              type="button"
              onClick={() => setChosenStatus()}
              style={{
                padding: "8px 14px",
                backgroundColor: Colors.redDark,
                borderRadius: "8px",
                color: "white",
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </CustomModal>

      <div className="flex items-center" style={styles.header}>
        <div style={{ marginBottom: "24px" }} className="flex flex-row items-center">
          <TabButton
            name={"Addresses"}
            active={activePage === "addresses"}
            onClick={() => {
              setActivePage("addresses");
            }}
          />
          <TabButton
            name={"Items"}
            active={activePage === "items"}
            extraButtonClasses={"ml-12"}
            onClick={() => {
              setActivePage("items");
            }}
          />
          {statusSocket === "failed" && (
            <TabButton
              name={"Logs"}
              active={activePage === "logs"}
              extraButtonClasses={"ml-12"}
              onClick={() => {
                setActivePage("logs");
              }}
            />
          )}
        </div>
      </div>
      {isAdmin && (
        <div className="flex items-center w-full">
          <div
            className="flex items-center justify-start w-full scrollbar"
            style={{
              padding: "0px 20px",
              overflowX: "scroll",
              width: "100%",
              margin: "0 2%",
            }}
          >
            <div
              style={{
                fontFamily: "Roboto",
                fontWeight: "500",
                color: Colors.fontDark,
              }}
            >
              Change Status:
            </div>
            {statuses.map((_status, index) => (
              <button
                id={`${_status.back}StatusesId`}
                type="button"
                onClick={async () => {
                  setChosenStatus(_status);
                }}
                key={index}
                className="flex items-center"
                style={{
                  fontFamily: "Roboto",
                  fontWeight: "500",
                  color: statusSocket === _status ? Colors.bluePrimary : Colors.fontGreyLight,
                  marginLeft: "12px",
                  fontSize: xsFontSize,
                }}
              >
                {item?.status === _status.back ? (
                  <BiCheckboxChecked size={22} color={Colors.bluePrimary} />
                ) : (
                  <BiCheckbox size={22} color={Colors.fontGreyLight} />
                )}
                {_status.front}
              </button>
            ))}
          </div>
        </div>
      )}
      {activePage === "addresses" ? (
        <>
          <div className="flex flex-col px-10 pb-10 mt-4 justify-start w-full">
            <div
              style={{
                fontFamily: "Poppins",
                fontSize: mdfontsize,
                color: Colors.fontGreyLight,
                fontWeight: "400",
              }}
            // className="text-gray-500"
            >
              ID: {delivery_id}
            </div>
            <div
              style={{
                fontFamily: "Poppins",
                fontSize: mdfontsize,
                color: Colors.fontGreyLight,
                fontWeight: "400",
              }}
            // className="text-gray-500 "
            >
              {moment(date).format("MMM D, YYYY, h:mma")}
            </div>
            <div className="flex w-full items-start justify-start mt-7 overflow-x-auto">
              <div style={{ marginRight: "20px", width: "50%" }}>
                {userEmail && (
                  <div
                    style={{
                      fontSize: smfontsize,
                      color: Colors.fontGreyLight,
                    }}
                    className="font-semibold "
                  >
                    User's Email: <span style={{ color: Colors.fontDark }}>{userEmail}</span>
                  </div>
                )}
                {usersMetaData && (
                  <>
                    <div
                      style={{
                        fontSize: smfontsize,
                        color: Colors.fontGreyLight,
                      }}
                      className="font-semibold mt-2"
                    >
                      User's Phone Number: <span style={{ color: Colors.fontDark }}>{usersMetaData?.phone_number}</span>
                    </div>
                    <div
                      style={{
                        fontSize: smfontsize,
                        color: Colors.fontGreyLight,
                      }}
                      className="font-semibold mt-2"
                    >
                      User's Business Name:
                      <span style={{ color: Colors.fontDark }}> {usersMetaData?.name}</span>
                    </div>
                    <div
                      style={{
                        fontSize: smfontsize,
                        color: Colors.fontGreyLight,
                      }}
                      className="font-semibold mt-2"
                    >
                      User's Business Website:
                      <span style={{ color: Colors.fontDark }}> {usersMetaData?.website}</span>
                    </div>
                    <div
                      style={{
                        fontSize: smfontsize,
                        color: Colors.fontGreyLight,
                      }}
                      className="font-semibold mt-2"
                    >
                      Scheduled Time {item?.dropoff_at ? "Dropoff" : "pickup"}:
                      <span style={{ color: Colors.fontDark }}>
                        {" "}
                        {item?.dropoff_at &&
                          (moment(item.dropoff_at).format("D MMM YYYY, hh:mmA") === "Invalid date"
                            ? ""
                            : moment(item.dropoff_at).format("D MMM YYYY, hh:mmA"))}
                        {item?.pickup_at &&
                          (moment(item.pickup_at).format("D MMM YYYY, hh:mmA") === "Invalid date"
                            ? ""
                            : moment(item.pickup_at).format("D MMM YYYY, hh:mmA"))}
                      </span>
                    </div>
                    <div
                      style={{
                        fontSize: smfontsize,
                        color: Colors.fontGreyLight,
                      }}
                      className="font-semibold mt-2"
                    >
                      Provider Customer support id:{" "}
                      <span style={{ color: Colors.fontDark }}>{item.provider_customer_support_id || " Not Available"}</span>
                    </div>
                    <div
                      style={{
                        fontSize: smfontsize,
                        color: Colors.fontGreyLight,
                      }}
                      className="font-semibold mt-2"
                    >
                      External Order Id:
                      <span style={{ color: Colors.fontDark }}>{item.external_order_ref || " Not Available"}</span>
                    </div>
                    {item?.reroute_child_delivery_id && (
                      <div
                        style={{
                          fontSize: smfontsize,
                          color: Colors.fontGreyLight,
                        }}
                        className="font-semibold mt-2"
                      >
                        Delivery rerouted. New ID:
                        <span style={{ color: Colors.fontDark, marginLeft: "5px" }}>{item.reroute_child_delivery_id}</span>
                      </div>
                    )}
                    {item?.reroute_parent_delivery_id && (
                      <div
                        style={{
                          fontSize: smfontsize,
                          color: Colors.fontGreyLight,
                        }}
                        className="font-semibold mt-2"
                      >
                        This is rerouted Delivery from Delivery ID:
                        <span style={{ color: Colors.fontDark, marginLeft: "5px" }}>{item.reroute_parent_delivery_id}</span>
                      </div>
                    )}
                  </>
                )}
              </div>
              <div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ color: Colors.fontDark, fontWeight: "bold" }}>Payment Status:</div>
                  <div style={{ marginLeft: "10px", color: Colors.fontDark }}>
                    {item?.payment_status ? item.payment_status : ""}
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ color: Colors.fontDark, fontWeight: "bold" }}>Order Value:</div>
                  <div style={{ marginLeft: "10px", color: Colors.fontDark }}>
                    {item.order_value == 0 ? "$ 0" : item.order_value ? "$" + item.order_value / 100 : ""}
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ color: Colors.fontDark, fontWeight: "bold" }}>Vehicle type:</div>
                  <div style={{ marginLeft: "10px", color: Colors.fontDark }}>{item.vehicle ? item.vehicle : ""}</div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ color: Colors.fontDark, fontWeight: "bold" }}>Provider Id:</div>
                  <div style={{ marginLeft: "10px", color: Colors.fontDark }}>{item.provider_id || ""}</div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ color: Colors.fontDark, fontWeight: "bold" }}>Provider Name:</div>
                  <div style={{ marginLeft: "10px", color: Colors.fontDark }}>{item.service || ""}</div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ color: Colors.fontDark, fontWeight: "bold" }}>Subsidized:</div>
                  <div style={{ marginLeft: "10px", color: Colors.fontDark }}>
                    ${item?.subsidized ? (item?.subsidized / 100).toFixed(2) : "0"}
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ color: Colors.fontDark, fontWeight: "bold" }}>upsell:</div>
                  <div style={{ marginLeft: "10px", color: Colors.fontDark }}>
                    ${item?.upsell ? (item?.upsell / 100).toFixed(2) : "0"}
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ color: Colors.fontDark, fontWeight: "bold" }}>Delivery type:</div>
                  <div style={{ marginLeft: "10px", color: Colors.fontDark }}>{item?.delivery_type || ""}</div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ color: Colors.fontDark, fontWeight: "bold" }}>Proof:</div>
                  <div style={{ marginLeft: "10px", color: Colors.fontDark }}>
                    {item?.signature_requested ? "Signature" : item?.contactless_requested ? "Contactless" : "No Preference"}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div style={{ color: Colors.fontDark, fontWeight: "bold" }}>Undeliverable Action:</div>
                  <div style={{ marginLeft: "10px", color: Colors.fontDark }}>
                    {item?.undeliverable_action_requested
                      ? mapUndeliverableActions[item.undeliverable_action_requested]
                      : "No Preference"}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "30px",
                  }}
                >
                  <div style={{ color: Colors.fontDark, fontWeight: "bold" }}>Minimum Age Requirement:</div>
                  <div style={{ marginLeft: "10px", color: Colors.fontDark }}>
                    {item?.recipient_minimum_age
                      ?? "Not applicable"}
                  </div>
                </div>

                {item.reroute_intent && item.reroute_intent.action_decided_at &&
                  (<div style={{ marginBottom: "30px" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ color: Colors.fontDark, fontWeight: "bold", fontSize: mdfontsize }}>Reroute Intent <span>{item.reroute_intent.id}</span></div>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ color: Colors.fontDark, fontWeight: "bold" }}>Evaluated Action:</div>
                      <div style={{ marginLeft: "10px", color: Colors.fontDark }}>{mapRerouteIntentActions[item.reroute_intent.action]}</div>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ color: Colors.fontDark, fontWeight: "bold" }}>Evaluated Action Reason:</div>
                      <div style={{ marginLeft: "10px", color: Colors.fontDark }}>{mapRerouteIntentActionReasons[item.reroute_intent.action_reason] || 'NA'}</div>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ color: Colors.fontDark, fontWeight: "bold" }}>Evaluated Action Explanation:</div>
                      <div style={{ marginLeft: "10px", color: Colors.fontDark }}>{item.reroute_intent.action_explanation || ""}</div>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ color: Colors.fontDark, fontWeight: "bold" }}>Evaluation time:</div>
                      <div style={{ marginLeft: "10px", color: Colors.fontDark }}>{item.reroute_intent.action_decided_at ? moment(item.reroute_intent.action_decided_at).format("D MMM YYYY, hh:mmA") : 'NA'}</div>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ color: Colors.fontDark, fontWeight: "bold" }}>Intent Status:</div>
                      <div style={{ marginLeft: "10px", color: Colors.fontDark }}>{mapRerouteIntentStatus[item.reroute_intent.status] || 'NA'}</div>
                    </div>
                    {item.reroute_intent.error_message &&
                      (<div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ color: Colors.fontDark, fontWeight: "bold" }}>Failure Reason:</div>
                        <div style={{ marginLeft: "10px", color: Colors.fontDark }}>{`Intent failed with error: ${item.reroute_intent.error_message}`}</div>
                      </div>)}
                  </div>)
                }

                {item?.cancellation_reason && (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "start",
                      alignItems: "start",
                      // height: "100%",
                    }}
                  >
                    <div
                      style={{
                        fontFamily: "Poppins",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: mdfontsize,
                        lineHeight: "18px",
                        color: Colors.fontDark,
                        width: "100%",
                        backgroundColor: Colors.whiteBlue,
                        height: "31px",
                        alignItems: "center",
                        display: "flex",
                        borderRadius: "5px 5px 0px 0px",
                        paddingLeft: "12px",
                      }}
                    >
                      Cancellation Reason
                    </div>
                    <div
                      className="kata"
                      style={{
                        width: "100%",
                        backgroundColor: Colors.whitestBlue,
                        padding: isdesktop ? "30px" : "50px",
                        maxHeight: "530px",
                        overflowY: "scroll",
                      }}
                    >
                      {item.cancellation_reason || ""}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div
              className="kata"
              style={{
                width: "100%",
                height: "100%",
                margin: "20px 0px 0px 0px",
                padding: "0px 0px 0px 0px",
                overflowY: "scroll",
              }}
            >
              <SingleAddressForModal
                index={0}
                styles={styles}
                address={item.pickup_address}
                bussinessNmae="Burq"
                name={name}
                note={item?.pickup_notes}
                phoneNumber={item?.pickup_phone_number}
                smfontsize={smfontsize}
                mdfontsize={mdfontsize}
                unit={item.pickup_unit}
              />

              {item.dropoffs?.map((dropoff, index) => {
                return (
                  <SingleAddressForModal
                    length={item.dropoffs.length}
                    key={index}
                    index={index + 1}
                    styles={styles}
                    address={dropoff.dropoff_address}
                    bussinessNmae="Burq"
                    name={dropoff.dropoff_name}
                    note={dropoff.dropoff_notes}
                    phoneNumber={dropoff.dropoff_phone_number}
                    smfontsize={smfontsize}
                    mdfontsize={mdfontsize}
                    unit={item.dropoff_unit}
                  />
                );
              })}
            </div>

            {statusesHistory?.length > 0 && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                  alignItems: "start",
                  // height: "100%",
                }}
              >
                <div
                  style={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: mdfontsize,
                    lineHeight: "18px",
                    color: Colors.fontDark,
                    width: "100%",
                    backgroundColor: Colors.whiteBlue,
                    height: "31px",
                    alignItems: "center",
                    display: "flex",
                    borderRadius: "5px 5px 0px 0px",
                    paddingLeft: "12px",
                  }}
                >
                  Statuses
                </div>
                <DeliveryStatusTimeline onAdmin status={statusSocket} statusesHystory={statusesHistory} />
              </div>
            )}

            <div className="w-full mt-4 ">
              {item?.proof_of_delivery?.length > 0 && (
                <ImagesContainer smfontsize={smfontsize} images={item.proof_of_delivery} isdesktop={isdesktop} id={item.id} />
              )}
              {item?.signature && <SignatureContainer image={item.signature} id={item.id} />}
            </div>
            <div className="w-full flex items-start justify-between">
              <div>
                <div className="flex flex-col w-full">
                  <div
                    style={{
                      fontFamily: "Poppins",
                      fontSize: mdfontsize,
                      color: Colors.fontGreyLight,
                      fontWeight: "400",
                    }}
                    className=" capitalize"
                  >
                    Driver
                  </div>
                  <div style={{ fontSize: smfontsize, color: Colors.fontDark }} className="font-semibold  capitalize">
                    {courierName || "Not available"}
                  </div>
                </div>
                <DriverNumbers item={item} />

                <div
                  style={{
                    fontFamily: "Poppins",
                    fontSize: mdfontsize,
                    color: Colors.fontGreyLight,
                    fontWeight: "400",
                  }}
                  className=" text-xs capitalize mt-7 relative"
                >
                  Tracking
                </div>

                <a target="_blank" rel="noreferrer" href={`${process.env.REACT_APP_TRACKING_URL}/orders/track/${item.order_id}`}>
                  <div
                    style={{ fontSize: smfontsize, width: "100px" }}
                    className=" mt-2 w-20  text-blue-500 items-start justify-center"
                  >
                    See Map
                  </div>
                </a>
              </div>

              <div className="w-full flex flex-col items-end ">
                <div className="w-1/2 -mt-4">
                  <AmountDetails item={item} />
                </div>
              </div>
            </div>
          </div>

          <div className="w-full flex items-center justify-end px-5 mt-8">
            <button
              style={{
                fontSize: mdfontsize,
                fontWeight: "500",
                padding: isdesktop ? "8px 20px" : "18px 24px",
                backgroundColor: Colors.bluePrimary,
                color: Colors.white,
                borderRadius: "10px",
              }}
              onClick={() => {
                setRerouteItem(item);
                setIsAdminReroute(true);
                history(`/reroute/${item?.account_id}`);
                // setAllTimeVisible(false);
              }}
              className={`focus:outline-none px-4 py-2.5 ${themeChanger ? "hover:bg-gray-200 " : ""} flex items-center`}
            >
              <span
                style={{
                  marginRight: "8px",
                }}
              >
                <TbRoute color={Colors.white} size={22} />
              </span>
              Reroute
            </button>
          </div>
        </>
      ) : activePage === "items" ? (
        <div className="kata" style={styles.tabContainer}>
          {item?.items?.map((item, index) => {
            return <SingleItemForModal name={item.name} quantity={item.quantity} size={item.size} key={index} />;
          })}
        </div>
      ) : (
        activePage === "logs" && statusSocket === "failed" && <DeliveryFailureLogs deliveryId={item?.id} />
      )}
    </div>
  );
}

export default DetailsContent;
