import React, { useEffect, useState } from "react";
import styles from "../../styles/feedback.module.css";
import classNames from "classnames";

import {
  ChatIcon,
  StarGradientIcon,
  StarIcon,
} from "../../../../assets/svgs/Svgs";

const Feedback = ({ item }) => {
  const [myArray, setMyArray] = useState();

  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  useEffect(() => {
    if (item?.rating) {
      setMyArray(new Array(item.rating.rating_score).fill("star"));
    }

    // ReactTooltip.show();
  }, [item]);
  if (!item?.rating) {
    return <></>;
  }

  return (
    <div
      // data-tip
      // data-for={`feedback-${item?.id}`}
      style={{ width: "185px" }}
      className="flex flex-col mr-4 "
    >
      <div
        className={classNames(
          "w-full text-left",
          styles.textGrey,
          styles.font_14
        )}
      >
        Feedback
      </div>
      <div className="w-full flex flex-row items-center ">
        {myArray?.map((item, index) => {
          return (
            <div key={index} className="mr-2">
              <StarIcon />
              {/* <StarGradient /> */}
            </div>
          );
          {
            /* return <div className={classNames(styles.square)}></div>; */
          }
        })}
        <div className={styles.ratingNumber}>
          {item.rating.rating_score.toFixed(1)}
        </div>
        {item.rating?.comment && (
          <div
            onClick={(e) => {
              e.stopPropagation();
              setIsTooltipVisible(true);
            }}
            onMouseEnter={() => {
              setIsTooltipVisible(true);
            }}
            onMouseLeave={() => {
              setIsTooltipVisible(false);
            }}
            className={classNames(styles.ratingNumber, "relative p-1")}
          >
            {isTooltipVisible && (
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onMouseEnter={() => {
                  setIsTooltipVisible(true);
                }}
                className={classNames(
                  "absolute cursor-default",
                  styles.tooltip
                )}
              >
                <div
                  className={classNames(
                    "h-7 w-7 bg-white absolute",
                    styles.tooltipAngle
                  )}
                ></div>
                <div
                  className={classNames(
                    styles.toolTipContent,
                    "h-full w-full cursor-text"
                  )}
                >
                  {item.rating?.comment}
                </div>
              </button>
            )}
            <ChatIcon />
          </div>
        )}
      </div>
    </div>
  );
};

export default Feedback;
