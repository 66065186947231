import React, { useEffect, useState } from "react";
import moment from "moment";
import classNames from "classnames";
import styles from "../styles.module.css";
import Pagination from "../../../shared/Pagination";
import OrdersListLoading from "../../OrdersList/OrdersListLoading";
import { fileUploadData } from "../../utils.js";
import useOrdersCsvUpload from "../../../../store/useOrdersCsvUpload.js";
import userDataStore from "../../../../store/userDataStore.js";
import { useNavigate, useParams } from "react-router-dom";
import { IoIosCloseCircle } from "react-icons/io";

const OneBulkDetailsPage = () => {
  const { getCsvOrderCHildHandler } = useOrdersCsvUpload();
  const navigate = useNavigate();

  const [csvOrderChildData, setCsvOrderChildData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const { id } = useParams();
  const { authInfo } = userDataStore();

  const getPaginationCsvChildHandler = async ({
    starting_after,
    ending_before,
  } = {}) => {
    setIsLoading(true);

    const params = {
      starting_after: starting_after || null,
      ending_before: ending_before || null,
    };
    const data = await getCsvOrderCHildHandler(
      authInfo.merchantAccountId,
      id,
      params
    );
    setCsvOrderChildData(data);
    setIsLoading(false);
  };
  const firstLoadHandler = async () => {
    setIsLoading(true);
    const data = await getCsvOrderCHildHandler(
      authInfo.merchantAccountId,
      id,
      {}
    );
    setCsvOrderChildData(data);
    setIsLoading(false);
  };
  useEffect(() => {
    if (authInfo && id) {
      firstLoadHandler();
    }
  }, [authInfo, id]);

  const renderTableBody = () => {
    if (isLoading) {
      return <OrdersListLoading />;
    } else if (!csvOrderChildData?.data.length) {
      return (
        <tr className={styles.tableRow}>
          <td className="py-4 px-4 text-center" colSpan="5">
            No imorted orders yet
          </td>
        </tr>
      );
    } else
      return csvOrderChildData?.data?.map((item) => {
        // const error =
        //   item?.processing_error && JSON.parse(item.processing_error);

        return (
          <tr key={item.id} className={styles.tableRow}>
            <td className={classNames(styles.tableRowText, "w-72 pr-3")}>
              {/* {moment(item.created_at).format("DD/MM/YYYY")} */}
              {item?.order_row_number}
            </td>
            <td className={classNames(styles.tableRowText, styles.textLeft)}>
              {item?.processing_error && (
                <div className="flex">
                  <span className="mt-0.5 mr-2">
                    <IoIosCloseCircle size={20} color="#FC5A5A" />
                  </span>
                  <span>{item?.processing_error}</span>
                </div>
              )}
            </td>
          </tr>
        );
      });
  };
  return (
    <div className={classNames("overflow-x-auto", styles.container)}>
      <div
        onClick={() => {
          navigate(`/v1/orders/bulk`);
        }}
        className={styles.backButton}
      >{`< Back to history`}</div>
      <div className={styles.pageTitle}>Order Details</div>
      <div className={styles.pageDesc}>
        Your order had the following errors, kindly add in the correct details
        and try uploading again.
      </div>
      <div className={styles.tableContainer}>
        <table className={styles.tableOnDetailsPage}>
          <thead className={styles.tableHeader}>
            <tr>
              <th className={classNames(styles.columnTitle, "w-72")}>
                Row Number
              </th>
              <th className={classNames(styles.columnTitle, styles.textLeft)}>
                Status
              </th>
            </tr>
          </thead>

          <tbody className={classNames(styles.tableBody, "kata")}>
            {renderTableBody()}
          </tbody>
          <tfoot className={styles.tableFooter}>
            <tr className="flex items-center justify-end pr-28 mt-4">
              <td colSpan="4">
                <Pagination
                  data={csvOrderChildData}
                  onGetPaginationData={getPaginationCsvChildHandler}
                  dependencies={[]}
                />
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default OneBulkDetailsPage;
