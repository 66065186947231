import React from "react";
import { useTheme, useQuoteWorkflow, useStoreAndChild } from "../../../../store";
import {
  BurqFeeSvg,
  HideQuoteSvg,
  MultipleQuoteIcon,
  RadiusLimitSvg,
  SubsidizeSvg,
  UpsellSvg,
} from "../../../../assets/svgs/Svgs";
import { useDesktopOrLaptop } from "../../../../services/useDesktopOrLaptop";
import { useFlag } from "@unleash/proxy-client-react";
import { QUOTE_ITEMS } from "../utils";
import { PreferenceIcon, VehicleTypeIcon } from "../../OrderWorkflow/OrderWorkflowSvgs";
import { LuRepeat2 } from "react-icons/lu";

const QuoteItems = () => {
  const isDeliveryPreferencesFFEnabled = useFlag("deliveryPreferencesFF");
  const isRerouteFFEnabled = useFlag("rerouteWorkflow");

  const { Colors } = useTheme();
  const { isdesktop, xsfontsize } = useDesktopOrLaptop();
  const { selectedStore } = useStoreAndChild();
  const {
    //  quoteWorkflowStatuses,
    selectedQuoteItem,
    setSelectedQuoteItem,
  } = useQuoteWorkflow();

  return (
    <div
      style={{
        width: "100%",
        flexWrap: "wrap",
        display: "flex",
        justifyContent: "space-between",
        marginTop: "20px",
      }}
    >
      {QUOTE_ITEMS?.map((item, index) => {
        if (item.id === 4 && selectedStore) {
          return <></>;
        }
        if (item.id === 8 && !isDeliveryPreferencesFFEnabled) {
          return <></>;
        }
        if ((item.id === 7 || item.id === 6) && !isRerouteFFEnabled) {
          return null;
        }
        return (
          <button
            key={item.key}
            onClick={() => {
              setSelectedQuoteItem(item.key);
            }}
            type="button"
            style={{
              width: "46.5%",
              height: isdesktop ? "100px" : "110px",
              fontSize: xsfontsize,
              marginBottom: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              borderRadius: "10px",
              borderWidth: "1px",
              borderColor: selectedQuoteItem === item.key ? Colors.bluePrimary : Colors.borderSecondary,
              backgroundColor: Colors.detailsBg,
            }}
          >
            {item.id === 0 ? (
              <SubsidizeSvg />
            ) : item.id === 1 ? (
              <UpsellSvg />
            ) : item.id === 2 ? (
              <RadiusLimitSvg />
            ) : item.id === 3 ? (
              <HideQuoteSvg />
            ) : item.id === 4 ? (
              <MultipleQuoteIcon />
            ) : item.id === 5 ? (
              <BurqFeeSvg />
            ) : item.id === 6 ? (
              <PreferenceIcon />
            ) : item.id === 7 ? (
              <LuRepeat2 size={28} color={Colors.bluePrimary} />
            ) : (
              item.id === 8 && <VehicleTypeIcon />
            )}
            <div style={{ color: Colors.fontDark, marginTop: "10px" }}>{item.key}</div>
          </button>
        );
      })}
    </div>
  );
};

export default QuoteItems;
