import { useState } from "react";
import { createStore } from "aniuta";
import * as Sentry from "@sentry/react";
import { toast } from "react-toastify";
import {
  getInvoice,
  getInvoicePDFLink,
  getInvoices,
  getInvoicesStores,
  getTransactions,
  invoiceInitiatePayment,
  invoicesCSVExport,
  getStripePaymentAccount,
} from "../utils/api/invoice";

const useInvoices = createStore({
  name: "useInvoices",
  Store: () => {
    const [selectedInvoice, setSelectedInvoice] = useState();
    const [filterStore, setFilterStore] = useState();
    const [excludeStoreTransactions, setExcludeStoreTransactions] = useState(false);
    const [paymentStatusfilter, setPaymentStatusfilter] = useState({ label: "Payment Needed", value: "payment_needed" });

    const [invoices, setInvoices] = useState();
    const [isInvoicesLoading, setIsInvoicesLoading] = useState(false);

    const getInvoicesWorker = async (id, params) => {
      setIsInvoicesLoading(true);
      try {
        const { data } = await getInvoices(id, {
          starting_after: null,
          ending_before: null,
          limit: 30,
          ...params,
        });
        setInvoices(data);
        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsInvoicesLoading(false);
      }
    };

    const [isInvoicesStoresLoading, setIsInvoicesStoresLoading] = useState(false);
    const [invoiceStores, setInvoiceStores] = useState([]);

    const getInvoicesStoresWorker = async (id) => {
      setIsInvoicesStoresLoading(true);
      try {
        const { data } = await getInvoicesStores(id);
        setInvoiceStores(data);
        return data;
      } catch (error) {
        setInvoiceStores([]);
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsInvoicesStoresLoading(false);
      }
    };

    const [invoice, setInvoice] = useState();
    const [isInvoiceLoading, setIsInvoiceLoading] = useState(false);

    const getInvoiceWorker = async (id) => {
      setIsInvoiceLoading(true);
      try {
        const { data } = await getInvoice(id);
        setInvoice(data);
        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsInvoiceLoading(false);
      }
    };

    const [isTransactionsLoading, setIsTransactionsLoading] = useState(false);
    const [transactions, setTransactions] = useState();

    const getTransactionsWorker = async (id, params) => {
      setIsTransactionsLoading(true);
      try {
        const { data } = await getTransactions(id, {
          starting_after: null,
          ending_before: null,
          limit: 30,
          ...params,
        });
        setTransactions(data);
        return data;
      } catch (error) {
        setTransactions();
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsTransactionsLoading(false);
      }
    };

    const invoiceInitiatePaymentWorker = async (id, cb) => {
      try {
        const { data } = await invoiceInitiatePayment(id);
        toast.success("Thank you for initiating the payment.");
        cb(null, data);
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
        cb(error, null);
      }
    };

    const [isInvoiceCSVLoading, setIsInvoiceCSVLoading] = useState(false);

    const invoicesCSVExportWorker = async (id, params) => {
      try {
        const { data } = await invoicesCSVExport(id, params);
        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
        return "errror";
      } finally {
        setIsInvoiceCSVLoading(false);
      }
    };

    const [isInvoicePDFLinkLoading, setIsInvoicePDFLinkLoading] =
      useState(false);

    const getInvoicePDFLinkWorker = async (id) => {
      setIsInvoicePDFLinkLoading(true);
      try {
        const { data } = await getInvoicePDFLink(id);
        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
        return "errror";
      } finally {
        setIsInvoicePDFLinkLoading(false);
      }
    };

    const [isBankAccountDetailsLoading, setIsBankAccountDetailsLoading] =
      useState(false);
    const [bankAccountDetails, setBankAccountDetails] = useState();

    const getStripePaymentAccountWorker = async (id) => {
      setIsBankAccountDetailsLoading(true);
      try {
        const { data } = await getStripePaymentAccount(id);
        setBankAccountDetails(data);
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsBankAccountDetailsLoading(false);
      }
    };

    return {
      selectedInvoice,
      setSelectedInvoice,

      invoices,
      getInvoicesWorker,
      isInvoicesLoading,

      transactions,
      setTransactions,
      getTransactionsWorker,
      isTransactionsLoading,

      invoice,
      getInvoiceWorker,
      isInvoiceLoading,

      invoiceInitiatePaymentWorker,

      isInvoiceCSVLoading,
      setIsInvoiceCSVLoading,
      invoicesCSVExportWorker,

      isInvoicePDFLinkLoading,
      getInvoicePDFLinkWorker,

      invoiceStores,
      setInvoiceStores,
      isInvoicesStoresLoading,
      getInvoicesStoresWorker,

      filterStore,
      setFilterStore,
      excludeStoreTransactions,
      setExcludeStoreTransactions,

      paymentStatusfilter,
      setPaymentStatusfilter,

      bankAccountDetails,
      isBankAccountDetailsLoading,
      getStripePaymentAccountWorker,
    };
  },
});

export default useInvoices;
