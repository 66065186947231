import React from "react";
import Cookies from "js-cookie";
import { VscEdit, VscTrash } from "react-icons/vsc";

import { useTheme } from "../../../store";
import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";
import useWebhooks from "../../../store/useWebhooks";

function WebhookItem({
  url,
  itemId,
  id,
  index,
  deleteWebhook,
  setCreateWebhookVisible,
  item,
}) {
  const { Colors } = useTheme();
  const { smfontsize } = useDesktopOrLaptop();
  const { setWebhookForUpdate } = useWebhooks();

  return (
    <div
      style={{
        paddingLeft: "16px",
        borderRadius: "8px",
        borderWidth: "1px",
        paddingTop: "10px",
        paddingBottom: "10px",
        marginBottom: "10px",
        borderColor: Colors.borderPrimary,
      }}
      className="flex w-1/2  justify-between items-center  "
    >
      <div className="flex">
        <div
          id={id + index}
          style={{
            fontFamily: "Poppins",
            fontWeight: "500",
            color: Colors.fontDark,
            fontSize: smfontsize,
          }}
        >
          {url}
        </div>
      </div>
      <div className="relative">
        <button
          className="pr-2 pt-1 focus:outline-none"
          onClick={() => {
            setCreateWebhookVisible(true);
            setWebhookForUpdate(item);
          }}
        >
          <VscEdit size={22} color={Colors.fontDark} />
        </button>
        <button
          className="pr-4 pt-1 focus:outline-none"
          onClick={() => {
            deleteWebhook(Cookies.get("token"), itemId);
          }}
        >
          <VscTrash size={24} color={Colors.fontDark} />
        </button>
      </div>
    </div>
  );
}

export default WebhookItem;
