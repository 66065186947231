import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { BiFilterAlt } from "react-icons/bi";
import { MdSearch } from "react-icons/md";

import { useDesktopOrLaptop } from "../../../../services/useDesktopOrLaptop";
import { useTheme } from "../../../../store";
import Dropdown from "./Dropdown";
import "./styles/filtersMenu.css";

const FiltersMenu = ({ menuItems, title, icon = "filter" }) => {
  // icon "filter" | "search"
  const [activeFiltersIds, setActiveFiltersIds] = useState([]);
  const [dropdown, setDropdown] = useState(false);
  const { isdesktop, xxsfontsize, isScalingDisabled } = useDesktopOrLaptop();
  const { Colors } = useTheme();

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setDropdown(false);
      }}
    >
      <div>
        <nav>
          <ul className="menus">
            <li className="menu-items">
              <button
                style={{
                  borderWidth: "1px",
                  borderColor: "#D8D8D8",
                  borderRadius: "16px",
                  padding: isScalingDisabled || isdesktop ? "4px 0px" : "4px 4px",
                  borderStyle: "dashed",
                }}
                type="button"
                aria-haspopup="menu"
                aria-expanded={dropdown ? "true" : "false"}
                onClick={() => setDropdown((prev) => !prev)}
                className="flex flex-row items-center focus:outline-none "
              >
                <div style={{ padding: "2px 0px 2px 10px" }}>
                  {icon === "search" ? (
                    <MdSearch color={Colors.fontGreyLight} size={19} />
                  ) : (
                    <BiFilterAlt color={Colors.fontGreyLight} size={19} />
                  )}
                </div>
                <div
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    color: Colors.fontGreyLight,
                    display: "flex",
                    alignItems: "center",
                    padding: "2px 10px 2px 0px",
                  }}
                >
                  <span style={{ marginLeft: "8px" }}>{title}</span>
                </div>
                {activeFiltersIds?.length ? (
                  <div
                    style={{
                      height: "20px",
                      width: "20px",
                      backgroundColor: Colors.bluePrimary,
                      borderRadius: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "absolute",
                      right: "-4px",
                      top: "-8px",
                      color: "white",
                      fontSize: isScalingDisabled ? "10px" : xxsfontsize,
                      textAlign: "center",
                    }}
                  >
                    {activeFiltersIds.length}
                  </div>
                ) : null}
              </button>
              <Dropdown
                depthLevel={0}
                submenus={menuItems}
                dropdown={dropdown}
                activeFiltersIds={activeFiltersIds}
                setActiveFiltersIds={setActiveFiltersIds}
              />
            </li>
          </ul>
        </nav>
      </div>
    </OutsideClickHandler>
  );
};

export default FiltersMenu;
