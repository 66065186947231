import { createStore } from "aniuta";
import { useState } from "react";

const useDelInfoOnSidePopup = createStore({
  name: "useDelInfoOnSidePopup",
  Store: () => {
    const [deliveryIdFromQueryParam, setDeliveryIdFromQueryParam] = useState();

    return {
      deliveryIdFromQueryParam,
      setDeliveryIdFromQueryParam,
    };
  },
});

export default useDelInfoOnSidePopup;
