import { useState } from "react";
import * as Sentry from "@sentry/react";
import { createStore } from "aniuta";
import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { CardElement } from "@stripe/react-stripe-js";
import { baseUrl } from "../runTimeConst";
import { getMerchantAccountsStripeSetupIntent } from "../utils/api/account";
import {
  setStorePaymentMethod,
  merchantAccountsPaymentMethods,
  removePaymentMethod,
  merchantAccountsSetDefaultPaymentMethod,
} from "../utils/api/paymentMethod";

const usePaymentStore = createStore({
  name: "PaymentStore",
  Store: () => {
    const [customerCards, setCustomerCards] = useState([]);
    const [selectedCardId, setSelectedCardId] = useState();
    const [addCardModal, setAddCardModal] = useState(false);
    const [updater, setUpdater] = useState(false);
    const [cardsLoading, setCardsLoading] = useState(false);
    const [paymentLoading, setPaymentLoading] = useState(false);
    const [setupIntent, setSetupIntent] = useState(null);
    // ! Load Saved Cards

    const loadCardList = async (accountId) => {
      if (!accountId) return;
      setCardsLoading(true);
      const response = await merchantAccountsPaymentMethods(accountId);
      setCardsLoading(false);
      setCustomerCards(response.data);
    };

    // ! Remove Card
    const removeSelectedCard = async (id) => {
      if (!id) {
        return;
      }

      try {
        await removePaymentMethod(id);

        toast.success("The Card has been removed successfully");
        setUpdater(!updater);
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      }
    };

    // ! Select As Default
    const setSelectedCardDefault = async (id, paymentMethodId) => {
      if (!id) {
        return;
      }
      try {
        await merchantAccountsSetDefaultPaymentMethod(id, paymentMethodId);
        toast.success("Payment Method defaulted successfully");
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      }
      setUpdater(!updater);
    };

    // ! Save New Card

    const [isSaveLoading, setIsSaveLoading] = useState(false);

    const saveCard = async (stripe, elements, push) => {
      setIsSaveLoading(true);
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
      // debugger;
      const secretResponse = await axios.post(
        `${baseUrl}stripe/setup`,
        {},
        { headers: { "x-burq-client": "client-dashboard" } }
      );
      const clientSecret = secretResponse.data.client_secret;
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
        setup_future_usage: "off_session",
      });
      if (result.error) {
        setIsSaveLoading(false);
        // push();

        // console.log(result.error.message);
        if (result.error.message) {
          toast.error(result.error.message);
        }
        // alert(result.error.message);
      } else {
        setIsSaveLoading(false);

        const paymentMethodId = result.paymentIntent.payment_method;
        const intentId = result.paymentIntent.id;
        // ვაგზავნით id-ს ბექში.
        await axios.post(
          `${baseUrl}stripe/payment-method/save`,
          // "https://dashboard.burqup.com/api/test-stripe/payment-method/save",
          {
            paymentMethodId: paymentMethodId,
            intentId: intentId,
          },
          { headers: { "x-burq-client": "client-dashboard" } }
        );
        setAddCardModal(false);
        // ! Provide Payments
        if (customerCards <= 1) {
          push();
        }

        setUpdater(!updater);
      }
    };

    // ! Confirm With Selected Card
    const [requestWithPaymentLoading, setRequestWithPaymentLoading] =
      useState(false);
    const [stripeIntentId, setStripeIntentId] = useState();

    const getSetupIntent = async (id, payload) => {
      try {
        setPaymentLoading(true);
        const { data } = await getMerchantAccountsStripeSetupIntent(
          id,
          payload
        );
        setSetupIntent(data?.data?.client_secret);
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setPaymentLoading(false);
      }
    };

    // ! Select As Store payment method
    const setStoreLevelPaymentMethod = async (
      id,
      payment_method_id,
      callBack
    ) => {
      try {
        await setStorePaymentMethod(id, payment_method_id);
        if (!!callBack) {
          callBack();
        }
        toast.success("Payment Method defaulted successfully");
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      }
    };

    return {
      customerCards,
      setCustomerCards,
      selectedCardId,
      setSelectedCardId,
      addCardModal,
      setAddCardModal,
      updater,
      setUpdater,
      setIsSaveLoading,
      isSaveLoading,
      cardsLoading,
      setStripeIntentId,
      stripeIntentId,
      requestWithPaymentLoading,
      // func
      loadCardList,
      removeSelectedCard,
      setSelectedCardDefault,
      saveCard,
      getSetupIntent,
      paymentLoading,
      setupIntent,
      setStoreLevelPaymentMethod,
    };
  },
});

export default usePaymentStore;
