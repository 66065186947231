import React from "react";

function CommonToggle({
  smsHandler,
  checked,
  style,
  circleStyle,
  translateX,
  id,
  disabled,
}) {
  return (
    <button
      disabled={disabled ?? false}
      id={id}
      style={{ ...style, ...{ opacity: disabled ? 0.2 : 1 } }}
      type="button"
      onClick={() => smsHandler()}
      className={`  transition-colors flex items-center rounded-full  focus:outline-none `}
    >
      <div
        style={{
          ...circleStyle,
          transform: checked
            ? `translateX(${translateX}px)`
            : `translateX(-1px)`,
        }}
        className={` transition-transform  rounded-full  `}
      ></div>
    </button>
  );
}

export default CommonToggle;
