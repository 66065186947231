import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useMerchant, userDataStore } from "../store";
import { canSeeStatementsTab } from "../utils/helpers";
import { useDesktopOrLaptop } from "../services/useDesktopOrLaptop";
import TabButton from "./shared/common/TabButton";
import { ROLES } from "./AccountSettings/RolesSidebar/helpers";
import { useFlag } from "@unleash/proxy-client-react";

function SettingsPage() {
  const { isdesktop } = useDesktopOrLaptop();
  // const isUserRolesEnabled = false;
  const isUserRolesEnabled = useFlag("userRoles");

  const location = useLocation();

  const { merchantData, isAdmin } = useMerchant();
  const { userRole } = userDataStore();

  const [settingsPages, setSettingsPages] = useState("payments");
  let navigate = useNavigate();
  const canSeeStatements = canSeeStatementsTab(merchantData?.capabilities);

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_location: window.location.href,
      page_path: window.location.pathname,
      page_title: window.location.pathname,
    });
    window.analytics.page();
    let splitedPath = location.pathname.split("/");

    if (!location?.pathname || !merchantData) {
      return;
    }

    if (splitedPath[3] === "payments") {
      if (userRole !== ROLES.ADMIN && !isAdmin && isUserRolesEnabled) {
        setSettingsPages("addressBook");
        navigate("/v1/settings/addressBook");
        return;
      }
    }
    if (splitedPath[3] === "details") {
      if (userRole !== ROLES.ADMIN && !isAdmin && isUserRolesEnabled) {
        setSettingsPages("addressBook");
        navigate("/v1/settings/addressBook");
        return;
      }
    }
    if (splitedPath[3] === "members") {
      if ((userRole === ROLES.EMPLOEE || userRole === ROLES.STORE_EMPLOEE) && !isAdmin && isUserRolesEnabled) {
        setSettingsPages("addressBook");
        navigate("/v1/settings/addressBook");
        return;
      }
    }

    if (splitedPath[3] === "billing" && !canSeeStatementsTab(merchantData?.capabilities).isEnabled) {
      if (userRole === ROLES.ADMIN || isAdmin || !isUserRolesEnabled) {
        setSettingsPages("payments");
        navigate("/v1/settings/payments");
      } else {
        setSettingsPages("addressBook");
        navigate("/v1/settings/addressBook");
      }
    } else {
      setSettingsPages(splitedPath[3]);
    }
  }, [location.pathname, merchantData]);

  return (
    <div style={{ padding: "24px 24px 10px 24px", height: `calc(100% - 70px)` }} className="w-full flex flex-col">
      <div className={`flex flex-row items-center ${!isdesktop && "scrollbar"}`}>
        {(userRole === ROLES.ADMIN || isAdmin || !isUserRolesEnabled) && (
          <TabButton
            name={"Payments"}
            onClick={() => {
              setSettingsPages("payments");
              navigate("/v1/settings/payments");
            }}
            active={settingsPages === "payments"}
            extraButtonClasses={"mr-12"}
          />
        )}

        {canSeeStatements?.isEnabled && (userRole === ROLES.ADMIN || !isUserRolesEnabled || isAdmin) && (
          <TabButton
            name={"Billing"}
            onClick={() => {
              setSettingsPages("billing");
              navigate("/v1/settings/billing");
            }}
            active={settingsPages === "billing"}
            extraButtonClasses={"mr-12"}
          />
        )}
        <TabButton
          name={"Address Book"}
          onClick={() => {
            setSettingsPages("addressBook");
            navigate("/v1/settings/addressBook");
          }}
          active={settingsPages === "addressBook"}
          extraButtonClasses={"mr-12"}
        />

        {(userRole === ROLES.ADMIN || userRole === ROLES.STORE_MANAGER || isAdmin || !isUserRolesEnabled) && (
          <TabButton
            name={"Account Members"}
            onClick={() => {
              navigate("/v1/settings/members");
              setSettingsPages("members");
            }}
            active={settingsPages === "members"}
            extraButtonClasses={"mr-12"}
          />
        )}
        {(userRole === ROLES.ADMIN || isAdmin || !isUserRolesEnabled) && (
          <TabButton
            name={"Account Details"}
            onClick={() => {
              setSettingsPages("details");
              navigate("/v1/settings/details");
            }}
            active={settingsPages === "details"}
            extraButtonClasses={"mr-12"}
          />
        )}

        <TabButton
          name={"Notifications"}
          onClick={() => {
            setSettingsPages("notifications");
            navigate("/v1/settings/notifications");
          }}
          active={settingsPages === "notifications"}
          extraButtonClasses={"mr-12"}
        />
      </div>
      <div className="w-full h-full">
        <Outlet />
      </div>
    </div>
  );
}

export default SettingsPage;
