import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";

const CustomFullScreenModal = ({
  children,
  isOpen,
  onClose,
  extraClasses,
  extraStyles,
  notCloseOnOutside = false,
}) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative"
        style={{ zIndex: 999 }}
        onClose={() => {
          if (!notCloseOnOutside) {
            onClose();
          }
        }}
      >
        {/* <Dialog as="div" className="relative z-50" onClose={() => {}}> */}
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div
          style={extraStyles}
          className={`fixed inset-0 overflow-y-auto ${extraClasses}`}
        >
          <div
            style={{ width: "100%" }}
            className="flex min-h-full items-center justify-center p-0 text-center"
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                style={{
                  width: "100%",
                }}
                className="w-full h-screen overflow-hidden text-left align-middle shadow-xl transition-all"
              >
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default CustomFullScreenModal;
