import React, { useEffect, useMemo, useState } from "react";
import {
  ScatterChart,
  XAxis,
  YAxis,
  Scatter,
  Tooltip,
  Legend,
  Rectangle,
  ResponsiveContainer,
} from "recharts";
import { useTheme } from "../../../../store";
import { formatTime, formatWeek, numberWithCommas } from "../../utils";
import { useDesktopOrLaptop } from "../../../../services/useDesktopOrLaptop";

const getHeatGroups = (dataset, colors) => {
  let maxDeliveries = dataset.reduce(
    (acc, curr) => (acc < curr?.deliveries ? curr?.deliveries : acc),
    0
  );
  maxDeliveries = maxDeliveries < 4 ? 4 : maxDeliveries;
  const breakpoints = [
    { count: 0, color: colors.chartBlueLighter },
    { count: 20, color: colors.chartBlueLight },
    { count: 40, color: colors.chartBlue },
    { count: 80, color: colors.chartBlueDark },
  ];
  let remaining = [...dataset];
  const heatGroups = [];

  breakpoints.forEach(({ count, color }, index) => {
    const percentage = Math.round((count / 100) * maxDeliveries);
    heatGroups.push({
      label: `<= ${percentage}`,
      color,
      data: remaining.filter((d) => d.deliveries <= percentage),
    });

    // Keep track of remaining
    remaining = remaining.filter((d) => d.deliveries > percentage);
  });
  if (remaining.length > 0) {
    heatGroups.push({
      label: `> ${Math.round((breakpoints.pop().count / 100) * maxDeliveries)}`,
      color: colors.chartBlueDarker,
      data: remaining,
    });
  }

  return heatGroups;
};

const CustomShape = (props, windowSize, isdesktop) => {
  const getSize = () => {
    if (windowSize[0] > 1350) {
      return windowSize[0] / 40;
    } else if (windowSize[0] > 1250) {
      return windowSize[0] / 42;
    } else if (windowSize[0] > 1090) {
      return windowSize[0] / 45;
    } else if (windowSize[0] > 900) {
      return windowSize[0] / 40;
    } else {
      return windowSize[0] / 55;
    }
  };

  return (
    <Rectangle
      {...props}
      height={!isdesktop ? 35 : getSize()}
      width={!isdesktop ? 35 : getSize()}
      x={props.x + 5}
      y={isdesktop ? props.y - 15 : props.y}
    />
  );
};

const HeatMap = ({ dataset = [] }) => {
  const { isdesktop } = useDesktopOrLaptop();

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  const { Colors } = useTheme();
  const styles = useMemo(() => {
    return {
      tickStyle: {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: isdesktop ? 16 : 8,
        lineHeight: "21px",
        textAlign: "right",
        fill: Colors.chartSecondLine,
      },
      toolTip: {
        backgroundColor: Colors.white,
        border: "none",
        padding: 10,
      },
      toolTipText: {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 14,
        lineHeight: "21px",
        padding: "2px !important",
        margin: "0px",
      },
    };
  }, [Colors, isdesktop]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <ResponsiveContainer
        width={isdesktop ? "90%" : "100%"}
        aspect={isdesktop ? 2.55 : 2.4}
      >
        <ScatterChart>
          <XAxis
            dataKey="hour"
            interval={0}
            type="number"
            tick={{ ...styles.tickStyle }}
            tickMargin={0}
            domain={[0, 24]}
            tickLine={false}
            axisLine={false}
            tickFormatter={formatTime}
            ticks={[2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22]}
            height={45}
          />
          <YAxis
            dataKey="day"
            reversed
            interval={0}
            tick={{ ...styles.tickStyle }}
            tickMargin={5}
            tickFormatter={formatWeek}
            tickLine={false}
            axisLine={false}
            ticks={[1, 2, 3, 4, 5, 6, 7]}
            domain={[0, 8]}
            width={45}
          />

          <Tooltip
            content={({ payload }) => {
              const data = payload && payload[0] && payload[0].payload;
              const { day, hour, deliveries } = data || {};

              return (
                <div style={styles.toolTip}>
                  <p style={styles.toolTipText}>{`${formatWeek(
                    day
                  )} - ${formatTime(hour)}`}</p>
                  <p style={styles.toolTipText}>
                    {deliveries && numberWithCommas(deliveries)} deliveries
                  </p>
                </div>
              );
            }}
          />
          <Legend />

          {getHeatGroups(dataset, Colors).map((group) => (
            <Scatter
              key={group.label}
              name={group.label}
              data={group.data}
              fill={group.color}
              shape={(props) => CustomShape(props, windowSize, isdesktop)}
            />
          ))}
        </ScatterChart>
      </ResponsiveContainer>
    </div>
  );
};

const HeatChart = ({ payload }) => {
  return (
    <div style={{ width: "100%" }}>
      <HeatMap dataset={payload} />
    </div>
  );
};

export default HeatChart;
