import React from "react";
import styles from "./styles.module.css";
import classNames from "classnames";

const NotificationBadge = ({
  notificationsObject,
  lookupKey,
  notificationsTitle,
  externalClassName,
}) => {
  let badgeData = notificationsObject[lookupKey || notificationsTitle] ?? {
    type: "",
    label: "",
  };
  return (
    <span
      className={classNames(
        styles.bdg,
        {
          [styles.warning]: badgeData.type === "warning",
          [styles.error]: badgeData.type === "alert",
          [styles.neutral]: badgeData.type === "info",
        },
        externalClassName
      )}
    >
      {badgeData.label}
    </span>
  );
};

export default NotificationBadge;
