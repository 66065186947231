import React, { Fragment, forwardRef, useState } from "react";
import DropdownHeadless from "../../../shared/common/DropdownHeadless";
import styles from "./styles.module.css";
import { MdFlashOn } from "react-icons/md";
import { FaChevronDown } from "react-icons/fa";
import { Menu } from "@headlessui/react";
import classNames from "classnames";
import { useFlag } from "@unleash/proxy-client-react";
import { useSingleItemStore } from "../../../../store";
import { useNavigate } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import Tooltip from "../../../shared/atoms/ToolTip";

let MyCustomButton = forwardRef(function (props, ref) {
  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        if (props?.isActive) {
          props?.setIsActive(false);
        }
      }}
    >
      <button
        className={classNames(styles.actionsButton, {
          [styles.activeAction]: props?.isActive,
        })}
        ref={ref}
        {...props}
      >
        <span>
          <MdFlashOn size={18} />
        </span>
        <div className="ml-1">Actions</div>
        <span className="ml-4">
          <FaChevronDown size={14} />
        </span>
      </button>
    </OutsideClickHandler>
  );
});
export function Actions({ item, setIsCreateIncidentOpened, setCancelVisible, getDeliveryInfo }) {
  const { setRerouteItem, setIsAdminReroute, setIsMerchantReroute } = useSingleItemStore();
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);

  const getRerouteTooltipContent = () => {
    if (item?.reroute_status === "rerouted") {
      return {
        heading: "Delivery already rerouted",
        description: "This delivery has already been rerouted due to an issue with the provider."
      }
    } else if (item?.reroute_status === "provider_search") {
      return {
        heading: "Reroute in progress",
        description: "Burq customer support is currently attempting to reroute this delivery."
      }
    } else return {}
  }

  return (
    <div className="-mr-5 ml-5">
      <DropdownHeadless
        isActive={isActive}
        setIsActive={setIsActive}
        onClick={() => {
          setIsActive((prev) => !prev);
        }}
        CustomButton={MyCustomButton}
      >
        {item.status !== "canceled" &&
          item.status !== "provider_canceled" &&
          item.status !== "customer_canceled" &&
          item.status !== "burq_canceled" &&
          item.status !== "expired" &&
          item.status !== "failed" &&
          item.status !== "disputed" &&
          item.status !== "delivered" &&
          item.status !== "returned" &&
          item.status !== "request" && (
            <Menu.Item as={Fragment}>
              {({ active }) => (
                <div>
                  <div
                    onClick={() => {
                      setCancelVisible(true);
                      // setAllTimeVisible(false);
                      // setIsCalendarVisible(false);
                    }}
                    className={classNames(
                      styles.orderIdTitle,
                      { [styles.active]: active },
                      "w-full text-left mb-2 cursor-pointer px-5"
                    )}
                  >
                    Cancel
                  </div>
                </div>
              )}
            </Menu.Item>
          )}
        {(item.status === "enroute_to_return" ||
          item.status === "provider_canceled" ||
          item.status === "customer_canceled" ||
          item.status === "burq_canceled" ||
          item.status === "failed" ||
          item.status === "returned") && (
            <Tooltip
              heading={getRerouteTooltipContent().heading}
              description={getRerouteTooltipContent().description}
              place="left"
              extraTooltipClass={styles.tooltipExtraStyle}>
              <Menu.Item
                as={Fragment}
                disabled={!!(item?.reroute_status === "rerouted" || item?.reroute_status === "provider_search")}>
                {({ active, disabled }) => (
                  <div>
                    <div
                      onClick={() => {
                        if (item?.reroute_status === "rerouted" || item?.reroute_status === "provider_search") {
                          return;
                        }
                        setRerouteItem(item);
                        setIsAdminReroute(false);
                        navigate(`/reroute/${item.id}`);
                        setIsMerchantReroute(true);
                      }}
                      className={classNames(
                        styles.orderIdTitle,
                        {
                          [styles.active]: active,
                          [styles.disabled]: disabled,
                        },
                        "w-full text-left px-5 mb-2 cursor-pointer",
                      )}
                    >
                      <span>Reroute</span>
                    </div>
                  </div>
                )}
              </Menu.Item>
            </Tooltip>
          )}

        <Menu.Item as={Fragment}>
          {({ active }) => (
            <div>
              <div
                onClick={() => {
                  setRerouteItem(item);
                  setIsAdminReroute(false);
                  navigate(`/rerouteEdit/${item.id}`);
                }}
                className={classNames(
                  styles.orderIdTitle,
                  { [styles.active]: active },
                  "w-full text-left px-5 cursor-pointer mb-2"
                )}
              >
                Duplicate
              </div>
            </div>
          )}
        </Menu.Item>

        {!item?.delivery_incident && (
          <Menu.Item as={Fragment}>
            {({ active }) => (
              <div>
                <div
                  onClick={() => {
                    setIsCreateIncidentOpened(true);
                  }}
                  className={classNames(styles.orderIdTitle, { [styles.active]: active }, "w-full text-left px-5 cursor-pointer")}
                >
                  Create incident
                </div>
              </div>
            )}
          </Menu.Item>
        )}
      </DropdownHeadless>
    </div>
  );
}
