import React from "react";
import styles from "./styles.module.css"; // Your CSS module
import moment from "moment";
import classNames from "classnames";
import { statusesObject } from "../../../../runTimeConst";
import momenttz from "moment-timezone";

const TimelineItem = ({ status, date, time, index, length, isLast }) => {
  // const readableStatus = status
  //   .replace(/_/g, " ")
  //   .replace(/\b\w/g, (l) => l.toUpperCase());

  const formattedDate = moment(time).format("ddd, DD MMM YYYY");
  const formattedTime =
    moment(time).format("hh:mm A") +
    ", " +
    momenttz.tz(momenttz.tz.guess()).format("z");

  const isRed =
    status === "provider_canceled" ||
    status === "customer_canceled" ||
    status === "burq_canceled" ||
    status === "expired" ||
    status === "disputed" ||
    status === "failed";

  return (
    <div className={styles.container}>
      {!isLast && <div className={styles.line} />}
      <div className={styles.circle}>
        <div className={styles.smallCircle}>
          <div
            className={classNames(styles.smallest, {
              [styles.blueCircle]: status === "delivery_created",
              [styles.greenCircle]: status === "delivered",
              [styles.redCircle]: isRed,
            })}
          ></div>{" "}
        </div>
      </div>
      <div className={classNames(styles.fontDark)}>
        {statusesObject[status]}
      </div>
      <div className="w-36">
        <div className={styles.date}>{formattedDate}</div>
        <div className={styles.time}>{formattedTime}</div>
      </div>
    </div>
  );
};

export default TimelineItem;
