import React from "react";
import styles from "./styles/activeFiltersBar.module.css";
import { AiOutlineCloseCircle } from "react-icons/ai";

import classNames from "classnames";
import { FILTER_TYPES } from "../../../constants";
import { IoCloseCircle } from "react-icons/io5";
import { useMyStore, userDataStore } from "../../../store";

const ActiveFiltersBarOnHome = ({ activeFilters = [] }) => {
  const { isSandbox } = useMyStore();

  const countOfstates = activeFilters.filter((fl) => fl.filterState).length;
  const { authInfo } = userDataStore();
  const merchantAccountId = authInfo.merchantAccountId;
  return (
    <div className={classNames(styles.container, "kata")}>
      {activeFilters
        .filter((fl) => fl.filterState)
        .map((filter, index) => {
          if (index === 0)
            return (
              <div key={filter.title} className={classNames(styles.filterItem, "truncate")}>
                {countOfstates > 1 && <div className={styles.count}>{countOfstates}</div>}
                <div className={classNames(styles.title, "truncate")}>{filter.title}</div>
                <IoCloseCircle
                  onClick={(e) => {
                    e.stopPropagation();
                    filter.setFilterState("", merchantAccountId, isSandbox);
                  }}
                  color="#0062ff"
                  className="ml-2 cursor-pointer"
                  size={16}
                />
              </div>
            );
          return <></>;
        })}
    </div>
  );
};

export default ActiveFiltersBarOnHome;
