import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Map from "./Map";
import TrackingMobileViewHeader from "./TrackingMobileViewHeader";
import OrderDetailsCard from "./OrderDetailsCard";
import powered from "../../assets/png/powered.png";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import { getOrderfulfillment } from "../../utils/api/order";
import {
  calculateRoutes,
  coordinatesHandler,
  ORDER_STATUSES,
  shouldShowDriverMarker,
} from "./utils";

const OrderTracking = () => {
  const [pickupCoordinates, setPickupCoordinates] = useState(null);
  const [zoom, setZoom] = useState(10);
  const [dropoffCoordsArr, setDropoffCoordsArr] = useState([]);
  const [orderInfo, setOrderInfo] = useState(null);
  const [routesForMap, setRoutesForMap] = useState([]);

  const { isdesktop } = useDesktopOrLaptop();
  let { id } = useParams();

  // useEffect(() => {
  //   if (!id) {
  //     return;
  //   }
  //   socket.emit("trackOrder", id);
  // }, [id]);

  // socket.on("trackOrder", (data) => {
  //   if(data.data.id === id) setOrderInfo(data.data);
  // });

  useEffect(() => {
    if (orderInfo?.dropoff.address) {
      calculateAndDisplayRoute(orderInfo);
    }
  }, [orderInfo]);

  useEffect(() => {
    getDeliveryInfo(id);
  }, [id]);

  useEffect(() => {
    window.gtag("event", "screen_view", {
      app_name: "Burq",
      screen_name: "Tracking Screen",
    });
  }, []);

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_location: window.location.href,
      page_path: window.location.pathname,
      page_title: "Tracking",
    });
  }, [window.location.pathname]);

  const getDeliveryInfo = async (id) => {
    try {
      const { data: response } = await getOrderfulfillment(id);
      const { pickup, dropoff, status } = response.data;
      if(!status || status !== ORDER_STATUSES.SUBMITTED) throw new Error("Order is not submitted");
      setOrderInfo(response.data);
      const pickupLatLng = await coordinatesHandler(pickup.address);
      setPickupCoordinates(pickupLatLng);

      const dropoffLatLng = await coordinatesHandler(dropoff.address);
      setDropoffCoordsArr([dropoffLatLng]);
    } catch (err) {
      const toastError = err.message ? err.message : "Requested Id Not Found";
      toast.error(toastError);
    }
  };

  const calculateAndDisplayRoute = async (orderInfo) => {
    const _routesForMap = await calculateRoutes(orderInfo);
    setZoom(14);
    setRoutesForMap(_routesForMap);
  };

  return (
    <div className="xl:flex-row flex flex-col ">
      {!isdesktop && <TrackingMobileViewHeader id={id} />}
      <Map
        containerElement={
          isdesktop ? (
            <div style={{ height: "100vh", width: "100vw" }} />
          ) : (
            <div style={{ height: "70vh", width: "100vw" }} />
          )
        }
        dropoffCoordsArr={dropoffCoordsArr}
        pickupCoordinates={pickupCoordinates}
        zoom={zoom}
        driverInfo={orderInfo && orderInfo.driver}
        routesForMap={routesForMap}
        showDriverMarker={shouldShowDriverMarker(orderInfo)}
      />

      {orderInfo && <OrderDetailsCard orderInfo={orderInfo} />}

      {isdesktop ? (
        <div className="absolute bottom-0 flex w-full justify-center items-center">
          <img src={powered} alt="" className=" w-40" />
        </div>
      ) : (
        <div className="text-gray-800 w-full flex justify-center items-center">
          <img src={powered} alt="" style={{ width: "300px" }} />
        </div>
      )}
    </div>
  );
};

export default OrderTracking;
