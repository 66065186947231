import React, { useCallback, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { cloneDeep } from "lodash";
import ListRow from "./ListRow";
import Button from "../../../../shared/atoms/Button";
import styles from "../../styles/PricingRules/RulesList/styles.module.css";
import Loader from "../../../../shared/atoms/Loader";
import { usePartnerStore } from "../../../../../store";
import AreYouSurePopup from "../../../../shared/common/AreYouSurePopup";

const RulesList = ({
  rules,
  setFieldValue,
  setActiveStep,
  setSelectedRuleIndex,
  handleSubmit,
  deleteRulesHandler,
  selectedRule,
}) => {
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);
  const [isGoingBack, setIsGoingBack] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const {
    isPartnerRulesLoading,
    isUpdatePartnerRulesLoading,
    isDeletePartnerRulesLoading,
  } = usePartnerStore();

  const isLoading =
    isPartnerRulesLoading ||
    isUpdatePartnerRulesLoading ||
    isDeletePartnerRulesLoading;

  const moveRule = useCallback((dragIndex, hoverIndex) => {
    let newRules = cloneDeep(rules);
    let dragRule = newRules[dragIndex];
    newRules.splice(dragIndex, 1);
    newRules.splice(hoverIndex, 0, dragRule);
    setFieldValue("rules", newRules);
  }, []);

  const renderListItem = useCallback((rule, index) => {
    return (
      <ListRow
        setActiveStep={setActiveStep}
        setSelectedRuleIndex={setSelectedRuleIndex}
        rule={rule}
        index={index}
        moveRule={moveRule}
      />
    );
  }, []);

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.header}>
          <div className={styles.heading}>Rules</div>
          <div>
            <Button
              text="Create rule"
              disabled={isLoading}
              onClick={(e) => {
                setSelectedRuleIndex(null);
                setActiveStep("create_rule");
              }}
              externalClassName={styles.createButton}
            />
          </div>
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <DndProvider backend={HTML5Backend}>
              {rules?.map((rule, index) => renderListItem(rule, index))}
            </DndProvider>
            {!rules.length && <div>Please create a rule.</div>}
          </>
        )}
      </div>
      <div className={styles.buttonSection}>
        {selectedRule && (
          <Button
            text="Delete Pricing"
            variant="secondary"
            onClick={(e) => {
              setIsDeleting(true);
            }}
            externalClassName={styles.saveRuleButton}
          />
        )}
        <div className={styles.buttonSection}>
          <Button
            text="Back"
            variant="secondary"
            onClick={(e) => {
              setIsGoingBack(true);
            }}
            externalClassName={styles.saveRuleButton}
          />
          <Button
            text="Save Changes"
            disabled={isSaveButtonDisabled || !rules.length}
            variant={
              isSaveButtonDisabled || !rules.length ? "secondary" : "primary"
            }
            onClick={(e) => {
              setIsSaveButtonDisabled(true);
              handleSubmit(e);
            }}
            externalClassName={styles.saveRuleButton}
          />
        </div>
      </div>
      <AreYouSurePopup
        isOpen={isGoingBack}
        onClose={() => {
          setIsGoingBack(false);
        }}
        onYes={async () => {
          setActiveStep("select_partner");
        }}
        helperText={"Any unsaved progress will be lost."}
      />
      <AreYouSurePopup
        isOpen={isDeleting}
        onClose={() => {
          setIsDeleting(false);
        }}
        onYes={async () => {
          setIsDeleting(false);
          deleteRulesHandler();
        }}
        helperText={
          "This will delete this set of rules. You can not undo this."
        }
      />
    </div>
  );
};

export default RulesList;
