import React, { useEffect, useState } from "react";

import classNames from "classnames";
import styles from "./styles.module.css";
import { useMyStore, useTheme } from "../../../store";

const Pagination = ({
  data,
  onGetPaginationData,
  buttonClass,
  dependencies = [],
}) => {
  const [firstItemId, setFirstItemId] = useState(null);
  const { Colors } = useTheme();

  const { isSandbox } = useMyStore();

  useEffect(() => {
    if (!firstItemId) {
      if (data?.data) setFirstItemId(data?.data[0]?.id);
    }
  }, [data?.data]);

  useEffect(() => {
    setFirstItemId();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies]);

  const disableStyleNext = () => {
    return {
      color: data?.has_more ? Colors.fontDark : Colors.fontGreyLight,
    };
  };

  const disableStyle = () => {
    if (!firstItemId) {
      return { color: Colors.fontGreyLight };
    }
    return {
      color:
        firstItemId === data?.data[0]?.id
          ? Colors.fontGreyLight
          : Colors.fontDark,
    };
  };

  return (
    <div className={styles.container}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <button
          type="button"
          disabled={!firstItemId || firstItemId === data?.data[0]?.id}
          onClick={() => {
            onGetPaginationData({
              ending_before: data?.data[0]?.id,
              starting_after: "",
              isSandbox: isSandbox,
            });
          }}
          className={classNames(styles.buttons, buttonClass)}
          style={{
            ...disableStyle(),
          }}
        >
          Previous
        </button>

        <button
          type="button"
          disabled={!data?.has_more}
          onClick={() => {
            onGetPaginationData({
              ending_before: "",
              starting_after: data?.data[data?.data?.length - 1]?.id,
              isSandbox: isSandbox,
            });
          }}
          className={classNames(styles.buttons, buttonClass)}
          style={{
            ...disableStyleNext(),
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Pagination;
