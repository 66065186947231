import React from "react";
import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";
import { useTheme } from "../../../store";
import { CheckIcon, SearchSvg } from "../../../assets/svgs/Svgs";
import ToolTip from "../../Tooltip/Tooltip";
import Pagination from "../Pagination";
import styles from "./styles.module.css";
import OutsideClickHandler from "react-outside-click-handler";
import classNames from "classnames";

const CustomTypeAndSearch = ({
  isListVisible,
  setIsListVisible,
  list,
  onQueryChange,
  listItemClickHandler,
  selectedItem,
  renderListItem = null,
  listContainerStyle = {},
  searchQuery = "",
  placeholder = "Search Store",
  searchHidden = false,
  paging = false,
  onGetPaginationData,
  pagingData,
}) => {
  const { smfontsize, isdesktop } = useDesktopOrLaptop();

  const { Colors, themeChanger } = useTheme();

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setIsListVisible(false);
      }}
    >
      <div className="relative">
        <div
          style={{
            left: isdesktop ? "-106px" : "-130px",
            right: isdesktop ? "0px" : "-190px",
            top: isdesktop ? "27px" : "60px",
            ...listContainerStyle,
            maxHeight: isdesktop ? "280px" : "35opx",
          }}
          className={classNames(
            styles.absoluteContainer,
            {
              [styles.hidden]: !isListVisible,
            },
            `kata rounded-md shadow-xl`
          )}
        >
          {!searchHidden && (
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
              className={styles.searchForm}
            >
              <SearchSvg />
              <input
                type="text"
                placeholder={placeholder}
                value={searchQuery}
                onChange={(event) => {
                  onQueryChange(event.target.value);
                }}
                className={styles.searchInput}
              />
            </form>
          )}
          {(list || []).map((item) => (
            <button
              data-for={`list-item-${item.id}`}
              onClick={() => listItemClickHandler(item)}
              key={item.id}
              type="button"
              className={`flex justify-between items-center   ${
                !themeChanger ? "hover:bg-gray-700" : "hover:bg-gray-100"
              }`}
              style={{
                height: "50px",
                borderRadius: "8px",
                padding: "4px 10px",
                margin: isdesktop ? "4px 0px" : "18px 0px",
                width: "100%",
              }}
            >
              {renderListItem ? (
                renderListItem(item)
              ) : (
                <div
                  style={{ color: Colors.fontGreyLight, width: "90%" }}
                  className=" flex flex-col items-start "
                >
                  <div
                    className="text-left truncate"
                    style={{
                      color: Colors.fontDark,
                      width: "100%",
                      fontSize: smfontsize,
                    }}
                    data-for={`list-item-${item.id}`}
                  >
                    {item?.name}
                  </div>
                  <ToolTip
                    id={`list-item-${item.id}`}
                    disable={false}
                    text={item?.name}
                  />

                  <div style={{ fontSize: smfontsize }}>{item?.id}</div>
                </div>
              )}
              {selectedItem?.id === item.id && <CheckIcon />}
            </button>
          ))}
          {paging && pagingData?.data?.length > 0 && (
            <Pagination
              data={pagingData}
              onGetPaginationData={onGetPaginationData}
              buttonClass={styles.paginationExtras}
            />
          )}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default CustomTypeAndSearch;
