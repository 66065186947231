import React from "react";
import { compose, withProps } from "recompose";
import {
  withGoogleMap,
  GoogleMap,
  DirectionsRenderer,
  withScriptjs,
  OverlayView,
} from "react-google-maps";

import { isCenterDriver, mapStyles } from "./helpers";

import dropoffImg from "../../../assets/png/trackingRedesign/dropoff.png";
import pickup from "../../../assets/png/trackingRedesign/pickup.png";

import newCar from "../../../assets/png/carTrack.png";
import newVan from "../../../assets/png/vanTrack.png";
import newTruck from "../../../assets/png/truckTrack.png";

import styles from "./styles.module.css";
import classNames from "classnames";
import { MarkerSvg } from "../../../assets/svgs/Svgs";
import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";
import { LIGHT_MAP } from "../../../runTimeConst";

const MapOnTracking = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_KEY}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    // containerElement: <div style={{ height: "100vh", width: "100vw" }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)(
  ({
    pickupCoordinates,
    dropoffCoordsArr,
    routesForMap,
    deliveryInfo,
    color,
    center,
    isOnBranding,
  }) => {
    const getPixelPositionOffset = (width, height) => {
      if (deliveryInfo?.courier_img) {
        return {
          x: 0,
          y: -0,
        };
      }
      return {
        x: -0,
        y: -0,
      };
    };
    // console.log("routesForMaproutesForMaproutesForMap", routesForMap);
    const { isdesktop } = useDesktopOrLaptop();

    if (pickupCoordinates?.lat) {
      return (
        <GoogleMap
          defaultOptions={{
            // styles: mapStyles,
            styles: LIGHT_MAP,
            clickableIcons: false,
            fullscreenControl: false,
            disableDefaultUI: true,
            mapTypeControl: false,
            gestureHandling: "greedy",
            scrollwheel: true,
            zoomControlOptions: false,
          }}
          center={
            deliveryInfo.status === "enroute_pickup"
              ? {
                  lat: isCenterDriver(deliveryInfo)
                    ? parseFloat(deliveryInfo.courier_location_lat)
                    : pickupCoordinates?.lat,
                  lng: isCenterDriver(deliveryInfo)
                    ? parseFloat(deliveryInfo.courier_location_lng)
                    : pickupCoordinates?.lng,
                }
              : pickupCoordinates
          }
          zoom={12}
          defaultZoom={12}
        >
          {isCenterDriver(deliveryInfo) && (
            <OverlayView
              key={Math.random()}
              // mapPaneName={OverlayView.OVERLAY_LAYER}
              mapPaneName={OverlayView.FLOAT_PANE}
              // mapPaneName={OverlayView?.OVERLAY_MOUSE_TARGET}
              getPixelPositionOffset={getPixelPositionOffset}
              position={{
                lat: parseFloat(deliveryInfo?.courier_location_lat),
                lng: parseFloat(deliveryInfo?.courier_location_lng),
              }}
            >
              {deliveryInfo?.courier_img ? (
                <img
                  className={styles.courierImageOnMap}
                  src={deliveryInfo?.courier_img}
                  alt="dasd"
                />
              ) : deliveryInfo.vehicle === "truck" ? (
                <img className={styles.vehicles} src={newTruck} alt="" />
              ) : deliveryInfo.vehicle === "van" ? (
                <img className={styles.vehicles} src={newVan} alt="" />
              ) : (
                <img className={styles.vehicles} src={newCar} alt="" />
              )}
            </OverlayView>
          )}

          <OverlayView
            getPixelPositionOffset={getPixelPositionOffset}
            position={{
              lat: pickupCoordinates?.lat,
              lng: pickupCoordinates?.lng,
            }}
            mapPaneName={OverlayView?.OVERLAY_MOUSE_TARGET}
            // mapPaneName={OverlayView?.MARKER_LAYER}
          >
            <div>
              <div
                className={classNames(
                  "hover:opacity-100 opacity-0 absolute",
                  styles.pickupTootlip
                )}
              >
                <div className="myShadow flex flex-col  justify-between max-h-full bg-white rounded-xl px-4 py-3">
                  <div className={styles.tooltipTitle}>Pickup:</div>
                  {isOnBranding ? (
                    <>
                      <div
                        className={classNames(
                          "w-full rounded-full h-5 mt-1 bg-gray-400"
                          // styles.maskingColor
                        )}
                      ></div>
                      <div
                        className={classNames(
                          "w-full rounded-full h-5 mt-1",
                          styles.maskingColor
                        )}
                      ></div>
                    </>
                  ) : (
                    <div className={styles.tooltipBody}>
                      {deliveryInfo?.pickup_address}
                    </div>
                  )}
                </div>
              </div>

              <img className={styles.markers} src={pickup} alt="" />
            </div>
          </OverlayView>
          {routesForMap?.length > 0 &&
            routesForMap.map((item, index) => {
              return (
                <DirectionsRenderer
                  key={index}
                  options={{
                    suppressMarkers: true,
                    polylineOptions: { strokeColor: color ?? "#1460EF" },
                  }}
                  directions={item}
                />
              );
            })}

          {dropoffCoordsArr.length > 0 &&
            dropoffCoordsArr.map((item, index) => {
              return (
                <OverlayView
                  key={index}
                  getPixelPositionOffset={getPixelPositionOffset}
                  position={{
                    lat: item?.lat,
                    lng: item?.lng,
                  }}
                  mapPaneName={OverlayView?.OVERLAY_MOUSE_TARGET}
                >
                  <div>
                    <div
                      className={classNames(
                        "hover:opacity-100 opacity-0 absolute",
                        styles.pickupTootlip
                      )}
                    >
                      <div className="myShadow flex flex-col justify-between max-h-full bg-white rounded-xl px-4 py-3">
                        <div className={styles.tooltipTitle}>Dropoff:</div>
                        {isOnBranding ? (
                          <>
                            <div
                              className={classNames(
                                "w-full rounded-full h-5 mt-1 bg-gray-400"
                              )}
                            ></div>
                            <div
                              className={classNames(
                                "w-full rounded-full h-5 mt-1",
                                styles.maskingColor
                              )}
                            ></div>
                          </>
                        ) : (
                          <div className={styles.tooltipBody}>
                            {deliveryInfo?.dropoffs[index]?.dropoff_address}
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className={classNames(
                        styles.circle,
                        `flex items-center justify-center`,
                        styles.dropoffBigCircleColor,
                        styles.circleResp
                      )}
                    >
                      <MarkerSvg
                        width={isdesktop ? "23" : "49"}
                        height={isdesktop ? "23" : "49"}
                        viewBox={isdesktop ? "0 0 25 25" : "0 0 25 25"}
                        // height={"23" : "33"}
                        // viewBox={"0 0 25 25" : "0 0 24 24"}
                        color={color}
                      />
                    </div>
                    {/* <img className={styles.markers} src={dropoffImg} alt="" /> */}
                  </div>
                </OverlayView>
              );
            })}
        </GoogleMap>
      );
    } else {
      return null;
    }
  }
);

export default MapOnTracking;
