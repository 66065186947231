import React, { useCallback, useEffect, useRef, useState } from "react";
import { useStoreAndChild, userDataStore } from "../../../../store";

import { SearchSvg } from "../../../../assets/svgs/Svgs";
import styles from "./styles.module.css";
import { debounce } from "lodash";
import classNames from "classnames";
import { FaRegSquare, FaRegSquareCheck } from "react-icons/fa6";
import InputErrorComp from "../../../shared/common/InputErrorComp";
import { ROLES } from "../helpers";

export const StoresInsideRolesSidebar = ({
  selectedStoresForRoles,
  setSelectedStoresForRoles,
  values,
  error,
  backendRole,
}) => {
  const containerRef = useRef(null);
  const { allStoresByUser, getAllStoresByUser, setAllStoresByUser } =
    useStoreAndChild();

  const { authInfo, userRole } = userDataStore();

  const [searchText, setSearchText] = useState();

  const storeListHandler = async (accountId) => {
    await getAllStoresByUser(accountId);

    return;
  };

  const debounceFn = useCallback(
    debounce((search) => {
      getAllStoresByUser(authInfo.merchantAccountId, false, {
        search_phrase: search,
      });
    }, 500),
    []
  );

  useEffect(() => {
    const container = containerRef.current;

    // Function to check if the container is scrolled to the bottom
    const handleScroll = async () => {
      const scrollTop = container.scrollTop;
      const scrollHeight = container.scrollHeight;
      const clientHeight = container.clientHeight;

      if (scrollTop + clientHeight >= scrollHeight) {
        if (allStoresByUser?.has_more) {
          let params = {
            ending_before: "",
            starting_after:
              allStoresByUser?.data[allStoresByUser?.data?.length - 1].id,
          };

          const items = await getAllStoresByUser(
            authInfo.merchantAccountId,
            false,
            params
          );

          // Load new list items
          // const newItems = [...allStoresByUser?.data, ...items?.data];
          const newItems = {
            data: [...allStoresByUser?.data, ...items?.data],
            has_more: items?.has_more,
          };
          setAllStoresByUser(newItems);
        }
      }
    };

    // Attach the scroll event listener to the container
    container.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [allStoresByUser]);

  useEffect(() => {
    if (authInfo?.merchantAccountId) {
      storeListHandler(authInfo.merchantAccountId);
    }
  }, [authInfo]);

  const isResourceIdPresent = (targetResourceId) =>
    selectedStoresForRoles.some(
      (item) => item?.resource_id === targetResourceId
    );

  const isAllStoresSelected =
    values?.user_roles?.length > 0 &&
    (values?.user_roles[0].role_name === "store_admin" ||
      values?.user_roles[0].role_name === "store_operator_admin");

  return (
    <div className={styles.container}>
      <div className="flex items-center justify-between">
        <div className="flex items-center ">
          <div className={classNames(styles.selectStoreText, "mr-2")}>
            Select Store
          </div>{" "}
          <InputErrorComp
            // extraClasses={styles.reqText}
            error={error}
          />
        </div>
        {userRole === ROLES.ADMIN && (
          <div
            onClick={() => {
              if (isAllStoresSelected) {
                setSelectedStoresForRoles("user_roles", []);
              } else {
                setSelectedStoresForRoles("user_roles", [
                  {
                    role_name:
                      backendRole === ROLES.STORE_MANAGER
                        ? "store_admin"
                        : backendRole === ROLES.STORE_EMPLOEE &&
                          "store_operator_admin",
                    resource_id: authInfo.merchantAccountId,
                  },
                ]);
              }
            }}
            className={classNames(
              "flex items-center cursor-pointer",
              styles.allStoresText,
              {
                [styles.primaryColor]: isAllStoresSelected,
              }
            )}
          >
            <div className="mr-2">
              {isAllStoresSelected ? (
                <FaRegSquareCheck size={18} color={"#0062FF"} />
              ) : (
                <FaRegSquare size={18} className={styles.square} />
              )}
            </div>
            All stores
          </div>
        )}
      </div>
      <form
        onSubmit={(e) => {
          getAllStoresByUser(authInfo.merchantAccountId, false, {
            search_phrase: searchText,
          });
          e.preventDefault();
        }}
        className={styles.form}
      >
        <input
          type="text"
          placeholder="Search Store"
          onChange={(event) => {
            setSearchText(event.target.value);
            debounceFn(event.target.value);
          }}
          className={styles.searchInput}
        />
        <SearchSvg />
      </form>
      <div className={styles.scrollContainer} ref={containerRef}>
        {allStoresByUser?.data?.map((sto) => {
          return (
            <button
              onClick={() => {
                if (isResourceIdPresent(sto.id)) {
                  const _selectedStoresForRoles = selectedStoresForRoles.filter(
                    (item) => item.resource_id !== sto.id
                  );
                  setSelectedStoresForRoles(
                    "user_roles",
                    _selectedStoresForRoles
                  );
                } else {
                  const _selectedStoresForRoles = [...selectedStoresForRoles];
                  _selectedStoresForRoles.push({
                    role_name: backendRole,
                    resource_id: sto.id,
                  });
                  setSelectedStoresForRoles(
                    "user_roles",
                    _selectedStoresForRoles
                  );
                }
              }}
              disabled={isAllStoresSelected}
              key={sto.id}
              type="button"
              className={classNames(
                "flex justify-start items-start w-full p-2 rounded ",
                { "opacity-50": isAllStoresSelected },
                styles.oneItem
              )}
            >
              <div className="mr-5 mt-1">
                {isResourceIdPresent(sto.id) ? (
                  <FaRegSquareCheck size={18} color={"#0062FF"} />
                ) : (
                  <FaRegSquare size={18} className={styles.square} />
                )}
              </div>
              <div className={classNames("flex flex-col items-start")}>
                <div
                  className={classNames(
                    "text-left truncate w-full whitespace-nowrap",
                    styles.fontDark,
                    {
                      [styles.primaryColor]: isResourceIdPresent(sto.id),
                    }
                  )}
                >
                  {sto.name}
                </div>

                <div
                  className={classNames(
                    "truncate whitespace-nowrap text-left",
                    styles.storeId
                  )}
                >
                  {sto.id}
                </div>
              </div>
            </button>
          );
        })}
      </div>
    </div>
  );
};
