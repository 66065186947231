import React from "react";
import Cookies from "js-cookie";
import {
  useClickStore,
  useMyStore,
  useOrderManagement,
  userDataStore,
  useSingleItemStore,
  useStoreAndChild,
  useTheme,
} from "../../../store";

function RemoveOrderModal({}) {
  const { Colors } = useTheme();
  const {
    setIsDeleteOrderModalVisible,
    deleteOrder,
    getOrdersList,
    orderItemForDetails,
    setIsDetailsVisible,
    activeTab,
  } = useOrderManagement();

  const { isSandbox } = useMyStore();
  const { selectedStore } = useStoreAndChild();

  return (
    <div
      style={{ backgroundColor: Colors.white }}
      className="flex flex-col  py-16  justify-center items-startrounded-md w-full overflow-hidden"
    >
      <div
        style={{ color: Colors.fontDark }}
        className="text-2xl  font-bold text-center w-full mb-16"
      >
        Are you Sure?
      </div>
      <div className="w-full flex justify-evenly">
        <button
          onClick={async () => {
            deleteOrder(isSandbox, orderItemForDetails.id).then((res) => {
              if (res) {
                setIsDetailsVisible(false);

                getOrdersList(
                  isSandbox,
                  undefined,
                  undefined,
                  activeTab,
                  selectedStore?.id
                );
              }
            });
            setIsDeleteOrderModalVisible(false);
          }}
          className="focus:outline-none px-12 py-1.5 bg-red-50 rounded-xl text-red-500 border border-red-500 text-center"
        >
          Yes
        </button>
        <button
          onClick={() => {
            setIsDeleteOrderModalVisible(false);
          }}
          className="focus:outline-none px-12 py-1.5 bg-blue-600 rounded-xl text-indigo-50 text-center"
        >
          No
        </button>
      </div>
    </div>
  );
}

export default RemoveOrderModal;
