import React from "react";
import {
  AllDeliveriesSvg,
  AllCompletedSvg,
  AllCanceledSvg,
  AllPaymentSvg,
} from "../../assets/svgs/Svgs";
import { useTheme } from "../../store";

function AnalyticsCardsResponsove({ usd, title, sum, percent, itemId }) {
  const { Colors } = useTheme();

  return (
    <div
      style={{
        width: "95vw",
        paddingTop: "38px",
        paddingBottom: "38px",
        position: "relative",
        borderRadius: "50px",
        backgroundColor: Colors.bgDarkSecondary,
        borderWidth: "1px",
        borderColor: Colors.fontGreyLight,
        display: "flex",
        flexDirection: "row",
        marginLeft: itemId === 0 ? "0px" : "10px",
        justifyContent: "start",
        alignItems: "center",
        paddingLeft: "90px",
        marginTop: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "40%",
          justifyContent: "space-between",
        }}
        className="justify-center items-center "
      >
        <div
          style={{
            paddingTop: "30px",
            paddingBottom: "30px",
          }}
          // className="w-1/3 "
        >
          {title === "Deliveries" ? (
            <AllDeliveriesSvg />
          ) : title === "Completed" ? (
            <AllCompletedSvg />
          ) : title === "Canceled" ? (
            <AllCanceledSvg />
          ) : (
            title === "Payments" && <AllPaymentSvg />
          )}
        </div>
        <div
          style={{
            fontFamily: "Poppins",
            fontSize: "30px",
            marginLeft: "30px",
          }}
          className="flex mt-3"
        >
          <div
            style={{
              fontWeight: "300",
              color: Colors.fontBlack,
            }}
            className="font-semibold "
          >
            {usd && "$"}
            {sum && sum}
          </div>
        </div>
        <h2
          style={{
            fontFamily: "Poppins",
            fontSize: "30px",
            color: Colors.fontBlack,
            marginLeft: "30px",
            fontWeight: "300",
          }}
          className="font-semibold"
        >
          {title}
        </h2>
      </div>
    </div>
  );
}

export default AnalyticsCardsResponsove;
