import classNames from "classnames";
import React from "react";
import styles from "./styles.module.css";

const TableHeader = () => {
  return (
    <div
      className={classNames(
        "grid grid-cols-12 gap-1 w-full pb-2",
        styles.headerCont
      )}
    >
      <div
        className={classNames(
          "col-start-1 col-span-3 text-center",
          styles.headerTexts
        )}
      >
        {"ID"}
      </div>
      {/* <div
        className={classNames(
          "col-start-3 col-span-2 text-center",
          styles.headerTexts
        )}
      >
        Billing Period
      </div> */}
      <div
        className={classNames(
          "col-start-4 col-span-3 text-center",
          styles.headerTexts
        )}
      >
        Store{" "}
      </div>

      <div
        className={classNames(
          "col-start-7 col-span-3 text-center",
          styles.headerTexts
        )}
      >
        Status
      </div>
      {/* <div
        className={classNames(
          "col-start-9 col-span-2 text-center",
          styles.headerTexts
        )}
      >
        Starting Balance
      </div>
      <div
        className={classNames(
          "col-start-11 col-span-2 text-center",
          styles.headerTexts
        )}
      >
        Ending Balance
      </div> */}
      <div
        className={classNames(
          "col-start-10 col-span-2 text-center",
          styles.headerTexts
        )}
      >
        Amount due
      </div>
      <div
        className={classNames(
          "col-start-12 col-span-1 text-center",
          styles.headerTexts
        )}
      >
        Details
      </div>
    </div>
  );
};

export default TableHeader;
