import React from "react";
import moment from "moment";
import momenttz from "moment-timezone";

import styles from "./styles.module.css";
import { useMyStore } from "../../../../../../store";

import StatusBadgeReusable from "../../../../../shared/common/StatusBadgeReusable";
import classNames from "classnames";
import { statusesBadgesObject } from "../../../../../InvoicesHistory/utils";

const DeliveryInfo = ({ item, requestId }) => {
  const { isSandbox } = useMyStore();

  return (
    <div>
      <div className="w-full flex items-center justify-between">
        <div className="flex items-center justify-between w-2/5">
          <div>Burq ID:</div>
          {item?.external_order_ref && (
            <div style={{ marginLeft: "0px" }}> Order Id:</div>
          )}
        </div>

        {item.payment_status && !isSandbox && (
          <StatusBadgeReusable
            status={item.payment_status}
            badgesObject={statusesBadgesObject}
            externalClassName={styles.badge}
          />
        )}
      </div>
      <div className="flex items-center justify-between w-2/5 break-all">
        <div className={styles.titles}>{requestId}</div>
        <div className={classNames(styles.titlesExterRef)}>
          {item?.external_order_ref}
        </div>
      </div>

      {item.pickup_at && item.pickup_at !== "Invalid date" ? (
        <>
          <div>Scheduled Pick-up</div>

          <div className={styles.titles}>
            {moment(item.pickup_at).format("D MMM YYYY, hh:mmA") ===
            "Invalid date"
              ? ""
              : moment(item.pickup_at).format("D MMM YYYY, hh:mmA") +
                ", " +
                momenttz.tz(momenttz.tz.guess()).format("z")}
          </div>
        </>
      ) : (
        item.dropoff_at && (
          <>
            <div>Scheduled Drop-off</div>

            <div className={styles.titles}>
              {moment(item.dropoff_at).format("D MMM YYYY, hh:mmA") +
                ", " +
                momenttz.tz(momenttz.tz.guess()).format("z")}
            </div>
          </>
        )
      )}
    </div>
  );
};

export default DeliveryInfo;
