import { useEffect, useState } from "react";
import { useAdmin, useClickStore, useFilter, useMyStore, useTheme } from "../../../../store";
import { useDesktopOrLaptop } from "../../../../services/useDesktopOrLaptop";
import moment from "moment";
import DatePicker, { utils } from "react-modern-calendar-datepicker";
import { CheckIcon, SearchSvg } from "../../../../assets/svgs/Svgs";
import ButtonComp from "../../../ButtonComp/ButtonComp";
import DropdownReusable from "../../../shared/common/DropdownReusable";
import DownloadCsv from "../../../downloadCsv";
import { BiFilterAlt } from "react-icons/bi";
import Filter from "../../../Filter";
import styles from "./styles.module.css";
import classNames from "classnames";
import FiltersMenu from "../../../shared/common/FiltersMenu";
import { FILTER_TYPES } from "../../../../constants";
import ActiveFiltersBar from "../../../shared/common/FiltersMenu/ActiveFiltersBar";
import { AiOutlineClear } from "react-icons/ai";

const ListHeaderAdmin = () => {
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);

  const { setPageCount, isSandbox } = useMyStore();
  const { Colors } = useTheme();
  const { mdfontsize, isdesktop, smfontsize, xsfontsize } = useDesktopOrLaptop();
  const { getAllProvidersHandler, selectedProvider, setSelectedProvider, filterArray } = useFilter();
  const { allTimeVisible, startDate, setStartDate } = useClickStore();

  const {
    searchByDropoffAddress,
    setSearchByDropoffAddress,
    searchByDropoffPhoneNum,
    setSearchByDropoffPhoneNum,
    searchByDroppofName,
    setSearchByDroppofName,
    searchByPhoneNumber,
    setSearchByPhoneNumber,
    searchByName,
    setSearchByName,
    searchByAddress,
    setSearchByAddress,
    searchById,
    setSearchById,
    getAdminDeliveries,
    searchByProviderId,
    setSearchByProviderId,
    searchByBurqOrderId,
    setSearchByBurqOrderId,
  } = useAdmin();

  const deliveriesHandler = async (param) => {
    getAdminDeliveries({
      ...{
        test_mode: isSandbox,
        provider_id: selectedProvider?.id,
        statuses: param?.statuses ?? filterArray?.join(","),
        start_date: param?.startDate?.start ?? startDate?.start,
        end_date: param?.startDate?.end ?? startDate?.end,
      },
      ...param,
    });
  };

  const filtersDefinition = [
    {
      id: "search_by_pickup_id",
      title: "By Delivery Id",
      type: FILTER_TYPES.SEARCH,
      placeholder: "Delivery Id",
      filterState: searchById,
      setFilterState: (id) => {
        setSearchById(id);
        deliveriesHandler({
          search_by_pickup_id: id,
        });
      },
    },
    {
      id: "search_by_pickup_address",
      title: "By Pick-up Address",
      type: FILTER_TYPES.SEARCH,
      placeholder: "Pick-up Address",
      filterState: searchByAddress,
      setFilterState: (id) => {
        setSearchByAddress(id);
        deliveriesHandler({
          search_by_pickup_address: id,
        });
      },
    },
    {
      id: "search_by_pickup_name",
      title: "by Pick-up Name",
      type: FILTER_TYPES.SEARCH,
      placeholder: "Pick-up Name",
      filterState: searchByName,
      setFilterState: (id) => {
        setSearchByName(id);
        deliveriesHandler({
          search_by_pickup_name: id,
        });
      },
    },

    {
      id: "search_by_pickup_phone_number",
      title: "By Pick-up Phone Number",
      type: FILTER_TYPES.SEARCH,
      placeholder: "Pick-up Phone",
      filterState: searchByPhoneNumber,
      setFilterState: (id) => {
        setSearchByPhoneNumber(id);
        deliveriesHandler({
          search_by_pickup_phone_number: id,
        });
      },
    },
    {
      id: "search_by_dropoff_name",
      title: "By Dropoff Name",
      type: FILTER_TYPES.SEARCH,
      placeholder: "Dropoff Name",
      filterState: searchByDroppofName,
      setFilterState: (id) => {
        setSearchByDroppofName(id);
        deliveriesHandler({
          search_by_dropoff_name: id,
        });
      },
    },
    {
      id: "search_by_dropoff_phone_number",
      title: "By Dropoff Phone Number",
      type: FILTER_TYPES.SEARCH,
      placeholder: "Dropoff Phone Number",
      filterState: searchByDropoffPhoneNum,
      setFilterState: (id) => {
        setSearchByDropoffPhoneNum(id);
        deliveriesHandler({
          search_by_dropoff_phone_number: id,
        });
      },
    },
    {
      id: "search_by_dropoff_address",
      title: "By Dropoff Address",
      type: FILTER_TYPES.SEARCH,
      placeholder: "Dropoff Address",
      filterState: searchByDropoffAddress,
      setFilterState: (id) => {
        setSearchByDropoffAddress(id);
        deliveriesHandler({
          search_by_dropoff_address: id,
        });
      },
    },
    {
      id: "search_by_provider_id",
      title: "By Provider id",
      type: FILTER_TYPES.SEARCH,
      placeholder: "Provider id",
      filterState: searchByProviderId,
      setFilterState: (id) => {
        setSearchByProviderId(id);
        deliveriesHandler({
          short_id: id,
        });
      },
    },
    {
      id: "search_by_burq_order_id",
      title: "By Burq Order Id",
      type: FILTER_TYPES.SEARCH,
      placeholder: "Burq order id",
      filterState: searchByBurqOrderId,
      setFilterState: (id) => {
        setSearchByBurqOrderId(id);
        deliveriesHandler({
          search_by_burq_order_id: id,
        });
      },
    },
  ];

  const defaultFrom = {
    year: parseInt(moment().format("YYYY")),
    month: parseInt(moment().format("M")),
    day: parseInt(moment().format("D")),
  };
  const defaultTo = {
    year: parseInt(moment().format("YYYY")),
    month: parseInt(moment().format("M")),
    day: parseInt(moment().format("D")),
  };
  const defaultValue = {
    from: defaultFrom,
    to: defaultTo,
  };
  const [selectedDay, setSelectedDay] = useState(defaultValue);

  const formatInputValue = () => {
    if (!selectedDay?.from?.day || !selectedDay?.to?.day || !startDate) return "All Time";

    return `${selectedDay?.from?.month}/${selectedDay?.from?.day}-${
      selectedDay?.to ? selectedDay.to.month + "/" + selectedDay.to.day : ""
    }`;
  };

  const renderCustomInput = ({ ref }) => (
    <input
      id="calendarPickerIddasd"
      readOnly
      ref={ref} // necessary
      placeholder="Date"
      value={formatInputValue()}
      style={{
        fontFamily: "Poppins",
        // borderWidth: "1px",
        // borderColor: Colors.borderPrimary,
        backgroundColor: Colors.white,
        borderRadius: "10px",
        fontSize: isdesktop ? smfontsize : "16px",
        fontWeight: "500",
        color: Colors.fontDark,
        width: !isdesktop && "100px",
        maxWidth: "90px",
        height: "40px",
      }}
      className="focus:outline-none placeholder-gray-800 text-center " // a styling class
    />
  );
  const [providers, setProviders] = useState([]);

  const getProviders = async () => {
    const res = await getAllProvidersHandler();
    if (res) {
      setProviders(res.data);
    }
  };

  useEffect(() => {
    setSelectedDay(defaultValue);
    getProviders();
  }, [isSandbox]);

  return (
    <div className="w-full flex justify-between ">
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ minWidth: "130px" }}>
          <FiltersMenu menuItems={filtersDefinition} title="Search by" />
        </div>
        <div
          className="noScroll"
          style={{
            maxWidth: isdesktop ? "370px" : "150px",
            overflowX: "scroll",
          }}
        >
          <ActiveFiltersBar activeFilters={[...filtersDefinition]} />
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: "10px",
          }}
        >
          <div
            style={{
              fontSize: isdesktop ? mdfontsize : "15px",
              color: Colors.fontGreyLight,
              marginRight: "10px",
              width: !isdesktop && "100px",
              whiteSpace: "nowrap",
            }}
          >
            Date range:
          </div>
          {allTimeVisible && (
            <div
              style={{
                // width: "100px",
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              <DatePicker
                inputClassName="z-0 "
                calendarPopperPosition="bottom"
                renderInput={renderCustomInput}
                value={selectedDay ? selectedDay : utils.getToday()}
                colorPrimary="#3DD598" // added this
                colorPrimaryLight="#ECFBF5"
                // and this
                onChange={(day) => {
                  setSelectedDay(day);

                  if (day.from && day.to) {
                    let date = {
                      start: `${day.from.year}-${day.from.month}-${day.from.day}`,
                      end: `${day.to.year}-${day.to.month}-${day.to.day}`,
                    };
                    setStartDate(date);
                  } else {
                    setPageCount(0);
                  }
                }}
                inputPlaceholder="Date"
                maximumDate={utils().getToday()}
                formatInputText={formatInputValue}
              />
            </div>
          )}
          {startDate && (
            <button
              type="button"
              onClick={() => {
                setStartDate();
                setSelectedDay(defaultValue);
              }}
              style={{
                padding: "4px",
                borderRadius: "8px",
                fontFamily: "Poppins",
                fontSize: xsfontsize,
                color: Colors.fontGreyLight,
                marginRight: "4px",
                outline: "none",
                fontWeight: "500",
              }}
            >
              <AiOutlineClear color={Colors.fontGreyLight} size={isdesktop ? 22 : 26} />
            </button>
          )}
        </div>
      </div>

      <div
        style={{
          justifySelf: "flex-end",
        }}
        className="flex items-center"
      >
        <DropdownReusable
          onChange={() => {}}
          selectedItem={selectedProvider?.status}
          setSelectedItem={setSelectedProvider}
          items={providers}
          placeHolder="Provider Name"
          extraContainerClasses={styles.dropDownComponent}
          isDropdownOpened={isDropdownOpened}
          setIsDropdownOpened={setIsDropdownOpened}
        >
          <div
            className={styles.dropdownContainer}
            style={{
              borderColor: Colors.borderPrimary,
            }}
          >
            {providers.map((item, index) => {
              return (
                <div key={index} className={styles.oneItem}>
                  <div
                    onClick={() => {
                      if (item.id === selectedProvider) {
                        setSelectedProvider();
                      } else {
                        setSelectedProvider(item);
                      }
                    }}
                    className={classNames(styles.oneItemInDropdown)}
                  >
                    {item.status}
                  </div>
                  {item.id === selectedProvider.id && <CheckIcon />}
                </div>
              );
            })}
          </div>
        </DropdownReusable>
        <DownloadCsv admin={true} />
        <button
          style={{
            borderWidth: "1px",
            borderColor: "#D8D8D8",
            borderRadius: "6px",
            padding: isdesktop ? "10px 20px" : "10px 20px",
            marginRight: "0px",
          }}
          onClick={() => {
            setIsFilterVisible(!isFilterVisible);
          }}
          type="button"
          className="flex flex-row items-center focus:outline-none "
        >
          <div
            style={{
              fontFamily: "Poppins",
              color: Colors.fontGreyLight,
              display: "flex",
              alignItems: "center",
            }}
          >
            <BiFilterAlt size={18} />
            <span
              style={{
                marginLeft: "6px",
                fontSize: xsfontsize,
                whiteSpace: "nowrap",
              }}
            >
              Filter by
            </span>
          </div>
        </button>
        <Filter
          isAdmin
          setPageCount={setPageCount}
          startDate={startDate}
          isFilterVisible={isFilterVisible}
          setIsFilterVisible={setIsFilterVisible}
        />
      </div>
    </div>
  );
};

export default ListHeaderAdmin;
