import Pusher from "pusher-js";
import apiUrls from "../constants/apiUrls";
import axios from "axios";
// import { baseUrl } from "../runTimeConst";

const channelAuthorizationCustomHandler = async (
  { socketId, channelName },
  callback
) => {
  try {
    const { data } = await axios.post(
      apiUrls.PUSHER_CHANNEL_AUTHORIZATION,
      {
        socket_id: socketId,
        channel_name: channelName,
      },
      {
        headers: { "x-burq-client": "client-dashboard" },
        withCredentials: true,
      }
    );
    callback(null, { auth: data.auth, channel_data: data?.channel_data });
  } catch (error) {
    callback(error, null);
  }
};

export const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
  userAuthentication: {
    endpoint: apiUrls.PUSHER_USER_AUTHENTICATION,
  },
  channelAuthorization: {
    endpoint: apiUrls.PUSHER_CHANNEL_AUTHORIZATION,
    customHandler: channelAuthorizationCustomHandler,
  },
  cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
  forceTLS: true,
  encrypted: true,
});
