import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";

const CustomDetailsRightModal = ({
  children,
  isOpen,
  onClose,
  extraClasses,
  extraStyles,
}) => {
  const { isdesktop } = useDesktopOrLaptop();
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        style={{ zIndex: 100 }}
        className="relative"
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div
          style={extraStyles}
          className={`fixed right-0 top-0 bottom-0 overflow-y-auto ${extraClasses}`}
        >
          <div
            style={{ width: isdesktop ? "56%" : "90%" }}
            className="flex min-h-full items-center justify-center text-center fixed right-0 top-0 bottom-0"
          >
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-300 sm:duration-500"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-300 sm:duration-500"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <Dialog.Panel
                style={{ height: "100%" }}
                className="w-full overflow-hidden text-left align-middle transition-all"
              >
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default CustomDetailsRightModal;
