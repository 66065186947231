import React from "react";

import styles from "./styles.module.css";
import classNames from "classnames";
import { calculateCostLineItemsTotalAmount, sortObjectArrayByOrder } from "../../../utils/helpers";
import StatusBadgeReusable from "../../shared/common/StatusBadgeReusable";

import { iconHandlerForPayments, statusesBadgesObject } from "../../InvoicesHistory/utils";
import { InfoSvg } from "../../../assets/svgs/Svgs";
import ToolTip from "../../Tooltip/Tooltip";

const order = ["Delivery Fee", "Transaction Fee", "Platform Fee", "Tip"];

const PaymentInfoOnSideDrawer = ({ item }) => {
  const tooltipContainerForIconText = (
    <span className={styles.tooltipText}>
      Below are cost factors that will be billed to you directly by the provider based on your contract.
    </span>
  );
  return (
    <div className="flex  w-full">
      <div className={styles.amountCont}>
        {sortObjectArrayByOrder(item?.cost_line_items ?? [], order).map((lineItem, index) => {
          return (
            <div key={index} className={classNames("flex justify-between mt-2", styles.detailsOneLine)}>
              <div className={classNames(styles.oneLineTitle, styles.light)}>{lineItem.name}</div>
              <div className={classNames(styles.oneLineTitle, styles.dark)}>${(lineItem.amount / 100).toFixed(2)}</div>
            </div>
          );
        })}

        <div className={classNames("flex justify-between mt-6", styles.detailsOneLine)}>
          <div className={classNames(styles.oneLineTitle, styles.dark, styles.fontBigger, "font-bold")}>Total</div>
          <div className={classNames(styles.oneLineTitle, styles.dark, styles.fontBigger, "font-bold")}>
            {/* ${(item?.payment_amount / 100).toFixed(2)} */}${""}
            {(calculateCostLineItemsTotalAmount(item.cost_line_items) / 100).toFixed(2)}
          </div>
        </div>

        {(item?.upsell || item?.subsidized) && (
          <div className={classNames(styles.detailsOneLine, styles.noBorder, "flex justify-between mt-5 border-0")}>
            <div className={classNames(styles.oneLineTitle, styles.dark)}>
              {item?.subsidized ? "Subsidized" : item?.upsell && "Upsell"}
            </div>
            <div className={classNames(styles.oneLineTitle, styles.dark)}>
              ${item?.subsidized ? (item?.subsidized / 100).toFixed(2) : item?.upsell && (item?.upsell / 100).toFixed(2)}
            </div>
          </div>
        )}
        {item.byopk_cost && (
          <div>
            <hr className="mt-6" />

            <div className={classNames("flex justify-between mt-6", styles.detailsOneLine)}>
              <div className={classNames(styles.oneLineTitle, styles.dark, styles.fontBigger, "font-bold flex gap-1")}>
                <span>BYOPK Cost Factors</span>
                <span className="content-center" data-for={`infoToolTip-${item.id}`} data-tip>
                  <span className="cursor-pointer">
                    <InfoSvg />
                  </span>
                  <ToolTip
                    anchorSelect="#clickable"
                    clickable
                    backgroundColor="#fff"
                    className={styles.tooltipContainerForIcon}
                    id={`infoToolTip-${item.id}`}
                    disable={false}
                    place="right"
                    text={tooltipContainerForIconText}
                  />
                </span>
              </div>
            </div>
            <div className={classNames("flex justify-between mt-2", styles.detailsOneLine)}>
              <div className={classNames(styles.oneLineTitle, styles.light)}>{"Delivery Cost"}</div>
              <div className={classNames(styles.oneLineTitle, styles.dark)}>
                ${(item.byopk_cost.delivery_cost / 100).toFixed(2)}
              </div>
            </div>
            <div className={classNames("flex justify-between mt-2", styles.detailsOneLine)}>
              <div className={classNames(styles.oneLineTitle, styles.light)}>{"Tip"}</div>
              <div className={classNames(styles.oneLineTitle, styles.dark)}>${(item.byopk_cost.tip / 100).toFixed(2)}</div>
            </div>
          </div>
        )}
      </div>
      <div className={styles.paymentCont}>
        {item.payment_status && (
          <StatusBadgeReusable
            leftIcon={iconHandlerForPayments(item.payment_status, true)}
            // leftIcon={iconHandlerForPayments("failed", true)}
            hideIcon={false}
            // status={"failed"}
            status={item.payment_status}
            badgesObject={statusesBadgesObject}
            externalClassName={styles.badge}
            extraIconClasses={styles.iconClass}
            uppercase
          />
        )}
      </div>
    </div>
  );
};

export default PaymentInfoOnSideDrawer;
