import { FaCcDinersClub, FaCcJcb } from "react-icons/fa";
import { GrAmex } from "react-icons/gr";
import { SiDiscover } from "react-icons/si";
import { MasterCard, UnionpayIcon, VisaCard } from "../../assets/svgs/Svgs";
import unionpayPng from "../../assets/png/unionpay.png";
import { CiWarning } from "react-icons/ci";

export const cardLogoHandler = (cardIssuer, xsfontsize, Colors) => {
  return (
    <>
      {cardIssuer === "visa" ? (
        <VisaCard />
      ) : cardIssuer === "diners" ? (
        <FaCcDinersClub size={20} color={Colors.bluePrimary} />
      ) : cardIssuer === "amex" ? (
        <GrAmex size={28} color={Colors.bluePrimary} />
      ) : cardIssuer === "unionpay" ? (
        <div
          style={{
            color: Colors.bluePrimary,
            fontSize: xsfontsize,
            fontWeight: "500",
          }}
        >
          <img
            style={{ objectFit: "contain", height: "18px" }}
            src={unionpayPng}
            alt=""
          />
        </div>
      ) : cardIssuer === "discover" ? (
        <SiDiscover size={32} color={Colors.bluePrimary} />
      ) : cardIssuer === "jcb" ? (
        <FaCcJcb size={20} color={Colors.bluePrimary} />
      ) : (
        <MasterCard />
      )}
    </>
  );
};

export const cardNameHandler = (cardIssuer) => {
  return (
    <>
      {cardIssuer === "visa"
        ? "Visa"
        : cardIssuer === "diners"
        ? "Diners"
        : cardIssuer === "amex"
        ? "Amex"
        : cardIssuer === "unionpay"
        ? "Unionpay"
        : cardIssuer === "discover"
        ? "Discover"
        : cardIssuer === "jcb"
        ? "JCB"
        : "Mastercard"}{" "}
    </>
  );
};

export const verifyPM = (verification_url, Colors, xsfontsize) => {
  return (
    <div style={{ color: Colors.fontDark, fontSize: xsfontsize }}>
      Once you receive{" "}
      <a
        style={{ color: Colors.bluePrimary }}
        href="https://support.stripe.com/questions/i-cant-find-the-micro-deposit-verification-code-in-my-bank-statement-descriptor-what-should-i-do"
        target="_blank"
        rel="noreferrer"
      >
        micro-deposits
      </a>{" "}
      in your bank account (usually within 2-3 days after you added the payment
      method), please{" "}
      <a
        style={{ color: Colors.bluePrimary }}
        href={verification_url}
        target="_blank"
        rel="noreferrer"
      >
        click here
      </a>{" "}
      to complete verification.
    </div>
  );
};

// export const expiredVerificationLinkWarning = (
//   Colors,
//   xsfontsize,
//   onClickHandler
// ) => (
//   <div style={{ color: "#ff9966" }} className="flex items-start ">
//     <CiWarning size={30} />
//     <div
//       style={{
//         fontSize: xsfontsize,

//         marginLeft: "6px",
//       }}
//     >
//       The micro-deposit verification of this payment method has expired (usually
//       ten days after the payment method was added). Please click{" "}
//       <button
//         style={{ color: Colors.bluePrimary, cursor: "pointer" }}
//         onClick={onClickHandler}
//       >
//         here
//       </button>{" "}
//       to restart the verification process by re-entering your bank account info
//       and waiting for a new micro-deposit to be processed.
//     </div>
//   </div>
// );

export const allowedBadgesForBankAccounts = {
  unverified: { type: "warning", label: "Pending verification" },
  verification_expired: {
    type: "error",
    label: "Verification link expired",
  },
  declined: {
    type: "error",
    label: "declined",
  },
};

export const allowedBadgesForCards = {
  unverified: { type: "warning", label: "Pending verification" },
  declined: {
    type: "error",
    label: "declined",
  },
};
