import React, { useEffect, memo } from "react";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import { useTheme } from "../../store";

const SelectComp = ({
  value,
  defaultValue,
  list = [],
  setSelectedValue,
  placeholder = "Please Choose...",
  title,
  inputStyle = {},
}) => {
  const { Colors } = useTheme();

  const { smfontsize } = useDesktopOrLaptop();

  const styles = {
    inputs: {
      fontFamily: "Poppins",
      borderRadius: "8px",
      fontSize: smfontsize,
      fontWeight: "400",
      color: Colors.chartSecondLine,
      backgroundColor: Colors.white,
    },
  };

  useEffect(() => {
    if (defaultValue && list.length) {
      setSelectedValue(defaultValue);
    }
  }, []);

  const onChange = (e) => {
    e.preventDefault();
    const currentValue = e.target.value;
    setSelectedValue(currentValue);
  };

  return (
    <div className="flex items-center w-full content-space-between">
      {title && (
        <div
          style={{
            color: Colors.fontDark,
            marginRight: "5px",
            fontSize: smfontsize,
            fontWeight: "400",
          }}
        >
          {title}
        </div>
      )}
      <div style={{ width: "100%" }}>
        <select
          value={value}
          onChange={onChange}
          style={{ ...styles.inputs, ...inputStyle }}
          className="py-1 w-full focus:outline-none "
        >
          <option value="" disabled hidden>
            {placeholder}
          </option>
          {list.map((el, i) => (
            <option key={i} value={el.value}>
              {el.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default memo(SelectComp);
