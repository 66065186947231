import { createStore } from "aniuta";
import * as Sentry from "@sentry/react";
import axios from "axios";
import { useState } from "react";
import { baseUrl } from "../runTimeConst";

const useLogsStore = createStore({
  name: "LogsStore",
  Store: () => {
    const [userLogs, setUserLogs] = useState();
    const [userLogsLoading, setUserLogsLoading] = useState(true);

    const userLogsRequest = async (userId) => {
      try {
        setUserLogsLoading(true);
        const response = await axios.get(`${baseUrl}delivery/logs`, {
          headers: { "x-burq-client" : "client-dashboard" },
        });
        setUserLogs(response.data);
        setUserLogsLoading(false);
      } catch (error) {
        Sentry.captureException(error);
        console.log("USERLOGS ERROR", error);
      } finally {
        setUserLogsLoading(false);
      }
    };

    return {
      userLogsRequest,
      userLogsLoading,
      setUserLogsLoading,
      userLogs,
      setUserLogs,
    };
  },
});

export default useLogsStore;
