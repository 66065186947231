import { createStore } from "aniuta";
import { useState } from "react";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";
import { getDisputedList } from "../utils/api/deliveries";
import { FILTER_TYPES } from "../constants";
import { disputedStatuses } from "../components/Home/utils";

const useDisputeDeliveries = createStore({
  name: "useDisputeDeliveries",
  Store: () => {
    const [searchByDelId, setSearchByDelId] = useState("");
    const [searchByDropoffName, setSearchByDropoffName] = useState("");
    const [searchByDropoffNumber, setSearchByDropoffNumber] = useState("");

    const [dateRangeForDisputed, setDateRangeForDisputed] = useState();

    const [storesFilteredArray, setStoresFilteredArray] = useState([]);
    const [disputedStatusesFilter, setDisputedStatusesFilter] = useState(disputedStatuses);

    const [isDisputedListLoading, setIsDisputedListLoading] = useState(false);

    const [disputedDeliveriesData, setDisputedDeliveriesData] = useState();

    const disputedListHandler = async (id, params) => {
      setIsDisputedListLoading(true);
      const statuesForApi = disputedStatusesFilter.filter((item) => item.checked);
      const queryParams = {
        test_mode: params?.test_mode ?? undefined,
        search_by_delivery_id: params?.search_by_id ?? searchByDelId,
        search_by_dropoff_name: params?.search_by_dropoff_name ?? searchByDropoffName,
        search_by_dropoff_phone_number: params?.search_by_dropoff_phone_number ?? searchByDropoffNumber,
        ending_before: params?.ending_before ?? undefined,
        starting_after: params?.starting_after ?? undefined,
        store_ids: params?.store_ids?.map((item) => item.id) ?? storesFilteredArray?.map((item) => item.id),
        status: params?.status?.map((item) => item.back) ?? statuesForApi?.map((item) => item.back),
        start_date:
          params?.start_date !== undefined && params?.start_date !== null ? params.start_date : dateRangeForDisputed?.start,
        end_date: params?.end_date !== undefined && params?.end_date !== null ? params.end_date : dateRangeForDisputed?.end,
        // ...params,
      };
      try {
        const { data } = await getDisputedList(id, queryParams);

        if (params?.ending_before) {
          if (data?.data?.length < 1) {
            return "disablePrevious";
          }
        }

        setDisputedDeliveriesData(data);

        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          // toast.error("Something went wronga! Please try again.");
        }
      } finally {
        setIsDisputedListLoading(false);
      }
    };

    const disputedFiltersDefinitions = [
      {
        id: "search_by_id",
        title: "By Burq Delivery Id",
        type: FILTER_TYPES.SEARCH,
        placeholder: "Delivery id",
        filterState: searchByDelId,
        // filterState: searchByBurqOrderId,
        setFilterState: (id, merchantId, test_mode) => {
          disputedListHandler(merchantId, { search_by_id: id, test_mode: test_mode });
          setSearchByDelId(id);
        },
      },
      {
        id: "search_by_dropoff_name",
        title: "By Drop-off Name",
        type: FILTER_TYPES.SEARCH,
        placeholder: "Drop-off Name",
        filterState: searchByDropoffName,
        setFilterState: (id, merchantId, test_mode) => {
          disputedListHandler(merchantId, { search_by_dropoff_name: id, test_mode: test_mode });
          setSearchByDropoffName(id);
        },
      },
      {
        id: "search_by_dropoff_phone_number",
        title: "By Drop-off Phone Number",
        type: FILTER_TYPES.SEARCH,
        placeholder: "Drop-off Phone Number",
        filterState: searchByDropoffNumber,

        setFilterState: (id, merchantId, test_mode) => {
          disputedListHandler(merchantId, { search_by_dropoff_phone_number: id, test_mode: test_mode });
          setSearchByDropoffNumber(id);
        },
      },
    ];

    return {
      isDisputedListLoading,
      setIsDisputedListLoading,
      disputedListHandler,
      disputedFiltersDefinitions,
      disputedStatusesFilter,
      setDisputedStatusesFilter,

      disputedDeliveriesData,
      setDisputedDeliveriesData,

      storesFilteredArray,
      setStoresFilteredArray,

      dateRangeForDisputed,
      setDateRangeForDisputed,
      setSearchByDelId,

      setSearchByDropoffName,
      setSearchByDropoffNumber,
    };
  },
});

export default useDisputeDeliveries;
