import React, { useEffect } from 'react';
import styles from './styles/styles.module.css';

import ImageCarousal from './ImageCarousal';
import useViewportMeta from '../../customHooks/useViewportMeta';
import { Form, Formik } from 'formik';
import { useMerchant, useTheme } from '../../store';
import { formValidationSchema, initialValues } from './validationSchemas';
import { industries } from './utils';
import CustomTextField from './CustomTextField';
import CustomPhoneNumberField from './CustomPhoneNumberField';
import classNames from 'classnames';
import Hotjar from '@hotjar/browser';

const BusinessForm = ({ loginHandler }) => {
  useViewportMeta()
  const { createMerchantData } = useMerchant();
  const { Colors } = useTheme();

  useEffect(() => {
    if (Hotjar.isReady()) {
      Hotjar.event('Start_Onboarding_Page');
    }
  }, []);

  const onFormSubmit = async (values) => {
    if (Hotjar.isReady()) {
      Hotjar.event('Complete_Onboarding');
    }
    createMerchantData(values).then((res) => {
      if (res) {
        loginHandler();
      }
    });
  }

  return (
    <div className={styles.container}>
      <div className={styles.imageSection}>
        <h2>Manage and track deliveries</h2>
        <p>Keep an eye on orders even after they’re out the door. Track orders in real time and easily send your customers a single link so they can do the same.</p>
        <ImageCarousal />
      </div>
      <div className={classNames(styles.formSection, "kata")}>
        <h2>Let's get to know your business</h2>
        <Formik
          onSubmit={onFormSubmit}
          validationSchema={formValidationSchema}
          initialValues={initialValues}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            errors,
            touched,
            setFieldValue,
          }) => {
            return (
              <Form>
                <label htmlFor="standardized_industry">Industry</label>
                <select
                  name="standardized_industry"
                  value={values.standardized_industry}
                  draggable={true}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                >
                  {industries.map((item) => {
                    return (
                      <option key={item.back} value={item.back}>
                        {item.front}
                      </option>
                    );
                  })}
                </select>

                <p>
                  Your industry choice will set the delivery providers available to you.
                  Don’t worry, you can always change it later in settings!
                </p>

                <label htmlFor="name">Business Name</label>
                <CustomTextField
                  id="name"
                  name="name"
                  lable="name"
                  placeholder="Enter your business name"
                  type="text"
                />

                <label htmlFor="address">Business Address</label>
                <CustomTextField
                  id="address"
                  name="address"
                  address={true}
                  setFieldValue={setFieldValue}
                  lable="address"
                  placeholder="Enter your business address"
                  type="text"
                />

                <label htmlFor="phone-number">Phone Number</label>
                <CustomPhoneNumberField
                  name="phone_number"
                  buttonStyle={{
                    backgroundColor: Colors.bgSettings,
                    borderWidth: "1px",
                    borderColor: "#E2E2EA",
                    borderRadius: "10px",
                  }}
                  style={{
                    ...{
                      color: Colors.fontGreyLight,
                      backgroundColor: Colors.bgSettings,
                      border: `1px solid ${Colors.tableBorderGray}`,
                      paddingLeft: "48px",
                      marginBottom: "27px",
                    },
                  }}
                  containerStyles={{ height: "44px", width: "100%", marginBottom: "27px", }}
                  errorTextStyles={{ fontSize: "12px" }}
                  placeholder="+19712030805"
                  type="tel"
                  format="+1 (###) ###-####"
                  value={values.phone_number}
                />

                <label htmlFor="website">Business Website (optional)</label>
                <CustomTextField
                  url="true"
                  name="website"
                  lable="website"
                  placeholder="Enter your website"
                />

                <label htmlFor="tax_or_ein">Company Registration Number (optional)</label>
                <CustomTextField
                  name="tax_or_ein"
                  lable="tax_or_ein"
                  placeholder="Tax Id or EIN"
                />

                <button type="submit">Go to dashboard</button>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default BusinessForm;
