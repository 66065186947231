import React, { Children, useState } from "react";
import { MdContentCopy } from "react-icons/md";
import styles from "./styles.module.css";
import classNames from "classnames";

const CustomTooltip = ({ children, content, setCopiedText, extraContainerClasses = "", extraTooltipClasses }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  return (
    <div
      onMouseEnter={() => {
        setTooltipVisible(true);
      }}
      onMouseLeave={() => {
        setTooltipVisible(false);
      }}
      className={classNames(styles.copyIcon, extraContainerClasses)}
    >
      <div
      // onMouseEnter={() => {
      //   setTooltipVisible(true);
      // }}
      // onMouseLeave={() => {
      //   setTooltipVisible(false);
      // }}
      >
        {children}
      </div>

      <div
        className={classNames(
          styles.tooltip,
          {
            [styles.fadeIn]: tooltipVisible,
            [styles.fadeOut]: !tooltipVisible,
          },
          extraTooltipClasses
        )}
      >
        <div className={styles.rectangle}></div>
        {content}
      </div>
    </div>
  );
};
export default CustomTooltip;
