import React, { useEffect, useState } from "react";
import {
  useFilter,
  useMerchant,
  useMyStore,
  useStatementsStore,
  useTheme,
  userDataStore,
} from "../../../../store";
import { Loading } from "../../../../assets/svgs/Svgs";

import Pagination from "../../../shared/Pagination";

import TableHeader from "./TableHeader";
import StatementsItem from "./StatementsItem";
import FilterWithConnectedAccounts from "./FilterWithConnectedAccounts";
import FilterWithStores from "./FilterWithStores";
import FilterWithBillingEvent from "./FilterWithBillingEvent";
import { useDesktopOrLaptop } from "../../../../services/useDesktopOrLaptop";
import { findLatestBillingPeriod } from "../../utlis";

const PAGE_LIMIT = 30;

const PreviousStatements = () => {
  const { Colors } = useTheme();
  const { xlfontsize } = useDesktopOrLaptop();

  const { isSandbox } = useMyStore();
  const { selectedBillingPeriod, setSelectedBillingPeriod } = useFilter();
  const {
    getStatementsForMerchant,
    invoicesData,
    isInvoiceListLoading,
    getBillingEventsHandler,
  } = useStatementsStore();

  const { authInfo } = userDataStore();

  const { merchantData } = useMerchant();

  const [selectedStoreForStatements, setSelectedStoreForStatements] =
    useState();
  const [selectedConnectedAccount, setSelectedConnectedAccount] = useState();

  const [connectedTriggerer, setConnectedTriggerer] = useState(false);
  const [storesTrigerer, setStoresTrigerer] = useState(false);

  const statemenetsHandler = async (param) => {
    let params = {
      test_mode: param.isSandbox ?? isSandbox,
      store_id: param.store
        ? param.store.id
        : selectedStoreForStatements?.id ?? "",
      ending_before: param.ending_before,
      starting_after: param.starting_after,
      limit: PAGE_LIMIT,
      billing_event_id:
        param?.billing_event_id ?? selectedBillingPeriod?.id ?? "",
    };

    const id = param?.store?.merchant_account_id
      ? param.store.merchant_account_id
      : selectedStoreForStatements?.merchant_account_id ?? param.merchantId;
    await getStatementsForMerchant(id, params);
  };

  const billingPeriodHandler = async (param) => {
    let params = {
      test_mode: param?.isSandbox ?? isSandbox,
      ending_before: param?.ending_before,
      starting_after: param?.starting_after,
      limit: PAGE_LIMIT,
    };
    const res = await getBillingEventsHandler(
      authInfo.merchantAccountId,
      params
    );
    return res;
  };

  const firstLoadHandler = async () => {
    const { data } = await billingPeriodHandler();
    const period = findLatestBillingPeriod(data);
    setSelectedBillingPeriod(period);

    statemenetsHandler({
      test_mode: isSandbox,
      billing_event_id: period.id,
      merchantId: authInfo.merchantAccountId,
    });
  };

  useEffect(() => {
    let subscribe = true;
    if (subscribe) {
      if (authInfo) {
        firstLoadHandler();
      }
    }

    return () => {
      subscribe = false;
    };
  }, [authInfo, isSandbox]);

  return (
    <>
      <div className="w-full flex justify-end">
        {merchantData?.account_type === "platform" && (
          <FilterWithConnectedAccounts
            selectedConnectedAccount={selectedConnectedAccount}
            setSelectedConnectedAccount={setSelectedConnectedAccount}
            statementsListHandler={statemenetsHandler}
            setConnectedTriggerer={setConnectedTriggerer}
            storesTrigerer={storesTrigerer}
          />
        )}
        <FilterWithStores
          selectedStoreForStatements={selectedStoreForStatements}
          setSelectedStoreForStatements={setSelectedStoreForStatements}
          statementsListHandler={statemenetsHandler}
          connectedTriggerer={connectedTriggerer}
          setStoresTrigerer={setStoresTrigerer}
        />
        <FilterWithBillingEvent
          selectedPeriod={selectedBillingPeriod}
          statementsListHandler={statemenetsHandler}
          setSelectedPeriod={setSelectedBillingPeriod}
        />
      </div>
      <TableHeader />

      <div
        style={{
          height: "calc(100% - 255px)",
          overflowY: "auto",
        }}
      >
        {isInvoiceListLoading && (
          <div
            style={{ minHeight: "100px" }}
            className="w-full flex items-center justify-center kata"
          >
            <Loading
              color={Colors.bluePrimary}
              className="h-14 w-14 animate-spin"
            />
          </div>
        )}
        {invoicesData?.data?.length > 0 ? (
          invoicesData.data.map((invoiceObject, index) => {
            return (
              <StatementsItem
                key={invoiceObject.id}
                invoiceObject={invoiceObject}
              />
            );
          })
        ) : (
          <div
            style={{ color: Colors.fontDark, fontSize: xlfontsize }}
            className="h-full w-full flex justify-center items-center"
          >
            There is no data within selected period
          </div>
        )}
      </div>
      <div className="flex items-center justify-end pr-28 mt-9">
        <Pagination
          data={invoicesData}
          onGetPaginationData={statemenetsHandler}
          dependencies={[
            selectedConnectedAccount,
            selectedStoreForStatements,
            selectedBillingPeriod,
          ]}
        />
      </div>
    </>
  );
};

export default PreviousStatements;
