import { useState } from "react";
import * as Sentry from "@sentry/react";
import { createStore } from "aniuta";
import { toast } from "react-toastify";
import {
  transactionsForStatement,
  setStatementsForMerchant,
  setStatementById,
  setBillingEvents,
  setRecentTransactions,
  getRelatedStatements,
  depositStatementsInitiatePayment,
} from "../utils/api/statements";

const useStatementsStore = createStore({
  name: "useInvoice",
  Store: () => {
    const [invoicesData, setInvoicesData] = useState();

    const [isInvoiceListLoading, setIsInvoiceListLoading] = useState(false);

    const [selectedStatement, setSelectedStatement] = useState();

    const getStatementsForMerchant = async (id, params) => {
      setIsInvoiceListLoading(true);
      try {
        const { data } = await setStatementsForMerchant(id, params);
        setInvoicesData(data);
        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsInvoiceListLoading(false);
      }
    };

    const [
      isTransactionsForStatementLoading,
      setIsTransactionsForStatementLoading,
    ] = useState(false);

    const getTransactionsForStatement = async (id, params) => {
      setIsTransactionsForStatementLoading(true);
      try {
        const { data } = await transactionsForStatement(id, params);
        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsTransactionsForStatementLoading(false);
      }
    };

    const [isSingleDepositStatement, setIsSingleDepositStatement] =
      useState(false);

    const getSingleDepositStatement = async (id) => {
      setIsSingleDepositStatement(true);
      try {
        const { data } = await setStatementById(id);
        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsSingleDepositStatement(false);
      }
    };

    const [isBillingEventsLoading, setIsBillingEventsLoading] = useState(false);
    const [billingEventsData, setBillingEventsData] = useState();

    const getBillingEventsHandler = async (id, params) => {
      setIsBillingEventsLoading(true);
      try {
        const { data } = await setBillingEvents(id, params);
        setBillingEventsData(data);
        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsBillingEventsLoading(false);
      }
    };

    const [isRecentTransactionsLoading, setIsRecentTransactionsLoading] =
      useState(false);

    const [recentTransactionsData, setRecentTransactionsData] = useState();

    const getRecentTransactions = async (id, params) => {
      setIsRecentTransactionsLoading(true);
      try {
        const { data } = await setRecentTransactions(id, params);
        setRecentTransactionsData(data);
        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsRecentTransactionsLoading(false);
      }
    };

    const [relatedStatementsData, setRelatedStatementsData] = useState(null);
    const [isRelatedStatementsLoading, setIsRelatedStatementsLoading] =
      useState(true);

    const getRelatedStatementsList = async (id, params, notLoad) => {
      if (!notLoad) {
        setIsRelatedStatementsLoading(true);
      }
      try {
        const { data } = await getRelatedStatements(id, {
          limit: 30,
          ...params,
        });
        setRelatedStatementsData(data);
        return data.data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsRelatedStatementsLoading(false);
      }
    };

    const depositStatementsInitiatePaymentWorker = async (id, cb) => {
      try {
        const { data } = await depositStatementsInitiatePayment(id);
        toast.success("Thank you for initiating the payment.")
        cb(null, data);
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
        cb(error, null);
      }
    };

    return {
      getStatementsForMerchant,
      getTransactionsForStatement,
      invoicesData,
      setInvoicesData,
      isInvoiceListLoading,
      setIsInvoiceListLoading,
      selectedStatement,
      setSelectedStatement,
      isTransactionsForStatementLoading,
      setIsTransactionsForStatementLoading,
      isSingleDepositStatement,
      getSingleDepositStatement,

      isBillingEventsLoading,
      setIsBillingEventsLoading,
      getBillingEventsHandler,
      billingEventsData,
      setBillingEventsData,

      isRecentTransactionsLoading,
      setIsRecentTransactionsLoading,
      getRecentTransactions,
      recentTransactionsData,
      setRecentTransactionsData,

      isRelatedStatementsLoading,
      relatedStatementsData,
      getRelatedStatementsList,
      depositStatementsInitiatePaymentWorker,
    };
  },
});

export default useStatementsStore;
