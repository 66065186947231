import React from "react";
import styles from "./styles/paymentHistoryListHeader.module.css";

const PaymentHistoryListHeader = () => {
  return (
    <>
      <tr>
        <th className={styles.columnTitle}>Date</th>
        <th className={styles.columnTitle}>Method</th>
        <th className={styles.columnTitle}>Amount</th>
        <th className={styles.columnTitle}>Status</th>
        <th></th>
      </tr>
    </>
  );
};

export default PaymentHistoryListHeader;
