import React, { useEffect } from "react";
import { usePartnerStore } from "../../../../../store";
import PartnerUserListItem from "./PartnerUserListItem";
import Loader from "../../../../shared/atoms/Loader";
import classNames from "classnames";
import styles from "../../styles/PricingRules/PartnerUsersList/styles.module.css";

const PartnerUsersList = ({ setSelectedPartner, setActiveStep }) => {
  const { getPartnerUsersWorker, partnerUsers, isPartnerUsersLoading } =
    usePartnerStore();

  const getPartnerUsers = () => {
    getPartnerUsersWorker();
  };

  useEffect(() => {
    getPartnerUsers();
  }, []);

  return (
    <div className={classNames(styles.container, "kata")}>
      <div className={styles.heading}>Select Partner</div>
      <div className={styles.listContainer}>
        {isPartnerUsersLoading && <Loader />}

        {!isPartnerUsersLoading &&
          partnerUsers?.data?.map((partnerUser, index) => {
            return (
              <PartnerUserListItem
                index={index}
                key={partnerUser.id}
                partnerUser={partnerUser}
                setSelectedPartner={setSelectedPartner}
                setActiveStep={setActiveStep}
              />
            );
          })}
      </div>
    </div>
  );
};

export default PartnerUsersList;
