import React, { useEffect, useState } from "react";
import DropdownReusable from "../../../shared/common/DropdownReusable";
import styles from "./styles.module.css";
import classNames from "classnames";
import { CheckIcon } from "../../../../assets/svgs/Svgs";
import { useIncidents } from "../../../../store";
import CustomTooltip from "../../../shared/atoms/CustomTooltip";
import { IoIosInformationCircle } from "react-icons/io";

const RejectionReasons = ({ incidentPayload, setIncidentPayload, disabled }) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [rejectionReasonsList, setRejectionReasonsList] = useState();
  const { getRejectionReasonsHandler } = useIncidents();
  useEffect(() => {
    if (!rejectionReasonsList) {
      rejectionReasonsHandler();
    }
  }, []);
  const rejectionReasonsHandler = async () => {
    const data = await getRejectionReasonsHandler();

    setRejectionReasonsList(data?.data);
  };

  return (
    <div className="flex w-full justify-between items-start mt-4">
      <div className={styles.title}>
        Add refund denial reason:{" "}
        {/* <CustomTooltip
          extraTooltipClasses={styles.tooltipExtras}
          content={
            <div className={styles.contentContainer}>
              <div className={styles.contentTitles}>Signature Required:</div>
              <div className={styles.contentDescs}>The provider will be unable to complete this delivery without a signature</div>
              <div className={classNames(styles.contentTitles, "mt-3")}>Contactless:</div>
              <div className={styles.contentDescs}>A proof of delivery will be mandatory to complete this order.</div>
            </div>
          }
          extraContainerClasses="ml-2"
        >
          <span className={styles.infoIcon}>
            <IoIosInformationCircle />
          </span>
        </CustomTooltip> */}
      </div>
      <DropdownReusable
        showCleaner={false}
        disabled={disabled}
        selectedItem={
          rejectionReasonsList?.find((item) => item?.internal_name === incidentPayload.refund_rejection_reason)?.display_name
        }
        placeHolder="Select Reason"
        extraContainerClasses={classNames(styles.dropDownComponent)}
        inputExtras={classNames(styles.dropdownInput)}
        extraPlaceholderStyles={styles.placeholderStyle}
        isDropdownOpened={isDropdownVisible}
        setIsDropdownOpened={setIsDropdownVisible}
      >
        <div className={styles.dropdownContainer}>
          {/* <div className={styles.oneItem}>
            <div
              onClick={() => {
                const _incidentPayload = { ...incidentPayload };
                _incidentPayload.refund_rejection_reason = undefined;
                setIncidentPayload(_incidentPayload);
                setIsDropdownVisible(false);
              }}
              className={classNames(styles.oneItemInDropdown)}
            >
              Select Reason
            </div>
            {!incidentPayload.refund_rejection_reason && <CheckIcon className={styles.placeholderStyle} />}
          </div> */}
          {rejectionReasonsList?.map((item) => (
            <div key={item?.id} className={styles.oneItem}>
              <div
                onClick={() => {
                  const _incidentPayload = { ...incidentPayload };
                  _incidentPayload.refund_rejection_reason = item.internal_name;
                  setIncidentPayload(_incidentPayload);
                  setIsDropdownVisible(false);
                }}
                className={classNames(styles.oneItemInDropdown)}
              >
                {item?.display_name}
              </div>
              {incidentPayload.refund_rejection_reason === item?.internal_name && (
                <CheckIcon className={styles.placeholderStyle} />
              )}
            </div>
          ))}
        </div>
      </DropdownReusable>
    </div>
  );
};

export default RejectionReasons;
