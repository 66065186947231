import classNames from "classnames";

import styles from "./styles.module.css";
import Hyperlink from "../../shared/atoms/Hyperlink";
import { RerouteIcon } from "../Svgs";
import { useNavigate } from "react-router-dom";

const RerouteDeliveryBanner = ({ deliveryId }) => {
  let history = useNavigate();

  return (
    <div className={styles.card}>
      <div className={styles.header}>
        <div className="flex items-center">
          <RerouteIcon />
          <div>
            <div className={classNames(styles.reviewStatement)}>This is a rerouted delivery</div>
            <div className={classNames(styles.delIdTitle)}>
              Original delivery ID: {deliveryId}
            </div>
          </div>
        </div>

        <Hyperlink
          text="View original delivery"
          onClick={() => {
            history(`?deliveryId=${deliveryId}`);
          }}
        />
      </div>
    </div>
  );
};

export default RerouteDeliveryBanner;
