import React, { useEffect, useMemo, useState } from "react";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import { useOnboardingStore, useTheme } from "../../store";
import burq from "../../assets/png/g10.png";
import dickeys from "../../assets/png/dickeys.png";
import NewPasswordComp from "./NewPasswordComp";
import PaymentInfo from "./PaymentInfo";
import { useParams } from "react-router-dom";
import { Loading } from "../../assets/svgs/Svgs";
import FinishElement from "./FinishElement";

import OnboardingHeader from "../../components/ConnectedAccountOnboarding/OnboardingHeader";
import styles from "./styles/onboarding.module.css";
import OnboardingFooter from "../../components/ConnectedAccountOnboarding/OnboardingFooter";
import useViewportMeta from "../../customHooks/useViewportMeta";

const Onboarding = () => {
  useViewportMeta();

  const { Colors } = useTheme();

  const { step, invitationDetails, getInvitationDetails, platformInfo, getPlatformInfo, savePassword, isLoading, setIsLoading } =
    useOnboardingStore();

  const { id } = useParams();

  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [password, setPassword] = useState();
  const submitForm = async () => {
    if (!isButtonEnabled) return;

    savePassword(id, password);
  };

  const loadHandler = async () => {
    const res = await getInvitationDetails({ id }, platformInfo?.redirect_url);
    getPlatformInfo(res?.platform_account_id);
  };
  useEffect(() => {
    loadHandler();
  }, [id]);

  const renderFormStep = () => {
    switch (step) {
      case 1:
        return <PaymentInfo invitationId={id} platformInfo={platformInfo} />;
      case 2:
        return (
          <NewPasswordComp
            isButtonEnabled={isButtonEnabled}
            setIsButtonEnabled={setIsButtonEnabled}
            invitationId={id}
            platformInfo={platformInfo}
            // submitForm={submitForm}
            password={password}
            setPassword={setPassword}
          />
        );
      case 3:
        return <FinishElement platformInfo={platformInfo} />;
      default:
        return <PaymentInfo invitationId={id} platformInfo={platformInfo} />;
    }
  };

  if (!invitationDetails) {
    return (
      <div className="w-full h-screen flex justify-center items-center">
        <Loading color={Colors.bluePrimary} className="h-12 w-12 animate-spin" />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <div className={styles.background}></div>
        <div style={{ height: "calc(100% - 116px)", width: "100%", padding: "25px 45px" }}>
          <OnboardingHeader />
          <div style={{ margin: "20px 0px 0px 0px" }} className="w-full flex justify-center items-center"></div>

          <div
            style={{
              maxHeight: "calc(100% - 170px)",
              // maxHeight: "calc(100% - 338px)",
              height: "100%",
              overflowY: "auto",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "start",
              padding: "0px 0px 30px 0px",
            }}
          >
            {renderFormStep()}
          </div>
        </div>

        <OnboardingFooter
          isLoading={isLoading}
          onClick={
            step === 1
              ? () => {
                  const button = document.getElementById("submitForElemntId");
                  button.addEventListener("click", () => {
                    setIsLoading(true);
                  });

                  button.click();
                }
              : submitForm
          }
          buttonTitle={step === 1 ? "Next" : "Finish"}
          hideButton={step === 3}
          disabled={step !== 1 && !isButtonEnabled}
        />
      </div>
    </div>
  );
};

export default Onboarding;
