import React from "react";
import { useTheme } from "../../../../../store";
import { useDesktopOrLaptop } from "../../../../../services/useDesktopOrLaptop";

import ImagesContainer from "../../../../shared/ImagesContainer";
import SignatureContainer from "../../../../shared/SignatureContainer";

import IncidentDetails from "./IncidentDetails";
import RerouteTracking from "./RerouteTracking";
import AmountDetails from "./AmountDetails";
import StatusTimeline from "./StatusTimeline";
import ItemsDetails from "./ItemsDetails";
import StopsDetails from "./StopsDetails";
import ActionButtons from "./ActionButtons";
import CancellationReason from "./CancellationReason";
import DeliveryInfo from "./DeliveryInfo";
import DriverContain from "../../common/DriverDetails";
import Badge from "../../../../shared/atoms/Badge";

export default function DeliverySummary({
  item,
  statusesHystory,
  setCancelVisible,
  setIsIncidentVisible,
}) {
  const { Colors } = useTheme();
  const { mdfontsize } = useDesktopOrLaptop();
  let statusSocket = item?.status;

  return (
    <>
      <div className="flex flex-col px-7 pb-10 mt-4 justify-start w-full">
        <div
          style={{
            fontSize: mdfontsize,
            color: Colors.fontDark,
          }}
        >
          {item?.delivery_incident && <IncidentDetails item={item} />}
          {item?.reroute_child_delivery_id && (
            <RerouteTracking
              label="Delivery rerouted. New ID"
              id={item.reroute_child_delivery_id}
            />
          )}
          {item?.reroute_parent_delivery_id && (
            <RerouteTracking
              label="This is rerouted Delivery from Delivery ID"
              id={item.reroute_parent_delivery_id}
            />
          )}
          <DeliveryInfo item={item} requestId={item?.id} />
        </div>
        <div style={{ flexDirection: "row", display: "flex" }}>
          <div style={{ width: "65%" }}>
            <StopsDetails item={item} name={item?.pickup_name} />

            <ItemsDetails item={item} />
          </div>
          <div style={{ width: "35%" }}>
            {statusesHystory?.length > 0 && (
              <StatusTimeline
                statusSocket={statusSocket}
                statusesHystory={statusesHystory}
              />
            )}
            {item?.cancellation_reason && <CancellationReason item={item} />}
            <ActionButtons
              setIsIncidentVisible={setIsIncidentVisible}
              item={item}
            />
            {item?.proof_of_delivery?.length > 0 && (
              <ImagesContainer images={item.proof_of_delivery} id={item.id} />
            )}
            {item?.signature && (
              <SignatureContainer image={item.signature} id={item.id} />
            )}
            <AmountDetails item={item} />
          </div>
        </div>
      </div>
      <DriverContain
        item={item}
        statusSocket={statusSocket}
        setCancelVisible={setCancelVisible}
      />
    </>
  );
}
