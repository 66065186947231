import React, { useState } from "react";
import styles from "./styles.module.css";
import classNames from "classnames";
import { BsTelephone } from "react-icons/bs";

const DriverPhone = ({ phoneNumber, brandingColor }) => {
  const [isPhoneNumberVisible, setIsPhoneNumberVisible] = useState(false);
  return (
    <button
      onClick={() => {
        setIsPhoneNumberVisible(true);
      }}
      className="flex items-center"
      type="button"
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
          if (phoneNumber) {
            setIsPhoneNumberVisible((prev) => !prev);
          }
        }}
        className={classNames(
          styles.phoneNumber,
          `flex items-center justify-center`
        )}
      >
        <BsTelephone
          color={brandingColor ?? "#0062FF"}
          className={styles.phoneIcon}
        />
      </div>
      {isPhoneNumberVisible && (
        <a className={styles.number} href={phoneNumber && `tel:${phoneNumber}`}>
          {phoneNumber && phoneNumber[0] === "+"
            ? phoneNumber
            : "+" + phoneNumber}
        </a>
      )}
    </button>
  );
};
export default DriverPhone;
