import React from "react";
import { useTheme } from "../../../../store";
import { useDesktopOrLaptop } from "../../../../services/useDesktopOrLaptop";
import { MdSearch } from "react-icons/md";
import { RiCheckboxBlankCircleFill } from "react-icons/ri";

const MenuItemButton = ({ title, filterState, dropdown, setDropdown }) => {
  const { smfontsize, isScalingDisabled } = useDesktopOrLaptop();
  const { Colors } = useTheme();
  return (
    <button
      type="button"
      aria-haspopup="menu"
      aria-expanded={dropdown ? "true" : "false"}
      onClick={() => setDropdown((prev) => !prev)}
    >
      <div
        style={{
          paddingRight: "8px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <MdSearch size={16} />
      </div>
      <div
        style={{
          fontFamily: "Poppins",
          fontSize: isScalingDisabled ? "14px" : smfontsize,
          display: "flex",
          alignItems: "center",
          padding: "2px 0",
          gap: "8px",
        }}
      >
        {title}{" "}
        {filterState && (
          <RiCheckboxBlankCircleFill color={Colors.greenDark} size={12} />
        )}
      </div>
    </button>
  );
};

export default MenuItemButton;
