import React from "react";
import Avatar from "react-avatar";
import { useMerchant, useMyStore } from "../../../store/index";
import { useTheme } from "../../../store";
import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";
import Cookies from "js-cookie";
import Button from "../../shared/atoms/Button";
import styles from "./styles/usersListItem.module.css";
import { setCookie } from "../../../utils";

function Users({ item, setSelectedUserRow }) {
  const { Colors, themeChanger } = useTheme();
  const { userId, setUserId } = useMyStore();

  const { getMerchantData, setIdOfUser, setSelectedUser, setAvatar } = useMerchant();
  const { isdesktop, smfontsize } = useDesktopOrLaptop();
  return (
    <div
      style={{
        backgroundColor: userId === item.merchantAccountId ? Colors.bgGrey : "",
        borderRadius: "10px",
        padding: "0 10px",
      }}
      className={`w-full flex items-center flex-row justify-between ${
        themeChanger ? "hover:bg-gray-100 " : ""
      } focus:outline-none`}
    >
      <div
        onClick={() => {
          getMerchantData(item.merchantAccountId);
          setUserId(item.merchantAccountId);
          setAvatar(item.image);
          setIdOfUser(item.id);
          setSelectedUser(item);
          setCookie("sandbox", item?.sandboxKey);
          setCookie("prod", item?.prodKey);
        }}
        style={{ padding: "10px 8px", width: "100%" }}
        className="flex flex-row justify-between items-center  focus:outline-none"
      >
        <div className="flex items-center " style={{ width: "25%" }}>
          <div
            style={{
              height: isdesktop ? "30px" : "40px",
              width: isdesktop ? "30px" : "40px",
              borderRadius: "100px",
              overflow: "hidden",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0px 10px",
            }}
          >
            {item?.image ? (
              <img
                style={{
                  height: "100%",
                }}
                src={item.image}
                alt="user avatar"
              />
            ) : (
              <Avatar name={item?.email.split("").join(" ")} size={isdesktop ? 30 : 40} maxInitials={2} />
            )}
          </div>
          <div
            id={`usersId${item.name}`}
            style={{
              fontSize: smfontsize,
              marginLeft: "10px",
              display: "flex",
              justifyContent: "start",
              fontWeight: "500",
              color: userId === item.merchantAccountId ? Colors.bluePrimary : Colors.fontDark,
            }}
          >
            {item.first_name || "" + " " + item.last_name || ""}
          </div>
        </div>

        <div
          className="break-all"
          style={{
            fontSize: smfontsize,
            display: "flex",
            fontWeight: "500",
            color: userId === item.merchantAccountId ? Colors.bluePrimary : Colors.fontDark,
            width: "35%",
            justifyContent: "start",
            marginLeft: "10px",
          }}
        >
          {item?.name}
        </div>
        <div
          className="break-all"
          style={{
            fontSize: smfontsize,
            display: "flex",
            fontWeight: "500",
            color: userId === item.merchantAccountId ? Colors.bluePrimary : Colors.fontDark,
            width: "40%",
            justifyContent: "start",
            marginLeft: "10px",
          }}
        >
          {item.email}
        </div>
      </div>
      <Button
        text="Rules"
        onClick={(e) => {
          e.stopPropagation();
          setSelectedUserRow(item);
        }}
        externalClassName={styles.ruleButton}
      />
    </div>
  );
}

export default Users;
