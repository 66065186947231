import React from "react";
import styles from "./styles/activeFiltersBar.module.css";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FILTER_TYPES } from "../../../../constants";
import classNames from "classnames";

const ActiveFiltersBar = ({ activeFilters = [] }) => {
  return (
    <div className={classNames(styles.container, "kata")}>
      {activeFilters
        .filter((fl) => fl.filterState)
        .map((filter) => {
          const value =
            filter.type === FILTER_TYPES.TYPE_AND_SEARCH ||
            filter.type === FILTER_TYPES.RADIO
              ? filter["filterState"][filter.label]
              : filter.filterState;
          return (
            <div className={styles.filterItem}>
              {filter.title + ": " + value}
              <AiOutlineCloseCircle
                onClick={() => {
                  filter.setFilterState("");
                }}
                className={styles.close}
              />
            </div>
          );
        })}
    </div>
  );
};

export default ActiveFiltersBar;
