import { useFormikContext } from "formik";
import React from "react";
import {
  AddressSvg,
  MultiQuoteIcon,
  ReviewSvg,
  TimeSvg,
  VehicleSvg,
} from "../../assets/svgs/Svgs";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import { useMyStore, useTheme } from "../../store";

const CreateDeliveryTabsHeaders = ({
  steps,
  setSteps,
  rerouteItem,
  isReroute,
  edit,
  create,
}) => {
  const { values, errors, touched } = useFormikContext();

  const { setQuoteRes } = useMyStore();
  const { Colors } = useTheme();

  const { mdfontsize, smfontsize } = useDesktopOrLaptop();

  const styles = {
    svgBg: {
      height: "45px",
      width: "45px",
      backgroundColor: Colors.bgListHeader,
      display: "flex",
      borderRadius: "100%",
      justifyContent: "center",
      alignItems: "center",
      marginRight: "10px",
      marginLeft: "-3px",
    },
    inputNames: {
      fontWeight: "500",
      fontSize: mdfontsize,
      fontFamily: "Poppins",
      marginBottom: "16px",
    },
    inputs: {
      fontFamily: "Poppins",
      borderRadius: "8px",
      fontSize: smfontsize,
      fontWeight: "400",
      color: Colors.fontDark,
      backgroundColor: Colors.buttonBg,
    },
    headerText: {
      fontFamily: "Poppins",
      fontSize: smfontsize,
      fontWeight: "500",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
    },
    betweenLine: {
      width: "60px",
      height: "2px",
    },
  };

  return (
    <div
      style={{
        padding: "0px 40px 0px 30px",
        margin: "34px 0px ",
      }}
      className={`flex flex-row ${
        isReroute ? "justify-around" : "justify-between"
      } items-center w-full`}
    >
      {!isReroute && (
        <>
          <div
            onClick={() => {
              setQuoteRes();
              setSteps(1);
            }}
            style={styles.headerText}
          >
            <div style={{ marginBottom: "5px" }}>
              <AddressSvg color={Colors.bluePrimary} />
            </div>
            <div
              style={{
                color: steps > 0 ? Colors.bluePrimary : Colors.accentBlue,
              }}
            >
              Addresses
            </div>
          </div>
          <div
            style={{
              ...styles.betweenLine,
              ...{
                backgroundColor:
                  steps >= 2 ? Colors.bluePrimary : Colors.accentBlue,
              },
            }}
          ></div>

          <div
            onClick={() => {
              if (
                steps === 1 &&
                !errors.dropoff &&
                !errors.pickup &&
                (touched.pickup || touched.dropoff)
              ) {
                setSteps(2);
              } else if (steps > 1) {
                setSteps(2);
              }
              setQuoteRes();
            }}
            style={styles.headerText}
          >
            <div style={{ marginBottom: "5px" }}>
              <VehicleSvg color={steps >= 2 ? Colors.bluePrimary : undefined} />
            </div>
            <div
              style={{
                marginBottom: "4px",
                color: steps > 1 ? Colors.bluePrimary : Colors.accentBlue,
              }}
            >
              Vehicle Type
            </div>
          </div>
          <div
            style={{
              ...styles.betweenLine,
              ...{
                backgroundColor:
                  steps >= 3 ? Colors.bluePrimary : Colors.accentBlue,
              },
            }}
          ></div>
          <div
            onClick={() => {
              if (
                steps === 1 &&
                !errors.dropoff &&
                !errors.pickup &&
                (touched.pickup || touched.dropoff)
              ) {
                setQuoteRes();
                setSteps(3);
              } else if (steps !== 1) {
                setSteps(3);
              }
            }}
            style={styles.headerText}
          >
            <div style={{ marginBottom: "5px" }}>
              <TimeSvg color={steps >= 3 ? Colors.bluePrimary : undefined} />
            </div>
            <div
              style={{
                color: steps >= 3 ? Colors.bluePrimary : Colors.accentBlue,
              }}
            >
              Time
            </div>
          </div>

          <div
            style={{
              ...styles.betweenLine,
              ...{
                backgroundColor:
                  steps >= 4 ? Colors.bluePrimary : Colors.accentBlue,
              },
            }}
          ></div>
        </>
      )}
      <div
        onClick={() => {
          if (steps > 4) {
            setSteps(4);
          }
          if (
            steps === 1 &&
            !errors.dropoff &&
            !errors.pickup &&
            (touched.pickup || touched.dropoff)
          ) {
            setQuoteRes();
            setSteps(4);
          } else if (steps === 3) {
            if (values.initiate !== "scheduled") {
              setSteps(4);
            } else if (values.initiate === "scheduled" && values.eta) {
              setSteps(4);
            }
          }
        }}
        style={styles.headerText}
      >
        <div style={{ marginBottom: "8px" }}>
          <ReviewSvg color={steps >= 4 ? Colors.bluePrimary : undefined} />
        </div>
        <div
          style={{
            color: steps >= 4 ? Colors.bluePrimary : Colors.accentBlue,
          }}
        >
          Review
        </div>
      </div>
      <div
        style={{
          ...styles.betweenLine,
          ...{
            backgroundColor:
              steps >= 5 ? Colors.bluePrimary : Colors.accentBlue,
          },
        }}
      ></div>
      <div
        onClick={() => {
          if (
            steps === 1 &&
            !errors.dropoff &&
            !errors.pickup &&
            (touched.pickup || touched.dropoff)
          ) {
            setQuoteRes();
            setSteps(5);
          } else if (steps === 4) {
            setSteps(5);
          }
        }}
        style={styles.headerText}
      >
        <div style={{ marginBottom: "4px" }}>
          <MultiQuoteIcon color={steps >= 5 ? Colors.bluePrimary : undefined} />
        </div>
        <div
          style={{
            color: steps >= 5 ? Colors.bluePrimary : Colors.accentBlue,
          }}
        >
          Quotes
        </div>
      </div>
    </div>
  );
};

export default CreateDeliveryTabsHeaders;
