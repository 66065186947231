import React, { useEffect, useState } from "react";

import { useClickStore, useIncidents, useTheme } from "../../../../store";
import { useDesktopOrLaptop } from "../../../../services/useDesktopOrLaptop";
import UpdateIncident from "./UpdateIncident";
import CreateIncident from "./CreateIncident";
import MarkDeliveryNotReroutable from "../../MarkDeliveryNotReroutable";

const DeliveryIncidentModals = ({}) => {
  const [delInfo, setDelInfo] = useState();

  const { Colors } = useTheme();
  const { setAllTimeVisible } = useClickStore();

  const { mdfontsize, isdesktop } = useDesktopOrLaptop();
  const {
    setIsCreateEditIncidentModalOpen,
    selectedIncident,
    setSelectedIncident,
    selectedDeliveryForIncident,
    setSelectedDeliveryForIncident,

    setUpdatingIncidentIndex,
    markNotReroutableVisible,
    setMarkNotReroutableVisible,
    updatingIncidentIndex,
    incidents,
    setIncidents,
  } = useIncidents();

  useEffect(() => {
    return () => {
      setSelectedIncident();
      setSelectedDeliveryForIncident();
      setIsCreateEditIncidentModalOpen(false);
    };
  }, []);

  if (markNotReroutableVisible) {
    return <MarkDeliveryNotReroutable
      deliveryId={selectedIncident?.delivery_id}
      onClose={() => setMarkNotReroutableVisible(false)}
      onCallback={(res, error) => {
        if(res) {
          const _incidents = { ...incidents };
          _incidents.data[
            updatingIncidentIndex
          ].reroute_status = "not_reroutable";
          setIncidents(_incidents);
        }
      }} />
  }

  return (
    <div className=" py-10">
      <div
        style={{ borderColor: Colors.borderPrimary, marginBottom: "20px" }}
        className="w-full px-8 pb-5 border-b"
      >
        <div className="w-full flex items-start justify-between">
          <div
            style={{
              fontFamily: "Poppins",
              fontSize: mdfontsize,
              color: Colors?.fontDark,
              fontWeight: "500",
              width: "100%",
            }}
          >
            {selectedIncident ? (
              <div>
                <div>
                  Update Incident:{" "}
                  <span style={{ color: Colors.fontGreyLight }}>
                    {selectedIncident.delivery_id}
                  </span>
                </div>
              </div>
            ) : (
              <div>
                <div>
                  Create Incident for Delivery:{" "}
                  <span style={{ color: Colors.fontGreyLight }}>
                    {selectedDeliveryForIncident?.id}
                  </span>
                </div>
              </div>
            )}
          </div>
          <button
            onClick={() => {
              setIsCreateEditIncidentModalOpen(false);

              setUpdatingIncidentIndex();
              setAllTimeVisible(true);
            }}
            type="button"
            style={{
              color: Colors?.fontDark,
              fontSize: isdesktop ? "20px" : "36px",
            }}
            className="material-icons outline-none"
          >
            close
          </button>
        </div>
      </div>

      <div className="w-full ">
        {selectedIncident ? (
          <UpdateIncident
            selectedIncident={selectedIncident}
            delInfo={delInfo}
          />
        ) : (
          <CreateIncident />
        )}
      </div>
    </div>
  );
};

export default DeliveryIncidentModals;
