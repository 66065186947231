import React from "react";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import { useTheme } from "../../store";

function ButtonComp({ title, onClick, primary, extraStyles, ...rest }) {
  const { Colors } = useTheme();

  const { mdfontsize } = useDesktopOrLaptop();

  return (
    <button
      onClick={onClick}
      className="trans"
      type="button"
      style={{
        ...{
          backgroundColor: primary ? Colors.bluePrimary : Colors.white,
          fontFamily: "Poppins",
          fontSize: mdfontsize,
          fontWeight: "500",
          color: "#FAFAFB",
          textAlign: "center",
          borderRadius: "8px",
          padding: "8px 22px",
        },
        ...extraStyles,
      }}
      {...rest}
    >
      {title}
    </button>
  );
}

export default ButtonComp;
