import { useDeliveryIncidents } from "../../store";
import FiltersMenu from "../shared/common/FiltersMenu";
import { FILTER_TYPES } from "../../constants";
import ActiveFiltersBar from "../shared/common/FiltersMenu/ActiveFiltersBar";

const Incidentfilters = () => {
  const {
    searchDeliveryId,
    setSearchDeliveryId,
    searchByStoreId,
    setSearchByStoreId,
    searchByStatus,
    setSearchByStatus,
    searchByExternalOrderRef,
    setSearchByExternalOrderRef,
  } = useDeliveryIncidents();

  const filtersDefinition = [
    {
      id: "searchDeliveryId",
      title: "By Delivery Id",
      type: FILTER_TYPES.SEARCH,
      placeholder: "Delivery Id",
      filterState: searchDeliveryId,
      setFilterState: setSearchDeliveryId,
    },
    {
      id: "searchByStoreId",
      title: "By Store Id",
      type: FILTER_TYPES.SEARCH,
      placeholder: "Store Id",
      filterState: searchByStoreId,
      setFilterState: setSearchByStoreId,
    },
    {
      id: "searchByexternalOrderRefId",
      title: "By External Order Ref",
      type: FILTER_TYPES.SEARCH,
      placeholder: "External Order Ref",
      filterState: searchByExternalOrderRef,
      setFilterState: setSearchByExternalOrderRef,
    },
    {
      id: "searchByStatus",
      title: "By Status",
      type: FILTER_TYPES.TYPE_AND_SEARCH,
      label: "label",
      list: [
        { label: "Review Completed", value: "review_completed" },
        { label: "Under Review", value: "under_review" },
      ],
      placeholder: "Status",
      filterState: searchByStatus,
      setFilterState: setSearchByStatus,
    },
  ];

  return (
    <div className="w-full py-6">
      <div style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
        <div style={{ minWidth: "130px" }}>
          <FiltersMenu menuItems={filtersDefinition} title="Search By" icon="search" />
        </div>
        <div
          className="noScroll"
          style={{
            overflowX: "scroll",
          }}
        >
          <ActiveFiltersBar activeFilters={[...filtersDefinition]} />
        </div>
      </div>
    </div>
  );
};

export default Incidentfilters;
