import React, { useEffect, useState } from "react";
import { AiFillBank } from "react-icons/ai";
import { ImCreditCard } from "react-icons/im";
import { useNavigate } from "react-router-dom";

import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import {
  useMerchant,
  useMyStore,
  usePaymentStore,
  useStoreAndChild,
  useTheme,
  userDataStore,
} from "../../store";
import {
  allowedBadgesForBankAccounts,
  allowedBadgesForCards,
  cardLogoHandler,
  cardNameHandler,
  verifyPM,
} from "./UtilsComponents";
import ButtonComp from "../ButtonComp/ButtonComp";
import { stripeReturnUrlForStore } from "../../runTimeConst";
import StripePaymentElement from "../shared/StripePaymentElement";
import StatusBadgeReusable from "../shared/common/StatusBadgeReusable";
import { ROLES } from "../AccountSettings/RolesSidebar/helpers";

const PaymentMethods = () => {
  const [isAnyCardDefaulted, setIsAnyCardDefaulted] = useState(false);
  let history = useNavigate();

  const { authInfo, userRole } = userDataStore();
  const { userId } = useMyStore();

  const {
    customerCards,
    loadCardList,
    setStoreLevelPaymentMethod,
    setAddCardModal,
    getSetupIntent,
  } = usePaymentStore();
  const { selectedStore, setSelectedStore } = useStoreAndChild();
  const { Colors } = useTheme();
  const { isAdmin } = useMerchant();
  const { smfontsize, mdfontsize, xsfontsize, isdesktop } =
    useDesktopOrLaptop();

  useEffect(() => {
    let subscribe = true;
    if (subscribe) {
      if (customerCards?.length > 0) {
        for (const card of customerCards) {
          if (card.is_default) {
            setIsAnyCardDefaulted(true);
            break;
          }
        }
      }
    }

    return () => {
      subscribe = false;
      setIsAnyCardDefaulted(false);
    };
  }, [customerCards]);

  useEffect(() => {
    if (authInfo?.merchantAccountId)
      getSetupIntent(authInfo.merchantAccountId, {
        set_as_store_default_payment_method: selectedStore?.id,
      });
    loadCardList(userId ?? authInfo?.merchantAccountId);
  }, [authInfo]);

  const setPaymentMethodHandler = async (
    selectedStore,
    paymentMethodObject,
    checked
  ) => {
    const id = checked ? null : paymentMethodObject.id;
    const cb = () => {
      setSelectedStore({
        ...selectedStore,
        payment_method: {
          ...selectedStore.payment_method,
          id: checked ? false : paymentMethodObject?.id,
        },
        // payment_method: paymentMethodObject?.id,
      });
    };
    setStoreLevelPaymentMethod(selectedStore.id, id, cb);
  };

  const renderBankAccountTypeMethod = (data) => {
    return (
      <div className="flex items-center">
        <div
          style={{
            borderWidth: "1px",
            borderColor: Colors.borderPrimary,
          }}
          className="p-3 rounded ml-5"
        >
          <AiFillBank size={20} />
        </div>
        <div className="text-gray-400 ml-5 font-medium">
          <div className="flex items-center">
            <span
              style={{
                fontSize: smfontsize,
                color: Colors.fontGreyLight,
                marginRight: "8px",
              }}
              className="text-gray-800"
            >
              **** {" " + data?.public_identifier}
            </span>
            <StatusBadgeReusable
              status={data?.status}
              badgesObject={allowedBadgesForBankAccounts}
            />
          </div>
          {data?.verification_url &&
            data.status === "unverified" &&
            verifyPM(data.verification_url, Colors, xsfontsize)}
        </div>
      </div>
    );
  };

  const renderCardTypeMethod = (data) => {
    return (
      <div className="flex items-center">
        <div
          style={{
            borderWidth: "1px",
            borderColor: Colors.borderPrimary,
          }}
          className="p-2   rounded ml-5"
        >
          {cardLogoHandler(data?.card_issuer, xsfontsize, Colors)}
        </div>
        <div className="text-gray-400 ml-5 font-medium">
          <div className="flex items-center">
            <span
              style={{
                fontSize: smfontsize,
                color: Colors.fontGreyLight,
                marginRight: "8px",
              }}
              className="text-gray-800"
            >
              {cardNameHandler(data?.card_issuer)}
              ****
              {" " + data?.public_identifier}
            </span>
            <StatusBadgeReusable
              status={data?.status}
              badgesObject={allowedBadgesForCards}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderPaymentMethodList = (paymentMethodObject, index) => {
    if (
      paymentMethodObject.type !== "card" &&
      paymentMethodObject.type !== "us_bank_account"
    )
      return null;
    return (
      <div
        key={index}
        className="mt-9 flex justify-between items-center px-4 py-2"
      >
        <div className="flex items-center">
          <input
            type="checkbox"
            style={{ backgroundColor: Colors.white }}
            onChange={async () => {
              let checked =
                paymentMethodObject?.id === selectedStore?.payment_method?.id;
              return setPaymentMethodHandler(
                selectedStore,
                paymentMethodObject,
                checked
              );
            }}
            checked={
              paymentMethodObject?.id === selectedStore?.payment_method?.id
            }
          />
          {paymentMethodObject.type === "card"
            ? renderCardTypeMethod(paymentMethodObject)
            : renderBankAccountTypeMethod(paymentMethodObject)}
        </div>
        {/* {paymentMethodObject.type === "card"
          ? renderCardStatusTag(paymentMethodObject.status, styles)
          : renderBankStatusTag(paymentMethodObject.status, styles)} */}
      </div>
    );
  };

  return (
    <div
      style={{
        height: isdesktop ? `calc(100vh - 210px)` : `calc(100vh - 200px)`,
        overflowY: "scroll",
        padding: "20px",
      }}
    >
      <div
        style={{
          marginTop: "24px",
        }}
        className="w-full flex flex-col items-center  "
      >
        <div
          style={{
            padding: "18px",
            borderRadius: "8px",
            paddingBottom: "24px",
            backgroundColor: Colors.white,
          }}
          className="w-full flex flex-col justify-start shadow-md"
        >
          <div className="flex justify-between items-center">
            <div className="flex justify-start w-full  items-center ">
              <div
                style={{ backgroundColor: Colors.white }}
                className="rounded-full  p-3"
              >
                <ImCreditCard color={Colors.bluePrimary} size={35} />
              </div>
              <div
                style={{ fontSize: mdfontsize, color: Colors.fontDark }}
                className="font-semibold  ml-4"
              >
                Payment Methods
              </div>
            </div>
            {/* {(userRole === ROLES.ADMIN ||
              userRole === ROLES.STORE_MANAGER ||
              isAdmin) && ( */}
            <ButtonComp
              title={"Add Payment Method"}
              onClick={() => {
                setAddCardModal(true);
              }}
              extraStyles={{ width: "20%", cursor: "pointer" }}
              primary
            />
            {/* )} */}
          </div>
          {customerCards?.map?.length < 1 ? (
            <div
              style={{ color: Colors.fontGreyLight }}
              className="flex w-full items-center justify-start my-10"
            >
              There is no payment method available for selection. Please first
              <span
                className="cursor-pointer ml-2 text-blue-600"
                onClick={() => {
                  history("/v1/settings/payments", {
                    state: { showPaymentElement: true },
                  });
                }}
              >
                add payment methods to your account.
              </span>
            </div>
          ) : (
            <>
              {true && (
                <div
                  style={{ color: Colors.fontGreyLight, fontSize: smfontsize }}
                  className=" my-3"
                >
                  Adding a payment method to the store allows deliveries from
                  the store to be paid via a different payment method from the
                  account default payment method.
                </div>
              )}

              {customerCards.map((paymentMethodObject, index) => {
                return renderPaymentMethodList(paymentMethodObject, index);
              })}
              <StripePaymentElement
                returnUrl={stripeReturnUrlForStore(selectedStore?.id)}
                successMessage="Your payment method has been added for the store. Adding payment method may take up to a minute. If you don’t see the payment method below, please reload the page in a few seconds."
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentMethods;
