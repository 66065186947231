import React from "react";
import { Outlet } from "react-router-dom";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";

const PaymentsHistory = () => {
  const { isdesktop } = useDesktopOrLaptop();

  return (
    <div
      style={{
        padding: "10px 0px 10px 0px",
        height: isdesktop ? `calc(100% - 30px)` : `calc(100vh - 200px)`,
      }}
      className="md:w-full flex flex-col"
    >
      <Outlet />
    </div>
  );
};

export default PaymentsHistory;
