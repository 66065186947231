import React from "react";
import DeliveryStatusTimeline from "../../../../shared/DeliveryStatusTimeline";
import { useTheme } from "../../../../../store";
import { useDesktopOrLaptop } from "../../../../../services/useDesktopOrLaptop";

const StatusTimeline = ({ statusSocket, statusesHystory }) => {
  const { Colors } = useTheme();
  const { mdfontsize } = useDesktopOrLaptop();
  return (
    <div style={{ marginBottom: "12px" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          backgroundColor: Colors.whiteBlue,
          fontSize: mdfontsize,
          borderRadius: "5px 5px 0px 0px",
          height: "31px",
          padding: "12px",
          color: Colors.fontDark
        }}
      >
        Statuses
      </div>
      <DeliveryStatusTimeline
        status={statusSocket}
        statusesHystory={statusesHystory}
      />
    </div>
  );
};

export default StatusTimeline;
