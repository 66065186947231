import React, { useCallback, useState } from "react";
import styles from "./styles/StoresMultiselectFilter.module.css";
import OutsideClickHandler from "react-outside-click-handler";
import classNames from "classnames";
import { IoCloseCircle } from "react-icons/io5";
import { debounce } from "lodash";

import { BiCheckbox, BiCheckboxChecked } from "react-icons/bi";

import { StoreIcon } from "./Svgs";
import { useStoreAndChild, userDataStore } from "../../store";
import ToolTip from "../Tooltip/Tooltip";

const StoresMultiselectFilter = ({ storesFilteredArray, setStoresFilteredArray, storesFilterHandler }) => {
  // const [storesArray, setStoresArray] = useState([]);

  const { allStoresByUser, getAllStoresByUser } = useStoreAndChild();
  const { authInfo } = userDataStore();
  const [isStoresVisible, setIsStoresVisible] = useState(false);

  const debounceFn = useCallback(
    debounce((search) => {
      getAllStoresByUser(authInfo.merchantAccountId, false, {
        search_phrase: search,
      });
    }, 500),
    []
  );

  const filterArrayHandler = (item) => {
    let _filterArray = [...storesFilteredArray];

    if (storesFilteredArray?.includes(item)) {
      _filterArray = _filterArray.filter((e) => e !== item);

      setStoresFilteredArray(_filterArray);
    } else {
      _filterArray.push(item);
      setStoresFilteredArray(_filterArray);
    }
    storesFilterHandler(_filterArray);
  };

  const filterArrayCleanHandler = () => {
    let _filterArray = [...storesFilteredArray];
    _filterArray.splice(0, 1);
    setStoresFilteredArray(_filterArray);
    storesFilterHandler(_filterArray);
  };

  return (
    <div className={styles.container}>
      <OutsideClickHandler
        onOutsideClick={() => {
          setIsStoresVisible(false);
        }}
      >
        <div className="flex items-center">
          <div
            onClick={() => {
              setIsStoresVisible(true);
            }}
            className={styles.buttonContainer}
          >
            <StoreIcon />

            <div
              // style={{ color: storesArray.length > 0 && "#0062ff" }}
              className="ml-1"
            >
              Stores
            </div>
            {storesFilteredArray?.length > 0 && (
              <div className={styles.categorie} onClick={(e) => {}}>
                <div className={styles.count}>{storesFilteredArray.length}</div>
                <div className={classNames(styles.catName, "truncate")}>{storesFilteredArray[0]?.name}</div>
                {storesFilteredArray?.length > 1 && "+"}{" "}
                <IoCloseCircle onClick={filterArrayCleanHandler} color="#0062ff" className="ml-1" size={16} />
              </div>
            )}
          </div>
        </div>
        {isStoresVisible && (
          <div className={classNames(styles.filterDropdown, "scrollbar")}>
            <form
              onSubmit={(e) => {
                // getAllStoresByUser(authInfo.merchantAccountId, false, {
                //   search_phrase: searchText,
                // });
                e.preventDefault();
              }}
              className={styles.searchFormCont}
            >
              <input
                type="text"
                className={styles.searchInput}
                placeholder="Search Store"
                onChange={(event) => {
                  // setSearchText(event.target.value);
                  debounceFn(event.target.value);
                }}
                style={{}}
              />
            </form>
            <div>
              {allStoresByUser?.data?.map((sto) => {
                return (
                  <button
                    data-for={`store-name-${sto.id}`}
                    onClick={() => {
                      filterArrayHandler(sto);
                    }}
                    key={sto.id}
                    type="button"
                    className={classNames(`flex justify-between items-center`, styles.sotoreItem)}
                  >
                    <div>
                      {storesFilteredArray.includes(sto) ? (
                        <BiCheckboxChecked size={24} color="#0062FF" />
                      ) : (
                        <BiCheckbox size={24} />
                      )}
                    </div>
                    <div className={classNames("flex flex-col items-start", styles.storeName)}>
                      <div
                        className="text-left truncate"
                        style={{
                          width: "100%",
                          whiteSpace: "nowrap",
                        }}
                        data-for={`store-name-${sto.name}`}
                      >
                        {sto.name}
                      </div>
                      <ToolTip id={`store-name-${sto.name}`} disable={sto.name.length > 24 ? false : true} text={sto.name} />
                    </div>
                  </button>
                );
              })}
            </div>
          </div>
        )}
      </OutsideClickHandler>
    </div>
  );
};
export default StoresMultiselectFilter;
