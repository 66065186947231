import React, { useEffect, useState } from "react";
import moment from "moment";
import { useMyStore } from "../../store";

import DeliveriesFilters from "./DeliveriesFilters";

import PagingWithNextAndPrevButtons from "../PagingWithNextAndPrevButtons";
import DeliveriesListSection from "./DeliveriesListSection";

function DeliveriesAnalytics({ scrollHandler, isInsideStore }) {
  const {
    pageCount,
    setPageCount,
    totalDeliveryPages,
    totalDeliveries,
    isSandbox,
  } = useMyStore();

  const defaultFrom = {
    year: parseInt(moment().format("YYYY")),
    month: parseInt(moment().format("M")),
    day: parseInt(moment().format("D")),
  };
  const defaultTo = {
    year: parseInt(moment().format("YYYY")),
    month: parseInt(moment().format("M")),
    day: parseInt(moment().format("D")),
  };

  const defaultValue = {
    from: defaultFrom,
    to: defaultTo,
  };

  const [selectedDay, setSelectedDay] = useState(defaultValue);

  const [activeIndex, setActiveIndex] = useState(null);

  useEffect(() => {
    setActiveIndex(null);
    setSelectedDay(defaultValue);
  }, [isSandbox]);

  return (
    <div
      style={{
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
        overflow: "hidden",
        marginTop: isInsideStore ? "0px" : "34px",
      }}
      className="w-full flex flex-col"
    >
      <div style={{ minHeight: "500px" }} className="w-full">
        <div className="rounded-2xl">
          <DeliveriesFilters
            selectedDay={selectedDay}
            setSelectedDay={setSelectedDay}
            defaultValue={defaultValue}
            isInsideStore={isInsideStore}
          />
          <DeliveriesListSection
            scrollHandler={scrollHandler}
            isInsideStore={isInsideStore}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
          />
        </div>
        <PagingWithNextAndPrevButtons
          allPages={totalDeliveryPages}
          setPage={setPageCount}
          page={pageCount}
          result={totalDeliveries}
          itemsPerPage={30}
        />
      </div>
    </div>
  );
}

export default DeliveriesAnalytics;
