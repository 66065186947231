import React from "react";
import styles from "../styles/providerDetails/providerVehicles.module.css";
import car from "../../../../assets/png/vehicles/car.png";
import van from "../../../../assets/png/vehicles/van.png";
import truck from "../../../../assets/png/vehicles/truck.png";
import defaultVehicle from "../../../../assets/png/vehicles/devaultVehicle.png";

const mapVehicleType = {
  car: "Car",
  suv: "SUV",
  truck: "Pickup Truck",
};

const ProviderVehicles = ({ vehicle }) => {
  const getVehicleIcon = (vehicleType) => {
    switch (vehicleType) {
      case "car":
        return car;
      case "suv":
        return van;
      case "truck":
        return truck;
      default:
        return defaultVehicle;
    }
  };

  const renderVehicleDescription = (vehicleType) => {
    switch (vehicleType) {
      case "car":
        return (
          <span>
            Small items - food, <br /> grocery, flowers
          </span>
        );
      case "suv":
        return (
          <span>
            Medium size items - <br />
            retail bags, boxes,
            <br /> catering
          </span>
        );
      case "truck":
        return (
          <span>
            Large size items - <br />
            furniture, appliances
          </span>
        );
      default:
        return defaultVehicle;
    }
  };

  return (
    <div className={styles.cardItem}>
      <img className={styles.icon} src={getVehicleIcon(vehicle)} alt="" />
      <div className={styles.cartTitle}>{mapVehicleType[vehicle]}</div>
      <div className={styles.cardDescription}>
        {renderVehicleDescription(vehicle)}
      </div>
    </div>
  );
};

export default ProviderVehicles;
