import React from "react";

import styles from "../styles/delLateCircle.module.css";

const DelLateCircle = ({ minutes }) => {
  return (
    <div className={styles.cont}>
      <div className={styles.firstLine}>
        <span style={{ fontSize: "26px" }}>{minutes}</span>min
      </div>
      <div className={styles.secondLine}>Late</div>
    </div>
  );
};

export default DelLateCircle;
