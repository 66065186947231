import React, { useEffect, useMemo, useState } from "react";
import Header from "../components/Header";
import { Link, useNavigate } from "react-router-dom";
import { BsChevronLeft } from "react-icons/bs";
import {
  usePaymentStore,
  useMyStore,
  useClickStore,
  useTheme,
  useOrdersForMap,
} from "../store";

import "./CreateDelivery/CreateDelivery.css";
import MapForCreateDelivery from "../components/MapForCreateDelivery";
import { Formik } from "formik";

import VehicleTab from "../components/formikFields/VehicleTab";
import { MdClose } from "react-icons/md";
import { useDesktopOrLaptop } from "../services/useDesktopOrLaptop";
import TripAddressesTab from "./TripManagement/CreateTrip/TripAddressesTab";
import CreateTripHeader from "./TripManagement/CreateTrip/CreateTripHeader";
import TripsQuoteList from "./TripManagement/CreateTrip/TripsQuoteList";
import TripsReview from "./TripManagement/CreateTrip/TripsReview";
import useTripManagement from "../store/useTripManagement";

const CreateTrip = () => {
  const initialValues = useMemo(() => {
    return {
      orders: undefined,
      vehicleType: "car",
      quote_id: undefined,
      quoteName: "",
      tripId: undefined,
      tripStops: undefined,
    };
  }, []);

  const { Colors } = useTheme();
  const { mdfontsize, isdesktop, smfontsize } = useDesktopOrLaptop();
  let history = useNavigate();
  const { submitTrip, tripQuoteRes } = useTripManagement();

  const { setQuoteRes, isSandbox, deliveryLoading } = useMyStore();
  const {
    sidepanelactive,
    setSidepanelactive,
    isBurgerMenuVisible,
    setIsBurgerMenuVisible,
  } = useClickStore();

  const [providedDirections, setProvidedDirections] = useState();

  const [selectedQuotePrice, setSelectedQuotePrice] = useState(0);

  const [steps, setSteps] = useState(1);

  return (
    <div style={{}} className="overflow-y-hidden  ">
      <Header
        noStores
        createBurger={"createBurgerId"}
        setQuoteRes={setQuoteRes}
        noMenu
        isdesktop={isdesktop}
        setSidepanelactive={setSidepanelactive}
        setIsBurgerMenuVisible={setIsBurgerMenuVisible}
        isBurgerMenuVisible={isBurgerMenuVisible}
        sidepanelactive={sidepanelactive}
        disableToggle
      />

      <div
        style={{
          fontFamily: "Poppins",
          height: `calc(100vh - 56px)`,
          overflowY: !isdesktop && "scroll",
          backgroundColor: Colors.bgGrey,
        }}
        className={`flex ${
          isdesktop ? "flex-row" : "flex-col-reverse"
        }  w-full  `}
      >
        <div
          style={{ padding: "24px", paddingBottom: "100px" }}
          className={`${
            isdesktop ? " w-1/2 h-full" : "w-full h-3/4"
          }  w-1/2 flex flex-col items-start kata`}
        >
          <div className="flex flex-row justify-between items-center w-full">
            <button
              type="button"
              onClick={() => {
                setQuoteRes();
                if (steps > 1) {
                  setSteps(steps - 1);
                }
              }}
              style={{
                padding: "8px 3px",
                cursor: "pointer",
                outline: "none",
              }}
            >
              <BsChevronLeft
                size={22}
                color={steps !== 1 ? Colors.fontDark : Colors.bgGrey}
              />
            </button>
            <h1
              style={{
                fontFamily: "Poppins",
                fontWeight: "500",
                fontSize: mdfontsize,
                color: Colors.fontDark,
              }}
            >
              Create Trip
            </h1>
            <Link
              to="/v1/orders"
              className=""
              onClick={() => {
                setQuoteRes();
              }}
            >
              <MdClose size={28} color={Colors.fontDark} />
            </Link>
          </div>

          <div
            style={{
              borderRadius: "8px",
              marginTop: "24px",
              backgroundColor: Colors.white,
              padding: "0px 20px 20px 20px",
            }}
            className=" w-full  flex flex-col justify-start items-center "
          >
            <Formik
              onSubmit={() => {}}
              enableReinitialize={true}
              initialValues={initialValues}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                errors,
                touched,
                setFieldValue,
              }) => {
                return (
                  <>
                    <CreateTripHeader steps={steps} setSteps={setSteps} />

                    {steps === 1 ? (
                      <TripAddressesTab
                        setFieldValue={setFieldValue}
                        tripStops={values.tripStops}
                        setProvidedDirections={setProvidedDirections}
                      />
                    ) : steps === 2 ? (
                      <VehicleTab trip />
                    ) : steps === 3 ? (
                      <TripsQuoteList
                        selectedQuotePrice={selectedQuotePrice}
                        setSelectedQuotePrice={setSelectedQuotePrice}
                        setSteps={setSteps}
                      />
                    ) : (
                      steps === 4 && (
                        <TripsReview
                          setFieldValue={setFieldValue}
                          tripStops={values.tripStops}
                          vehicleType={values.vehicleType}
                          quoteName={values.quoteName}
                          setSteps={setSteps}
                          setProvidedDirections={setProvidedDirections}
                          selectedQuotePrice={selectedQuotePrice}
                        />
                      )
                    )}
                    {/* {steps === 1 ? (
                      <TripAddressesTab
                        setProvidedDirections={setProvidedDirections}
                      />
                    ) : steps === 2 ? (
                      <div>vehicles</div>
                    ) : steps === 3 ? (
                      <div>quotes</div>
                    ) : (
                      steps === 4 && <div>review</div>
                    )} */}
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "30px",
                      }}
                    >
                      {steps < 4 ? (
                        <button
                          onClick={() => {
                            if (steps === 3) {
                              if (tripQuoteRes) {
                                setSteps(steps + 1);
                              }
                            } else if (steps !== 3) {
                              setSteps(steps + 1);
                            }
                          }}
                          type="button"
                          style={{
                            padding: "12px 96px",
                            borderRadius: "8px",
                            backgroundColor: Colors.bluePrimary,
                            color: "#FAFAFA",
                            fontFamily: "Poppins",
                            fontSize: mdfontsize,
                          }}
                        >
                          Continue
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={async () => {
                            submitTrip(
                              isSandbox,
                              values.tripId,
                              values.quote_id
                            ).then((res) => {
                              history("/v1/orders");
                            });
                          }}
                          style={{
                            padding: "12px 96px",
                            borderRadius: "8px",
                            backgroundColor: !deliveryLoading
                              ? Colors.bluePrimary
                              : Colors.borderSecondary,
                            color: "#FAFAFA",
                            fontFamily: "Poppins",
                            fontSize: mdfontsize,
                          }}
                        >
                          Submit
                        </button>
                      )}
                    </div>
                  </>
                );
              }}
            </Formik>
          </div>
        </div>
        <div
          style={{ backgroundColor: Colors.white }}
          className={`w-1/2  ${isdesktop ? "w-1/2 h-full" : "w-full h-1/4"}`}
        >
          <MapForCreateDelivery
            providedDirections={providedDirections}
            zoom={2}
            isdesktop={isdesktop}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateTrip;
