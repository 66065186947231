import { createStore } from "aniuta";
import { useState } from "react";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";
import {
  getMerchantProviders,
  updateAccountProviderSetting,
  getProviderAccessRequestQuestionnaire,
  createProviderAccessRequest,
  getProviderCredential,
  createProviderContactRequest,
  createMissingProviderRequest,
} from "../utils/api/providers";

const useProvidersManagementStore = createStore({
  name: "providerManagementStore",
  Store: () => {
    const [isBYOPKVisible, setIsBYOPKVisible] = useState(false);
    const [selectedProvider, setSelectedProvider] = useState(null);
    const [isMissingProviderRequestFormOpen, setIsMissingProviderRequestFormOpen] = useState(false);
    const [defaultActiveTab, setDefaultActiveTab] = useState('');

    //getMerchantProvidersHandler
    const [isMerchantProvidersLoading, setIsMerchantProvidersLoading] =
      useState(false);
    const [merchantProviders, setMerchantProviders] = useState();

    const getMerchantProvidersHandler = async (accountId, isSandbox) => {
      if (!accountId) return;
      setIsMerchantProvidersLoading(true);
      try {
        const { data } = await getMerchantProviders(accountId, {
          test_mode: isSandbox,
        });
        setMerchantProviders(data?.data);
        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsMerchantProvidersLoading(false);
      }
    };

    //updateAccountProviderSettingHandler
    const updateAccountProviderSettingHandler = async (
      accountProviderSettingId,
      paylaod
    ) => {
      try {
        const { data } = await updateAccountProviderSetting(
          accountProviderSettingId,
          paylaod
        );
        toast.success("The provider setting is updated.");
        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
        return error;
      }
    };

    //getAccessRequestQuestionnaireHandler
    const [questions, setQuestionnaire] = useState();
    const [isQuestionsLoading, setIsQuestionnaireLoading] = useState(false);

    const getProviderAccessRequestQuestionnaireHandler = async () => {
      setIsQuestionnaireLoading(true);
      try {
        const { data } = await getProviderAccessRequestQuestionnaire();
        setQuestionnaire(data?.data);
        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsQuestionnaireLoading(false);
      }
    };

    const [isPARLoading, setIsPARLoading] = useState(false);

    const createProviderAccessRequestWorker = async (
      accountId,
      providerId,
      paylaod
    ) => {
      setIsPARLoading(true);
      try {
        const { data } = await createProviderAccessRequest(
          accountId,
          providerId,
          paylaod
        );
        getMerchantProvidersHandler(accountId, paylaod.test_mode);
        setSelectedProvider(null);
        toast.success("The provider access request was received successfully.");
        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsPARLoading(false);
      }
    };

    const [isProviderAccessRequestLoading, setIsProviderAccessRequestLoading] =
      useState(false);

    const createProviderAccessRequestHandler = async (
      accountId,
      providerId,
      paylaod
    ) => {
      setIsProviderAccessRequestLoading(true);
      try {
        const { data } = await createProviderAccessRequest(
          accountId,
          providerId,
          paylaod
        );
        toast.success("The provider access request is submited successfully.");
        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsProviderAccessRequestLoading(false);
      }
    };

    const [isCredentialsLoading, setIsCredentialsLoading] = useState(false);
    const [merchantCredentials, setMerchantCredentials] = useState();
    const getProviderCredentialWorker = async (
      accountId,
      providerId,
      params
    ) => {
      if (!accountId || !providerId) return;
      setIsCredentialsLoading(true);
      try {
        const { data } = await getProviderCredential(
          accountId,
          providerId,
          params
        );
        setMerchantCredentials(data?.data[0]);
        return data?.data[0];
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsCredentialsLoading(false);
      }
    };

    const [isPCRLoading, setIsPCRLoading] = useState(false);

    const createProviderContactRequestWorker = async (
      accountId,
      providerId,
      paylaod
    ) => {
      setIsPCRLoading(true);
      try {
        const { data } = await createProviderContactRequest(
          accountId,
          providerId,
          paylaod
        );
        getMerchantProvidersHandler(accountId, paylaod.test_mode);
        setSelectedProvider(null);
        toast.success("The provider contact request was received successfully.");
        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsPCRLoading(false);
      }
    };

    const [isCreateMissingProviderRequestLoading, setIsCreateMissingProviderRequestLoading] = useState(false);

    const createMissingProviderRequestWorker = async (
      accountId,
      paylaod
    ) => {
      setIsCreateMissingProviderRequestLoading(true);
      try {
        const { data } = await createMissingProviderRequest(
          accountId,
          paylaod
        );
        setSelectedProvider(null);
        setIsMissingProviderRequestFormOpen(false);
        toast.success("The missing provider request was received successfully.");
        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
      } finally {
        setIsCreateMissingProviderRequestLoading(false);
      }
    };

    return {
      isMerchantProvidersLoading,
      merchantProviders,
      getMerchantProvidersHandler,
      updateAccountProviderSettingHandler,

      questions,
      isQuestionsLoading,
      getProviderAccessRequestQuestionnaireHandler,

      isBYOPKVisible,
      setIsBYOPKVisible,
      selectedProvider,
      setSelectedProvider,
      defaultActiveTab,
      setDefaultActiveTab,

      isProviderAccessRequestLoading,
      createProviderAccessRequestHandler,

      isPARLoading,
      createProviderAccessRequestWorker,

      isCredentialsLoading,
      merchantCredentials,
      getProviderCredentialWorker,

      isPCRLoading,
      createProviderContactRequestWorker,

      isMissingProviderRequestFormOpen, 
      setIsMissingProviderRequestFormOpen,

      isCreateMissingProviderRequestLoading,
      createMissingProviderRequestWorker,
    };
  },
});

export default useProvidersManagementStore;
