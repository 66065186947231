import * as Yup from "yup";

export const ROLES = {
  ADMIN: "acct_admin",
  STORE_MANAGER: "store_manager",
  EMPLOEE: "delivery_operator",
  STORE_EMPLOEE: "store_operator",
};

export const initialValues = (userInfo) => {
  return {
    first_name: userInfo?.first_name ?? "",
    last_name: userInfo?.last_name ?? "",
    email: userInfo?.email ?? "",
    role:
      userInfo?.user_roles?.length > 0
        ? userInfo?.user_roles[0].role_name === "store_admin"
          ? ROLES.STORE_MANAGER
          : userInfo?.user_roles[0].role_name === "store_operator_admin"
          ? ROLES.STORE_EMPLOEE
          : userInfo?.user_roles[0].role_name
        : undefined,
    user_roles: userInfo?.user_roles?.length > 0 ? userInfo?.user_roles : [],
  };
};

export const defaultValidationSchema = (isConnectedOrPlatform) => {
  if (isConnectedOrPlatform) {
    return Yup.object().shape({
      first_name: Yup.string().required("Field is required."),
      last_name: Yup.string().required("Field is required."),
      email: Yup.string()
        .required("Field is required.")
        .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email format"),
    });
  }
  return Yup.object().shape({
    first_name: Yup.string().required("Field is required."),
    last_name: Yup.string().required("Field is required."),
    email: Yup.string()
      .required("Field is required.")
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email format"),
    // email: Yup.string().required("Field is required.").matches("/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/"),

    //! ROLES HIDDEN
    role: Yup.string().required("Role is required."),
    user_roles: Yup.array().when("role", (role) => {
      if (role === ROLES.STORE_MANAGER || role === ROLES.STORE_EMPLOEE) {
        return Yup.array().min(1, "At least one store must be checked");
      } else {
        return Yup.array().notRequired();
      }
    }),
  });
};

export const roles = [
  {
    role: "Admin",
    desc: "Admins can assign roles to other members, have full access to all the features and analytics",
    roleBE: "acct_admin",
  },
  {
    role: "Store Manager",
    desc: "Store Manager can add employees, create deliveries, view stores and analytics",
    roleBE: "store_manager",
  },
  {
    role: "Store Employee",
    desc: "Store employees have limited access, creating deliveries exclusively for assigned stores",
    roleBE: "store_operator",
  },
  {
    role: "Account Employee",
    desc: "Account employees is restricted to creating deliveries only for this account, Account employee does not have access to stores",
    roleBE: "delivery_operator",
  },
];

export const rolesUi = {
  acct_admin: "Admin",
  store_manager: "Store Manager",
  store_admin: "Store Manager",
  delivery_operator: "Account Employee",
  store_operator: "Store employee",
  store_operator_admin: "Store employee",
};
