import React from "react";
import styles from "./styles/undeliverableActions.module.css";
import { useFormikContext } from "formik";
import { useMerchant, useTheme } from "../../../../store";
import CommonToggle from "../../../../components/CommonToggle";
import { useDesktopOrLaptop } from "../../../../services/useDesktopOrLaptop";
import { PARTNER_INDUSTRY_OPTIONS } from "../../../../components/ProviderIntegrationSettings/utils";

const UndeliverableActions = ({ quote }) => {
  const { undeliverable_actions } = quote.provider_capability || {};

  const { values, setFieldValue } = useFormikContext();
  const { Colors } = useTheme();
  const { isdesktop } = useDesktopOrLaptop();
  const { merchantData } = useMerchant();

  const isRetunToStoreEnabled = values.provider_settings.undeliverable_action === "return_to_store";
  const shouldRenderComponent =
    Array.isArray(undeliverable_actions) && undeliverable_actions.includes("return_to_store") && values.quote_id === quote.id;

  if (!shouldRenderComponent) return null;

  const shouldAllowSignatureOnly =
    merchantData.standardized_industry === PARTNER_INDUSTRY_OPTIONS.pharmacy.toLowerCase() || values.contains_alcohol;
  return (
    <div className={styles.container} onClick={(e) => e.stopPropagation()}>
      <div>
        {!shouldAllowSignatureOnly ? (
          <>
            <div className={styles.header}>Return </div>
            <div className={styles.subtext}>
              In case the delivery can not be completed the order will be returned to the store at an additional fee
            </div>
          </>
        ) : (
          <div className={styles.header}>Return if order not delivered.</div>
        )}

        <div className={styles.feeNote}>Return fee: ${quote.total_cost === 0 ? "0.00" : (quote.total_cost / 100).toFixed(2)}</div>
      </div>
      {!shouldAllowSignatureOnly && (
        <div className="w-7 pl-1 flex-shrink-0">
          <CommonToggle
            id="headerToggle"
            smsHandler={(e) => {
              if (isRetunToStoreEnabled) {
                setFieldValue("provider_settings.undeliverable_action", null);
              } else {
                setFieldValue("provider_settings.undeliverable_action", "return_to_store");
              }
            }}
            checked={isRetunToStoreEnabled}
            disabled={values.provider_settings.signature}
            translateX={isdesktop ? 13.5 : 20}
            circleStyle={{
              width: isdesktop ? "11px" : "20px",
              height: isdesktop ? "11px" : "20px",
              backgroundColor: Colors.white,
            }}
            style={{
              width: isdesktop ? "30px" : "46px",
              height: isdesktop ? "16px" : "22px",
              padding: "3px",
              backgroundColor: isRetunToStoreEnabled ? Colors.bluePrimary : Colors.fontGreyLight,
            }}
            // translateX={isdesktop ? 12 : 20}
            // circleStyle={{
            //   width: isdesktop ? "10px" : "30px",
            //   height: isdesktop ? "10px" : "30px",
            //   backgroundColor: Colors.white,
            // }}
            // style={{
            //   width: isdesktop ? "30px" : "56px",
            //   height: isdesktop ? "14px" : "32px",
            //   padding: "3px",
            //   backgroundColor: isRetunToStoreEnabled ? Colors.bluePrimary : Colors.fontGreyLight,
            // }}
          />
        </div>
      )}
    </div>
  );
};

export default UndeliverableActions;
