import { useState } from "react";
import { createStore } from "aniuta";
import * as Sentry from "@sentry/react";
import { getAllProviders } from "../utils/api/providers";
import { toast } from "react-toastify";

const usePusherUpdates = createStore({
  name: "usePusherUpdates",
  Store: () => {
    const [deliveriesUpdates, setDeliveriesUpdates] = useState();
    const [notificationsUpdates, setNotificationsUpdates] = useState();
    return {
      deliveriesUpdates,
      setDeliveriesUpdates,
      notificationsUpdates,
      setNotificationsUpdates,
    };
  },
});

export default usePusherUpdates;
