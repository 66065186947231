import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useStripe } from "@stripe/react-stripe-js";
import { useOnboardingStore } from "../../../store";

const PaymentStatus = ({successMessage}) => {
  const stripe = useStripe();
  const { retrieveSetupIntent } = useOnboardingStore();
  const navigate = useNavigate();

  useEffect(() => {
    // Retrieve the "setup_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    const clientSecret = new URLSearchParams(window.location.search).get(
      "setup_intent_client_secret"
    );
    if (!stripe || !clientSecret) return;
    // Retrieve the SetupIntent
    retrieveSetupIntent(stripe, clientSecret, successMessage);
    navigate({ replace: true });
  }, [stripe]);

  return null;
};

export default PaymentStatus;
