import React, { useMemo, useRef } from "react";
import { RiArrowDownSLine } from "react-icons/ri";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import { useConnectedAccounts, useStoreAndChild, useTheme } from "../../store";
import created from "../../assets/png/statuses/created.png";
import Avatar from "react-avatar";
import { EditIcon, TrashIcon } from "../../assets/svgs/Svgs";
import Badge from "../shared/atoms/Badge";
import ToolTip from "../Tooltip/Tooltip";

const ConnectedAcountListItem = ({
  detailsHeight,
  activeIndex,
  index,
  setActiveIndex,
  scrollHandler,
  item,
  firstStoreInsideAccount,
  setFirstStoreInsideAccount,
}) => {
  const {
    setIsCreateAccountModalOpen,
    setAccountForEditing,
    setSelectedConnectedAccount,
    isDeleteAccountModalVisible,
    setIsDeleteAccountModalVisible,
  } = useConnectedAccounts();

  const { getAllStoresByUser } = useStoreAndChild();
  const { Colors } = useTheme();
  const { isdesktop, smfontsize, xsfontsize, xxsfontsize } =
    useDesktopOrLaptop();

  const onRowClickHandler = () => {
    setTimeout(() => {
      scrollHandler(index * 100);
    }, 200);
    if (activeIndex === index) {
      setSelectedConnectedAccount();
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
      getAllStoresByUser(item.id, true).then((res) => {
        setFirstStoreInsideAccount(res?.data[0]?.id);
      });
    }
  };

  const listRef = useRef();
  const timer = useRef();

  return (
    <div
      ref={listRef}
      onClick={async (event) => {
        clearTimeout(timer.current);
        const selection = window.getSelection();
        if (selection.type === "Range") return;

        if (event.detail === 1) {
          timer.current = setTimeout(onRowClickHandler, 200);
        } else if (event.detail === 2) {
          return;
        }
      }}
      style={{
        height: "94px",
        width: "100%",
        flexDirection: "row",
        display: "flex",
        outline: "none",
        justifyContent: "space-between",
        borderTopWidth: index === activeIndex ? "0px" : "0.5px",
        borderColor: Colors.borderPrimary,
        padding: "20px 0px",
        cursor: "pointer",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: !isdesktop ? "60%" : "41%",
        }}
      >
        <div
          style={{
            borderRadius: "1000px",
            overflow: "hidden",
          }}
        >
          {item?.image ? (
            <img
              style={{
                height: "90%",
              }}
              src={item.image}
              alt="user avatar"
            />
          ) : (
            <div className="w-full block">
              <Avatar
                name={
                  item?.name
                    ? item?.name?.split("")?.join(" ")
                    : item.email.split("")?.join(" ")
                }
                size={35}
                maxInitials={2}
              />
            </div>
          )}
        </div>
        <div
          style={{
            flexDirection: "column",
            maxWidth: !isdesktop ? "50%" : "50%",
            width: isdesktop && "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div
              style={{
                marginLeft: "18px",
                fontFamily: "Poppins",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: smfontsize,
                textAlign: "left",
                color: Colors.fontDark,
                width: "100%",
              }}
              className={`w-full ${isdesktop ? "truncate" : "truncate"}`}
            >
              <span
                style={{
                  color: Colors.fontGreyLight,
                  fontWeight: "500",
                  marginRight: "6px",
                }}
              >
                User:
              </span>
              <span
                data-tip
                data-for={`connected-email-${item.id}`}
                style={{ cursor: "text" }}
              >
                {item.email}
              </span>
              <ToolTip
                id={`connected-email-${item.id}`}
                disable={item.email.length > 17}
                text={item.email}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "18px",
              marginTop: "10px",
              width: "95%",
            }}
          >
            <div
              style={{
                fontWeight: "500",
                fontSize: smfontsize,
                textAlign: "center",
                color: Colors.fontGreyLight,
              }}
              className="truncate"
            >
              Name:
              <span
                data-tip
                data-for={`connected-name-${item.id}`}
                style={{
                  marginLeft: "10px",
                  color: Colors.fontDark,
                  cursor: "text",
                }}
              >
                {item.name}
              </span>
              <ToolTip
                id={`connected-name-${item.id}`}
                disable={item.name.length > 14 ? false : true}
                text={item.name}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ width: !isdesktop && "40%" }}
        className="flex items-center justify-end"
      >
        <div
          style={{
            width: isdesktop ? "180px" : "100%",
            marginRight: "20px",
            marginLeft: "10px",
          }}
          className="flex-col justify-between items-center text-"
        >
          <div
            style={{
              marginBottom: "6px",
              textAlign: "center",
              fontSize: "10px",
            }}
          >
            {!item?.onboarding?.password_reset_completed && (
              <Badge smallerText label="Password not created" type="warning" />
            )}
          </div>
          <div
            style={{
              textAlign: "center",
            }}
          >
            {!item?.onboarding?.payment_method_added && (
              <Badge
                smallerText
                label="Payment method not provided"
                type="warning"
              />
            )}
          </div>
        </div>
        {isdesktop && (
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              paddingRight: "4px",
              justifyContent: "space-between",
              minWidth: "300px",
            }}
          >
            <div style={{ display: "flex" }}>
              <div
                style={{
                  flexDirection: "column",
                  color: Colors.fontGreyLight,
                  marginRight: "10px",
                }}
              >
                <div
                  style={{
                    fontSize: xsfontsize,
                    textAlign: "center",
                  }}
                >
                  Account Id:
                </div>
                <div
                  style={{
                    fontSize: xsfontsize,
                    textAlign: "center",
                    marginTop: item.id?.length > 18 && "16px",
                  }}
                >
                  Status:
                </div>
              </div>
            </div>
            <div
              style={{
                flexDirection: "column",
                marginLeft: "6px",
                width: "200px",
                color:
                  activeIndex === index
                    ? Colors.bluePrimary
                    : Colors.fontGreyLight,
              }}
            >
              <div
                style={{
                  fontSize: xsfontsize,
                  color: Colors.fontDark,
                  userSelect: "text",
                  textAlign: "center",
                  cursor: "text",
                }}
              >
                {item.id}
              </div>
              <div
                className="capitalize"
                style={{
                  fontSize: xsfontsize,
                  color: Colors.fontDark,
                  userSelect: "text",
                  textAlign: "center",
                }}
              >
                {item.status === "pending_onboarding_tasks"
                  ? "pending"
                  : item.status}
              </div>
            </div>
            <button
              onClick={(event) => {
                event.stopPropagation();
                setAccountForEditing(item);
                setIsCreateAccountModalOpen(true);
              }}
              type="button"
              className="ml-4 mr-2 outline-none"
            >
              <EditIcon />
            </button>
            <button
              onClick={(event) => {
                event.stopPropagation();
                setAccountForEditing(item);
                setIsDeleteAccountModalVisible(true);
              }}
              type="button"
              className="mr-4 outline-none"
            >
              <TrashIcon />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConnectedAcountListItem;
