import MenuItems from "./MenuItems";
const Dropdown = ({
  submenus,
  dropdown,
  depthLevel,
  activeFiltersIds,
  setActiveFiltersIds,
}) => {
  depthLevel = depthLevel + 1;
  const dropdownClass = depthLevel > 1 ? "dropdown-submenu" : "";

  return (
    <ul className={`dropdown ${dropdownClass} ${dropdown ? "show" : ""}`}>
      {submenus.map((submenu, index) => (
        <MenuItems
          activeFiltersIds={activeFiltersIds}
          setActiveFiltersIds={setActiveFiltersIds}
          items={submenu}
          key={index}
          depthLevel={depthLevel}
        />
      ))}
    </ul>
  );
};

export default Dropdown;
