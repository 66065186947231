import { createStore } from "aniuta";
import * as Sentry from "@sentry/react";
import axios from "axios";
import Cookies from "js-cookie";
import moment from "moment";
import { useState } from "react";
import { toast } from "react-toastify";
import { baseUrlOfApi } from "../runTimeConst";

const useOrderManagement = createStore({
  name: "useOrderManagement",
  Store: () => {
    const [isDeleteOrderModalVisible, setIsDeleteOrderModalVisible] =
      useState(false);

    const [activeTab, setActiveTab] = useState("open");

    const [orderItemForDetails, setOrderItemForDetails] = useState(false);

    const [isDetailsVisible, setIsDetailsVisible] = useState(false);

    const postCreateOrder = async (isSandbox, info, store) => {
      let merchandise_value = 0;

      for (let item of info.dropoff) {
        for (let manifestItem of item.items) {
          merchandise_value =
            merchandise_value +
            manifestItem.merchandise_value * 100 * manifestItem.quantity;
        }
      }

      try {
        const response = await axios.post(
          `${baseUrlOfApi}api/orders`,
          {
            number: info.number,
            currency: "USD", // this must be 'USD' for now
            merchandise_value: +merchandise_value.toFixed(2),
            tip: +(info.tip * 100).toFixed(2),

            items: info.dropoff[0].items,

            pickups: [
              {
                store: {
                  burq_id: store.id,
                  location: {
                    address: store.address,
                  },
                },
                note_for_driver: "notes",
                // 8601 format, provide current timestamp for asap
                ready_for_pickup_at: info.eta ? moment(info.eta) : undefined,
              },
            ],

            dropoffs: [
              // only 1 item is supported at this time
              {
                contact: {
                  first_name: info.dropoff[0].dropoff_name,
                  last_name: info.dropoff[0].dropoff_last_name,
                  phone_number: info.dropoff[0].dropoff_phone_number,
                  location: {
                    address: info.dropoff[0].dropoff_address,
                    unit_number: info.dropoff[0].dropoff_unit,
                    // latitude: 1.234,
                    // longitude: -2.3948,
                  },
                },
                note_for_driver: info.dropoff[0].dropoff_notes,
                scheduled_at: info.eta ? info.eta : undefined,
                // 8601 format
              },
            ],
          },
          {
            headers: {
              "x-api-key": isSandbox
                ? Cookies.get("sandbox")
                : Cookies.get("prod"),
              "x-burq-client": "client-dashboard",
            },
            withCredentials: false,
          }
        );
        return response;
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    const [ordersList, setOrdersList] = useState();
    const [isOrdersListLoading, setIsOrdersListLoading] = useState(false);

    const getOrdersList = async (
      isSandbox,
      beforeId,
      afterId,
      status,
      store_id
    ) => {
      setIsOrdersListLoading(true);
      try {
        const response = await axios.get(
          `${baseUrlOfApi}api/orders?ending_before=${
            beforeId || ""
          }&starting_after=${afterId || ""}&limit=30&order_status=${
            status || ""
          }&store_id=${store_id || ""}`,

          {
            headers: {
              "x-api-key": isSandbox
                ? Cookies.get("sandbox")
                : Cookies.get("prod"),
              "x-burq-client": "client-dashboard",
            },
            withCredentials: false,
          }
        );
        setOrdersList(response.data);
        return response.data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
        return error;
      } finally {
        setIsOrdersListLoading(false);
      }
    };
    const deleteOrder = async (isSandbox, id) => {
      try {
        const response = await axios.delete(
          `${baseUrlOfApi}api/orders/${id}`,

          {
            headers: {
              "x-api-key": isSandbox
                ? Cookies.get("sandbox")
                : Cookies.get("prod"),
              "x-burq-client": "client-dashboard",
            },
            withCredentials: false,
          }
        );
        return response.data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
        return error;
      } finally {
        setIsOrdersListLoading(false);
      }
    };

    return {
      postCreateOrder,
      isDeleteOrderModalVisible,
      setIsDeleteOrderModalVisible,
      // orderIdForRemove,
      orderItemForDetails,
      setOrderItemForDetails,
      // list
      getOrdersList,
      ordersList,
      setOrdersList,
      isOrdersListLoading,
      setIsOrdersListLoading,
      deleteOrder,
      isDetailsVisible,
      setIsDetailsVisible,
      activeTab,
      setActiveTab,
    };
  },
});

export default useOrderManagement;
// {
//   "test_mode": true,
//   "number": "number",
//   "currency": "USD",
//   "merchandise_value": 123,
//   "tip": 123,
//   "items": [{
//       "name": "name",
//       "merchandise_value": 123,
//       "quantity": 123
//   },{
//       "name": "name",
//       "merchandise_value": 123,
//       "quantity": 123
//   }],
//   "pickups": [
//           {
//               "store":{
//                   "name": "name",
//                   "phone_number": "+18047909971",
//                   "location": {
//                       "address": "1717 E Cary St, Richmond, VA 23223, USA",
//                       "unit_number": "123",
//                       "latitude": 1.234,
//                       "longitude": -2.3948
//                   }
//               },
//               "note_for_driver": "note_for_driver",
//               "ready_for_pickup_at": "2022-09-28T10:27:18.000Z"
//           }
//       ],
//   "dropoffs":  [
//       {
//           "contact":{
//               "first_name": "name",
//               "last_name": "phone_number",
//               "phone_number": "+18047909971",
//               "location": {
//                   "address": "1720 E Cary St, Richmond, VA 23223, USA",
//                   "unit_number": "unit_number",
//                   "latitude": 1.234,
//                   "longitude": -2.3948
//               }
//           },
//           "note_for_driver": "note_for_driver"
//       }
//   ]
// }
