import React, { useEffect, useState } from "react";
import { cloneDeep } from "lodash";
import { Formik } from "formik";
import classNames from "classnames";
import ExpressionForm from "./ExpressionForm";
import ResultForm from "./ResultForm";
import {
  equalityOperatorsList,
  initialExpressionPayload,
  initialLogicalOperatorPayload,
  initialPayloadForRule,
  operationsList,
  ruleExpressionFieldsList,
  ruleResultFieldsList,
} from "../utils";
import { pricingRulesSchema } from "../validationSchema";
import Button from "../../../../shared/atoms/Button";
import styles from "../../styles/PricingRules/CreateRule/styles.module.css";

const CreateRule = ({
  rules,
  setFieldValue,
  selectedRuleIndex,
  setSelectedRuleIndex,
  setActiveStep,
}) => {
  const [initialValues, setInitialValues] = useState(initialPayloadForRule);

  useEffect(() => {
    const rule = cloneDeep(rules[selectedRuleIndex]);
    if (rule?.condition && rule?.result) {
      setInitialValues(rule);
    }
  }, [rules, selectedRuleIndex]);

  const logicalOperatorClickHandler = (values, setValues, index, value) => {
    const condition = [...values.condition];
    const logicalOperatorPayload = { ...initialLogicalOperatorPayload, value };
    if (index === condition.length) {
      setValues({
        ...values,
        condition: [
          ...condition,
          logicalOperatorPayload,
          cloneDeep(initialExpressionPayload),
        ],
      });
    } else {
      condition[index] = logicalOperatorPayload;
      setValues({
        ...values,
        condition: [...condition],
      });
    }
  };

  const expressionRemoveHandler = (index, values, setValues) => {
    const condition = [...values.condition];
    if (condition.length < 2) return;
    if (condition[index + 1]) {
      condition.splice(index, 2);
    } else {
      condition.splice(index - 1, 2);
    }
    setValues({ ...values, condition });
  };

  const handleSubmit = (values, errors) => {
    let newRules = cloneDeep(rules);
    if (selectedRuleIndex !== null) {
      newRules[selectedRuleIndex] = values;
    } else {
      newRules.push(values);
    }
    setFieldValue("rules", newRules);
    setSelectedRuleIndex(null);
    setActiveStep("list_rules");
  };

  const handleDelete = () => {
    let newRules = cloneDeep(rules);
    if (selectedRuleIndex !== null) {
      newRules.splice(selectedRuleIndex, 1);
      setFieldValue("rules", newRules);
      setSelectedRuleIndex(null);
      setActiveStep("list_rules");
    }
  };

  const renderExpressionSection = (values, setValues, condition, index) => {
    if (condition.type !== "expression") return null;
    const logicalOperator = values.condition[index + 1];
    return (
      <>
        <ExpressionForm
          ruleExpressionFieldsList={ruleExpressionFieldsList}
          operationsList={operationsList}
          equalityOperatorsList={equalityOperatorsList}
          removeHandler={() =>
            expressionRemoveHandler(index, values, setValues)
          }
          index={index}
        />
        <div className={styles.dottedConnectorLine} />
        <Button
          onClick={(e) =>
            logicalOperatorClickHandler(values, setValues, index + 1, "OR")
          }
          text="+or"
          externalClassName={classNames(
            styles.logicalOperatorButton,
            styles.leftButton,
            {
              [styles.logicalOperatorButtonSelected]:
                logicalOperator?.value === "OR",
            }
          )}
        />
        <Button
          onClick={(e) =>
            logicalOperatorClickHandler(values, setValues, index + 1, "AND")
          }
          text="+and"
          externalClassName={classNames(styles.logicalOperatorButton, {
            [styles.logicalOperatorButtonSelected]:
              logicalOperator?.value === "AND",
          })}
        />
        {index < values.condition.length - 1 && (
          <div className={styles.dottedConnectorLine} />
        )}
      </>
    );
  };

  return (
    <Formik
      validationSchema={pricingRulesSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values, setValues, handleSubmit, errors }) => (
        <div className={styles.container}>
          <div className={classNames(styles.formSection, "kata")}>
            {selectedRuleIndex !== null && (
              <Button
                primary
                type="submit"
                onClick={() => handleDelete()}
                text="Delete rule"
                externalClassName={styles.deleteButton}
              />
            )}
            <div className={styles.ifBlockHeading}>If</div>
            <div className={styles.orBlockContainer}>
              {values?.condition?.map((condition, index) =>
                renderExpressionSection(values, setValues, condition, index)
              )}
            </div>
            <div className={styles.thenBlockHeading}>Then</div>
            <div className={styles.orBlockContainer}>
              <ResultForm
                ruleExpressionFieldsList={ruleExpressionFieldsList}
                ruleResultFieldsList={ruleResultFieldsList}
                operationsList={operationsList}
              />
            </div>
          </div>
          <div className={styles.buttonSection}>
            <Button
              text="Cancel"
              variant="secondary"
              onClick={(e) => {
                setSelectedRuleIndex(null);
                setActiveStep("list_rules");
              }}
            />
            <Button
              type="submit"
              onClick={(e) => handleSubmit(e, errors)}
              text="Save"
              externalClassName={styles.saveButton}
            />
          </div>
        </div>
      )}
    </Formik>
  );
};

export default CreateRule;
