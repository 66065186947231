import { createStore } from "aniuta";

import { useState } from "react";
import { toast } from "react-toastify";

import {
  addEventTypes,
  eventTypeDelete,
  subscribedEvents,
  subscribleEventTypes,
  updateWebhooks,
} from "../utils/api/webhooks";
import * as Sentry from "@sentry/react";

const useWebhooks = createStore({
  name: "useWebhooks",
  Store: () => {
    const [webhookForUpdate, setWebhookForUpdate] = useState();
    const [isUpgrading, setIsUpgrading] = useState(false);

    const [isSubscriblesLoading, setIsSubscriblesLoading] = useState(false);
    const [subscriblesData, setSubscriblesData] = useState();

    const getSubscribleEventTypes = async (id) => {
      setIsSubscriblesLoading(true);
      try {
        const { data } = await subscribleEventTypes(id);
        setSubscriblesData(data);
        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
        return "error";
      } finally {
        setIsSubscriblesLoading(false);
      }
    };

    const [updateLoading, setUpdateLoading] = useState(false);

    const putUpdateWebhooks = async (id, payload) => {
      setUpdateLoading(true);
      try {
        const { data } = await updateWebhooks(id, payload);
        // setSubscriblesData(data);
        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
        return "error";
      } finally {
        setUpdateLoading(false);
      }
    };

    const [addEventTypesLoading, setAddEventTypesLoading] = useState(false);

    const postAddEventTypes = async (id, payload) => {
      setAddEventTypesLoading(true);
      try {
        const { data } = await addEventTypes(id, payload);
        // setSubscriblesData(data);
        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
        return "error";
      } finally {
        setAddEventTypesLoading(false);
      }
    };

    const [subscribedEventsLoading, setSubscribedEventsLoading] =
      useState(false);

    const [subscribedEventsData, setSubscribedEventsData] = useState();
    const getSubscribedEvents = async (id) => {
      try {
        const { data } = await subscribedEvents(id);
        // setSubscriblesData(data);
        setSubscribedEventsData(data);
        return data;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
        return "error";
      } finally {
        setSubscribedEventsLoading(false);
      }
    };

    const [isDeleteEventLoading, setIsDeleteEventLoading] = useState(false);
    const deteleEventType = async (id) => {
      setIsDeleteEventLoading(true);
      try {
        await eventTypeDelete(id);

        return true;
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message);
        } else {
          Sentry.captureException(error);
          toast.error("Something went wrong! Please try again.");
        }
        return false;
      } finally {
        setIsDeleteEventLoading(false);
      }
    };

    return {
      isSubscriblesLoading,
      getSubscribleEventTypes,
      subscriblesData,

      //update
      putUpdateWebhooks,
      updateLoading,

      //add event
      postAddEventTypes,

      //get events
      getSubscribedEvents,
      subscribedEventsData,
      setSubscribedEventsData,
      subscribedEventsLoading,
      setSubscribedEventsLoading,

      //delete event
      deteleEventType,
      isDeleteEventLoading,

      // webhook for update
      webhookForUpdate,
      setWebhookForUpdate,
      isUpgrading,
      setIsUpgrading,
    };
  },
});

export default useWebhooks;
