import React from "react";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
// import Colors from "../../assets/Colors";
import { useTheme } from "../../store";

function SingleAddressInFormik({
  index,
  address,
  name,
  phoneNumber,
  note,
  length,
  unit,
}) {
  const { Colors } = useTheme();
  const { smfontsize, mdfontsize } = useDesktopOrLaptop();

  const styles = {
    circle: {
      position: "absolute",
      height: "14px",
      width: "14px",
      borderRadius: "100%",
      top: "0px",
      left: "0px",
    },
    pickupDropoff: {
      fontFamily: "Poppins",
      fontSize: mdfontsize,
      color: Colors.fontGreyLight,
      fontWeight: "500",
      marginBottom: "2px",
      marginTop: "0px",
    },
    singleAddress: {
      position: "relative",
      minHeight: "33%",
      overflow: "hidden",
      padding: "0px 0px 0px 30px",
    },
    addresses: {
      fontFamily: "Poppins",
      fontSize: mdfontsize,
      color: Colors.fontDark,
      fontWeight: "500",
    },
  };
  return (
    <div
    // style={{
    //   backgroundColor: Colors.buttonBg,
    //   padding: "20px 20px 0px 20px",
    //   borderRadius: "8px",
    // }}
    >
      <div style={styles.singleAddress}>
        {index !== length && (
          <div
            style={{
              width: "1px",
              height: `100%`,
              backgroundColor: Colors.fontDark,
              position: "absolute",
              top: "0px",
              left: "6px",
            }}
          ></div>
        )}

        <div
          style={{
            ...styles.circle,
            ...{
              backgroundColor:
                index !== 0 ? Colors.greenDark : Colors.yellowDark,
            },
          }}
        ></div>
        <div style={styles.pickupDropoff}>
          {index === 0 ? "Pickup:" : `Stop ${index}`}
        </div>

        <div style={{ ...styles.addresses }}>{address}</div>
        <div style={{ ...styles.pickupDropoff, ...{ marginTop: "20px" } }}>
          Unit:
        </div>
        <div
          style={{
            ...styles.addresses,
            ...{ textAlign: "start", width: "50%" },
          }}
        >
          {unit}
        </div>
        <div
          style={{ paddingRight: "61px", marginTop: "20px" }}
          className="flex w-full items-center justify-between"
        >
          <div
            style={{
              ...styles.pickupDropoff,
              ...{ textAlign: "start", width: "33%" },
            }}
          >
            Name
          </div>
          {/* <div
            style={{
              ...styles.pickupDropoff,
              ...{ textAlign: "start", width: "33%" },
            }}
          >
            Business Name
          </div> */}
          <div
            style={{
              ...styles.pickupDropoff,
              ...{ textAlign: "start", width: "33%" },
            }}
          >
            Phone Number
          </div>
        </div>
        <div
          style={{ paddingRight: "61px", marginTop: "0px" }}
          className="flex w-full items-center justify-between"
        >
          <div
            style={{
              ...styles.addresses,
              ...{ textAlign: "start", width: "33%" },
            }}
          >
            {name}
          </div>
          {/* <div
            style={{
              ...styles.addresses,
              ...{ textAlign: "start", width: "33%" },
            }}
          >
            {bussinessNmae}
          </div> */}
          <div
            style={{
              ...styles.addresses,
              ...{ textAlign: "start", width: "33%" },
            }}
          >
            {phoneNumber[0] == "+" ? phoneNumber : "+" + phoneNumber}
          </div>
        </div>
        <div style={{ ...styles.pickupDropoff, ...{ marginTop: "20px" } }}>
          Note:
        </div>

        <div
          style={{
            minHeight: "80px",
            width: "100%",
            backgroundColor: Colors.bgGrey,
            borderRadius: "8px",
            padding: "10px",
            marginBottom: "10px",
          }}
        >
          <div style={{ ...styles.addresses, ...{ fontSize: smfontsize } }}>
            {note}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingleAddressInFormik;
