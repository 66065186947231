export const QUOTE_ITEMS = [
  {
    id: 0,
    key: "Subsidize",
    value: null,
    type: null,
    description: "Subsidize delivery quotes for your end customer",
    saved: false,
    example: "* For example, a $2 subsidy will bring an  original $7 quote  down to $5.",
  },
  {
    id: 1,
    key: "Upsell",
    value: null,
    type: null,
    description: "Mark-up delivery prices for your business",
    saved: false,
    example: "* For example, a $2 mark-up will bring an original $7 quote  up to $9.",
  },
  {
    id: 2,
    key: "Limit Radius",
    value: null,
    type: "mile",
    description: "Restrict delivery radius to your preferred mileage",
    saved: false,
    example: "* For example, with a 10 mile limit, any longer deliveries will be restricted.",
  },
  {
    id: 3,
    key: "Price Ceiling",
    value: null,
    type: "usd",
    description: "Hide all delivery options when the quote exceeds your pre-defined amount",
    saved: false,
    example: "* If price is over eg. $10 do not show any delivery options.",
  },
  {
    id: 4,
    key: "Multi Quote",
    value: false,
    type: "boolean",
    description: "Enable and receive quotes from multiple providers during your delivery creation process.",
    saved: false,
    example: "",
  },
  {
    id: 5,
    key: "Include Burq fee",
    value: false,
    type: "boolean",
    description:
      "When this setting is enabled, Burq fee is included in the prices in quotes. When disabled, Burq fee is excluded from the prices in quotes.",
    saved: false,
    example: "",
  },
  {
    id: 6,
    key: "Provider Preference",
    value: null,
    type: null,
    description: "Please select providers from the list below to receive quotes from them exclusively on the quote page",
    saved: false,
    example: "* For example, a $2 subsidy will bring an  original $7 quote  down to $5.",
  },
  {
    id: 7,
    key: "Reroute",
    value: false,
    type: "boolean",
    description: "Automatically reroute your deliveries when ever they are provider canceled.",
    saved: false,
    example: "",
  },
];
