import React from "react";

function SomeoneNewIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="43" fill="none" viewBox="0 0 45 43">
      <g clipPath="url(#clip0_17466_7742)">
        <path
          fill="#0062FF"
          d="M26.056 28.269a16.962 16.962 0 00-3.506-1.788c1.636-1.42 2.721-3.411 2.91-5.64 2.481-2.31 5.733-3.576 9.22-3.576 2.72 0 5.334.783 7.556 2.265.797.53 1.892.344 2.448-.417.555-.76.36-1.808-.436-2.338a16.972 16.972 0 00-3.507-1.788c1.806-1.568 2.94-3.829 2.94-6.338 0-4.723-4.02-8.565-8.963-8.565s-8.964 3.842-8.964 8.565c0 2.5 1.126 4.752 2.919 6.319a16.904 16.904 0 00-3.827 1.987c-1.326-3.149-4.555-5.377-8.32-5.377-4.942 0-8.963 3.842-8.963 8.565 0 2.492 1.12 4.738 2.903 6.305C5.698 28.155 1.832 31.82.258 36.496c-.521 1.55-.245 3.195.759 4.512 1.004 1.316 2.557 2.07 4.261 2.07H20.57c.971 0 1.758-.751 1.758-1.679 0-.927-.787-1.68-1.758-1.68H5.28a1.767 1.767 0 01-1.422-.69 1.604 1.604 0 01-.253-1.504c1.738-5.161 7.036-8.766 12.884-8.766 2.721 0 5.335.784 7.558 2.265.796.53 1.891.344 2.447-.417.555-.76.36-1.808-.437-2.338zm8.662-24.826c3.004 0 5.448 2.336 5.448 5.206 0 2.871-2.444 5.207-5.448 5.207-3.004 0-5.449-2.336-5.449-5.207 0-2.87 2.445-5.206 5.449-5.206zM16.527 14.937c3.004 0 5.448 2.336 5.448 5.206 0 2.871-2.444 5.206-5.448 5.206-3.005 0-5.449-2.335-5.449-5.206 0-2.87 2.444-5.206 5.449-5.206zM45 35.1c0 .928-.787 1.68-1.758 1.68H38.41v4.618c0 .928-.787 1.68-1.758 1.68-.97 0-1.758-.752-1.758-1.68v-4.618H30.06c-.97 0-1.757-.752-1.757-1.68 0-.928.787-1.68 1.757-1.68h4.834v-4.618c0-.927.786-1.68 1.757-1.68.97 0 1.758.753 1.758 1.68v4.619h4.833c.971 0 1.758.752 1.758 1.68z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_17466_7742">
          <path fill="#fff" d="M0 0H45V43H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

function LetThemSignUpIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="none" viewBox="0 0 50 50">
      <path
        fill="#0062FF"
        fillRule="evenodd"
        d="M23.958 42.215H6.146a.418.418 0 01-.417-.416v-3.023c0-1.73 1.235-3.255 3.14-4.55 3.4-2.321 8.902-3.784 15.09-3.784a1.563 1.563 0 000-3.125c-6.922 0-13.049 1.732-16.853 4.325-2.902 1.98-4.502 4.494-4.502 7.134v3.023a3.544 3.544 0 003.542 3.541h17.812a1.561 1.561 0 100-3.125zM23.958 2.604c-6.325 0-11.458 5.134-11.458 11.459 0 6.325 5.133 11.458 11.458 11.458s11.459-5.133 11.459-11.458S30.283 2.604 23.958 2.604zm0 3.125c4.6 0 8.334 3.734 8.334 8.334s-3.734 8.333-8.334 8.333a8.336 8.336 0 01-8.333-8.333c0-4.6 3.733-8.334 8.333-8.334zM36.458 29.038a7.424 7.424 0 00-7.42 7.42 7.426 7.426 0 007.42 7.424 7.428 7.428 0 007.423-7.423 7.426 7.426 0 00-7.423-7.42zm0 3.125a4.3 4.3 0 014.298 4.296 4.302 4.302 0 01-4.298 4.298 4.302 4.302 0 01-4.298-4.298 4.3 4.3 0 014.298-4.296z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#0062FF"
        fillRule="evenodd"
        d="M38.02 30.599v-3.515a1.563 1.563 0 00-3.125 0v3.517c0 .86.7 1.562 1.563 1.562a1.566 1.566 0 001.563-1.564zM41.706 33.421l2.486-2.487c.61-.609.61-1.6 0-2.209a1.56 1.56 0 00-2.209 0l-2.487 2.486c-.608.61-.608 1.6 0 2.21.61.609 1.6.609 2.21 0zM42.316 38.022h3.517a1.563 1.563 0 000-3.125h-3.517a1.563 1.563 0 000 3.125zM39.496 41.707l2.487 2.485c.609.61 1.6.61 2.209 0 .61-.608.61-1.6 0-2.208l-2.486-2.488a1.567 1.567 0 00-2.21 0c-.608.61-.608 1.6 0 2.21zM34.895 42.317v3.517a1.563 1.563 0 003.125 0v-3.517c0-.86-.7-1.56-1.562-1.562-.862 0-1.563.702-1.563 1.562zM31.21 39.496l-2.485 2.488a1.56 1.56 0 000 2.208c.608.61 1.6.61 2.208 0l2.488-2.485c.608-.61.608-1.6 0-2.21a1.567 1.567 0 00-2.21 0zM30.598 34.897h-3.515a1.563 1.563 0 000 3.125h3.515a1.562 1.562 0 100-3.125zM33.42 31.21l-2.487-2.485a1.56 1.56 0 00-2.208 0 1.56 1.56 0 000 2.209l2.485 2.487c.61.609 1.6.609 2.21 0 .61-.61.61-1.6 0-2.21z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

function RewardIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" fill="none" viewBox="0 0 44 44">
      <g clipPath="url(#clip0_17466_7738)">
        <path
          fill="#0062FF"
          d="M38.844 10.313h-5.738a6.83 6.83 0 00.925-3.438A6.883 6.883 0 0027.156 0 6.859 6.859 0 0022 2.335 6.859 6.859 0 0016.844 0a6.883 6.883 0 00-6.875 6.875 6.83 6.83 0 00.925 3.438H5.156A5.162 5.162 0 000 15.468v8.25h3.438v15.125A5.162 5.162 0 008.593 44h26.812a5.162 5.162 0 005.157-5.156V23.719H44v-8.25a5.162 5.162 0 00-5.156-5.156zM20.28 40.563H8.594a1.72 1.72 0 01-1.719-1.72V23.72h13.406v16.843zm0-20.282H3.438V15.47a1.72 1.72 0 011.718-1.719h15.125v6.531zm0-9.968h-3.437a3.441 3.441 0 01-3.438-3.438 3.441 3.441 0 013.438-3.438 3.441 3.441 0 013.437 3.438v3.438zm3.438-3.438a3.441 3.441 0 013.437-3.438 3.441 3.441 0 013.438 3.438 3.441 3.441 0 01-3.438 3.438H23.72V6.874zm13.406 31.969a1.72 1.72 0 01-1.719 1.718H23.72V23.72h13.406v15.125zm3.438-18.563H23.718V13.75h15.125a1.72 1.72 0 011.718 1.719v4.812z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_17466_7738">
          <path fill="#fff" d="M0 0H44V44H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export { SomeoneNewIcon, LetThemSignUpIcon, RewardIcon };
