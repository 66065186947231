import React, { useEffect, useState, useRef, useMemo } from "react";
import { HiOutlineClipboardCopy } from "react-icons/hi";
import Cookies from "js-cookie";

import { useMyStore, useTheme } from "../../store";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import AddEditWebhookModal from "./AddEditWebhookModal";
import WebhooksSubscription from "./WebhooksSubscription";
import WebhookItem from "./WebhookItem";
import CustomModal from "../CustomModal/CustomModal";
import AreYouSurePopup from "../shared/common/AreYouSurePopup";
// import { useMyStore, useTheme } from "../store";
// import "../screens/CreateDelivery/CreateDelivery.css";
// import CustomModal from "./CustomModal/CustomModal";
// import { useDesktopOrLaptop } from "../services/useDesktopOrLaptop";

const Webhooks = () => {
  const { Colors, themeChanger } = useTheme();

  const { isdesktop, smfontsize, mdfontsize } = useDesktopOrLaptop();

  const styles = useMemo(() => {
    return {
      webhook: {
        fontWeight: "500",
        fontSize: mdfontsize,
        color: Colors.fontDark,
      },
      newWebhookButton: {
        fontSize: mdfontsize,
        fontWeight: "500",
        color: Colors.buttonBg,
        height: isdesktop ? "44px" : "56px",
        textAlign: "center",
        borderRadius: "8px",
        paddingLeft: "22px",
        paddingRight: "22px",
      },
      webhooksContainer: {
        borderRadius: "10px",
        marginTop: "24px",
      },
    };
  }, [Colors, isdesktop, themeChanger]);

  const {
    getWebhooksList,
    webhooksList,
    webhookError,
    addWebhookData,
    deleteWebhookData,
    isSandbox,
    setWebhookError,
    deleteWebhook,
  } = useMyStore();
  const copyText = useRef(null);
  const [createWebhookVisible, setCreateWebhookVisible] = useState(false);

  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
  const [modalIdForDelete, setModalIdForDelete] = useState();

  const [url, setUrl] = useState();
  const handelCopy = () => {
    copyText.current.select();
    document.execCommand("copy");
  };
  useEffect(() => {
    setWebhookError();
    if (!webhookError) {
      getWebhooksList(isSandbox);
    } else if (webhookError) {
    }
  }, [addWebhookData, deleteWebhookData, isSandbox]);
  useEffect(() => {
    window.analytics.page();
  }, []);

  let isNewWebhookButtonDisabled = webhooksList?.length > 0;
  let isLatestVersion =
    isNewWebhookButtonDisabled &&
    webhooksList[0].current_version === webhooksList[0].latest_version;

  return (
    <div
      style={{
        padding: "24px 24px 200px 24px",
        backgroundColor: Colors.white,
        overflowY: "auto",
      }}
      className="w-full  flex flex-col h-full kata"
    >
      <AreYouSurePopup
        isOpen={isDeleteModalOpened}
        onClose={() => {
          setIsDeleteModalOpened(false);
          setModalIdForDelete();
        }}
        onYes={async () => {
          await deleteWebhook(Cookies.get("token"), modalIdForDelete.id);
          setIsDeleteModalOpened(false);
        }}
      />
      <div className="flex w-full justify-between items-center ">
        <h1 style={styles.webhook}>Webhooks</h1>
        <div className="mr-0 rounded-lg">
          <button
            id="newWebhookButtonId"
            onClick={() => {
              if (isNewWebhookButtonDisabled) {
                return;
              }
              setCreateWebhookVisible(true);
            }}
            style={{
              fontSize: mdfontsize,
              fontWeight: "500",
              color: Colors.buttonBg,
              height: isdesktop ? "44px" : "56px",
              textAlign: "center",
              borderRadius: "8px",
              paddingLeft: "22px",
              paddingRight: "22px",
              backgroundColor: isNewWebhookButtonDisabled
                ? Colors.borderSecondary
                : Colors.bluePrimary,
            }}
          >
            New Webhook
          </button>
        </div>
      </div>

      {webhooksList?.length > 0 && (
        <div style={styles.webhooksContainer} className="w-full">
          {webhooksList?.map((item, index) => {
            return (
              <WebhookItem
                index={index}
                id={item.id}
                itemId={item.id}
                key={item.id}
                url={item.url}
                item={item}
                deleteWebhook={() => {
                  setModalIdForDelete(item);
                  setIsDeleteModalOpened(true);
                }}
                setCreateWebhookVisible={setCreateWebhookVisible}
              />
            );
          })}
        </div>
      )}

      <CustomModal
        extraPanelStyles={{ maxWidth: isdesktop ? "50%" : "80%" }}
        isOpen={createWebhookVisible}
        onClose={() => setCreateWebhookVisible(false)}
      >
        <AddEditWebhookModal
          setCreateWebhookVisible={setCreateWebhookVisible}
          setUrl={setUrl}
          url={url}
        />
      </CustomModal>

      <WebhooksSubscription
        webhookId={isNewWebhookButtonDisabled && webhooksList[0].id}
        isLatestVersion={isLatestVersion}
        createWebhookVisible={createWebhookVisible}
        setCreateWebhookVisible={setCreateWebhookVisible}
        webhook={isNewWebhookButtonDisabled ? webhooksList[0] : undefined}
      />
      <h2
        style={{ ...styles.webhook, ...{ marginTop: "24px" } }}
        className=" font-semibold"
      >
        Keys
      </h2>
      <div
        style={{
          ...styles.webhooksContainer,
          ...{
            width: "100%",
            paddingTop: "16px",
            paddingBottom: "24px",
            paddingLeft: "24px",
          },
        }}
        className="shadow-md"
      >
        <h3
          style={{
            fontFamily: "Poppins",
            fontSize: mdfontsize,
            fontWeight: "600",
            color: Colors.fontDark,
          }}
          className="text-gray-600 mb-4"
        >
          {isSandbox ? "Sandbox Key" : "Production Key"}
        </h3>
        <div className="flex flex-row items-center">
          <button
            type="button"
            style={{ width: isdesktop ? "350px" : "540px" }}
            onClick={handelCopy}
          >
            <input
              id="sandBoxOrProdKey"
              style={{
                minWidth: "100%",
                color: Colors.fontDark,
                fontFamily: "Poppins",
                fontSize: smfontsize,
                fontWeight: "500",
                borderWidth: "1px",
                borderColor: Colors.borderPrimary,
                paddingLeft: "16px",
                paddingRight: "16px",
                borderRadius: "10px",
                paddingTop: "10px",
                paddingBottom: "10px",
                backgroundColor: Colors.white,
              }}
              readOnly
              className="focus:outline-none "
              ref={copyText}
              value={isSandbox ? Cookies.get("sandbox") : Cookies.get("prod")}
            />
          </button>
          <button
            style={{ marginLeft: "10px" }}
            type="button"
            onClick={handelCopy}
          >
            <HiOutlineClipboardCopy size={24} color={Colors.fontDark} />
          </button>
        </div>
      </div>
      <div style={{ ...styles.webhook, ...{ marginTop: "24px" } }}>
        API Documentation
      </div>
      <div
        style={{
          ...styles.webhooksContainer,
          ...{ paddingTop: "24px", paddingBottom: "24px", paddingLeft: "24px" },
        }}
        className="shadow-md flex flex-row items-center "
      >
        <div
          style={{
            color: Colors.fontDark,
            fontFamily: "Poppins",
            fontSize: smfontsize,
            fontWeight: "500",
          }}
        >
          You can see API docs here:
        </div>
        <a
          style={{
            fontFamily: "Poppins",
            fontSize: mdfontsize,
            fontWeight: "600",
            color: Colors.fontDark,
            marginLeft: "16px",
          }}
          rel="noreferrer"
          target="_blank"
          className=" "
          href="https://burq.readme.io/"
        >
          Burq API
        </a>
      </div>
    </div>
  );
};

export default Webhooks;
